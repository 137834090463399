import { useTheme } from 'emotion-theming'
import React from 'react'
import { AlertTriangle } from 'react-feather'
import { Text } from 'rebass'
import { MediaQueries as Mq, styled } from '../../../styles/settings/theme'

import Alert from '../../atoms/Alert'
import Animation from '../../atoms/Animation'
import Card from '../../atoms/Card'

const RegistrationAlert = styled(Card)`
  padding: 3rem 1rem;

  ${Mq.md} {
    padding: 5rem;
  }
`

const PopulatingLeagueContent: React.FC = () => {
  const { colors } = useTheme()

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <RegistrationAlert notch={true}>
      <Alert noPadding={true} mx="auto">
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4 }}
        >
          <AlertTriangle color={colors.sand} size="38px" />
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Text mt={4} mb={5}>
            <h2>League Is Being Populated</h2>
          </Text>
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
        >
          <Text mb={6}>
            <p>
              This league has passed its registration period and it's matches are currently being
              set. Please check back in a few days to see the full schedule.
            </p>
          </Text>
        </Animation>
      </Alert>
    </RegistrationAlert>
  )
}

export default PopulatingLeagueContent
