import { Box, Flex, FlexProps } from 'rebass'
import { ChevronDown } from 'react-feather'
import { styled } from '../../styles/settings/theme'
import React, { useState } from 'react'

import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'

interface IDropDownProps {
  header: string
  listItems: string[]
  onSelectItem: (item: number) => void
}

const DropDownWrapper = styled(Flex)<FlexProps>`
  position: relative;
  width: max-content;
`

const DropDownMenuWrapper = styled(Flex)`
  background: ${props => props.theme.colors.primarylightblue};
  flex-direction: column;
  top: 3.5rem;
  overflow: hidden;
  padding: 1rem 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;

  --notchSize: 20px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 0px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );

  h6 {
    padding: 0 0 1rem 0;
    transition: ${props => props.theme.transitions.fast};
    cursor: pointer;
    color: ${props => props.theme.colors.primarynavy};
    :hover {
      color: ${props => props.theme.colors.primarylightblue};
    }
  }
`

const StyledBaseButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${props => props.theme.colors.primarynavy};

  svg {
    margin-left: 1rem;
    margin-top: -0.3rem;
  }

  &.clicked {
    border-color: ${props => props.theme.colors.primarylightblue};
  }
`

const MenuBorder = styled(Flex)`
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  --notchSize: 20px;
  background: ${props => props.theme.colors.white};

  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 0px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
`

const DropDownLinks = styled(Box)`
  z-index: 9;
`

const DropDown: React.FC<IDropDownProps> = ({ header, listItems, onSelectItem }) => {
  const [showMenu, setShowMenu] = useState(false)
  const animations = {
    hidden: {
      y: '-24px',
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  const handleItemSelected = (index: number) => {
    setShowMenu(false)
    onSelectItem(index)
  }

  return (
    <DropDownWrapper>
      <Animation
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.6 }}
      >
        <StyledBaseButton variant="secondary" onClick={() => setShowMenu(!showMenu)}>
          {header}
          <ChevronDown />
        </StyledBaseButton>
      </Animation>

      {showMenu && (
        <DropDownMenuWrapper>
          <DropDownLinks>
            {listItems.map((item, index) => (
              <h6 key={item} onClick={() => handleItemSelected(index)}>
                {item}
              </h6>
            ))}
          </DropDownLinks>
          <MenuBorder />
        </DropDownMenuWrapper>
      )}
    </DropDownWrapper>
  )
}

export default DropDown
