import { Flex, Box, Text } from 'rebass'
import React, { useState } from 'react'

import { aceScoreTeam } from '../../utils/rosterEntryUtils'
import { RosterEntry, Match } from '../../types/graphql'
import { styled } from '../../styles/settings'
import Animation from '../atoms/Animation'
import AsciiEmoji from '../atoms/AsciiEmoji'
import PlayerCurrentTeamTable from '../organisms/PlayerCurrentTeamTable'
import TeamDetailSeasonHistory from '../organisms/TeamDetailSeasonHistory'
import DropDown from '../atoms/DropDown'
import { prettyName } from '../../utils/sportUtils'

interface TwoTablesProps {
  entries: [RosterEntry]
  matches: Match[]
  sport: string
}

const NoTeamBox = styled(Box)`
  border: 2px solid ${props => props.theme.colors.middlegray};
  background: ${props => props.theme.colors.white};
  text-align: center;
`

const PlayerTwoTables: React.FC<TwoTablesProps> = ({ entries, matches, sport }) => {
  const [currentTeam, setCurrentTeam] = useState(0)
  const teamData =
    entries.length &&
    entries.map((entry: RosterEntry) => {
      return {
        id: entry.team && entry.team.id,
        name: entry.team && entry.team.name,
        role: entry.gameRole,
        'ace score': aceScoreTeam(entry),
        'season record':
          entry.team && entry.team.selectedSeasonStats
            ? entry.team.selectedSeasonStats.wins + '-' + entry.team.selectedSeasonStats.losses
            : null,
        'season standing': entry.team && entry.team.selectedSeasonRank,
        league: entry.team && entry.team.division,
        conference: entry.team && entry.team.selectedSeasonConferenceName,
      }
    })

  const currentTeamMatches =
    teamData[currentTeam] &&
    matches.filter(m =>
      [m.homeTeam && m.homeTeam.id, m.awayTeam && m.awayTeam.id].includes(teamData[currentTeam].id),
    )

  return (
    <>
      {entries.length ? (
        <Box mt={[4, 4, 4, 8]} mb={[4, 4, 4, 0]}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {
                opacity: 0,
                y: '24px',
              },
              visible: {
                opacity: 1,
                y: 0,
              },
            }}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
          >
            <Flex
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent={['center', 'center', 'center', 'space-between']}
              alignItems={['space-between', 'space-between', 'space-between', 'center']}
              mb={5}
            >
              <Text mb={[5, 5, 5, 0]}>
                <h2>team details: {teamData[currentTeam].name}</h2>
              </Text>
              {teamData.length > 1 && (
                <DropDown
                  header="view other teams"
                  listItems={teamData.map(t => t.name)}
                  onSelectItem={index => setCurrentTeam(index)}
                />
              )}
            </Flex>
          </Animation>
          <Flex flexWrap="wrap">
            <PlayerCurrentTeamTable teamData={teamData[currentTeam]} />
            <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
              <TeamDetailSeasonHistory
                teamId={entries[currentTeam].team.id}
                matches={currentTeamMatches}
                hideTitle
              />
            </Box>
          </Flex>
        </Box>
      ) : (
        <>
          <Text mt={8}>
            <h2>team</h2>
          </Text>
          <NoTeamBox mt={2} py={8}>
            <AsciiEmoji mt={4} mb={4}>
              ¯\_(ツ)_/¯
            </AsciiEmoji>
            <Text color="darkgray">
              <Box mb={3}>
                <h6>Not currently on a {prettyName(sport)} team</h6>
              </Box>
              <Box mx={'auto'} mb={6} width={1 / 2}>
                <p>
                  This player is not currently on a {prettyName(sport)} team. Contact them to
                  recruit them to your team.
                </p>
              </Box>
            </Text>
          </NoTeamBox>
        </>
      )}
    </>
  )
}

export default PlayerTwoTables
