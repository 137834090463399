import { css } from '@emotion/core'

import { Theme } from '../settings/theme'
import { MediaQueries as Mq } from '../../styles/settings/theme'

export const Base = css`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
  }
  html {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  body {
    width: 100%;
    height: 100%; // This is needed for react modal override
    background-color: ${Theme.colors.backgroundgray};
    ${Mq.lg} {
      width: 100vw;
    }
  }
  h1 {
    font-size: ${Theme.responsiveFontSizes[2]};
    font-family: ${Theme.fonts.wide};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ${Mq.md} {
      font-size: ${Theme.fontSizes[2]};
      font-family: ${Theme.fonts.wide};
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }
  h2 {
    font-size: ${Theme.responsiveFontSizes[1]};
    font-family: ${Theme.fonts.wide};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ${Mq.md} {
      font-family: ${Theme.fonts.wide};
      font-size: ${Theme.fontSizes[1]};
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }
  h3 {
    font-family: ${Theme.fonts.wide};
    font-size: ${Theme.responsiveFontSizes[0]};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ${Mq.md} {
      font-family: ${Theme.fonts.wide};
      font-size: ${Theme.fontSizes[0]};
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }
  h4 {
    font-family: ${Theme.fonts.condensed};
    font-size: ${Theme.responsiveFontSizes[1]};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ${Mq.md} {
      font-family: ${Theme.fonts.condensed};
      font-size: ${Theme.fontSizes[1]};
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }
  h5 {
    font-family: ${Theme.fonts.condensed};
    font-size: ${Theme.responsiveFontSizes[0]};
    font-weight: bold;
    line-height: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ${Mq.md} {
      font-family: ${Theme.fonts.condensed};
      font-size: ${Theme.fontSizes[0]};
      font-weight: bold;
      line-height: auto;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }
  h6 {
    font-family: ${Theme.fonts.condensed};
    font-size: ${Theme.responsiveFontSizes[0]};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ${Mq.md} {
      font-family: ${Theme.fonts.condensed};
      font-size: ${Theme.fontSizes[0]};
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }

  p {
    font-family: ${Theme.fonts.body};
    font-size: ${Theme.bodyFontSizes[1]};
    line-height: 1.8rem;
    padding: 0;
    a {
      font-weight: bold;
      color: ${Theme.colors.primaryblue};
    }
  }
  a {
    color: ${Theme.colors.primaryblue};
    cursor: pointer;
    text-decoration: none;
    transition: ${Theme.transitions.fast};

    &:hover {
      color: ${Theme.colors.primarynavy};
    }
  }
  li {
    height: auto;
    font-family: ${Theme.fonts.condensed};
    color: ${Theme.colors.darkgray};
  }

  .grid-container {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
`
