import gql from 'graphql-tag'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Flex, Text, Link } from 'rebass'
import { styled } from '../../styles/settings/theme'
import { useMutation } from '@apollo/react-hooks'

import { Forfeit } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import LoadingSpinner from '../atoms/LoadingSpinner'
import TextBox from '../atoms/TextBox'

const REVIEW_FORFEIT = gql`
  mutation ReviewForfeit($forfeitId: ID!, $rejectedMessage: String, $accepted: Boolean!) {
    reviewForfeit(forfeitId: $forfeitId, rejectedMessage: $rejectedMessage, accepted: $accepted) {
      errors
    }
  }
`

const InfoContainer = styled(Box)`
  border: 2px solid ${props => props.theme.colors.middlegray};
  padding: 1rem;
`

const TextBoxContainer = styled(Box)`
  position: relative;
  margin-top: 3rem;

  textarea {
    border-top-left-radius: 0;
  }
`

const LabelContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-98%);
`

const Triangle = styled.div`
  width: 2rem;
  background: linear-gradient(
    to left bottom,
    transparent 50%,
    ${props => props.theme.colors.darkmiddlegray} 50%
  );
`

const NoteLabel = styled(Box)`
  background: ${props => props.theme.colors.darkmiddlegray};
  border-right: 1px solid ${props => props.theme.colors.darkmiddlegray};
  padding: 0.5rem;
  border-radius: 4px 0 0 0;
`

interface ViewForfeitProps {
  forfeit: Forfeit
  closeModal: () => void
}

const ViewForfeit: React.FunctionComponent<ViewForfeitProps> = ({ forfeit, closeModal }) => {
  const [notes, setNotes] = useState('')

  const [reviewForfeit, { loading }] = useMutation(REVIEW_FORFEIT, {
    refetchQueries: ['matchDetailPage', 'pendingForfeits'],
    onError(error) {
      toast.error('An unexpected error occured, please try again.', { containerId: 'temporary' })
    },
    onCompleted(data) {
      if (data.reviewForfeit.errors.length) {
        toast.error(JSON.parse(data.reviewForfeit.errors[0])[0], { containerId: 'temporary' })
      } else {
        toast.success('Forfeit request updated!', { containerId: 'temporary' })
        closeModal()
      }
    },
  })

  const handleSubmit = (accepted: boolean) => {
    reviewForfeit({
      variables: {
        forfeitId: forfeit.id,
        rejectedMessage: !accepted && notes ? notes : null,
        accepted: accepted,
      },
    })
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Text mb={4}>
        <h2>Review Forfeit</h2>
      </Text>
      <InfoContainer>
        <Flex mb={1}>
          <Text mr={3}>
            <h5>Requesting Team:</h5>
          </Text>
          <p>{forfeit.requestingTeamName}</p>
        </Flex>
        <Flex mb={1}>
          <Text mr={3}>
            <h5> Opposing Team:</h5>
          </Text>
          <p>{forfeit.opposingTeamName}</p>
        </Flex>
        <Flex flexDirection="column" mt={3} mb={1}>
          <Text>
            <h5>Summary:</h5>
          </Text>

          <p>{forfeit.explanation}</p>
          <Link href={forfeit.proofScreenshotUrl!} target="_blank" mt={1}>
            <h6>view proof screenshot</h6>
          </Link>
        </Flex>
      </InfoContainer>
      <TextBoxContainer mb={3}>
        <LabelContainer justifyContent="flex-end" alignItems="stretch">
          <NoteLabel>
            <Text>
              <h6>Note (optional):</h6>
            </Text>
          </NoteLabel>
          <Triangle />
        </LabelContainer>
        <TextBox onChange={e => setNotes(e.target.value)} />
      </TextBoxContainer>
      <Flex>
        <BaseButton variant="secondary" onClick={() => handleSubmit(true)} mr={3}>
          approve
        </BaseButton>
        <BaseButton variant="decline" onClick={() => handleSubmit(false)}>
          deny
        </BaseButton>
      </Flex>
    </>
  )
}

export default ViewForfeit
