import React from 'react'

import NewsBrief from '../../organisms/NewsBrief'

// TODO: remove this component/just use NewsBrief if
// it doesn't need to be styled futher
const News: React.FC = () => {
  return <NewsBrief />
}

export default News
