import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import { oc } from 'ts-optchain'
import _ from 'lodash'

import DataFetcher from '../../organisms/DataFetcher'
import Content from '../../atoms/Content'
import Hero from '../../molecules/Hero'
import Filters from '../../molecules/Filters'
import { useConstants } from '../../../hooks/constants'
import { stringValues } from '../../../utils/enumUtils'
import { stringToEnum } from '../../../utils/typeCoercions'
import { errorsFullMessage, handleResult } from '../../../utils/results'
import { paths } from '../../../utils/Routes'
import {
  NewsArticleTypes,
  PostCategories,
  SportSlugs,
  useUpsertNewsOrAnnouncementMutation as useUpsertNewsOrAnnouncement,
  UpsertNewsOrAnnouncementMutation,
  ManageNewsAndAnnouncements_NewsOrAnnouncementFragment as NewsOrAnnouncement,
} from '../../../types/graphql'
import { ClickEvent } from '../../../types/aliases'
import { schema } from './validations'
import { Mode, YesOrNo } from './enums'
import CreateAPostFormFields, { IFeaturedImageFields } from './CreateAPostFormFields'
import PostContentFormFields from './PostContentFormFields'
// TODO: use actual hero image?
import HeroImage from '../../../assets/images/hero__league-index.jpg'

gql`
  mutation UpsertNewsOrAnnouncement(
    $type: NewsArticleTypes!
    $title: String!
    $body: String!
    $summary: String!
    $category: PostCategories
    $sport: SportSlugs
    $featuredImage: String
    $id: ID
    $featured: Boolean
    $position: Int
    $published: Boolean
  ) {
    upsertNewsOrAnnouncement(
      type: $type
      category: $category
      title: $title
      body: $body
      summary: $summary
      sport: $sport
      featuredImage: $featuredImage
      id: $id
      featured: $featured
      position: $position
      published: $published
    ) {
      success
      value {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

interface INewsAndAnnouncementsInfo {
  id?: string
  loading?: boolean
  newsOrAnnouncement?: NewsOrAnnouncement
}

const parseStringEnumC = <EnumType extends any>(eenum: any) => (str?: string | null) => {
  if (str === null || str === undefined) {
    return undefined
  }

  return stringToEnum<EnumType>(eenum, str!)
}

const parseCategory = parseStringEnumC<PostCategories>(PostCategories)
const parseSport = parseStringEnumC<SportSlugs>(SportSlugs)
const parseMode = parseStringEnumC<Mode>(Mode)
const parseYesOrNo = (bool?: boolean) => {
  if (_.isNil(bool)) {
    return undefined
  }

  return bool ? YesOrNo.Yes : YesOrNo.No
}

const NewsAndAnnouncementsInfo: React.FC<INewsAndAnnouncementsInfo> = ({
  id,
  loading,
  newsOrAnnouncement,
}) => {
  const constants = useConstants()
  const postCategories = oc(constants).postCategories([])
  const sportSlugs = oc(constants).sportSlugs([])
  const isLoading = !constants || !!loading

  const [currentMode, setCurrentMode] = useState<Mode>(Mode.News)
  const [category, setCategory] = useState<PostCategories | undefined>()
  const [featuredImageName, setFeaturedImageName] = useState()
  const [featuredImageDataUrl, setFeaturedImageDataUrl] = useState()
  const [body, setBody] = useState('')
  const [title, setTitle] = useState()
  const [excerpt, setExcerpt] = useState()
  const [featured, setFeatured] = useState<YesOrNo>()
  const [position, setPosition] = useState()
  const [sport, setSport] = useState<SportSlugs>()
  const [published, setPublished] = useState(false)
  const [posted, setPosted] = useState(false)

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirectTo, setRedirectTo] = useState()

  const setExistingState = (data?: NewsOrAnnouncement) => {
    if (!data) {
      return
    }
    setCurrentMode(parseMode(data.type) || Mode.News)
    setCategory(parseCategory(oc(data).newsCategory.name()))
    setFeaturedImageName(data.featuredImage || 'image')
    setFeaturedImageDataUrl(data.featuredImage)
    setBody(data.body || '')
    setTitle(data.title)
    setExcerpt(data.summary)
    setFeatured(parseYesOrNo(data.featured))
    setPosition(data.position)
    setSport(parseSport(data.sport))
    setPublished(data.published || false)
  }

  useEffect(() => {
    setExistingState(newsOrAnnouncement)
  }, [newsOrAnnouncement])

  const [upsertNewsOrAnnouncement] = useUpsertNewsOrAnnouncement()

  const isPersisted = !!id

  const setFeaturedImage = ({ name, dataUrl }: IFeaturedImageFields) => {
    setFeaturedImageDataUrl(dataUrl)
    setFeaturedImageName(name)
  }

  const handleTabClicked = (e: ClickEvent, value: string) => {
    e.preventDefault()
    const mode = stringToEnum<Mode>(Mode, value)

    setCurrentMode(mode!)
  }

  const typeFrom = (mode: Mode) => {
    return NewsArticleTypes[mode]
  }

  const handleFormSubmitted = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setPosted(true)

    currentMode === Mode.News ? handleNewsSubmitted() : handleAnnouncementSubmitted()
  }

  const handleErrors = (errors: _.Dictionary<string>) => {
    setPosted(false)
    toast.error(errorsFullMessage(errors), { containerId: 'temporary' })
  }

  const handleMutationComplete = (data?: UpsertNewsOrAnnouncementMutation) => {
    handleResult({
      result: data!.upsertNewsOrAnnouncement,
      onSuccess: _data => {
        toast.success('Content added', { containerId: 'temporary' })
        setRedirectTo(paths.newsAndAnnouncements())
      },
      onFailure: errors => {
        handleErrors(errors)
      },
    })
  }

  const handleNewsSubmitted = async () => {
    try {
      await schema.validate({ title, excerpt })

      const variables = {
        id,
        type: typeFrom(currentMode),
        title,
        body,
        featuredImage: featuredImageDataUrl,
        summary: excerpt,
        published: isPersisted ? published : undefined,
        // News-specific:
        category,
        sport,
      }

      const { data } = await upsertNewsOrAnnouncement({ variables })
      handleMutationComplete(data)
    } catch (err) {
      handleErrors(err.errors)
    }
  }

  const handleAnnouncementSubmitted = async () => {
    try {
      await schema.validate({ title, excerpt })

      const variables = {
        id,
        type: typeFrom(currentMode),
        title,
        body,
        featuredImage: featuredImageDataUrl,
        summary: excerpt,
        published: isPersisted ? published : undefined,
        // Announcement-specific:
        featured: featured === YesOrNo.Yes,
        position,
      }

      const { data } = await upsertNewsOrAnnouncement({ variables })
      handleMutationComplete(data)
    } catch (err) {
      handleErrors(err.errors)
    }
  }

  return (
    <DataFetcher loading={isLoading}>
      {redirectTo && <Redirect to={redirectTo} />}
      <Hero title={'News & Announcements'} image={HeroImage}></Hero>
      <Content>
        <form onSubmit={handleFormSubmitted}>
          <CreateAPostFormFields
            actionsComponent={
              isPersisted ? null : (
                <Filters
                  selectedFilter={currentMode}
                  filters={stringValues(Mode)}
                  setSelectedFilter={handleTabClicked}
                />
              )
            }
            category={category}
            currentMode={currentMode}
            excerpt={excerpt}
            featured={featured}
            featuredImageDataUrl={featuredImageDataUrl}
            featuredImageName={featuredImageName}
            id={id}
            position={position}
            postCategories={postCategories}
            published={published}
            sport={sport}
            sportSlugs={sportSlugs}
            title={title}
            onCategoryChanged={setCategory}
            onExcerptChanged={setExcerpt}
            onFeaturedChanged={setFeatured}
            onFeaturedImageChanged={setFeaturedImage}
            onPositionChanged={setPosition}
            onPublishedChanged={setPublished}
            onSportChanged={setSport}
            onTitleChanged={setTitle}
          />

          <PostContentFormFields
            currentMode={currentMode}
            body={body}
            posted={posted}
            onBodyChanged={setBody}
          />
        </form>
      </Content>
    </DataFetcher>
  )
}

export default NewsAndAnnouncementsInfo
