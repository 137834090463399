import React, { useContext } from 'react'
import { CheckCircle } from 'react-feather'
import { GiCrossedSwords } from 'react-icons/gi'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass'
import { useModal } from 'react-modal-hook'
import pluralize from 'pluralize'
import { styled, Theme } from '../../styles/settings/theme'

import { TeamContext } from '../../contexts/TeamContext'
import { BaseButton } from '../atoms/Buttons'
import InvitePlayer from '../organisms/InvitePlayer'
import ModalContainer from '../atoms/ModalContainer'
import { SiteSwitch } from '../../utils/sites'

const BorderBox = styled(Box)<BoxProps>`
  padding: 2px;
  --notchSize: 15px;

  clip-path: polygon(
    0% 0%,
    var(--notchSize) 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100%),
    calc(100% - var(--notchSize)) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
`

const StyledFlex = styled(Flex)<FlexProps>`
  background: white;
  --notchSize: 14px;

  clip-path: polygon(
    0% 0%,
    var(--notchSize) 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100%),
    calc(100% - var(--notchSize)) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
`

const RecruitTeamMembers: React.FC = () => {
  const { id, sportSlug, numPlayers, isLineupFull } = useContext(TeamContext)
  const [showInviteModal, hideInviteModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideInviteModal}
        style={{
          content: { maxWidth: '50rem' },
        }}
      >
        <InvitePlayer
          refetchQueries={['teamDetailPage']}
          sport={sportSlug}
          teamId={id}
          modalClose={hideInviteModal}
        />
      </ModalContainer>
    ),
    [sportSlug],
  )

  return (
    <BorderBox
      m={6}
      backgroundColor={isLineupFull ? Theme.colors.secondarygreen : Theme.colors.sand}
    >
      <StyledFlex alignItems="center" justifyContent="space-between" py={4} px={5}>
        <Flex alignItems="center">
          {isLineupFull ? (
            <CheckCircle size={38} color={Theme.colors.secondarygreen} />
          ) : (
            <GiCrossedSwords color={Theme.colors.sand} size={24} />
          )}
          <Text color={Theme.colors.darkgray} ml={4}>
            {isLineupFull ? (
              <h6>Your team is ready to play. Recruit some substitutes.</h6>
            ) : (
              <h6>
                You need {numPlayers} starting {pluralize('player', numPlayers)} for a full lineup.
              </h6>
            )}
          </Text>
        </Flex>

        <BaseButton onClick={showInviteModal} variant="secondary">
          <SiteSwitch college="Recruit Teammates" esports="Invite Teammates" />
        </BaseButton>
      </StyledFlex>
    </BorderBox>
  )
}

export default RecruitTeamMembers
