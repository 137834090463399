import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Flex, Text } from 'rebass'
import { styled } from '../../../styles/settings/theme'

import { useUpdateTeamMutation } from '../../../types/graphql'
import { errorsFullMessage, handleResult } from '../../../utils/results'
import { BaseButton } from '../../atoms/Buttons'
import { InputFieldWithErrors } from '../../molecules/InputFieldWithErrors'
import { schema } from './validations'
import { TeamContext } from '../../../contexts/TeamContext'
import EditTeamDetailFieldWrapper from '../../atoms/EditTeamDetailFieldWrapper'
import ImageUploader from '../../molecules/ImageUploader'
import TeamSettingsContainer from '../../atoms/TeamSettingsContainer'

gql`
  mutation updateTeam(
    $id: ID!
    $name: String
    $pendingLogo: String
    $approvedLogo: String
    $pendingBanner: String
    $approvedBanner: String
    $facebookUsername: String
    $twitterUsername: String
    $twitchUsername: String
  ) {
    upsertTeam(
      id: $id
      name: $name
      approvedLogo: $approvedLogo
      approvedBanner: $approvedBanner
      pendingLogo: $pendingLogo
      pendingBanner: $pendingBanner
      facebookUsername: $facebookUsername
      twitterUsername: $twitterUsername
      twitchUsername: $twitchUsername
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`

const SubmitButton = styled(BaseButton)`
  height: fit-content;
`

const EditTeamDetails: React.FC = () => {
  const { colors } = useTheme()
  const {
    name,
    setName,
    approvedLogo,
    pendingLogo,
    setPendingLogo,
    setApprovedLogo,
    approvedBanner,
    pendingBanner,
    setPendingBanner,
    setApprovedBanner,
    id,
    facebookUsername,
    twitterUsername,
    twitchUsername,
    setFacebookUsername,
    setTwitterUsername,
    setTwitchUsername,
    setTeamContextLoading,
  } = useContext(TeamContext)

  const [updateTeam, { loading }] = useUpdateTeamMutation()

  const handleFormSubmitted = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await schema.validate({ name })

      const variables = {
        pendingLogo: pendingLogo.dataUrl,
        pendingBanner: pendingBanner.dataUrl,
        approvedBanner: approvedBanner.dataUrl,
        approvedLogo: approvedLogo.dataUrl,
        facebookUsername,
        twitterUsername,
        twitchUsername,
        id,
        name,
      }

      const { data } = await updateTeam({
        variables,
        refetchQueries: ['teamDetailPage'],
      })
      handleResult({
        result: data!.upsertTeam,
        onSuccess: () =>
          toast.success('Your changes have been saved.', { containerId: 'temporary' }),
        onFailure: errors => {
          toast.error(errorsFullMessage(errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      toast.error(errorsFullMessage(err.errors), { containerId: 'temporary' })
    }
  }

  useEffect(() => {
    setTeamContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <TeamSettingsContainer py={7} px={6}>
      <Text mb={4} color={colors.darkgray}>
        <h4>Edit your team details</h4>
      </Text>
      <Text mb={5} maxWidth={640}>
        <p>
          Edit how your team is displayed on your team page, including your banner image, logo, and
          social accounts.
        </p>
      </Text>
      <form onSubmit={handleFormSubmitted}>
        <EditTeamDetailFieldWrapper width="100%" mb={6}>
          <InputFieldWithErrors
            width="100%"
            type="text"
            name="name"
            label="team name"
            placeholder="team name"
            value={name}
            updateSingleField={field => {
              setName(field)
            }}
            mb={6}
          />
        </EditTeamDetailFieldWrapper>

        <ImageUploader
          mb={6}
          imageFieldTitle="logo"
          onImageChanged={setPendingLogo}
          pending={Boolean(pendingLogo.dataUrl)}
          imageUrl={pendingLogo.dataUrl}
          imageName={pendingLogo.name}
          approvedImageData={{
            url: approvedLogo.dataUrl,
            name: approvedLogo.name,
            onChanged: setApprovedLogo,
          }}
          title="team logo"
          guidance="For best results upload a transparent PNG in a square format that is at least 100px by 100px."
        />
        <ImageUploader
          mb={6}
          imageFieldTitle="banner"
          onImageChanged={setPendingBanner}
          imageUrl={pendingBanner.dataUrl}
          imageName={pendingBanner.name}
          pending={Boolean(pendingBanner.dataUrl)}
          approvedImageData={{
            url: approvedBanner.dataUrl,
            name: approvedBanner.name,
            onChanged: setApprovedBanner,
          }}
          backgroundSize="cover"
          title="Team banner image"
          guidance="Do NOT include images with text, logos, or inappropraite material. JPG or PNG with a
            minimum width of 1400px and minimum height of 240px."
        />
        <Text mb={4} color={colors.darkgray}>
          <h4>Social Accounts</h4>
        </Text>
        <Flex
          justifyContent="space-between"
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
        >
          <EditTeamDetailFieldWrapper width="100%">
            <InputFieldWithErrors
              width="100%"
              type="text"
              name="twitter"
              label="twitter username"
              placeholder="twitter username"
              value={twitterUsername}
              updateSingleField={field => {
                setTwitterUsername(field)
              }}
              mb={6}
            />
            <InputFieldWithErrors
              width="100%"
              type="text"
              name="facebook"
              label="facebook username"
              placeholder="facebook username"
              value={facebookUsername}
              updateSingleField={field => {
                setFacebookUsername(field)
              }}
              mb={6}
            />
            <InputFieldWithErrors
              width="100%"
              type="text"
              name="twitch"
              label="twitch username"
              placeholder="twitch username"
              value={twitchUsername}
              updateSingleField={field => {
                setTwitchUsername(field)
              }}
            />
          </EditTeamDetailFieldWrapper>
          <SubmitButton variant="primary" type="submit" mt={[4, 4, 4, 0]}>
            update team information
          </SubmitButton>
        </Flex>
      </form>
    </TeamSettingsContainer>
  )
}

export default EditTeamDetails
