import React from 'react'
import { BaseButton } from '../atoms/Buttons'
import ModalContainer from '../atoms/ModalContainer'
import AsciiEmoji from '../atoms/AsciiEmoji'
import { Flex } from 'rebass'

import { useModal } from 'react-modal-hook'

interface AdminSafetySwitchProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  thisPropTriggersAReRender: number
}

export const AdminSafetySwitch: React.FC<AdminSafetySwitchProps> = ({
  onClick,
  thisPropTriggersAReRender,
  children,
}) => {
  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideConfirmModal}
        style={{
          content: { maxWidth: '50rem' },
        }}
      >
        <h1>
          Warning <AsciiEmoji>ಠ_ಠ</AsciiEmoji>
        </h1>
        <p>
          <br />
          If the action you are about to take is performed on an in progress tournament or season it
          may result in <strong>permanent and irrecoverable loss of data</strong>
          <br />
          If you ignore this warning and proceed anyways, Paul <strong>WILL</strong> be very mad
          when you ask him to fix it
        </p>

        <Flex flexDirection="column" alignItems="center">
          <BaseButton variant="primary" mt={4} onClick={onClick}>
            I accept the consequences
          </BaseButton>
          <BaseButton variant="primary" mt={4} onClick={hideConfirmModal}>
            Cancel
          </BaseButton>
        </Flex>
      </ModalContainer>
    ),
    [thisPropTriggersAReRender],
  )
  return (
    <BaseButton variant="primary" mt={4} mr={4} onClick={showConfirmModal}>
      {children}
    </BaseButton>
  )
}

export default AdminSafetySwitch
