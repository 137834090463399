import { Flex } from 'rebass'
import { styled } from '../../styles/settings/theme'

const HorizontalTableHeader = styled(Flex)`
  background-color: ${props => props.theme.colors.primarynavy};
  height: 73px;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  --notchSize: 20px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    0% 100%,
    0% 100%,
    0% 100%
  );
`

export default HorizontalTableHeader
