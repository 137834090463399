import React from 'react'
import { Flex, Text } from 'rebass'
import { isEmpty } from 'lodash'

import { Team } from '../../../types/graphql'
import { BaseButton } from '../../atoms/Buttons'
import TeamRegistryTable from './TeamRegistryTable'

interface ITeamRegistryModalProps {
  tournamentId: string
  name: string
  teams: Team[]
  isCheckin?: boolean
  onToggleOn: (team: Team) => void
  onToggleOff: (team: Team) => void
  hideModal: () => void
}

const TeamRegistryModal: React.FC<ITeamRegistryModalProps> = ({
  tournamentId,
  name,
  teams,
  isCheckin,
  onToggleOn,
  onToggleOff,
  hideModal,
}) => {
  const toggleOn = (team: Team) => {
    return isCheckin
      ? team.checkedIn
      : !!team.tournamentRegistries.find(registery => registery.tournament.id === tournamentId)
  }

  const teamsWithToggleOff = () => {
    return !isEmpty(teams.filter(team => !toggleOn(team)))
  }

  const toggleText = isCheckin ? 'checked in' : 'registered'

  return (
    <Flex py={[4, 4, 5]} px={[0, 0, 6, 7]} flexDirection="column" justifyContent="center">
      <Text mb={5} textAlign="center">
        <h2>{isCheckin ? 'check in' : 'register'} Your Teams</h2>
      </Text>
      <Text mb={6} textAlign="center">
        {isCheckin ? (
          <p>
            Are you ready to go? Please select the teams you would like to check-in for this
            tournament below. Just make sure you’re all ready when the tournament begins
          </p>
        ) : (
          <p>
            Interested in registering your teams to be a part of <b>{name}</b>? Select the teams
            from the dropdown below that you would like to register.
          </p>
        )}
      </Text>
      <TeamRegistryTable
        teams={teams}
        toggleOn={toggleOn}
        toggleOnText={toggleText}
        toggleOffText={`not ${toggleText}`}
        onToggleOn={onToggleOn}
        onToggleOff={onToggleOff}
        action={isCheckin ? 'check in' : 'register'}
      />
      <Flex mt={7}>
        <BaseButton mx="auto" variant="tertiary" backgroundColor="transparent" onClick={hideModal}>
          {teamsWithToggleOff() ? "We're not ready yet" : "We're good to go!"}
        </BaseButton>
      </Flex>
    </Flex>
  )
}

export default TeamRegistryModal
