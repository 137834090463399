import gql from 'graphql-tag'
import _, { Dictionary } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { Redirect, useParams } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'
import { oc } from 'ts-optchain'

import { useConstants } from '../../hooks/constants'
import { ClickEvent } from '../../types/aliases'
import {
  LeagueDetail_ConferenceFragment as Conference,
  LeagueDetail_RegisteredTeamFragment as RegisteredTeam,
  LeagueRegistrationStatuses,
  useLeagueDetailLazyQuery as useLeagueDetail,
  useLeagueDetailRegisterTeamForSeasonMutation as useRegisterTeamForSeason,
} from '../../types/graphql'
import { currentUserIsLoggedIn } from '../../utils/accountUtils'
import { fullWithShortMonth } from '../../utils/dateTimeFormats'
import { sportLogo } from '../../utils/logoUtils'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'
import Content from '../atoms/Content'
import DivisionLogo from '../atoms/DivisionLogo'
import ModalContainer from '../atoms/ModalContainer'
import DataFetcher from '../organisms/DataFetcher'
import LeagueHero from '../organisms/LeagueHero'
import LeagueRegistrationModal from './LeagueDetail/LeagueRegistrationModal'
import LeagueScheduleModal from './LeagueDetail/LeagueScheduleModal'
import PopulatingLeagueContent from './LeagueDetail/PopulatingLeagueContent'
import StandingsTable from './LeagueDetail/StandingsTable'
import { ITeamLike } from './LeagueDetail/types'
import UnstartedLeagueContent from './LeagueDetail/UnstartedLeagueContent'

const LEAGUE_DETAIL_CURRENT_USER_TEAM = gql`
  fragment LeagueDetail_Team on Team {
    id
    name
    sportSlug
    seasonRegistries {
      id
      season {
        id
      }
    }
    teamEnrollments {
      id
      season {
        id
      }
    }
  }
`

const LEAGUE_DETAIL_REGISTERED_TEAM_UNIVERSITY = gql`
  fragment LeagueDetail_RegisteredTeamUniversity on University {
    id
    name
  }
`

const LEAGUE_DETAIL_REGISTERED_TEAM = gql`
  fragment LeagueDetail_RegisteredTeam on Team {
    id
    name
    conferenceIds
    university {
      ...LeagueDetail_RegisteredTeamUniversity
    }
  }
  ${LEAGUE_DETAIL_REGISTERED_TEAM_UNIVERSITY}
`

const LEAGUE_DETAIL_CURRENT_USER = gql`
  fragment LeagueDetail_CurrentUser on CurrentUser {
    id
    coordinatingTeams {
      ...LeagueDetail_Team
    }
  }
  ${LEAGUE_DETAIL_CURRENT_USER_TEAM}
`

const LEAGUE_DETAIL_CONFERENCE = gql`
  fragment LeagueDetail_Conference on Conference {
    id
    name
  }
`

const LEAGUE_DETAIL_SEASON = gql`
  fragment LeagueDetail_Season on Season {
    id
    name
    teamsCount
    participantsCount
    registrationStatus
    banner
    sponsorImage
    firstMatchDate
    seasonEndDate
    seasonYear
    teamSeasonStats {
      teamId
      wins
      losses
      rank
    }
    conferences {
      ...LeagueDetail_Conference
    }
    division {
      logoUrl
    }
    sport {
      slug
    }
    registeredTeams {
      ...LeagueDetail_RegisteredTeam
    }
    enrolledTeams {
      ...LeagueDetail_RegisteredTeam
    }
  }
  ${LEAGUE_DETAIL_CURRENT_USER_TEAM}
  ${LEAGUE_DETAIL_REGISTERED_TEAM}
  ${LEAGUE_DETAIL_CONFERENCE}
`

gql`
  query LeagueDetail($id: ID!) {
    currentUser {
      ...LeagueDetail_CurrentUser
    }
    season(id: $id) {
      ...LeagueDetail_Season
    }
  }
  ${LEAGUE_DETAIL_CURRENT_USER}
  ${LEAGUE_DETAIL_SEASON}
`

gql`
  mutation LeagueDetailRegisterTeamForSeason($teamId: ID!, $seasonId: ID!) {
    registerTeamForSeason(teamId: $teamId, seasonId: $seasonId) {
      success
      value
      errors {
        field
        message
      }
    }
  }
`

const formattedLeagueStats = ({
  startDate,
  endDate,
  teamsCount,
  participantsCount,
}: {
  startDate?: Date
  endDate?: Date
  teamsCount: number
  participantsCount: number
}) => {
  const dateRange =
    startDate && endDate && `${fullWithShortMonth(startDate)} - ${fullWithShortMonth(endDate)}`
  const teamStats = `${teamsCount} Teams | ${participantsCount} Participants`
  return dateRange ? `${dateRange} | ${teamStats}` : teamStats
}

interface IMightHaveId {
  id?: string | null
}

interface IMightHaveSeason {
  season?: IMightHaveId | null
}

const seasonIds = (hasSeasons: IMightHaveSeason[]) => {
  return _.compact(hasSeasons.map(thing => oc(thing).season.id()))
}

// A user can register if any of the teams they're coordinating are for the same sport as
// the current league. Teams that are already registered don't count
const leagueRelevant = (teams: ITeamLike[], sportSlug: string, leagueId: string) => {
  const teamIsLeagueRelevant = (team: ITeamLike) => {
    const teamEnrollmentSeasonIds = seasonIds(team.teamEnrollments)
    const seasonRegistrySeasonIds = seasonIds(team.seasonRegistries)
    const allSeasonIds = [...teamEnrollmentSeasonIds, ...seasonRegistrySeasonIds]

    const alreadyInLeague = _.includes(allSeasonIds, leagueId)
    const sportMatches = team.sportSlug === sportSlug

    return !alreadyInLeague && sportMatches
  }

  return _.filter(teams, teamIsLeagueRelevant)
}

const currentUserCanRegister = ({ leagueRelevantTeams }: { leagueRelevantTeams: ITeamLike[] }) => {
  return leagueRelevantTeams.length > 0
}

interface IRouteParams {
  leagueId: string
}

const LeagueDetail: React.FC = () => {
  const params = useParams<IRouteParams>()
  const id = params.leagueId

  const constants = useConstants()
  const currentSeasonYear = oc(constants).currentSeasonYear('')

  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState<Date>()
  const [regStatus, setRegStatus] = useState<LeagueRegistrationStatuses>()

  const [endDate, setEndDate] = useState<Date>()
  const [teamsCount, setTeamsCount] = useState<number>(0)
  const [participantsCount, setParticipantsCount] = useState<number>(0)
  const [divisionLogo, setDivisionLogo] = useState<string>()
  const [leagueRelevantTeams, setLeagueRelevantTeams] = useState<ITeamLike[]>([])
  const [leagueSportSlug, setLeagueSportSlug] = useState<string>()
  const [registeredTeams, setRegisteredTeams] = useState<RegisteredTeam[]>([])
  const [redirectTo, setRedirectTo] = useState<string>()
  const [banner, setBanner] = useState<string>()
  const [sponsorImage, setSponsorImage] = useState<string>()
  const [seasonYear, setSeasonYear] = useState<string>()

  const [seasonQuery, { error, loading }] = useLeagueDetail({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const season = data && data.season
      const currentUser = data && data.currentUser
      if (!season) {
        return
      }

      season.name && setName(season.name)
      season.firstMatchDate && setStartDate(season.firstMatchDate)
      season.seasonEndDate && setEndDate(season.seasonEndDate)
      season.registrationStatus && setRegStatus(season.registrationStatus)
      season.banner && setBanner(season.banner)
      season.sponsorImage && setSponsorImage(season.sponsorImage)
      season.seasonYear && setSeasonYear(season.seasonYear)
      setTeamsCount(season.teamsCount)
      setParticipantsCount(season.participantsCount)
      season.division && season.division.logoUrl && setDivisionLogo(season.division.logoUrl)

      let registeredTeams
      if (!_.isEmpty(season.registeredTeams)) {
        registeredTeams = season.registeredTeams
      } else if (!_.isEmpty(season.enrolledTeams)) {
        registeredTeams = season.enrolledTeams
      }

      const conferencesMap = season.conferences.reduce(
        (acc: Dictionary<string>, curr: Conference) => {
          acc[curr.id!] = curr.name
          return acc
        },
        {},
      )

      setRegisteredTeams(
        _.uniqBy(registeredTeams, 'id').map(function(t) {
          const selectedSeasonStats = season.teamSeasonStats.find(
            s => String(s.teamId) === t.id,
          ) || {
            wins: 0,
            losses: 0,
          }
          const conferenceId = t.conferenceIds.find(id => conferencesMap[id])
          const selectedSeasonConferenceName = conferenceId && conferencesMap[conferenceId]
          return { ...t, selectedSeasonStats, selectedSeasonConferenceName }
        }),
      )

      if (!season.sport || !season.sport.slug) {
        return
      }
      const leagueSportSlug = season.sport.slug

      setLeagueSportSlug(leagueSportSlug)

      if (!currentUser) {
        return
      }
      currentUser.coordinatingTeams &&
        setLeagueRelevantTeams(leagueRelevant(currentUser.coordinatingTeams, leagueSportSlug, id))
    },
  })
  if (error) {
    console.error(error)
  }

  const teamsGroupedByConference = _.groupBy(registeredTeams, 'selectedSeasonConferenceName')

  const [registerTeamForSeason] = useRegisterTeamForSeason()

  const [showRegistrationModal, hideRegistrationModal] = useModal(
    () => (
      <ModalContainer hideModal={hideRegistrationModal}>
        <LeagueRegistrationModal
          teams={leagueRelevantTeams}
          leagueName={name}
          leagueId={id}
          registerTeamForSeason={registerTeamForSeason}
        />
      </ModalContainer>
    ),
    [leagueRelevantTeams],
  )

  const [showScheduleModal, hideScheduleModal] = useModal(
    () => (
      <ModalContainer hideModal={hideScheduleModal}>
        <LeagueScheduleModal leagueId={id} />
      </ModalContainer>
    ),
    [id],
  )

  const handleLeagueRegisterClicked = (e: ClickEvent) => {
    e.preventDefault()
    showRegistrationModal()
  }

  const handleSignUpClicked = (e: ClickEvent) => {
    e.preventDefault()
    setRedirectTo(paths.signup())
  }

  const handleLogInClicked = (e: ClickEvent) => {
    e.preventDefault()
    setRedirectTo(paths.logIn())
  }

  useEffect(() => {
    if (!id) {
      return
    }
    seasonQuery({ variables: { id } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const registrationIsOpen = () => {
    return regStatus === LeagueRegistrationStatuses.Enrolling
  }

  const unstartedLeague = () => {
    return regStatus === LeagueRegistrationStatuses.Unstarted
  }

  const leaguePopulating = () => {
    return regStatus === LeagueRegistrationStatuses.Populating
  }

  const allowRegistration = currentUserCanRegister({ leagueRelevantTeams }) && registrationIsOpen()
  const sportIcon = leagueSportSlug && sportLogo(leagueSportSlug)

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }
  return (
    <DataFetcher error={error} loading={loading}>
      {redirectTo && <Redirect to={redirectTo} />}
      <LeagueHero
        title={name}
        subTitle={formattedLeagueStats({ startDate, endDate, teamsCount, participantsCount })}
        divisionLogo={divisionLogo && <DivisionLogo src={divisionLogo} />}
        sportIcon={sportIcon}
        sponsorImage={sponsorImage}
        banner={banner}
        register={
          allowRegistration && (
            <BaseButton onClick={handleLeagueRegisterClicked}>Register for League</BaseButton>
          )
        }
        isCurrentSeason={seasonYear === currentSeasonYear}
      />
      <Content>
        {/*TODO: render different content based on the league status */}
        {unstartedLeague() && (
          <UnstartedLeagueContent
            allowRegistration={allowRegistration}
            onRegisterClick={handleLeagueRegisterClicked}
            startDate={startDate}
          >
            {!currentUserIsLoggedIn() && (
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
              >
                <BaseButton onClick={handleSignUpClicked}>Sign Up</BaseButton>
                &nbsp;&nbsp;&nbsp;
                <BaseButton variant="secondary" onClick={handleLogInClicked}>
                  Log In
                </BaseButton>
              </Animation>
            )}
          </UnstartedLeagueContent>
        )}

        {leaguePopulating() && <PopulatingLeagueContent />}

        {!_.isEmpty(registeredTeams) &&
          Object.keys(teamsGroupedByConference)
            .sort()
            .map(confKey => (
              <div key={confKey}>
                <Flex mt={7} width={'auto'} justifyContent="space-between" alignItems="center">
                  <Box width={1}>
                    <Animation
                      initial="hidden"
                      animate="visible"
                      variants={animations}
                      transition={{ ease: 'easeOut', duration: 0.4 }}
                    >
                      <Text>
                        <h2>{confKey} Standings</h2>
                      </Text>
                    </Animation>
                  </Box>
                  <Box minWidth="max-content" pb={[2]}>
                    <BaseButton variant="secondary" onClick={() => showScheduleModal()}>
                      View Schedule
                    </BaseButton>
                  </Box>
                </Flex>
                <Box mt={2} width={1}>
                  <Animation
                    initial="hidden"
                    animate="visible"
                    variants={animations}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                  >
                    <StandingsTable teams={teamsGroupedByConference[confKey]} />
                  </Animation>
                </Box>
              </div>
            ))}
      </Content>
    </DataFetcher>
  )
}

export default LeagueDetail
