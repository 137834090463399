import React from 'react'
import { Flex } from 'rebass'
import { Round, LowerLine } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getTuples } from '../../utils/bracketDataUtils'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const Round3ALosers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)

  const seats = getTuples(halfSeats)
  //needs 'lower' to make a wider round
  return (
    <Round round="3A" shift={shift} top={bottomHalf ? '78px' : '0px'} numTeams={numTeams}>
      {seats.map(tuple => (
        <Flex mb={'42px'}>
          <BracketCouplet seats={tuple} round="3A" bottom={'86px'} numTeams={numTeams} />
          <LowerLine round="3A" numTeams={numTeams} bottomHalf={bottomHalf} />
        </Flex>
      ))}
    </Round>
  )
}

export default Round3ALosers
