import gql from 'graphql-tag'
import { Text, Flex, Box } from 'rebass'
import { Redirect } from 'react-router-dom'
import React, { useState } from 'react'

import { handleResult, errorOn } from '../../utils/results'
import { useResetPasswordMutation } from '../../types/graphql'
import { AuthFormContent, AuthFormBox, StyledInput } from '../atoms/AuthFormPieces'
import { BaseButton } from '../atoms/Buttons'
import LoadingSpinner from '../atoms/LoadingSpinner'
import SectionTitle from '../atoms/SectionTitle'

gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface ResetPasswordProps {
  token: string
}

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({ token }) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [formError, setFormError] = useState('')
  const [redirect, setRedirect] = useState(false)

  const [submitForm, { loading }] = useResetPasswordMutation({
    onError: error => {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        setFormError(error.graphQLErrors[0].message)
      } else {
        console.log(error)
      }
    },
    onCompleted: data => {
      handleResult({
        result: data.resetPassword,
        onSuccess: () => setRedirect(true),
        onFailure: errors => {
          setFormError(errorOn('password', errors))
        },
      })
    },
  })

  const handleOnFocus = () => {
    setFormError('')
  }

  const validatePasswordConfirmation = (event: React.SyntheticEvent) => {
    const value = (event as any).target.value
    value === password ? setFormError('') : setFormError('Passwords do not match.')
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    submitForm({
      variables: {
        password,
        token,
      },
    })
  }

  const isButtonDisabled = loading || !!formError || password === '' || passwordConfirmation === ''

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/logIn',
          state: {
            message: 'Your password has been reset. Please login with your new password.',
          },
        }}
      />
    )
  }

  return (
    <AuthFormContent onSubmit={handleSubmit}>
      <AuthFormBox>
        <SectionTitle text="Reset Password" align="center" mb=".75rem" />
        <Text textAlign="center">
          Enter your new password below. Remember passwords must be 8 or more characters.
        </Text>
        <Box width={[1, 1, 1, 1 / 2]} margin="auto">
          <StyledInput
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onFocus={handleOnFocus}
          />
          <StyledInput
            type="password"
            name="password"
            placeholder="Confirm Password"
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
            onBlur={validatePasswordConfirmation}
            onFocus={handleOnFocus}
          />
        </Box>

        {formError && (
          <Text textAlign="center" color="red" mt={2}>
            {formError}
          </Text>
        )}
        <br />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Flex width="auto" mt={4} justifyContent="center">
            <Box width="auto">
              <BaseButton
                variant={isButtonDisabled ? 'primaryDisabled' : 'primary'}
                disabled={isButtonDisabled}
                type="submit"
              >
                Reset Password
              </BaseButton>
            </Box>
          </Flex>
        )}
      </AuthFormBox>
    </AuthFormContent>
  )
}

export default ResetPassword
