import gql from 'graphql-tag'
import React, { useEffect, useReducer, useState } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { oc } from 'ts-optchain'
import { styled } from '../../../styles/settings/theme'

import { Game, Map, Team } from '../../../types/graphql'
import { rawNumPlayers } from '../../../utils/sportUtils'
import { numToString } from '../../../utils/typeCoercions'
import { BaseButton } from '../../atoms/Buttons'
import LoadingSpinner from '../../atoms/LoadingSpinner'
import { CustomSelect } from '../../atoms/Select'
import SportLogo from '../../atoms/SportLogo'
import SmashReportingTable from './SmashReportingTable'
import { GameBoxReportEvidence, useEvidence } from './evidence'
import { currentUserIsAdmin } from '../../../utils/admins'

export const GET_SMASH_CHARACTERS = gql`
  query SmashCharacters {
    sportPlayerCharacteristics(smashCharacters: true) {
      smashCharacters {
        options
      }
    }
  }
`

export const REPORT_SMASH_GAME_MUTATION = gql`
  mutation ReportSmashGame(
    $gameId: ID!
    $homeTeam: UserReportedGame!
    $awayTeam: UserReportedGame!
    $winningTeamId: ID!
    $mapId: ID!
    $evidenceScreenshot: String!
  ) {
    reportSmashGame(
      gameId: $gameId
      homeTeam: $homeTeam
      awayTeam: $awayTeam
      winningTeamId: $winningTeamId
      mapId: $mapId
      evidenceScreenshot: $evidenceScreenshot
    ) {
      match {
        id
        settled
      }
    }
  }
`

interface IHasStocksRemaining {
  stocksRemaining: number
}

interface IGameProps {
  game: Game
  homeTeam: Team
  awayTeam: Team
  toOpen: any
  updateLoading: (loading: boolean) => void
  report: number
  index: number
  reportable: boolean
  maps: [Map] | null
  settled: boolean
}
const Circle = styled(Box)<BoxProps>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`
const RowBox = styled(Flex)<FlexProps>`
  flex-direction: column;
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  padding: 2rem 1.5rem;
  margin-top: 1.25rem;
`
const Reporting = styled(Flex)<FlexProps>`
  border-top: 2px solid ${props => props.theme.colors.lightgray};
`
interface IAction {
  payload: { index: number; name: string; character: string; stocksRemaining: number }
}

const teamReducer = (state: any, action: IAction) => {
  return {
    ...state,
    [action.payload.index]: {
      name: action.payload.name,
      character: action.payload.character,
      stocksRemaining: action.payload.stocksRemaining,
    },
  }
}

const SmashGameBox: React.FC<IGameProps> = ({
  game,
  homeTeam,
  awayTeam,
  toOpen,
  report,
  index,
  maps,
  reportable,
  updateLoading,
  settled,
}) => {
  const [ready, setReady] = useState(false)
  const [winningTeamId, setWinner] = useState()
  const [smashCharacters, setSmashCharacters] = useState([])
  const [mapName, setMapName] = useState(oc(maps)[0].name(''))
  const { evidenceScreenshotUrl, evidenceScreenshotName, handleEvidenceChanged } = useEvidence()

  const activeBox = report === index

  useQuery(GET_SMASH_CHARACTERS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setSmashCharacters(data.sportPlayerCharacteristics.smashCharacters.options)
    },
  })

  const [reportGame, { loading }] = useMutation(REPORT_SMASH_GAME_MUTATION, {
    refetchQueries: ['matchDetailPage'],
    onError(error) {
      console.error(error)
    },
    onCompleted(data) {
      if (data.reportSmashGame.match.settled) {
        toast.success(
          "Thank you for your submission. We're processing it now and brackets will be updated within 5 minutes",
          { containerId: 'temporary' },
        )
      } else {
        toast.success('Thank you for your submission!', { containerId: 'temporary' })
      }
      changeOpenIndex(-1)
      updateLoading(false)
    },
  })

  // Fixes race condition where initial state is
  // set before smash characters are loaded +
  // user doesn't select anything from the dropdown
  useEffect(() => {
    const fakeEvent = {
      target: {
        name: 'character',
        value: smashCharacters[0],
      },
    }
    handleLineupChange(fakeEvent, true, 0)
    handleLineupChange(fakeEvent, false, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smashCharacters])

  useEffect(() => {
    const isReadyForSubmit = () => {
      return winningTeamId && evidenceScreenshotUrl
    }

    setReady(isReadyForSubmit())
    if (activeBox) {
      updateLoading(loading)
    }
  }, [winningTeamId, activeBox, loading, updateLoading, evidenceScreenshotUrl])

  const numPlayers = Number(rawNumPlayers(homeTeam.sportSlug))

  //create initial state object
  const results = (team: any) => {
    return {
      ...[...Array(numPlayers)].map((_el, i) => {
        const character = team.activeRosterEntries[i]
          ? team.activeRosterEntries[i].player.characterName || smashCharacters[i]
          : ''

        return {
          name: team.activeRosterEntries[i] ? team.activeRosterEntries[i].player.username : '',
          character,
          stocksRemaining: 0,
        }
      }),
    }
  }

  const [homeTeamState, homeDispatch] = useReducer(teamReducer, results(homeTeam))
  const [awayTeamState, awayDispatch] = useReducer(teamReducer, results(awayTeam))

  const handleLineupChange = (e: any, isHomeTeam: any, i: any) => {
    const payload = {
      ...(isHomeTeam ? homeTeamState[i] : awayTeamState[i]),
      index: i,
      [e.target.name]: e.target.value,
    }

    isHomeTeam ? homeDispatch({ payload }) : awayDispatch({ payload })
  }

  // const handleEvidenceChanged = ({ name, dataUrl }: IImageFields) => {
  //   setEvidenceScreenshotUrl(dataUrl)
  //   setEvidenceScreenshotName(name)
  // }

  const playerLookupId =
    homeTeam && homeTeam.activeRosterEntries && awayTeam && awayTeam.activeRosterEntries
      ? homeTeam.activeRosterEntries
          .concat(awayTeam.activeRosterEntries)
          .map((entry: any) => {
            return { name: entry.player.username, id: entry.player.id }
          })
          .reduce((obj: any, item: any) => {
            obj[item.name] = item.id
            return obj
          }, {})
      : null

  const mapsPresent = maps && maps.length > 0

  const mapLookupId =
    maps &&
    mapsPresent &&
    maps.reduce((obj: any, item: any) => {
      obj[item.name] = item.id
      return obj
    }, {})

  const changeOpenIndex = (index: number) => {
    toOpen(index)
  }

  const teamStocksRemaining = (teamState: IHasStocksRemaining[]) => {
    return _.sum(_.map(teamState, 'stocksRemaining'))
  }

  const isZero = (num: number) => {
    return num === 0
  }

  const anyAreZero = (nums: number[]) => {
    return _.some(nums, isZero)
  }

  const allAreZero = (nums: number[]) => {
    return _.every(nums, isZero)
  }

  const checkData = () => {
    //checks for duplicate names
    const homeNameObj: { [key: string]: boolean } = {}
    for (let key in homeTeamState) {
      if (!homeNameObj[homeTeamState[key].name]) {
        homeNameObj[homeTeamState[key].name] = true
      } else {
        toast.error(
          `You have entered duplicate players on ${homeTeam.name}. If there are not ${numPlayers} unique players on this team, the roster must be edited on the team's page before reporting this game.`,
          { containerId: 'temporary' },
        )
        return false
      }
    }
    const awayNameObj: { [key: string]: boolean } = {}
    for (let key in awayTeamState) {
      if (!awayNameObj[awayTeamState[key].name]) {
        awayNameObj[awayTeamState[key].name] = true
      } else {
        toast.error(
          `You have entered duplicate players on ${awayTeam.name}. If there are not ${numPlayers} unique players on this team, the roster must be edited on the team's page before reporting this game.`,
          { containerId: 'temporary' },
        )
        return false
      }
    }

    const homeTeamStocksRemaining = teamStocksRemaining(homeTeamState)
    const awayTeamStocksRemaining = teamStocksRemaining(awayTeamState)
    if (!anyAreZero([homeTeamStocksRemaining, awayTeamStocksRemaining])) {
      toast.error('One team must have 0 stocks remaining', { containerId: 'temporary' })
      return false
    }
    if (allAreZero([homeTeamStocksRemaining, awayTeamStocksRemaining])) {
      toast.error('One team must have >0 stocks remaining', { containerId: 'temporary' })
      return false
    }

    return true
  }

  const handleSubmit = () => {
    const validData = checkData()
    if (validData) {
      const gamePayload = {
        id: game.id,
        homeTeam: {
          score: winningTeamId === homeTeam.id ? 1 : 0,
          userSubmittedLineup: Object.values(homeTeamState).map((entry: any) => {
            return {
              playerId: playerLookupId[entry.name],
              smashCharacter: entry.character,
              smashStocksRemaining: Number(entry.stocksRemaining),
            }
          }),
        },
        awayTeam: {
          score: winningTeamId === awayTeam.id ? 1 : 0,
          userSubmittedLineup: Object.values(awayTeamState).map((entry: any) => {
            return {
              playerId: playerLookupId[entry.name],
              smashCharacter: entry.character,
              smashStocksRemaining: Number(entry.stocksRemaining),
            }
          }),
        },
      }
      if (!loading) {
        reportGame({
          variables: {
            gameId: gamePayload.id,
            homeTeam: gamePayload.homeTeam,
            awayTeam: gamePayload.awayTeam,
            winningTeamId: winningTeamId,
            mapId: mapLookupId && mapName ? mapLookupId[mapName] : '',
            evidenceScreenshot: evidenceScreenshotUrl, // is a  Base64 encoded data URL
          },
        })
      }
    }
  }

  return (
    <RowBox>
      {/* top row */}
      <Flex justifyContent="space-between" flexDirection={['column', 'column', 'row']}>
        <Flex alignItems="center" mb={[2, 2, 0]}>
          <Box mr={4}>
            <SportLogo sport={homeTeam.sportSlug} width="2rem" height="2rem" />
          </Box>
          <Text color="darkgray">
            <h3>Game {numToString(index + 1)}</h3>
          </Text>
          {game.winner && game.winner.team && (
            <Text color="green" ml={5} mt={'2px'}>
              <h5>{game.winner.team.truncatedName}</h5>
            </Text>
          )}
        </Flex>

        <Flex
          alignItems={['flex-start', 'flex-start', 'center']}
          width={[1, 1, 1 / 2]}
          justifyContent="flex-end"
          flexDirection={['column', 'column', 'row']}
        >
          {loading ? (
            <LoadingSpinner />
          ) : report !== index ? (
            <Flex mr={5} mb={[2, 2, 0]} alignItems="center">
              <Circle bg={game.winner ? 'green' : !settled ? 'red' : 'middlegray'} mr={2} />
              <Text color="darkgray" mt={'2px'}>
                <h6>
                  {game.winner
                    ? 'game reported'
                    : reportable
                    ? 'game unreported'
                    : settled
                    ? 'game not needed'
                    : 'report previous game'}
                </h6>
              </Text>
            </Flex>
          ) : maps && mapsPresent ? (
            <Box width={1 / 2} mb={[2, 2, 0]}>
              <CustomSelect
                key={index}
                value={mapName}
                onChange={e => setMapName(e.target.value)}
                mr={4}
              >
                {maps.map(item => (
                  <option key={item.id}>{item.name}</option>
                ))}
              </CustomSelect>
            </Box>
          ) : null}
          {((!game.winner && !loading && reportable && !settled) || currentUserIsAdmin()) && (
            <BaseButton
              variant={activeBox && !ready ? 'primaryDisabled' : 'primary'}
              disabled={(activeBox && !ready) || loading}
              onClick={activeBox ? handleSubmit : () => changeOpenIndex(index)}
            >
              {activeBox ? 'submit game report' : 'report game'}
            </BaseButton>
          )}
        </Flex>
      </Flex>
      {/* reporting content */}
      {activeBox && (
        <Reporting mt={5} flexWrap="wrap" mx={[0, 0, 0, -2]} p={1}>
          <SmashReportingTable
            isHomeTeam={true}
            team={homeTeam}
            handleChange={handleLineupChange}
            smashCharacters={smashCharacters}
            teamResults={homeTeamState}
            isWinningTeam={winningTeamId === homeTeam.id}
            setWinner={setWinner}
          />

          <SmashReportingTable
            isHomeTeam={false}
            team={awayTeam}
            handleChange={handleLineupChange}
            smashCharacters={smashCharacters}
            teamResults={awayTeamState}
            isWinningTeam={winningTeamId === awayTeam.id}
            setWinner={setWinner}
          />

          <GameBoxReportEvidence
            imageName={evidenceScreenshotName}
            imageUrl={evidenceScreenshotUrl}
            onImageChanged={handleEvidenceChanged}
          />
        </Reporting>
      )}
    </RowBox>
  )
}

export default SmashGameBox
