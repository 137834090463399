import { isEmpty } from 'lodash'
import { Sport, RosterEntry } from '../types/graphql'

// TODO: refactor callsites to use useConstants hook
export const allSportSlugs = ['fortnite', 'nhl', 'rl']

export function usesRoles(sportId: string) {
  return Object.keys(rolesMap).includes(sportId)
}

//playersTable displays the value for characterInGameName in place of role for these sports
export function displaysCharactersAsRoles(sportId: string) {
  return ['mtga', 'nba', 'nhl', 'sfv', 'valorant'].includes(sportId)
}

export const categoryName: { [key: string]: string } = {
  csgo: 'role',
  dota2: 'hero',
  hs: 'class',
  madden: 'team',
  mtga: 'color',
  nba: 'team',
  nhl: 'team',
  ow: 'hero',
  sc2: 'race',
  sfv: 'fighter',
  smash: 'character',
  valorant: 'agent',
  vg: 'hero',
}

const rolesMap: { [key: string]: Array<string> } = {
  csgo: ['IGL', 'Primary AWP', 'Entry Fragger', 'Support', 'Secondary AWP', 'Lurker'],
  dota2: ['1', '2', '3', '4', '5'],
  lol: ['Top', 'Jungle', 'Mid', 'AD Carry', 'Support'],
}

export function roles(sport: string) {
  return rolesMap[sport]
}

export function prettyName(sport: string) {
  const pretty: { [key: string]: string } = {
    csgo: 'CS:GO',
    dota2: 'Dota 2',
    fortnite: 'Fortnite',
    gears: 'Gears of War',
    hs: 'Hearthstone',
    lol: 'League of Legends',
    madden: 'Madden',
    mtga: 'Magic: The Gathering Arena',
    nba: 'NBA 2K',
    nhl: 'NHL',
    ow: 'Overwatch',
    rl: 'Rocket League',
    sc2: 'Starcraft 2',
    sfv: 'Street Fighter V',
    smash: 'Smash Bros',
    valorant: 'Valorant',
  }
  return pretty[sport]
}

export function shortName(sport: string) {
  const short: { [key: string]: string } = {
    csgo: 'CS:GO',
    dota2: 'Dota 2',
    fortnite: 'fortnite',
    gears: 'gears',
    hs: 'hs',
    lol: 'lol',
    madden: 'madden',
    mtga: 'mtga',
    nba: 'nba 2k',
    nhl: 'nhl',
    ow: 'ow',
    rl: 'rl',
    sc2: 'sc2',
    sfv: 'sfv',
    smash: 'smash',
    valorant: 'VALORANT',
  }
  return short[sport]
}

//default number of players per game
//Todo refactor this to take in a ??? and return it aka
//stop using static values
export function rawNumPlayers(sport: string | null | undefined) {
  const rosterNum: { [key: string]: string } = {
    csgo: '5',
    dota2: '5',
    fortnite: '2',
    lol: '5',
    madden: '1',
    mtga: '3',
    nba: '1',
    nhl: '1',
    rl: '3',
    sfv: '1',
    smash: '1',
    valorant: '5',
    ow: '6',
  }
  return rosterNum[String(sport)]
}

export const MtgaColors = ['black', 'blue', 'colorless', 'green', 'multi-color', 'red', 'white']

export function playerDefaultSport(playedSports: Sport[], rosterEntries: RosterEntry[]) {
  const lolPlayedSport = playedSports.find((sport: Sport) => sport.slug === 'lol')
  let defaultSport = playedSports[0].slug

  const playerOnCurrentSportTeam = rosterEntries.find(
    (entry: RosterEntry) => entry.team.sportSlug === defaultSport,
  )

  if (isEmpty(rosterEntries) && lolPlayedSport) {
    defaultSport = lolPlayedSport.slug
  } else if (!isEmpty(rosterEntries) && !playerOnCurrentSportTeam) {
    defaultSport = rosterEntries[0].team.sportSlug
  }

  return defaultSport as string
}
