import React, { useState } from 'react'
import { Flex, FlexProps } from 'rebass'
import { ChevronDown } from 'react-feather'
import { AnimatePresence } from 'framer-motion'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import BackButton from '../atoms/BackButton'
import SeasonsMenu from '../molecules/SeasonsMenu'

interface BaseButtonProps {
  backgroundDark?: boolean
  className?: string
}

interface ISeasonsToggleProps extends BaseButtonProps {
  closeMenu?: () => void
  changeYear: (arg0: string) => void
  selectedYear: string
  isCurrentSeason: boolean
}

const BackButtonWrapper = styled(Flex)<FlexProps>`
  position: absolute;
  top: 6rem;
  left: 0;
  z-index: 2;

  h6 {
    display: none;
  }

  ${Mq.xs} {
    h6 {
      display: block;
    }
  }

  ${Mq.md} {
    top: 40px;
  }
`

const SeasonsToggleWrapper = styled(Flex)<FlexProps>`
  position: relative;
  z-index: 2;
  margin-top: 1rem;
  width: max-content;
  ${Mq.md} {
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 0;
  }
`

const StyledBaseButton = styled(BaseButton)<BaseButtonProps>`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${props =>
    props.backgroundDark ? props.theme.colors.white : props.theme.colors.primarynavy};

  svg {
    margin-left: 1rem;
    margin-top: -0.3rem;
  }

  &.clicked {
    border-color: ${props => props.theme.colors.sand};
  }
`

const SeasonsToggle: React.FC<ISeasonsToggleProps> = ({
  backgroundDark,
  selectedYear,
  changeYear,
  isCurrentSeason,
}) => {
  const [menu, showMenu] = useState(false)
  const animations = {
    hidden: {
      y: '-24px',
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  // TODO: Replace with real seasons and allow scoping of page
  const seasons = [
    '2019-2020',
    '2018-2019',
    '2017-2018',
    '2016-2017',
    '2015-2016',
    '2014-2015',
    '2013-2014',
    '2012-2013',
    '2011-2012',
    '2010-2011',
  ]

  return (
    <>
      {!isCurrentSeason && (
        <BackButtonWrapper>
          <Animation
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.6 }}
          >
            <BackButton
              onClick={() => window.location.reload()}
              buttonColor="white"
              textColor="teal"
              text="back to current season"
            />
          </Animation>
        </BackButtonWrapper>
      )}

      <SeasonsToggleWrapper>
        <Animation
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.6 }}
        >
          <StyledBaseButton
            variant="secondary"
            onClick={() => showMenu(!menu)}
            className={menu ? 'clicked' : undefined}
            backgroundDark={backgroundDark}
          >
            {isCurrentSeason ? 'View Past Seasons ' : `${selectedYear.replace('-', ' - ')} season `}
            <ChevronDown />
          </StyledBaseButton>
        </Animation>

        <AnimatePresence>
          {menu && (
            <SeasonsMenu
              backgroundDark={backgroundDark}
              onSelectSeason={changeYear}
              seasons={seasons}
            />
          )}
        </AnimatePresence>
      </SeasonsToggleWrapper>
    </>
  )
}

export default SeasonsToggle
