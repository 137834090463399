import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Flex } from 'rebass'
import ReactCookieConsent from 'react-cookie-consent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { TiChevronRightOutline } from 'react-icons/ti'
import { Theme, styled, MediaQueries as Mq } from '../../styles/settings/theme'

import { paths, externalPaths } from '../../utils/Routes'
import Animation from '../atoms/Animation'

interface IMediaQueryProps {
  query?: any
}

const CookieJar = styled(Flex)`
  a {
    color: ${props => props.theme.colors.primarylightblue};
    transition: 0.2s ease-in-out;
    &:hover {
      color: ${props => props.theme.colors.teal};
    }
  }

  p {
    padding: 0.25rem 0;
    ${Mq.md} {
      max-width: 90%;
    }
    ${Mq.xl} {
      max-width: 780px;
    }
  }

  svg {
    margin-bottom: -4px;
  }
`

const CookieConsent: React.FC<IMediaQueryProps> = () => {
  const [redirectTo, setRedirectTo] = useState<string>()
  const matches = useMediaQuery('(max-width:1000px)')

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    invisible: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  const customStyles = {
    background: 'rgba(0,0,0,0.9)',
    fontFamily: Theme.fonts.body,
    paddingLeft: matches ? 'calc(1rem - 15px)' : 'calc(4rem - 15px)',
    paddingRight: matches ? 'calc(1rem - 15px)' : 'calc(4rem - 15px)',
    alignItems: 'center',

    buttons: {
      accept: {
        color: Theme.colors.primarynavy,
        background: Theme.colors.primarylightblue,
        fontFamily: Theme.fonts.condensed,
        fontSize: '1rem',
        letterSpacing: 1,
        textTransform: 'uppercase',
        padding: '10px 1.5rem 8px',
      },
      decline: {
        color: Theme.colors.white,
        background: Theme.colors.red,
        fontFamily: Theme.fonts.condensed,
        fontSize: '1rem',
        letterSpacing: 1,
        textTransform: 'uppercase',
        padding: '10px 1.5rem 8px',
      },
    },
  }

  useEffect(() => {
    if (!redirectTo) {
      return
    }

    window.location.href = redirectTo
  }, [redirectTo])

  return (
    <CookieJar>
      <ReactCookieConsent
        location="bottom"
        enableDeclineButton
        setDeclineCookie={false}
        buttonText="Accept"
        declineButtonText="Decline"
        onDecline={() => setRedirectTo(externalPaths.acceptTermsForBrands())}
        cookieName="cookie-consent"
        declineButtonStyle={customStyles.buttons.decline}
        buttonStyle={customStyles.buttons.accept}
        style={customStyles}
        sameSite={'lax'}
      >
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
        >
          <p className="small">
            We use cookies to improve your browsing experience and show you personalized content. To
            find out more, read our <Link to={paths.privacyPolicy()}>privacy policy</Link>. To
            consent, please click the accept button.{' '}
            <TiChevronRightOutline color={Theme.colors.primarylightblue} />
          </p>
        </Animation>
      </ReactCookieConsent>
    </CookieJar>
  )
}

export default CookieConsent
