import { css } from '@emotion/core'
import { startCase } from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { MediaQueries as Mq, styled } from '../../../styles/settings/theme'
import StyledLink from '../../atoms/StyledLink'

export type INavListItem = { path?: string; label?: string; hidden?: boolean; onClick?: () => void }

interface INavListProps {
  listItems: INavListItem[]
  flexDirection: string
  textAlign?: string
  mainMenu?: boolean
}

interface INavListWrapperProps {
  flexDirection: string
  textAlign?: string
}

const NavListWrapper = styled.ul<INavListWrapperProps>`
  display: flex;

  ${props =>
    props.flexDirection === 'column' &&
    css`
      flex-direction: column;
      li {
        margin-bottom: ${props.theme.space[4]}px;
        color: inherit;
      }

      li:last-of-type {
        margin-bottom: 0;
      }
    `}

  ${props =>
    props.flexDirection === 'row' &&
    css`
      flex-direction: row;
      li {
        font-family: ${props.theme.fonts.condensed};
        margin: 0 1em 0 1em;
        color: inherit;
      }
    `}

  li {
    text-align: ${props => props.textAlign || 'left'};
  }

  a {
    font-family: ${props => props.theme.fonts.condensed};
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    transition: ${props => props.theme.transitions.fast};
    &:hover {
      color: ${props => props.theme.colors.primaryblue};
    }

    h5 {
      padding-left: 0;
      transition: ${props => props.theme.transitions.fast};
      ${Mq.md} {
        padding-left: 1rem;
      }
      &:hover {
        color: ${props => props.theme.colors.primaryblue};
      }
    }
  }
  a.active {
    color: ${props => props.theme.colors.primaryblue};
  }
`

const NavList: React.FC<INavListProps> = ({ listItems, flexDirection, textAlign, mainMenu }) => {
  return (
    <NavListWrapper flexDirection={flexDirection} textAlign={textAlign}>
      {listItems
        .filter(item => !item.hidden)
        .map(item => (
          <li key={item.label || startCase(item.path)}>
            {item.path ? (
              <NavLink activeClassName="active" to={item.path}>
                {item.label || startCase(item.path)}
              </NavLink>
            ) : item.onClick ? (
              <StyledLink onClick={item.onClick}>{item.label}</StyledLink>
            ) : null}
          </li>
        ))}
    </NavListWrapper>
  )
}

export default NavList
