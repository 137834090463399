import React from 'react'
import gql from 'graphql-tag'
import { useTheme } from 'emotion-theming'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { useNewsBriefQuery } from '../../types/graphql'
import { justDateFrom, MoDDoYY } from '../../utils/dateTimeFormats'
import { NewsBrief_NewsArticleFragment as NewsArticle } from '../../types/graphql'
import { paths } from '../../utils/Routes'
import defaultImage from '../../assets/images/news__placeholder.jpg'
import Animation from '../atoms/Animation'
import Card from '../atoms/Card'
import DataFetcher from '../organisms/DataFetcher'

const NEWS_BRIEF_NEWS_ARTICLE = gql`
  fragment NewsBrief_NewsArticle on NewsArticle {
    id
    createdAt
    title
    featuredImage
    newsCategory {
      id
      name
    }
    slug
  }
`

gql`
  query newsBrief {
    newsBrief {
      ...NewsBrief_NewsArticle
    }
  }
  ${NEWS_BRIEF_NEWS_ARTICLE}
`

interface INews {
  news: NewsArticle[]
}

interface IImage {
  image: string
}

const AnnouncementBox = styled(Box)`
  border: 2px solid ${props => props.theme.colors.sand};

  h3 {
    transition: ${props => props.theme.transitions.fast};

    &:hover {
      color: ${props => props.theme.colors.primarynavy};
    }
  }
`

const StyledNews = styled.ul`
  li {
    border-bottom: 1px solid ${props => props.theme.colors.darkmiddlegray};
  }
  li:last-child {
    border: none;
  }
`
const FeaturedImage = styled(Box)<IImage>`
  background-image: url('${props => props.image}');
  min-height: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
`

const NewsBriefInner: React.FC<INews> = ({ news }) => {
  const { colors } = useTheme()
  const newsItems = news.slice(1, 4)
  const announcement = news[0]
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4 }}
        >
          <Text>
            <h2>News &amp; Announcements</h2>
          </Text>
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4 }}
        >
          <Link to="/news">
            <Text color={paths.homepage() ? colors.primarylightblue : colors.primarynavy}>
              <h6 className="view-all">View all</h6>
            </Text>
          </Link>
        </Animation>
      </Flex>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4 }}
      >
        <Card notch={true} notchSize={16}>
          <Flex flexWrap="wrap" mx={[0, 0, 0, -4]} p={4} mb={[0, 0, 6, 6]} alignItems="center">
            <Box px={[0, 0, 0, 4]} width={[1, 1, 1, 1 / 2]}>
              {announcement && (
                <Link to={paths.newsArticle(announcement.slug)}>
                  <AnnouncementBox>
                    <FeaturedImage image={announcement.featuredImage || defaultImage} />
                    <Box p={5}>
                      <Text mb={3} color={colors.darkgray}>
                        <h6>
                          {MoDDoYY(justDateFrom(announcement.createdAt))} &mdash; CSL Admins &mdash;
                          Announcement
                        </h6>
                      </Text>
                      <Text>
                        <h3>{announcement.title}</h3>
                      </Text>
                    </Box>
                  </AnnouncementBox>
                </Link>
              )}
            </Box>
            <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 4]}>
              <StyledNews>
                {newsItems.map(article => (
                  <li key={article.id}>
                    <Text mt={3}>
                      <p style={{ fontSize: '.75rem' }}>
                        {MoDDoYY(justDateFrom(article.createdAt))} &mdash; CSL Admins &mdash; News
                      </p>
                    </Text>
                    <Link to={paths.newsArticle(article.slug)}>
                      <Text color={colors.black}>
                        <h5>{article.title}</h5>
                      </Text>
                      <Text my={3}>
                        <h6>Read More</h6>
                      </Text>
                    </Link>
                  </li>
                ))}
              </StyledNews>
            </Box>
          </Flex>
        </Card>
      </Animation>
    </>
  )
}

const NewsBrief: React.FC = () => {
  const { data, error, loading } = useNewsBriefQuery({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <DataFetcher error={error} loading={loading} isDark={true}>
      {data && data.newsBrief && <NewsBriefInner news={data.newsBrief} />}
    </DataFetcher>
  )
}

export default NewsBrief
