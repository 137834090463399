import React from 'react'
import { oc } from 'ts-optchain'
import { RouteComponentProps } from 'react-router-dom'

import { CurrentUserProvider } from '../../../contexts/CurrentUserContext'
import TeamSettingsQueryProvider from './TeamSettingsQueryProvider'
import ActiveRosterEntries from './ActiveRosterEntries'
import Invites from './Invites'
import Applications from './Applications'
import EmptyState from './EmptyState'
import { Container } from '../../atoms/SettingsPieces'
import SectionTitle from '../../atoms/SectionTitle'
import BackButton from '../../atoms/BackButton'

interface IURLParams {
  playerId?: string
}

const TeamSettings: React.FC<RouteComponentProps<IURLParams>> = ({ match: { params } }) => {
  const playerId = oc(params).playerId('')

  return (
    <CurrentUserProvider>
      <TeamSettingsQueryProvider playerId={playerId}>
        <Container>
          {playerId && <BackButton />}
          <SectionTitle text={playerId ? 'team settings' : 'my teams'} align="left" mt="3" />
          <ActiveRosterEntries />
          <Invites playerId={playerId} />
          <Applications />
          <EmptyState />
        </Container>
      </TeamSettingsQueryProvider>
    </CurrentUserProvider>
  )
}
export default TeamSettings
