import React, { useContext } from 'react'
import { Box, Flex, Link, Text } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { TeamContext } from '../../contexts/TeamContext'
import HeroImage from '../../assets/images/hero__team-details.jpg'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import Awards from '../atoms/Awards'
import CircleLogo from '../atoms/CircleLogo'
import HeroContainer from '../atoms/HeroContainer'
import SocialIcons from '../molecules/SocialIcons'
import SportLogo from '../atoms/SportLogo'
import TeamInfo from '../molecules/TeamInfo'
import TeamRecruitingLabel from '../atoms/TeamRecruitingLabel'

const RecruitingContainer = styled(Flex)`
  ${Mq.sm} {
    justify-content: flex-end;
  }
`

interface HeroInfoProps {
  applyToTeam: () => void
}

const TeamDetailHero: React.FunctionComponent<HeroInfoProps> = ({ applyToTeam }) => {
  const {
    name,
    sportSlug,
    activeRosterEntries,
    currentUserIsEligible,
    currentUserIsCoordinator,
    conferenceName,
    divisionName,
    isRecruiting,
    numAwards,
    universityId,
    university,
    currentUserApplicationPending,
    approvedBanner,
    approvedLogo,
    facebookUsername,
    twitchUsername,
    twitterUsername,
  } = useContext(TeamContext)
  const shouldShowApply = () => {
    const isEligible = isRecruiting && currentUserIsEligible
    return (isEligible || currentUserApplicationPending) && !currentUserIsCoordinator
  }
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    hiddenRight: {
      opacity: 0,
      x: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
    },
  }
  return (
    <HeroContainer
      height="auto"
      heroImage={approvedBanner.dataUrl || HeroImage}
      tint={'3,51,89, 0.6'}
    >
      <Flex alignItems="flex-end" minHeight={'10rem'} flexWrap="wrap">
        <Box width={[1, 1, 2 / 3]}>
          <Flex>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4 }}
            >
              <Awards major={numAwards} minor={0} />
            </Animation>
          </Flex>

          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4 }}
          >
            <Flex alignItems="flex-end" mt={4}>
              {approvedLogo.dataUrl && (
                <CircleLogo logoUrl={approvedLogo.dataUrl} alt="Team Logo" mr={5} />
              )}
              <Box>
                <SportLogo width={'2rem'} height={'2rem'} sport={sportSlug} />
              </Box>
              <Link href={paths.university(universityId)}>
                <Text color="white" ml={'.75rem'}>
                  <h2>{name}</h2>
                </Text>
              </Link>
            </Flex>{' '}
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <Flex mt={4}>
              <TeamInfo
                members={activeRosterEntries && activeRosterEntries.length}
                conference={conferenceName}
                league={divisionName}
                university={university}
                universityId={universityId}
              />
              <SocialIcons
                fb={facebookUsername}
                twitch={twitchUsername}
                twitter={twitterUsername}
              />
            </Flex>
          </Animation>
        </Box>
        <Box width={[1, 1, 1 / 3]} mt={[3, 3, 0]}>
          <Animation
            initial="hiddenRight"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
          >
            <RecruitingContainer alignItems="center">
              {isRecruiting && <TeamRecruitingLabel />}
              {shouldShowApply() && (
                <BaseButton
                  disabled={currentUserApplicationPending}
                  variant={currentUserApplicationPending ? 'primaryDisabled' : 'primary'}
                  onClick={applyToTeam}
                  ml={'1.5rem'}
                  height="100%"
                >
                  {currentUserApplicationPending ? 'Application Pending' : 'Apply For Team'}
                </BaseButton>
              )}
            </RecruitingContainer>
          </Animation>
        </Box>
      </Flex>
    </HeroContainer>
  )
}

export default TeamDetailHero
