import { Flex, FlexProps, Text } from 'rebass'
import { MediaQueries as Mq, styled } from '../../../styles/settings/theme'
import React from 'react'

import { paths } from '../../../utils/Routes'
import { SiteSwitch, esportsSite } from '../../../utils/sites'
import Animation from '../../atoms/Animation'
import NavList, { INavListItem } from './NavList'
import StyledLink from '../../atoms/StyledLink'

interface IUserMenuProps {
  isIncompleteAccount: boolean
  userId: string
  textAlign?: string
  closeMenu?: () => void
  showInviteModal: () => void
}

const UserMenuWrapper = styled(Flex)<FlexProps>`
  flex-direction: column;
  padding: 1rem 0;
  z-index: 99999;

  ${Mq.md} {
    position: absolute;
    background: white;
    top: 4rem;
    right: 3rem;
    width: 200px;
    border: 1px solid ${props => props.theme.colors.lightgray};
    padding: ${props => props.theme.space[6]}px;
    transition: ${props => props.theme.transitions.fast};
    color: ${props => props.theme.colors.primarynavy};

    a:hover {
      color: ${props => props.theme.colors.primaryblue};
    }

    --notchSize: 20px;

    clip-path: polygon(
      0% 0px,
      0px 0%,
      calc(100% - var(--notchSize)) 0%,
      100% 0px,
      100% calc(100% - 0px),
      calc(100% - 0px) 100%,
      var(--notchSize) 100%,
      0% calc(100% - var(--notchSize))
    );
  }
`

const UserMenu: React.FC<IUserMenuProps> = ({
  isIncompleteAccount,
  userId,
  textAlign,
  closeMenu,
  showInviteModal,
}) => {
  let listItems: INavListItem[] = [
    { path: isIncompleteAccount ? paths.profile() : paths.player(userId), label: 'view profile' },
    {
      path: isIncompleteAccount ? paths.completeProfile() : paths.profileSettings(),
      label: isIncompleteAccount ? 'complete profile' : 'edit profile',
    },
  ]

  if (!isIncompleteAccount) {
    listItems = [...listItems, { path: paths.accountSettings() }, { path: paths.teamSettings() }]
  }

  if (esportsSite) {
    listItems = [
      ...listItems,
      {
        label: 'send an invite',
        onClick: showInviteModal,
      },
    ]
  }

  listItems = [...listItems, { path: paths.logOut() }]

  const animations = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }

  return (
    <Animation
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.2 }}
    >
      <UserMenuWrapper id="userMenu" onMouseLeave={closeMenu ? closeMenu : () => {}}>
        <SiteSwitch
          college={
            <StyledLink href={paths.myUniversity()} target="_blank" rel="noopener noreferrer">
              <Flex alignItems="center" justifyContent="flex-end">
                <Text mb={4} ml={[0, 0, 3]}>
                  My University
                </Text>
              </Flex>
            </StyledLink>
          }
          esports={null}
        />

        <NavList listItems={listItems} flexDirection="column" textAlign={textAlign} />
      </UserMenuWrapper>
    </Animation>
  )
}

export default UserMenu
