import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { useModal } from 'react-modal-hook'
import { toast } from 'react-toastify'
import { Box, Text, Flex } from 'rebass'
import { useTheme } from 'emotion-theming'

import { TeamContext } from '../../../contexts/TeamContext'
import { useUpdateRosterEntryMutation } from '../../../types/graphql'
import { prettyName } from '../../../utils/sportUtils'
import { handleResult, errorOn } from '../../../utils/results'
import EditTeamTable from '../../molecules/EditTeamTable'
import RecruitTeamMembers from '../../molecules/RecruitTeamMembers'
import ToggleTeamRecruitingStatus from '../../molecules/ToggleTeamRecruitingStatus'
import TeamSettingsContainer from '../../atoms/TeamSettingsContainer'
import { BaseButton } from '../../atoms/Buttons'
import ModalContainer from '../../atoms/ModalContainer'
import ChangeCoordinator from './ChangeCoordinator'

gql`
  mutation updateRosterEntry(
    $id: ID!
    $role: String
    $gameRole: String
    $staffRole: String
    $kicked: Boolean
  ) {
    updateRosterEntry(
      id: $id
      role: $role
      gameRole: $gameRole
      staffRole: $staffRole
      kicked: $kicked
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`

const EditTeam: React.FC = () => {
  const { colors } = useTheme()
  const {
    starterLineup,
    substituteLineup,
    sportSlug,
    numPlayers,
    setTeamContextLoading,
    activeRosterEntries,
    id,
    coordinator,
  } = useContext(TeamContext)
  const [updateRosterEntry, { loading }] = useUpdateRosterEntryMutation({
    refetchQueries: ['teamDetailPage'],
    awaitRefetchQueries: true,
    onCompleted: data => {
      handleResult({
        result: data.updateRosterEntry,
        onSuccess: () => toast.success('Roster Changes Saved!', { containerId: 'temporary' }),
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    },
  })

  useEffect(() => {
    setTeamContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const [showChangeCoordinatorModal, hideChangeCoordinatorModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideChangeCoordinatorModal}
        style={{
          content: { maxWidth: '50rem' },
        }}
      >
        <ChangeCoordinator
          activeRosterEntries={activeRosterEntries}
          teamId={id}
          hideModal={hideChangeCoordinatorModal}
        />
      </ModalContainer>
    ),
    [activeRosterEntries, id],
  )

  return (
    <TeamSettingsContainer pt={7} pb={5} notch={true} notchSize={20}>
      <Flex px={6} mb={5} flexDirection={['column', 'column', 'row']}>
        <Box flex={1} mr={[0, 0, 2]}>
          <Text mb={4} color={colors.darkgray}>
            <h4>Edit your team lineup</h4>
          </Text>
          <p>
            Manage your team's lineup by selecting appropriate roles for each player. You are
            required to have <strong>{numPlayers} starters</strong> for{' '}
            <strong>{prettyName(sportSlug)}</strong>.
          </p>
          <Box mt={7}>
            <h3>
              Starting Lineup{' '}
              <Text as="span" color={colors.red}>
                ({starterLineup.length}/{numPlayers})
              </Text>
            </h3>
          </Box>
        </Box>
        <Flex
          flex={1}
          ml={[0, 0, 2]}
          flexDirection="column"
          justifyContent="space-between"
          alignItems={['flex-start', 'flex-start', 'flex-end']}
        >
          <Flex
            flexDirection={['row', 'row', 'column']}
            justifyContent="space-between"
            alignItems={['flex-start', 'flex-start', 'flex-end']}
            width={['100%', '100%', 'auto']}
            mt={[5, 5, 0]}
            ml={[0, 0, 5]}
          >
            <Text mb={4} color={colors.darkgray} textAlign="right">
              <h4>Recruiting Status</h4>
            </Text>
            <ToggleTeamRecruitingStatus />
          </Flex>
          <BaseButton
            variant="secondary"
            type="submit"
            maxWidth="16rem"
            mt={[3, 3, 0]}
            onClick={showChangeCoordinatorModal}
          >
            set new coordinator
          </BaseButton>
        </Flex>
      </Flex>

      <Box mb={5}>
        <EditTeamTable
          starters
          rosterEntries={starterLineup}
          updateRosterEntry={updateRosterEntry}
          sportSlug={sportSlug}
          coordinatorId={coordinator.id}
        />
        <RecruitTeamMembers />
      </Box>

      {!!substituteLineup.length && (
        <>
          <Box px={6} mt={8} mb={5}>
            <h3>Substitutes</h3>
          </Box>
          <EditTeamTable
            rosterEntries={substituteLineup}
            updateRosterEntry={updateRosterEntry}
            sportSlug={sportSlug}
            coordinatorId={coordinator.id}
          />
        </>
      )}
    </TeamSettingsContainer>
  )
}

export default EditTeam
