import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React, { useEffect, useReducer, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass'
import { styled } from '../../../styles/settings/theme'

import { Team, Game } from '../../../types/graphql'
import { rawNumPlayers, roles } from '../../../utils/sportUtils'
import { numToString } from '../../../utils/typeCoercions'
import { BaseButton } from '../../atoms/Buttons'
import LoadingSpinner from '../../atoms/LoadingSpinner'
import SportLogo from '../../atoms/SportLogo'
import LolReportingTable from './LolReportingTable'
import { GameBoxReportEvidence, useEvidence } from './evidence'
import { currentUserIsAdmin } from '../../../utils/admins'

export const GET_LOL_CHAMPIONS = gql`
  query lolChampions {
    sportPlayerCharacteristics(lolChampions: true) {
      lolChampions {
        options
      }
    }
  }
`

export const REPORT_LOL_GAME_MUTATION = gql`
  mutation report_lol_game(
    $gameId: ID!
    $winningTeamId: ID!
    $homeTeam: UserReportedGame!
    $awayTeam: UserReportedGame!
    $evidenceScreenshot: String!
  ) {
    reportLolGame(
      gameId: $gameId
      winningTeamId: $winningTeamId
      homeTeam: $homeTeam
      awayTeam: $awayTeam
      evidenceScreenshot: $evidenceScreenshot
    ) {
      match {
        id
        settled
      }
    }
  }
`

interface GameProps {
  game: Game
  homeTeam: Team
  awayTeam: Team
  toOpen: any
  updateLoading: (loading: boolean) => void
  report: number
  index: number
  reportable: boolean
  settled: boolean
}
const Circle = styled(Box)<BoxProps>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`
const RowBox = styled(Flex)<FlexProps>`
  flex-direction: column;
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  padding: 2rem 1.5rem;
  margin-top: 1.25rem;
`
const Reporting = styled(Flex)<FlexProps>`
  border-top: 2px solid ${props => props.theme.colors.lightgray};
`
interface Action {
  payload: { index: number; name: string; role: string; pick: string }
}

const teamReducer = (state: any, action: Action) => {
  return {
    ...state,
    [action.payload.index]: {
      name: action.payload.name,
      pick: action.payload.pick,
      role: action.payload.role,
    },
  }
}

const LolGameBox = ({
  game,
  homeTeam,
  awayTeam,
  toOpen,
  report,
  index,
  updateLoading,
  reportable,
  settled,
}: GameProps) => {
  const [ready, setReady] = useState(false)
  const [winningTeamId, setWinner] = useState()
  const [lolChampions, setLolChampions] = useState([])
  const { evidenceScreenshotUrl, evidenceScreenshotName, handleEvidenceChanged } = useEvidence()

  const activeBox = report === index

  useQuery(GET_LOL_CHAMPIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setLolChampions(data.sportPlayerCharacteristics.lolChampions.options)
    },
  })

  const [reportLolGame, { loading }] = useMutation(REPORT_LOL_GAME_MUTATION, {
    refetchQueries: ['matchDetailPage'],
    onError(error) {},
    onCompleted(data) {
      if (data.reportLolGame.match.settled) {
        toast.success(
          "Thank you for your submission. We're processing it now and brackets will be updated within 5 minutes",
          { containerId: 'temporary' },
        )
      } else {
        toast.success('Thank you for your submission!', { containerId: 'temporary' })
      }
      changeOpenIndex(-1)
      updateLoading(false)
    },
  })

  useEffect(() => {
    const isReadyForSubmit = () => {
      return winningTeamId && evidenceScreenshotUrl
    }
    setReady(isReadyForSubmit())

    if (activeBox) {
      loading ? updateLoading(true) : updateLoading(false)
    }
  }, [winningTeamId, activeBox, loading, updateLoading, evidenceScreenshotUrl])

  const num = Number(rawNumPlayers(homeTeam.sportSlug))

  //create initial state object
  const results = (team: any) => {
    return {
      ...[...Array(num)].map((el, i) => {
        return {
          name: team.activeRosterEntries[i] ? team.activeRosterEntries[i].player.username : '',
          pick: team.activeRosterEntries[i] ? team.activeRosterEntries[i].player.characterName : '',
          role: roles(team.sportSlug)[i],
        }
      }),
    }
  }

  const homeTeamInitialState = results(homeTeam)
  const awayTeamInitialState = results(awayTeam)

  const [homeTeamState, homeDispatch] = useReducer(teamReducer, homeTeamInitialState)
  const [awayTeamState, awayDispatch] = useReducer(teamReducer, awayTeamInitialState)

  const handlePlayerChange = (e: any, isHomeTeam: any, i: any) => {
    const payload = {
      ...(isHomeTeam ? homeTeamState[i] : awayTeamState[i]),
      index: i,
      [e.target.name]: e.target.value,
    }
    isHomeTeam ? homeDispatch({ payload }) : awayDispatch({ payload })
  }

  const playerLookupId =
    homeTeam && homeTeam.activeRosterEntries && awayTeam && awayTeam.activeRosterEntries
      ? homeTeam.activeRosterEntries
          .concat(awayTeam.activeRosterEntries)
          .map((entry: any) => {
            return { name: entry.player.username, id: entry.player.id }
          })
          .reduce((obj: any, item: any) => {
            obj[item.name] = item.id
            return obj
          }, {})
      : null

  const changeOpenIndex = (index: number) => {
    toOpen(index)
  }

  const checkData = () => {
    //checks for duplicate names
    const homeNameObj: { [key: string]: boolean } = {}
    for (let key in homeTeamState) {
      if (!homeNameObj[homeTeamState[key].name]) {
        homeNameObj[homeTeamState[key].name] = true
      } else {
        toast.error(
          `You have entered duplicate players on ${homeTeam.name}. If there are not ${num} unique players on this team, the roster must be edited on the team's page before reporting this game.`,
          { containerId: 'temporary' },
        )
        return false
      }
    }

    const awayNameObj: { [key: string]: boolean } = {}
    for (let key in awayTeamState) {
      if (!awayNameObj[awayTeamState[key].name]) {
        awayNameObj[awayTeamState[key].name] = true
      } else {
        toast.error(
          `You have entered duplicate players on ${awayTeam.name}. If there are not ${num} unique players on this team, the roster must be edited on the team's page before reporting this game.`,
          { containerId: 'temporary' },
        )
        return false
      }
    }

    //checks for duplicate pickss
    const pickObj: { [key: string]: boolean } = {}
    const combinedStates: Array<any> = Object.values(homeTeamState).concat(
      Object.values(awayTeamState),
    )
    for (var i = 0; i < combinedStates.length; i++) {
      if (!pickObj[combinedStates[i].pick]) {
        pickObj[combinedStates[i].pick] = true
      } else {
        toast.error('You have used ' + combinedStates[i].pick + ' as a pick more than once.', {
          containerId: 'temporary',
        })
        return false
      }
    }

    //checks for clear winner
    if (!winningTeamId) {
      toast.error('Please indicate a winner.', { containerId: 'temporary' })
      return false
    }
    return true
  }

  const handleSubmit = () => {
    const validData = checkData()
    if (validData) {
      const gamePayload = {
        id: game.id,
        winningTeamId: winningTeamId,
        homeTeam: {
          score: winningTeamId === homeTeam.id ? 1 : 0,
          userSubmittedLineup: Object.values(homeTeamState).map((entry: any) => {
            const lolChampion: any = lolChampions.find(
              (champion: string) => champion === entry.pick,
            )
            return {
              playerId: playerLookupId[entry.name],
              roleCd: entry.role,
              lolChampion,
            }
          }),
        },
        awayTeam: {
          score: winningTeamId === awayTeam.id ? 1 : 0,
          userSubmittedLineup: Object.values(awayTeamState).map((entry: any) => {
            const lolChampion: any = lolChampions.find(
              (champion: string) => champion === entry.pick,
            )
            return {
              playerId: playerLookupId[entry.name],
              roleCd: entry.role,
              lolChampion,
            }
          }),
        },
      }
      if (!loading) {
        reportLolGame({
          variables: {
            gameId: gamePayload.id,
            winningTeamId: gamePayload.winningTeamId,
            homeTeam: gamePayload.homeTeam,
            awayTeam: gamePayload.awayTeam,
            evidenceScreenshot: evidenceScreenshotUrl, // is a  Base64 encoded data URL
          },
        })
      }
    }
  }

  return (
    <RowBox>
      {/* top row */}
      <Flex justifyContent="space-between" flexDirection={['column', 'column', 'row']}>
        <Flex alignItems="center" mb={[2, 2, 0]}>
          <Box mr={4}>
            <SportLogo sport={homeTeam.sportSlug} width="2rem" height="2rem" />
          </Box>
          <Text color="darkgray">
            <h3>Game {numToString(index + 1)}</h3>
          </Text>
          {game.winner && game.winner.team && (
            <Text color="green" ml={5} mt={'2px'}>
              <h5>{game.winner.team.truncatedName}</h5>
            </Text>
          )}
        </Flex>

        <Flex
          alignItems={['flex-start', 'flex-start', 'center']}
          width={[1, 1, 1 / 2]}
          justifyContent="flex-end"
          flexDirection={['column', 'column', 'row']}
        >
          {loading ? (
            <LoadingSpinner />
          ) : report !== index ? (
            <Flex mr={5} mb={[2, 2, 0]} alignItems="center">
              <Circle bg={game.winner ? 'green' : !settled ? 'red' : 'middlegray'} mr={2} />
              <Text color="darkgray" mt={'2px'}>
                <h6>
                  {game.winner
                    ? 'game reported'
                    : reportable
                    ? 'game unreported'
                    : settled
                    ? 'game not needed'
                    : 'report previous game'}
                </h6>
              </Text>
            </Flex>
          ) : null}
          {((!game.winner && !loading && reportable && !settled) || currentUserIsAdmin()) && (
            <BaseButton
              variant={activeBox && !ready ? 'primaryDisabled' : 'primary'}
              disabled={(activeBox && !ready) || loading}
              onClick={activeBox ? handleSubmit : () => changeOpenIndex(index)}
            >
              {activeBox ? 'submit game report' : 'report game'}
            </BaseButton>
          )}
        </Flex>
      </Flex>
      {/* reporting content */}
      {activeBox && (
        <Reporting mt={5} flexWrap="wrap" mx={[0, 0, 0, -2]} p={1}>
          <LolReportingTable
            isHomeTeam={true}
            team={homeTeam}
            handlePlayerChange={handlePlayerChange}
            teamResults={homeTeamState}
            setWinner={setWinner}
            isWinningTeam={winningTeamId === homeTeam.id}
            lolChampions={lolChampions}
          />

          <LolReportingTable
            isHomeTeam={false}
            team={awayTeam}
            handlePlayerChange={handlePlayerChange}
            teamResults={awayTeamState}
            setWinner={setWinner}
            isWinningTeam={winningTeamId === awayTeam.id}
            lolChampions={lolChampions}
          />

          <GameBoxReportEvidence
            imageName={evidenceScreenshotName}
            imageUrl={evidenceScreenshotUrl}
            onImageChanged={handleEvidenceChanged}
          />
        </Reporting>
      )}
    </RowBox>
  )
}

export default LolGameBox
