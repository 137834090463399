import React from 'react'
import { NavLink, Route, RouteComponentProps, useRouteMatch, Redirect } from 'react-router-dom'
import { Box } from 'rebass'
import { oc } from 'ts-optchain'
import { styled } from '../../styles/settings'

import { paths } from '../../utils/Routes'
import { SeasonAdminProvider } from '../../contexts/SeasonAdminContext'
import AdminSeasonInfo from '../organisms/AdminSeasonInfo'
import AdminSeasonQueryProvider from '../organisms/AdminSeasonQueryProvider'
import AdminSeasonTeams from '../organisms/AdminSeasonTeams'
import AdminSeasonTitle from '../atoms/AdminSeasonTitle'
import Content from '../atoms/Content'

const EditNav = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    font-family: ${props => props.theme.fonts.condensed};
    margin-bottom: 1.5rem;
  }
`

const CustomNavLink = styled(NavLink)`
  color: ${props => props.theme.colors.darkmiddlegray};
  &.active {
    color: ${props => props.theme.colors.darkgray};
  }
`

interface IURLParams {
  seasonId?: string
  anchor?: string
}

interface IProps extends RouteComponentProps<IURLParams> {}

const ManageSeason: React.FC<IProps> = ({ match: { params } }) => {
  //basic info
  const match = useRouteMatch()
  const seasonId = oc(params).seasonId('')
  const anchor = oc(params).anchor('')

  enum Anchors {
    BasicInfo = 'basic-info',
    Teams = 'teams',
  }

  const sections: { [key: string]: React.ReactElement } = {
    [Anchors.BasicInfo]: <AdminSeasonInfo />,
    [Anchors.Teams]: <AdminSeasonTeams />,
  }

  return anchor === '' ? (
    <Redirect to={paths.manageSeason(seasonId, Anchors.BasicInfo)} />
  ) : (
    <SeasonAdminProvider>
      <AdminSeasonQueryProvider seasonId={seasonId}>
        <Content>
          <AdminSeasonTitle />
          <Box
            sx={{
              display: 'grid',
              gridGap: 1,
              gridTemplateColumns: '1fr 4fr',
            }}
          >
            <EditNav>
              <li key={'info'}>
                <CustomNavLink to={`basic-info`} isActive={() => anchor === Anchors.BasicInfo}>
                  <h5>basic info</h5>
                </CustomNavLink>
              </li>
              <li key={'teams'}>
                <CustomNavLink to={'teams'} isActive={() => anchor === Anchors.Teams}>
                  <h5>teams</h5>
                </CustomNavLink>
              </li>
            </EditNav>
            <Route
              path={`${match.path}/:section`}
              render={({ match }) => sections[match.params.section]}
            />
          </Box>
        </Content>
      </AdminSeasonQueryProvider>
    </SeasonAdminProvider>
  )
}

export default ManageSeason
