import React from 'react'
import { oc } from 'ts-optchain'
import { RouteComponentProps } from 'react-router-dom'

import { CurrentUserProvider } from '../../../contexts/CurrentUserContext'
import ProfileSettingsQueryProvider from './ProfileSettingsQueryProvider'
import UserAboutFields from './UserAboutFields'
import SportFields from './SportFields'
import SocialFields from './SocialFields'
import { Container } from '../../atoms/SettingsPieces'
import SectionTitle from '../../atoms/SectionTitle'
import BackButton from '../../atoms/BackButton'

interface IURLParams {
  playerId?: string
}

const ProfileSettings: React.FC<RouteComponentProps<IURLParams>> = ({ match: { params } }) => {
  const playerId = oc(params).playerId('')

  return (
    <CurrentUserProvider>
      <ProfileSettingsQueryProvider playerId={playerId}>
        <Container>
          {playerId && <BackButton />}
          <SectionTitle text="profile settings" align="left" mt="3" />
          <UserAboutFields playerId={playerId} />
          <SportFields playerId={playerId} />
          <SocialFields playerId={playerId} />
        </Container>
      </ProfileSettingsQueryProvider>
    </CurrentUserProvider>
  )
}
export default ProfileSettings
