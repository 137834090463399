import React from 'react'
import { Box, Flex, Text } from 'rebass'

import { RosterEntry, Team } from '../../../types/graphql'
import { rawNumPlayers } from '../../../utils/sportUtils'
import { randIntUpto } from '../../../utils/random'
import { BaseButton } from '../../atoms/Buttons'
import { CustomSelect } from '../../atoms/Select'
import { CustomInput } from '../../atoms/FormPieces'
import Table from '../../atoms/Table'

interface ReportingProps {
  team: Team
  isHomeTeam: boolean
  handleChange: (e: React.ChangeEvent, isHomeTeam: boolean, i: number) => void
  smashCharacters: Array<string>
  teamResults: { [key: number]: { [key: string]: string } }
  setWinner: (value: any) => void
  isWinningTeam: boolean
}

const SmashReportingTable = ({
  team,
  isHomeTeam,
  handleChange,
  smashCharacters,
  teamResults,
  isWinningTeam,
  setWinner,
}: ReportingProps) => {
  const num = Number(rawNumPlayers(team.sportSlug))

  const handleScore = () => {
    setWinner(team.id)
  }

  return (
    <>
      <Box width={[1, 1, 1, 1 / 2]} mt={5} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
        <Flex alignItems="center" justifyContent="space-between">
          <h3>{team.truncatedName}</h3>
          <BaseButton variant={isWinningTeam ? 'selected' : 'select'} onClick={handleScore}>
            select as winner
          </BaseButton>
        </Flex>
        <Box mt={4}>
          <Table
            bodyBackgroundColor="backgroundgray"
            thpadding="0.8rem .75rem 0.7rem"
            tdpadding=".8rem .5rem"
            align="left"
          >
            <colgroup>
              <col span={1} style={{ width: '60%' }} />
              <col span={1} style={{ width: '30%' }} />
              <col span={1} style={{ width: '10%' }} />
            </colgroup>

            <thead>
              <tr>
                <th>
                  <Text color="white">
                    <h6>Player</h6>
                  </Text>
                </th>
                <th>
                  <Text color="white">
                    <h6>Character</h6>
                  </Text>
                </th>
                <th>
                  <Text color="white">
                    <h6>Stocks Remaining</h6>
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(num)].map((_el, i: number) => {
                const playerInfo = teamResults[i]

                return (
                  <tr key={i}>
                    <td>
                      <CustomSelect
                        key={playerInfo.name}
                        onChange={e => {
                          handleChange(e, isHomeTeam, i)
                        }}
                        value={playerInfo.name}
                        name={'name'}
                      >
                        {team.activeRosterEntries &&
                          team.activeRosterEntries.map((entry: RosterEntry) => (
                            <option key={entry.id}>{entry.player && entry.player.username}</option>
                          ))}
                      </CustomSelect>
                    </td>
                    <td>
                      <CustomSelect
                        key={`${playerInfo.character}-${randIntUpto(10000000)}`}
                        onChange={e => {
                          handleChange(e, isHomeTeam, i)
                        }}
                        value={playerInfo.character}
                        name={'character'}
                      >
                        {smashCharacters.length ? (
                          smashCharacters.map((character: string) => (
                            <option key={`${character}-${randIntUpto(10000000)}`}>
                              {character}
                            </option>
                          ))
                        ) : (
                          <option>{playerInfo.character}</option>
                        )}
                      </CustomSelect>
                    </td>
                    <td>
                      <CustomInput
                        type="number"
                        min={0}
                        max={50}
                        name={'stocksRemaining'}
                        value={playerInfo.stocksRemaining}
                        onChange={e => {
                          handleChange(e, isHomeTeam, i)
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Box>
      </Box>
    </>
  )
}
export default SmashReportingTable
