import { Box } from 'rebass'
import { CustomInput } from '../atoms/FormPieces'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React, { useState, useEffect } from 'react'

import TeamsTable, { TeamTableLike } from '../molecules/TeamsTable'
import {
  EligibleTeamInfoTournamentFragment as Team,
  useAdminTournnamentEligibleTeamsLazyQuery,
} from '../../types/graphql'
import DataFetcher from './DataFetcher'

const ELIGIBLE_TEAM_INFO = gql`
  fragment eligibleTeamInfoTournament on Team {
    id
    name
    selectedSeasonStats {
      wins
      losses
    }
    university {
      id
      abbreviation
      name
      state
    }
    selectedSeasonConferenceName
    coordinator {
      id
      username
    }
  }
`

gql`
  query AdminTournnamentEligibleTeams($tournamentId: ID!, $name: String) {
    tournament(id: $tournamentId) {
      id
      eligibleTeams(name: $name) {
        ...eligibleTeamInfoTournament
      }
    }
  }
  ${ELIGIBLE_TEAM_INFO}
`

const REGISTER_TEAM_MUTATION = gql`
  mutation AdminRegisterTeamForTournament($teamId: ID!, $tournamentId: ID!) {
    registerTeamForTournament(teamId: $teamId, tournamentId: $tournamentId) {
      value
      errors {
        field
        message
      }
    }
  }
`

interface IProps {
  tournamentId: string
}

const AdminTournamentTeamsModal: React.FC<IProps> = ({ tournamentId }) => {
  const [teams, setTeams] = useState<Team[]>([])
  const [name, setName] = useState('')

  const [eligibleTeamsQuery, { error, loading }] = useAdminTournnamentEligibleTeamsLazyQuery({
    variables: { tournamentId: tournamentId, name: name },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data && data.tournament) {
        setTeams(data.tournament.eligibleTeams)
      }
    },
  })

  const [registerTeam] = useMutation(REGISTER_TEAM_MUTATION, {
    refetchQueries: ['TournamentAdmin', 'AdminTournnamentEligibleTeams'],
    onCompleted({ registerTeamForTournament }) {
      if (registerTeamForTournament) {
        if (registerTeamForTournament.success)
          toast.success('Team registered for season', { containerId: 'temporary' })
        else if (registerTeamForTournament.errors)
          registerTeamForTournament.errors.map((e: any) => toast.error(e.message), {
            containerId: 'temporary',
          })
      } else toast.error('Error Registering Team', { containerId: 'temporary' })
    },
  })

  useEffect(() => {
    if (!tournamentId) {
      return
    }
    eligibleTeamsQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentId])

  const handleSearch = () => {
    eligibleTeamsQuery()
  }
  const enrollTeam = (team: TeamTableLike) => {
    registerTeam({ variables: { teamId: team.id, tournamentId: tournamentId } })
    //TODO implement this function
  }
  return (
    <>
      <h2>Search for a team</h2>
      <Box as="form" onSubmit={handleSearch}>
        <CustomInput
          width={[1, 1, 1, 1 / 3]}
          mt={4}
          onChange={e => setName(e.target.value)}
          placeholder="search by name"
        />
        <DataFetcher loading={loading} error={error}>
          <Box mt={6}>
            <TeamsTable
              teams={teams as TeamTableLike[]}
              coordinator={false}
              enroll={true}
              enrollTeam={enrollTeam}
            />
          </Box>
        </DataFetcher>
      </Box>
    </>
  )
}

export default AdminTournamentTeamsModal
