import { Flex, FlexProps } from 'rebass'
import { darken } from 'polished'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

export const AuthFormContent = styled(Flex)<FlexProps>`
  max-width: calc(100% - 2rem);
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: relative;

  ${Mq.md} {
    max-width: 75%;
  }
`

export const AuthFormBox = styled.form`
  display: flex;
  width: 100%;
  padding: 6rem 1rem;
  margin: 4rem auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: white;

  --notchSize: 20px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );

  ${Mq.md} {
    padding: 6rem 2rem;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 1rem;
  color: ${props => darken(0.3, props.theme.colors.darkmiddlegray)};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  height: 3rem;
  border: solid 2px ${props => props.theme.colors.darkmiddlegray};
  background-color: ${props => props.theme.colors.backgroundgray};
  margin-top: 5%;
  padding: 1rem;
  ::placeholder {
    color: ${props => darken(0.3, props.theme.colors.darkmiddlegray)};
    font-size: 1rem;
  }
`
