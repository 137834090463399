import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { paths } from '../../utils/Routes'
import { User_SearchFragment as User } from '../../types/graphql'

import Animation from '../atoms/Animation'
import Table from '../atoms/Table'

interface IUsersTableProps {
  users: User[]
}

const animations = {
  hidden: {
    opacity: 0,
    y: '24px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const UsersTable: React.FC<IUsersTableProps> = ({ users }) => {
  const { colors } = useTheme()

  return (
    <Animation
      initial="hidden"
      animate="visible"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
    >
      <Table headerBackgroundColor="middlegray">
        <thead>
          <tr>
            <th>
              <h6>Name</h6>
            </th>
            <th>
              <h6>Email</h6>
            </th>
            <th>
              <h6>Personal Email</h6>
            </th>
            <th>
              <h6>Username</h6>
            </th>
            <th>
              <h6>Discord Username</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>
                <Link to={paths.player(user.id)}>
                  <Text color={colors.darkgray}>
                    <h6>{user.name}</h6>
                  </Text>
                </Link>
              </td>
              <td>
                <Link to={paths.player(user.id)}>
                  <Text color={colors.darkgray}>
                    <h6>{user.email}</h6>
                  </Text>
                </Link>
              </td>
              <td>
                <Link to={paths.player(user.id)}>
                  <Text color={colors.darkgray}>
                    <h6>{user.personalEmail}</h6>
                  </Text>
                </Link>
              </td>
              <td>
                <Link to={paths.player(user.id)}>
                  <Text color={colors.darkgray}>
                    <h6>{user.username}</h6>
                  </Text>
                </Link>
              </td>
              <td>
                <Link to={paths.player(user.id)}>
                  <Text color={colors.darkgray}>
                    <h6>{user.discordUsername}</h6>
                  </Text>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Animation>
  )
}

export default UsersTable
