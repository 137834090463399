import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { TournamentContext } from '../../../contexts/TournamentContext'
import {
  useCheckinTeamForTournamentMutation as useCheckinTeamForTournament,
  useUncheckinTeamFromTournamentMutation as useUncheckinTeamFromTournament,
} from '../../../types/graphql'
import { errorOn, handleResult } from '../../../utils/results'
import ToggleSwitch from '../../atoms/ToggleSwitch'
import DataFetcher from '../DataFetcher'

interface ITeamCheckinToggleProps {
  teamId: string
  teamName: string
  tournamentId: string
  alreadyCheckedIn: boolean
}

export const TeamCheckinToggle: React.FC<ITeamCheckinToggleProps> = ({
  teamId,
  teamName,
  tournamentId,

  alreadyCheckedIn,
}) => {
  const { setCheckedInTeamIds } = useContext(TournamentContext)
  const [checkinTeamForTournament, { loading: checkinLoading }] = useCheckinTeamForTournament()
  const [
    uncheckinTeamFromTournament,
    { loading: uncheckinLoading },
  ] = useUncheckinTeamFromTournament()

  const mutationOptions = {
    variables: {
      teamId: teamId,
      tournamentId: tournamentId,
    },
  }

  const checkinTeam = async () => {
    try {
      const { data } = await checkinTeamForTournament(mutationOptions)
      handleResult({
        result: data!.createNewCheckin,
        onSuccess: _value => {
          setCheckedInTeamIds(checkedInTeamIds => [...checkedInTeamIds, teamId])

          return toast.success(`${teamName} has been checked-in!`, {
            containerId: 'temporary',
          })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const uncheckinTeam = async () => {
    try {
      const { data } = await uncheckinTeamFromTournament(mutationOptions)
      handleResult({
        result: data!.deleteTournamentCheckin,
        onSuccess: _value => {
          setCheckedInTeamIds(
            checkedInTeamIds => checkedInTeamIds && checkedInTeamIds.filter(id => id !== teamId),
          )

          return toast.success(`${teamName} has been unchecked-in!`, {
            containerId: 'temporary',
          })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const loading = checkinLoading || uncheckinLoading

  return (
    <DataFetcher loading={loading} height="auto">
      <ToggleSwitch
        handleChange={() => (alreadyCheckedIn ? uncheckinTeam() : checkinTeam())}
        checked={alreadyCheckedIn}
      />
    </DataFetcher>
  )
}
