import dayjs from 'dayjs'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'

import { DashboardMatchHistoryInfoFragment as Match, Game } from '../../types/graphql'
import { opponentTeam, teamScore } from '../../utils/matchUtils'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import Table from '../atoms/Table'

gql`
  fragment DashboardMatchHistoryInfo on Match {
    id
    scheduledDate
    homeTeam {
      id
      name
    }
    awayTeam {
      id
      name
    }
    games {
      id
      winner {
        id
        team {
          id
        }
      }
    }
  }
`

interface IMatchLike {
  id: string
  score: number
  opponentScore: number
  opponentName: string | null | undefined
  scheduledDate: string
}

interface IProps {
  teamIds: string[]
  matches: Match[]
}

interface IHistoryTableProps {
  matches: IMatchLike[]
  bodyBorder?: boolean
  lastRowBorder?: boolean
  paginate?: boolean
}

const animations = {
  hidden: {
    opacity: 0,
    y: '24px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const HistoryTable: React.FC<IHistoryTableProps> = ({ matches, bodyBorder, lastRowBorder }) => {
  const { colors } = useTheme()

  return (
    <Box>
      <Table
        headerBackgroundColor="middlegray"
        lastRowBorder={lastRowBorder}
        bodyBorder={bodyBorder}
        overflow="auto"
      >
        <thead className="notched">
          <tr>
            <th>
              <Text color={colors.black}>
                <h6>Date</h6>
              </Text>
            </th>
            <th>
              <Text color={colors.black}>
                <h6>Opponent</h6>
              </Text>
            </th>
            <th>
              <Text color={colors.black}>
                <h6>Results</h6>
              </Text>
            </th>
          </tr>
        </thead>

        <tbody>
          {matches.map((match, idx) => (
            <tr key={idx} style={{ cursor: match.opponentName ? 'pointer' : 'auto' }}>
              <td>
                <Link to={paths.matchDetail(match.id)}>
                  <Text color={colors.darkgray}>
                    <h6>{dayjs(match.scheduledDate).format('MM-DD-YY')}</h6>
                  </Text>
                </Link>
              </td>

              <td>
                <Link to={paths.matchDetail(match.id)}>
                  <Flex height="100%" alignItems="center">
                    <Text
                      mr={3}
                      color={
                        match.opponentName
                          ? match.score > match.opponentScore
                            ? colors.green
                            : colors.red
                          : colors.darkgray
                      }
                    >
                      <h6>VS</h6>
                    </Text>
                    <Text color={match.opponentName ? colors.primarynavy : colors.darkgray}>
                      <h6>{match.opponentName || 'bye'}</h6>
                    </Text>
                  </Flex>
                </Link>
              </td>
              <td>
                {match.opponentName && (
                  <Link to={paths.matchDetail(match.id)}>
                    <Text color={colors.primarynavy}>
                      <h6>
                        {match.score}:{match.opponentScore}
                      </h6>
                    </Text>
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  )
}

const DashboardMatchHistory: React.FC<IProps> = ({ teamIds, matches }) => {
  const { colors } = useTheme()
  const sortedMatches = [...matches]
    .sort(
      (matchA, matchB) => dayjs(matchB.scheduledDate).unix() - dayjs(matchA.scheduledDate).unix(),
    )
    .map(function(match) {
      const homeTeamId = match.homeTeam ? match.homeTeam.id : ''
      const awayTeamId = match.awayTeam ? match.awayTeam.id : ''
      const teamId = homeTeamId && teamIds.indexOf(homeTeamId) > -1 ? homeTeamId : awayTeamId
      const opponent = opponentTeam(teamId, match.homeTeam, match.awayTeam)
      const games = match.games || []
      return {
        id: match.id,
        score: teamScore(teamId, games as Game[]),
        opponentScore: opponent && opponent.id ? teamScore(opponent.id, games as Game[]) : 0,
        opponentName: opponent && opponent.name,
        scheduledDate: match.scheduledDate,
      }
    })

  return (
    <Box height="100%">
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
      >
        <Text color="black" mb={3}>
          <h2>Match History</h2>
        </Text>
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
        styles={{ height: '100%' }}
      >
        <HistoryTable matches={sortedMatches.slice(0, 6)} lastRowBorder={true} />
        <Flex backgroundColor={colors.white} width="100%" justifyContent="center">
          {matches.length === 0 && (
            <Text my={5}>
              <h6>You do not have any match history</h6>
            </Text>
          )}
        </Flex>
      </Animation>
    </Box>
  )
}

export default DashboardMatchHistory
