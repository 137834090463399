import React, { useContext } from 'react'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import {
  SettingsBlock,
  SettingsModal,
  SettingsHeader,
  SettingsContent,
} from '../../atoms/SettingsPieces'
import SocialProfiles from '../../molecules/SocialProfiles'

interface SocialFieldsProps {
  playerId?: string
}

const SocialFields: React.FC<SocialFieldsProps> = ({ playerId }) => {
  const { showSocialProfilesModal, setShowSocialProfilesModal } = useContext(CurrentUserContext)

  return (
    <SettingsBlock>
      <SettingsHeader
        text="social profiles"
        onSettingsClick={() => setShowSocialProfilesModal(true)}
      />
      <SettingsContent>
        <SocialProfiles onFormSubmitted={() => {}} showUneditableFields />
      </SettingsContent>

      {showSocialProfilesModal && (
        <SettingsModal hideModal={() => setShowSocialProfilesModal(false)}>
          <SocialProfiles
            submitButtonText="update social links"
            onFormSubmitted={() => setShowSocialProfilesModal(false)}
            playerId={playerId}
          />
        </SettingsModal>
      )}
    </SettingsBlock>
  )
}
export default SocialFields
