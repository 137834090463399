import { Box, Flex, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useTheme } from 'emotion-theming'
import dayjs from 'dayjs'
import React, { useState } from 'react'

import { Match } from '../../types/graphql'
import { paths } from '../../utils/Routes'
import { teamScore, opponentTeam } from '../../utils/matchUtils'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import ModalContainer from '../atoms/ModalContainer'
import Paginator from '../molecules/Paginator'
import Table from '../atoms/Table'

interface IMatchLike {
  id: string
  score: number
  opponentScore: number
  opponentName: string | null | undefined
  scheduledDate: string
}

interface IProps {
  teamId: string
  matches: Match[]
  hideTitle?: boolean
}

interface ISeasonHistoryTableProps {
  matches: IMatchLike[]
  opponentColumnTitle: string
  bodyBorder?: boolean
  lastRowBorder?: boolean
  paginate?: boolean
}

const animations = {
  hidden: {
    opacity: 0,
    y: '24px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const SeasonHistoryTable: React.FC<ISeasonHistoryTableProps> = ({
  matches,
  opponentColumnTitle,
  bodyBorder,
  lastRowBorder,
  paginate,
}) => {
  const { colors } = useTheme()
  const itemsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1)

  const paginatedMatches = paginate
    ? matches.slice(
        (currentPage - 1) * itemsPerPage,
        (currentPage - 1) * itemsPerPage + itemsPerPage,
      )
    : matches

  return (
    <Box>
      <Table
        headerBackgroundColor="middlegray"
        lastRowBorder={lastRowBorder}
        bodyBorder={bodyBorder}
        overflow="auto"
      >
        <thead className="notched">
          <tr>
            <th>
              <Text color={colors.black}>
                <h6>Date</h6>
              </Text>
            </th>
            <th>
              <Text color={colors.black}>
                <h6>{opponentColumnTitle}</h6>
              </Text>
            </th>
            <th>
              <Text color={colors.black}>
                <h6>Results</h6>
              </Text>
            </th>
          </tr>
        </thead>

        <tbody>
          {paginatedMatches.map((match, idx) => (
            <tr key={idx} style={{ cursor: match.opponentName ? 'pointer' : 'auto' }}>
              <td>
                <Link to={paths.matchDetail(match.id)}>
                  <Text color={colors.darkgray}>
                    <h6>{dayjs(match.scheduledDate).format('MM-DD-YY')}</h6>
                  </Text>
                </Link>
              </td>

              <td>
                <Link to={paths.matchDetail(match.id)}>
                  <Flex height="100%" alignItems="center">
                    <Text
                      mr={3}
                      color={
                        match.opponentName
                          ? match.score > match.opponentScore
                            ? colors.green
                            : colors.red
                          : colors.darkgray
                      }
                    >
                      <h6>VS</h6>
                    </Text>
                    <Text color={match.opponentName ? colors.primarynavy : colors.darkgray}>
                      <h6>{match.opponentName || 'bye'}</h6>
                    </Text>
                  </Flex>
                </Link>
              </td>
              <td>
                {match.opponentName && (
                  <Link to={paths.matchDetail(match.id)}>
                    <Text color={colors.primarynavy}>
                      <h6>
                        {match.score}:{match.opponentScore}
                      </h6>
                    </Text>
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {paginate && matches.length > itemsPerPage && (
        <Flex justifyContent="center" mt={5}>
          <Paginator
            count={Math.ceil(matches.length / itemsPerPage)}
            onChange={(e, page) => setCurrentPage(page)}
          />
        </Flex>
      )}
    </Box>
  )
}

const TeamDetailSeasonHistory: React.FC<IProps> = ({ teamId, matches, hideTitle }) => {
  const { colors } = useTheme()
  const sortedMatches = [...matches]
    .sort(
      (matchA, matchB) => dayjs(matchB.scheduledDate).unix() - dayjs(matchA.scheduledDate).unix(),
    )
    .map(function(match) {
      const opponent = opponentTeam(teamId, match.homeTeam, match.awayTeam)
      const games = match.games || []
      return {
        id: match.id,
        score: teamScore(teamId, games),
        opponentScore: opponent ? teamScore(opponent.id, games) : 0,
        opponentName: opponent && opponent.name,
        scheduledDate: match.scheduledDate,
      }
    })

  const [showFullListModal, hideFullListModal] = useModal(
    () => (
      <ModalContainer hideModal={hideFullListModal} style={{ content: { maxWidth: '50rem' } }}>
        <SeasonHistoryTable
          matches={sortedMatches}
          opponentColumnTitle={hideTitle ? 'match history' : 'opponent'}
          bodyBorder
          paginate
        />
      </ModalContainer>
    ),
    [sortedMatches],
  )

  return (
    <Box height="100%">
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
      >
        {!hideTitle && (
          <Text color="black" mb={3}>
            <h2>Match History</h2>
          </Text>
        )}
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
        styles={{ height: '100%' }}
      >
        <SeasonHistoryTable
          matches={sortedMatches.slice(0, 6)}
          opponentColumnTitle={hideTitle ? 'match history' : 'opponent'}
          lastRowBorder={true}
        />
        <Flex backgroundColor={colors.white} width="100%" justifyContent="center">
          {matches.length ? (
            matches.length > 6 ? (
              <BaseButton my={5} variant="secondary" onClick={showFullListModal}>
                See All Matches
              </BaseButton>
            ) : null
          ) : (
            <Text my={5}>
              <h6>This team does not have any match history</h6>
            </Text>
          )}
        </Flex>
      </Animation>
    </Box>
  )
}

export default TeamDetailSeasonHistory
