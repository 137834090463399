import { useTheme } from 'emotion-theming'
import React from 'react'
import { Flex, FlexProps, Text } from 'rebass'

import { styled, ThemeColors } from '../../styles/settings/theme'

interface IProps {
  rank?: number | null
  textColor?: ThemeColors
}

interface IStyledRankProps extends FlexProps {
  color?: ThemeColors
}

const StyledRank = styled(Flex)<IStyledRankProps>`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 2px solid ${props => props.color || 'transparent'};
`

const Rank: React.FC<IProps> = ({ rank, textColor }) => {
  const { colors } = useTheme()

  const rankColors = {
    1: colors.sand,
    2: colors.middlegray,
    3: colors.bronze,
  }

  return (
    <StyledRank justifyContent="center" alignItems="center" color={rank && rankColors[rank]}>
      <Text color={textColor || colors.primarynavy}>
        <h6>{rank || '-'}</h6>
      </Text>
    </StyledRank>
  )
}

export default Rank
