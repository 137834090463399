import React from 'react'
import { Box, Flex } from 'rebass'
import { HalfLine, MatchBox, Round } from '../atoms/BracketPieces'
import { MatchNumber } from '../atoms/MatchNumber'

import { BracketText } from '../atoms/BracketText'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const Round2BLosers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)
  return (
    <Round shift={shift}>
      <Box mt={'40px'} mb={'42px'}>
        {halfSeats.map((seat, i) => (
          <Flex key={seat}>
            <MatchBox round="2B" mb={i % 2 !== 0 ? '127px' : '87px'} numTeams={numTeams}>
              <MatchNumber seat={seat} />
              <BracketText seat={seat} homeTeam={true} />
              <BracketText seat={seat} />
            </MatchBox>
            <HalfLine bottomHalf={bottomHalf} />
          </Flex>
        ))}
      </Box>
    </Round>
  )
}

export default Round2BLosers
