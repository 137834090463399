import React, { useContext } from 'react'
import { Text } from 'rebass'
import { SeasonAdminContext } from '../../contexts/SeasonAdminContext'

const AdminSeasonTitle: React.FC = () => {
  const useName = () => {
    const { name } = useContext(SeasonAdminContext)
    return name || 'your season'
  }
  return (
    <Text mb={8}>
      <h2>{`Manage ${useName()}`}</h2>
    </Text>
  )
}

export default AdminSeasonTitle
