import React from 'react'
import { Box } from 'rebass'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { styled } from '../../styles/settings/theme'

interface IProps extends LayoutProps {
  logoUrl: string
  alt: string
}

const Container = styled(Box)`
  width: 4.1875rem;
  height: 4.1875rem;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.white};
  overflow: hidden;
  ${layout}
  ${space}
`

const Logo = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const CircleLogo = ({ logoUrl, alt, ...rest }: IProps & SpaceProps) => {
  return (
    <Container {...rest}>
      <Logo src={logoUrl} alt={alt} />
    </Container>
  )
}

export default CircleLogo
