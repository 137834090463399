import * as R from 'ramda'
import { stringToEnumC } from './typeCoercions'
import { SetState } from '../types/aliases'

export function handleErrors(error: any) {
  if (error.graphQLErrors && error.graphQLErrors.length) {
    return error.graphQLErrors[0].message
  } else if (error.message) {
    return error.message
  } else {
    return 'An unexpected error has occured. Please try again.'
  }
}

interface UserForAuth {
  accessToken: string
  client: string
  expiry: string
  id: string
  uid: string
  username: string
  isAdmin?: boolean
  isIncompleteAccount?: boolean
  teamIds?: Array<string>
}

export function updateUserAuth(user: UserForAuth) {
  localStorage.setItem('csl_access-token', user.accessToken)
  localStorage.setItem('csl_client-token', user.client)
  localStorage.setItem('csl_username', user.username)
  localStorage.setItem('csl_expiry', user.expiry)
  localStorage.setItem('csl_uid', user.uid)
  localStorage.setItem('csl_user_id', user.id)

  if (user.teamIds) {
    sessionStorage.setItem('csl_team_ids', JSON.stringify(user.teamIds))
  }

  if (user.isAdmin) {
    sessionStorage.setItem('csl_user_is_admin', 'true')
  }

  if (user.isIncompleteAccount) {
    sessionStorage.setItem('is_incomplete_account', 'true')
  }
}

// NOTE: these functions are meant to assist anywhere you
// have, for ex., a useState callback fn + a
// fn onChange: (e: React.ChangeEvent<HTMLInputElement>) that pulls out e.target.value
//
// If you just do onChange(setMyState), even if you define
// const [myState, setMyState] = useState<number>(),
// there's no automatic type coercion. This can lead to weird
// bugs
//
// These fns help, i.e.:
// onChange(parseAsNumber(setMyState))
//
function parseWith<A extends any>(
  parseFn: (value: string) => A,
  setStateAction: SetState<A>,
  value: string,
) {
  return setStateAction(parseFn(value))
}

export const parseWithC = R.curry(parseWith)
export const parseAsNumber = parseWithC(Number)
export const parseAsEnum = <EnumType extends any>(eenum: EnumType) =>
  parseWithC(stringToEnumC(eenum))

interface IHasStringValue {
  value: string
}

interface IHasTargetWithStringValue {
  target: IHasStringValue
}

export const valueFrom = (e: IHasTargetWithStringValue) => {
  return e.target.value
}

export default {
  parseWith,
  parseWithC,
  parseAsNumber,
  parseAsEnum,
  valueFrom,
}
