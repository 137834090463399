import LOLlogo from '../assets/small_logos/lol-selector-color.png'
import CSGOlogo from '../assets/small_logos/csgo-selector-color.png'
import DOTA2logo from '../assets/small_logos/dota2-selector-color.png'
import RLlogo from '../assets/small_logos/rl-selector-color.png'
import SFVlogo from '../assets/small_logos/sfv-selector-color.png'
import FORTNITElogo from '../assets/small_logos/fortnite-selector-color.png'
import GEARSlogo from '../assets/small_logos/gears-selector-color.png'
import OWlogo from '../assets/small_logos/ow-selector-color.png'
import MTGAlogo from '../assets/small_logos/mtga-selector-color.png'
import NBAlogo from '../assets/small_logos/nba-selector-color.png'
import HSlogo from '../assets/small_logos/hs-selector-color.png'
import SC2logo from '../assets/small_logos/sc2-selector-color.png'
import SMASHlogo from '../assets/small_logos/smash-selector-color.png'
import NHLlogo from '../assets/small_logos/nhl-selector-color.png'
import MADDENlogo from '../assets/small_logos/madden-selector-color.png'
import LOLprovisional from '../assets/small_logos/lol-provisional.png'
import LOLIron from '../assets/small_logos/lol-bronze.png'
import LOLBronze from '../assets/small_logos/lol-bronze.png'
import LOLSilver from '../assets/small_logos/lol-silver.png'
import LOLGold from '../assets/small_logos/lol-gold.png'
import LOLPlatinum from '../assets/small_logos/lol-platinum.png'
import LOLDiamond from '../assets/small_logos/lol-diamond.png'
import LOLChallanger from '../assets/small_logos/lol-challanger.png'
import LOLMaster from '../assets/small_logos/lol-masters.png'
import LOLGrandMaster from '../assets/small_logos/lol-masters.png'
import VALORANTlogo from '../assets/small_logos/valorant-selector.png'

export function sportLogo(sport: string | null | undefined) {
  const logos: { [key: string]: string } = {
    lol: LOLlogo,
    csgo: CSGOlogo,
    dota2: DOTA2logo,
    rl: RLlogo,
    sfv: SFVlogo,
    fortnite: FORTNITElogo,
    gears: GEARSlogo,
    ow: OWlogo,
    mtga: MTGAlogo,
    nba: NBAlogo,
    hs: HSlogo,
    sc2: SC2logo,
    smash: SMASHlogo,
    nhl: NHLlogo,
    madden: MADDENlogo,
    valorant: VALORANTlogo,
  }
  return logos[String(sport)]
}

export function LolSummonerLogos(tier: string | null | undefined) {
  const logos: { [key: string]: string } = {
    provisional: LOLprovisional,
    bronze: LOLBronze,
    silver: LOLSilver,
    gold: LOLGold,
    platinum: LOLPlatinum,
    diamond: LOLDiamond,
    challanger: LOLChallanger,
    master: LOLMaster,
    grandmaster: LOLGrandMaster,
    iron: LOLIron,
    null: LOLprovisional,
  }
  return logos[String(tier)]
}

export default {
  sportLogo,
}
