import React from 'react'
import SizzleReel from '../../assets/videos/SizzleReel.mp4'

interface IVideoProps {
  controls?: boolean
  autoPlay?: boolean
}

const Video: React.FC<IVideoProps> = () => {
  return (
    <video
      preload="auto"
      title="CSL Sizzle Reel"
      width="auto"
      height="600px"
      style={{ marginBottom: '-3px' }}
      controls
      autoPlay
    >
      <source src={SizzleReel} type="video/mp4" />
    </video>
  )
}
export default Video
