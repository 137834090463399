import React from 'react'
import { Couplet, MatchBox } from './../atoms/BracketPieces'
import { MatchNumber } from '../atoms/MatchNumber'

import { BracketText } from './../atoms/BracketText'

interface CoupletProps {
  round?: string
  bottom?: string
  seats: Array<number>
  flip?: boolean
  numTeams?: number
  bottomHalf?: boolean
  isDoubleElim?: boolean
}

const BracketCouplet: React.FunctionComponent<CoupletProps> = ({
  seats,
  round,
  bottom,
  flip,
  numTeams,
  bottomHalf,
  isDoubleElim,
}) => {
  //for each seat, we need one matchbox
  return (
    <Couplet>
      {seats.map((seat, index) => (
        <MatchBox
          key={index}
          round={round}
          bottom={bottom}
          flip={flip}
          numTeams={numTeams}
          bottomHalf={bottomHalf}
          isDoubleElim={isDoubleElim}
        >
          <MatchNumber flip={flip} seat={seat} />
          <BracketText seat={seat} homeTeam={true} />
          <BracketText seat={seat} />
        </MatchBox>
      ))}
    </Couplet>
  )
}

export default BracketCouplet
