import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { CheckCircle } from 'react-feather'
import { GiCrossedSabres } from 'react-icons/gi'
import { useModal } from 'react-modal-hook'
import { Box, Flex, Text } from 'rebass'

import { MediaQueries as Mq, styled, Theme } from '../../styles/settings/theme'
import { Forfeit, Team, Tournament } from '../../types/graphql'
import { adjustedFullWithShortMonthAndTime } from '../../utils/dateTimeFormats'
import { prettyName } from '../../utils/sportUtils'
import { BaseButton } from '../atoms/Buttons'
import ContentContainer from '../atoms/ContentContainer'
import ModalContainer from '../atoms/ModalContainer'
import ForfeitFrom from '../organisms/ForfeitForm'
import ViewForfeit from '../organisms/ViewForfeit'
import RescheduleMatchButton from './RescheduleMatchButton'

interface IProps {
  tournament: Tournament | null
  sportId: string
  scheduledDate: string
  settled: boolean
  rescheduleDate?: string
  currentUserCanReport: boolean
  currentUserIsCoordinator: boolean
  currentUserIsTeamMember: boolean
  forfeit?: Forfeit
  matchId: string
  teams: Array<Team>
}

interface ICenterContainerProps {
  complete: boolean
  declinedForfeit: boolean
}

const Container = styled(Box)`
  background: ${props => props.theme.colors.white};
`
const CenterContainer = styled(Flex)<ICenterContainerProps>`
  border: 2px solid
    ${props =>
      props.complete
        ? props.theme.colors.green
        : props.declinedForfeit
        ? props.theme.colors.red
        : props.theme.colors.sand};
  max-width: 16rem;
  display: flex;
  justify-content: space-between;

  ${Mq.sm} {
    margin: 0 auto;
  }
`
const CenterText = styled.h6`
  white-space: pre-line;
  text-align: right;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const TimeContainer = styled(Box)`
  text-align: left;

  ${Mq.sm} {
    text-align: right;
  }
`

const MatchDetailSubHero: React.FunctionComponent<IProps> = ({
  scheduledDate,
  tournament,
  sportId,
  settled,
  currentUserCanReport,
  currentUserIsCoordinator,
  currentUserIsTeamMember,
  rescheduleDate,
  forfeit,
  matchId,
  teams,
}: IProps) => {
  const date = dayjs(scheduledDate)
  const [remainingSeconds, setRemainingSeconds] = useState(date.diff(dayjs(), 'second'))
  const remainingHours = date.diff(dayjs(), 'hour')

  const pendingForfeit = forfeit && forfeit.accepted === null && currentUserCanReport
  const declinedForfeit = forfeit && forfeit.accepted === false && currentUserCanReport
  const showReportForfeitButton =
    currentUserCanReport && !settled && (!forfeit || forfeit.accepted === false)
  const showViewPendingForfeitButton =
    currentUserCanReport && !currentUserIsTeamMember && !settled && pendingForfeit

  const [showForfeitModal, hideForfeitModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideForfeitModal}
        style={{
          content: { maxWidth: '41.25rem' },
        }}
      >
        <ForfeitFrom
          matchId={matchId}
          closeModal={hideForfeitModal}
          userIsAdmin={currentUserCanReport && !currentUserIsTeamMember}
          teams={teams!}
        />
      </ModalContainer>
    ),
    [],
  )

  const [showViewForfeitModal, hideViewForfeitModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideViewForfeitModal}
        style={{
          content: { maxWidth: '60rem' },
        }}
      >
        <ViewForfeit forfeit={forfeit!} closeModal={hideViewForfeitModal} />
      </ModalContainer>
    ),
    [],
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingSeconds(date.diff(dayjs(), 'second'))
    }, 1000)

    return function cleanup() {
      clearInterval(interval)
    }
  }, [date])

  const renderCenterText = () => {
    if (settled) {
      return 'Match Complete'
    } else if (remainingHours >= 24) {
      const remainingDays = date.diff(dayjs(), 'day')
      return `Your match will \nbegin in ${remainingDays} day${remainingDays > 1 ? 's' : ''}`
    } else if (remainingHours < 24 && date.isAfter(dayjs())) {
      return (
        <>
          <Box>Matches begin in</Box>
          <Text color="red">
            {dayjs()
              .startOf('day')
              .second(remainingSeconds)
              .format('H:mm:ss')}
          </Text>
        </>
      )
    } else if (pendingForfeit) {
      return 'Match Forfeit Pending'
    } else if (declinedForfeit) {
      return 'Forfeit Request Declined'
    } else {
      return 'Match Reporting \nIncomplete'
    }
  }

  return (
    <Container>
      <ContentContainer bg="transparent" flexWrap="wrap" alignItems="center" p={[3, 3, 5, 6]}>
        <Box width={[1, 1, 1 / 3]}>
          <h3>{tournament ? 'Tournament' : 'League'} Match</h3>
          <Text color="darkgray" mt={3}>
            <Box mb={1}>
              <h6>{tournament ? tournament.name : null}</h6>
            </Box>

            <h6>
              {sportId ? prettyName(sportId) : 'Sport Unvailable'}
              {/* Round of TODO: FILL IN{} */}
            </h6>
          </Text>
        </Box>

        <Box width={[1, 1, 1 / 3]} px={[0, 0, 5]} py={[5, 5, 0]}>
          <CenterContainer
            p={[2, 2, 3]}
            complete={settled}
            declinedForfeit={!!declinedForfeit}
            width="100%"
          >
            {settled ? (
              <CheckCircle size={38} color={Theme.colors.green} />
            ) : (
              <GiCrossedSabres
                size={38}
                color={declinedForfeit ? Theme.colors.red : Theme.colors.sand}
              />
            )}

            <Text color="darkgray" ml={7}>
              <CenterText>{renderCenterText()}</CenterText>
            </Text>
          </CenterContainer>
          {(showReportForfeitButton || showViewPendingForfeitButton) && (
            <Flex m={[0, 0, 'auto']} width="100%" maxWidth="16rem">
              <BaseButton
                variant="secondary"
                width="100%"
                mt="0.5rem"
                onClick={showReportForfeitButton ? showForfeitModal : showViewForfeitModal}
                fullWidth
              >
                {showReportForfeitButton ? 'report forfeit' : 'view pending forfeit'}
              </BaseButton>
            </Flex>
          )}
        </Box>

        <TimeContainer width={[1, 1, 1 / 3]}>
          <Text color="darkgray">
            <Box mb={1}>
              <h6>{date.format('dddd')}</h6>
            </Box>

            <h6>{adjustedFullWithShortMonthAndTime(scheduledDate)}</h6>
          </Text>
          {dayjs().isAfter(scheduledDate) ? null : (
            <RescheduleMatchButton
              matchId={matchId}
              rescheduleDate={rescheduleDate}
              scheduledDate={scheduledDate}
              currentUserIsCoordinator={currentUserIsCoordinator}
            >
              Reschedule Match
            </RescheduleMatchButton>
          )}
        </TimeContainer>
      </ContentContainer>
    </Container>
  )
}
export default MatchDetailSubHero
