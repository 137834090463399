import React from 'react'
import { Box, Flex, Link, Text } from 'rebass'

import { paths } from '../../utils/Routes'
import { Theme } from '../../styles/settings/theme'
import { Team } from '../../types/graphql'

import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'
import League from '../atoms/League'
import SportLogo from '../atoms/SportLogo'
import Table from '../atoms/Table'

interface IProps {
  teams: Array<Team>
}

const PlayerOldTeams: React.FunctionComponent<IProps> = ({ teams }) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Box mt={8}>
      <Flex mb={3} alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Box>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
          >
            <h2>
              Previous Teams
              <Text color="darkgray" as="span" ml={2}>
                ({teams.length})
              </Text>
            </h2>
          </Animation>
        </Box>
      </Flex>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
      >
        <Table headerBackgroundColor="middlegray">
          <thead>
            <tr>
              <th>
                <Text color="transparent">Logo</Text>
              </th>
              <th>
                <Text color="secondarynavy">
                  <h6>Team</h6>
                </Text>
              </th>
              <th>
                <Text color="transparent">
                  <h6>Conference</h6>
                </Text>
              </th>
              <th>
                <Text color="transparent">
                  <h6>League</h6>
                </Text>
              </th>
              <th>
                <Text color="transparent">
                  <h6>Record</h6>
                </Text>
              </th>
              <th>
                <Text color="transparent">Link</Text>
              </th>
            </tr>
          </thead>

          <tbody>
            {teams.map(team => (
              <tr key={team.id}>
                <td>{team.sportSlug && <SportLogo width="1.5625rem" sport={team.sportSlug} />}</td>
                <td>
                  <Text color="secondarynavy">
                    <h6>{team.name}</h6>
                  </Text>
                </td>
                <td>
                  <Text color="darkgray">
                    <h6>{team.selectedSeasonConferenceName}</h6>
                  </Text>
                </td>

                <td>
                  {team.selectedSeason && team.selectedSeason.divisionName && (
                    <League
                      league={team.selectedSeason && team.selectedSeason.divisionName}
                      color={Theme.colors.secondarynavy}
                    />
                  )}
                </td>

                <td>
                  {team.selectedSeasonStats && (
                    <Text color="darkgray">
                      <h6>
                        {team.selectedSeasonStats.wins}-{team.selectedSeasonStats.losses}
                      </h6>
                    </Text>
                  )}
                </td>

                <td>
                  <Link href={paths.teamDetail(team.id)}>
                    <BaseButton variant="secondary">View Team</BaseButton>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Animation>
    </Box>
  )
}

export default PlayerOldTeams
