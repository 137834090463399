import gql from 'graphql-tag'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Flex } from 'rebass'

import { useChangeAccountSettingsFieldMutation as useChangeAccountSettingsField } from '../../types/graphql'
import { AuthenticationAttributes } from '../../types/fragments'
import { spaceify } from '../../utils/strings'
import { handleResult, errorOn } from '../../utils/results'
import { updateUserAuth } from '../../utils/formUtils'
import LoadingSpinner from '../atoms/LoadingSpinner'
import SectionTitle from '../atoms/SectionTitle'
import { BaseButton } from '../atoms/Buttons'
import { InputFieldWithErrors } from './InputFieldWithErrors'

gql`
  mutation ChangeAccountSettingsField($personalEmail: String, $username: String, $id: ID) {
    updateUser(personalEmail: $personalEmail, username: $username, id: $id) {
      success
      value {
        ...AuthenticationAttributes
      }
      errors {
        field
        message
      }
    }
  }
  ${AuthenticationAttributes}
`

interface IChangeAccountSettingsFieldProps {
  fieldName: string
  currentField: string
  onFormSubmitted: (value: string) => void
  userId?: string
  fieldType?: string
  fieldLabel?: string
}

const ChangeAccountSettingsField: React.FC<IChangeAccountSettingsFieldProps> = ({
  fieldName,
  currentField,
  onFormSubmitted,
  userId,
  fieldType,
  fieldLabel,
}) => {
  const [field, setField] = useState(currentField)
  const [error, setError] = useState('')
  const [changeField, { loading }] = useChangeAccountSettingsField()

  const label = fieldLabel || spaceify(fieldName)

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    try {
      const { data } = await changeField({
        variables: {
          [fieldName]: field,
          id: userId,
        },
      })
      handleResult({
        result: data!.updateUser,
        onSuccess: value => {
          toast.success(`${label} updated!`, { containerId: 'temporary' })
          updateUserAuth(value)
          return onFormSubmitted(field)
        },
        onFailure: errors => {
          return setError(errorOn(fieldName, errors))
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const disableButton = loading || !field || field === currentField || !!error

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Flex flexDirection="column" justifyContent="center">
      <SectionTitle text={`change ${label}`} align="center" />
      <InputFieldWithErrors
        mt={5}
        width="100%"
        type={fieldType || 'text'}
        isEmailField={fieldType === 'email'}
        name={fieldName}
        label={label}
        placeholder={label}
        value={field}
        errorMessage={error}
        updateSingleField={field => {
          setField(field)
        }}
        updateErrors={errors => setError(errors[fieldName])}
      />
      <BaseButton
        mt={5}
        mx="auto"
        variant={disableButton ? 'primaryDisabled' : 'primary'}
        disabled={disableButton}
        onClick={handleSubmit}
      >
        Change {label}
      </BaseButton>
    </Flex>
  )
}

export default ChangeAccountSettingsField
