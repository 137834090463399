import React from 'react'
import { Box, Flex } from 'rebass'
import { styled } from '../../../styles/settings/theme'

import { RosterEntry, Team } from '../../../types/graphql'
import { BaseButton } from '../../atoms/Buttons'
import { CustomTabSelect } from '../../atoms/FormPieces'
import TabLabel from '../../atoms/TabLabel'

const InputBox = styled(Box)`
  position: relative;
`

interface ReportingProps {
  team: Team
  isHomeTeam: boolean
  isWinningTeam: boolean
  setWinner: (value: any) => void
  changeName: (e: React.ChangeEvent, isHomeTeam: boolean) => void
  changeTeam: (e: React.ChangeEvent, isHomeTeam: boolean) => void
  player: string
  selectedTeam: string
  nhlTeams: Array<string>
}

const NhlReportingTable = ({
  team,
  isHomeTeam,
  isWinningTeam,
  setWinner,
  changeName,
  changeTeam,
  player,
  selectedTeam,
  nhlTeams,
}: ReportingProps) => {
  const handleScore = () => {
    setWinner(team.id)
  }
  return (
    <>
      <Box width={[1, 1, 1, 1 / 2]} mt={5} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
        <Flex alignItems="center" justifyContent="space-between">
          <h3>{team.truncatedName}</h3>
          <BaseButton variant={isWinningTeam ? 'selected' : 'select'} onClick={handleScore}>
            select as winner
          </BaseButton>
        </Flex>
        <Box mt={4} p={3} bg="backgroundgray">
          <InputBox mt={4}>
            <TabLabel text="player" />
            <CustomTabSelect
              onChange={e => {
                changeName(e, isHomeTeam)
              }}
              value={player}
            >
              {team.activeRosterEntries &&
                team.activeRosterEntries.map((entry: RosterEntry) => (
                  <option key={entry.id}>{entry.player && entry.player.username}</option>
                ))}
            </CustomTabSelect>
          </InputBox>
          <InputBox mt={6}>
            <TabLabel text="team" />
            <CustomTabSelect
              onChange={e => {
                changeTeam(e, isHomeTeam)
              }}
              value={selectedTeam}
            >
              {nhlTeams.map(team => (
                <option key={team}>{team}</option>
              ))}
            </CustomTabSelect>
          </InputBox>
        </Box>
      </Box>
    </>
  )
}
export default NhlReportingTable
