import { Select } from '@rebass/forms'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

interface IProps {
  outlined?: boolean
  placeholderActive?: boolean
}

export const CustomSelect = styled(Select)<IProps>`
  border: 2px solid
    ${props =>
      props.outlined ? props.theme.colors.primarylightblue : props.theme.colors.darkmiddlegray};
  background-color: ${props =>
    props.outlined ? props.theme.colors.transparent : props.theme.colors.backgroundgray};
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 1rem 1rem 0.8rem;
  color: ${props =>
    props.outlined
      ? props.theme.colors.white
      : props.placeholderActive
      ? 'rgba(102, 102, 102, 0.5)'
      : props.theme.colors.darkgray};

  + svg {
    color: ${props => (props.outlined ? props.theme.colors.white : props.theme.colors.darkgray)};
  }
  min-width: max-content;

  ${Mq.md} {
    min-width: auto;
  }
`
