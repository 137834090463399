import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import ToggleSwitch from '../atoms/ToggleSwitch'
import { useTheme } from 'emotion-theming'
import { Text, Flex } from 'rebass'
import { TeamContext } from '../../contexts/TeamContext'
import { useToggleTeamRecruitingStatusMutation as usetoggleRecruitingStatusMutation } from '../../types/graphql'
import { handleResult, errorOn } from '../../utils/results'

gql`
  mutation toggleTeamRecruitingStatus($id: ID!, $isRecruiting: Boolean!) {
    editTeam(id: $id, isRecruiting: $isRecruiting) {
      success
      errors {
        field
        message
      }
    }
  }
`

const ToggleTeamRecruitingStatus: React.FC = () => {
  const { colors } = useTheme()
  const { id, isRecruiting, setTeamContextLoading } = useContext(TeamContext)

  const [toggleRecruitingStatus, { loading }] = usetoggleRecruitingStatusMutation({
    refetchQueries: ['teamDetailPage'],
    awaitRefetchQueries: true,
    variables: { id, isRecruiting: !isRecruiting },
    onError(error) {
      toast.error(error, { containerId: 'temporary' })
    },
    onCompleted: data => {
      handleResult({
        result: data.editTeam,
        onSuccess: () => {},
        onFailure: errors => {
          return toast.error(errorOn('team', errors), { containerId: 'temporary' })
        },
      })
    },
  })

  useEffect(() => {
    setTeamContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Flex alignItems="center">
      <Text pt={1} mr={2} color={isRecruiting ? colors.orange : colors.darkgray}>
        <h6>{!isRecruiting && 'not '}recruiting</h6>
      </Text>
      <ToggleSwitch
        handleChange={toggleRecruitingStatus}
        checked={isRecruiting}
        disabled={loading}
      />
    </Flex>
  )
}

export default ToggleTeamRecruitingStatus
