import React, { useContext } from 'react'
import { List, Shield, Target, TrendingUp, Users } from 'react-feather'
import { FaDiscord } from 'react-icons/fa'
import { AiFillCrown } from 'react-icons/ai'
import { Box, Flex, Link, Text } from 'rebass'
import { styled, Theme } from '../../styles/settings/theme'

import { SiteSwitch } from '../../utils/sites'
import { TeamContext } from '../../contexts/TeamContext'
import { OpenLeagueLogo } from '../../assets/icons/league-icons'
import Animation from '../atoms/Animation'
import HorizontalTableHeader from '../atoms/HorizontalTableHeader'
import League from '../atoms/League'
import SportLogo from '../atoms/SportLogo'
import Table from '../atoms/Table'

const StyledTable = styled(Table)`
  tbody {
    td:first-of-type {
      width: 3rem;
      padding-right: 0;
    }

    th {
      padding-left: 0;
    }
  }
`

const TeamDetailInformation: React.FC = () => {
  const ordinal = require('ordinal')

  const {
    name,
    coordinator,
    selectedSeasonStats,
    activeRosterEntries,
    sportSlug,
    divisionName,
    seasonStanding,
    conferenceName,
    isRecruiting,
  } = useContext(TeamContext)

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Box>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
      >
        <Text color="black" mb={3}>
          <h2>Team Information</h2>
        </Text>
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
      >
        <HorizontalTableHeader justifyContent="space-between">
          <Text color="white">
            <h6>{name}</h6>
          </Text>

          <SportLogo sport={sportSlug} width="1.875rem" height="1.875rem" />
        </HorizontalTableHeader>

        <StyledTable>
          <tbody>
            <tr>
              <td>
                <Users size={20} color={Theme.colors.darkgray} />
              </td>
              <th>
                <Text color="darkgray">
                  <h6>Players</h6>
                </Text>
              </th>
              <td>
                <Text color="primarynavy">
                  <h6>{activeRosterEntries.length}</h6>
                </Text>
              </td>
            </tr>

            <tr>
              <td>
                <List size={20} color={Theme.colors.darkgray} />
              </td>
              <th>
                <Text color="darkgray">
                  <h6>
                    <SiteSwitch college="Season Record" esports="Record" />
                  </h6>
                </Text>
              </th>
              <td>
                <Text color="primarynavy">
                  {selectedSeasonStats.wins || selectedSeasonStats.losses ? (
                    <h6>
                      {selectedSeasonStats.wins}-{selectedSeasonStats.losses}
                    </h6>
                  ) : null}
                </Text>
              </td>
            </tr>

            <tr>
              <td>
                <TrendingUp size={20} color={Theme.colors.darkgray} />
              </td>
              <th>
                <Text color="darkgray">
                  <h6>
                    <SiteSwitch college="Season Standing" esports="Standing" />
                  </h6>
                </Text>
              </th>
              <td>
                <Text color="primarynavy">
                  {seasonStanding > 0 && <h6>{ordinal(seasonStanding)}</h6>}
                </Text>
              </td>
            </tr>

            <SiteSwitch
              college={
                <>
                  <tr>
                    <td>
                      <OpenLeagueLogo
                        height={'20px'}
                        width={'24px'}
                        colorOne={Theme.colors.darkgray}
                      />
                    </td>
                    <th>
                      <Text color="darkgray">
                        <h6>League</h6>
                      </Text>
                    </th>
                    <td>
                      <Flex justifyContent="flex-end">
                        <League league={divisionName} color="primarynavy" />
                      </Flex>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Shield size={20} color={Theme.colors.darkgray} />
                    </td>
                    <th>
                      <Text color="darkgray">
                        <h6>Conference</h6>
                      </Text>
                    </th>
                    <td>
                      <Text color="primarynavy">
                        <h6>{conferenceName}</h6>
                      </Text>
                    </td>
                  </tr>
                </>
              }
              esports={null}
            />

            <tr>
              <td>
                <Target size={20} color={Theme.colors.darkgray} />
              </td>
              <th>
                <Text color="darkgray">
                  <h6>Status</h6>
                </Text>
              </th>
              <td>
                <Text color="primarynavy">
                  <h6>{isRecruiting ? 'Open to Applications' : 'Applications closed'}</h6>
                </Text>
              </td>
            </tr>

            <tr>
              <td>
                <AiFillCrown size={24} color={Theme.colors.darkgray} />
              </td>
              <th>
                <Text color="darkgray">
                  <h6>Coordinator</h6>
                </Text>
              </th>
              <td>
                <Flex justifyContent="flex-end">
                  <Text color="primarynavy" mr={3}>
                    <h6>{coordinator.username}</h6>
                  </Text>

                  {coordinator.discordId ? (
                    <Link href={'https://discordapp.com/users/' + coordinator.discordId}>
                      <FaDiscord size={20} color="purple" />
                    </Link>
                  ) : (
                    <FaDiscord size={20} color="lightgray" />
                  )}
                </Flex>
              </td>
            </tr>
          </tbody>
        </StyledTable>
      </Animation>
    </Box>
  )
}

export default TeamDetailInformation
