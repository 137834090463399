import { RosterEntry } from '../types/graphql'

export function _numStarters(rosterEntries: RosterEntry[]) {
  return rosterEntries ? teamStarters(rosterEntries).length : 0
}

export const teamStarters = (rosterEntries: RosterEntry[]): RosterEntry[] =>
  rosterEntries.filter(member => member.roleCd === 'main_player')

export const teamSubstitutes = (rosterEntries: RosterEntry[]): RosterEntry[] =>
  rosterEntries.filter(member => member.roleCd !== 'main_player')

export default {
  _numStarters,
}
