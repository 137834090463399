import React from 'react'
import { keyframes } from '@emotion/core'
import { Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import loadingLogo from '../../assets/images/logos/CSL__LoaderIcon.svg'

const rotate = keyframes`
  0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const LoadingIcon = styled(Box)<BoxProps>`
  display: block;
  width: 80px;
  padding: 0.5rem;
  margin: auto;
  animation: ${rotate} 1.4s infinite;
`

const LoadingSpinner: React.FC = () => {
  return (
    <LoadingIcon>
      <img src={loadingLogo} alt="loading animation" />
    </LoadingIcon>
  )
}

export default LoadingSpinner
