import 'minireset.css'
import 'modern-normalize/modern-normalize.css'
import 'dota2-minimap-hero-sprites/assets/stylesheets/dota2minimapheroes.css'
import 'react-datepicker/dist/react-datepicker.css'
import { css } from '@emotion/core'

import { Base } from './elements/base'
import { Overrides } from './elements/overrides'
import { fonts } from './settings/fonts'

// You can also import your fonts and add it above ${Base}
export const GlobalStyles = css`
  ${fonts};
  ${Base};
  ${Overrides}
`
