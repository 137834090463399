import React from 'react'
import { Box } from 'rebass'
import { styled } from '../../styles/settings/theme'

interface IProps {
  children: any
  className?: string
}

const Container = styled(Box)`
  margin: auto 0;
`

const WideContent: React.FC<IProps> = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
)

export default WideContent
