import gql from 'graphql-tag'
import React, { useContext, useState, useEffect } from 'react'
import { SeasonAdminContext } from '../../contexts/SeasonAdminContext'
import DataFetcher from './DataFetcher'
import { AdminSeasonInfoQuery, AdminSeasonTeamsQuery } from '../../types/fragments'
import { useSeasonAdminLazyQuery } from '../../types/graphql'

gql`
  query seasonAdmin($id: ID!) {
    season(id: $id) {
      ...AdminSeasonInfoQuery
      ...AdminSeasonTeamsQuery
    }
  }
  ${AdminSeasonInfoQuery}
  ${AdminSeasonTeamsQuery}
`

interface IProps {
  seasonId: string
  children?: any
}

const AdminSeasonQueryProvider: React.FC<IProps> = ({ seasonId, children }) => {
  const { updateFromQuery } = useContext(SeasonAdminContext)
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)

  const [seasonQuery, { error, loading }] = useSeasonAdminLazyQuery({
    variables: { id: seasonId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data && data.season) {
        updateFromQuery(data)
        setFirstLoadComplete(true)
      }
    },
  })

  useEffect(() => {
    if (!seasonId) {
      return
    }
    seasonQuery()
  }, [seasonId, seasonQuery])

  return (
    <DataFetcher loading={loading && !firstLoadComplete} error={error}>
      {children}
    </DataFetcher>
  )
}

export default AdminSeasonQueryProvider
