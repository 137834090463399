import React from 'react'
import Switch from 'react-switch'
import { Theme } from '../../styles/settings/theme'

interface ISwitchProps {
  handleChange: () => void
  checked: boolean
  disabled?: boolean
  onColor?: string
}

const ToggleSwitch = ({ handleChange, checked, disabled, onColor }: ISwitchProps) => {
  return (
    <Switch
      onChange={handleChange}
      checked={checked}
      disabled={disabled}
      onColor={onColor || Theme.colors.orange}
      offColor={Theme.colors.darkmiddlegray}
      uncheckedIcon={false}
      checkedIcon={false}
      height={20}
      handleDiameter={16}
      width={45}
    />
  )
}

export default ToggleSwitch
