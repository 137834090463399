import React from 'react'
import { Flex } from 'rebass'
import { styled } from '../../styles/settings'

import { ClickEvent } from '../../types/aliases'
import { BaseButton } from '../atoms/Buttons'

interface IFilterProps {
  selectedFilter: string
  filters: string[]
  setSelectedFilter: (event: ClickEvent, value: string) => void
}

const Filter = styled(BaseButton)`
  border-radius: 0px 4px;
`

const Filters = ({ selectedFilter, filters, setSelectedFilter }: IFilterProps) => {
  return (
    <Flex mt={[3, 3, 0]}>
      {filters.map((state: string, index) => (
        <Filter
          onClick={(e: ClickEvent) => setSelectedFilter(e, state)}
          key={state}
          variant={state === selectedFilter ? 'tertiaryOutlined' : 'tertiaryOutlinedDisabled'}
          mr={index === filters.length - 1 ? 0 : 1}
        >
          {state}
        </Filter>
      ))}
    </Flex>
  )
}

export default Filters
