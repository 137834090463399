import { Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import nhlSprites from '../../assets/sprites/nhl-sprites.png'

interface SpriteProps {
  zoom?: string
}

export const NhlSprite = styled(Box)<SpriteProps & BoxProps>`
  background-image: url(${nhlSprites});
  margin-right: 7px;
  zoom: 0.4;
  -moz-transform: scale(0.4);
  vertical-align: text-bottom;
  zoom: ${props => props.zoom};
  -moz-transform: scale(${props => props.zoom});

  &.anaheim-ducks { background-position:  -0px -0px; height: 50px; width: 87px;}
  &.boston-bruins { background-position:  -0px -50px; height: 64px; width: 64px;}
  &.buffalo-sabres { background-position:  -0px -114px; height: 63px; width: 63px;}
  &.calgary-flames { background-position:  -64px -50px; height: 58px; width: 66px;}
  &.carolina-panthers { background-position:  -87px -0px; height: 48px; width: 79px;}
  &.chicago-blackhawks { background-position:  -64px -108px; height: 62px; width: 72px;}
  &.columbus-blue-jackets { background-position:  -136px -108px; height: 67px; width: 78px;}
  &.dallas-stars { background-position:  -136px -175px; height: 70px; width: 84px;}
  &.denver-avalanche { background-position:  -130px -50px; height: 58px; width: 70px;}
  &.detroit-red-wings { background-position:  -214px -108px; height: 59px; width: 79px;}
  &.edmonton-oilers { background-position:  -0px -177px; height: 60px; width: 61px;}
  &.florida-panthers { background-position:  -136px -245px; height: 76px; width: 76px;}
  &.los-angeles-kings { background-position:  -0px -237px; height: 68px; width: 57px;}
  &.minnesota-wild { background-position:  -293px -108px; height: 60px; width: 91px;}
  &.montreal-canadiens { background-position:  -166px -0px; height: 50px; width: 74px;}
  &.nashville-predators { background-position:  -200px -50px; height: 57px; width: 96px;}
  &.new-york-islanders { background-position:  -0px -305px; height: 62px; width: 64px;}
  &.new-york-rangers { background-position:  -0px -367px; height: 60px; width: 62px;}
  &.newark-devils { background-position:  -64px -170px; height: 68px; width: 66px;}
  &.ottawa-senators { background-position:  -0px -427px; height: 67px; width: 56px;}
  &.philadelphia-flyers { background-position:  -240px -0px; height: 47px; width: 66px;}
  &.phoenix-coyotes { background-position:  -64px -238px; height: 70px; width: 67px;}
  &.pittsburgh-penguins { background-position:  -64px -308px; height: 63px; width: 66px;}
  &.san-jose-sharks { background-position:  -384px -108px; height: 67px; width: 81px;}
  &.st-louis-blues { background-position:  -296px -50px; height: 54px; width: 67px;}
  &.tampa-bay-lightning { background-position:  -64px -371px; height: 62px; width: 6px;}
  &.toronto-maple-leafs { background-position:  -136px -321px; height: 68px; width: 63px;}
  &.vancouver-canucks { background-position:  -136px -389px; height: 71px; width: 78px;}
  &.washington-capitals { background-position:  -363px -50px; height: 56px; width: 89px;}
  &.winnipeg-jets { background-position:  -220px -175px; height: 70px; width: 70px;}
}`
