import React, { useState } from 'react'
import { Flex, FlexProps } from 'rebass'
import { styled } from '../../styles/settings/theme'
import { BaseButton } from '../atoms/Buttons'
import TextBox from '../atoms/TextBox'
import DataFetcher from '../organisms/DataFetcher'

// -------------------------------------------------------

// NOTE: Everything commented out in this component is related to stripe and premium subscriptions.
// Once we get the green light to resume premium subscriptions, we should do the following:

// TODO:
// -uncomment everything
// -delete any code that is marked for deletion (will say "DELETE")
// -add back imports for useEffect and BoxProps
// -reorganize import statements

// -------------------------------------------------------

// import { useQuery } from '@apollo/react-hooks'
// import gql from 'graphql-tag'
// import { Theme } from '../../styles/settings'
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
// import { CheckCircle } from 'react-feather'
// import { darken } from 'polished'
// import LoadingSpinner from '../atoms/LoadingSpinner'
// import { InputBox, FieldLabel } from '../atoms/FormPieces'
// import SubscriptionPrice from '../atoms/SubscriptionPrice'

// const GET_PAYMENT_INTENT = gql`
//   query paymentIntent {
//     paymentIntent {
//       clientSecret
//       amount
//       receiptEmail
//     }
//   }
// `

interface OptionContainerProps extends FlexProps {
  selected: boolean
  disabled?: boolean
  expanded?: boolean
}

const OptionContainer = styled(Flex)<OptionContainerProps>`
  padding: 2rem;
  flex-direction: column;
  max-width: 4526px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  overflow: hidden;
  transition: height 0.5s ease-in-out;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

// interface CardFormContainerProps extends BoxProps {
//   error: boolean
// }

// const CardFormContainer = styled(Box)<CardFormContainerProps>`
//   border: 2px solid
//     ${props => (props.error ? props.theme.colors.red : props.theme.colors.darkmiddlegray)};
//   padding: 0.55rem 0.75rem 0.45rem;
// `

// const PaymentConfirmation = styled(Flex)`
//   border: 2px solid ${props => props.theme.colors.green};
//   max-width: 21rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto;
//   padding: 10px 0;
// `

// const CARD_OPTIONS = {
//   style: {
//     base: {
//       iconColor: Theme.colors.primarylightblue,
//       color: Theme.colors.primarynavy,
//       fontFamily: Theme.fonts.condensed,
//       fontSize: '16px',
//       fontSmoothing: 'antialiased',
//       ':-webkit-autofill': { color: Theme.colors.primarynavy },
//       '::placeholder': {
//         color: darken(0.3, Theme.colors.darkmiddlegray),
//         fontFamily: Theme.fonts.condensed,
//       },
//     },
//     invalid: {
//       iconColor: Theme.colors.red,
//       color: Theme.colors.red,
//     },
//   },
// }

interface UserSubscriptionProps {
  onSelectAccountType: (accountType: string) => void
  updateHeardAboutUs: React.Dispatch<React.SetStateAction<string>>
  updateLoading?: (loading: boolean) => void
  parentLoading?: boolean
  isUpdate?: boolean
}

const UserSubscription: React.FunctionComponent<UserSubscriptionProps> = ({
  onSelectAccountType,
  parentLoading,
  updateHeardAboutUs,
}) => {
  const [selectedOption, setSelectedOption] = useState(1)
  return (
    // <DataFetcher
    //   loading={!paymentSuccess && (paymentIntentLoading || parentLoading || !stripe || !elements)}
    //   error={paymentIntentLoadingError}
    //   height="100%"
    // >
    <DataFetcher loading={!!parentLoading} error={undefined} height="100%">
      <Flex flexDirection="column" width="100%" px={[2, 2, 7]}>
        <OptionContainer selected={selectedOption === 1} onClick={() => setSelectedOption(1)}>
          <h2 color="primarynavy">
            <h5>How did you hear about us</h5>
          </h2>
          <Flex justifyContent="space-between">
            <TextBox onChange={(e) => updateHeardAboutUs(e.target.value)} />
          </Flex>
        </OptionContainer>

        <Flex mt={5}>
          <BaseButton variant="primary" onClick={() => onSelectAccountType('')} mx="auto">
            Complete Your Profile
          </BaseButton>
        </Flex>
      </Flex>
    </DataFetcher>
  )
}

export default UserSubscription
