import React from 'react'
import { Box, BoxProps } from 'rebass'
import { Link } from 'react-router-dom'
import { styled } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { Image } from 'rebass'
import DefaultLogoImage from '../../assets/images/logos/CSL_Esports_Primary_White.png'

interface IProps {
  variant: string
}

export const LogoBox = styled(Box)<BoxProps>`
  display: block;
  width: 6rem;
  margin: auto auto auto 0;
`

const FooterLogo = ({ variant }: IProps) => {
  return (
    <LogoBox>
      <Link to={paths.homepage()}>
        <Image
          alt={DefaultLogoImage}
          src={DefaultLogoImage}
        />
      </Link>
    </LogoBox>
  )
}

export default FooterLogo
