import React, { ReactNode } from 'react'
import { Transition, Variants, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

interface IAnimationProps {
  inView?: number
  noRef?: string
  initial?: string
  animate?: string
  exit?: string
  styles?: any
  variants?: Variants
  transition?: Transition
  children?: ReactNode
}

export const IntersectionContext = React.createContext({ inView: true })

const Animation: React.FC<IAnimationProps> = ({
  children,
  noRef,
  initial,
  animate,
  exit,
  styles,
  variants,
  transition,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-0px 0px',
  })

  return (
    <motion.div
      ref={noRef ? null : ref}
      initial={initial}
      exit={exit}
      animate={inView ? animate : 'hidden'}
      variants={variants}
      transition={transition}
      style={styles}
    >
      {children}
    </motion.div>
  )
}

export default Animation
