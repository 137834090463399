import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React from 'react'
import { Bell } from 'react-feather'
import { useModal } from 'react-modal-hook'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'

import { Badge, Hidden, makeStyles } from '@material-ui/core'

import { Theme } from '../../styles/settings'
import {
  useMarkNotificationsReadMutation,
  useMyNotificationsQuery,
} from '../../types/graphql'
import { adjustedFullWithShortMonthAndTime } from '../../utils/dateTimeFormats'
import { handleResult } from '../../utils/results'
import { BaseButton, UnstyledButton } from '../atoms/Buttons'
import ModalContainer from '../atoms/ModalContainer'
import Table from '../atoms/Table'
import DataFetcher from './DataFetcher'

gql`
  query MyNotifications {
    myNotifications {
      id
      description
      title
      read
      createdAt
    }
  }
`

gql`
  mutation MarkNotificationsRead($ids: [ID!]!) {
    markNotificationsRead(ids: $ids) {
      success
      errors {
        field
        message
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: Theme.colors.red,
    },
  },
}))

const Notifications = () => {
  const classes = useStyles()
  const { colors } = useTheme()
  const { data, error, loading } = useMyNotificationsQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [markNotificationsRead, { loading: markAsReadLoading }] = useMarkNotificationsReadMutation({
    refetchQueries: ['MyNotifications'],
    onError: () => {
      toast.error('Failed to mark notification as read.', { containerId: 'temporary' })
    },
    onCompleted: data => {
      handleResult({
        result: data.markNotificationsRead,
        onSuccess: () => {},
        onFailure: () => {
          toast.error('Failed to mark notification as read.', { containerId: 'temporary' })
        },
      })
    },
  })

  const unreadNotifications =
    data && data.myNotifications
      ? data.myNotifications.filter(notification => !notification.read)
      : []

  const markAsReadClickHandler = (notificationId: string) => {
    markNotificationsRead({ variables: { ids: [notificationId] } })
  }

  const markAllAsReadClickHandler = () => {
    markNotificationsRead({
      variables: {
        ids: unreadNotifications.map(notification => notification.id),
      },
    })
  }

  const [showNotificationsModal, hideNotificationsModal] = useModal(
    () => (
      <ModalContainer hideModal={hideNotificationsModal}>
        <DataFetcher error={error} loading={loading || markAsReadLoading}>
          {data && data.myNotifications && (
            <>
              <Flex mb={5} justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Box flex={['0 0 100%', '0 0 100%', 1]} mb={[3, 3, 0]}>
                  <h2>
                    Notifications (
                    <Text as="span" color={unreadNotifications.length > 0 ? colors.red : undefined}>
                      {unreadNotifications.length}
                    </Text>
                    )
                  </h2>
                </Box>
                <Box flex={['0 0 100%', '0 0 100%', '0 1 auto']}>
                  <BaseButton
                    variant={unreadNotifications.length === 0 ? 'secondaryDisabled' : 'secondary'}
                    disabled={unreadNotifications.length === 0}
                    onClick={markAllAsReadClickHandler}
                  >
                    Mark all as read
                  </BaseButton>
                </Box>
              </Flex>

              <Table headerBackgroundColor="middlegray" tdpadding="1rem 1rem" bodyBorder={true}>
                <thead>
                  <tr>
                    <th>
                      <h6>Notification</h6>
                    </th>
                    <th>
                      <Hidden smDown>
                        <h6>Time</h6>
                      </Hidden>
                    </th>

                    <th>
                      <h6>Mark as read</h6>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.myNotifications.map(notification => (
                    <tr key={notification.id}>
                      <td style={{ maxWidth: '35rem' }}>
                        <Text color={notification.read ? null : colors.red} mb={1}>
                          <h6>{notification.title}</h6>
                          <Hidden mdUp>
                            <h6 style={{ padding: '0.25rem 0 0.5rem', color: colors.darkgray }}>
                              {adjustedFullWithShortMonthAndTime(notification.createdAt)}
                            </h6>
                          </Hidden>
                        </Text>

                        <p>
                          <Text lineHeight={'1.4rem'}>{notification.description}</Text>
                        </p>
                      </td>
                      <td>
                        <Hidden smDown>
                          <h6>{adjustedFullWithShortMonthAndTime(notification.createdAt)}</h6>
                        </Hidden>
                      </td>
                      <td>
                        <BaseButton
                          variant={notification.read ? 'secondaryDisabled' : 'secondary'}
                          disabled={notification.read}
                          onClick={() => markAsReadClickHandler(notification.id)}
                        >
                          {notification.read ? (
                            <>
                              <Hidden smDown>Notification </Hidden>read
                            </>
                          ) : (
                            <>
                              <Hidden smDown>Mark as </Hidden>read
                            </>
                          )}
                        </BaseButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </DataFetcher>
      </ModalContainer>
    ),
    [data, error, loading],
  )

  return (
    <UnstyledButton onClick={showNotificationsModal} aria-label="Notifications">
      <Badge badgeContent={unreadNotifications.length} color="primary" className={classes.root}>
        <Bell size={24} color={unreadNotifications.length > 0 ? colors.red : 'currentColor'} />
      </Badge>
    </UnstyledButton>
  )
}

export default Notifications
