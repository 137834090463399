import React, { useState } from 'react'
import { Text, Box, Flex } from 'rebass'

import { BaseButton } from '../atoms/Buttons'
import { CustomSelect } from '../atoms/Select'
import SectionTitle from '../atoms/SectionTitle'
import { ApplyForTeamMutationFn } from '../../types/graphql'

interface ISelectRoleProps {
  hideModal: () => void
  applyToTeam: ApplyForTeamMutationFn
  roles: string[]
  teamId: string
}

const SelectRole: React.FC<ISelectRoleProps> = ({ hideModal, applyToTeam, roles, teamId }) => {
  const [gameRole, setGameRole] = useState('')

  const isButtonDisabled = !gameRole

  const onClick = () => {
    applyToTeam({
      variables: { id: teamId, gameRole },
    })
    hideModal()
  }

  return (
    <Box py={6} px={8}>
      <SectionTitle text="select your role" align="center" />

      <Text textAlign="center" my={5}>
        <p>
          This game has specific roles that make up the team. Please select which role you would
          like to apply for on this team.
        </p>
      </Text>

      <CustomSelect onChange={e => setGameRole(e.target.value)}>
        <option value="">your role</option>
        {roles.map(role => (
          <option key={role}>{role}</option>
        ))}
      </CustomSelect>

      <Flex flexDirection="column" justifyContent="center" mt={6}>
        <BaseButton
          variant={isButtonDisabled ? 'secondaryDisabled' : 'secondary'}
          disabled={isButtonDisabled}
          onClick={onClick}
          mx={'auto'}
        >
          apply for team
        </BaseButton>
      </Flex>
    </Box>
  )
}

export default SelectRole
