import React, { useContext } from 'react'
import { Box, Text, Flex } from 'rebass'
import { AdminTournamentRoundInfoFragment as Round } from '../../types/graphql'
import { TournamentContext } from '../../contexts/TournamentContext'

import { CustomSelect } from '../atoms/Select'
import Table from '../atoms/Table'
import { validSeriesLenghts } from '../../utils/matchUtils'

interface ISettingsProps {
  isWinners: boolean
  changeValue: (e: React.ChangeEvent<HTMLSelectElement>, round: Round, isWinners: boolean) => void
  wide?: boolean
}

const AdminMatchSettingTable = ({ isWinners, changeValue, wide }: ISettingsProps) => {
  const { rounds, seriesLength } = useContext(TournamentContext)

  // todo use enums from backend instead of this
  const roundColors: string[] = [
    'middlegray',
    'primarylightblue',
    'secondarygreen',
    'orange',
    'purple',
    'primarynavy',
    'sand',
  ]
  const seriesOptions = validSeriesLenghts

  let roundsMap = {}

  rounds &&
    rounds.forEach(round => {
      roundsMap[round.id] = round.matchPrototype ? round.matchPrototype.gameCount : seriesLength
    })

  return (
    <Box width={wide ? [1, 1, 1, 1] : [1, 1, 1, 1 / 2]} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
      <Box mt={4}>
        <Table bodyBackgroundColor="white">
          <colgroup>
            <col span={1} style={{ width: '50%' }} />
            <col span={1} style={{ width: '30%' }} />
            <col span={1} style={{ width: '20%' }} />
          </colgroup>

          <thead>
            <tr>
              <th>
                <Text color="white">
                  <h6>{isWinners ? 'winners' : 'losers'} bracket</h6>
                </Text>
              </th>

              <th>
                <Text color="white">
                  <h6>series length</h6>
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {rounds &&
              rounds
                .filter(round =>
                  isWinners
                    ? round.tournamentBracketOrder === 0
                    : round.tournamentBracketOrder === 1,
                )
                .map((round: Round, i: number) => (
                  <tr key={i}>
                    <td>
                      <Flex alignItems="center">
                        <Box size={10} bg={roundColors[i]} mr={2} mb={'2px'}></Box>
                        <Text color="darkgray">
                          <h6>{round.tournamentRoundName}</h6>
                        </Text>
                      </Flex>
                    </td>

                    <td>
                      <CustomSelect
                        key={i}
                        onChange={e => {
                          changeValue(e, round, isWinners)
                        }}
                        value={roundsMap[round.id]}
                        name="length"
                      >
                        {seriesOptions.map((num, i) => (
                          <option key={num}>{num}</option>
                        ))}
                      </CustomSelect>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </Box>
    </Box>
  )
}

export default AdminMatchSettingTable
