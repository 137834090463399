import React from 'react'
import { Box, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import AsciiEmoji from '../atoms/AsciiEmoji'

const Container = styled(Box)`
  background: ${props => props.theme.colors.backgroundgray};
`

const InnerContainer = styled(Box)`
  border: 2px solid ${props => props.theme.colors.middlegray};
  background: ${props => props.theme.colors.white};
  text-align: center;
  padding: 50px 0;
`

const MatchDetailForfeitedMatch = () => (
  <Container>
    <InnerContainer>
      <AsciiEmoji mb={4}>¯\_(ツ)_/¯</AsciiEmoji>
      <Text color="darkgray">
        <Box mt={5}>
          <h6>this match was reported as a forfeit.</h6>
        </Box>
      </Text>
    </InnerContainer>
  </Container>
)

export default MatchDetailForfeitedMatch
