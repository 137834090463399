import React from 'react'
import moment from 'moment-timezone'
import { useTheme } from 'emotion-theming'
import { Flex, Text } from 'rebass'

import { BaseButton } from '../../atoms/Buttons'

interface ICountdownTextProps {
  isDisabledButton?: Boolean
  countdownValue: moment.Duration
  countdownText: string
}

const CountdownText: React.FC<ICountdownTextProps> = ({
  isDisabledButton,
  countdownValue,
  countdownText,
}) => {
  const { colors } = useTheme()
  const content = (
    <Flex justifyContent="center">
      <Text color={colors.darkgray}>
        <h5>{countdownText}</h5>
      </Text>
      <Text ml={2} color={colors.red}>
        <h5>{countdownValue && countdownValue.humanize()}</h5>
      </Text>
    </Flex>
  )

  return isDisabledButton ? (
    <BaseButton width="100%" mt={2} mx="auto" variant="tertiaryOutlinedDisabled" disabled>
      {content}
    </BaseButton>
  ) : (
    content
  )
}

export default CountdownText
