import React from 'react'
import { Flex, Box } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { useTheme } from 'emotion-theming'

import Animation from '../atoms/Animation'

interface ISchedulesMenuProps {
  closeMenu?: () => void
  numberOfWeeks: Number
  currentIndex: Number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
}

const SchedulesMenuWrapper = styled(Flex)`
  flex-direction: column;
  padding: 1rem 0;
  text-align: center;
  z-index: 2;
  overflow: hidden;
  position: absolute;
  background: ${props => props.theme.colors.sand};
  top: 3rem;
  right: 0;
  width: 100%;
  z-index: 2;
  --notchSize: 20px;

  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 0px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );

  h5 {
    padding: 0 0 1rem;
    transition: ${props => props.theme.transitions.fast};
    cursor: pointer;
    color: ${props => props.theme.colors.primarynavy};
    :hover {
      color: ${props => props.theme.colors.primarylightblue};
    }
    :last-of-type {
      padding: 0;
    }
  }

  ${Mq.md} {
    padding: 0;
  }
`

const MenuBorder = styled(Flex)`
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  --notchSize: 20px;
  background: ${props => props.theme.colors.white};

  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 0px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
`

const SchedulesLinks = styled(Box)`
  z-index: 9;
  max-height: 280px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.lightgray};
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.sand};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.sand};
    cursor: pointer;
  }
`

const StyledFlex = styled(Flex)`
  padding: 1rem 0;
  z-index: 1;
`

const PaddingTop = styled(Box)`
  width: 90%;
  margin: 0 auto;
  height: 1rem;
  background: ${props => props.theme.colors.white};
  position: absolute;
  z-index: 999;
  top: 0;
  border-top: 2px solid;
  border-left: 2px solid;
  border-color: ${props => props.theme.colors.sand};
  left: 0;
`

const PaddingBottom = styled(Box)`
  width: 90%;
  margin: 0 auto;
  height: 1rem;
  background: ${props => props.theme.colors.white};
  position: absolute;
  z-index: 999;
  bottom: 0;
  border-bottom: 2px solid;
  border-color: ${props => props.theme.colors.sand};
  right: 0.25rem;
`

const animations = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

const SchedulesMenu: React.FC<ISchedulesMenuProps> = ({
  numberOfWeeks,
  currentIndex,
  setCurrentIndex,
}) => {
  const templateArray = new Array(numberOfWeeks).fill(1)
  const { colors } = useTheme()

  return (
    <Animation
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.2 }}
    >
      <SchedulesMenuWrapper id="schedulesMenu">
        <SchedulesLinks>
          <PaddingTop />
          <StyledFlex flexDirection="column">
            {templateArray.map((ignoreMe, idx) => (
              <Box onClick={() => setCurrentIndex(idx)}>
                <h5 color={currentIndex === idx ? colors.primaryblue : ''}>
                  Week {idx + 1} Schedule
                </h5>
              </Box>
            ))}
          </StyledFlex>
          <PaddingBottom />
        </SchedulesLinks>

        <MenuBorder />
      </SchedulesMenuWrapper>
    </Animation>
  )
}

export default SchedulesMenu
