import { darken } from 'polished'
import { styled } from '../../styles/settings/theme'

const StyledTextArea = styled.textarea`
  width: 100%;
  color: ${props => darken(0.3, props.theme.colors.darkmiddlegray)};
  border: solid 2px ${props => props.theme.colors.darkmiddlegray};
  background-color: ${props => props.theme.colors.backgroundgray};
  padding: 0.75rem;
  max-width: 100%;
  min-width: 100%;
  min-height: 6rem;
  outline: none;
`
export default StyledTextArea
