import React from 'react'
import { Text, Box, Flex } from 'rebass'

interface ColorKeyProps {
  bracket: string
  numTeams: number
}

const BracketColorKey = ({ bracket, numTeams }: ColorKeyProps) => {
  const winnersInfo: { [key: string]: string } = {
    middlegray: 'round of 128',
    primarylightblue: 'round of 64',
    secondarygreen: 'round of 32',
    orange: 'round of 16',
    purple: 'round of 8',
    primarynavy: 'semi finals',
    sand: 'finals',
  }

  const getLosersInfo = (size: number) => {
    const losersInfo: { [key: string]: string | { [key: string]: string } } = {
      middlegray: 'round of 64A',
      primarylightblue: size === 64 ? 'round of 32A' : 'round of 64B/32A',
      secondarygreen: size === 32 ? 'round of 16A' : 'round of 32B/16A',
      orange: size === 16 ? 'round of 8A' : 'round of 16B/8A',
      purple: size === 8 ? 'round of 4A' : 'round of 8B/4A',
      primarynavy: size === 4 ? 'losers semifinals' : 'round of 4B/2A',
      sand: 'losers finals',
    }
    return losersInfo
  }

  //adjust color key for bracket size: amount to slice
  const adjustKey: { [key: number]: number } = {
    128: 0,
    64: 1,
    32: 2,
    16: 3,
    8: 4,
    4: 5,
  }

  const roundInfo = Object.keys(bracket === 'losers' ? getLosersInfo(numTeams) : winnersInfo).slice(
    adjustKey[numTeams],
  )

  const getWidth = (bracket: string, numTeams: number) => {
    const widthPxAmount: { [key: number]: string } = {
      128: bracket === 'winners' ? '900px' : '1100px',
      64: bracket === 'winners' ? '800px' : '1100px',
      32: bracket === 'winners' ? '650px' : '850px',
      16: bracket === 'winners' ? '550px' : '700px',
      8: bracket === 'winners' ? '420px' : '500px',
      4: bracket === 'winners' ? '270px' : '390px',
    }
    return widthPxAmount[numTeams]
  }
  return (
    <Flex mt={7} justifyContent="center" mx={'auto'} width={getWidth(bracket, numTeams)}>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mt={bracket === 'losers' ? 1 : 0}
        mx={6}
      >
        {roundInfo.map((color: string, i) => (
          <Flex key={i} alignItems="center">
            <Box width="6px" height="12px" bg={color} mb={'2px'} mr="6px" />
            <Text color={i < roundInfo.length - 2 ? 'primarynavy' : 'darkgray'}>
              <h6>{bracket === 'losers' ? getLosersInfo(numTeams)[color] : winnersInfo[color]}</h6>
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default BracketColorKey
