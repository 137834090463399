import { css } from '@emotion/core'
import { Flex, FlexProps, Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

interface CenterProps {
  lower?: boolean
  numTeams: number
  isDoubleElim?: boolean
}

export const CenterBracketContent = styled(Flex)<CenterProps & FlexProps>`
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  height: 200px;
  min-width: ${props =>
    props.numTeams === 128
      ? '1440px'
      : props.lower
      ? props.numTeams === 16 || props.numTeams === 8 || props.numTeams === 4
        ? '1040px'
        : '1440px'
      : '1040px'};
`
interface SideBoxProps {
  lower?: boolean
  side?: string
  isDoubleElim?: boolean
}
export const SideBox = styled(Flex)<SideBoxProps & FlexProps>`
  background-color: ${props => props.theme.colors.white};
  border: 2px solid
    ${props => (props.isDoubleElim ? props.theme.colors.sand : props.theme.colors.primarynavy)};
  min-width: 275px;
  max-width: 350px;
  width: 100%;
  height: 146px;
  padding: 1rem 1.25rem;

  ${props =>
    props.lower
      ? props.side === 'left'
        ? css`
            visibility: hidden;
          `
        : css`
            border-color: ${props.theme.colors.primarynavy};
          `
      : null}
`
export const CenterBox = styled(Flex)<FlexProps>`
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.sand};
  width: 29.5rem;
  min-width: 450px;
  height: 200px;
  padding: 1rem 1.25rem;
`

export const TopRow = styled(Flex)<FlexProps>`
  border-bottom: 1px solid ${props => props.theme.colors.middlegray};
`
export const VS = styled(Flex)<FlexProps>`
  width: 3rem;
  height: 3rem;
  border: 1px solid ${props => props.theme.colors.darkmiddlegray};
  border-radius: 50%;
  text-align: center;
`
export const Thirds = styled(Box)<BoxProps>`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  margin-top: 3rem;
`
export const Gray = styled(Box)<BoxProps>`
  background-color: ${props => props.theme.colors.backgroundgray};
  width: 100%;
  height: 5rem;
`
export const Boxes = styled(Flex)<SideBoxProps & FlexProps>`
  width: 100%;
  position: absolute;
  left: 0;
  align-items: center;
  justify-content: center;
  height: 277px;
`

interface DashProps {
  lower?: boolean
}
export const Dash = styled(Box)<DashProps & BoxProps>`
  border-top: 2px solid ${props => props.theme.colors.darkmiddlegray};
  height: 4px;
  width: 52%;
  margin-left: auto;
  ${props =>
    props.lower &&
    css`
      width: 25%;
      margin-left: 50%;
    `}
  margin-right: auto;
  transform: translateY(2.5rem);
`
interface CenterBracketLineProps {
  bottomHalf?: boolean
  isDoubleElim?: boolean
  numTeams: number
}

export const SideBoxLeftJoiner = styled(Box)<CenterBracketLineProps & BoxProps>`
  border-top: 2px solid ${props => props.theme.colors.middlegray};
  border-left: 2px solid ${props => props.theme.colors.middlegray};
  height: 65px;
  transform: translateX(50%);
  z-index: 1;
  ${props =>
    props.bottomHalf &&
    css`
      border-top: none;
      border-bottom: 2px solid ${props.theme.colors.middlegray};
    `}
  visibility: ${props =>
    !props.isDoubleElim && (props.bottomHalf || props.numTeams <= 4) ? 'hidden' : 'visible'}
`
export const SideBoxRightJoiner = styled(Box)<CenterBracketLineProps & BoxProps>`
  border-bottom: 2px solid ${props => props.theme.colors.middlegray};
  border-right: 2px solid ${props => props.theme.colors.middlegray};
  height: 65px;
  transform: translateX(-50%);
  z-index: 1;
  visibility: ${props => (props.isDoubleElim || props.numTeams <= 4 ? 'hidden' : 'visible')};
`
export const SideContainer = styled(Flex)<FlexProps>`
  min-width: 275px;
  max-width: 350px;
  width: 100%;
  height: 100%;
`
export const Circle = styled(Box)<BoxProps>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`
