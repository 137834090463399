import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Text, Flex } from 'rebass'
import { useTheme } from 'emotion-theming'
import { GiBrutalHelm } from 'react-icons/gi'

import SportLogo from '../../atoms/SportLogo'
import { BaseButton } from '../../atoms/Buttons'
import { SettingsTableHeader, SettingsTable } from '../../atoms/SettingsPieces'
import { RosterEntry } from '../../../types/graphql'
import { paths } from '../../../utils/Routes'
import { currentUserIsAdmin } from '../../../utils/admins'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'

const ActiveRosterEntries: React.FC = () => {
  const { colors } = useTheme()
  const { activeRosterEntries } = useContext(CurrentUserContext)

  const showManageTeam = (rosterEntry: RosterEntry) => {
    return rosterEntry.team.currentUserIsCoordinator || currentUserIsAdmin()
  }

  const sortedRosterEntries = activeRosterEntries.sort((a, _b) =>
    a.team.currentUserIsCoordinator ? -1 : 1,
  )

  return activeRosterEntries.length ? (
    <SettingsTable>
      <thead>
        <tr>
          <SettingsTableHeader text="teams" />
          <SettingsTableHeader text="record" />
          <SettingsTableHeader text="role" />
          <SettingsTableHeader text="actions" />
        </tr>
      </thead>

      <tbody>
        {sortedRosterEntries.map((rosterEntry, index) => (
          <tr key={index}>
            <td style={{ width: '30%' }}>
              <Flex alignItems="center">
                <SportLogo height="2rem" width="2rem" sport={rosterEntry.team.sportSlug} />
                <Text color={colors.black} ml={5} mr={4}>
                  <h6>{rosterEntry.team.name}</h6>
                </Text>
                {rosterEntry.team.currentUserIsCoordinator && (
                  <GiBrutalHelm size={24} color={colors.sand} />
                )}
              </Flex>
            </td>
            <td>
              <Flex alignItems="center">
                <Text color={colors.darkgray}>
                  <h6>{rosterEntry.team.overallWins}-</h6>
                </Text>
                <Text color={colors.darkgray}>
                  <h6>{rosterEntry.team.overallLosses}</h6>
                </Text>
              </Flex>
            </td>

            <td>
              <Text color={colors.darkgray}>
                <h6>{rosterEntry.gameRole}</h6>
              </Text>
            </td>
            <td>
              <Link
                to={
                  showManageTeam(rosterEntry)
                    ? paths.manageTeam(rosterEntry.team.id)
                    : paths.teamDetail(rosterEntry.team.id)
                }
              >
                <BaseButton variant="secondary" width="11rem">
                  {showManageTeam(rosterEntry) ? 'manage' : 'view'} team
                </BaseButton>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </SettingsTable>
  ) : null
}
export default ActiveRosterEntries
