import _ from 'lodash'
import * as R from 'ramda'

export const capitalize = _.capitalize
export const camelCase = _.camelCase
export const startCase = _.startCase

export const spaceify = (str: string) => {
  return _.replace(str, /_|-/, ' ')
}

export const upperCamelCase = R.compose(_.upperFirst, _.camelCase)

export default {
  capitalize,
  spaceify,
  upperCamelCase,
  camelCase,
  startCase,
}
