import React, { useState } from 'react'
import { FiPlay } from 'react-icons/fi'
import { useModal } from 'react-modal-hook'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'
import HeroImage from '../../assets/images/hero__home-page.jpg'
import { MediaQueries as Mq, styled, Theme } from '../../styles/settings/theme'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'
import ModalContainer from '../atoms/ModalContainer'
import SportLogo from '../atoms/SportLogo'
import Video from '../atoms/Video'
import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'

interface IHeroProps {
  borderColor?: any
}

const StyledBox = styled(Box)`
  padding: 0 0 3rem 0;
  p {
    max-width: 480px;
  }
`

const StyledWideContent = styled(WideContent)`
  background: ${props => props.theme.colors.primarynavy};
  background-image: url('${HeroImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 20rem;

  ${Mq.md} {
    background-position: bottom right;
    padding-top: 300px;
  }
`
const VideoLightbox = styled(Box)`
  margin: 0;
  display: flex;
  align-items: center;

  h4 {
    cursor: pointer;
    color: ${props => props.theme.colors.white};
  }

  @media screen and (max-width: 1200px) and (min-width: 961px) {
    margin-left: 0px;
    margin-top: 1rem;
  }
  ${Mq.md} {
    margin: 0 0 0 2rem;
  }
`

const StyledPlayButton = styled.div<IHeroProps>`
  margin: 0px 1rem 0 0;
  display: flex;
  align-items: center;
  border-width: 3px;
  border-color: ${props => props.borderColor};
  border-radius: 40px;
  border-style: solid;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  transition: ${props => props.theme.transitions.fast};
  position: relative;

  :hover {
    border-color: ${props => props.theme.colors.secondaryblue};
  }

  svg {
    fill: ${props => props.theme.colors.white};
    stroke: ${props => props.theme.colors.white};
    position: absolute;
    left: 1rem;
    top: 12px;
  }
`

const HomePageHero: React.FC<IHeroProps> = () => {
  const [state, setState] = useState({
    hover: false,
  })

  const handleShowBorder = () => {
    setState({ hover: true })
  }

  const handleHideBorder = () => {
    setState({ hover: false })
  }

  const [showVideoModal, hideVideoModal] = useModal(() => (
    <ModalContainer hideModal={hideVideoModal} noPadding>
      <Video />
    </ModalContainer>
  ))

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <StyledWideContent>
      <WideContainer>
        <StyledBox width={1}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4 }}
          >
            <Text color={Theme.colors.primarylightblue} mb={2}>
              <h5>Powered by</h5>
            </Text>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <Text color={Theme.colors.white}>
              <h1>csl esports</h1>
            </Text>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
          >
            <Text color={Theme.colors.white} my={5}>
              <p>Join Telus and and CSL for 6 weeks of esports fun for all skill levels!</p>
            </Text>
          </Animation>
          <Flex alignItems="center" mt={2} mb={[0, 4, 4, 7]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
            >
              <Link to={paths.signup()}>
                <BaseButton variant="primary" mr={4}>
                  sign up today
                </BaseButton>
              </Link>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
            >
              <VideoLightbox>
                <StyledPlayButton
                  borderColor={
                    state.hover ? Theme.colors.secondaryblue : Theme.colors.primarylightblue
                  }
                  onClick={() => showVideoModal()}
                >
                  <FiPlay size={24} />
                </StyledPlayButton>
                <h4
                  onMouseEnter={handleShowBorder}
                  onMouseLeave={handleHideBorder}
                  onClick={() => showVideoModal()}
                >
                  ABOUT TELUS
                </h4>
              </VideoLightbox>
            </Animation>
          </Flex>
        </StyledBox>
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Flex mt={4} mb={4} justifyContent="space-between" alignItems="center" width={'100%'}>
            <Box width={'33%'} mr={2}>
              <Text color={Theme.colors.white}>
                <h2>Great Games</h2>
                <p>
                  <Flex flexDirection="row" alignItems="center">
                    <Box mr={2} paddingTop={1}>
                      <SportLogo sport="fortnite" width="20px" height="auto" />
                    </Box>{' '}
                    Fortnite <br />
                  </Flex>
                  <Flex flexDirection="row" alignItems="center">
                    <Box mr={2} paddingTop={1}>
                      <SportLogo sport="rl" width="20px" height="auto" />
                    </Box>{' '}
                    Rocket League <br />
                  </Flex>
                  <Flex flexDirection="row" alignItems="center">
                    <Box mr={2} paddingTop={1}>
                      <SportLogo sport="nhl" width="20px" height="auto" />
                    </Box>{' '}
                    NHL <br />
                  </Flex>
                </p>
              </Text>
            </Box>

            <Box width={'33%'} mr={2}>
              <Text color={Theme.colors.white}>
                <h2>Great Prizes</h2>
                <p>
                  <span role="img" aria-label="headphones">
                    🎧
                  </span>
                  {'  '}
                  Headsets <br />
                  <span role="img" aria-label="keyboard">
                    ⌨️
                  </span>
                  {'  '}
                  Keyboards <br />
                  <span role="img" aria-label="money mouth">
                    🤑
                  </span>
                  {'  '}
                  Gitfcards <br />
                </p>
              </Text>
            </Box>

            <Box width={'33%'}>
              <Text color={Theme.colors.white}>
                <h2>Play for all skill levels</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </Text>
            </Box>
          </Flex>
          <br />
          <br />
        </Animation>
      </WideContainer>
    </StyledWideContent>
  )
}

export default HomePageHero
