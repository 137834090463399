import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination'

import { Theme } from '../../styles/settings'

interface IPaginator extends PaginationProps {}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaginationItem-rounded': {
      borderRadius: '0px 4px',
      borderWidth: '2px',
    },
    '& .MuiPaginationItem-root': {
      fontFamily: Theme.fonts.condensed,
      fontSize: '1rem',
      lineHeight: '0px',
    },
    '& .MuiPaginationItem-page': {
      transition: '0.2s ease-in-out',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'transparent',
      borderColor: Theme.colors.sand,
    },
    '& .MuiPaginationItem-page.Mui-selected:hover': {
      borderColor: Theme.colors.sand,
      cursor: 'default',
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: 'transparent',
      borderColor: Theme.colors.secondaryblue,
    },
  },
}))

const Paginator: React.FC<IPaginator> = ({ ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Pagination shape="rounded" variant="outlined" {...props} />
    </div>
  )
}

export default Paginator
