import gql from 'graphql-tag'
import React, { useState, useEffect, useContext } from 'react'
import { Flex, FlexProps, Text, Box, Link } from 'rebass'
import { useTheme } from 'emotion-theming'

import { currentUserIsAdmin } from '../../../utils/admins'
import { styled } from '../../../styles/settings/theme'
import { useUniversitiesQuery, useUniversityMajorsQuery } from '../../../types/graphql'

import { CheckBox } from '../../atoms/FormPieces'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { IOptionType } from '../InputFieldWithErrors'
import UserAboutField from './UserAboutField'

gql`
  query Universities {
    universities {
      id
      name
    }
  }

  query UniversityMajors {
    universityMajors {
      id
      name
    }
  }
`

interface IFieldsWrapperProps extends FlexProps {
  addPadding?: boolean
}

const FieldsWrapper = styled(Flex)<IFieldsWrapperProps>`
  position: relative;

  ${props => props.addPadding && 'padding-bottom: 20px;'}
`
const CheckBoxWrapper = styled(Flex)<FlexProps>`
  position: absolute;
  left: calc(50% + 13px);
  top: 55px;
`

interface IUniversityFieldsProps {
  isNewUser?: boolean
}

const UniversityFields: React.FC<IUniversityFieldsProps> = ({ isNewUser }) => {
  const {
    isConfirmed,
    isInvitedUser,
    email,
    universityId,
    universityMajorId,
    graduationYear,
    university,
    setIsConfirmed,
    setEmail,
    setUniversityId,
    setUniversityMajorId,
    setUniversityMajor,
    setGraduationYear,
  } = useContext(CurrentUserContext)
  const { colors } = useTheme()
  const [universities, setUniversities] = useState<IOptionType[]>([])
  const [majors, setMajors] = useState<IOptionType[]>([])

  useUniversityMajorsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setMajors(
        data.universityMajors.map(major => ({ id: major.id, name: major.name, label: major.name })),
      )
    },
  })

  useUniversitiesQuery({
    fetchPolicy: 'cache-and-network',
    skip: isInvitedUser,
    onCompleted: data => {
      setUniversities(
        data.universities.map(university => ({
          id: university.id,
          name: university.name,
          label: university.name,
        })),
      )
    },
  })

  useEffect(() => {
    const major = majors.find(major => major.id === universityMajorId)
    major && setUniversityMajor({ id: major.id, name: major.name! })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityMajorId])

  const isAdmin = currentUserIsAdmin()

  return (
    <Box>
      <Text mb={5} color={colors.primarynavy}>
        <h5>your school</h5>
      </Text>
      {(isNewUser || isAdmin) && (
        <FieldsWrapper flexDirection="column" addPadding={isAdmin}>
          <Flex
            justifyContent="space-between"
            width="100%"
            flexDirection={['column', 'column', 'row', 'row']}
          >
            <UserAboutField
              name="universityId"
              value={universityId}
              updateField={setUniversityId}
              label="college/university*"
              fieldSubText={
                isInvitedUser || isAdmin ? (
                  ''
                ) : (
                  <>
                    *School issues? Email:{' '}
                    <Link src="mailto:support@cslesports.gg">support@cslesports.gg</Link>
                  </>
                )
              }
              options={isInvitedUser ? [{ ...university, label: university.name }] : universities}
              disabled={isInvitedUser}
            />
            <UserAboutField
              name="email"
              value={email}
              updateField={setEmail}
              label="school email"
              fieldSubText={isInvitedUser || isAdmin ? '' : 'This email will be verified.'}
              disabled={isInvitedUser}
            />
          </Flex>
          {isAdmin && (
            <CheckBoxWrapper>
              <CheckBox
                name="isConfirmed"
                type="checkbox"
                checked={isConfirmed}
                onChange={() => setIsConfirmed(!isConfirmed)}
              />
              <Text ml={3} color={colors.primarynavy}>
                <h5>Email verified (confirmed user)</h5>
              </Text>
            </CheckBoxWrapper>
          )}
        </FieldsWrapper>
      )}

      <Flex
        mb={4}
        justifyContent="space-between"
        width="100%"
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <UserAboutField
          name="universityMajorId"
          value={universityMajorId}
          updateField={setUniversityMajorId}
          label="major"
          options={majors}
        />
        <UserAboutField
          name="graduationYear"
          value={graduationYear}
          updateField={setGraduationYear}
        >
          {[...Array(10).keys()]
            .map(n => n + new Date().getFullYear())
            .map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
        </UserAboutField>
      </Flex>
    </Box>
  )
}

export default UniversityFields
