import React from 'react'
import { allSportSlugs } from '../../utils/sportUtils'
import { Box, Flex, Text } from 'rebass'
import GamesFilter from '../organisms/GamesFilter'
import SectionTitle from '../atoms/SectionTitle'
import { BaseButton } from '../atoms/Buttons'

interface AddGamesProps {
  selectedSports: string[]
  sportClickHandler: (sportSlug: string) => void
  headerText: string
  subheaderText: string
  submitButtonText: string
  onFormSubmitted: (user: any) => void
  existingSports?: string[]
}

const AddGames: React.FC<AddGamesProps> = ({
  selectedSports,
  sportClickHandler,
  headerText,
  subheaderText,
  submitButtonText,
  onFormSubmitted,
  existingSports,
}) => {
  return (
    <Box px={[0, 0, 7]}>
      <SectionTitle text={headerText} align="center" mb="5" />
      <Text textAlign="center" mb={6}>
        <p>{subheaderText}</p>
      </Text>
      <Box my={6}>
        <GamesFilter
          sportSlugs={
            existingSports
              ? allSportSlugs.filter(sportSlug => !existingSports.includes(sportSlug))
              : allSportSlugs
          }
          selectedSports={selectedSports}
          sportClickHandler={sportClickHandler}
          gridTemplateColumns="repeat(6,1fr)"
          customPadding="1.75rem"
        />
      </Box>
      <Flex>
        <BaseButton
          variant={!selectedSports.length ? 'secondaryDisabled' : 'secondary'}
          disabled={!selectedSports.length}
          onClick={onFormSubmitted}
          mx="auto"
        >
          {submitButtonText}
        </BaseButton>
      </Flex>
    </Box>
  )
}

export default AddGames
