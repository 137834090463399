import { Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import sfvSprites from '../../assets/sprites/sfv-sprites.png'

interface SpriteProps {
  zoom?: string
}

export const SfvSprite = styled(Box)<SpriteProps & BoxProps>`
  background-image: url(${sfvSprites});
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 7px;
  height: 66px;
  width: 66px;
  vertical-align: middle;
  zoom: ${props => props.zoom};
  -moz-transform: scale(${props => props.zoom});

  &.ryu { background-position:  -0px -0px;}
  &.chun-li { background-position:  -66px -0px;}
  &.nash { background-position:  -132px -0px;}
  &.m-bison { background-position:  -198px -0px;}
  &.cammy { background-position:  -263px -0px;}
  &.birdie { background-position:  -330px -0px;}
  &.ken { background-position:  -400px -0px;}
  &.necalli { background-position:  -0px -67px;}
  &.vega { background-position:  -66px -67px;}
  &.rainbow-mika { background-position:  -132px -67px;}
  &.rashid { background-position:  -198px -67px;}
  &.karin { background-position:  -263px -67px;}
  &.zangief { background-position:  -330px -67px;}
  &.laura { background-position:  -400px -67px;}
  &.dhalsim { background-position:  -0px -132px;}
  &.fang { background-position:  -66px -132px;}
  &.alex { background-position:  -132px -132px;}
  &.guile { background-position:  -198px -132px;}
  &.ibuki { background-position:  -263px -132px;}
  &.balrog { background-position:  -330px -132px;}
  &.juri { background-position:  -400px -132px;}
  &.urien { background-position:  -0px -199px;}
  &.akuma { background-position:  -66px -199px;}
  &.kolin { background-position:  -132px -199px;}
  &.ed { background-position:  -198px -199px;}
  &.abigail { background-position:  -263px -199px;}
  &.menat { background-position:  -330px -199px;}
  &.zeku { background-position:  -400px -199px;}
  &.sakura { background-position:  -0px -266px;}
  &.blanka { background-position:  -66px -266px;}
  &.falke { background-position:  -132px -266px;}
  &.cody { background-position:  -198px -266px;}
  &.g { background-position:  -263px -266px;}
  &.sagat { background-position:  -330px -266px;}
  &.kage { background-position:  -400px -266px;}
}

`
