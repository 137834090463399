import { useTheme } from 'emotion-theming'
import React, { useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Text } from 'rebass'
import { currentUserIsLoggedIn } from '../../../utils/accountUtils'
import { currentUserIsAdmin } from '../../../utils/admins'
import { paths } from '../../../utils/Routes'
import { currentlyInRegistration } from '../../../utils/seasonUtils'
import { SiteSwitch } from '../../../utils/sites'
import WideContent from '../../atoms/WideContent'
import HomePageHero from '../../organisms/HomePageHero'
import HomePageValueProps from '../../organisms/HomePageValueProps'
import HomePageGames from './HomePageGames'
import HomePageSponsors from './HomePageSponsors'

const TelusHome: React.FC = () => {
  const { colors } = useTheme()

  useEffect(() => {
    // TODO: Paul – Change the requirements below to show or hide based on how this toast should be presented
    if (currentlyInRegistration() && !currentUserIsLoggedIn()) {
      toast.info(
        <Link to={paths.signup()}>
          <Text color={colors.white}>
            <h6>
              <SiteSwitch
                college="ENROLLMENT IS NOW OPEN FOR THE 2020-2021 SEASON &mdash; CLICK HERE TO REGISTER!"
                esports={null}
              />
            </h6>
          </Text>
        </Link>,
        {
          autoClose: false,
          hideProgressBar: true,
          className: 'CTA',
          containerId: 'permanent',
          toastId: 'home-page-notice', // unique id prevents stacking up a toast loaf
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyInRegistration(), colors.white])

  return currentUserIsLoggedIn() && !currentUserIsAdmin() ? (
    <Redirect to={paths.userDashboard()} />
  ) : (
    <WideContent>
      <div
        dangerouslySetInnerHTML={{
          __html: `
      <!--
      Start of Floodlight Tag: Please do not remove
      Activity name of this tag: 2020 - Telus - CSL - National - Page View
      URL of the webpage where the tag is expected to be placed: http://telus.com
      This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
      Creation Date: 12/10/2020
      -->`,
        }}
      />
      <script type="text/javascript">
        var axel = Math.random() + ""; var a = axel * 10000000000000; document.write('
        <iframe
          title="tracker"
          // eslint-disable-next-line
          src="https://6587013.fls.doubleclick.net/activityi;src=6587013;type=telus00;cat=2020-02q;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?"
          width="1"
          height="1"
          style={{ display: 'none' }}
        ></iframe>
        ');
      </script>
      <noscript>
        <iframe
          title="tracker"
          // eslint-disable-next-line
          src="https://6587013.fls.doubleclick.net/activityi;src=6587013;type=telus00;cat=2020-02q;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
          width="1"
          height="1"
          style={{ display: 'none' }}
        ></iframe>
      </noscript>
      <div
        dangerouslySetInnerHTML={{
          __html: `
    <!-- End of Floodlight Tag: Please do not remove -->`,
        }}
      />
      <HomePageHero />
      <SiteSwitch college={null} esports={<HomePageValueProps />} />
      <HomePageGames />
      <HomePageSponsors />
    </WideContent>
  )
}

export default TelusHome
