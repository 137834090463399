import dayjs from 'dayjs'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'

import { MatchDetailAttributes } from '../../types/fragments'
import {
  useRequestMatchRescheduleMutation,
  useRescheduleMatchMutation,
} from '../../types/graphql'
import { currentUserIsAdmin } from '../../utils/admins'
import { getLocallyAdjustedDateTime } from '../../utils/dateTimeFormats'
import { handleErrors } from '../../utils/formUtils'
import { errorsFullMessage, handleResult } from '../../utils/results'
import { BaseButton } from '../atoms/Buttons'
import Card from '../atoms/Card'
import ModalContainer from '../atoms/ModalContainer'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import DataFetcher from './DataFetcher'

gql`
  mutation requestMatchReschedule($matchId: ID!, $rescheduleDate: DateTime!) {
    requestMatchReschedule(matchId: $matchId, rescheduleDate: $rescheduleDate) {
      success
      errors {
        field
        message
      }
      value {
        ...MatchDetailAttributes
      }
    }
  }
  ${MatchDetailAttributes}
`

gql`
  mutation rescheduleMatch($matchId: ID!, $rescheduleDate: DateTime!) {
    rescheduleMatch(matchId: $matchId, rescheduleDate: $rescheduleDate) {
      success
      errors {
        field
        message
      }
      value {
        ...MatchDetailAttributes
      }
    }
  }
  ${MatchDetailAttributes}
`

interface IRescheduleMatchModal {
  matchId: string
  scheduledDate: string
  hideModal: () => void
}

interface IRescheduleMatchModalTemplateProps extends IRescheduleMatchModal {
  title: string
  description: string
  rescheduleButtonText: string
  rescheduleButtonAction: (rescheduleDate: dayjs.Dayjs) => void
  loading: boolean
}

const RescheduleMatchModalTemplate: React.FC<IRescheduleMatchModalTemplateProps> = ({
  scheduledDate,
  hideModal,
  title,
  description,
  rescheduleButtonText,
  rescheduleButtonAction,
  loading,
}) => {
  const { colors } = useTheme()
  const date = dayjs(scheduledDate)
  const [rescheduleDay, setRescheduleDay] = useState<string>(date.format('YYYY-MM-DD'))
  const [rescheduleTime, setRescheduleTime] = useState<string>(date.format('HH:MM'))
  const rescheduleDate = dayjs(`${rescheduleDay} ${rescheduleTime}`)

  const rescheduleButtonOnClick = (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    rescheduleButtonAction(rescheduleDate)
  }

  return (
    <ModalContainer
      hideModal={hideModal}
      style={{
        content: { maxWidth: '50rem' },
      }}
    >
      <Text textAlign="center">
        <Box mb={5}>
          <h2>{title}</h2>
        </Box>

        <p>{description}</p>
      </Text>

      <DataFetcher loading={loading} height={'auto'}>
        <>
          <Card p={4} my={5} borderColor="middlegray">
            <Text color={colors.darkgray}>
              <h6>New Match Date and Time</h6>
            </Text>

            <Flex>
              <Box width={1 / 2} mr={2}>
                <InputFieldWithErrors
                  mt={5}
                  width="100%"
                  type="date"
                  name="reschedule_date"
                  value={rescheduleDay}
                  label="Date"
                  placeholder=""
                  updateSingleField={setRescheduleDay}
                />
              </Box>
              <Box width={1 / 2} ml={2}>
                <InputFieldWithErrors
                  mt={5}
                  width="100%"
                  type="time"
                  name="reschedule_time"
                  value={rescheduleTime}
                  label={`Time (${getLocallyAdjustedDateTime(new Date()).format('z')})`}
                  placeholder=""
                  updateSingleField={setRescheduleTime}
                />
              </Box>
            </Flex>
          </Card>

          <Text textAlign="center" mt="4">
            <BaseButton
              disabled={loading}
              onClick={rescheduleButtonOnClick}
              variant={loading ? 'secondaryDisabled' : 'secondary'}
            >
              {rescheduleButtonText}
            </BaseButton>
          </Text>
        </>
      </DataFetcher>
    </ModalContainer>
  )
}

const RescheduleMatchModal: React.FC<IRescheduleMatchModal> = ({
  hideModal,
  matchId,
  scheduledDate,
}) => {
  const [requestMatchReschdule, { loading }] = useRequestMatchRescheduleMutation({
    refetchQueries: ['matchDetailPage'],
    awaitRefetchQueries: true,
    onError: error => {
      toast.error(handleErrors(error), { containerId: 'temporary', autoClose: false })
    },
    onCompleted: data => {
      handleResult({
        result: data.requestMatchReschedule,
        onSuccess: () => {
          toast.success('Reschedule request sent!', { containerId: 'temporary' })
          hideModal()
        },
        onFailure: errors => {
          toast.error(errorsFullMessage(errors), { containerId: 'temporary', autoClose: false })
        },
      })
    },
  })

  const [rescheduleMatch, { loading: loadingRescheduleMatch }] = useRescheduleMatchMutation({
    refetchQueries: ['matchDetailPage'],
    awaitRefetchQueries: true,
    onError: error => {
      toast.error(handleErrors(error), { containerId: 'temporary', autoClose: false })
    },
    onCompleted: data => {
      handleResult({
        result: data.rescheduleMatch,
        onSuccess: () => {
          toast.success('Match rescheduled!', { containerId: 'temporary' })
          hideModal()
        },
        onFailure: errors => {
          toast.error(errorsFullMessage(errors), { containerId: 'temporary', autoClose: false })
        },
      })
    },
  })

  if (currentUserIsAdmin()) {
    return (
      <RescheduleMatchModalTemplate
        matchId={matchId}
        scheduledDate={scheduledDate}
        hideModal={hideModal}
        title="Reschedule match"
        description="Please select a new time for the match below."
        loading={loadingRescheduleMatch}
        rescheduleButtonText="Reschedule"
        rescheduleButtonAction={rescheduleDate => {
          rescheduleMatch({ variables: { matchId, rescheduleDate } })
        }}
      />
    )
  }

  return (
    <RescheduleMatchModalTemplate
      matchId={matchId}
      scheduledDate={scheduledDate}
      hideModal={hideModal}
      title="Request to reschedule"
      description="Please select a new time for the match below and your request will be sent to the other
    team's coordinator. If approved it will be automatically updated."
      loading={loading}
      rescheduleButtonText="Send request to coordinator"
      rescheduleButtonAction={rescheduleDate => {
        requestMatchReschdule({ variables: { matchId, rescheduleDate } })
      }}
    />
  )
}

export default RescheduleMatchModal
