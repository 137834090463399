import gql from 'graphql-tag'
import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { isEmpty } from 'lodash'

import { Anchors } from './enums'
import { handleResult } from '../../../utils/results'
import { updateUserAuth } from '../../../utils/formUtils'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import UserSubscription from '../../molecules/UserSubscription'
import {
  UserForAuth,
  useRescindPendingInviteMutation as useRescindPendingInvite,
  useCreateRosterEntryMutation as createRosterEntryMutation,
} from '../../../types/graphql'

const CREATE_NEW_USER = gql(String.raw`
  mutation createNewUser(
    $name: String!
    $email: String!
    $discordUsername: String!
    $heardAboutUs: String
  ) {
    createNewUser(
      name: $name
      email: $email
      discordUsername: $discordUsername
      heardAboutUs: $heardAboutUs
    ) {
      success
      value {
        accessToken
        username
        client
        expiry
        uid
        id
        isIncompleteAccount
      }
      errors {
        field
        message
      }
    }
  }
`)

interface IFinalizedUserProps {
  onFormSubmitted: () => void
  changeAnchor: (anchor: string) => void
}

const FinalizedUser: React.FC<IFinalizedUserProps> = ({ onFormSubmitted, changeAnchor }) => {
  const {
    setCurrentUserContextLoading,
    fullName,
    email,
    discordUsername,
    sportFields,
    acceptedInvitations,
    declinedInvitations,
    paidForPremium,
  } = useContext(CurrentUserContext)
  const [heardAboutUs, setHeardAboutUs] = useState('')

  const [removePendingInvite, { loading: removePendingInviteLoading }] = useRescindPendingInvite({
    onError(error) {
      console.log(error)
    },
    onCompleted() {
      onFormSubmitted()
    },
  })

  const [createRosterEntry, { loading: createRosterEntryLoading }] = createRosterEntryMutation({
    onError(error) {
      console.log(error)
    },
    onCompleted() {
      onFormSubmitted()
    },
  })

  const [createNewUser, { loading: createNewUserLoading }] = useMutation(CREATE_NEW_USER, {
    variables: {
      name: fullName,
      email,
      discordUsername,
      paidForPremium,
      heardAboutUs: heardAboutUs,
    },
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      handleResult({
        result: data.createNewUser,
        onSuccess: (value) => {
          updateUserAuth(value as UserForAuth)
          if (acceptedInvitations.length || declinedInvitations.length) {
            acceptedInvitations.forEach((invitation) =>
              createRosterEntry({
                variables: {
                  teamPlayerInviteId: invitation.id,
                },
              }),
            )
            declinedInvitations.forEach((invitation) =>
              removePendingInvite({
                variables: {
                  id: invitation.id,
                },
              }),
            )
          } else {
            onFormSubmitted()
          }
          setCurrentUserContextLoading(false)
        },
        onFailure: (errors) => {
          setCurrentUserContextLoading(false)
          console.log(errors)
        },
      })
    },
  })

  const loading = removePendingInviteLoading || createRosterEntryLoading || createNewUserLoading

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (isEmpty(sportFields)) {
    changeAnchor(Anchors.GamingProfiles)
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `
      <!--
      Start of Floodlight Tag: Please do not remove
      Activity name of this tag: 2020 - Telus - CSL - National - Social Page Submit
      URL of the webpage where the tag is expected to be placed: http://telus.com
      This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
      Creation Date: 12/10/2020      
      -->`,
        }}
      />
      <script type="text/javascript">
        var axel = Math.random() + ""; var a = axel * 10000000000000; document.write('
        <iframe
          title="tracker"
          // eslint-disable-next-line
          src="https://6587013.fls.doubleclick.net/activityi;src=6587013;type=telus00;cat=2020-02s;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?"
          width="1"
          height="1"
          style={{ display: 'none' }}
        ></iframe>
        ');
      </script>
      <noscript>
        <iframe
          title="tracker"
          // eslint-disable-next-line
          src="https://6587013.fls.doubleclick.net/activityi;src=6587013;type=telus00;cat=2020-02s;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
          width="1"
          height="1"
          style={{ display: 'none' }}
        ></iframe>
      </noscript>
      <div
        dangerouslySetInnerHTML={{
          __html: `
    <!-- End of Floodlight Tag: Please do not remove -->`,
        }}
      />
      <UserSubscription
        onSelectAccountType={(text: string) => {
          setHeardAboutUs(text)
          createNewUser()
        }}
        updateHeardAboutUs={setHeardAboutUs}
      />
    </>
  )
}

export default FinalizedUser
