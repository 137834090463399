import React from 'react'
import { Flex, Box, Text } from 'rebass'
import _ from 'lodash'

import Card from '../atoms/Card'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'

interface IHasTitle {
  title: string
}

interface IMightHaveActionsComponent {
  actionsComponent?: React.ReactNode
}

interface IFormCardProps extends IMightHaveActionsComponent, IHasTitle {
  children: React.ReactNode
}

interface IFormCardHeaderProps extends IMightHaveActionsComponent, IHasTitle {}

interface IFormCardInputProps {
  name: string
  label: string
  type?: string
  flex?: string
  minWidth?: string
  placeholder: string
  min?: number
  value?: string | number
  width?: string | number
  onChange: (value: string) => void
}

export const FormCardHeader: React.FC<IFormCardHeaderProps> = ({ actionsComponent, title }) => {
  return (
    <Flex mb={5} justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <Text>
        <h2>{title}</h2>
      </Text>
      {actionsComponent || null}
    </Flex>
  )
}

export const FormCard: React.FC<IFormCardProps> = ({ actionsComponent, title, children }) => {
  return (
    <Box mb={8}>
      <FormCardHeader title={title} actionsComponent={actionsComponent} />
      <Card>{children}</Card>
    </Box>
  )
}

export const FormCardInput: React.FC<IFormCardInputProps> = ({
  name,
  label,
  type,
  flex,
  minWidth,
  min,
  value,
  onChange,
  width,
  ...rest
}) => {
  return (
    <InputFieldWithErrors
      name={name}
      label={label}
      type={type || 'text'}
      min={min}
      flex={flex || '1'}
      minWidth={minWidth || '10rem'}
      value={value}
      width={width}
      updateField={field => {
        const val = _.values(field)[0]
        onChange(`${val}`)
      }}
      {...rest}
    />
  )
}
