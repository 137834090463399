import React from 'react'
import { Flex } from 'rebass'
import { Round, Line } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getQuadrant } from '../../utils/bracketDataUtils'

interface RoundProps {
  flip?: boolean
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const RoundOf32: React.FunctionComponent<RoundProps> = ({
  seatList,
  flip,
  shift,
  bottomHalf,
  numTeams,
}) => {
  const topLeft = getQuadrant(seatList, 1)
  const topRight = getQuadrant(seatList, 2)
  const bottomLeft = getQuadrant(seatList, 3)
  const bottomRight = getQuadrant(seatList, 4)

  const seats =
    flip && bottomHalf ? bottomRight : bottomHalf ? bottomLeft : flip ? topRight : topLeft

  const top = (size: number) => {
    const topPxAmount: { [key: number]: string } = {
      128: bottomHalf ? '147px' : '112px',
      64: bottomHalf ? '117px' : '77px',
      32: bottomHalf ? '41px' : '77px',
    }
    return topPxAmount[size]
  }

  const bottom = (numTeams: number) => {
    const bottomPxAmount: { [key: number]: string } = {
      128: '271px',
      64: '221px',
      32: '106px',
    }
    return bottomPxAmount[numTeams]
  }

  return (
    <Round round="32" top={top(numTeams)} shift={shift}>
      {seats.map((tuple, i) => (
        <Flex key={i}>
          {flip && <Line numTeams={numTeams} round="32" flip={flip} />}

          <BracketCouplet
            seats={tuple}
            round="32"
            bottom={bottom(numTeams)}
            flip={flip}
            numTeams={numTeams}
          />
          {!flip && <Line numTeams={numTeams} round="32" flip={flip} />}
        </Flex>
      ))}
    </Round>
  )
}

export default RoundOf32
