import React, { useMemo } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FiCopy, FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { GiCrossedSwords, GiSwordsEmblem } from 'react-icons/gi'
import { AiFillCrown } from 'react-icons/ai'
import { usePagination, useTable } from 'react-table'
import { toast } from 'react-toastify'
import { useTheme } from 'emotion-theming'
import { Box, Flex, Link, Text } from 'rebass'
import { styled, Theme, ThemeColors } from '../../styles/settings/theme'

import { currentUserIsLoggedIn } from '../../utils/accountUtils'
import { LineupEntry, RosterEntry } from '../../types/graphql'
import { aceScore } from '../../utils/rosterEntryUtils'
import { siteSwitcher } from '../../utils/sites'
import { paths } from '../../utils/Routes'
import {
  categoryName,
  displaysCharactersAsRoles,
  rawNumPlayers,
  usesRoles,
} from '../../utils/sportUtils'
import { DiscordLink, FortniteLink, SteamLink } from '../atoms/SiteLinks'
import { LOLSprite } from '../atoms/LOLSprite'
import { MtgaSprite } from '../atoms/MtgaSprite'
import { NbaSprite } from '../atoms/NbaSprite'
import { NhlSprite } from '../atoms/NhlSprite'
import { SfvSprite } from '../atoms/SfvSprite'
import Table from '../atoms/Table'
import Alert from '../atoms/Alert'
import { DiscordUsername } from '../atoms/DiscordUsername'

export interface IPlayersTableProps {
  aceScoreColumn?: boolean
  bodyBackgroundColor?: ThemeColors
  bodyBorder?: boolean
  coordinatorId: string | null
  inGameNameColumn?: boolean
  killsColumn?: boolean
  lightHeaderBackground?: boolean
  lineupEntries?: LineupEntry[] | null
  pagination?: boolean
  playerHeaderTitle?: string | null
  recordsColumn?: boolean
  rosterEntries: RosterEntry[]
  showEmptyPositions?: boolean
  sport: string
  showDiscordUsername?: boolean
}

const mainColumWidthPercentage = (numberOfColumns: number): number => {
  switch (numberOfColumns) {
    case 2:
      return 50
    case 3:
      return 50
    case 4:
      return 40
    case 5:
      return 30
    case 6:
      return 20
    default:
      return 20
  }
}

const PaginationButton = styled.button`
  background: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  cursor: pointer;
  padding: 0.25rem 0.3125rem 0.25rem 0.3125rem;
`

const PlayersTable = ({
  aceScoreColumn,
  bodyBackgroundColor,
  bodyBorder,
  coordinatorId,
  inGameNameColumn,
  killsColumn,
  lightHeaderBackground,
  lineupEntries,
  pagination,
  playerHeaderTitle,
  recordsColumn,
  rosterEntries,
  showEmptyPositions,
  sport,
  showDiscordUsername,
}: IPlayersTableProps) => {
  const headerBackgroundColor = lightHeaderBackground ? 'middlegray' : 'secondarynavy'
  const headerTextColor = lightHeaderBackground ? 'darkgray' : 'white'
  const numberOfPlayers = +rawNumPlayers(sport) || 0
  const emptyPositions = showEmptyPositions
    ? [...Array(Math.max(0, numberOfPlayers - rosterEntries.length))]
    : []
  const roleColumn = usesRoles(sport) || displaysCharactersAsRoles(sport)
  const numberOfColumns =
    2 +
    Number(Boolean(roleColumn)) +
    Number(Boolean(recordsColumn)) +
    Number(Boolean(aceScoreColumn)) +
    Number(Boolean(inGameNameColumn))

  const otherColumnsWidth = `${(100 - mainColumWidthPercentage(numberOfColumns)) /
    numberOfColumns}%`

  //for mtga
  const deckListCode =
    lineupEntries && lineupEntries.length && (lineupEntries[0].deckListCode as any)
  const selectedColor =
    lineupEntries && lineupEntries.length && (lineupEntries[0].selectedColor as any)

  const handleDeckListCopy = () => {
    toast.success('The deck list has been copied to your clipboard.', { containerId: 'temporary' })
  }

  const columns = useMemo(
    () => [
      {
        Header: playerHeaderTitle || 'Starter',
        accessor: 'username',
        style: { width: `${mainColumWidthPercentage(numberOfColumns)}%` },
        Cell: ({ row: { original } }: { row: { original: RosterEntry } }) => (
          <Flex alignItems="center">
            {original.player && original.player.id === coordinatorId ? (
              <Box mr={3} pb={'0.1rem'}>
                <AiFillCrown size={24} color={Theme.colors.sand} />
              </Box>
            ) : null}
            <Text color="primarynavy">
              {original.player ? (
                <Link href={paths.player(original.player.id)}>
                  <h6>{original.player.username}</h6>
                </Link>
              ) : (
                <h6>Name Unavailable</h6>
              )}
            </Text>
          </Flex>
        ),
      },
      ...(roleColumn
        ? [
            {
              Header: categoryName[sport] || 'Role',
              accessor: 'gameRole',
              style: { width: otherColumnsWidth },
              Cell: ({ row: { original } }: { row: { original: RosterEntry } }) => {
                const lineupEntry: any =
                  lineupEntries &&
                  lineupEntries.find(
                    (entry: LineupEntry) => entry.player && entry.player.id === original.player.id,
                  )
                const characterImageName = lineupEntries
                  ? lineupEntry && lineupEntry.characterImageName
                  : original.player && original.player.characterImageName
                const role =
                  lineupEntry && lineupEntry.role ? lineupEntry.role : original.gameRole || 'Flex'
                return sport === 'mtga' ? (
                  <Text color="darkgray">
                    <MtgaSprite
                      className={
                        selectedColor
                          ? selectedColor
                          : characterImageName && characterImageName.toLowerCase()
                      }
                      zoom={'0.5'}
                      mr={2}
                    />
                  </Text>
                ) : sport === 'sfv' && characterImageName ? (
                  <SfvSprite className={characterImageName} zoom={'0.8'} mr={2} />
                ) : sport === 'valorant' && characterImageName ? (
                  //TODO: This is a placeholder until we get sprites
                  <Text color="darkgray">
                    <h6>{characterImageName}</h6>
                  </Text>
                ) : sport === 'nhl' && characterImageName ? (
                  <NhlSprite className={characterImageName} zoom={'0.5'} mr={2} />
                ) : sport === 'nba' && characterImageName ? (
                  <NbaSprite className={characterImageName} zoom={'1'} mr={2} />
                ) : (
                  <Flex alignItems="center">
                    {sport === 'lol' && characterImageName && (
                      <Box size={32} mr={2}>
                        <LOLSprite
                          championImageName={
                            lineupEntries
                              ? lineupEntry.characterImageName
                              : original.player.characterImageName
                          }
                        />
                      </Box>
                    )}
                    {sport === 'dota2' && characterImageName && (
                      <Box mr={2}>
                        <i className={`d2mh ${characterImageName}`}></i>
                      </Box>
                    )}

                    <Text color="darkgray">
                      <h6>{role}</h6>
                    </Text>

                    {!characterImageName && !role && (
                      <Text color="darkgray">
                        <h6>-</h6>
                      </Text>
                    )}
                  </Flex>
                )
              },
            },
          ]
        : []),
      ...(recordsColumn
        ? [
            {
              Header: sport === 'lol' && lineupEntries ? 'KDA' : 'Record',
              accessor: 'record',
              style: { width: otherColumnsWidth },
              Cell: ({ row: { original } }: { row: { original: RosterEntry } }) => {
                const lineupEntry: any =
                  sport === 'lol' && lineupEntries
                    ? lineupEntries.find(
                        (entry: LineupEntry) =>
                          entry.player && entry.player.id === original.player.id,
                      )
                    : null

                if (lineupEntry && lineupEntry.lolGamePlayerStats)
                  var { kills, deaths, assists } = lineupEntry.lolGamePlayerStats

                return sport === 'lol' && lineupEntries ? (
                  <Text color="darkgray">
                    <h6>
                      {lineupEntry.lolGamePlayerStats ? (
                        `${kills + '/'}${deaths + '/'}${assists}`
                      ) : (
                        <span>&mdash;</span>
                      )}
                    </h6>
                  </Text>
                ) : (
                  <Text color="darkgray">
                    <h6>
                      {original.player && original.player.selectedSeasonStats
                        ? original.player.selectedSeasonStats.wins
                        : 0}
                      -
                      {original.player && original.player.selectedSeasonStats
                        ? original.player.selectedSeasonStats.losses
                        : 0}
                    </h6>
                  </Text>
                )
              },
            },
          ]
        : []),
      ...(aceScoreColumn
        ? [
            {
              Header: 'Ace Score',
              accessor: 'aceScore',
              style: { width: otherColumnsWidth },
              Cell: ({ row: { original } }: { row: { original: RosterEntry } }) => (
                <Text color="darkgray">
                  <h6>{aceScore(original)}</h6>
                </Text>
              ),
            },
          ]
        : []),
      ...(inGameNameColumn
        ? [
            {
              Header: 'In-Game Name',
              accessor: 'player.inGameName',
              style: { width: otherColumnsWidth },
              Cell: ({ row: { original } }: { row: { original: RosterEntry } }) => (
                <Text color="darkgray">
                  <h6>{original.inGameName}</h6>
                </Text>
              ),
            },
          ]
        : []),
      ...(killsColumn
        ? [
            {
              Header: 'Kills',
              accessor: 'kills',
              style: { width: otherColumnsWidth },
              Cell: ({ row: { original } }: { row: { original: RosterEntry } }) => {
                const lineupEntry: any =
                  lineupEntries &&
                  lineupEntries.find(
                    (entry: LineupEntry) => entry.player && entry.player.id === original.player.id,
                  )
                return (
                  <Text color="darkgray">
                    <h6>{lineupEntry && lineupEntry.kills}</h6>
                  </Text>
                )
              },
            },
          ]
        : []),
      {
        Header: currentUserIsLoggedIn() && showDiscordUsername ? 'Discord' : 'Links',
        accessor: currentUserIsLoggedIn() && showDiscordUsername ? 'discord' : 'links',
        style: { width: '1%' } /* width of 1% to make minimum width */,
        Cell: ({ row: { original } }: { row: { original: RosterEntry } }) => (
          <Flex justifyContent="flex-end" alignItems="center">
            {currentUserIsLoggedIn() && showDiscordUsername ? (
              original.player &&
              original.player.discordUsername && (
                <Text color="darkgray">
                  <DiscordUsername>{original.player.discordUsername}</DiscordUsername>
                </Text>
              )
            ) : (
              <>
                <DiscordLink
                  discordId={
                    original.player
                      ? original.player.discordId
                        ? original.player.discordId
                        : ''
                      : ''
                  }
                />
                {(sport === 'csgo' || sport === 'dota2') && (
                  <SteamLink steamId={original.inGameName ? original.inGameName : ''} />
                )}
                {/* note: need unwrapped version of icon if no decklistcode b/c of Safari behavior */}
                {sport === 'mtga' &&
                  (deckListCode ? (
                    <CopyToClipboard text={deckListCode} onCopy={handleDeckListCopy}>
                      <FiCopy
                        size="24"
                        color={Theme.colors.primaryblue}
                        style={{ cursor: 'pointer' }}
                      />
                    </CopyToClipboard>
                  ) : (
                    <FiCopy size="24" color={'lightgray'} />
                  ))}
                {sport === 'fortnite' && original.inGameName && (
                  <FortniteLink inGameName={original.inGameName} />
                )}
              </>
            )}
          </Flex>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      playerHeaderTitle,
      otherColumnsWidth,
      aceScoreColumn,
      inGameNameColumn,
      recordsColumn,
      numberOfColumns,
      coordinatorId,
      sport,
    ],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns: siteSwitcher({
        college: columns,
        esports: useMemo(() => columns.filter((column: any) => column.accessor !== 'aceScore'), [
          columns,
        ]), // eslint-disable-line react-hooks/exhaustive-deps
      }),
      data: useMemo(() => rosterEntries, [rosterEntries]),
      initialState: { pageIndex: 0, pageSize: pagination ? 30 : 9999 },
    },
    usePagination,
  )
  const { colors } = useTheme()

  return (
    <Box>
      <Table
        {...getTableProps()}
        headerBackgroundColor={headerBackgroundColor}
        bodyBackgroundColor={bodyBackgroundColor}
        bodyBorder={bodyBorder}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // TODO: can you not use 'any' here?
                <th {...column.getHeaderProps()} style={(column as any).style}>
                  <Text color={headerTextColor}>
                    <h6>{column.render('Header')}</h6>
                  </Text>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}

          {emptyPositions.map((_, idx) => (
            <tr key={idx}>
              <td>
                <Flex alignItems="center">
                  <Box mr={3} pb={'0.1rem'}>
                    <GiSwordsEmblem size={20} color={Theme.colors.sand} />
                  </Box>

                  <Text color="darkgray">
                    <h6>Position Empty</h6>
                  </Text>
                </Flex>
              </td>
              {roleColumn ? (
                <td>
                  <Text color="darkgray">
                    <h6>-</h6>
                  </Text>
                </td>
              ) : null}
              {recordsColumn ? (
                <td>
                  <Text color="darkgray">
                    <h6>-</h6>
                  </Text>
                </td>
              ) : null}
              {aceScoreColumn ? (
                <td>
                  <Text color="darkgray">
                    <h6>-</h6>
                  </Text>
                </td>
              ) : null}
              {inGameNameColumn ? (
                <td>
                  <Text color="darkgray">
                    <h6>-</h6>
                  </Text>
                </td>
              ) : null}

              <td>
                <Text color="darkgray">
                  <h6>-</h6>
                </Text>
              </td>
              {/* <td>-</td> */}
            </tr>
          ))}
        </tbody>
      </Table>

      {emptyPositions.length > 1 && (
        <Alert borderColor={colors.middlegray} mx="auto" maxWidth="100%">
          <Box mt={4}>
            <GiCrossedSwords color={colors.sand} size={40} />
          </Box>
          <h2>No Players Found</h2>
        </Alert>
      )}

      {pagination && (
        <Flex mt={5} alignItems="center" justifyContent="flex-end">
          <PaginationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            <FiArrowLeft size="20" />
          </PaginationButton>

          <Box mx={3}>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </Box>

          <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
            <FiArrowRight size="20" />
          </PaginationButton>
        </Flex>
      )}
    </Box>
  )
}

export default PlayersTable
