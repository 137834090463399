import React from 'react'
import { Box, Flex, Text } from 'rebass'

import { NewsAndAnnouncements_NewsArticleFragment as NewsArticle } from '../../../types/graphql'

import AnnouncementSummary from './AnnouncementSummary'
import Card from '../../atoms/Card'
import Animation from '../../atoms/Animation'
import DataFetcher from '../../organisms/DataFetcher'

interface IAnnouncementsSectionProps {
  loading: boolean
  announcements: NewsArticle[]
}

const AnnouncementsSection: React.FC<IAnnouncementsSectionProps> = ({ loading, announcements }) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <DataFetcher loading={loading}>
      <Flex mb={6} flexDirection="column">
        <Box>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4 }}
          >
            <Text>
              <h2>Announcements</h2>
            </Text>
          </Animation>
        </Box>
        <Box mt={4}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <Card notch={true}>
              <Flex flexDirection="column" py={4}>
                {announcements.map(announcement => (
                  <AnnouncementSummary key={announcement.id} {...announcement} />
                ))}
              </Flex>
            </Card>
          </Animation>
        </Box>
      </Flex>
    </DataFetcher>
  )
}

export default AnnouncementsSection
