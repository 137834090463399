import React from 'react'
import { Flex } from 'rebass'

import { LowerLine, Round } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getTuples } from '../../utils/bracketDataUtils'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const Round4ALosers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)

  const seats = getTuples(halfSeats)
  return (
    <Round round="4A" shift={shift} top={bottomHalf ? '156px' : '78px'}>
      <Flex flexDirection="column" mb={'40px'}>
        {seats.map(tuple => (
          <BracketCouplet
            seats={tuple}
            round="4A"
            bottom={numTeams === 32 ? '0px' : '280px'}
            numTeams={numTeams}
          />
        ))}
        {numTeams === 32 && <LowerLine round="4A" bottomHalf={bottomHalf} numTeams={numTeams} />}
      </Flex>
    </Round>
  )
}

export default Round4ALosers
