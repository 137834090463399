import { css } from '@emotion/core'
import { Box, BoxProps, Flex, FlexProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

export const BracketContainer = styled(Box)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
`
interface RoundProps {
  top?: string
  shift?: string
  round?: string
  flip?: boolean
  bottomHalf?: boolean
  numTeams?: number
}
interface SlotProps {
  myTeam?: boolean
  highlight?: boolean
  center?: boolean
  finals?: boolean
  alignRight?: boolean
  matchUrl?: string
}

export const Round = styled(Box)<RoundProps & BoxProps>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.shift};
  width: ${props =>
    props.round === '2A' && props.numTeams === 8
      ? '505px'
      : props.round === '3A'
      ? props.numTeams === 16
        ? '336px'
        : '267px'
      : props.round === '3B'
      ? props.numTeams === 128
        ? '264px'
        : '378px'
      : props.round === '4B' && props.numTeams === 128
      ? '357px'
      : props.round === '8' && props.flip
      ? '206px'
      : null};

  > .quadruple:nth-of-type(even) {
    margin-bottom: 30px;
  }
`

export const Couplet = styled(Box)<BoxProps>`
  width: 190px;
`
interface MatchProps {
  bottom?: string
  round?: string
  flip?: boolean
  numTeams?: number
  bottomHalf?: boolean
  isDoubleElim?: boolean
}

export const MatchBox = styled(Box)<MatchProps & BoxProps>`
  background-color: ${props => props.theme.colors.white};
  width: 190px;
  height: 67px;
  border: solid
    ${props =>
      props.round === '128' || (props.round === '1' && props.numTeams === 128)
        ? props.theme.colors.middlegray
        : props.round === '64' ||
          ((props.round === '2A' || props.round === '2B') && props.numTeams === 128) ||
          (props.round === '1' && props.numTeams === 64)
        ? props.theme.colors.primarylightblue
        : props.round === '32' ||
          ((props.round === '3A' || props.round === '3B') && props.numTeams === 128) ||
          ((props.round === '2A' || props.round === '2B') && props.numTeams === 64) ||
          (props.round === '1' && props.numTeams === 32)
        ? props.theme.colors.secondarygreen
        : props.round === '16' ||
          ((props.round === '4A' || props.round === '4B') && props.numTeams === 128) ||
          ((props.round === '3A' || props.round === '3B') && props.numTeams === 64) ||
          ((props.round === '2A' || props.round === '2B') && props.numTeams === 32) ||
          (props.round === '1' && props.numTeams === 16)
        ? props.theme.colors.orange
        : props.round === '8' ||
          ((props.round === '5A' || props.round === '5B') && props.numTeams === 128) ||
          ((props.round === '4A' || props.round === '4B') && props.numTeams === 64) ||
          ((props.round === '3A' || props.round === '3B') && props.numTeams === 32) ||
          ((props.round === '2A' || props.round === '2B') && props.numTeams === 16) ||
          (props.round === '1' && props.numTeams === 8)
        ? props.theme.colors.purple
        : props.theme.colors.primarynavy};
  border-width: ${props => (props.flip ? '2px 14px 2px 2px' : '2px 2px 2px 14px')};
  z-index: 2;
  margin-bottom: ${props => props.bottom};
  position: relative;

  ${props =>
    props.round === '64'
      ? props.numTeams === 64
        ? css`
            :last-child {
              margin-bottom: calc(${props.bottom} - 20px);
            }
          `
        : props.numTeams === 128
        ? css`
            :last-child {
              margin-bottom: calc(${props.bottom} + 30px);
            }
          `
        : null
      : null}
  ${props =>
    props.numTeams === 32 &&
    props.round === '32' &&
    css`
      :last-child {
        margin-bottom: calc(${props.bottom} + 18px);
      }
    `}
  //special case for shifting purple matchbox on top of its line (to its left) when
  //flipped single elim line has to be wider to go up right center box
  transform: ${props =>
    props.round === '8' && props.flip && props.bottomHalf && !props.isDoubleElim
      ? 'translateX(-116px)'
      : null};
`

export const Slot = styled(Flex)<SlotProps & FlexProps>`
  background-color: ${props =>
    props.myTeam ? props.theme.colors.primarylightblueHighlight : null};
  background-color: ${props => (props.highlight ? props.theme.colors.yellowHighlight : null)};
  cursor: ${props => (props.matchUrl ? 'pointer' : 'default')}
  width: 100%;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.lightgray};
  :last-child {
    border-bottom: none;
  }
  padding: 0.5625rem;
  ${props =>
    props.center
      ? props.finals
        ? css`
            flex-direction: column;
            justify-content: space-between;
            border-bottom: none;
            padding: 0;
          `
        : css`
            border-bottom: none;
            padding: 1.75rem 0 0 0;
            :last-child {
              padding: 1.25rem 0 0 0;
            }
          `
      : null}

  ${props =>
    props.alignRight &&
    css`
      align-items: flex-end;
    `}
`

interface LineProps {
  round?: string
  bottomHalf?: boolean | null
  flip?: boolean | null
  part?: string
  numTeams?: number
  isDoubleElim?: boolean
}

//UPPER BRACKET LINES:
//normal bracket lines
export const Line = styled(Box)<LineProps & BoxProps>`
  border-top: 2px solid;
  border-right: 2px solid;
  border-bottom: 2px solid;
  z-index: 1;
  ${props =>
    props.flip &&
    css`
      border-right: none;
      border-left: 2px solid;
    `}
  ${props =>
    props.round === '8' &&
    ((props.flip && !props.bottomHalf) || (!props.flip && props.bottomHalf)) &&
    css`
      border-left: none;
      border-right: none;
      border-bottom: none;
    `}
  border-color: ${props => props.theme.colors.middlegray};
  width: ${props =>
    props.round === '8'
      ? !props.isDoubleElim
        ? props.bottomHalf && props.flip
          ? '350px'
          : !props.bottomHalf && !props.flip
          ? '150px'
          : '16px'
        : '16px'
      : '50px'};

   //this is repetitive but making each condition explicit is easier to read
  height: ${props =>
    props.numTeams === 128
      ? props.round === '128'
        ? '104px'
        : props.round === '64'
        ? '155px'
        : props.round === '32'
        ? '340px'
        : props.round === '16'
        ? '678px'
        : props.round === '8' &&
          props.isDoubleElim &&
          ((!props.flip && !props.bottomHalf) || (props.flip && props.bottomHalf))
        ? '600px'
        : props.round === '8' && !props.isDoubleElim && !props.flip && !props.bottomHalf
        ? '686px'
        : props.round === '8' && !props.isDoubleElim && props.flip && props.bottomHalf
        ? '694px'
        : '0px'
      : props.numTeams === 64
      ? props.round === '64'
        ? '155px'
        : props.round === '32'
        ? '290px'
        : props.round === '16'
        ? '578px'
        : props.round === '8' &&
          props.isDoubleElim &&
          ((!props.flip && !props.bottomHalf) || (props.flip && props.bottomHalf))
        ? '540px'
        : props.round === '8' &&
          !props.isDoubleElim &&
          ((!props.flip && !props.bottomHalf) || (props.flip && props.bottomHalf))
        ? '583px'
        : '0px'
      : props.numTeams === 32
      ? props.round === '32'
        ? '175px'
        : props.round === '16'
        ? '367px'
        : props.round === '8' &&
          props.isDoubleElim &&
          ((!props.flip && !props.bottomHalf) || (props.flip && props.bottomHalf))
        ? '280px'
        : props.round === '8' &&
          !props.isDoubleElim &&
          ((!props.flip && !props.bottomHalf) || (props.flip && props.bottomHalf))
        ? '342px'
        : '0px'
      : props.numTeams === 16
      ? props.round === '16'
        ? '196px'
        : props.round === '8' &&
          props.isDoubleElim &&
          ((!props.flip && !props.bottomHalf) || (props.flip && props.bottomHalf))
        ? '220px'
        : props.round === '8' &&
          !props.isDoubleElim &&
          ((!props.flip && !props.bottomHalf) || (props.flip && props.bottomHalf))
        ? '174px'
        : null
      : null};

  transform: ${props =>
    props.round === '128'
      ? 'translateY(20px)'
      : props.round === '8' && !props.flip && !props.isDoubleElim && !props.bottomHalf
      ? 'translate(-134px, 33px)' //special case for single elim line that leads into left center bracket
      : 'translateY(33px)'};

  //this only applies to R8 because only R8 Line gets bottomHalf prop
  transform: ${props =>
    props.bottomHalf
      ? props.isDoubleElim
        ? props.flip
          ? props.numTeams === 128
            ? 'translateY(-565px)'
            : props.numTeams === 64
            ? 'translateY(-505px)'
            : props.numTeams === 32
            ? 'translateY(-245px)'
            : props.numTeams === 16
            ? 'translateY(-185px)'
            : props.numTeams === 8
            ? 'translateY(-32px)'
            : 'translateY(33px)'
          : null
        : props.flip
        ? props.numTeams === 128
          ? 'translateY(-659px)'
          : props.numTeams === 64
          ? 'translateY(-548px)'
          : props.numTeams === 32
          ? 'translateY(-307px)'
          : props.numTeams === 16
          ? 'translateY(-139px)'
          : props.numTeams === 8
          ? 'translateY(-32px)'
          : 'translateY(33px)'
        : null
      : null};

`

//lines connecting top and bottom to center
export const BottomLine = styled(Box)<LineProps & BoxProps>`
  border-right: 2px solid;
  border-bottom: 2px solid;
  height: 39px;
  transform: translateX(-95px);
  ${props =>
    props.bottomHalf &&
    css`
      height: 40px;
      border-bottom: none;
      border-top: 2px solid;
    `}
    ${props =>
      props.numTeams === 128 &&
      css`
        height: 69px;
      `}
  border-color: ${props => props.theme.colors.middlegray};
`
//LOWER BRACKET LINES:
//short line connectin first round to others
export const LowerDash = styled(Box)<LineProps & BoxProps>`
  border-top: 2px solid ${props => props.theme.colors.middlegray};
  width: 10px;
  transform: translateY(32px);
  ${props =>
    props.round === '3B' &&
    css`
      width: 40px;
    `}
`
//normal bracket line
export const LowerLine = styled(Box)<LineProps & BoxProps>`
  border-top: 2px solid ${props => props.theme.colors.middlegray};
  border-right: 2px solid ${props => props.theme.colors.middlegray};
  border-bottom: 2px solid ${props => props.theme.colors.middlegray};
  width: 40px;
  height: 107px;
  transform: translateY(20px);
  ${props =>
    props.round === '2A' &&
    props.numTeams === 8 &&
    css`
      width: 315px;
      height: 127px;
      border-bottom: none;
      z-index: 1;
      transform: translateY(33px);
    `}
  ${props =>
    props.round === '2A' &&
    props.numTeams === 8 &&
    props.bottomHalf &&
    css`
      height: 125px;
      border-bottom: 2px solid ${props.theme.colors.middlegray};
      border-top: none;
      transform: translateY(-90px);
    `}
  ${props =>
    props.round === '3A' &&
    css`
      width: 77px;
      height: 183px;
    `}
  ${props =>
    props.round === '3A' &&
    props.numTeams === 16 &&
    css`
      width: 146px;
      height: 204px;
      border-bottom: none;
      z-index: 1;
      transform: translateY(32px);
    `}
  ${props =>
    props.round === '3A' &&
    props.numTeams === 16 &&
    props.bottomHalf &&
    css`
      height: 200px;
      border-bottom: 2px solid ${props.theme.colors.middlegray};
      border-top: none;
      transform: translateY(-165px);
    `}
  ${props =>
    props.round === '3B' &&
    css`
      width: 188px;
      height: 350px;
      transform: translateY(32px);
    `}
  ${props =>
    props.round === '3B' &&
    props.numTeams === 128 &&
    css`
      width: 74px;
    `}
  ${props =>
    props.round === '4A' &&
    props.numTeams === 32 &&
    css`
      border-top: none;
      border-bottom: none;
      width: 4px;
      height: 243px;
      z-index: 1;
      transform: translateX(91px);
    `}
  ${props =>
    props.round === '4A' &&
    props.numTeams === 32 &&
    props.bottomHalf &&
    css`
      height: 250px;
      transform: translate(91px, -313px);
    `}

  ${props =>
    (props.round === '4B' || props.round === '5B' || props.round === '6') &&
    css`
      border-top: none;
      border-bottom: none;
      width: 4px;
      height: 477px;
      z-index: 1;
      transform: translateX(-25px);
    `}

  ${props =>
    props.round === '4B' &&
    props.bottomHalf &&
    css`
      height: 425px;
      transform: translate(-25px, -416px);
    `}
  ${props =>
    props.round === '4B' &&
    props.numTeams === 128 &&
    css`
      border-top: 2px solid ${props.theme.colors.middlegray};
      border-bottom: 2px solid ${props.theme.colors.middlegray};
      width: 167px;
      height: 698px;
      transform: translate(0px, 32px);
    `}
    ${props =>
      props.round === '5B'
        ? props.bottomHalf
          ? css`
              height: 605px;
              transform: translate(-25px, -605px);
            `
          : css`
              height: 675px;
            `
        : null}
    ${props =>
      props.round === '6'
        ? props.bottomHalf
          ? css`
              height: 140px;
              transform: translate(-109px, -90px);
            `
          : css`
              height: 158px;
              transform: translateX(-109px);
            `
        : null}

  `

//elbow line joining rounds
export const HalfLine = styled(Box)<LineProps & BoxProps>`
  border-left: 2px solid;
  border-top: 2px solid;
  height: 22px;
  width: 40px;
  transform: translate(-20px, -20px);
  ${props =>
    props.bottomHalf &&
    css`
      border-top: none;
      border-bottom: 2px solid;
      transform: translate(-20px, 66px);
    `}
  border-color: ${props => props.theme.colors.middlegray};
`
