import React from 'react'

import { Round } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getTuples } from '../../utils/bracketDataUtils'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}
//only used in bracket size 64 and 128
const Round5ALosers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const top: { [key: number]: string } = {
    128: bottomHalf ? '325px' : '250px',
    64: bottomHalf ? '0px' : '570px',
  }
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)

  const seats = getTuples(halfSeats)

  return (
    <Round round="5" shift={shift} top={top[numTeams]}>
      {seats.map(tuple => (
        <BracketCouplet seats={tuple} round="5A" bottom={'628px'} numTeams={numTeams} />
      ))}
    </Round>
  )
}

export default Round5ALosers
