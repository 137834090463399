import dayjs from 'dayjs'

interface ITournament {
  decided: boolean
  registrationCloseDate?: any
  registrationOpenDate?: any
}

export const inRegistration = (tournament: ITournament) => {
  return (
    dayjs(tournament.registrationCloseDate) > dayjs() &&
    dayjs(tournament.registrationOpenDate) < dayjs()
  )
}
export const isUpcoming = (tournament: ITournament) => {
  return dayjs(tournament.registrationOpenDate) > dayjs()
}

export const tournamentStyleFormatter = (style: string | undefined | null): string =>
  style === 'double_elim' ? 'double elim' : 'single elim'

export const isActive = (tournament: ITournament) =>
  !isUpcoming(tournament) && !inRegistration(tournament) && !tournament.decided
