import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex } from 'rebass'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { useVerfiyNewEsportsUserMutation, VerfiyNewUserMutation } from '../../../types/graphql'
import { handleResult } from '../../../utils/results'
import { paths } from '../../../utils/Routes'
import UserAboutSection from '../../molecules/UserAboutSection/UserAboutSection'

gql`
  mutation VerfiyNewEsportsUser(
    $name: String!
    $streetAddress: String
    $postalCode: String
    $city: String
    $addressUnit: String
    $province: String
    $discordUsername: String!
  ) {
    verifyNewUser(
      name: $name
      discordUsername: $discordUsername
      streetAddress: $streetAddress
      postalCode: $postalCode
      city: $city
      addressUnit: $addressUnit
      province: $province
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`

gql`
  mutation VerfiyNewUser(
    $name: String!
    $streetAddress: String
    $postalCode: String
    $city: String
    $addressUnit: String
    $province: String
    $discordUsername: String!
  ) {
    verifyNewUser(
      name: $name
      discordUsername: $discordUsername
      streetAddress: $streetAddress
      postalCode: $postalCode
      city: $city
      addressUnit: $addressUnit
      province: $province
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface IAboutSectionProps {
  onFormSubmitted: () => void
}

const AboutMe: React.FC<IAboutSectionProps> = ({ onFormSubmitted }) => {
  const history = useHistory()
  const {
    setCurrentUserContextLoading,
    setCurrentUserFormErrors,
    fullName,
    postalCode,
    streetAddress,
    city,
    province,
    addressUnit,
    discordUsername,
  } = useContext(CurrentUserContext)

  const useVerfiyNewUser = useVerfiyNewEsportsUserMutation

  //  add address here
  const [verifyNewUser, { loading }] = useVerfiyNewUser({
    variables: {
      name: fullName,
      addressUnit: addressUnit,
      province: province,
      city: city,
      streetAddress: streetAddress,
      postalCode: postalCode,
      discordUsername,
    },
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data: VerfiyNewUserMutation) => {
      handleResult({
        result: data.verifyNewUser,
        onSuccess: () => {
          setCurrentUserContextLoading(false)
          onFormSubmitted()
        },
        onFailure: (errors) => {
          setCurrentUserContextLoading(false)
          setCurrentUserFormErrors(errors)
        },
      })
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Flex>
      <UserAboutSection
        headerText="complete your profile"
        submitButtonText="continue"
        cancelButtonText="skip profile setup"
        onCancel={() => history.push(paths.profile())}
        onSubmit={verifyNewUser}
      ></UserAboutSection>
    </Flex>
  )
}

export default AboutMe
