import React, { useState } from 'react'
import { oc } from 'ts-optchain'

import { IImageFields } from '../components/molecules/ImageUploader'
import { SetState } from '../types/aliases'
import {
  Match,
  RosterEntry,
  Team,
  TeamSeasonStats,
  User,
  UserSeasonStats,
  MatchCarouselMatchInfoFragment as CarouselMatch,
  MatchCarouselTournamentCheckinInfoFragment as Tournament,
  SeasonRegistry
} from '../types/graphql'
import { rawNumPlayers } from '../utils/sportUtils'
import { teamStarters, teamSubstitutes } from '../utils/teamUtils'

interface IApplicantsType extends RosterEntry {
  invitation?: boolean
}

interface ITeamState {
  teamContextLoading: boolean
  id: string
  name: string
  approvedLogo: IImageFields
  pendingLogo: IImageFields
  approvedBanner: IImageFields
  pendingBanner: IImageFields
  sportSlug: string
  facebookUsername: string
  twitterUsername: string
  twitchUsername: string
  coordinator: User
  selectedSeasonStats: TeamSeasonStats
  activeRosterEntries: RosterEntry[]
  applicants: IApplicantsType[]
  selectedSeasonMatches: Match[]
  settledMatches: Match[]
  conferenceName: string
  divisionName: string
  seasonStanding: number
  isRecruiting: boolean
  numAwards: number
  universityId: string
  university: string
  currentUserApplicationPending: boolean
  selectedSeasonPlayerStats: UserSeasonStats[]
  currentUserIsCoordinator: boolean
  currentUserIsEligible: boolean
  starterLineup: RosterEntry[]
  substituteLineup: RosterEntry[]
  numPlayers: number
  isLineupFull: boolean
  carouselMatches: CarouselMatch[]
  tournamentsToCheckinTo: Tournament[]
  currentSeasonEligible: number
  seasonRegistries: SeasonRegistry[]

  setTeamContextLoading: SetState<boolean>
  setId: SetState<string>
  setName: SetState<string>
  setApprovedLogo: SetState<IImageFields>
  setPendingLogo: SetState<IImageFields>
  setApprovedBanner: SetState<IImageFields>
  setPendingBanner: SetState<IImageFields>
  setSportSlug: SetState<string>
  setFacebookUsername: SetState<string>
  setTwitterUsername: SetState<string>
  setTwitchUsername: SetState<string>
  setCoordinator: SetState<User>
  setSelectedSeasonStats: SetState<TeamSeasonStats>
  setActiveRosterEntries: SetState<RosterEntry[]>
  setApplicants: SetState<IApplicantsType[]>
  setSelectedSeasonMatches: SetState<Match[]>
  setSettledMatches: SetState<Match[]>
  setConferenceName: SetState<string>
  setDivisionName: SetState<string>
  setSeasonStanding: SetState<number>
  setIsRecruiting: SetState<boolean>
  setNumAwards: SetState<number>
  setUniversityId: SetState<string>
  setUniversity: SetState<string>
  setCurrentUserApplicationPending: SetState<boolean>
  setSelectedSeasonPlayerStats: SetState<UserSeasonStats[]>
  setCurrentUserIsCoordinator: SetState<boolean>
  setCurrentUserIsEligible: SetState<boolean>
  setCarouselMatches: SetState<CarouselMatch[]>
  setTournamentsToCheckinTo: SetState<Tournament[]>
  setCurrentSeasonEligible: SetState<number>
  setSeasonRegistries: SetState<SeasonRegistry[]>

  updateFromQuery: (team: Team) => void
}

interface IProps {
  children?: any
}

const defaultTeamState: ITeamState = {
  teamContextLoading: false,
  id: '',
  name: '',
  approvedLogo: {} as IImageFields,
  pendingLogo: {} as IImageFields,
  approvedBanner: {} as IImageFields,
  pendingBanner: {} as IImageFields,
  sportSlug: '',
  facebookUsername: '',
  twitterUsername: '',
  twitchUsername: '',
  coordinator: {} as User,
  selectedSeasonStats: {} as TeamSeasonStats,
  activeRosterEntries: [],
  applicants: [],
  selectedSeasonMatches: [],
  settledMatches: [],
  conferenceName: '',
  divisionName: '',
  seasonStanding: 0,
  isRecruiting: false,
  numAwards: 0,
  universityId: '',
  university: '',
  currentUserApplicationPending: false,
  selectedSeasonPlayerStats: [],
  currentUserIsCoordinator: false,
  currentUserIsEligible: false,
  starterLineup: [],
  substituteLineup: [],
  numPlayers: 0,
  isLineupFull: false,
  carouselMatches: [],
  tournamentsToCheckinTo: [],
  currentSeasonEligible: 0,
  seasonRegistries: [],

  setTeamContextLoading: (): void => {},
  setId: (): void => {},
  setName: (): void => {},
  setApprovedLogo: (): void => {},
  setPendingLogo: (): void => {},
  setApprovedBanner: (): void => {},
  setPendingBanner: (): void => {},
  setSportSlug: (): void => {},
  setFacebookUsername: (): void => {},
  setTwitterUsername: (): void => {},
  setTwitchUsername: (): void => {},
  setCoordinator: (): void => {},
  setSelectedSeasonStats: (): void => {},
  setActiveRosterEntries: (): void => {},
  setApplicants: (): void => {},
  setSelectedSeasonMatches: (): void => {},
  setSettledMatches: (): void => {},
  setConferenceName: (): void => {},
  setDivisionName: (): void => {},
  setSeasonStanding: (): void => {},
  setIsRecruiting: (): void => {},
  setNumAwards: (): void => {},
  setUniversityId: (): void => {},
  setUniversity: (): void => {},
  setCurrentUserApplicationPending: (): void => {},
  setSelectedSeasonPlayerStats: (): void => {},
  setCurrentUserIsCoordinator: (): void => {},
  setCurrentUserIsEligible: (): void => {},
  setCarouselMatches: (): void => {},
  setTournamentsToCheckinTo: (): void => {},
  setCurrentSeasonEligible: (): void => {},
  setSeasonRegistries: (): void => {},

  updateFromQuery: (): void => {},
}

export const TeamContext = React.createContext<ITeamState>(defaultTeamState)

export const TeamProvider = ({ children }: IProps) => {
  const [teamContextLoading, setTeamContextLoading] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [approvedLogo, setApprovedLogo] = useState({} as IImageFields)
  const [pendingLogo, setPendingLogo] = useState({} as IImageFields)
  const [approvedBanner, setApprovedBanner] = useState({} as IImageFields)
  const [pendingBanner, setPendingBanner] = useState({} as IImageFields)
  const [sportSlug, setSportSlug] = useState('')
  const [facebookUsername, setFacebookUsername] = useState('')
  const [twitterUsername, setTwitterUsername] = useState('')
  const [twitchUsername, setTwitchUsername] = useState('')
  const [coordinator, setCoordinator] = useState({} as User)
  const [selectedSeasonStats, setSelectedSeasonStats] = useState({} as TeamSeasonStats)
  const [activeRosterEntries, setActiveRosterEntries] = useState<RosterEntry[]>([])
  const [applicants, setApplicants] = useState<IApplicantsType[]>([])
  const [selectedSeasonMatches, setSelectedSeasonMatches] = useState<Match[]>([])
  const [settledMatches, setSettledMatches] = useState<Match[]>([])
  const [conferenceName, setConferenceName] = useState('')
  const [divisionName, setDivisionName] = useState('')
  const [seasonStanding, setSeasonStanding] = useState<number>(0)
  const [isRecruiting, setIsRecruiting] = useState(false)
  const [numAwards, setNumAwards] = useState(0)
  const [universityId, setUniversityId] = useState('')
  const [university, setUniversity] = useState('')
  const [currentUserApplicationPending, setCurrentUserApplicationPending] = useState(false)
  const [selectedSeasonPlayerStats, setSelectedSeasonPlayerStats] = useState<UserSeasonStats[]>([])
  const [currentUserIsCoordinator, setCurrentUserIsCoordinator] = useState(false)
  const [currentUserIsEligible, setCurrentUserIsEligible] = useState(false)
  const [carouselMatches, setCarouselMatches] = useState<CarouselMatch[]>([])
  const [tournamentsToCheckinTo, setTournamentsToCheckinTo] = useState<Tournament[]>([])
  const [currentSeasonEligible, setCurrentSeasonEligible] = useState<number>(0)
  const [seasonRegistries, setSeasonRegistries] = useState<SeasonRegistry[]>([])
  const starterLineup = teamStarters(activeRosterEntries)
  const substituteLineup = teamSubstitutes(activeRosterEntries)
  const numPlayers = Number(rawNumPlayers(sportSlug))
  const isLineupFull = starterLineup.length >= numPlayers

  const updateFromQuery = (team: Team) => {
    if (team) {
      setId(team.id)
      setName(team.name)
      setSportSlug(team.sportSlug)
      setFacebookUsername(oc(team).facebookUsername(''))
      setTwitterUsername(oc(team).twitterUsername(''))
      setTwitchUsername(oc(team).twitchUsername(''))
      setIsRecruiting(team.isRecruiting)
      setCurrentUserApplicationPending(team.currentUserApplicationPending)
      setCurrentUserIsCoordinator(team.currentUserIsCoordinator)
      setCurrentUserIsEligible(team.currentUserIsEligible)
      setSettledMatches(team.settledMatches)

      setApplicants(
        (team.pendingRosterEntries as IApplicantsType[]).concat(
          (team.pendingInvitations as IApplicantsType[]).map(invitation => ({
            ...invitation,
            invitation: true,
          })),
        ),
      )

      setCarouselMatches(
        [...team.upcomingMatches, ...team.unreportedMatches].sort(function(a, b) {
          return a.scheduledDate.localeCompare(b.scheduledDate)
        }),
      )

      setTournamentsToCheckinTo(team.tournamentsToCheckinTo)

      if (team.coordinator) {
        setCoordinator(team.coordinator)
      }

      if (team.approvedLogoImage && team.approvedLogoImage.dataUrl) {
        setApprovedLogo({
          name: team.approvedLogoImage.name || 'logo.png',
          dataUrl: team.approvedLogoImage.dataUrl,
        })
      }

      if (team.pendingLogoImage && team.pendingLogoImage.dataUrl) {
        setPendingLogo({
          name: team.pendingLogoImage.name || 'logo.png',
          dataUrl: team.pendingLogoImage.dataUrl,
        })
      }

      if (team.approvedBannerImage && team.approvedBannerImage.dataUrl) {
        setApprovedBanner({
          name: team.approvedBannerImage.name || 'banner.png',
          dataUrl: team.approvedBannerImage.dataUrl,
        })
      }

      if (team.pendingBannerImage && team.pendingBannerImage.dataUrl) {
        setPendingBanner({
          name: team.pendingBannerImage.name || 'banner.png',
          dataUrl: team.pendingBannerImage.dataUrl,
        })
      }
      if (team.selectedSeasonMatches) setSelectedSeasonMatches(team.selectedSeasonMatches)
      if (team.selectedSeasonStats) setSelectedSeasonStats(team.selectedSeasonStats)
      if (team.activeRosterEntries) setActiveRosterEntries(team.activeRosterEntries)
      if (team.selectedSeasonConferenceName) setConferenceName(team.selectedSeasonConferenceName)
      if (team.selectedSeason && team.selectedSeason.divisionName)
        setDivisionName(team.selectedSeason.divisionName)
      if (team.selectedSeasonRank) setSeasonStanding(team.selectedSeasonRank)
      if (team.teamAwards) setNumAwards(team.teamAwards.length)
      if (team.university) {
        setUniversityId(team.university.id)
        setUniversity(oc(team.university).abbreviation(''))
      }
      if (team.selectedSeasonPlayerStats)
        setSelectedSeasonPlayerStats(team.selectedSeasonPlayerStats)
      if (team.currentSeasonEligible) {
        setCurrentSeasonEligible(team.currentSeasonEligible)
      }

      if (team.seasonRegistries) {
        setSeasonRegistries(team.seasonRegistries)
      }
    }
    setTeamContextLoading(false)
  }

  return (
    <TeamContext.Provider
      value={{
        teamContextLoading,
        id,
        name,
        approvedLogo,
        pendingLogo,
        approvedBanner,
        pendingBanner,
        sportSlug,
        facebookUsername,
        twitterUsername,
        twitchUsername,
        coordinator,
        selectedSeasonStats,
        activeRosterEntries,
        applicants,
        selectedSeasonMatches,
        settledMatches,
        conferenceName,
        divisionName,
        seasonStanding,
        isRecruiting,
        numAwards,
        universityId,
        university,
        currentUserApplicationPending,
        selectedSeasonPlayerStats,
        currentUserIsCoordinator,
        currentUserIsEligible,
        starterLineup,
        substituteLineup,
        numPlayers,
        isLineupFull,
        carouselMatches,
        tournamentsToCheckinTo,
        currentSeasonEligible,
        seasonRegistries,

        setTeamContextLoading,
        setId,
        setName,
        setApprovedLogo,
        setPendingLogo,
        setApprovedBanner,
        setPendingBanner,
        setSportSlug,
        setFacebookUsername,
        setTwitterUsername,
        setTwitchUsername,
        setCoordinator,
        setSelectedSeasonStats,
        setActiveRosterEntries,
        setApplicants,
        setSelectedSeasonMatches,
        setSettledMatches,
        setConferenceName,
        setDivisionName,
        setSeasonStanding,
        setIsRecruiting,
        setNumAwards,
        setUniversityId,
        setUniversity,
        setCurrentUserApplicationPending,
        setSelectedSeasonPlayerStats,
        setCurrentUserIsCoordinator,
        setCurrentUserIsEligible,
        setCarouselMatches,
        setTournamentsToCheckinTo,
        setCurrentSeasonEligible,
        setSeasonRegistries,
        updateFromQuery,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}
