import React, { useState } from 'react'
import { Box, Flex, Text } from 'rebass'
import gql from 'graphql-tag'

import Content from '../atoms/Content'
import DataFetcher from '../organisms/DataFetcher'
import DashboardMatchHistory from '../organisms/DashboardMatchHistory'
import MatchCarousel from '../organisms/MatchCarousel'
import News from './UserDashboard/News'
import DiscordWidget from '../atoms/DiscordWidget'
import {
  DashboardMatchHistoryInfoFragment as HistoryMatch,
  MatchCarouselMatchInfoFragment as CarouselMatch,
  MyTeamInfoFragment as Team,
  MatchCarouselTournamentCheckinInfoFragment as Tournament,
  useDashboardQuery,
} from '../../types/graphql'
import MyTeams from '../organisms/MyTeams'

gql`
  query Dashboard {
    userDashboard {
      id
      activeTeams {
        ...MyTeamInfo
      }
      upcomingMatches {
        ...MatchCarouselMatchInfo
      }
      tournamentsToCheckinTo {
        ...MatchCarouselTournamentCheckinInfo
      }
      unreportedMatches {
        ...MatchCarouselMatchInfo
      }
      dashboardMatchHistory {
        ...DashboardMatchHistoryInfo
      }
    }
  }
`

const UserDashboard: React.FC = () => {
  const [carouselMatches, setCarouselMatches] = useState<CarouselMatch[]>([])
  const [matchHistory, setMatchHistory] = useState<HistoryMatch[]>([])
  const [teams, setTeams] = useState<Team[]>([])
  const [tournaments, setTournaments] = useState<Tournament[]>([])

  const { error, loading } = useDashboardQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data.userDashboard) {
        const upcomingMatches = data.userDashboard.upcomingMatches
        const unreportedMatches = data.userDashboard.unreportedMatches
        const _carouselMatches = new Set([...unreportedMatches, ...upcomingMatches])
        setCarouselMatches(
          [..._carouselMatches].sort(function(a, b) {
            return a.scheduledDate.localeCompare(b.scheduledDate)
          }),
        )
        data.userDashboard.dashboardMatchHistory &&
          setMatchHistory(data.userDashboard.dashboardMatchHistory)
        data.userDashboard.tournamentsToCheckinTo &&
          setTournaments(data.userDashboard.tournamentsToCheckinTo)
        setTeams(data.userDashboard.activeTeams)
      }
    },
  })
  return (
    <DataFetcher error={error} loading={loading}>
      <Content>
        <Box>
          <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
            {(carouselMatches.length > 0 || tournaments.length > 0) && (
              <>
                <Box width={1} mb={4}>
                  <h2>My Matches</h2>
                </Box>
                <MatchCarousel tournaments={tournaments} matches={carouselMatches} />
              </>
            )}

            <Box width={1} mt={[6, 6, 4]}>
              <Flex flexDirection="column" justifyContent="flex-start">
                <Text pb={3}>
                  <h2>My Teams</h2>
                </Text>
                <MyTeams teams={teams} />
              </Flex>
            </Box>

            <Box width={1} mt={6}>
              <Flex flexDirection={['column', 'column', 'row']} alignItems="center" mx={-2}>
                <Box width={[1, 1, 1, 1 / 2]} px={2}>
                  <DashboardMatchHistory matches={matchHistory} teamIds={teams.map(t => t.id)} />
                </Box>
                <Box width={[1, 1, 1, 1 / 2]} px={2} mt={[6, 6, 0]}>
                  <Text pb={2}>
                    <h2>Join Discord</h2>
                  </Text>
                  <DiscordWidget />
                </Box>
              </Flex>
            </Box>

            <Box width={1} mt={6}>
              <News />
            </Box>
          </Flex>
        </Box>
      </Content>
    </DataFetcher>
  )
}

export default UserDashboard
