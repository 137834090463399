import React from 'react'

interface AwardProps {
  color?: string
  width?: string
  height?: string
  viewBox?: string
}

export const MinorAward: React.FunctionComponent<AwardProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.124 19c-.204 1.148-1.08 1.96-2.181 1.96-.354 0-.704-.084-1.041-.249l-1.075-.527-.68 1.02c-.046.07-.096.134-.147.197l2.89 6.49c.063.143.173.146.243.006l1.39-2.749a.38.38 0 01.396-.193l2.905.651c.147.033.216-.057.152-.2L17.124 19zM7.174 20.185l-1.075.527c-.338.165-.688.25-1.041.25-1.1 0-1.977-.813-2.181-1.962L.023 25.407c-.064.143.005.232.152.2l2.905-.652a.38.38 0 01.396.194l1.39 2.748c.07.14.18.137.244-.006L8 21.4c-.05-.064-.1-.128-.147-.197l-.679-1.02z"
        fill={color}
      />
      <path
        d="M20.628 9.871l-1.259-.878c-.494-.346-.691-1.076-.435-1.624l.652-1.389c.256-.547-.027-1.037-.63-1.088l-1.528-.132c-.602-.051-1.136-.586-1.188-1.188l-.132-1.528c-.051-.602-.54-.885-1.088-.63l-1.39.651c-.547.256-1.278.06-1.624-.435L11.13.371c-.346-.495-.912-.495-1.257 0l-.88 1.26c-.345.494-1.075.69-1.622.434l-1.39-.65c-.547-.257-1.038.026-1.089.628l-.13 1.529c-.052.602-.587 1.137-1.189 1.188l-1.53.132c-.601.051-.884.541-.628 1.088l.651 1.39c.257.547.06 1.278-.434 1.624l-1.26.878c-.495.345-.495.911 0 1.257l1.26.878c.495.345.69 1.076.434 1.623l-.651 1.39c-.257.547.027 1.037.629 1.088l1.53.132c.601.051 1.136.586 1.188 1.188l.13 1.53c.052.601.542.884 1.089.628l1.39-.651c.547-.256 1.277-.06 1.623.435l.879 1.258c.345.496.91.496 1.257 0l.878-1.258c.346-.495 1.076-.691 1.623-.435l1.39.65c.547.257 1.037-.026 1.088-.628l.131-1.529c.052-.602.586-1.137 1.188-1.188l1.53-.131c.601-.052.884-.542.628-1.089l-.651-1.39c-.256-.547-.06-1.278.435-1.623l1.259-.878c.496-.347.496-.912 0-1.258zM10.5 16.815a6.316 6.316 0 110-12.631 6.316 6.316 0 010 12.631z"
        fill={color}
      />
      <path
        d="M12.5 8.588c-.292-.04-.636-.273-.767-.52l-.997-1.883c-.13-.247-.343-.247-.473 0l-.997 1.884c-.13.246-.475.48-.767.52l-2.23.301c-.29.04-.356.229-.145.42l1.614 1.467c.21.192.342.57.292.84l-.38 2.071c-.05.27.122.387.382.26l1.994-.978a1.22 1.22 0 01.948 0l1.994.978c.26.128.433.011.383-.26l-.381-2.07c-.05-.271.082-.649.292-.841l1.614-1.467c.212-.191.145-.38-.146-.42l-2.23-.302z"
        fill={color}
      />
    </svg>
  )
}

export const MajorAward: React.FunctionComponent<AwardProps> = ({
  color,
  width,
  height,
  viewBox,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
    >
      <path
        d="M10.762 25H6v3h16v-3H10.762zM24 11l4-2.99V2h-4v9zM23 0H5v13.645l7.66 5.807v3.774h-1.915V24h6.51v-.774H15.34v-3.774L23 13.645V0zm-5.379 14.134L14 12.084l-3.621 2.05.811-4.114-3.05-2.847 4.123-.492L14 2.87l1.736 3.81 4.123.493-3.05 2.847.812 4.114zM0 8.01L4 11V2H0v6.01z"
        fill={color}
      />
      <path
        d="M14.89 8.03L14 6l-.89 2.03-2.11.262 1.562 1.516L12.146 12 14 10.907 15.854 12l-.415-2.192L17 8.292l-2.11-.262z"
        fill={color}
      />
    </svg>
  )
}
