import React from 'react'
import { Box, Flex } from 'rebass'

import { MajorAward, MinorAward } from '../../assets/icons/award-icons'
import { Theme } from '../../styles/settings'

interface AwardProps {
  major?: number
  minor?: number
}

const Awards: React.FunctionComponent<AwardProps> = ({ major, minor }) => {
  return (
    <Flex color={Theme.colors.white}>
      {[...Array(Number(major))].map((e, i) => (
        <Box mr={'.75rem'} key={i}>
          <MajorAward color={Theme.colors.white} width={'1.75rem'} height={'1.75rem'} key={i} />
        </Box>
      ))}
      {[...Array(Number(minor))].map((e, i) => (
        <Box key={i}>
          <MinorAward color={Theme.colors.white} width={'2rem'} height={'2rem'} key={i} />
        </Box>
      ))}
    </Flex>
  )
}

export default Awards
