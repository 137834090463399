import { useApolloClient } from '@apollo/react-hooks'
import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'
import { useContext } from 'react'
import { TbdCheatSheetContext } from '../components/pages/TournamentDetail'
import { paths } from './Routes'

function useMatchDataFromSeat(seat?: number) {
  const client: ApolloClient<any> = useApolloClient()
  const match = client.readFragment({
    id: 'TournamentMatch:' + seat,
    fragment: gql`
      fragment blah on TournamentMatch @client {
        id
        team1Name
        team1Id
        matchNumber
        matchId
        team1Wins
        team2Name
        team2Id
        team2Wins
        settled
        team1Seed
        team2Seed
        scheduledDate
      }
    `,
  })
  return match
}

export const getQuadrant = (seatList: Array<number>, quadrant: number) => {
  const startIndex = (seatList.length / 4) * (quadrant - 1)
  const endIndex = (seatList.length / 4) * quadrant
  const subArray = seatList.slice(startIndex, endIndex)

  let seats: Array<Array<number>> = []
  for (var i = 0; i < subArray.length; i += 2) {
    if (seatList.length >= 5) {
      var seat1 = subArray[i]
      var seat2 = subArray[i + 1]

      seats.push([seat1, seat2])
    } else {
      var seat = subArray[i]
      seats.push([seat])
    }
  }
  return seats
}

export const getTuples = (halfSeats: Array<number>) => {
  let seats: Array<Array<number>> = []
  for (var i = 0; i < halfSeats.length; i += 2) {
    if (halfSeats.length % 2 === 0) {
      var seat1 = halfSeats[i]
      var seat2 = halfSeats[i + 1]

      seats.push([seat1, seat2])
    } else {
      var seat = halfSeats[i]
      seats.push([seat])
    }
  }
  return seats
}

export const useTeamNameFromSeat = (seat: number, homeTeam: boolean) => {
  const match = useMatchDataFromSeat(seat)

  let name = match ? (homeTeam ? match['team1Name'] : match['team2Name']) : 'tbd'

  const { tbdCheatSheet } = useContext(TbdCheatSheetContext)

  name =
    name.toLowerCase() === 'tbd'
      ? seat in tbdCheatSheet
        ? tbdCheatSheet[seat][homeTeam ? 0 : 1]
          ? `Loser of ${tbdCheatSheet[seat][homeTeam ? 0 : 1]}`
          : 'tbd'
        : 'tbd'
      : name
  return name
}
export const useTeamSeedFromSeat = (seat: number, homeTeam: boolean) => {
  const match = useMatchDataFromSeat(seat)
  return match ? (homeTeam ? match['team1Seed'] : match['team2Seed']) : ''
}
export const useTeamWinsFromSeat = (seat: number, homeTeam: boolean) => {
  const match = useMatchDataFromSeat(seat)
  return match
    ? match['team1Name'] === 'Bye' || match['team2Name'] === 'Bye'
      ? '-'
      : homeTeam
      ? match['team1Wins']
      : match['team2Wins']
    : 'tbd'
}

export const useTeamWinnerFromSeat = (seat: number, homeTeam: boolean) => {
  const match = useMatchDataFromSeat(seat)
  return match
    ? match.settled
      ? homeTeam
        ? parseInt(match['team1Wins']) > parseInt(match['team2Wins'])
        : parseInt(match['team1Wins']) < parseInt(match['team2Wins'])
      : false
    : false
}
export const useTeamLoserFromSeat = (seat: number, homeTeam: boolean) => {
  const match = useMatchDataFromSeat(seat)
  return match
    ? match.settled
      ? !homeTeam
        ? parseInt(match['team1Wins']) > parseInt(match['team2Wins'])
        : parseInt(match['team1Wins']) < parseInt(match['team2Wins'])
      : false
    : false
}
export const useTeamIdFromSeat = (seat: number, homeTeam: boolean) => {
  const match = useMatchDataFromSeat(seat)
  return match ? (homeTeam ? parseInt(match['team1Id']) : parseInt(match['team2Id'])) : null
}

export const useMatchUrlFromSeat = (seat: number) => {
  const match = useMatchDataFromSeat(seat)
  return match
    ? match['team1Name'] === 'Bye' || match['team2Name'] === 'Bye'
      ? ''
      : match['matchId']
      ? paths.matchDetail(match['matchId'])
      : ''
    : ''
}
export const useMatchSettled = (seat: number) => {
  const match = useMatchDataFromSeat(seat)
  return match ? match.settled === 'true' : null
}
//TODO: add scheduledDate to query (for use in center bracket status message)
export const useMatchScheduledDate = (seat: number) => {
  const match = useMatchDataFromSeat(seat)
  return match ? match.scheduledDate : null
}

export const useGrandFinalsTBDMatchTeam = (precedingSeat?: number) => {
  const precedingMatch = useMatchDataFromSeat(precedingSeat)
  if (precedingMatch && precedingMatch.settled === 'true') {
    return precedingMatch.team1Wins > precedingMatch.team2Wins
      ? precedingMatch.team1Name
      : precedingMatch.team2Name
  }
  return 'tbd'
}

export const startingSeedFromMatchIndex = (index: number) => {
  const topLeftSeeds = [1, 64, 32, 33, 16, 49, 17, 48, 8, 57, 25, 40, 9, 56, 24, 41]
  const bottomleftSeeds = [2, 63, 31, 34, 15, 50, 18, 47, 7, 58, 26, 39, 10, 55, 23, 42]
  const bottomRightSeeds = [3, 62, 30, 35, 14, 51, 19, 46, 6, 59, 27, 38, 11, 54, 22, 43]
  const topRightSeeds = [4, 61, 29, 36, 13, 52, 20, 45, 5, 60, 28, 37, 12, 53, 21, 44]
  const orderedSeeeds = topLeftSeeds
    .concat(topRightSeeds)
    .concat(bottomleftSeeds)
    .concat(bottomRightSeeds)

  return orderedSeeeds[index]
}

export const blankBracketData = () => {
  const seats = [...Array(253).keys()]
  const data = seats.map(s => ({
    __typename: 'TournamentMatch',
    id: s,
    matchId: '',
    matchNumber: '',
    team1Id: null,
    team1Name: 'tbd',
    team1Wins: 0,
    team2Id: null,
    team2Name: 'tbd',
    team2Seed: '',
    team1Seed: '',
    team2Wins: 0,
    settled: false,
    scheduledDate: '',
  }))
  return data
}

//Goes from Seat number to match number
export const useMatchNumber = (seat: number) => {
  const match = useMatchDataFromSeat(seat)
  return match ? match['matchNumber'] : ''
  // const lookupTable: { [key: number]: number } = {
  //   2: 1,
  //   6: 2,
  //   10: 3,
  //   14: 4,
  //   18: 5,
  //   22: 6,
  //   26: 7,
  //   30: 8,
  //   34: 9,
  //   38: 10,
  //   42: 11,
  //   46: 12,
  //   50: 13,
  //   54: 14,
  //   58: 15,
  //   62: 16,
  //   66: 17,
  //   70: 18,
  //   74: 19,
  //   78: 20,
  //   82: 21,
  //   86: 22,
  //   90: 23,
  //   94: 24,
  //   98: 25,
  //   102: 26,
  //   106: 27,
  //   110: 28,
  //   114: 29,
  //   118: 30,
  //   122: 31,
  //   126: 32,
  //   140: 33,
  //   146: 34,
  //   154: 35,
  //   160: 36,
  //   170: 37,
  //   176: 38,
  //   184: 39,
  //   190: 40,
  //   202: 41,
  //   208: 42,
  //   216: 43,
  //   222: 44,
  //   232: 45,
  //   238: 46,
  //   246: 47,
  //   252: 48,
  //   4: 49,
  //   12: 50,
  //   20: 51,
  //   28: 52,
  //   36: 53,
  //   44: 54,
  //   52: 55,
  //   60: 56,
  //   68: 57,
  //   76: 58,
  //   84: 59,
  //   92: 60,
  //   100: 61,
  //   108: 62,
  //   116: 63,
  //   124: 64,
  //   138: 65,
  //   144: 66,
  //   152: 67,
  //   158: 68,
  //   168: 69,
  //   174: 70,
  //   182: 71,
  //   188: 72,
  //   200: 73,
  //   206: 74,
  //   214: 75,
  //   220: 76,
  //   230: 77,
  //   236: 78,
  //   244: 79,
  //   250: 80,
  //   142: 81,
  //   156: 82,
  //   172: 83,
  //   186: 84,
  //   204: 85,
  //   218: 86,
  //   234: 87,
  //   248: 88,
  //   8: 89,
  //   24: 90,
  //   40: 91,
  //   56: 92,
  //   72: 93,
  //   88: 94,
  //   104: 95,
  //   120: 96,
  //   136: 97,
  //   150: 98,
  //   166: 99,
  //   180: 100,
  //   198: 101,
  //   212: 102,
  //   228: 103,
  //   242: 104,
  //   148: 105,
  //   178: 106,
  //   210: 107,
  //   240: 108,
  //   16: 109,
  //   48: 110,
  //   80: 111,
  //   112: 112,
  //   134: 113,
  //   164: 114,
  //   196: 115,
  //   226: 116,
  //   162: 117,
  //   224: 118,
  //   32: 119,
  //   96: 120,
  //   132: 121,
  //   194: 122,
  // }

  // return lookupTable[parseInt(seat)]
}
