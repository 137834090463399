import { useTheme } from 'emotion-theming'
import React from 'react'
import { AlertTriangle } from 'react-feather'
import { Flex, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { useModal } from 'react-modal-hook'

import { paths } from '../../utils/Routes'
import { MyTeamInfoFragment as Team } from '../../types/graphql'
import { allSportSlugs } from '../../utils/sportUtils'
import { BaseButton } from '../atoms/Buttons'
import Alert from '../atoms/Alert'
import Card from '../atoms/Card'
import League from '../atoms/League'
import SportLogo from '../atoms/SportLogo'
import Table from '../atoms/Table'
import ModalContainer from '../atoms/ModalContainer'
import CreateTeam from '../organisms/CreateTeam'

gql`
  fragment MyTeamInfo on Team {
    id
    name
    selectedSeasonConferenceName
    isRecruiting
    selectedSeason {
      id
      divisionName
      seasonYear
    }
    selectedSeasonStats {
      losses
      wins
      id
    }
    sportSlug
  }
`

interface IProps {
  teams: Team[]
}

const StyledText = styled.text`
  max-width: 16rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & a {
    color: ${(props) => props.theme.colors.primarynavy};
    :hover {
      color: ${(props) => props.theme.colors.primaryblue};
    }
  }
`

const MyTeams: React.FC<IProps> = ({ teams }) => {
  const { colors } = useTheme()

  const playedSports: string[] = [...new Set(teams.map((team: Team) => team.sportSlug))]
    .sort()
    .reverse()

  const matchingSportOrder = allSportSlugs.sort(
    (a: string, b: string) => playedSports.indexOf(b) - playedSports.indexOf(a),
  )

  const [showCreateTeamModal, hideCreateTeamModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideCreateTeamModal}
        style={{
          content: { maxWidth: '50rem' },
        }}
      >
        <CreateTeam
          unplayedSports={matchingSportOrder.filter((sport) => playedSports.includes(sport))}
          playedSports={playedSports}
        />
      </ModalContainer>
    ),
    [playedSports, matchingSportOrder],
  )
  if (teams.length === 0) {
    return (
      <Card notch={true} notchSize={16} mt={['1rem', '1rem', 0]} py={[6]} px={[4]}>
        <Alert borderColor={colors.sand} mx={['auto']}>
          <AlertTriangle size={38} color={colors.sand} />
          <Text my={5} color="darkgray">
            <h6>No Active Teams!</h6>
          </Text>
          <Text mb={5}>
            <p>
              {/* Looks like you dont have a team yet. Browse through your University Hub to find one or
              create your own! */}
              Create a team below so you can start competing!
            </p>
          </Text>
          {/* <Link to={paths.myUniversity}>
            <BaseButton>Browse University Hub</BaseButton>
          </Link> */}
          <BaseButton onClick={showCreateTeamModal}>Create a Team</BaseButton>
        </Alert>
      </Card>
    )
  }

  const hasRegisteredTeam = (team: Team) => team.selectedSeason && team.selectedSeason.divisionName

  return (
    <Table headerBackgroundColor="middlegray">
      <thead>
        <tr>
          <th />
          <th>
            <Text color={colors.secondarynavy}>
              <h6>Team</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.secondarynavy}>
              <h6>Conference</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.secondarynavy}>
              <h6>League</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.secondarynavy}>
              <h6>Record</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.transparent}>Link</Text>
          </th>
        </tr>
      </thead>

      <tbody>
        {teams.map((team) => (
          <tr key={team.id}>
            <td>{team.sportSlug && <SportLogo width="1.5625rem" sport={team.sportSlug} />}</td>
            <td>
              <Flex>
                <StyledText color={colors.primarynavy}>
                  <Link to={paths.teamDetail(team.id)}>
                    <h6>{team.name}</h6>
                  </Link>
                </StyledText>
              </Flex>
            </td>
            <td>
              {team.selectedSeasonConferenceName ? (
                <Text color={colors.darkgray}>
                  <h6>{team.selectedSeasonConferenceName}</h6>
                </Text>
              ) : (
                '–'
              )}
            </td>

            <td>
              {team.selectedSeason && team.selectedSeason.divisionName ? (
                <League
                  league={team.selectedSeason && team.selectedSeason.divisionName}
                  color={colors.secondarynavy}
                />
              ) : (
                <Text color={colors.red}>
                  <h6>Not Registered</h6>
                </Text>
              )}
            </td>

            <td>
              {team.selectedSeasonStats ? (
                <Text color={colors.darkgray}>
                  <h6>
                    {team.selectedSeasonStats.wins}-{team.selectedSeasonStats.losses}
                  </h6>
                </Text>
              ) : (
                '–'
              )}
            </td>

            <td>
              <Link to={hasRegisteredTeam(team) ? paths.teamDetail(team.id) : paths.leagues()}>
                <BaseButton variant="secondary">
                  {hasRegisteredTeam(team) ? 'View Roster' : 'Register'}
                </BaseButton>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MyTeams
