import React from 'react'
import { useTheme } from 'emotion-theming'
import { LinkProps, Text, Link } from 'rebass'
import { IoMdArrowBack } from 'react-icons/io'
import { styled, Theme } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import HeroImage from '../../assets/images/hero__news-announcements.jpg'
import { BaseButton } from '../atoms/Buttons'
import AdminLink from '../atoms/AdminLink'
import Animation from '../atoms/Animation'
import Hero from '../molecules/Hero'

const StyledLink = styled(Link)<LinkProps>`
  display: flex;

  h6 {
    transition: ${props => props.theme.transitions.fast};
    &:hover {
      color: ${Theme.colors.teal};
    }
  }
`

interface INewsArticleDetailHeroProps {
  id: string
}

const NewsArticleDetailHero: React.FC<INewsArticleDetailHeroProps> = ({ id }) => {
  const { colors } = useTheme()
  const animations = {
    hidden: {
      opacity: 0,
      x: '-3rem',
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }

  return (
    <Hero
      title={'News & Announcements'}
      image={HeroImage}
      topLeftComponent={
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.8, delay: 0.6 }}
        >
          <StyledLink href={paths.newsAndAnnouncements()}>
            <IoMdArrowBack color={colors.teal} />
            <Text ml={2} color={colors.white}>
              <h6>back to news and Announcements</h6>
            </Text>
          </StyledLink>
        </Animation>
      }
      actionsComponent={
        <AdminLink href={paths.manageNewsAndAnnouncements({ id: id })}>
          <BaseButton>Edit Post</BaseButton>
        </AdminLink>
      }
    ></Hero>
  )
}

export default NewsArticleDetailHero
