import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'

import { BaseButton } from '../../atoms/Buttons'
import { CustomSelect } from '../../atoms/Select'
import { LeagueDetailRegisterTeamForSeasonMutationFn as RegisterTeamForSeasonMutationFn } from '../../../types/graphql'
import { paths } from '../../../utils/Routes'
import { handleResult, errorOn } from '../../../utils/results'
import { ITeamLike } from './types'

interface ILeagueRegistrationModalProps {
  teams: ITeamLike[]
  leagueName: string
  leagueId: string
  registerTeamForSeason: RegisterTeamForSeasonMutationFn
}

const LeagueRegistrationModal: React.FC<ILeagueRegistrationModalProps> = ({
  teams,
  leagueName,
  leagueId,
  registerTeamForSeason,
}) => {
  const [selectedTeam, setSelectedTeam] = useState<ITeamLike>(teams[0])
  const [redirectTo, setRedirectTo] = useState<string>()

  const registerButtonHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const teamId = selectedTeam.id
    const seasonId = leagueId

    try {
      const { data } = await registerTeamForSeason({
        variables: {
          teamId,
          seasonId,
        },
      })
      handleResult({
        result: data!.registerTeamForSeason,
        onSuccess: _value => {
          toast.success(`Registered ${selectedTeam.name} for ${leagueName}`, {
            containerId: 'temporary',
          })
          return setRedirectTo(paths.leagues())
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {redirectTo && <Redirect to={redirectTo} />}
      <h2>Register Your Team</h2>
      <p>
        Interested in registering your team to be a part of the <b>{leagueName}</b>? Select the team
        from the dropdown below that you would like to register.
      </p>
      <CustomSelect
        mb={1}
        onChange={e => setSelectedTeam(teams.find(t => t.id === e.target.value)!)}
        value={selectedTeam.id!}
      >
        {teams.map(team => (
          <option key={team.id} value={team.id}>
            {team.name}
          </option>
        ))}
      </CustomSelect>
      <BaseButton onClick={registerButtonHandler}>Register</BaseButton>
    </>
  )
}

export default LeagueRegistrationModal
