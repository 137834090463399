import React from 'react'
import { Box, BoxProps, Flex } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { Sport } from '../../types/graphql'
import SportLogoSelector from '../molecules/SportLogoSelector'

interface GameProps extends BoxProps {
  sports: Array<Sport>
  changeSport: (arg0: string) => void
  customPadding?: string
  currentSport?: string
}

const StyledSportLogoSelector = styled(SportLogoSelector)`
  padding: 0.5rem;
`

const GameSelector: React.FC<GameProps> = ({
  sports,
  currentSport,
  size,
  changeSport,
  customPadding,
}) => {
  return (
    <Flex mx={-2}>
      {sports.map(
        (sport: Sport) =>
          sport.slug && (
            <Box key={sport.slug} px={2}>
              <StyledSportLogoSelector
                size={size}
                sportSlug={sport.slug}
                onClick={() => changeSport(sport.slug as string)}
                isSelected={currentSport === sport.slug}
                customPadding={customPadding}
              />
            </Box>
          ),
      )}
    </Flex>
  )
}

export default GameSelector
