import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'

export function createApolloClient() {
  const cache = new InMemoryCache()
  const options = {
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'include',
  }

  const httpLink = ApolloLink.split(
    operation => operation.getContext().hasUpload,
    createUploadLink(options),
    createHttpLink(options),
  )

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('csl_access-token')
    const clientToken = localStorage.getItem('csl_client-token')
    const expiry = localStorage.getItem('csl_expiry')
    const uid = localStorage.getItem('csl_uid')
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          'access-token': token,
          uid: uid,
          client: clientToken,
          expiry: expiry,
          'token-type': 'Bearer',
        },
      }
    }
    return { headers }
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, httpLink]),
  })
  client.writeData({
    data: {
      tournamentMatchData: { team1Id: '', team1Wins: '', __typename: 'TournamentResult' },
    },
  })
  return client
}

export default {
  createApolloClient,
}
