import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { TournamentContext } from '../../contexts/TournamentContext'
import { paths } from '../../utils/Routes'

const AdminTournamentCreateRedirect: React.FC = () => {
  const { tournamentId } = useContext(TournamentContext)
  return tournamentId ? <Redirect to={paths.manageTournament(tournamentId, 'basic-info')} /> : null
}

export default AdminTournamentCreateRedirect
