import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Box, Text } from 'rebass'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import HeroImage from '../../assets/images/hero__home-page.jpg'
import Animation from '../atoms/Animation'
import Card from '../atoms/Card'
import ContactForm from '../molecules/ContactForm'
import Content from '../atoms/Content'
import Hero from '../molecules/Hero'
import FooterSocialIcons from '../molecules/FooterSocialIcons'
import { BaseButton } from '../atoms/Buttons'

const StyledCard = styled(Card)`
  padding: 1rem;
  background: ${props => props.theme.colors.white};

  h2 {
    margin-bottom: 1rem;
  }

  ${Mq.md} {
    padding: 2rem;
  }
`

const ContactContent = styled(Box)`
  padding: 2rem 0 0;

  button {
    float: right;
  }
`

const ContactText = styled(Flex)`
  margin: 0 auto;
  padding-bottom: 2rem;
`

const Divider = styled(Box)`
  height: 2px;
  margin-top: 2rem;
  background: ${props => props.theme.colors.teal};
  width: 100%;
`

const Footer = styled(Card)`
  color: ${props => props.theme.colors.white};
  padding: 4rem;
  margin-top: 4rem;
  background: ${props => props.theme.colors.primarynavy};
  border-radius: 0 6px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Center = styled(Flex)`
  justify-content: center;
  width: 100%;
`

const Contact: React.FC = () => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    noWidth: {
      width: 0,
      opacity: 1,
      y: 0,
    },
    fullWidth: {
      width: '100%',
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <Hero
        title={'Contact Us'}
        image={HeroImage}
        position="center 30%"
        height="18rem"
        tint={'3,51,89, 0.6'}
      ></Hero>
      <Content>
        <StyledCard mb={6} notch={true} notchSize={20}>
          <ContactContent>
            <ContactText justifyContent="space-between" alignItems="flex-end">
              <Box width={2 / 3}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                >
                  <h2>We're here to help</h2>
                </Animation>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
                >
                  <p>
                    We are here to answer your questions about your CSL experiences. One of our
                    dedicated team members will respond as soon as possible.
                  </p>
                </Animation>
              </Box>
              <Box width={1 / 3}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
                >
                  <Link to={paths.support()}>
                    <BaseButton variant="secondary">Create Support Ticket</BaseButton>
                  </Link>
                </Animation>
              </Box>
            </ContactText>

            <Animation
              initial="noWidth"
              animate="fullWidth"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 1, delay: 1 }}
            >
              <Divider />
            </Animation>

            <ContactForm action="https://formspree.io/f/xrgopble" />
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Footer notch={true} notchSize={20}>
                <Text mb={4}>
                  <h2>Follow Us On Social</h2>
                </Text>
                <Center mt={6}>
                  <FooterSocialIcons position="relative" />
                </Center>
              </Footer>
            </Animation>
          </ContactContent>
        </StyledCard>
      </Content>
    </>
  )
}

export default Contact
