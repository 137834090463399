import React from 'react'
import { Flex, Text } from 'rebass'

import { MediaQueries as Mq, styled } from '../../styles/settings'
import { tournamentStyleFormatter } from '../../utils/tournament'

interface FactsProps {
  dates?: string
  teams?: string
  players?: string
  style?: string
}

const Facts = styled(Text)`
  padding-bottom: 1rem;
  color: ${props => props.theme.colors.white};
  ${Mq.sm} {
    display: flex;
    padding-bottom: 0;
    margin-right: 0.5rem;
  }
`

const TournamentFacts = ({ dates, teams, players, style }: FactsProps) => {
  return (
    <Flex alignItems="center">
      <Facts>
        <h5>{dates}</h5>
      </Facts>
      {teams && (
        <Facts>
          <h5>|</h5>
        </Facts>
      )}
      <Facts>
        <h5>{teams} teams</h5>
      </Facts>
      {players && (
        <>
          <Facts>
            <h5>|</h5>
          </Facts>
          <Facts>
            <h5>{players} players</h5>
          </Facts>
        </>
      )}
      {style && (
        <Facts>
          <h5>|</h5>
        </Facts>
      )}
      <Facts>
        <h5> {tournamentStyleFormatter(style)}</h5>
      </Facts>
    </Flex>
  )
}

export default TournamentFacts
