import React, { useState, useEffect } from 'react'
import { Flex, Text } from 'rebass'
import { styled, MediaQueries as Mq } from '../../../styles/settings'

import { ClickEvent } from '../../../types/aliases'
import { filterImage } from './filterImages'
import { PostCategories } from '../../../types/graphql'
import { spaceify } from '../../../utils/strings'
import { BaseButton } from '../../atoms/Buttons'
import Card from '../../atoms/Card'

interface INewsCategorySelectorProps {
  selectedCategory?: PostCategories
  categories: PostCategories[]
  onCategoryChanged: (category?: PostCategories) => void
}

interface IStyledCategorySelectionCard {
  selected?: boolean
  tint?: string
  background?: string
}

const StyledFlex = styled(Flex)`
  display: grid;
  padding: 0 12px;
  grid-template-columns: 50% 50%;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  ${Mq.md} {
    display: flex;
    padding: 0px;
  }
`

const StyledCategorySelectionCard = styled(Card)<IStyledCategorySelectionCard>`
  width: 100%;
  margin: 0px;
  ${Mq.md} {
    width: 25%;

    :first-of-type {
      margin-left: 0;
    }

    :last-of-type {
      margin-right: 0;
    }
  }

  button {
    width: 100%;
    height: 6rem;
    color: ${props => props.theme.colors.white};
    border-radius: 0;
    border-bottom: ${props =>
      props.selected
        ? `2px solid ${props.theme.colors.teal}`
        : `2px solid ${props.theme.colors.primarynavy}`};
    border-top: none;
    border-left: none;
    border-right: none;
    background-size: cover;
    background-position: center;
    box-shadow: ${props =>
      props.selected ? `inset 0px -50px 50px -30px rgba(43,254,200,0.71)` : `none`};
    background: linear-gradient(
      rgba(3, 51, 89, 0.75),
      rgba(3, 51, 89, 0.75)
    ), url('${props => props.background}');

    &:hover {
      box-shadow: inset 0px -50px 50px -30px rgba(43,254,200,0.71);
      background: linear-gradient(
        rgba(3, 51, 89, 0.75),
        rgba(3, 51, 89, 0.75)
      ), url('${props => props.background}');
      border-bottom: 2px solid ${props => props.theme.colors.teal};
    }

    &:focus {
      border-bottom: 2px solid ${props => props.theme.colors.teal};
    }
  }
`

const NewsCategorySelector: React.FC<INewsCategorySelectorProps> = ({
  selectedCategory: origSelectedCategory,
  categories,
  onCategoryChanged,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<PostCategories | undefined>(
    origSelectedCategory,
  )

  useEffect(() => {
    setSelectedCategory(origSelectedCategory)
  }, [origSelectedCategory])

  const isSelected = (category: PostCategories) => {
    return selectedCategory === category
  }

  const handleCategoryClicked = (category: PostCategories) => (_e: ClickEvent) => {
    const newCategory = category === selectedCategory ? undefined : category
    setSelectedCategory(newCategory)
    onCategoryChanged(newCategory)
  }

  return (
    <StyledFlex flexDirection={['column', 'column', 'row']} justifyContent="center" my={5}>
      {categories.map(category => (
        <StyledCategorySelectionCard
          key={category}
          selected={isSelected(category)}
          mx={4}
          background={filterImage(category)}
          width={1 / categories.length}
          notch={true}
          notchSize={12}
        >
          <Text textAlign="center">
            <BaseButton onClick={handleCategoryClicked(category)}>{spaceify(category)}</BaseButton>
          </Text>
        </StyledCategorySelectionCard>
      ))}
    </StyledFlex>
  )
}

export default NewsCategorySelector
