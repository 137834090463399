import { ThemeProvider } from 'emotion-theming'
import React from 'react'
import Modal from 'react-modal'

import { ApolloProvider } from '@apollo/react-hooks'
import { Global } from '@emotion/core'

import { GlobalStyles } from './styles/global'
import { Theme } from './styles/settings'
import { createApolloClient } from './utils/apolloUtils'
import { Routes } from './utils/Routes'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)

const client = createApolloClient()

Modal.setAppElement('#root')

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <Global styles={GlobalStyles} />
      <ApolloProvider client={client}>
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>
      </ApolloProvider>
    </ThemeProvider>
  )
}

export default App
