import React from 'react'
import { NavLink, Route, RouteComponentProps, useRouteMatch } from 'react-router-dom'
import { Box } from 'rebass'
import { styled } from '../../styles/settings'

import { TournamentProvider } from '../../contexts/TournamentContext'
import AdminTournamentCreateRedirect from '../atoms/AdminTournamentCreateRedirect'
import AdminTournamentInfo from '../organisms/AdminTournamentInfo'
import AdminTournamentTitle from '../atoms/AdminTournamentTitle'
import Content from '../atoms/Content'

const EditNav = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    font-family: ${props => props.theme.fonts.condensed};
    margin-bottom: 1.5rem;
  }
`

const CustomNavLink = styled(NavLink)`
  color: ${props => props.theme.colors.darkmiddlegray};
  &.active {
    color: ${props => props.theme.colors.darkgray};
  }
`

interface IURLParams {
  tournamentId?: string
  page?: string
}

interface IProps extends RouteComponentProps<IURLParams> {
  customStyles: any
}

const CreateTournament: React.FC<IProps> = () => {
  //basic info
  const match = useRouteMatch()
  const sections: { [key: string]: React.ReactElement } = {
    'basic-info': <AdminTournamentInfo />,
  }
  return (
    <TournamentProvider>
      <AdminTournamentCreateRedirect />
      <Content>
        <AdminTournamentTitle />
        <Box
          sx={{
            display: 'grid',
            gridGap: 1,
            gridTemplateColumns: '1fr 4fr',
          }}
        >
          <EditNav>
            <li key={'info'}>
              <CustomNavLink to={`${match.url}`}>
                <h5>basic info</h5>
              </CustomNavLink>
            </li>
          </EditNav>
          <Route exact path={`${match.path}`} render={() => sections['basic-info']} />
          <Route
            path={`${match.path}/:section`}
            render={({ match }) => sections[match.params.section]}
          />
        </Box>
      </Content>
    </TournamentProvider>
  )
}

export default CreateTournament
