import React from 'react'
import { Flex, FlexProps } from 'rebass'

import { MediaQueries as Mq, styled } from '../../../styles/settings/theme'
import { paths } from '../../../utils/Routes'
import { siteSwitcher } from '../../../utils/sites'
import NavList from './NavList'

interface IAdminMenuProps {
  textAlign?: string
  closeMenu?: () => void
}

const AdminMenuWrapper = styled(Flex)<FlexProps>`
  flex-direction: column;
  padding: 1rem 0;

  ${Mq.md} {
    position: absolute;
    background: white;
    top: 4rem;
    right: 3rem;
    width: 280px;
    border: 1px solid ${props => props.theme.colors.lightgray};
    padding: ${props => props.theme.space[6]}px;
    transition: ${props => props.theme.transitions.fast};
    color: ${props => props.theme.colors.primarynavy};

    a:hover {
      color: ${props => props.theme.colors.primaryblue};
    }

    --notchSize: 20px;

    clip-path: polygon(
      0% 0px,
      0px 0%,
      calc(100% - var(--notchSize)) 0%,
      100% 0px,
      100% calc(100% - 0px),
      calc(100% - 0px) 100%,
      var(--notchSize) 100%,
      0% calc(100% - var(--notchSize))
    );
  }
`

// TODO: DRY up this + UserMenu
const AdminMenu: React.FC<IAdminMenuProps> = ({ textAlign, closeMenu }) => {
  let collegeListItems: { path: string; label?: string }[] = [
    { path: paths.createNewsAndAnnouncements(), label: 'Create News/Announcements' },
    { path: paths.createSeason() },
    { path: paths.createTournament() },
    { path: paths.createUniversity() },
    { path: paths.userSearch(), label: 'Find a User' },
    { path: paths.forfeits() },
    { path: paths.mediaTools() },
    { path: paths.newsAndAnnouncements(), label: 'News' },
    { path: paths.logOut() },
  ]

  let esportsListItems: { path: string; label?: string }[] = [
    { path: paths.createNewsAndAnnouncements(), label: 'Create News/Announcements' },
    { path: paths.createTournament() },
    { path: paths.userSearch(), label: 'Find a User' },
    { path: paths.forfeits() },
    { path: paths.mediaTools() },
    { path: paths.newsAndAnnouncements(), label: 'News' },
    { path: paths.logOut() },
  ]

  return (
    <AdminMenuWrapper id="userMenu" onMouseLeave={closeMenu ? closeMenu : () => {}}>
      <NavList
        listItems={siteSwitcher({
          college: collegeListItems,
          esports: esportsListItems,
        })}
        flexDirection="column"
        textAlign={textAlign}
      />
    </AdminMenuWrapper>
  )
}

export default AdminMenu
