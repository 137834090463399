import React from 'react'
import { Text } from 'rebass'
import { MainHeader, Paragraph, SectionHeader, SubHeader } from '../atoms/RulesTypography'

const NhlRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="NHL 2K21" />
      <SectionHeader text="rulebooks" />
      <Paragraph>CSL NHL 2K21 2020-2021 Season Rulebook: Coming Soon</Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY NHL 2K21 RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/csl" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #NHL2k-help chat.
        </h3>
      </Text>
    </>
  )
}

export default NhlRules
