import gql from 'graphql-tag'
import { History } from 'history'
import React, { useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'

import DataFetcher from './DataFetcher'

const LOGOUT_MUTATION = gql`
  mutation SignOut {
    signOut {
      success
    }
  }
`

interface IHasHistory {
  history: History
}

const LogOut: React.FC<IHasHistory> = ({ history }) => {
  const [logout, { data, loading, error }] = useMutation(LOGOUT_MUTATION, {
    onCompleted() {
      localStorage.removeItem('csl_access-token')
      localStorage.removeItem('csl_client-token')
      localStorage.removeItem('csl_username')
      localStorage.removeItem('csl_expiry')
      localStorage.removeItem('csl_uid')
      sessionStorage.clear()
    },
  })

  if (data) {
    window.location.href = '/'
  }

  const doLogOut = () => {
    logout()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => doLogOut(), [])

  return (
    <DataFetcher error={error} loading={loading}>
      <></>
    </DataFetcher>
  )
}

export default LogOut
