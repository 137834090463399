import React from 'react'
import { Route, RouteComponentProps, useRouteMatch, Redirect, useHistory } from 'react-router-dom'
import { oc } from 'ts-optchain'
import { camelCase, upperFirst, startCase } from 'lodash'
import { Flex } from 'rebass'

import { paths } from '../../../utils/Routes'
import { TeamProvider } from '../../../contexts/TeamContext'
import TeamDetailQueryProvider from '../../organisms/TeamDetailQueryProvider'
import Filters from '../../molecules/Filters'
import ManageTeamTitle from '../../atoms/ManageTeamTitle'
import Content from '../../atoms/Content'
import BackButton from '../../atoms/BackButton'
import ManageTeamLineup from '../../organisms/ManageTeamLineup'
import ManageTeamDetails from '../../organisms/ManageTeamDetails'
import { ClickEvent } from '../../../types/aliases'

interface IURLParams {
  teamId?: string
  anchor?: string
}

interface IProps extends RouteComponentProps<IURLParams> {}

const ManageTeam: React.FC<IProps> = ({ match: { params } }) => {
  const match = useRouteMatch()
  const history = useHistory()
  const teamId = oc(params).teamId('')
  const anchor = oc(params).anchor('')

  enum Anchors {
    TeamLineup = 'team-lineup',
    TeamDetails = 'team-details',
  }

  const sections: { [key: string]: React.ReactElement } = {
    [Anchors.TeamLineup]: <ManageTeamLineup />,
    [Anchors.TeamDetails]: <ManageTeamDetails />,
  }

  const onAnchorClick = (anchor: string) => {
    history.push(paths.manageTeam(teamId, Anchors[upperFirst(camelCase(anchor))]))
  }

  return anchor === '' ? (
    <Redirect to={paths.manageTeam(teamId, Anchors.TeamLineup)} />
  ) : (
    <TeamProvider>
      <TeamDetailQueryProvider teamId={teamId} isCoordinatorRoute>
        <Content>
          <BackButton
            onClick={() => history.push(paths.teamDetail(teamId))}
            text="Team detail page"
          />
          <Flex
            mb={5}
            justifyContent="space-between"
            flexDirection={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'center']}
          >
            <ManageTeamTitle />
            <Filters
              selectedFilter={startCase(anchor)}
              filters={Object.keys(Anchors).map(anchor => startCase(anchor))}
              setSelectedFilter={(_e: ClickEvent, anchor: string) => onAnchorClick(anchor)}
            />
          </Flex>

          <Route
            path={`${match.path}/:section`}
            render={({ match }) => sections[match.params.section]}
          />
        </Content>
      </TeamDetailQueryProvider>
    </TeamProvider>
  )
}

export default ManageTeam
