import React from 'react'
import { Box, Text, Flex } from 'rebass'
import { styled } from '../../styles/settings/theme'

const LabelContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
`

const Triangle = styled(Box)`
  width: 0.75rem;
  background: linear-gradient(
    to left bottom,
    transparent 50%,
    ${props => props.theme.colors.darkmiddlegray} 50%
  );
`
const LabelBox = styled(Box)`
  border-radius: 4px 0px 0px 0px;
`

const LabelText = styled(Text)`
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`
interface LabelProps {
  text: string
}

const TabLabel = ({ text }: LabelProps) => {
  return (
    <LabelContainer>
      <LabelBox bg="darkmiddlegray" px={4} pt={'2px'} mb={'-2px'}>
        <LabelText color="darkgray">{text}</LabelText>
      </LabelBox>
      <Triangle />
    </LabelContainer>
  )
}

export default TabLabel
