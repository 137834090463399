import React, { useContext } from 'react'
import { Flex, FlexProps } from 'rebass'
import { styled } from '../../../styles/settings/theme'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { FieldType, allFieldsBySport } from '../../../utils/gamingProfilesUtils'
import { IconRemove } from '../../../assets/icons/icon-remove'
import { InputFieldWithErrors } from '../InputFieldWithErrors'
import SportLogo from '../../atoms/SportLogo'

const SportFieldsWrapper = styled(Flex)<FlexProps>`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-right: 54px;
`

const StyledSportLogo = styled(SportLogo)`
  height: 48px;
  width: 48px;
  padding: 10px;
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  margin-bottom: 24px;
  margin-right: 15px;
`

const RemoveIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 12px;
`

interface IGamingProfileFieldProps {
  removeSport?: (sport: string) => void
  sport: string
}

const GamingProfileField: React.FC<IGamingProfileFieldProps> = ({ removeSport, sport }) => {
  const {
    sportFields,
    setSportFields,
    sportDropdownFieldOptions,
    currentUserFormErrors,
    updateErrors,
    validateSportField,
  } = useContext(CurrentUserContext)

  const handleUpdateField = (value: string, currentField: FieldType) => {
    validateSportField(value, currentField)
    setSportFields({ ...sportFields, [currentField.name]: value })
  }

  const sportField = (field: FieldType) => {
    return (
      <InputFieldWithErrors
        width="265px"
        mb={5}
        key={field.name}
        type={field.type}
        name={field.name}
        label={field.label}
        placeholder={field.placeholder}
        value={sportFields[field.name] || ''}
        updateSingleField={(value: string) => {
          handleUpdateField(value, field)
        }}
        maxLength={field.maxLength}
        errorMessage={currentUserFormErrors[field.name]}
        updateErrors={updateErrors}
        isRequired={field.required && !field.exclusiveOf}
      >
        {sportDropdownFieldOptions[field.name] &&
          sportDropdownFieldOptions[field.name]['options'] &&
          sportDropdownFieldOptions[field.name]['options'].map((option: string) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </InputFieldWithErrors>
    )
  }

  return (
    <SportFieldsWrapper>
      <Flex alignItems="center" justifyContent="flex-end">
        <StyledSportLogo sport={sport} />
        {sportField(allFieldsBySport[sport][0])}
        {removeSport && (
          <RemoveIconWrapper onClick={() => removeSport(sport)}>
            <IconRemove />
          </RemoveIconWrapper>
        )}
      </Flex>

      <Flex flexDirection="column" alignItems="flex-end" ml="62px" mt={2}>
        {allFieldsBySport[sport]
          .slice(1, allFieldsBySport[sport].length)
          .map((field: FieldType) => sportField(field))}
      </Flex>
    </SportFieldsWrapper>
  )
}

export default GamingProfileField
