import React, { useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { updateQueryParams, paramify } from '../utils/Routes'

interface IQueryParamsUpdaterParams {
  queryParams?: object
}

type UseCurrentQueryParamsReturn<A> = [
  object | undefined,
  (existingParams: A, newParams: object) => void,
]

export function useCurrentQueryParams<IQueryParams extends object>(
  initialQueryParams?: object,
): UseCurrentQueryParamsReturn<IQueryParams> {
  const [currentQueryParams, setCurrentQueryParams] = useState(initialQueryParams)

  const handleUpdateCurrentQueryParams = (existingParams: IQueryParams, newParams: object) => {
    const newQueryParams = updateQueryParams<IQueryParams>(existingParams, newParams)
    setCurrentQueryParams(newQueryParams)
  }

  return [currentQueryParams, handleUpdateCurrentQueryParams]
}

export const QueryParamsUpdater: React.FC<IQueryParamsUpdaterParams> = ({ queryParams }) => {
  const queryString = queryParams && paramify(queryParams)

  const { pathname } = useLocation()

  return queryString ? <Redirect to={{ pathname, search: queryString }} /> : null
}
