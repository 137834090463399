import React from 'react'
import { Box, Link } from 'rebass'

import { styled } from '../../../styles/settings'
import { NewsArticleTypes, SportSlugs } from '../../../types/graphql'
import { sportLogo } from '../../../utils/logoUtils'
import { paths } from '../../../utils/Routes'
import Animation from '../../atoms/Animation'
import { BaseButton } from '../../atoms/Buttons'
import NewsOrAnnouncementSummary from './NewsOrAnnouncementSummary'
import { bigImgStyles } from './styles'
import SummaryItemLogo from './SummaryItemLogo'
import { NewsCategory } from './types'

interface INewsSummaryProps {
  createdAt: string
  featuredImage: string
  id: string
  newsCategory: NewsCategory
  slug?: string | null
  sport?: SportSlugs | null
  summary: string
  title: string
  published?: boolean | null
}

const StyledNewsBox = styled(Box)`
  ${bigImgStyles}
`

const NewsSummary: React.FC<INewsSummaryProps> = ({
  id,
  slug,
  createdAt,
  featuredImage,
  title,
  summary,
  sport,
  newsCategory,
  published,
}) => {
  const logo = sport && sportLogo(sport)
  const link = paths.newsArticle(slug || id)

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Animation
      initial="hidden"
      animate="visible"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
    >
      <StyledNewsBox mx={6} mt={6}>
        <NewsOrAnnouncementSummary
          type={NewsArticleTypes.News}
          newsCategory={newsCategory}
          title={title}
          summary={summary}
          image={featuredImage}
          date={createdAt}
          published={!!published}
          upperLeftComponent={logo ? <SummaryItemLogo src={logo} /> : null}
          button={
            <Link href={link}>
              <BaseButton variant="secondary">Read More</BaseButton>
            </Link>
          }
          link={link}
        />
      </StyledNewsBox>
    </Animation>
  )
}

export default NewsSummary
