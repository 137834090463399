import React from 'react'

import { Round, BottomLine } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const RoundOf4: React.FunctionComponent<RoundProps> = ({
  seatList,
  shift,
  bottomHalf,
  numTeams,
}) => {
  const seats = bottomHalf ? [seatList[1]] : [seatList[0]]

  const top = (size: number) => {
    const topPxAmount: { [key: number]: string } = {
      128: bottomHalf ? '0px' : '1203px',
      64: bottomHalf ? '0px' : '1018px',
      32: bottomHalf ? '0px' : '614px',
      16: bottomHalf ? '0px' : '460px',
      8: bottomHalf ? '0px' : '283px',
      4: bottomHalf ? '0px' : '210px',
    }
    return topPxAmount[size]
  }

  return (
    <Round round="4" top={top(numTeams)} shift={shift}>
      {bottomHalf && <BottomLine bottomHalf={bottomHalf} numTeams={numTeams} />}
      <BracketCouplet seats={seats} round="4" />
      {!bottomHalf && <BottomLine numTeams={numTeams} />}
    </Round>
  )
}

export default RoundOf4
