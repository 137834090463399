import { Box, Flex, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { Settings } from 'react-feather'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'

interface IPlayerAdminLinksProps {
  playerId: string
}

interface ISettingItemProps {
  text: string
  path: string
}

const SettingItem: React.FC<ISettingItemProps> = ({ text, path }) => {
  const { colors } = useTheme()

  return (
    <Link to={path}>
      <BaseButton backgroundColor={colors.white} variant="tertairy" p={4} mr={4} mb={[3, 0]}>
        <Flex alignItems="center">
          <Settings color={colors.primarynavy} />
          <Text ml={4} color={colors.primaryblue}>
            <h6>{text}</h6>
          </Text>
        </Flex>
      </BaseButton>
    </Link>
  )
}

const PlayerAdminLinks: React.FC<IPlayerAdminLinksProps> = ({ playerId }) => {
  return (
    <Box mb={[5, 7]}>
      <Text mb={4}>
        <h2>admin tools</h2>
      </Text>
      <Flex flexDirection={['column', 'row']}>
        <SettingItem text="profile settings" path={paths.profileSettings(playerId)} />
        <SettingItem text="account settings" path={paths.accountSettings(playerId)} />
        <SettingItem text="team settings" path={paths.teamSettings(playerId)} />
      </Flex>
    </Box>
  )
}

export default PlayerAdminLinks
