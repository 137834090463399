import D from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin

export const formats = {
  fullWithLongMonth: 'MMMM D, YYYY',
  fullWithShortMonth: 'MMM D, YYYY',
  //Should this just be LLL? \/
  fullWithShortMonthAndTime: 'MMM D, YYYY - h:mm A z',
  dayAndFullWithShortMonthAndTime: 'dddd, MMM D, YYYY - h:mm a z',
  timeOnly: 'h:mm A',
  MoDDoYY: 'M.DD.YY',
}

export const format = (fmt: string) => (date: Date | string) => {
  return D(date).format(fmt)
}
export const adjustedFormat = (fmt: string) => (date: Date | string | D.Dayjs) => {
  return getLocallyAdjustedDateTime(date).format(fmt)
}

export const getLocallyAdjustedDateTime = (dateTime: Date | string | D.Dayjs) => {
  D.extend(utc)
  D.extend(timezone)
  D.extend(advanced)
  return D(dateTime).tz(D.tz.guess())
}

export const fullWithShortMonth = format(formats.fullWithShortMonth)
export const fullWithShortMonthAndTime = format(formats.fullWithShortMonthAndTime)
export const adjustedDayAndFullWithShortMonthAndTime = adjustedFormat(
  formats.dayAndFullWithShortMonthAndTime,
)
export const adjustedFullWithShortMonthAndTime = adjustedFormat(formats.fullWithShortMonthAndTime)
export const adjustedFullWithShortMonth = adjustedFormat(formats.fullWithShortMonth)
export const adjustedTimeOnly = adjustedFormat(formats.timeOnly)
export const fullWithLongMonth = format(formats.fullWithLongMonth)

export const MoDDoYY = format(formats.MoDDoYY)

export const justDateFrom = (datetime: string) => {
  return datetime.split(' ')[0]
}

export default {
  format,
  formats,
  fullWithShortMonth,
  fullWithLongMonth,
  MoDDoYY,
  justDateFrom,
}
