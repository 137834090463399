import clsx from 'clsx'
import { props } from 'ramda'
import React, { useEffect, useState } from 'react'
import { FiMenu, FiXCircle } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'

import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

import {
  MediaQueries as Mq,
  styled,
  Theme,
} from '../../../styles/settings/theme'
import { currentUserIsLoggedIn } from '../../../utils/accountUtils'
import { paths } from '../../../utils/Routes'
import { currentlyInRegistration } from '../../../utils/seasonUtils'
import Logo from '../../atoms/Logo'
import StyledLink from '../../atoms/StyledLink'
import NotificationBar from '../../molecules/NotificationBar'
import Toasts from '../../molecules/Toasts'
import Notifications from '../../organisms/Notifications'
import AdminMenu from './AdminMenu'
import MainMenu from './MainMenu'
import UserMenu from './UserMenu'

const DrawerHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
`

const StyledList = styled(Box)`
  padding: 0 1rem;

  svg {
    ${Mq.md} {
      display: none;
    }
  }
`

const StyledUserBox = styled(Box)`
  border-top: 1px solid ${props => props.theme.colors.darkmiddlegray};
  padding-top: 1rem;
  margin-top: 1.5rem;
  display: flex;
  padding-left: 1rem;
  flex-direction: column;

  svg {
    ${Mq.md} {
      display: none;
    }
  }
`

const StyledAdminBox = StyledUserBox

const StyledDrawer = styled(Drawer)`
  width: 80%;
  flex-shrink: 0;
  padding-left: 1rem;

  div:first-of-type {
    width: 240px;
  }
`
const NavContent = styled.div`
  z-index: 999;
`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 'auto',
    height: '4rem',
    alignItems: 'center',
    zIndex: 9999,
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    border: '0px',
    position: 'sticky',
    top: '0',
    background: Theme.colors.white,
  },
  appBar: {
    boxShadow: '0px',
  },
  hide: {
    display: 'none',
  },
  closeIcon: {
    flex: 'auto',
    maxWidth: '56px',
    margin: '4px 0px auto auto',
    justifyContent: 'flex-end',
    width: 'max-content',
  },
  header: {
    border: '0px',
    background: 'transparent',
  },
}))

interface INavProps {
  isIncompleteAccount: boolean
  userIsAdmin: boolean
  userId: string
  username?: string
  showInviteModal: () => void
}

interface IProps {
  window?: () => Window
  children: React.ReactElement
}

const HideOnScroll: React.FC<IProps> = props => {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const HamburgerNav: React.FC<INavProps> = ({
  isIncompleteAccount,
  userIsAdmin,
  userId,
  username,
  showInviteModal,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [location])

  const CtaBanner = () => {
    const isHome = window.location.href.split('/')[3] === '' ? true : false
    return currentlyInRegistration() && isHome && !currentUserIsLoggedIn()
  }

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          color="inherit"
          elevation={0}
          style={{
            visibility: 'visible' /* hacky fix to prevent <Slide> from hiding temporary toasts */,
          }}
        >
          <NavContent>
            <NotificationBar />
            <Toolbar className={classes.root}>
              <Logo variant="dark" />
              <IconButton>
                <Notifications />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
                edge="end"
                className={clsx(open && classes.hide)}
              >
                <FiMenu />
              </IconButton>
            </Toolbar>
            <Toasts className={CtaBanner() ? 'with-banner' : 'standard'} />
          </NavContent>
        </AppBar>
      </HideOnScroll>

      <StyledDrawer variant="persistent" anchor="right" open={open}>
        <DrawerHeader className={clsx(open && classes.header)}>
          <IconButton onClick={() => setOpen(false)} className={clsx(classes.closeIcon)}>
            <FiXCircle size={32} />
          </IconButton>
        </DrawerHeader>
        <StyledList>
          <Flex mt="-36px" mb={7} alignItems="center">
            <Box>
              <h3>Menu</h3>
            </Box>
          </Flex>
          <MainMenu flexDirection="column" />
        </StyledList>

        {username ? (
          userIsAdmin ? (
            <StyledAdminBox>
              <AdminMenu />
            </StyledAdminBox>
          ) : (
            <StyledUserBox>
              <UserMenu
                isIncompleteAccount={isIncompleteAccount}
                userId={userId || ''}
                showInviteModal={showInviteModal}
              />
            </StyledUserBox>
          )
        ) : (
          <StyledUserBox>
            <StyledLink href={paths.signup()}>
              <Text mt={1} mr={5}>
                Sign Up
              </Text>
            </StyledLink>
            <StyledLink href={paths.logIn()}>
              <Flex alignItems="center">
                <Text mt={3} mr={3}>
                  login
                </Text>
              </Flex>
            </StyledLink>
          </StyledUserBox>
        )}
      </StyledDrawer>
    </>
  )
}

export default HamburgerNav
