import React from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { Box, Flex } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings'

import { BaseButton } from '../atoms/Buttons'
import AccordionContainer from '../atoms/AccordionContainer'
import Card from '../atoms/Card'
import DivisionLogo from '../atoms/DivisionLogo'

const AccordionItemHeader = styled.h2`
  color: ${props => props.theme.colors.white};
  transition: ${props => props.theme.transitions.fast};
  :hover {
    color: ${props => props.theme.colors.primarylightblue};
  }
`

const AccordionItemSubHeaders = styled.h5`
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;

  ${Mq.md} {
    justify-content: flex-start;
  }
`

const AccordionItemSubHeaderLeft = styled.h5`
  padding-right: 0.5rem;
`

const AccordionItemSubHeaderRight = styled.h5`
  color: ${props => props.theme.colors.orange};
  padding-left: 0.5rem;
`

const AccordionLogo = styled(DivisionLogo)`
  z-index: 10;
`

const StyledAccordionItemButton = styled(AccordionItemButton)`
  text-align: right;
  cursor: pointer;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
`

const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  width: 100%;
  height: 100%;
`

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  background-color: ${props => props.theme.colors.white};
  padding: 2rem 2rem 1rem;
`

const OpensAccordion = styled(AccordionItemButton)`
  cursor: pointer;
`

const StyledBox = styled(Box)`
  text-align: center;

  ${Mq.md} {
    text-align: left;
  }
`

interface IChunkyAccordionItemProps {
  buttonText: string
  heroImage: string
  heroTitle: string | React.ReactNode
  heroSubtitleLeft?: string
  heroSubtitleRight?: string
  logoImage: string
  children: React.ReactNode
  tint?: string
}

const ChunkyAccordionItem: React.FC<IChunkyAccordionItemProps> = ({
  buttonText,
  heroImage,
  heroTitle,
  heroSubtitleLeft,
  heroSubtitleRight,
  logoImage,
  children,
  tint,
}) => {
  return (
    <AccordionItem>
      <Card notch={true}>
        <AccordionContainer height={'13rem'} heroImage={heroImage} tint={tint}>
          <Flex
            width={1}
            height="100%"
            alignItems="center"
            flexDirection={['column', 'column', 'row']}
          >
            <Box width="max-content" pr={5}>
              <AccordionLogo src={logoImage} />
            </Box>
            <StyledBox width={1} mt={[4, 4, 0]}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                flexDirection={['column', 'column', 'row']}
                flexWrap="wrap"
              >
                <Box width="auto">
                  <OpensAccordion>
                    <AccordionItemHeader>{heroTitle}</AccordionItemHeader>
                  </OpensAccordion>
                  <AccordionItemSubHeaders>
                    <AccordionItemSubHeaderLeft>{heroSubtitleLeft}</AccordionItemSubHeaderLeft>
                    {heroSubtitleRight && (
                      <>
                        |
                        <AccordionItemSubHeaderRight>
                          {heroSubtitleRight}
                        </AccordionItemSubHeaderRight>
                      </>
                    )}
                  </AccordionItemSubHeaders>
                </Box>
                <Box width="auto" mt={[4, 4, 0]}>
                  <StyledAccordionItemHeading>
                    <StyledAccordionItemButton>
                      <BaseButton variant="secondaryLight">{buttonText}</BaseButton>
                    </StyledAccordionItemButton>
                  </StyledAccordionItemHeading>
                </Box>
              </Flex>
            </StyledBox>
          </Flex>
        </AccordionContainer>

        <StyledAccordionItemPanel>{children}</StyledAccordionItemPanel>
      </Card>
    </AccordionItem>
  )
}

export default ChunkyAccordionItem
