import React from 'react'
import { Box, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import AsciiEmoji from '../atoms/AsciiEmoji'

const Container = styled(Box)`
  background: ${props => props.theme.colors.backgroundgray};
`

const InnerContainer = styled(Box)`
  border: 2px solid ${props => props.theme.colors.middlegray};
  background: ${props => props.theme.colors.white};
  text-align: center;
`

const MatchDetailEmptyLineup = () => (
  <Container p={5}>
    <InnerContainer px={7} py={5}>
      <AsciiEmoji mb={4}>¯\_(ツ)_/¯</AsciiEmoji>
      <Text color="darkgray">
        <Box mb={3}>
          <h6>No Starting Lineup Indicated</h6>
        </Box>

        <p>This team has failed to report the starting lineup for this game.</p>
      </Text>
    </InnerContainer>
  </Container>
)

export default MatchDetailEmptyLineup
