import React, { useContext, ReactNode } from 'react'
import { Flex, Text, TextProps, Box } from 'rebass'
import { useTheme } from 'emotion-theming'
import { styled } from '../../../styles/settings/theme'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { BaseButton } from '../../atoms/Buttons'
import SectionTitle from '../../atoms/SectionTitle'
import UserAboutField from './UserAboutField'

interface IAboutSectionProps {
  headerText: string
  submitButtonText: string
  cancelButtonText: string
  onCancel: () => void
  onSubmit: () => void
  children?: ReactNode
}

const CancelText = styled(Text)<TextProps>`
  cursor: pointer;
`

const UserAboutSection: React.FC<IAboutSectionProps> = ({
  headerText,
  submitButtonText,
  cancelButtonText,
  onCancel,
  onSubmit,
  children,
}) => {
  const {
    firstName,
    lastName,
    discordUsername,
    requiredFieldsErrorsExist,
    requiredFields,
    setFirstName,
    setLastName,
    setDiscordUsername,
    postalCode,
    setPostalCode,
    streetAddress,
    setStreetAddress,
    city,
    setCity,
    province,
    setProvince,
    addressUnit,
    setAddressUnit,
  } = useContext(CurrentUserContext)
  const { colors } = useTheme()

  const isButtonDisabled = () => {
    const requiredFieldEmpty =
      Object.values(requiredFields).filter(field => field).length <
      Object.values(requiredFields).length

    return requiredFieldsErrorsExist || requiredFieldEmpty
  }

  return (
    <Box>
      <SectionTitle text={headerText} align="center" mb={'7'} />
      <Flex flexDirection="column" mx="auto" width="100%">
        <Text mb={5} color={colors.primarynavy}>
          <h5>a bit about you</h5>
        </Text>
        <Flex
          justifyContent="space-between"
          width="100%"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <UserAboutField name="firstName" value={firstName} updateField={setFirstName} />
          <UserAboutField name="lastName" value={lastName} updateField={setLastName} />
        </Flex>
        <Flex
          justifyContent="space-between"
          width="100%"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <UserAboutField
            name="discordUsername"
            value={discordUsername}
            updateField={setDiscordUsername}
          />
        </Flex>
        <Flex
          mb={4}
          justifyContent="space-between"
          width="100%"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <UserAboutField
            name="streetAddress"
            value={streetAddress}
            updateField={setStreetAddress}
          />
          <UserAboutField name="addressUnit" value={addressUnit} updateField={setAddressUnit} />
        </Flex>
        <Flex
          mb={4}
          justifyContent="space-between"
          width="100%"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <UserAboutField name="postalCode" value={postalCode} updateField={setPostalCode} />
          <UserAboutField name="city" value={city} updateField={setCity} />
        </Flex>
        <Flex
          mb={4}
          justifyContent="space-between"
          width="100%"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <UserAboutField name="province" value={province} updateField={setProvince}>
            {[
              'Ontario',
              'Nova Scotia',
              'New Brunswick',
              'Manitoba',
              'British Columbia',
              'Prince Edward Island',
              'Saskatchewan',
              'Alberta',
              'Newfoundland and Labrador',
            ].map((gender: string) => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </UserAboutField>
        </Flex>
        {children}
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        <BaseButton
          variant={isButtonDisabled() ? 'secondaryDisabled' : 'secondary'}
          disabled={isButtonDisabled()}
          mx="auto"
          onClick={onSubmit}
        >
          {submitButtonText}
        </BaseButton>
        <CancelText my={3} color={colors.primarynavy} onClick={onCancel}>
          <h6>{cancelButtonText}</h6>
        </CancelText>
      </Flex>
    </Box>
  )
}

export default UserAboutSection
