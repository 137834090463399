import React from 'react'
import { Box, Flex } from 'rebass'
import { Link } from 'react-router-dom'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'
import AsciiEmoji from '../atoms/AsciiEmoji'
import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'

interface IColorProps {
  background?: boolean
}

interface IContainerProps {
  background?: boolean
}

const StyledWideContainer = styled(WideContainer)`
  text-align: center;
  flex-direction: column;
  justify-content: center;
`

const StyledFlex = styled(Flex)`
  border: 2px solid ${props => props.theme.colors.red};
  position: relative;
`

const StyledWideContent = styled(WideContent)<IContainerProps>`
  margin: '0 auto';
  padding: ${props => (props.background ? '0' : '5rem 0 4rem')};
  background: ${props => (props.background ? 'black' : 'white')};

  p,
  h2 {
    color: ${props => (props.background ? 'white' : 'black')};
  }

  ${Mq.md} {
    max-width: 100%;
  }
`

const TableFlipEmoji = styled(AsciiEmoji)`
  font-size: 1.5rem;

  ${Mq.sm} {
    font-size: ${props => props.theme.fontSizes[2]};
  }
`

const ErrorLoading: React.FC<IColorProps> = ({ background }) => {
  return (
    <StyledWideContent background={background}>
      <StyledWideContainer>
        <StyledFlex flexDirection="column" py={8}>
          <TableFlipEmoji mb={7}>(ノಠ益ಠ)ノ彡┻━┻</TableFlipEmoji>

          <Box mb={5}>
            <h2>
              Ugh Oh! <br /> Something went wrong
            </h2>
          </Box>

          <Box mb={6} width={[1, 4 / 5, 3 / 4, 2 / 3, 1 / 2]} mx={'auto'}>
            <p>
              No need to flip your table. Try reloading the page or hit the button below to head
              back to the homepage.
            </p>
          </Box>

          <Link to={paths.homepage()}>
            <BaseButton>Return Home</BaseButton>
          </Link>
        </StyledFlex>
      </StyledWideContainer>
    </StyledWideContent>
  )
}

export default ErrorLoading
