import React from 'react'
import { paths } from '../../../utils/Routes'
import { currentUserIsLoggedIn } from '../../../utils/accountUtils'
import NavList from './NavList'

interface IMainMenuProps {
  flexDirection?: string
}

const MainMenu: React.FC<IMainMenuProps> = ({ flexDirection }) => {
  const LoggedInItems = [
    { path: paths.leagues() },
    { path: paths.tournaments() },
    { path: paths.rules('rl'), label: 'rules' },
    { path: paths.newsAndAnnouncements() },
  ]
  const LoggedOutItems = [
    { path: paths.homepage() },
  ]
  return (
    <NavList
      listItems={currentUserIsLoggedIn() ? LoggedInItems : LoggedOutItems}
      flexDirection={flexDirection || 'row'}
      mainMenu
    />
  )
}

export default MainMenu
