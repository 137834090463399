import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React from 'react'
import { XOctagon } from 'react-feather'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'

import { MatchDetailAttributes } from '../../types/fragments'
import { useApproveMatchRescheduleMutation } from '../../types/graphql'
import { adjustedFullWithShortMonthAndTime } from '../../utils/dateTimeFormats'
import { handleErrors } from '../../utils/formUtils'
import { errorsFullMessage, handleResult } from '../../utils/results'
import { BaseButton } from '../atoms/Buttons'
import Card from '../atoms/Card'
import DataFetcher from './DataFetcher'

gql`
  mutation approveMatchReschedule($matchId: ID!, $approve: Boolean!) {
    approveMatchReschedule(matchId: $matchId, approve: $approve) {
      success
      errors {
        field
        message
      }
      value {
        ...MatchDetailAttributes
      }
    }
  }
  ${MatchDetailAttributes}
`

interface IProps {
  matchId: string
  rescheduleDate: string
}

const RescheduleMatchRequest: React.FC<IProps> = ({ matchId, rescheduleDate }) => {
  const { colors } = useTheme()

  const [approveMatchReschdule, { loading }] = useApproveMatchRescheduleMutation({
    refetchQueries: ['matchDetailPage'],
    awaitRefetchQueries: true,
    onError: error => {
      toast.error(handleErrors(error), { containerId: 'temporary', autoClose: false })
    },
    onCompleted: data => {
      handleResult({
        result: data.approveMatchReschedule,
        onSuccess: () => {
          toast.success('Reschedule updated!', { containerId: 'temporary' })
        },
        onFailure: errors => {
          toast.error(errorsFullMessage(errors), { containerId: 'temporary', autoClose: false })
        },
      })
    },
  })

  const handleApproveClick = () => {
    approveMatchReschdule({ variables: { matchId, approve: true } })
  }

  const handleDeclineClick = () => {
    approveMatchReschdule({ variables: { matchId, approve: false } })
  }

  return (
    <DataFetcher loading={loading} height={'auto'}>
      <Text color={colors.red} mb={3}>
        <Flex>
          <Box mr={2}>
            <XOctagon />
          </Box>
          <h2>Reschedule Requested</h2>
        </Flex>
      </Text>

      <Card notch={true} p={5}>
        <Card borderColor="middlegray" p={5}>
          <Box
            sx={{
              display: 'grid',
              gridGap: [5, 5, 5, 5, 8],
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            }}
          >
            <p>
              Your opponents coordinator has requested to reschedule this match. Please confirm or
              deny the request for the time provided:
            </p>

            <Box
              sx={{
                display: 'grid',
                gridGap: 5,
                gridTemplateColumns: ['1fr', '1fr 1fr', '1fr', '1fr 1fr'],
              }}
            >
              <Card borderColor="sand" p={3}>
                <Text color={colors.darkgray} mb={2} textAlign="center">
                  <h6>New Proposed Time</h6>
                </Text>
                <Text textAlign="center">
                  <h4>{adjustedFullWithShortMonthAndTime(rescheduleDate)}</h4>
                </Text>
              </Card>

              <Box>
                <BaseButton width="100%" mb={3} onClick={handleApproveClick} disabled={loading}>
                  Accept request
                </BaseButton>
                <BaseButton
                  width="100%"
                  variant="decline"
                  onClick={handleDeclineClick}
                  disabled={loading}
                >
                  Decline request
                </BaseButton>
              </Box>
            </Box>
          </Box>
        </Card>
      </Card>
    </DataFetcher>
  )
}

export default RescheduleMatchRequest
