import gql from 'graphql-tag'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { TeamContext } from '../../contexts/TeamContext'
import {
  TeamDetailQuery,
  TeamPendingInvitationsQuery,
  TeamRosterEntriesQuery,
  TeamSeasonInfoQuery,
  TeamSettledMatchesQuery,
  UpcomingMatchesQuery,
  UnreportedMatchesQuery,
  TournamentsToCheckinToQuery,
} from '../../types/fragments'
import { Team, useTeamDetailPageQuery } from '../../types/graphql'
import DataFetcher from './DataFetcher'

gql`
  query teamDetailPage($id: ID!) {
    team(id: $id) {
      ...TeamDetailQuery
      ...TeamPendingInvitationsQuery
      ...TeamRosterEntriesQuery
      ...TeamSeasonInfoQuery
      ...TeamSettledMatchesQuery
      ...UpcomingMatchesQuery
      ...UnreportedMatchesQuery
      ...TournamentsToCheckinToQuery
    }
  }
  ${TeamDetailQuery}
  ${TeamPendingInvitationsQuery}
  ${TeamRosterEntriesQuery}
  ${TeamSeasonInfoQuery}
  ${TeamSettledMatchesQuery}
  ${UpcomingMatchesQuery}
  ${UnreportedMatchesQuery}
  ${TournamentsToCheckinToQuery}
`

interface IProps {
  teamId: string
  isCoordinatorRoute?: boolean
  children?: any
}

const TeamDetailQueryProvider: React.FC<IProps> = ({ teamId, children, isCoordinatorRoute }) => {
  const { updateFromQuery, teamContextLoading } = useContext(TeamContext)
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const { error, loading } = useTeamDetailPageQuery({
    variables: { id: teamId },
    onCompleted: data => {
      if (data.team) {
        setFirstLoadComplete(true)

        if (isCoordinatorRoute && !data.team.currentUserIsCoordinator) {
          setRedirect(true)
        } else {
          updateFromQuery(data.team as Team)
        }
      } else {
        setRedirect(true)
      }
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamContextLoading])

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <DataFetcher loading={loading && !firstLoadComplete} error={error}>
      {children}
    </DataFetcher>
  )
}

export default TeamDetailQueryProvider
