import React from 'react'
import { Box, Flex, Text } from 'rebass'

import { GoldLeagueLogo, OpenLeagueLogo, StarLeagueLogo } from '../../assets/icons/league-icons'
import { Theme } from '../../styles/settings'

interface LeagueProps {
  league: string
  color: string
}

export const LEAGUE_KEYS = {
  OPEN: 'open league',
  STAR: 'star league',
  GOLD: 'gold league'
}

const League: React.FC<LeagueProps> = ({ league, color }) => {
  const leagueLogos = {
    [LEAGUE_KEYS.OPEN]: (
      <OpenLeagueLogo colorOne={Theme.colors.primarylightblue} width={'1.5rem'} height={'1.5rem'} />
    ),
    [LEAGUE_KEYS.STAR]: (
      <StarLeagueLogo
        colorOne={Theme.colors.primarylightblue}
        colorTwo={Theme.colors.primarynavy}
        width={'1.5rem'}
        height={'1.5rem'}
      />
    ),
    [LEAGUE_KEYS.GOLD]: (
      <GoldLeagueLogo
        colorOne={Theme.colors.primarylightblue}
        colorTwo={Theme.colors.sand}
        width={'1.5rem'}
        height={'1.5rem'}
      />
    ),
  }

  return (
    <Flex alignItems="center">
      {Object.keys(leagueLogos).includes(league.toLocaleLowerCase()) ? (
        <Box mr=".5rem" flex="0 0 auto">
          {leagueLogos[league.toLocaleLowerCase()]}
        </Box>
      ) : null}

      <Text color={color}>
        <h6>{league}</h6>
      </Text>
    </Flex>
  )
}

export default League
