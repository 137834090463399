import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import {
  useNewsOrAnnouncementLazyQuery as useNewsOrAnnouncement,
  ManageNewsAndAnnouncements_NewsOrAnnouncementFragment as NewsOrAnnouncement,
} from '../../types/graphql'
import { handleResult } from '../../utils/results'
import NoResultsError from '../organisms/NoResultsError'
import NewsAndAnnouncementsInfo from './NewsAndAnnouncements/NewsAndAnnouncementsInfo'

const NEWS_OR_ANNOUNCEMENT = gql`
  fragment ManageNewsAndAnnouncements_NewsOrAnnouncement on NewsArticle {
    id
    type
    newsCategory {
      id
      name
    }
    title
    body
    summary
    sport
    featuredImage
    featured
    position
    published
  }
`

gql`
  query NewsOrAnnouncement($id: ID!) {
    newsOrAnnouncement(id: $id) {
      success
      value {
        ...ManageNewsAndAnnouncements_NewsOrAnnouncement
      }
      errors {
        field
        message
      }
    }
  }
  ${NEWS_OR_ANNOUNCEMENT}
`

interface IRouteParams {
  id: string
}

const ManageNewsAndAnnouncements: React.FC = () => {
  const params = useParams<IRouteParams>()
  const id = params.id

  const [newsOrAnnouncement, setNewsOrAnnouncement] = useState<NewsOrAnnouncement>()
  const [renderNotFound, setRenderNotFound] = useState(false)

  const handleErrors = (errors: _.Dictionary<string> | ApolloError) => {
    console.error(errors)
    setRenderNotFound(true)
  }

  const [newsOrAnnouncementQuery, { loading }] = useNewsOrAnnouncement({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      handleResult({
        result: data!.newsOrAnnouncement,
        onSuccess: setNewsOrAnnouncement,
        onFailure: handleErrors,
      })
    },
    onError: handleErrors,
  })

  useEffect(() => {
    newsOrAnnouncementQuery({
      variables: { id },
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (renderNotFound) {
    return <NoResultsError keyword={'News or Announcement'} />
  }

  return (
    <NewsAndAnnouncementsInfo id={id} loading={loading} newsOrAnnouncement={newsOrAnnouncement} />
  )
}

export default ManageNewsAndAnnouncements
