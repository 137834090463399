import React, { useState } from 'react'
import { Flex, FlexProps } from 'rebass'
import { ChevronDown } from 'react-feather'
import { AnimatePresence } from 'framer-motion'
import { styled } from '../../styles/settings/theme'

import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import SchedulesMenu from '../molecules/SchedulesMenu'

interface BaseButtonProps {
  className?: string
}

interface IWeeklyScheduleToggleProps extends BaseButtonProps {
  closeMenu?: () => void
  numberOfWeeks: Number
  currentIndex: Number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
}

const WeeklyScheduleToggleWrapper = styled(Flex)<FlexProps>`
  z-index: 2;
  position: relative;
`

const StyledBaseButton = styled(BaseButton)<BaseButtonProps>`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${props => props.theme.colors.primarynavy};

  svg {
    margin-left: 1rem;
    margin-top: -0.3rem;
  }

  &.clicked {
    border-color: ${props => props.theme.colors.sand};
  }
`

const WeeklyScheduleToggle: React.FC<IWeeklyScheduleToggleProps> = ({
  numberOfWeeks,
  currentIndex,
  setCurrentIndex,
}) => {
  const [menu, showMenu] = useState(false)
  const animations = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }

  // TODO: Replace with real schedule and allow scoping of page
  // const schedule = [
  //   { week: 1, title: 'Week 1 Schedule' },
  //   { week: 2, title: 'Week 2 Schedule' },
  //   { week: 3, title: 'Week 3 Schedule' },
  //   { week: 4, title: 'Week 4 Schedule' },
  //   { week: 5, title: 'Week 5 Schedule' },
  //   { week: 6, title: 'Week 6 Schedule' },
  //   { week: 7, title: 'Week 7 Schedule' },
  //   { week: 8, title: 'Week 8 Schedule' },
  //   { week: 9, title: 'Week 9 Schedule' },
  // ]

  return (
    <Animation
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.2 }}
    >
      <WeeklyScheduleToggleWrapper>
        <StyledBaseButton
          variant="secondary"
          onClick={() => showMenu(!menu)}
          className={menu ? 'clicked' : undefined}
        >
          {/* TODO: Replace with selection of week once selected */}
          Weekly Schedules
          <ChevronDown />
        </StyledBaseButton>

        <AnimatePresence>
          {menu && (
            <SchedulesMenu
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              numberOfWeeks={numberOfWeeks}
            />
          )}
        </AnimatePresence>
      </WeeklyScheduleToggleWrapper>
    </Animation>
  )
}

export default WeeklyScheduleToggle
