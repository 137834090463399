import React from 'react'
import { Box } from 'rebass'
import ContentContainer from '../atoms/ContentContainer'
import PlayerProfileHero from '../organisms/PlayerProfileHero'
import CompleteProfileCTA from '../atoms/CompleteProfileCTA'

interface IncompleteProfileProps {
  username: string
}

const IncompleteProfile: React.FunctionComponent<IncompleteProfileProps> = ({ username }) => {
  return (
    <Box>
      <PlayerProfileHero username={username} />
      <ContentContainer flexDirection="column" py={[6, 6, 6, 8]} px={[3, 3, 3, 3, 3, 0]}>
        <CompleteProfileCTA />
      </ContentContainer>
    </Box>
  )
}
export default IncompleteProfile
