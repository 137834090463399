import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Text, TextProps, Flex, FlexProps } from 'rebass'
import { css } from '@emotion/core'
import { styled } from '../../styles/settings/theme'
import useHover from '@react-hook/hover'

import {
  useTeamIdFromSeat,
  useTeamNameFromSeat,
  useTeamWinsFromSeat,
  useMatchUrlFromSeat,
  useTeamWinnerFromSeat,
  useTeamLoserFromSeat,
  useTeamSeedFromSeat,
  useGrandFinalsTBDMatchTeam,
} from '../../utils/bracketDataUtils'
import { Slot } from '../atoms/BracketPieces'
import { HoveredTeamContext } from '../pages/TournamentDetail'

interface SlotTeamProps {
  winner?: boolean
  loser?: boolean
  currentUserTeam?: boolean
  center?: boolean
}

interface BracketTextProps {
  seat: number
  homeTeam?: boolean
  center?: boolean
  finals?: boolean
  alignRight?: boolean
  precedingSeat?: number
}

const SlotTeam = styled(Flex)<SlotTeamProps & FlexProps>`
  opacity: ${props => (props.loser ? '0.5' : '1')};
`

const SlotText = styled(Text)<SlotTeamProps & TextProps>`
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 0.75rem;
  color: ${props => (props.winner ? props.theme.colors.green : props.theme.colors.darkgray)};
  opacity: ${props => (props.loser ? '0.5' : '1')};
  text-transform: uppercase;
  cursor: pointer;
  ${props =>
    props.center &&
    css`
      font-size: 1rem;
    `}
`

export const BracketText: React.FunctionComponent<BracketTextProps> = ({
  seat,
  homeTeam,
  center,
  finals,
  alignRight,
  precedingSeat,
}) => {
  const [isHovering, ref] = useHover(0 /*enterDelay*/, 0 /*leaveDelay*/)
  const grandFinalsTBDMatchName = useGrandFinalsTBDMatchTeam(precedingSeat)

  let teamName = useTeamNameFromSeat(seat, !!homeTeam)

  const isPendingGrandFinal = finals && teamName === 'tbd'

  if (isPendingGrandFinal) {
    teamName = grandFinalsTBDMatchName
  }

  const teamSeed = useTeamSeedFromSeat(seat, !!homeTeam)
  const teamWins = useTeamWinsFromSeat(seat, !!homeTeam)

  const teamId = useTeamIdFromSeat(seat, !!homeTeam)

  const matchUrl = useMatchUrlFromSeat(seat)
  const currentUserTeamIds = sessionStorage.getItem('csl_team_ids')
  const currentUserTeam = currentUserTeamIds
    ? JSON.parse(currentUserTeamIds)
        .map((id: string) => parseInt(id))
        .includes(teamId)
    : false
  const loser = useTeamLoserFromSeat(seat, !!homeTeam)
  const winner = useTeamWinnerFromSeat(seat, !!homeTeam)
  const { hoveredTeam, setHoveredTeam } = useContext(HoveredTeamContext)

  let history = useHistory()
  if (teamId && isHovering) {
    setHoveredTeam(teamId.toString())
  }
  const highlight = teamId ? hoveredTeam === teamId.toString() : false
  return (
    <Slot
      ref={ref}
      myTeam={currentUserTeam}
      highlight={highlight}
      onClick={matchUrl ? () => history.push(matchUrl) : () => null}
      center={center}
      finals={finals}
      alignRight={alignRight}
      matchUrl={matchUrl}
    >
      {finals && (
        <SlotText winner={winner} loser={loser} center={center}>
          <h3>{isPendingGrandFinal ? 0 : teamWins}</h3>
        </SlotText>
      )}
      <Flex width="100%" justifyContent={alignRight ? 'flex-end' : 'space-between'}>
        <SlotTeam loser={loser} currentUserTeam={currentUserTeam}>
          {finals ? (
            <Flex flexDirection="column">
              <SlotText winner={winner} loser={loser} center={center} />
              <Flex>
                <SlotText mr={teamSeed ? 2 : 0} center={center}>
                  {teamSeed}
                </SlotText>
                <SlotText center={center}>{teamName}</SlotText>
              </Flex>
            </Flex>
          ) : (
            <>
              <SlotText mr={teamSeed ? 2 : 0} center={center}>
                {teamSeed}
              </SlotText>
              <SlotText center={center}>{teamName}</SlotText>
            </>
          )}
        </SlotTeam>
        {!finals && (
          <SlotText winner={winner} loser={loser} center={center}>
            {center ? <h3>{teamWins}</h3> : teamWins}
          </SlotText>
        )}
      </Flex>
    </Slot>
  )
}

export default BracketText
