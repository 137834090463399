import React from 'react'
import { layout, LayoutProps } from 'styled-system'
import { css } from '@emotion/core'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import SportLogo from '../atoms/SportLogo'

interface IProps extends IButtonProps {
  sportSlug: string
  onClick?: () => void
  className?: string
  customPadding?: string
}

interface IButtonProps extends LayoutProps {
  isSelected: boolean
  notPlayed?: boolean
  customPadding?: string
}

const Container = styled.button<IButtonProps>`
  ${layout}
  background-color: ${props => props.theme.colors.white};
  border: 2px solid
    ${props => (props.isSelected ? props.theme.colors.sand : props.theme.colors.middlegray)};
  outline: none;
  ${props =>
    props.notPlayed &&
    css`
      border: none;
      filter: opacity(40%) grayscale(100%);
    `}
    border-radius: 0;
    padding: 1rem;
    cursor: ${props => (props.notPlayed ? 'default' : 'pointer')};
    transition: ${props => props.theme.transitions.fast};
    ${Mq.md} {
      padding: 8px;
    }

    &:hover {
      border: 2px solid
      ${props => props.theme.colors.orange};
    }

    ${Mq.md} {
      padding: ${props => (props.customPadding ? props.customPadding : '1rem')};
    }
  `

const SportLogoSelector = ({
  isSelected,
  sportSlug,
  onClick,
  className,
  size,
  notPlayed,
  customPadding,
}: IProps) => (
  <Container
    isSelected={isSelected}
    disabled={notPlayed}
    notPlayed={notPlayed}
    onClick={onClick}
    className={className}
    size={size}
    customPadding={customPadding}
  >
    <SportLogo sport={sportSlug} />
  </Container>
)

export default SportLogoSelector
