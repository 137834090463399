import React from 'react'
import { Flex } from 'rebass'
import { Round, Line } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getQuadrant } from '../../utils/bracketDataUtils'

interface RoundProps {
  flip?: boolean
  shift?: string
  bottomHalf: boolean
  numTeams: number
  seatList: Array<number>
  isDoubleElim?: boolean
}

const RoundOf8: React.FunctionComponent<RoundProps> = ({
  seatList,
  flip,
  shift,
  bottomHalf,
  numTeams,
  isDoubleElim,
}) => {
  const topLeft = getQuadrant(seatList, 1)[0]
  const topRight = getQuadrant(seatList, 2)[0]
  const bottomLeft = getQuadrant(seatList, 3)[0]
  const bottomRight = getQuadrant(seatList, 4)[0]

  const seats =
    flip && bottomHalf ? bottomRight : bottomHalf ? bottomLeft : flip ? topRight : topLeft

  const top = (size: number) => {
    const topPxAmount: { [key: number]: string } = {
      128: bottomHalf ? '660px' : '620px',
      64: bottomHalf ? '549px' : '508px',
      32: bottomHalf ? '308px' : '345px',
      16: isDoubleElim && bottomHalf ? '236px' : bottomHalf ? '140px' : '259px',
      8: isDoubleElim && bottomHalf ? '137px' : bottomHalf ? '33px' : '185px',
    }
    return topPxAmount[size]
  }

  return (
    <Round round="8" top={top(numTeams)} shift={shift} bottomHalf={bottomHalf} flip={flip}>
      <Flex>
        {flip && (
          <Line
            numTeams={numTeams}
            round="8"
            flip={flip}
            bottomHalf={bottomHalf}
            isDoubleElim={isDoubleElim}
          />
        )}
        <BracketCouplet
          seats={seats}
          round="8"
          //bottom={'509px'}
          flip={flip}
          bottomHalf={bottomHalf}
          isDoubleElim={isDoubleElim}
        />
        {!flip && (
          <Line
            numTeams={numTeams}
            round="8"
            flip={flip}
            bottomHalf={bottomHalf}
            isDoubleElim={isDoubleElim}
          />
        )}
      </Flex>
    </Round>
  )
}

export default RoundOf8
