import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { Box, BoxProps, Flex, Link, Text, TextProps } from 'rebass'
import { css } from '@emotion/core'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { categoryName } from '../../utils/sportUtils'
import { generateStatLink } from '../../utils/statUtils'
import { LolSummonerLogos } from '../../utils/logoUtils'
import { RosterEntry } from '../../types/graphql'
import { CapcomLink, DotaBuffLink, EseaLink, SteamLinkAlt } from '../atoms/SiteLinks'
import { LOLSprite } from '../atoms/LOLSprite'
import { MtgaSprite } from '../atoms/MtgaSprite'
import { NbaSprite } from '../atoms/NbaSprite'
import { NhlSprite } from '../atoms/NhlSprite'
import { SfvSprite } from '../atoms/SfvSprite'

const StatBox = styled(Box)<BoxProps>`
  border-radius: 0.2rem;
  overflow: hidden;
`
interface StatListProps {
  sportGroup: boolean
  sport: string
}

const StatList = styled.ul<StatListProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    border-right: 2px solid ${(props) => props.theme.colors.lightgray};
    min-width: 50%;
    margin-top: 1rem;
    ${(props) =>
      props.sportGroup &&
      css`
        margin-bottom: 1rem;
      `}
  }
  li:nth-of-type(even) {
    border-right: none;
  }

  ${Mq.xs} {
    li {
      min-width: 33.3%;
      ${(props) =>
        props.sportGroup &&
        css`
          min-width: 50%;
        `}

      ${(props) =>
        props.sport !== 'lol' &&
        css`
          margin-bottom: 1rem;
        `}
    }
    li:nth-of-type(even) {
      border-right: 2px solid ${(props) => props.theme.colors.lightgray};
      ${(props) =>
        props.sportGroup &&
        css`
          border-right: none;
        `}
    }
    li:nth-of-type(3) {
      border: none;
    }
    li:nth-of-type(6) {
      border: none;
    }
  }

  ${Mq.md} {
    flex-wrap: nowrap;
    li {
      min-width: 0;
      flex: 1;

      margin-bottom: 1rem;
    }
    li:nth-of-type(3) {
      border-right: 2px solid ${(props) => props.theme.colors.lightgray};
    }
    li:nth-of-type(6) {
      border-right: 2px solid ${(props) => props.theme.colors.lightgray};
    }
    li:last-child {
      border: none;
    }
  }
`

interface BottomProps {
  stat?: string
  sport?: string
  sportGroup?: boolean
}
const BottomBox = styled(Box)<BottomProps & BoxProps>`
${(props) =>
  props.sportGroup &&
  css`
    display: none;
  `}

  ${Mq.md} {
    display: none;
  }
  ${(props) =>
    props.sport !== 'lol' &&
    css`
      ${Mq.xs} {
        display: none;
      }
    `}

  ${(props) =>
    props.sport === 'dota2' &&
    css`
      ${Mq.xs} {
        display: block;
      }
      ${Mq.md} {
        display: none;
      }
    `}

}
`
const BottomLine = styled(Box)<BottomProps & BoxProps>`
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgray};
  width: 75%;

  ${(props) =>
    props.stat === 'csl record' &&
    props.sport === 'lol' &&
    css`
      display: none;
    `}

  ${(props) =>
    props.stat === 'dotaBuff' &&
    css`
      display: none;
      ${Mq.xs} {
        display: block;
      }
    `}
`

interface StatTextProps {
  sport: string
}

const StatText = styled(Text)<TextProps>`
  font-family: ${(props) => props.theme.fonts.condensed};
  font-size: ${(props) => props.theme.fontSizes[1]};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-top: 1rem;
  ${Mq.sm} {
    font-size: ${(props) => props.theme.fontSizes[2]};
  }
`

interface PlayerStatsProps {
  entries: [RosterEntry]
  statInfo: { [key: string]: string | null }
  sport: string
}

const PlayerStats = ({ entries, statInfo, sport }: PlayerStatsProps) => {
  const wins =
    entries.length &&
    entries
      .map(
        (entry: any) => entry.player.selectedSeasonStats && entry.player.selectedSeasonStats.wins,
      )
      .reduce((a: number, b: number) => a + b)

  const losses =
    entries.length &&
    entries
      .map(
        (entry: any) => entry.player.selectedSeasonStats && entry.player.selectedSeasonStats.losses,
      )
      .reduce((a: number, b: number) => a + b)

  const record = !wins && !losses ? null : `${wins} - ${losses}`

  const generalStats = (sport: string) => {
    return sport === 'csgo'
      ? {
          'csl record': record,
          esea: statInfo[sport + 'Esea'] || '',
          steam: statInfo[sport + 'SteamId'] || '',
        }
      : sport === 'dota2'
      ? {
          [`preferred ${categoryName[sport]}`]: statInfo[sport] || '',
          'csl record': record,
          dotaBuff: statInfo[sport + 'SteamId'] || '',
          steam: statInfo[sport + 'SteamId'] || '',
        }
      : sport === 'rl'
      ? { 'csl record': record, steam: statInfo[sport + 'SteamId'] || '' }
      : sport === 'sfv'
      ? {
          [`preferred ${categoryName[sport]}`]: statInfo[sport] || '',
          'csl record': record,
          capcom: statInfo[sport + 'Fid'] || '',
        }
      : sport === 'smash'
      ? { 'csl record': record }
      : sport === 'fortnite'
      ? {
          'csl record': record,
          'fortnite epic username': statInfo['fortniteEpicUserName'] || '',
        }
      : {
          [`preferred ${categoryName[sport]}`]: statInfo[sport] || '',
          'csl record': record,
        }
  }

  const stats: { [key: string]: any } = generalStats(sport)

  const LOLStats: any = {
    ...statInfo,
    'csl record': record,
  }

  //flag to handle responsive styling for sports that only have one or two tiles
  const sportCategory =
    sport !== 'lol' && sport !== 'dota2' && sport !== 'sfv' && sport !== 'csgo' ? true : false
  return (
    <Box width={!sportCategory ? 1 : [1, 1, 1 / 2]} mb={[4, 4, 4, 0]}>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Text>
          <h2>{entries.length ? entries[0].inGameName : null}</h2>
        </Text>
        {sport === 'lol' && (
          <Link href={generateStatLink('lol', LOLStats['name'])}>
            <Flex alignItems="center">
              <Text mt={'5px'} mr={2}>
                <h6>view op.gg stats</h6>
              </Text>
              <FiExternalLink size={20} color="primaryblue" />
            </Flex>
          </Link>
        )}
      </Flex>
      <StatBox bg="white" py={2} mt={2}>
        <StatList sportGroup={sportCategory} sport={sport}>
          {sport === 'lol'
            ? Object.keys(LOLStats).map((stat: string, i) => (
                <>
                  {stat !== 'name' ? (
                    <li key={i}>
                      {stat === 'tier' ? (
                        <Flex flexDirection="column" alignItems="center">
                          <Box size={72} mt={[0, 0, '10px', 3]} mb={[1, 1, 1, 4]}>
                            <img
                              alt="League of Legends Summoner Logos"
                              src={LolSummonerLogos(LOLStats[stat])}
                            />
                          </Box>
                          <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                            <h6>{stat}</h6>
                          </Text>
                        </Flex>
                      ) : stat === 'champion' && LOLStats[stat] ? (
                        <Flex flexDirection="column" alignItems="center">
                          <Box size={72} mt={[0, 0, '10px', 3]} mb={[1, 1, 1, 4]}>
                            <LOLSprite championImageName={LOLStats['champion']['imageName']} />
                          </Box>
                          <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                            <h6>{LOLStats['champion']['name']}</h6>
                          </Text>
                        </Flex>
                      ) : (
                        <>
                          {LOLStats[stat] === '0.00' || !LOLStats[stat] ? (
                            <StatText color="primarynavy" pt={[3, 3, 3, 5]} pb={[5, 5, 5, 5]}>
                              &mdash;
                            </StatText>
                          ) : (
                            <StatText color="primarynavy" pt={[3, 3, 3, 5]} pb={[5, 5, 5, 5]}>
                              {LOLStats[stat]}
                            </StatText>
                          )}

                          <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                            <h6>{stat}</h6>
                          </Text>
                        </>
                      )}

                      <BottomBox bg="white" height={'1.25rem'} width={'102%'} sport={sport}>
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </li>
                  ) : null}
                </>
              ))
            : // for all other sports
              Object.keys(stats).map((stat: any, i) => (
                <li key={stat}>
                  {stat === 'steam' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <SteamLinkAlt steamId={stats[stat]}>
                        <Text
                          color={stats[stat] ? 'primaryblue' : 'darkmiddlegray'}
                          textAlign="center"
                          pb={[4, 1, 1, 3]}
                        >
                          <h6>{stat}</h6>
                        </Text>
                      </SteamLinkAlt>
                    </Flex>
                  ) : stat === 'dotaBuff' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <DotaBuffLink dotaBuff={stats[stat]}>
                        <Text
                          color={stats[stat] ? 'primaryblue' : 'darkmiddlegray'}
                          textAlign="center"
                          pb={[1, 1, 1, 3]}
                        >
                          <h6>{stat}</h6>
                        </Text>
                      </DotaBuffLink>

                      <BottomBox bg="white" height={'1.25rem'} width={'102%'} sport={sport}>
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </Flex>
                  ) : stat === 'capcom' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <CapcomLink capcom={stats[stat]}>
                        <Text
                          color={stats[stat] ? 'primaryblue' : 'darkmiddlegray'}
                          textAlign="center"
                          pb={[4, 1, 1, 3]}
                        >
                          <h6>{stat}</h6>
                        </Text>
                      </CapcomLink>
                    </Flex>
                  ) : stat === 'esea' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <EseaLink esea={stats[stat]}>
                        <Text
                          color={stats[stat] ? 'primaryblue' : 'darkmiddlegray'}
                          textAlign="center"
                          pb={[1, 1, 1, 3]}
                        >
                          <h6>{stat}</h6>
                        </Text>
                      </EseaLink>

                      <BottomBox bg="white" height={'1.25rem'} width={'102%'} sport={sport}>
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </Flex>
                  ) : stat === 'preferred color' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <MtgaSprite
                        className={stats[stat].toLowerCase()}
                        zoom=".75"
                        mt={[2, 2, 4, 6]}
                        mb={['26px', '26px', 6, 6]}
                      />
                      <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                        <h6>{stat}</h6>
                      </Text>
                      <BottomBox
                        bg="white"
                        height={'1.25rem'}
                        width={'102%'}
                        sport={sport}
                        sportGroup={sportCategory}
                      >
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </Flex>
                  ) : stat === 'preferred fighter' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <Box size={72} mt={[0, 0, '10px', 3]} mb={[1, 1, 1, 4]} mr={'-18px'}>
                        <SfvSprite className={stats[stat].toLowerCase()} />
                      </Box>
                      <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                        <h6>{stats[stat]}</h6>
                      </Text>
                      <BottomBox
                        bg="white"
                        height={'1.25rem'}
                        width={'103%'}
                        sport={sport}
                        sportGroup={sportCategory}
                      >
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </Flex>
                  ) : stat === 'preferred hero' && sport === 'dota2' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <Flex
                        size={72}
                        mt={[0, 0, '10px', 3]}
                        mb={[1, 1, 1, 4]}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <i className={`d2mh ${stats[stat]}`}></i>
                      </Flex>
                      <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                        <h6>{stat}</h6>
                      </Text>
                      <BottomBox
                        bg="white"
                        height={'1.25rem'}
                        width={'103%'}
                        sport={sport}
                        sportGroup={sportCategory}
                      >
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </Flex>
                  ) : stat === 'preferred team' && sport === 'nhl' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <NhlSprite
                        className={stats[stat].replace('.', '').split(' ').join('-').toLowerCase()}
                        zoom=".75"
                        mt={[2, 2, 4, 6]}
                        mb={['26px', '26px', 6, 6]}
                      />
                      <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                        <h6>{stat}</h6>
                      </Text>
                      <BottomBox
                        bg="white"
                        height={'1.25rem'}
                        width={'102%'}
                        sport={sport}
                        sportGroup={sportCategory}
                      >
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </Flex>
                  ) : stat === 'preferred team' && sport === 'nba' ? (
                    <Flex flexDirection="column" alignItems="center">
                      <NbaSprite
                        className={stats[stat].split(' ').join('-').toLowerCase()}
                        zoom="1"
                        mt={[2, 2, 4, 6]}
                        mb={['26px', '26px', 6, 6]}
                      />
                      <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                        <h6>{stat}</h6>
                      </Text>
                      <BottomBox
                        bg="white"
                        height={'1.25rem'}
                        width={'102%'}
                        sport={sport}
                        sportGroup={sportCategory}
                      >
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </Flex>
                  ) : stats[stat] ? (
                    <>
                      <StatText color="primarynavy" pt={[3, 3, 3, 5]} pb={[5, 5, 5, 5]}>
                        {stats[stat]}
                      </StatText>
                      <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                        <h6>{stat}</h6>
                      </Text>
                      <BottomBox
                        bg="white"
                        height={'1.25rem'}
                        width={'102%'}
                        sport={sport}
                        sportGroup={sportCategory}
                      >
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </>
                  ) : (
                    <>
                      <StatText color="primarynavy" pt={[3, 3, 3, 5]} pb={[5, 5, 5, 5]}>
                        &mdash;
                      </StatText>
                      <Text color="darkmiddlegray" textAlign="center" pb={[1, 1, 1, 3]}>
                        <h6>{stat}</h6>
                      </Text>
                      <BottomBox
                        bg="white"
                        height={'1.25rem'}
                        width={'102%'}
                        sport={sport}
                        sportGroup={sportCategory}
                      >
                        <BottomLine mx={'auto'} height={'1.25rem'} stat={stat} sport={sport} />
                      </BottomBox>
                    </>
                  )}
                </li>
              ))}
        </StatList>
      </StatBox>
    </Box>
  )
}

export default PlayerStats
