import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Box, Text, Flex } from 'rebass'
import { toast } from 'react-toastify'

import { RosterEntry, useSetNewCoordinatorMutation } from '../../../types/graphql'
import { currentUserIsAdmin } from '../../../utils/admins'
import { paths } from '../../../utils/Routes'
import SectionTitle from '../../atoms/SectionTitle'
import { CustomSelect } from '../../atoms/Select'
import { BaseButton } from '../../atoms/Buttons'
import LoadingSpinner from '../../atoms/LoadingSpinner'
import { handleResult, errorsFullMessage } from '../../../utils/results'

gql`
  mutation setNewCoordinator($id: ID!, $coordinatorId: ID!) {
    upsertTeam(id: $id, coordinatorId: $coordinatorId) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface IChangeCoordinatorProps {
  activeRosterEntries: RosterEntry[]
  teamId: string
  hideModal: () => void
}

const ChangeCoordinator: React.FC<IChangeCoordinatorProps> = ({
  activeRosterEntries,
  teamId,
  hideModal,
}) => {
  const [newCoordinatorId, setNewCoordinatorId] = useState('')
  const [loading, setLoading] = useState(false)

  const [changeCoordinator] = useSetNewCoordinatorMutation({
    refetchQueries: ['teamDetailPage'],
    onError: error => {
      setLoading(false)
      toast.error(error.message, { containerId: 'temporary' })
    },
    onCompleted: data => {
      handleResult({
        result: data.upsertTeam,
        onSuccess: () => {
          if (currentUserIsAdmin()) {
            setLoading(false)
            hideModal()
            toast.success('Coordinator udpated.', { containerId: 'temporary' })
          } else {
            window.location.href = paths.teamDetail(teamId)
          }
        },
        onFailure: errors => {
          setLoading(false)
          return toast.error(errorsFullMessage(errors), { containerId: 'temporary' })
        },
      })
    },
  })

  const handleSubmit = () => {
    setLoading(true)
    changeCoordinator({ variables: { id: teamId, coordinatorId: newCoordinatorId } })
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Flex flexDirection="column" px={[2, 4, 5, 8]} py={[5, 5, 6]}>
      <SectionTitle text="set new coordinator" mb="5" />
      <Text>
        <p>
          Select a player from the list below.{' '}
          {!currentUserIsAdmin() &&
            `Keep in mind, once submitted, you will no longer have
          access to the team editing tools.`}
        </p>
      </Text>
      <Box my={6}>
        <CustomSelect value={newCoordinatorId} onChange={e => setNewCoordinatorId(e.target.value)}>
          <option value={''} disabled>
            select a new coordinator
          </option>
          {activeRosterEntries.map((rosterEntry, i) => (
            <option key={i} value={rosterEntry.player.id}>
              {rosterEntry.player.username}
            </option>
          ))}
        </CustomSelect>
      </Box>
      <BaseButton
        onClick={handleSubmit}
        variant={newCoordinatorId ? 'primary' : 'primaryDisabled'}
        maxWidth="14rem"
        mx="auto"
        disabled={!newCoordinatorId}
      >
        set as coordinator
      </BaseButton>
    </Flex>
  )
}

export default ChangeCoordinator
