import gql from 'graphql-tag'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useAddTournamentMessageMutation } from '../../types/graphql'
import { handleResult } from '../../utils/results'
import { BaseButton } from '../atoms/Buttons'
import { CustomTabTextArea } from '../atoms/FormPieces'

gql`
  mutation AddTournamentMessage($id: ID!, $message: String!) {
    addTournamentMessage(id: $id, message: $message) {
      success
    }
  }
`

interface IProps {
  tournamentId: string
}

const TournamentAdminMessage: React.FC<IProps> = ({ tournamentId }) => {
  const [message, setMessage] = useState('')

  const [addTournamentMessage, { loading }] = useAddTournamentMessageMutation({
    variables: {
      id: tournamentId,
      message,
    },
    onError(error) {
      toast.error('Failed to send message.', { containerId: 'temporary' })
      console.error(error)
    },
    onCompleted: data => {
      handleResult({
        result: data.addTournamentMessage,
        onSuccess: () => {
          toast.success('Message sent!', { containerId: 'temporary' })
          setMessage('')
        },
        onFailure: () => {
          toast.error('Failed to send message.', { containerId: 'temporary' })
        },
      })
    },
  })

  const handleFormSubmitted = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    addTournamentMessage()
  }

  return (
    <form onSubmit={handleFormSubmitted}>
      <CustomTabTextArea
        placeholder={'Send a message to all members'}
        value={message}
        onChange={e => setMessage(e.target.value)}
      />

      <BaseButton
        width="100%"
        my={[3]}
        variant={message ? 'primary' : 'primaryDisabled'}
        disabled={!message || loading}
        type="submit"
      >
        {loading ? 'Sending...' : 'Send message to all members'}
      </BaseButton>
    </form>
  )
}

export default TournamentAdminMessage
