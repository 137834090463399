import React from 'react'
import { useTheme } from 'emotion-theming'

interface LeftArrowIconProps {
  color?: string
}

export const LeftArrowIcon: React.FC<LeftArrowIconProps> = ({ color }) => {
  const { colors } = useTheme()
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 6H1"
        stroke={color || colors.primarynavy}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11L1 6L6 1"
        stroke={color || colors.primarynavy}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
