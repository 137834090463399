export const getSeasonYears = () => {
  const moment = require('moment')
  const numYearsInList = 4
  let years: string[] = []
  for (let x = 0; x < numYearsInList; x++) {
    years.push(
      moment()
        .add(x, 'years')
        .format('YYYY') +
        '-' +
        moment()
          .add(x + 1, 'years')
          .format('YYYY'),
    )
  }
  return years
}
