import React from 'react'
import { Box, Flex, Text } from 'rebass'

import {
  NewsAndAnnouncements_NewsArticleFragment as NewsArticle,
  PostCategories,
  SportSlugs,
} from '../../../types/graphql'

import NewsCategorySelector from './NewsCategorySelector'
import NewsSummary from './NewsSummary'
import Animation from '../../atoms/Animation'
import Card from '../../atoms/Card'
import Paginator from '../../molecules/Paginator'
import DataFetcher from '../../organisms/DataFetcher'
import GamesFilter from '../../organisms/GamesFilter'

interface INewsSectionProps {
  loading: boolean
  allSports: SportSlugs[]
  allCategories: PostCategories[]
  selectedSports: string[]
  selectedCategory?: PostCategories
  onSportClicked: (sportSlug: string) => void
  onCategoryChanged: (category?: PostCategories) => void
  onPageChanged: (_event: React.ChangeEvent<unknown>, page: number) => void
  newsArticles: NewsArticle[]
  newsCurrentPage: number
  newsTotalPages: number
}

const NewsSection: React.FC<INewsSectionProps> = ({
  loading,
  newsArticles,
  newsCurrentPage,
  newsTotalPages,
  allSports,
  allCategories,
  selectedSports,
  selectedCategory,
  onSportClicked,
  onCategoryChanged,
  onPageChanged,
}) => {
  const shownSports = allSports

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <Box width={1 / 2} pt={4}>
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4 }}
        >
          <Text>
            <h2>CSL News</h2>
          </Text>
        </Animation>
      </Box>
      <Box mt={4}>
        <GamesFilter
          sportSlugs={shownSports}
          unplayedSports={[]}
          selectedSports={selectedSports}
          sportClickHandler={onSportClicked}
        />
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <NewsCategorySelector
            selectedCategory={selectedCategory}
            categories={allCategories}
            onCategoryChanged={onCategoryChanged}
          />
        </Animation>
      </Box>
      <DataFetcher loading={loading} height={'7rem'} my={4}>
        <Flex mb={6} flexDirection="column">
          <Box>
            <Card notch={true}>
              {/* TODO: what else is supposed to be in the sort dropdown? Is sort really necessary now?
              <Flex flexDirection="row" mx={6}>
              <Box width={1 / 2}>
                <Text textAlign="right">
                  <BaseButton>TODO</BaseButton>
                </Text>
              </Box>
              </Flex>
              */}
              <Flex flexDirection="column">
                {newsArticles.map(news => (
                  <NewsSummary key={news.id} {...news} />
                ))}
              </Flex>
              <Flex justifyContent="center" py={7}>
                {newsTotalPages && newsTotalPages > 0 ? (
                  <Box>
                    <Paginator
                      page={newsCurrentPage}
                      count={newsTotalPages}
                      onChange={onPageChanged}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Text>
                      <h3>No Results</h3>
                    </Text>
                  </Box>
                )}
              </Flex>
            </Card>
          </Box>
        </Flex>
      </DataFetcher>
    </>
  )
}

export default NewsSection
