import React from 'react'
// TODO: use standard in-app Link component if/when we make one
import { Link, LinkProps } from 'rebass'

import { currentUserIsAdmin } from '../../utils/admins'

interface AdminLinkProps extends LinkProps {
  css?: string
}

const AdminLink: React.FC<AdminLinkProps> = ({ children, ...rest }) => {
  return currentUserIsAdmin() ? <Link {...rest}>{children}</Link> : null
}

export default AdminLink
