import { Flex } from 'rebass'
import { Link } from 'react-router-dom'
import React from 'react'

import { BaseButton } from '../atoms/Buttons'
import { CustomInput } from '../atoms/FormPieces'
import { paths } from '../../utils/Routes'

interface IFilterProps {
  selectedState: string
  setSelectedState: (value: any) => void
}

const states = ['drafts']
const CreateTournamentBox = ({ selectedState, setSelectedState }: IFilterProps) => {
  return (
    <Flex bg="white" p={5} mb={6} flexWrap="wrap">
      <CustomInput
        flex={'1'}
        mr={4}
        placeholder={'enter tournament name or id'}
        minWidth={'10rem'}
      />
      <Flex mt={[3, 0]}>
        {states.map((state: string) => (
          <BaseButton
            onClick={() => setSelectedState(state)}
            key={state}
            variant={state === selectedState ? 'tertiaryOutlined' : 'tertiaryOutlinedDisabled'}
            mr={4}
          >
            {state}
          </BaseButton>
        ))}
      </Flex>
      <Link to={paths.createTournament()}>
        <BaseButton mt={[3, 3, 0]} variant="primary">
          New Tournament
        </BaseButton>
      </Link>
    </Flex>
  )
}

export default CreateTournamentBox
