import React, { useContext } from 'react'
import { oc } from 'ts-optchain'
import { Text } from 'rebass'
import { useTheme } from 'emotion-theming'
import { TeamContext } from '../../contexts/TeamContext'
import { roles } from '../../utils/sportUtils'

import SectionTitle from '../atoms/SectionTitle'
import PlayerApplicationRow from '../molecules/PlayerApplicationRow'
import Table from '../atoms/Table'

const PlayerApplications: React.FC = () => {
  const { colors } = useTheme()
  const { applicants, sportSlug, setTeamContextLoading } = useContext(TeamContext)
  const showRoles = !!roles(sportSlug)

  return applicants.length ? (
    <>
      <SectionTitle text="Player applications" mt="6" mb="5" />
      <Table headerBackgroundColor="primarynavy">
        <thead>
          <tr>
            <th style={{ width: '30%' }}>
              <Text color={colors.white}>
                <h6>Player</h6>
              </Text>
            </th>
            {showRoles && (
              <th style={{ width: '20%' }}>
                <Text color={colors.white}>
                  <h6>Role</h6>
                </Text>
              </th>
            )}
            <th style={{ width: '10%' }}>
              <Text color={colors.white}>
                <h6>Discord</h6>
              </Text>
            </th>
            <th style={{ width: showRoles ? '40%' : '60%' }}>
              <Text color={colors.white}>
                <h6>Approval</h6>
              </Text>
            </th>
          </tr>
        </thead>

        <tbody>
          {applicants.map(
            re =>
              re.player && (
                <PlayerApplicationRow
                  showRoles={showRoles}
                  username={re.player.username || oc(re.player).email('')}
                  role={oc(re).gameRole('')}
                  discordUsername={oc(re.player).discordUsername('')}
                  id={re.id}
                  key={re.id}
                  isPendingInvite={!!re.invitation}
                  setTeamContextLoading={setTeamContextLoading}
                />
              ),
          )}
        </tbody>
      </Table>
    </>
  ) : null
}

export default PlayerApplications
