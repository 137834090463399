import React, { useContext } from 'react'
import { Text } from 'rebass'
import { TournamentContext } from '../../contexts/TournamentContext'
import { Link } from 'react-router-dom'
import { paths } from '../../utils/Routes'

const AdminTournamentTitle: React.FunctionComponent = () => {
  const { name, tournamentId } = useContext(TournamentContext)

  return tournamentId === '' ? (
    <Text mb={8}>
      <h2>{`Manage ${name}`}</h2>
    </Text>
  ) : (
    <Text mb={8}>
      <h2>
        {`Manage`}
        <Link to={paths.tournament(tournamentId)}>{` ${name}`}</Link>
      </h2>
    </Text>
  )
}

export default AdminTournamentTitle
