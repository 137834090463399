import _ from 'lodash'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'
import { oc } from 'ts-optchain'

import DefaultArticleImage from '../../../assets/images/news__placeholder.jpg'
import { styled } from '../../../styles/settings/theme'
import { NewsArticleTypes } from '../../../types/graphql'
import { currentUserIsAdmin } from '../../../utils/admins'
import { fullWithLongMonth, justDateFrom } from '../../../utils/dateTimeFormats'
import { H4, H5 } from './Headers'
import { NewsCategory } from './types'

interface INewsOrAnnouncementSummaryProps {
  type: NewsArticleTypes
  title?: string
  summary?: string
  date: string
  image?: string
  published?: boolean | null
  newsCategory?: NewsCategory
  upperRightComponent?: ReactNode
  upperLeftComponent?: ReactNode
  button: ReactNode
  link: string
}

interface IBackgroundProps {
  background: string
}

const StyledFlex = styled(Flex)`
  align-items: center;
`

const FeaturedImage = styled(Box)<IBackgroundProps>`
  width: 100%;
  background-image: url('${props => props.background}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 320px;
  transition: ${props => props.theme.transitions.fast};

  &:hover {
    box-shadow: inset 0px -100px 50px -70px rgba(0, 0, 0, 0.71);
  }
`

const StyledTitle = styled(H4)`
  transition: ${props => props.theme.transitions.fast};

  &:hover {
    color: ${props => props.theme.colors.primaryblue};
  }
`

const NewsOrAnnouncementSummary: React.FC<INewsOrAnnouncementSummaryProps> = ({
  type,
  newsCategory,
  title,
  summary,
  date,
  image: origImage,
  published,
  upperRightComponent,
  upperLeftComponent,
  button,
  link,
}) => {
  const image = origImage || DefaultArticleImage
  const displayableDate = fullWithLongMonth(justDateFrom(date))
  const displayableCategory =
    type === NewsArticleTypes.Announcement ? 'Announcement' : oc(newsCategory).name()
  const publishedStatus = currentUserIsAdmin() && !published ? ' (NOT PUBLISHED)' : ''

  return (
    <StyledFlex flexDirection={['column', 'column', 'column', 'row']}>
      <Box width={[1, 1, 1 / 2]}>
        <Link to={link}>
          <FeaturedImage background={image} alt={title} />
        </Link>
      </Box>

      <Box width={[1, 1, 1 / 2]} p={6}>
        {(upperLeftComponent || upperRightComponent) && (
          <Flex flexDirection="row" mb={4}>
            <Text width={1 / 2} textAlign="left">
              {upperLeftComponent}
            </Text>
            <Text width={1 / 2} textAlign="right">
              {upperRightComponent}
            </Text>
          </Flex>
        )}
        <Text lineHeight={'1rem'}>
          <H5>{`${displayableDate} - ${displayableCategory}`}</H5>
        </Text>
        <Text lineHeight={'2.5rem'}>
          <Link to={link}>
            <StyledTitle>
              {_.truncate(title, {
                length: 48,
                omission: ' ...',
              })}
              {publishedStatus}
            </StyledTitle>
          </Link>
        </Text>
        <Flex flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={[1, 1, 1, 1]}>
            <Text textAlign={'left'} pr={3}>
              <p>
                {_.truncate(summary, {
                  length: 130,
                  omission: ' ...',
                })}
              </p>
            </Text>
          </Box>
          <Box width={[1, 1, 1, 1]} mt={[2, 2, 2, 4]}>
            <Text textAlign={['left', 'left', 'left', 'left']}>{button}</Text>
          </Box>
        </Flex>
      </Box>
    </StyledFlex>
  )
}

export default NewsOrAnnouncementSummary
