import React from 'react'
import { useTheme } from 'emotion-theming'
import { Text } from 'rebass'

interface IStatusTextProps {
  status: string
}

const StatusText: React.FC<IStatusTextProps> = ({ status }) => {
  const { colors } = useTheme()
  return (
    <Text textAlign="center" color={colors.darkgray}>
      <h5>{status}</h5>
    </Text>
  )
}

export default StatusText
