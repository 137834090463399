import React from 'react'
import { Box } from 'rebass'
import { Tooltip } from '@material-ui/core'

import { sportLogo } from '../../utils/logoUtils'
import { prettyName } from '../../utils/sportUtils'

interface IProps {
  sport: string | null | undefined
  width?: any
  height?: any
}

const SportLogo = ({ sport, ...rest }: IProps) => {
  return (
    <Box {...rest}>
      {sport && (
        <Tooltip title={prettyName(sport)} placement="top">
          <img src={sportLogo(sport)} alt={`${prettyName(sport)} Logo`} />
        </Tooltip>
      )}
    </Box>
  )
}

export default SportLogo
