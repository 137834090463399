import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

interface ITextEditor {
  handleText: (content: string) => void
  text: string
  height?: string | number
  width?: string | number
  placeholder?: string
}

const TextEditor: React.FC<ITextEditor> = ({ handleText, text, height, width, placeholder }) => {
  const handleEditorChange = (content: string, editor: any) => {
    handleText(content)
  }

  return (
    <Editor
      apiKey={`${process.env.REACT_APP_TINYMCE_API_KEY}`}
      init={{
        height: height || 300,
        width: width || '100%',
        placeholder: placeholder || 'Enter your S-Tier description here...',
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'print',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'paste',
          'code',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | formatselect | bold italic forecolor backcolor | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | removeformat | help',
      }}
      value={text}
      onEditorChange={handleEditorChange}
    />
  )
}

export default TextEditor
