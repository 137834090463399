import { styled, MediaQueries as Mq } from '../../styles/settings'

export const ChunkyAccordionListItem = styled.li`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 2px solid ${props => props.theme.colors.lightgray};
  flex-direction: column;

  ${Mq.md} {
    flex-direction: row;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &:nth-child(1) {
    border-top: 0px;
    padding-top: 0px;
  }
`

export const ChunkyAccordionListTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  ${Mq.md} {
    flex-direction: row;
    align-items: center;
    padding: 0;

    h5 {
      padding-left: 1rem;
      margin: 0;
    }
  }

  h5 {
    padding-right: 1rem;
    margin: 0.5rem 0;
    color: ${props => props.theme.colors.primarynavy};
    transition: ${props => props.theme.transitions.fast};
    &:hover {
      color: ${props => props.theme.colors.primaryblue};
    }
  }
`

export const ChunkyAccordionListActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  ${Mq.md} {
    flex-direction: row;
    align-items: center;
  }
`
