import React from 'react'
import Iframe from 'react-iframe'

const DiscordWidget: React.FunctionComponent = () => {
  return (
    <div>
      <Iframe
        url="https://discordapp.com/widget?id=225275727208120320&theme=light"
        width="100%"
        height="434px"
      ></Iframe>
    </div>
  )
}

export default DiscordWidget
