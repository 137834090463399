import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Dictionary } from 'lodash'
import { Text, Flex, Box, FlexProps } from 'rebass'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import styled from 'styled-components'

import {
  FieldType,
  allFieldsBySport,
  allFieldsMutationArguments,
  allFieldsMutationVariables,
} from '../../../utils/gamingProfilesUtils'

import {
  SettingsBlock,
  Separator,
  SettingsModal,
  SettingsHeader,
  SettingsContent,
} from '../../atoms/SettingsPieces'
import { AddIcon } from '../../../assets/icons/add-icon'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { handleResult } from '../../../utils/results'
import AddGamingProfiles from './AddGamingProfiles'
import GamingProfiles from '../../molecules/GamingProfiles/GamingProfiles'
import SportLogo from '../../atoms/SportLogo'

const UPDATE_USER_GAMING_PROFILES = gql(String.raw`
  mutation updateUserGamingProfiles(
    $id: ID
    ${allFieldsMutationArguments}
  ) {
    updateUser(
      id: $id
      ${allFieldsMutationVariables}
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`)

const AddMoreButton = styled(Flex)<FlexProps>`
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`

interface SportFieldsProps {
  playerId?: string
}

const SportFields: React.FC<SportFieldsProps> = ({ playerId }) => {
  const { colors } = useTheme()
  const {
    sportFields,
    existingSports,
    showGamingProfilesModal,
    showAddGamingProfilesModal,
    setCurrentUserContextLoading,
    setCurrentUserFormErrors,
    resetUserToStateFromQuery,
    setShowGamingProfilesModal,
    setShowAddGamingProfilesModal,
    setSelectedSports,
  } = useContext(CurrentUserContext)
  const sportFieldsList = existingSports.reduce((fieldsList, sport) => {
    fieldsList = fieldsList.concat(
      allFieldsBySport[sport].map((field: FieldType) => ({
        sport,
        label: field.label,
        value: sportFields[field.name],
      })),
    )
    return fieldsList
  }, [])

  const [updateUser, { loading }] = useMutation(UPDATE_USER_GAMING_PROFILES, {
    refetchQueries: showAddGamingProfilesModal ? ['userProfileSettings'] : [],
    variables: {
      id: playerId,
      ...sportFields,
    },
    onError: error => {
      console.log(error)
    },
    onCompleted: data => {
      handleResult({
        result: data.updateUser,
        onSuccess: () => {
          setCurrentUserContextLoading(false)
          setShowGamingProfilesModal(false)
          setShowAddGamingProfilesModal(false)
          setSelectedSports([])
        },
        onFailure: errors => {
          setCurrentUserFormErrors(errors)
          setCurrentUserContextLoading(false)
        },
      })
    },
  })

  const onHideModals = () => {
    setCurrentUserFormErrors({})
    resetUserToStateFromQuery()
    setShowGamingProfilesModal(false)
    setShowAddGamingProfilesModal(false)
  }

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <SettingsBlock>
      <SettingsHeader
        text="gaming profiles"
        onSettingsClick={() => setShowGamingProfilesModal(true)}
      />
      <SettingsContent>
        {sportFieldsList.map((field: Dictionary<string>, index: number) => (
          <Box key={index}>
            <Flex my={4} alignItems="center">
              <SportLogo width="20px" height="20px" sport={field.sport} />
              <Text color={colors.black} mr={2} ml={4}>
                <h5>{field.label}:</h5>
              </Text>
              <Text color={colors.darkgray}>
                <p>{field.value}</p>
              </Text>
            </Flex>
            <Separator />
          </Box>
        ))}
        <AddMoreButton my={4} onClick={() => setShowAddGamingProfilesModal(true)}>
          <AddIcon />
          <Text color="primarynavy" ml={3}>
            <p>Add More Profiles</p>
          </Text>
        </AddMoreButton>
      </SettingsContent>
      {showGamingProfilesModal && (
        <SettingsModal hideModal={onHideModals}>
          <GamingProfiles
            headerText="update gaming profiles"
            submitButtonText="save"
            onFormSubmitted={updateUser}
            sports={existingSports}
          />
        </SettingsModal>
      )}
      {showAddGamingProfilesModal && (
        <SettingsModal hideModal={onHideModals}>
          <AddGamingProfiles onFormSubmitted={updateUser} />
        </SettingsModal>
      )}
    </SettingsBlock>
  )
}
export default SportFields
