import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'

import { useMutation } from '@apollo/react-hooks'

import { TournamentContext } from '../../contexts/TournamentContext'
import {
  AdminTournamentInfo,
  AdminTournamentTeamInfo,
} from '../../types/fragments'
import {
  PopulateTournamentMutation,
  useGetPlatformsQuery,
  usePopulateTournamentMutation,
} from '../../types/graphql'
import { paths } from '../../utils/Routes'
import { esportsSite, SiteSwitch } from '../../utils/sites'
import { allSportSlugs } from '../../utils/sportUtils'
import AdminSafetySwitch from '../atoms/AdminSafetySwitch'
import { BaseButton } from '../atoms/Buttons'
import { TabInput, TabSelect } from '../atoms/FormPieces'
import TabbedDatePicker from '../atoms/TabbedDatePicker'
import ToggleSwitch from '../atoms/ToggleSwitch'
import ImageUploader, { IImageFields } from '../molecules/ImageUploader'
import TextEditor from '../molecules/TextEditor'
import DataFetcher from '../organisms/DataFetcher'
import GamesFilter from './GamesFilter'

export const UPSERT_TOURNAMENT_INFO = gql`
  mutation upsert_tournament_info(
    $id: ID
    $sport: String!
    $name: String!
    $firstMatchDate: String!
    $registrationCloseDate: String!
    $registrationOpenDate: String!
    $banner: String
    $sponsorImage: String
    $description: String
    $isPremium: Boolean
    $checkinLength: Int
    $published: Boolean
    $platform: ID
  ) {
    editTournamentBasicInfo(
      id: $id
      sport: $sport
      name: $name
      firstMatchDate: $firstMatchDate
      registrationOpenDate: $registrationOpenDate
      registrationCloseDate: $registrationCloseDate
      banner: $banner
      sponsorImage: $sponsorImage
      description: $description
      isPremium: $isPremium
      checkinLength: $checkinLength
      published: $published
      platform: $platform
    ) {
      id
      tournament {
        ...AdminTournamentInfo
        adminTournamentTeams {
          ...AdminTournamentTeamInfo
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${AdminTournamentInfo}
  ${AdminTournamentTeamInfo}
`

gql`
  mutation populateTournament($tournamentId: ID!) {
    populateTournament(tournamentId: $tournamentId) {
      value {
        ...AdminTournamentInfo
        adminTournamentTeams {
          ...AdminTournamentTeamInfo
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${AdminTournamentInfo}
  ${AdminTournamentTeamInfo}
`

gql`
  query GetPlatforms {
    platforms {
      id
      uid
      title
    }
  }
`

const AdminInfo = () => {
  const {
    name,
    setName,
    tournamentId,
    regOpenDate,
    setRegOpenDate,
    regCloseDate,
    setRegCloseDate,
    firstMatchDate,
    setFirstMatchDate,
    description,
    setDescription,
    selectedSport,
    setSelectedSport,
    updateFromQuery,
    checkinLength,
    setCheckinLength,
    isPremium,
    isPublished,
    setIsPublished,
    setIsPremium,
    bannerUrl,
    setBannerUrl,
    bannerName,
    setBannerName,
    sponsoredImageUrl,
    setSponsoredImageUrl,
    sponsoredImageName,
    setSponsoredImageName,
    platformId,
    setPlatformId,
  } = useContext(TournamentContext)
  const history = useHistory()
  const isCreate = tournamentId ? false : true

  const [populateTournament, { loading: populateLoading }] = usePopulateTournamentMutation()

  const handleErrors = (errors: _.Dictionary<string>) => {
    toast.error('An unforseen error has occured. Please contact Paul.', {
      containerId: 'temporary',
    })
  }

  const handlePopulateComplete = (data?: PopulateTournamentMutation) => {
    if (data && data.populateTournament && data.populateTournament.value) {
      updateFromQuery(data.populateTournament.value)
      toast.success('Your tournament was populated.', { containerId: 'temporary' })
    }
  }

  const handlePopulateTournament = async () => {
    if (!loading) {
      try {
        const variables = {
          tournamentId: tournamentId,
        }

        const { data } = await populateTournament({ variables })
        handlePopulateComplete(data)
      } catch (err) {
        handleErrors(err.errors)
      }
    }
  }

  const {
    data: platformsData,
    loading: platformsLoading,
    error: platformsError,
  } = useGetPlatformsQuery({
    fetchPolicy: 'cache-and-network',
  })

  const [updateBasicInfo, { loading: queryLoading, error }] = useMutation(UPSERT_TOURNAMENT_INFO, {
    //refetchQueries: ['matchDetailPage'],
    onError(error) {},
    onCompleted(data) {
      if (data.editTournamentBasicInfo.id) {
        updateFromQuery(data.editTournamentBasicInfo.tournament)
        toast.success('Your tournament has been saved!', { containerId: 'temporary' })
      } else {
        toast.error('Error updating tournament.', { containerId: 'temporary' })
      }
    },
  })

  const sportClickHandler = (clickedSport: string) => {
    if (selectedSport.includes(clickedSport)) {
      setSelectedSport([''])
    } else setSelectedSport([clickedSport])
  }

  const checkData = () => {
    if (selectedSport[0] === '') {
      toast.error('Please select a sport for this tournament.', { containerId: 'temporary' })
      return false
    }
    if (!name) {
      toast.error('Please enter a name for this tournament.', { containerId: 'temporary' })
      return false
    }
    if (regCloseDate < regOpenDate) {
      toast.error('Please choose a registration closing date that is after registration opens.', {
        containerId: 'temporary',
      })
      return false
    }
    if (firstMatchDate < regCloseDate) {
      toast.error('Please choose a first match date that is after registration closes.', {
        containerId: 'temporary',
      })
      return false
    }

    if (description === '') {
      toast.error('Please enter a description for the tournament.', { containerId: 'temporary' })
      return false
    }

    if (esportsSite && !platformId) {
      toast.error('Please select a platform.', { containerId: 'temporary' })
      return false
    }
    return true
  }

  const setSponsoredImage = ({ name, dataUrl }: IImageFields) => {
    setSponsoredImageUrl(dataUrl)
    setSponsoredImageName(name)
  }

  const setBannerImage = ({ name, dataUrl }: IImageFields) => {
    setBannerUrl(dataUrl)
    setBannerName(name)
  }
  const loading = populateLoading || queryLoading || platformsLoading
  const handleSubmit = () => {
    const validData = checkData()
    if (validData && !loading) {
      updateBasicInfo({
        variables: {
          id: tournamentId,
          sport: selectedSport[0],
          name: name,
          firstMatchDate: firstMatchDate,
          registrationOpenDate: regOpenDate,
          registrationCloseDate: regCloseDate,
          banner: bannerUrl,
          sponsorImage: sponsoredImageUrl,
          description: description,
          isPremium: isPremium,
          checkinLength: +checkinLength,
          published: isPublished,
          platform: platformId,
        },
      })
    }
  }

  useEffect(() => {
    isCreate && tournamentId && history.push(paths.manageTournament(tournamentId))
  }, [tournamentId, history, isCreate])

  return (
    <DataFetcher error={error || platformsError} loading={loading}>
      <Box>
        <Text mb={4}>
          <h3>Edit Basic Tournament Info</h3>
        </Text>
        <Text mb={7}>
          <p>Select sport and edit user facing tournament details.</p>
        </Text>
        <Text mb={3}>
          <h3>Sport</h3>
        </Text>
        <GamesFilter
          sportSlugs={tournamentId ? selectedSport : allSportSlugs}
          selectedSports={selectedSport}
          unplayedSports={['']}
          sportClickHandler={sportClickHandler}
        />
        <Text mt={7} mb={3}>
          <h3>Tournament Details</h3>
        </Text>
        <Box bg="white" p={7}>
          <Box width={'70%'} minWidth={'10rem'}>
            <TabInput
              type="text"
              label={'tournament name'}
              placeholder={'enter tournament name'}
              value={name}
              onChange={setName}
            />
          </Box>
          <ImageUploader
            mt={6}
            mb={6}
            imageUrl={sponsoredImageUrl}
            imageName={sponsoredImageName}
            onImageChanged={setSponsoredImage}
            imageFieldTitle="logo"
            title="Sponsor Info"
            guidance={
              'For best results use a square JPG, GIF, or PNG that is at least 100px by 100px.'
            }
          />

          <ImageUploader
            imageUrl={bannerUrl}
            imageName={bannerName}
            onImageChanged={setBannerImage}
            imageFieldTitle="banner"
            title="Tournament Banner Image"
          />

          <Flex mt={8} flexWrap="wrap" justifyContent="space-between" mx={-2}>
            <Box mx={2}>
              <TabbedDatePicker
                selectedDate={regOpenDate}
                setSelectedDate={setRegOpenDate}
                label={'registration opens'}
              />
            </Box>
            <Box mx={2} mt={[5, 5, 0, 0]}>
              <TabbedDatePicker
                selectedDate={regCloseDate}
                setSelectedDate={setRegCloseDate}
                label={'registration closes'}
              />
            </Box>
            <Box mx={2} mt={[5, 5, 5, 5, 0]}>
              <TabbedDatePicker
                selectedDate={firstMatchDate}
                setSelectedDate={setFirstMatchDate}
                label={'first matches'}
              />
            </Box>
          </Flex>
          <Flex mt={8} flexWrap="wrap" justifyContent="space-between" mx={-2}>
            <Box mx={2} mt={[5, 5, 5, 0]}>
              <TabInput
                type="number"
                label={'Checkin interval (minutes)'}
                placeholder={'No Checkin'}
                value={checkinLength}
                onChange={setCheckinLength}
              />
            </Box>

            <Box mx={2} mt={[5, 5, 5, 0]}>
              <ToggleSwitch handleChange={() => setIsPremium(!isPremium)} checked={isPremium} />{' '}
              <h5>Premium Tournament</h5>
            </Box>
          </Flex>

          <SiteSwitch
            college={null}
            esports={
              platformsData && (
                <Box width={[1, 1, 1 / 2]}>
                  <TabSelect label="Platform" value={platformId} onChange={setPlatformId}>
                    <option label=" "></option>

                    {platformsData.platforms.map(platform => (
                      <option key={platform.uid} value={platform.id}>
                        {platform.title}
                      </option>
                    ))}
                  </TabSelect>
                </Box>
              )
            }
          />

          <Box mt={6}>
            <TextEditor handleText={setDescription} text={description} />
          </Box>
        </Box>

        <Flex flexDirection="row" alignItems="center" justifyContent="space-between" width="auto">
          <Box>
            <BaseButton variant="primary" mt={4} mr={4} onClick={handleSubmit}>
              save tournament
            </BaseButton>
            <AdminSafetySwitch
              onClick={handlePopulateTournament}
              thisPropTriggersAReRender={Math.random()}
            >
              populate tournament
            </AdminSafetySwitch>
          </Box>
          <Box>
            <Flex alignItems="center">
              <h5>Published</h5>
              <ToggleSwitch
                handleChange={() => setIsPublished(!isPublished)}
                checked={isPublished}
              />
            </Flex>
          </Box>
        </Flex>
      </Box>
    </DataFetcher>
  )
}

export default AdminInfo
