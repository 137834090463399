import React from 'react'
import { Box } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

interface IProps {
  children: any
  className?: string
}

const Container = styled(Box)`
  padding: 4rem 1rem;
  margin: 0 auto;
  max-width: calc(100%);

  ${Mq.md} {
    max-width: 1240px;
    padding: 4rem 2rem;
  }
`

const Content = ({ children, ...rest }: IProps) => (
  <Container py={[6, 7, 8]} px={[3, 3, 5, 6]} {...rest}>
    {children}
  </Container>
)

export default Content
