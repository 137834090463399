import React from 'react'
import { Flex, Box, Link, Text } from 'rebass'
import { GiCrossedSwords } from 'react-icons/gi'

import { SpreadAll } from '../../types/customUtilityTypes'
import {
  EligibleTeamInfoSeasonFragment,
  EligibleTeamInfoTournamentFragment,
} from '../../types/graphql'
import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'
import Alert from '../atoms/Alert'
import Table from '../atoms/Table'
import { useTheme } from 'emotion-theming'

export type TeamTableLike = SpreadAll<
  EligibleTeamInfoTournamentFragment,
  [EligibleTeamInfoSeasonFragment]
>
// If we use this in another component, add its fragment here ^^^

interface ITeamsTableProps {
  teams: TeamTableLike[]
  coordinator?: boolean
  enroll?: boolean
  enrollTeam?: (team: TeamTableLike) => void
}

const TeamsTable = ({ teams, coordinator, enroll, enrollTeam }: ITeamsTableProps) => {
  const { colors } = useTheme()

  return (
    <>
      <Table headerBackgroundColor="middlegray" bodyBorder={true}>
        <thead>
          <tr>
            <th>
              <h6>Team Name</h6>
            </th>
            {coordinator && (
              <th>
                <h6>Coordinator</h6>
              </th>
            )}
            <th>
              <h6>State</h6>
            </th>

            <th>
              <h6>University</h6>
            </th>

            {enroll && (
              <th>
                <h6>Enrollment</h6>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {teams &&
            teams.map((team: TeamTableLike) => (
              <tr key={team.id}>
                <td>
                  <Flex alignItems="center">
                    <Text color={colors.primarynavy} mr={5}>
                      <Link href={team.id && paths.teamDetail(team.id)}>
                        <h6>{team.name && team.name.toUpperCase()}</h6>
                      </Link>
                    </Text>
                    {/* Out of scope for 8/26, but we will need this for 9/12 so leaving for now */}
                    {/* {team.checkedIn && <CheckCircle size={20} color={colors.green} />} */}
                  </Flex>
                </td>
                {coordinator && (
                  <td>
                    {team.coordinator && (
                      <Link href={paths.player(team.coordinator.id)} color={colors.primarynavy}>
                        <h6>{team.coordinator && team.coordinator.username}</h6>
                      </Link>
                    )}
                  </td>
                )}
                <td>
                  <Text color={colors.primarynavy}>
                    <h6>{team.university && team.university.state && team.university.state}</h6>
                  </Text>
                </td>
                <td>
                  <Text color={colors.primarynavy}>
                    <h6>{team.university && team.university.abbreviation}</h6>
                  </Text>
                </td>

                {enroll && enrollTeam && (
                  <td>
                    <BaseButton onClick={() => enrollTeam(team)} variant="secondary">
                      add Team
                    </BaseButton>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>

      {teams.length < 1 && (
        <Alert borderColor={colors.middlegray} mx="auto" maxWidth="100%">
          <Box mt={4}>
            <GiCrossedSwords color={colors.sand} size={40} />
          </Box>
          <h2>No Teams Found</h2>
        </Alert>
      )}
    </>
  )
}

export default TeamsTable
