import React from 'react'
import { Box, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { styled, MediaQueries as Mq, Theme } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import HeroImage from '../../assets/images/hero__home-page.jpg'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import Content from '../atoms/Content'
import Card from '../atoms/Card'
import Hero from '../molecules/Hero'

const StyledCard = styled(Card)`
  padding: auto 1rem;
  background: ${props => props.theme.colors.white};

  h2 {
    margin-bottom: 1rem;
  }

  ${Mq.md} {
    padding: 2rem;
  }
`

const AboutContent = styled(Box)`
  padding: 4rem 0 0;
`

const AboutText = styled(Box)`
  max-width: 80%;
  margin: 0 auto 6rem;
`

const Divider = styled(Box)`
  height: 2px;
  background: ${props => props.theme.colors.teal};
  width: 100%;
  margin: 4rem 0;
`

const Footer = styled(Card)`
  color: ${props => props.theme.colors.white};
  padding: 4rem;
  background: ${props => props.theme.colors.primarynavy};
  border-radius: 0 6px;
  flex-direction: column;
  align-items: center
  text-align: left;
  p {
  color: ${props => props.theme.colors.white};
  }
`

const About: React.FC = () => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <Hero
        title={'About Us'}
        image={HeroImage}
        position="center 30%"
        height="18rem"
        tint={'3,51,89, 0.6'}
      ></Hero>
      <Content>
        <StyledCard mb={6} notch={true} notchSize={20}>
          <AboutContent>
            <AboutText>
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
              >
                <h2>Who we are</h2>
                <p>
                  We’re a portal for gamers to discover diverse communities, unforgettable
                  experiences, and extraordinary opportunities. We’ve welcomed gamers of all ages,
                  backgrounds and skills; and now we’re ready for you.
                </p>
                <Divider />
                <h2>What we do</h2>
                <p>
                  CSL Esports is the starting line for a path to the Pros. This is can be a
                  playground or training ground; the choice is yours.
                </p>
                <p>
                  Our platform is designed for gamers to connect, compete and have fun. For free.
                  Every week.
                </p>
                <p>
                  If winning was easy it’d be worth nothing, which is why we remind our players to
                </p>
              </Animation>
            </AboutText>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Footer notch={true} notchSize={20}>
                <Text mb={4}>
                  <h2>How To Play</h2>
                </Text>
                <Text mb={7} color={Theme.colors.white}>
                  <ul>
                    <li>
                      <p>1. Create an account here</p>
                    </li>
                    <li>
                      <p>2. Register for your game of choice, with a team, or alone</p>
                    </li>
                    <li>
                      <p>3. Discover new challengers in our tournament bracket</p>
                    </li>
                    <li>
                      <p>4. Sharpen your skills as you compete to place in the money</p>
                    </li>
                    <li>
                      <p>5. Be rewarded for your wins from our cash prize pool</p>
                    </li>
                  </ul>
                </Text>
                <Link to={paths.signup()}>
                  <BaseButton variant="secondaryLight">Get Started</BaseButton>
                </Link>
              </Footer>
            </Animation>
          </AboutContent>
        </StyledCard>
      </Content>
    </>
  )
}

export default About
