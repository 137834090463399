import { useTheme } from 'emotion-theming'
import { orderBy } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text } from 'rebass'
import { oc } from 'ts-optchain'

import { TeamSeasonStats } from '../../../types/graphql'
import { paths } from '../../../utils/Routes'
import Rank from '../../atoms/Rank'
import Table from '../../atoms/Table'

interface IHasName {
  name?: string | null
}

interface ITeamLike extends IHasName {
  id: string
  university?: IHasName | null
  selectedSeasonConferenceName?: string | null
  selectedSeasonStats?: TeamSeasonStats | null
}

interface IStandingsTableProps {
  teams: ITeamLike[]
}

const StandingsTable: React.FC<IStandingsTableProps> = ({ teams }) => {
  const { colors } = useTheme()

  const orderedTeams = orderBy(teams, ['selectedSeasonStats.rank'], ['asc'])

  return (
    <Table headerBackgroundColor="middlegray" bodyBorder={true}>
      <thead>
        <tr>
          <th>
            <h6>Rank</h6>
          </th>
          <th>
            <h6>Team</h6>
          </th>
          <th>
            <h6>University</h6>
          </th>
          <th>
            <h6>Conference</h6>
          </th>
          <th>
            <h6>Record</h6>
          </th>
        </tr>
      </thead>

      <tbody>
        {orderedTeams.map(team => (
          <tr key={team.id}>
            <td>
              <Rank rank={team.selectedSeasonStats && team.selectedSeasonStats.rank} />
            </td>
            <td>
              <Flex alignItems="center">
                <Text color={colors.primarynavy} mr={5}>
                  <Link to={paths.teamDetail(team.id)}>
                    <h6>{team.name}</h6>
                  </Link>
                </Text>
              </Flex>
            </td>
            <td>
              <Flex alignItems="center">
                <Text color={colors.primarynavy} mr={5}>
                  <h6>{oc(team).university.name()}</h6>
                </Text>
              </Flex>
            </td>
            <td>
              <Text color={colors.primarynavy}>
                <h6>{team.selectedSeasonConferenceName || '-'}</h6>
              </Text>
            </td>
            <td>
              {team.selectedSeasonStats && (
                <Text color={colors.primarynavy}>
                  <h6>
                    {team.selectedSeasonStats.wins} - {team.selectedSeasonStats.losses}
                  </h6>
                </Text>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default StandingsTable
