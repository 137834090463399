import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { Box, Link } from 'rebass'
import { oc } from 'ts-optchain'

import { useConstants } from '../../hooks/constants'
import {
  Leagues_DivisionFragment as Division,
  useLeaguesDivisionsLazyQuery as useDivisions,
} from '../../types/graphql'
import { currentUserIsAdmin } from '../../utils/admins'
import { orderedByPosition, withSeasons } from '../../utils/divisionUtils'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'
import Content from '../atoms/Content'
import DataFetcher from '../organisms/DataFetcher'
import LeagueHero from '../organisms/LeagueHero'
import DivisionAccordionItem from './Leagues/DivisionAccordionItem'

const LEAGUES_SEASON = gql`
  fragment Leagues_Season on Season {
    id
    name
    seasonYear
    registrationStatus
    sport {
      slug
    }
  }
`

const LEAGUES_DIVISION = gql`
  fragment Leagues_Division on Division {
    id
    name
    position
    bannerUrl
    logoUrl
    seasons {
      ...Leagues_Season
    }
  }
  ${LEAGUES_SEASON}
`

gql`
  query LeaguesDivisions($seasonYears: [String!]) {
    divisions(seasonYears: $seasonYears) {
      ...Leagues_Division
    }
  }
  ${LEAGUES_DIVISION}
`

const Leagues: React.FC = () => {
  const constants = useConstants()
  const currentSeasonYear = oc(constants).currentSeasonYear('')
  const [year, setYear] = useState('')
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)

  const animations = {
    hidden: {
      opacity: 0,
      y: '1.5rem',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  const [divisions, setDivisions] = useState<Division[]>([])

  const [divisionsQuery, { error, loading }] = useDivisions({
    fetchPolicy: 'cache-and-network',
    variables: {
      seasonYears: [year],
    },
    onCompleted: data => {
      setFirstLoadComplete(true)
      setDivisions(data.divisions)
    },
    onError: _error => {
      setFirstLoadComplete(true)
    },
  })

  useEffect(() => {
    if (currentSeasonYear && !year) {
      setYear(currentSeasonYear)
    }
  }, [year, currentSeasonYear])

  useEffect(() => {
    if (!year) {
      return
    }
    divisionsQuery()
  }, [year, divisionsQuery])

  const isCurrentSeason = year === currentSeasonYear

  return (
    <DataFetcher error={error} loading={loading || !firstLoadComplete}>
      <LeagueHero
        title={`${year.replace('-', ' - ')} season`}
        subTitle={
          isCurrentSeason
            ? 'ENROLLMENT BEGINS AUGUST 26   |   Play for fun or for scholarship prizes'
            : 'View past season information below'
        }
        height="18rem"
        isCurrentSeason={isCurrentSeason}
        year={year}
        setYear={setYear}
      />
      <Content>
        <Box>
          {currentUserIsAdmin() && (
            <Link href={paths.createSeason()}>
              <BaseButton variant="primary">Create New League</BaseButton>
            </Link>
          )}

          <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
            {withSeasons(orderedByPosition(divisions)).map((division, i) => (
              <Box key={i} mt={5}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                >
                  <DivisionAccordionItem
                    division={division as Division}
                    isPreviousYear={!isCurrentSeason}
                  />
                </Animation>
              </Box>
            ))}
          </Accordion>
        </Box>
      </Content>
    </DataFetcher>
  )
}

export default Leagues
