import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { Box, Text } from 'rebass'
import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'

import { BaseButton } from '../atoms/Buttons'
import { TabInput, TabSelect } from '../atoms/FormPieces'
import { TournamentContext } from '../../contexts/TournamentContext'
import { fiftyStates } from '../../utils/50States'

export const EDIT_TOURNAMENT_IRL = gql`
  mutation edit_tournament_irl(
    $id: ID!
    $address: String!
    $state: String!
    $onsiteEntryCode: String!
  ) {
    editTournamentIrl(
      id: $id
      address: $address
      state: $state
      onsiteEntryCode: $onsiteEntryCode
    ) {
      id
      errors {
        field
        message
      }
    }
  }
`

const AdminIrl = () => {
  const { tournamentId, address, setAddress, geoState, setGeoState, code, setCode } = useContext(
    TournamentContext,
  )

  //setting a redirect state and using Redirect caused too many re-renders here so used history

  const [editTournamentIrl, { loading }] = useMutation(EDIT_TOURNAMENT_IRL, {
    onError(_error) {},
    onCompleted: _data => {
      toast.success('Your tournament was updated.', { containerId: 'temporary' })
    },
  })

  const checkData = () => {
    if (!address) {
      toast.error('Please enter the address for this tournament.', { containerId: 'temporary' })
      return false
    }
    if (!geoState) {
      toast.error('Please enter the state for this tournament.', { containerId: 'temporary' })
      return false
    }
    if (!code) {
      toast.error('Please enter the onsite entry code for this tournament.', {
        containerId: 'temporary',
      })
      return false
    }
    return true
  }

  const handleSubmit = () => {
    const validData = checkData()
    if (validData && !loading) {
      editTournamentIrl({
        variables: {
          id: tournamentId,
          address: address,
          state: geoState,
          onsiteEntryCode: code,
        },
      })
    }
  }

  return (
    <Box>
      <Text mb={4}>
        <h3>Edit irl Tournament Info</h3>
      </Text>
      <Text mb={7}>
        <p>Set an address and add an onsite registration code.</p>
      </Text>

      <Text mb={3}>
        <h3>irl settings</h3>
      </Text>
      <Box bg="white" p={7}>
        <Box width={'70%'} minWidth={'10rem'}>
          <TabInput
            type="text"
            label={'street address'}
            placeholder={'enter street address'}
            value={address}
            onChange={setAddress}
          />
        </Box>
        <Box width={'70%'} minWidth={'10rem'} mt={4}>
          <TabSelect label={'state'} value={geoState} onChange={setGeoState}>
            <option key={'default'}>select a state</option>
            {fiftyStates.map((state, i) => (
              <option key={i}>{state}</option>
            ))}
          </TabSelect>
        </Box>
        <Box width={'70%'} minWidth={'10rem'} mt={6}>
          <TabInput
            type="text"
            label={'onsite entry code'}
            placeholder={'enter code'}
            value={code}
            onChange={setCode}
          />
        </Box>
      </Box>
      <BaseButton variant="primary" mt={4} onClick={handleSubmit}>
        save irl settings
      </BaseButton>
    </Box>
  )
}

export default AdminIrl
