import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { Box, Text } from 'rebass'

import { styled } from '../../styles/settings'
import Content from '../atoms/Content'
import AdminUniversityInfo from '../organisms/AdminUniversityInfo'

const EditNav = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    font-family: ${props => props.theme.fonts.condensed};
    margin-bottom: 1.5rem;
  }
`

const CreateUniversity: React.FC = () => {
  //basic info
  const match = useRouteMatch()
  const sections: { [key: string]: React.ReactElement } = {
    'basic-info': <AdminUniversityInfo />,
  }

  return (
    <Content>
      <Text mb={8}>
        <h2>Create University</h2>
      </Text>

      <Box
        sx={{
          display: 'grid',
          gridGap: 1,
          gridTemplateColumns: '1fr 4fr',
        }}
      >
        <EditNav>
          <li>
            <h5>basic info</h5>
          </li>
        </EditNav>
        <Route exact path={`${match.path}`} render={() => sections['basic-info']} />
      </Box>
    </Content>
  )
}

export default CreateUniversity
