import { AlertTriangle } from 'react-feather'
import { Flex, Text } from 'rebass'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { paths } from '../../../utils/Routes'

import { BaseButton } from '../../atoms/Buttons'
import { CustomInput } from '../../atoms/FormPieces'

interface IContactCoordinatorModalProps {
  tournamentId: string
  teamsLeftToRegister: boolean
}

const ContactCoordinatorModal: React.FC<IContactCoordinatorModalProps> = ({
  tournamentId,
  teamsLeftToRegister,
}) => {
  const { colors } = useTheme()
  const tournamentPath = window.location.origin + paths.tournament(tournamentId)

  const copyTournamentPathToClipboard = () => {
    const el = document.createElement('textarea')
    el.value = tournamentPath
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  return (
    <Flex
      py={[4, 4, 5]}
      px={[0, 0, 6, 7]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <AlertTriangle size={38} color={colors.sand} />
      <Text my={5} textAlign="center">
        <h2>contact your coordinator</h2>
      </Text>
      <Text mb={5} textAlign="center">
        {teamsLeftToRegister ? (
          <p>
            In order to compete in this tournament, your team's coordinator needs to register your
            team. Send your coordinator a link to this tournament below.
          </p>
        ) : (
          <p>
            If you need to make any changes to your team's registration status, please reach out to
            your coordinator and reference the link to this tournament below.
          </p>
        )}
      </Text>
      <CustomInput type="text" name="tournamentPath" value={tournamentPath} />

      <Flex mt={5}>
        <BaseButton mx="auto" variant="primary" onClick={copyTournamentPathToClipboard}>
          copy to clipboard
        </BaseButton>
      </Flex>
    </Flex>
  )
}

export default ContactCoordinatorModal
