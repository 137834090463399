import React, { useState, useContext } from 'react'
import { useTheme } from 'emotion-theming'
import { Settings } from 'react-feather'
import { Box, Flex, Text } from 'rebass'
import { Link } from 'react-router-dom'

import { TeamContext } from '../../contexts/TeamContext'
import { rawNumPlayers } from '../../utils/sportUtils'
import { teamStarters, teamSubstitutes } from '../../utils/teamUtils'
import { addStatsToRosterEntries } from '../../utils/statUtils'
import { paths } from '../../utils/Routes'

import { BaseButton } from '../atoms/Buttons'
import PlayersTable from '../molecules/PlayersTable'
import RosterBox from '../molecules/RosterBox'
import { siteSwitcher } from '../../utils/sites'

interface IProps {
  applyToTeam: () => void
}

const TeamDetailLineup: React.FunctionComponent<IProps> = ({ applyToTeam }) => {
  const { colors } = useTheme()
  const [showSubstitutes, setShowSubstitutes] = useState(false)

  const {
    id,
    name,
    sportSlug,
    coordinator,
    activeRosterEntries,
    selectedSeasonPlayerStats,
    currentUserIsCoordinator,
    currentUserIsEligible,
    currentUserApplicationPending,
    isRecruiting,
  } = useContext(TeamContext)

  const starterLineup = teamStarters(activeRosterEntries)
  const substituteLineup = teamSubstitutes(activeRosterEntries)
  return (
    <Box>
      <Flex
        mb={3}
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row']}
      >
        <Text color={colors.black}>
          <h2>
            {showSubstitutes
              ? `Available Subs (${substituteLineup.length})`
              : `Team Lineup (${starterLineup.length}/${sportSlug ? rawNumPlayers(sportSlug) : 5})`}
          </h2>
        </Text>

        <Flex flexWrap="wrap" alignItems="center" my={[3, 3, 0]}>
          <BaseButton
            mr={[0, '1rem']}
            mb={['0.5rem', 0]}
            variant={showSubstitutes ? 'tertiaryOutlinedDisabled' : 'tertiaryOutlined'}
            onClick={() => setShowSubstitutes(false)}
          >
            Starting Lineup
          </BaseButton>
          <BaseButton
            width={[1, 'auto']}
            variant={showSubstitutes ? 'tertiaryOutlined' : 'tertiaryOutlinedDisabled'}
            onClick={() => setShowSubstitutes(true)}
          >
            Substitutes
          </BaseButton>
          {currentUserIsCoordinator ? (
            <Box ml={[0, '1rem']} mt={['0.5rem', 0]} data-testid="edit-team-button">
              <Link to={paths.manageTeam(id)}>
                <Settings color={colors.primarynavy} />
              </Link>
            </Box>
          ) : null}
        </Flex>
      </Flex>

      <PlayersTable
        rosterEntries={
          showSubstitutes
            ? addStatsToRosterEntries(substituteLineup, selectedSeasonPlayerStats)
            : addStatsToRosterEntries(starterLineup, selectedSeasonPlayerStats)
        }
        playerHeaderTitle={showSubstitutes ? 'Substitutes' : null}
        lightHeaderBackground={showSubstitutes}
        recordsColumn={true}
        showEmptyPositions={!showSubstitutes}
        aceScoreColumn={siteSwitcher({college: true, esports: false})}
        inGameNameColumn={true}
        coordinatorId={coordinator.id}
        sport={sportSlug}
      />

      <RosterBox
        teamId={id}
        starterLineup={starterLineup}
        substituteLineup={substituteLineup}
        sportSlug={sportSlug}
        showSubstitutes={showSubstitutes}
        currentUserIsEligible={currentUserIsEligible}
        applyToTeam={applyToTeam}
        currentUserApplicationPending={currentUserApplicationPending}
        teamName={name}
        isRecruiting={isRecruiting}
      />
    </Box>
  )
}

export default TeamDetailLineup
