import gql from 'graphql-tag'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import { darken } from 'polished'
import { Box, Flex, Text, Button, ButtonProps } from 'rebass'
import { FiX } from 'react-icons/fi'
import { styled } from '../../styles/settings/theme'
import { useMutation } from '@apollo/react-hooks'

import { Team } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import { CustomSelect } from '../atoms/Select'
import LoadingSpinner from '../atoms/LoadingSpinner'
import TextBox from '../atoms/TextBox'

const REPORT_FORFEIT = gql`
  mutation ReportForfeit(
    $matchId: ID!
    $explanation: String!
    $proofScreenshot: Upload!
    $requestingTeamId: ID
  ) {
    reportForfeit(
      matchId: $matchId
      explanation: $explanation
      proofScreenshot: $proofScreenshot
      requestingTeamId: $requestingTeamId
    ) {
      errors
    }
  }
`

interface ForfeitFormProps {
  matchId: string
  closeModal: () => void
  userIsAdmin: boolean
  teams: Array<Team>
}

const LabelContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-98%);
`

const Triangle = styled.div`
  width: 2rem;
  background: linear-gradient(
    to left bottom,
    transparent 50%,
    ${props => props.theme.colors.darkmiddlegray} 50%
  );
`

const TeamLabel = styled(Box)`
  background: ${props => props.theme.colors.darkmiddlegray};
  border-right: 1px solid ${props => props.theme.colors.darkmiddlegray};
  padding: 0.5rem;
  border-radius: 4px 0 0 0;
`

const ButtonWrapper = styled(Button)<ButtonProps>`
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  display: flex;
  cursor: pointer;

  &:hover {
    background: transparent;
    color: inherit;
  }
`

const SelectContainer = styled(Box)`
  position: relative;
  margin-top: 3rem;
  margin-bottom: 0.5rem;

  select {
    outline: none;
    border-top-left-radius: 0;
  }
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  border-color: ${props => props.theme.colors.darkmiddlegray};
  border-style: dashed;
  background-color: ${props => props.theme.colors.backgroundgray};
  color: ${props => darken(0.3, props.theme.colors.darkmiddlegray)};
  outline: none;
  margin-top: 0.5rem;
`

const ForfeitForm: React.FunctionComponent<ForfeitFormProps> = ({
  matchId,
  closeModal,
  userIsAdmin,
  teams,
}) => {
  const [explanation, setExplanation] = useState('')
  const [proofScreenshot, setProofScreenshot] = useState({ name: null })
  const [requestingTeamId, setRequestingTeamId] = useState(teams[0].id)
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDropAccepted: (files: any) => setProofScreenshot(files[0]),
  })

  const [reportForfeit, { loading }] = useMutation(REPORT_FORFEIT, {
    refetchQueries: ['matchDetailPage'],
    onError(error) {
      toast.error('An unexpected error occured, please try again.', { containerId: 'temporary' })
    },
    context: { hasUpload: true },
    onCompleted(data) {
      if (data.reportForfeit.errors.length) {
        toast.error(JSON.parse(data.reportForfeit.errors[0])[0], { containerId: 'temporary' })
      } else {
        toast.success('Forfeit submitted!', { containerId: 'temporary' })
        closeModal()
      }
    },
  })

  const handleSubmit = () => {
    reportForfeit({
      variables: {
        matchId: matchId,
        explanation: explanation,
        proofScreenshot: proofScreenshot,
        requestingTeamId: userIsAdmin ? requestingTeamId : null,
      },
    })
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <h2>Report Match Forfeit</h2>
      {userIsAdmin && (
        <SelectContainer>
          <LabelContainer justifyContent="flex-end" alignItems="stretch">
            <TeamLabel>
              <Text>
                <h6>requesting team</h6>
              </Text>
            </TeamLabel>
            <Triangle />
          </LabelContainer>
          <CustomSelect onChange={e => setRequestingTeamId(e.target.value)}>
            {teams.map((team: Team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </CustomSelect>
        </SelectContainer>
      )}
      <Box mt={2}>
        <Text mb={1}>
          <p>
            Please tell us what was wrong/why you're requesting a forfeit, and include a proof
            screenshot. Make sure your screenshot includes a time-stamp and proof that the opposing
            team wasn't in the proper channel at the scheduled time.
          </p>
        </Text>
        <TextBox value={explanation} onChange={e => setExplanation(e.target.value)} />
        {proofScreenshot.name ? (
          <Flex mt={2} alignItems="center">
            <Text mr={2}>
              <p>{proofScreenshot.name}</p>
            </Text>
            <ButtonWrapper onClick={() => setProofScreenshot({ name: null })}>
              <FiX size="20" />
            </ButtonWrapper>
          </Flex>
        ) : (
          <Container {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag & drop proof screenshot here, or click to select a file.</p>
          </Container>
        )}
      </Box>
      <Flex mt={5} justifyContent="center">
        <BaseButton
          variant={!explanation || !proofScreenshot.name ? 'primaryDisabled' : 'primary'}
          disabled={!explanation || !proofScreenshot.name}
          onClick={handleSubmit}
        >
          submit
        </BaseButton>
      </Flex>
    </>
  )
}

export default ForfeitForm
