import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Text, Box, Flex } from 'rebass'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/react-hooks'

import { BaseButton } from '../atoms/Buttons'
import { CustomSelect } from '../atoms/Select'
import { handleResult, errorOn } from '../../utils/results'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import { roles } from '../../utils/sportUtils'
import { SiteSwitch } from '../../utils/sites'
import { useInviteUserToPlatformMutation as useInviteUserToPlatform } from '../../types/graphql'
import DataFetcher from '../organisms/DataFetcher'

const INVITE_USER_TO_TEAM = gql`
  mutation inviteUserToTeam($email: String!, $teamId: ID!, $gameRole: String) {
    inviteUserToTeam(email: $email, teamId: $teamId, gameRole: $gameRole) {
      errors {
        field
        message
      }
    }
  }
`

gql`
  mutation inviteUserToPlatform($email: String!) {
    inviteUserToPlatform(email: $email) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface IInvitePlayerProps {
  refetchQueries?: string[]
  sport?: string
  teamId?: string
  modalClose: () => void
}

const InvitePlayer: React.FunctionComponent<IInvitePlayerProps> = ({
  refetchQueries,
  sport,
  teamId,
  modalClose,
}) => {
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [
    inviteUserToTeam,
    { loading: inviteUserToTeamLoading, error: inviteUserToTeamError },
  ] = useMutation(INVITE_USER_TO_TEAM, {
    refetchQueries: refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: data => {
      if (data.inviteUserToTeam.errors && data.inviteUserToTeam.errors.length) {
        toast.error(data.inviteUserToTeam.errors[0].message, { containerId: 'temporary' })
      } else {
        modalClose()
        toast.success('Player invited!', { containerId: 'temporary' })
      }
    },
  })

  const [
    inviteUserToPlatform,
    { loading: inviteUserToPlatformLoading, error: inviteUserToPlatformError },
  ] = useInviteUserToPlatform({
    onCompleted: data => {
      handleResult({
        result: data.inviteUserToPlatform,
        onSuccess: () => {
          modalClose()
          toast.success('Player invited!', { containerId: 'temporary' })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    },
  })

  const handleSubmit = async (): Promise<void> => {
    sport && teamId
      ? inviteUserToTeam({ variables: { email: email, teamId: teamId, gameRole: role } })
      : inviteUserToPlatform({ variables: { email } })
  }

  let disabled = email === '' || formErrors['email']

  return (
    <DataFetcher
      loading={inviteUserToTeamLoading || inviteUserToPlatformLoading}
      error={inviteUserToTeamError || inviteUserToPlatformError}
      height="100%"
    >
      <Box py={[0, 3, 6]} px={[0, 3, 8]}>
        <Text textAlign="center">
          <h2>Invite a {`${teamId ? 'teammate' : 'player'}`}</h2>
        </Text>
        <Text textAlign="center" mt={4} mb={6}>
          <p>
            <SiteSwitch
              college="Add a teammate to your team using their university email. If they have a CSL account with the correct game information, they will be added to your roster!"
              esports={
                teamId
                  ? "Add the email of the player you'd like to invite. We will send them an email that will pre-approve them to join your team!"
                  : "Please add the player's email to the form below and we will send them an invite to join CSL Esports."
              }
            />
          </p>
        </Text>
        <InputFieldWithErrors
          type="text"
          name="email"
          label="email"
          placeholder="email"
          value={email}
          isEmailField={true}
          updateSingleField={field => {
            setEmail(field)
          }}
          updateErrors={errors => setFormErrors({ ...formErrors, ...errors })}
          errorMessage={formErrors['email']}
        />
        {sport && roles(sport) ? (
          <CustomSelect
            defaultValue={'add player role'}
            onChange={e => setRole(e.target.value)}
            my={4}
          >
            <option key={'add'}>add player role</option>
            {roles(sport).map(role => (
              <option key={role}>{role}</option>
            ))}
          </CustomSelect>
        ) : null}
        <Flex flexDirection="column" justifyContent="center" mt={6}>
          <BaseButton
            variant={disabled ? 'secondaryDisabled' : 'secondary'}
            disabled={disabled}
            onClick={handleSubmit}
            mx={'auto'}
          >
            {`invite ${teamId ? 'to team' : 'player'}`}
          </BaseButton>
        </Flex>
      </Box>
    </DataFetcher>
  )
}

export default InvitePlayer
