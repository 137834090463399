import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { Text, Flex } from 'rebass'
import { toast } from 'react-toastify'
import { BaseButton } from '../atoms/Buttons'
import { Theme } from '../../styles/settings'

import { SetState } from '../../types/aliases'
import {
  useUpdateUserApplicationMutation as useUpdateUserApplication,
  useRescindPendingInviteMutation as useRescindPendingInvite,
} from '../../types/graphql'
import { handleResult, errorOn } from '../../utils/results'
import { DiscordUsername } from '../atoms/DiscordUsername'

gql`
  mutation updateUserApplication($id: ID!, $accepted: Boolean) {
    updateRosterEntry(id: $id, accepted: $accepted) {
      success
      errors {
        field
        message
      }
    }
  }
  mutation rescindPendingInvite($id: ID!) {
    deleteTeamPlayerInvitation(id: $id) {
      success
    }
  }
`

interface ICreateTeamProps {
  username: string
  role: string
  id: string
  discordUsername?: string
  showRoles: boolean
  isPendingInvite: boolean
  setTeamContextLoading: SetState<boolean>
}

const PlayerApplicationRow: React.FC<ICreateTeamProps> = ({
  username,
  role,
  id,
  discordUsername,
  showRoles,
  isPendingInvite,
  setTeamContextLoading,
}) => {
  const handleDeny = () => {
    const variables = { id }
    isPendingInvite
      ? rescindPendingInvite({
          variables,
        })
      : updateUserApplication({
          variables: { ...variables, accepted: false },
        })
  }

  const [
    updateUserApplication,
    { loading: updateUserApplicationLoading },
  ] = useUpdateUserApplication({
    awaitRefetchQueries: true,
    refetchQueries: ['teamDetailPage'],
    onCompleted: data => {
      handleResult({
        result: data.updateRosterEntry,
        onSuccess: () => toast.success('Application Updated.', { containerId: 'temporary' }),
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    },
  })

  const [rescindPendingInvite, { loading: rescindPendingInviteLoading }] = useRescindPendingInvite({
    awaitRefetchQueries: true,
    refetchQueries: ['teamDetailPage'],
    onCompleted: () => {
      toast.success('Invite removed.', { containerId: 'temporary' })
    },
  })

  useEffect(() => {
    setTeamContextLoading(updateUserApplicationLoading || rescindPendingInviteLoading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserApplicationLoading, rescindPendingInviteLoading])

  return (
    <tr>
      <td style={{ width: '30%' }}>
        <Text color={Theme.colors.primarynavy}>
          <h6>{username}</h6>
        </Text>
      </td>
      {showRoles && (
        <td style={{ width: '20%' }}>
          <Text color={Theme.colors.primarynavy}>
            <h6>{role === 'none' ? 'Sub' : role}</h6>
          </Text>
        </td>
      )}
      <td style={{ width: '10%' }}>
        <Text color={Theme.colors.primarynavy}>
          <DiscordUsername>{discordUsername}</DiscordUsername>
        </Text>
      </td>

      <td style={{ width: showRoles ? '40%' : '60%' }}>
        <Flex>
          {isPendingInvite ? (
            <BaseButton variant="secondaryDisabled" disabled ml="auto">
              invitation pending
            </BaseButton>
          ) : (
            <BaseButton
              variant="secondary"
              onClick={() =>
                updateUserApplication({
                  variables: { id, accepted: true },
                })
              }
              ml="auto"
            >
              approve
            </BaseButton>
          )}
          <BaseButton variant="decline" onClick={handleDeny} ml={1}>
            {isPendingInvite ? 'rescind' : 'deny'}
          </BaseButton>
        </Flex>
      </td>
    </tr>
  )
}

export default PlayerApplicationRow
