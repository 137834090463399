import React from 'react'
import DatePicker from 'react-datepicker'

import { CustomDatePicker } from '../molecules/CustomDatePicker'

interface IProps {
  selectedDate: Date | undefined
  setSelectedDate:
    | React.Dispatch<React.SetStateAction<Date | undefined>>
    | React.Dispatch<React.SetStateAction<Date>>
  label: string
  disabled?: boolean
}

const TabbedDatePicker: React.FC<IProps> = ({ selectedDate, setSelectedDate, label, disabled }) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date: Date) => setSelectedDate(date)}
      popperClassName="date-picker-popper"
      showTimeInput
      disabled={disabled}
      timeCaption="Time:"
      dateFormat="MMM d, yyyy h:mm aa"
      minDate={new Date()}
      customInput={<CustomDatePicker label={label} />}
    />
  )
}

export default TabbedDatePicker
