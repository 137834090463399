import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text, Box } from 'rebass'
import { useTheme } from 'emotion-theming'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import WideContainer from '../atoms/WideContainer'

const StyledFlex = styled(Flex)`
  margin: auto;
  padding: 1rem;

  a p {
    color: ${props => props.theme.colors.white};
    transition: ${props => props.theme.transitions.fast};
    &:hover {
      color: ${props => props.theme.colors.primarylightblue};
    }
  }

  p {
    font-size: 14px;
  }

  ${Mq.md} {
    padding: 0;
  }
`

const CopyrightBar: React.FC = () => {
  const { colors } = useTheme()
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Box bg={colors.black}>
      <WideContainer>
        <StyledFlex
          py={[6, 6, 2]}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height={['auto', 'auto', '3rem']}
          flexWrap="wrap"
        >
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
          >
            <Text color={colors.white}>
              <p>
                &copy; 2009-2020 CSL Esports. All rights
                reserved.
              </p>
            </Text>
          </Animation>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
          >
            <Flex flexDirection={['column', 'column', 'row', 'row']} pt={[4, 4, 0, 0]}>
              {/* TODO: Reactivate once we have these pages */}
              {/* <Link to="/sitemap">
              <Text color={colors.white} mr={5}>
                <p>Sitemap</p>
              </Text>
            </Link> */}
              <Link to={paths.privacyPolicy()}>
                <Text color={colors.white} mr={5}>
                  <p>Privacy Policy</p>
                </Text>
              </Link>
              <Link to={paths.terms()}>
                <Text color={colors.white}>
                  <p>Terms &amp; Conditions</p>
                </Text>
              </Link>
            </Flex>
          </Animation>
        </StyledFlex>
      </WideContainer>
    </Box>
  )
}

export default CopyrightBar
