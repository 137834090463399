import { Box, Flex, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { BaseButton } from '../atoms/Buttons'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import League from '../atoms/League'
import Table from '../atoms/Table'

interface TeamTableProps {
  teamData: any
}

const PlayerCurrentTeamTable: React.FC<TeamTableProps> = ({ teamData }) => {
  const { colors } = useTheme()

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 2]} mb={[5, 5, 5, 0]}>
      <React.Fragment key={teamData.id}>
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Box>
            <Table headerBackgroundColor="middlegray" overflow="auto" lastRowBorder bodyBorder>
              <thead className="notched">
                <tr>
                  <th>
                    <Text color={colors.black}>
                      <h6>team details</h6>
                    </Text>
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {Object.keys(teamData).map((info: any) => (
                  <React.Fragment key={info}>
                    {info !== 'name' && info !== 'id' && (
                      <tr key={info}>
                        <td>
                          <Text color={colors.darkgray}>
                            <h6>{info}</h6>
                          </Text>
                        </td>
                        <td>
                          {info === 'league' ? (
                            teamData[info] ? (
                              teamData[info] === 'Star League' ||
                              teamData[info] === 'Open League' ||
                              teamData[info] === 'Gold League' ? (
                                <Flex justifyContent="flex-end">
                                  <League league={teamData[info]} color={colors.primarynavy} />
                                </Flex>
                              ) : (
                                <Text color={colors.primarynavy}>
                                  <h6>{teamData[info]}</h6>
                                </Text>
                              )
                            ) : (
                              <Text color={colors.primarynavy}>
                                <h6>&mdash;</h6>
                              </Text>
                            )
                          ) : (
                            <Text color={colors.primarynavy}>
                              {teamData[info] ? <h6> {teamData[info]}</h6> : <h6>&mdash;</h6>}
                            </Text>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
            <Flex bg="white" justifyContent="center">
              <Link to={paths.teamDetail(teamData.id)}>
                <BaseButton variant="secondary" my={5}>
                  view team
                </BaseButton>
              </Link>
            </Flex>
          </Box>
        </Animation>
      </React.Fragment>
    </Box>
  )
}

export default PlayerCurrentTeamTable
