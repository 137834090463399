import { Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import mtgaSprites from '../../assets/sprites/mtga-sprites.png'

interface MtgaSpriteProps {
  zoom?: string
}
export const MtgaSprite = styled(Box)<MtgaSpriteProps & BoxProps>`
  background-image: url(${mtgaSprites});
  background-repeat: no-repeat;
  display: inline-block;
  zoom: ${props => props.zoom};
  -moz-transform: scale(${props => props.zoom});
  vertical-align: middle;
  height: 66px;
  width: 66px;
  &.blue {
    background-position: -66px -0px;
  }
  &.black {
    background-position: -132px -0px;
  }
  &.red {
    background-position: -198px -0px;
  }
  &.green {
    background-position: -264px -0px;
  }
  &.colorless {
    background-position: -330px -0px;
  }
  &.multi-color {
    background-position: -396px -0px;
  }
`
