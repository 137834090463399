import * as R from 'ramda'

import { upperCamelCase } from './strings'

export function enumValueToEnum<T>(_enumValue: any, ordinal: number): T {
  if (_enumValue.hasOwnProperty(ordinal)) {
    return _enumValue[ordinal]
  } else {
    throw new TypeError(`enum does not define value '${ordinal}'.`)
  }
}

export function numToString(num: number) {
  const zeroToNine = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
  }

  const toReturn = zeroToNine[num]!
  return toReturn
}

// NOTE: Using 'any' since, if we have a type variable for eenum,
// this function gets really unwieldy to call, i.e.
//
// stringToEnum<typeof Enum, Enum>(Enum, 'string')
export function stringToEnum<EnumValueType>(eenum: any, str: string): EnumValueType | undefined {
  const firstTry = eenum[str]
  const secondTry = eenum[upperCamelCase(str)]
  return firstTry || secondTry
}

export const stringToEnumC = R.curry(stringToEnum)

export default {
  stringToEnum,
  stringToEnumC,
  numToString,
}
