import React, { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Box, Flex } from 'rebass'
import HeroImage from '../../assets/images/hero__rules.jpg'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { paths } from '../../utils/Routes'
import Content from '../atoms/Content'
import Hero from '../molecules/Hero'
import FortniteRules from '../organisms/FortniteRules'
import GamesFilter from '../organisms/GamesFilter'
import NhlRules from '../organisms/NhlRules'
import RlRules from '../organisms/RlRules'

interface IURLParams {
  sport: string
}

const StyledFlex = styled(Flex)`
  padding: 1rem;
  background: ${props => props.theme.colors.white};

  --notchSize: 16px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );

  ${Mq.md} {
    padding: 2rem;
  }
`

const sportMap: { [key: string]: any } = {
  fortnite: FortniteRules,
  nhl: NhlRules,
  rl: RlRules,
}

const Rules: React.FunctionComponent<RouteComponentProps<IURLParams>> = ({ match: { params } }) => {
  let history = useHistory()
  const sport = params.sport || Object.keys(sportMap)[0]
  const [selectedSport, setSelectedSport] = useState(sport)

  if (selectedSport !== sport) {
    history.push(paths.rules(selectedSport))
  }

  const Component = sportMap[selectedSport]

  const handleSportClick = (sport: string) => {
    history.push(paths.rules(sport))
    setSelectedSport(sport)
  }

  return (
    <>
      <Hero
        title={'Rules'}
        image={HeroImage}
        position="center 30%"
        height="18rem"
        tint={'3,51,89, 0.6'}
      ></Hero>
      <Content>
        <Box mb={7}>
          <GamesFilter
            sportSlugs={Object.keys(sportMap)}
            selectedSports={[selectedSport]}
            sportClickHandler={handleSportClick}
            unplayedSports={[]}
          />
          <h2 style={{ marginTop: '3rem' }}>season guide to csl fall 2020 - spring 2021</h2>
          <StyledFlex width="auto" flexDirection="column" margin="2rem auto 4rem">
            <Component />
          </StyledFlex>
        </Box>
      </Content>
    </>
  )
}

export default Rules
