import React from 'react'
import { Box, Flex, FlexProps, Link, Text } from 'rebass'
import { MediaQueries as Mq, styled, Theme } from '../../styles/settings/theme'

import { prettyName } from '../../utils/sportUtils'
import { paths } from '../../utils/Routes'
import { MajorAward } from '../../assets/icons/award-icons'
import { BaseButton } from '../atoms/Buttons'

interface IColoredBoxProps {
  color: string
}
const ColoredBox = styled(Flex)<IColoredBoxProps & FlexProps>`
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid;
  border-color: ${props => props.color};
  height: 100%;
  width: 100%;

  padding: 2.5rem;
  height: 200px;
  text-align: center;
  ${Mq.sm} {
    margin-bottom: 0px;
    height: 200px;
  }
`
interface ILineProps {
  width: string
}
const Line = styled.hr<ILineProps>`
  border: 0;
  border-bottom: 2px solid;
  border-color: ${props => (props.width === '100%' ? props.theme.colors.lightgray : 'inherit')};
  width: ${props => props.width};
`
interface IPrizeProps {
  color: string
  place?: string
  prize: string
  topRow?: boolean
  firstBox?: boolean
  nextPlace?: string
  boxWidth?: number[]
  boxPadding?: number[]
}

const PrizeBox = ({
  color,
  place,
  prize,
  boxPadding,
  topRow,
  boxWidth,
  nextPlace,
}: IPrizeProps) => {
  return (
    <>
      <Box px={boxPadding} mb={[0, 0, 4]} width={boxWidth}>
        <ColoredBox color={color}>
          {topRow && (
            <MajorAward
              width={'1.5rem'}
              height={'1.5rem'}
              viewBox={'0 0 28 28'}
              color={Theme.colors.black}
            />
          )}
          <Text color="black">
            <h5>
              {place ? place : nextPlace} place {place ? 'team' : ''}
            </h5>
          </Text>
          <Line width="3.75rem" />

          <Flex flexDirection="column" alignItems="center" color="black">
            <h3>${prize}</h3>
          </Flex>
        </ColoredBox>
      </Box>
    </>
  )
}

interface IIPrizeProps {
  sport: string
  prize: string
  topPrizes: { [key: string]: string }
  bottomPrizes: { [key: string]: string }
}

const PrizePoolModal = ({ sport, prize, topPrizes, bottomPrizes }: IIPrizeProps) => {
  const tournamentText = `Get hyped for the largest prize pool ever for collegiate ${prettyName(
    sport,
  )}! Battle in weekly matches throughout the season for your share of the pot.`

  return (
    <>
      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems={['center', 'center', 'flex-end']}
        width="100%"
      >
        <Text textAlign={['center', 'center', 'left']}>
          <h2>{`${prettyName(sport)} Playoffs Prize Pool`}</h2>
        </Text>
        <Text textAlign={['center', 'center', 'right']} color="sand" mt={[2, 2, 2, 0]}>
          <h2>{`${prize} in prizes`}</h2>
        </Text>
      </Flex>
      <Box mt={6}>
        <Line width="100%" />
      </Box>
      <Flex
        mt={6}
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems={['center', 'center', 'end']}
      >
        <Text
          textAlign={['center', 'center', 'left']}
          mb={[6, 6, 6, 0]}
          color="darkgray"
          width={['100%', '100%', '60%']}
        >
          <p>{tournamentText}</p>
        </Text>
        <Link href={paths.rules(sport)}>
          <BaseButton variant="secondary">view rules</BaseButton>
        </Link>
      </Flex>

      <Flex flexWrap="wrap" mt={7} mx={[0, 0, 0, -2]}>
        {Object.keys(topPrizes).map((place: string, i) => (
          <PrizeBox
            key={i}
            color={i < 3 ? 'sand' : 'primarylightblue'}
            place={place}
            prize={topPrizes[place]}
            firstBox={i === 0 ? true : false}
            topRow={i < 3 ? true : false}
            boxWidth={[1, 1, 1, 1 / 3]}
            boxPadding={[0, 0, 0, 2]}
          />
        ))}
      </Flex>
      <Flex flexWrap="wrap" mt={0} mx={[-2, -2]}>
        {Object.keys(bottomPrizes).map((place: string, i) => (
          <PrizeBox
            key={i}
            color="darkmiddlegray"
            nextPlace={place}
            boxWidth={[1 / 2, 1 / 2, 1 / 2, 1 / 5]}
            prize={bottomPrizes[place]}
            boxPadding={[2, 2, 2, 2]}
          />
        ))}
      </Flex>
    </>
  )
}

export default PrizePoolModal
