import { Box } from 'rebass'
import { styled, ThemeColors } from '../../styles/settings/theme'

interface IProps {
  borderColor?: ThemeColors
  notch?: boolean
  notchSize?: number
}

const Card = styled(Box)<IProps>`
  background-color: ${props => props.theme.colors.white};
  border: ${props => (props.borderColor ? '2px solid' : 'none')};
  border-color: ${props => (props.borderColor ? props.theme.colors[props.borderColor] : 'none')};
  ${props =>
    props.notch
      ? `--notchSize: ${props.notchSize || 20}px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );`
      : ''};
`

export default Card
