import { useTheme } from 'emotion-theming'
import React from 'react'
import ReactHtmlParser, {
  convertNodeToElement,
  Transform,
} from 'react-html-parser'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'

import { OpenLeagueLogoAlt } from '../../assets/icons/league-icons'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { NewsArticle } from '../../types/graphql'
import { fullWithLongMonth } from '../../utils/dateTimeFormats'
import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'

interface INewsArticleDetailProps {
  newsArticle: NewsArticle
}

interface IBackgroundProps {
  background: string
}

const Wrapper = styled(Box)`
  max-width: 1100px;
  margin: auto;
  background: ${props => props.theme.colors.white};
  margin-top: ${props => props.theme.space[8]}px;
  padding-bottom: 1px;
  --notchSize: 15px;

  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% 100%
  );
`

const Content = styled(Flex)`
  max-width: 660px;
  flex-direction: column;
  margin: 0 auto;
`

const Footer = styled(Flex)`
  color: ${props => props.theme.colors.white};
  margin: 0 1rem 2rem;
  padding: ${props => props.theme.space[6]}px;
  background: ${props => props.theme.colors.primarynavy};
  border-radius: 0 6px;
  flex-direction: column;
  align-items: center;
  text-align: center;

  --notchSize: 20px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );

  ${Mq.md} {
    margin: ${props => props.theme.space[6]}px;

    p {
      max-width: 600px;
    }
  }
`

const FeaturedImage = styled(Box)<IBackgroundProps>`
  width: 100%;
  background-image: url('${props => props.background}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 400px;
`

const BodyContent = styled(Box)`
  table {
    div:last-child {
      margin-bottom: 0;
    }
  }

  td {
    padding: 1rem;
  }

  .legal {
    font-size: 0.7rem;
    line-height: 1.5;
  }
`

const NewsArticleDetail: React.FC<INewsArticleDetailProps> = ({ newsArticle }) => {
  const { colors } = useTheme()
  const articleDate = fullWithLongMonth(newsArticle.createdAt.split(' ')[0])
  const category = newsArticle.newsCategory.name

  const transform: Transform = (node, index) => {
    const mb = node.next ? 6 : 0

    // Commenting out b/c editors want to be able to
    // maintain styles (like alignment) from text editor
    // if (node.attribs && node.attribs.style) {
    //   node.attribs.style = ''
    // }
    if (node.type === 'tag' && node.name === 'p') {
      return (
        <Text mb={mb} color={colors.black}>
          {convertNodeToElement(node, index, transform)}
        </Text>
      )
    } else if (
      node.type === 'tag' &&
      node.name &&
      ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(node.name)
    ) {
      return (
        <Text
          mb={mb}
          color={
            node.name === 'h4'
              ? colors.orange
              : node.name === 'h5'
              ? colors.primarynavy
              : colors.black
          }
        >
          {convertNodeToElement(node, index, transform)}
        </Text>
      )
    } else if (node.type === 'tag' && node.name === 'iframe' && node.attribs) {
      node.attribs.width = '100%'
      return convertNodeToElement(node, index, transform)
    } else {
      return convertNodeToElement(node, index, transform)
    }
  }

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Wrapper>
      {newsArticle.featuredImage && (
        <>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <FeaturedImage background={newsArticle.featuredImage} alt={newsArticle.title} />
          </Animation>
        </>
      )}
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
      >
        <Content py={[6, 7, 8]} px={[4, 4, 0, 0]}>
          <Flex alignItems="center">
            <OpenLeagueLogoAlt width={'1.5rem'} height={'1.5rem'} />
            <Text color={colors.darkgray} ml={4} pt={1}>
              <h6>
                {articleDate} - {category}
              </h6>
            </Text>
          </Flex>
          <Text mt={5} color={colors.black}>
            <h2>{newsArticle.title}</h2>
          </Text>
          <Text mt={6} color={colors.orange}>
            <h4>{newsArticle.summary}</h4>
          </Text>
          <BodyContent mt={6}>
            {ReactHtmlParser(newsArticle.body, {
              transform,
            })}
          </BodyContent>
        </Content>
      </Animation>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
      >
        <Footer>
          <Text mb={4}>
            <h3>Thanks for reading!</h3>
          </Text>
          <Text mb={7}>
            <p>
              Looking for more? We have a whole lot more where that came from. Click below to
              navigate back to all of our news & announcements.
            </p>
          </Text>
          <Link to={paths.newsAndAnnouncements()}>
            <BaseButton variant="secondaryLight">back to news & announcements</BaseButton>
          </Link>
        </Footer>
      </Animation>
    </Wrapper>
  )
}

export default NewsArticleDetail
