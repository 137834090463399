import React from 'react'
import { Flex } from 'rebass'
import { LowerLine, Round } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getTuples } from '../../utils/bracketDataUtils'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}
//only used in bracket size 64 and 128
const Round4BLosers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)

  const seats = getTuples(halfSeats)

  return (
    <Round round="4B" shift={shift} top={bottomHalf ? '325px' : '250px'} numTeams={numTeams}>
      {seats.map(tuple => (
        <Flex>
          <BracketCouplet seats={tuple} round="4B" bottom={'628px'} numTeams={numTeams} />
          <LowerLine round="4B" numTeams={numTeams} bottomHalf={bottomHalf} />
        </Flex>
      ))}
    </Round>
  )
}

export default Round4BLosers
