import React from 'react'
import { useModal } from 'react-modal-hook'

import { currentUserIsAdmin } from '../../utils/admins'
import { BaseButton } from '../atoms/Buttons'
import RescheduleMatchModal from './RescheduleMatchModal'

interface IRescheduleMatchButtonProps {
  currentUserIsCoordinator: boolean
  matchId: string
  rescheduleDate: string | undefined
  scheduledDate: string
}

const RescheduleMatchButton: React.FC<IRescheduleMatchButtonProps> = ({
  currentUserIsCoordinator,
  rescheduleDate,
  matchId,
  scheduledDate,
  ...props
}) => {
  const [showModal, hideModal] = useModal(() =>
    RescheduleMatchModal({ matchId, scheduledDate, hideModal }),
  )

  const showRescheduleModal = (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    showModal()
  }

  if (!currentUserIsCoordinator && !currentUserIsAdmin()) return null

  const buttonEnabled = currentUserIsAdmin() || !rescheduleDate

  return (
    <BaseButton
      variant={buttonEnabled ? 'secondary' : 'secondaryDisabled'}
      disabled={!buttonEnabled}
      mt={4}
      onClick={showRescheduleModal}
      {...props}
    >
      {props.children}
    </BaseButton>
  )
}

export default RescheduleMatchButton
