import React from 'react'
import { Box, Link } from 'rebass'

import Pin from '../../../assets/images/news__pin.png'
import { styled } from '../../../styles/settings'
import { NewsArticleTypes, SportSlugs } from '../../../types/graphql'
import { sportLogo } from '../../../utils/logoUtils'
import { paths } from '../../../utils/Routes'
import Animation from '../../atoms/Animation'
import { BaseButton } from '../../atoms/Buttons'
import NewsOrAnnouncementSummary from './NewsOrAnnouncementSummary'
import { bigImgStyles } from './styles'
import SummaryItemLogo from './SummaryItemLogo'

interface IAnnouncementSummaryProps {
  createdAt: string
  featured: boolean
  featuredImage: string
  id: string
  slug?: string | null
  published?: boolean | null
  summary: string
  title: string
}

const StyledAnnouncementBox = styled(Box)`
  border: 4px solid ${props => props.theme.colors.middlegray};

  ${bigImgStyles}
`

const StyledFeaturedAnnouncementBox = styled(Box)`
  border: 4px solid ${props => props.theme.colors.sand};
  line-height: 0;

  ${bigImgStyles}
`

const AnnouncementSummary: React.FC<IAnnouncementSummaryProps> = ({
  slug,
  id,
  featured,
  featuredImage,
  createdAt,
  title,
  summary,
  published,
}) => {
  const logo = sportLogo(SportSlugs.Lol)
  const link = paths.newsArticle(slug || id)
  const BoxComponent = featured ? StyledFeaturedAnnouncementBox : StyledAnnouncementBox
  const boxComponentProps = featured
    ? {
        mx: 6,
        my: 4,
      }
    : {
        mx: 6,
        mb: 6,
        p: 6,
      }

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Animation
      initial="hidden"
      animate="visible"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
    >
      <BoxComponent {...boxComponentProps}>
        <NewsOrAnnouncementSummary
          title={title}
          summary={summary}
          type={NewsArticleTypes.Announcement}
          date={createdAt}
          published={!!published}
          upperRightComponent={featured ? <img alt="A pin" src={Pin} /> : null}
          upperLeftComponent={logo ? <SummaryItemLogo src={logo} /> : null}
          image={featuredImage}
          button={
            <Link href={link}>
              <BaseButton variant="secondary">Learn More</BaseButton>
            </Link>
          }
          link={link}
        />
      </BoxComponent>
    </Animation>
  )
}

export default AnnouncementSummary
