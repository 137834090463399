import React from 'react'
import { BracketContainer } from '../atoms/BracketPieces'
import BracketTitle from '../atoms/BracketTitle'
import RoundOf128 from './RoundOf128'
import RoundOf64 from './RoundOf64'
import RoundOf32 from './RoundOf32'
import RoundOf16 from './RoundOf16'
import RoundOf8 from './RoundOf8'
import RoundOf4 from './RoundOf4'

interface BracketProps {
  winnersSeats: Array<number>
  top?: string
  bottomHalf: boolean
  cacheUpdated: boolean
  sport?: string
  date?: string
  isDoubleElim: boolean
  numTeams: number
  numPlaying?: number
}
interface IGetRoundEnd {}
const WinnersBracket: React.FunctionComponent<BracketProps> = ({
  winnersSeats,
  top,
  bottomHalf,
  cacheUpdated,
  sport,
  date,
  isDoubleElim,
  numTeams,
  numPlaying,
}) => {
  const getHeight = (size: number) => {
    const getPxAmount: { [key: number]: string } = {
      128: '1375px',
      64: '1160px',
      32: '756px',
      16: isDoubleElim ? (bottomHalf ? '480px' : '602px') : bottomHalf ? '400px' : '502px',
      8: isDoubleElim ? (bottomHalf ? '280px' : '425px') : bottomHalf ? '200px' : '321px',
      4: isDoubleElim ? (bottomHalf ? '220px' : '352px') : bottomHalf ? '50px' : '200px',
    }
    return getPxAmount[size]
  }
  const getRoundEnd = (round: number): number => {
    if (round >= numTeams) {
      return round / 2
    } else return round / 2 + getRoundEnd(round * 2)
  }

  const getRound = (round: number) => {
    const startIndex = numTeams <= round ? 0 : getRoundEnd(round * 2)
    const endIndex = getRoundEnd(round)
    return winnersSeats.slice(startIndex, endIndex)
  }

  const getWidth: { [key: number]: string } = {
    128: '1440px',
    64: '1040px',
    32: '1040px',
    16: '1040px',
    8: '1040px',
    4: '1040px',
  }

  const getShift: { [key: number]: string[] } = {
    128: [
      '30px',
      '230px',
      '315px',
      '446px',
      '515px',
      '625px',
      '718px',
      '754px',
      '885px',
      '970px',
      '1170px',
    ],
    64: ['0px', '30px', '115px', '246px', '315px', '425px', '518px', '554px', '685px', '770px'],
    32: ['0px', '30px', '115px', '246px', '315px', '425px', '518px', '554px', '685px', '770px'],
    16: ['0px', '30px', '115px', '246px', '315px', '425px', '518px', '554px', '685px', '770px'],
    8: ['0px', '30px', '115px', '246px', '315px', '425px', '518px', '554px', '685px', '770px'],
    4: ['0px', '30px', '115px', '246px', '315px', '425px', '518px', '554px', '685px', '770px'],
  }

  return (
    <>
      {cacheUpdated && (
        <BracketContainer width={getWidth[numTeams]} height={getHeight(numTeams)} mt={top}>
          {!bottomHalf && (
            <BracketTitle
              sport={sport}
              date={date}
              isDoubleElim={isDoubleElim}
              numPlaying={numPlaying}
            />
          )}

          {numTeams === 128 && (
            <RoundOf128
              seatList={getRound(128)}
              shift={getShift[numTeams][0]}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
            />
          )}
          {numTeams >= 64 && (
            <RoundOf64
              seatList={getRound(64)}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              shift={getShift[numTeams][1]}
            />
          )}
          {numTeams >= 32 && (
            <RoundOf32
              seatList={getRound(32)}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              shift={getShift[numTeams][2]}
            />
          )}
          {numTeams >= 16 && (
            <RoundOf16
              seatList={getRound(16)}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              isDoubleElim={isDoubleElim}
              shift={getShift[numTeams][3]}
            />
          )}
          {numTeams >= 8 && (
            <RoundOf8
              seatList={getRound(8)}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              isDoubleElim={isDoubleElim}
              shift={getShift[numTeams][4]}
            />
          )}
          {isDoubleElim && (
            <RoundOf4
              seatList={getRound(4)}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              shift={getShift[numTeams][5]}
            />
          )}
          {numTeams >= 8 && (
            <RoundOf8
              seatList={getRound(8)}
              flip={true}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              isDoubleElim={isDoubleElim}
              shift={getShift[numTeams][6]}
            />
          )}
          {numTeams >= 16 && (
            <RoundOf16
              seatList={getRound(16)}
              flip={true}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              isDoubleElim={isDoubleElim}
              shift={getShift[numTeams][7]}
            />
          )}
          {numTeams >= 32 && (
            <RoundOf32
              seatList={getRound(32)}
              flip={true}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              shift={getShift[numTeams][8]}
            />
          )}
          {numTeams >= 64 && (
            <RoundOf64
              seatList={getRound(64)}
              flip={true}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
              shift={getShift[numTeams][9]}
            />
          )}

          {numTeams === 128 && (
            <RoundOf128
              seatList={getRound(128)}
              flip={true}
              shift={getShift[numTeams][10]}
              bottomHalf={bottomHalf}
              numTeams={numTeams}
            />
          )}
        </BracketContainer>
      )}
    </>
  )
}

export default WinnersBracket
