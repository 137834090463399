import React from 'react'
import { Link } from 'react-router-dom'
import { oc } from 'ts-optchain'
import { Text, Flex, Box } from 'rebass'
import { useTheme } from 'emotion-theming'
import { AiFillCrown } from 'react-icons/ai'
import { styled } from '../../styles/settings/theme'
import { useConstants } from '../../hooks/constants'

import { paths } from '../../utils/Routes'
import { roles } from '../../utils/sportUtils'
import { RosterEntry, UpdateRosterEntryMutationFn } from '../../types/graphql'
import { RemoveIcon } from '../../assets/icons/remove-icon'
import { spaceify } from '../../utils/strings'
import { BaseButton } from '../atoms/Buttons'
import { CustomSelect } from '../atoms/Select'
import Table from '../atoms/Table'

const ButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  svg {
    margin-right: 1rem;
  }
`

interface ITableProps {
  starters?: boolean
  rosterEntries: RosterEntry[]
  updateRosterEntry: UpdateRosterEntryMutationFn
  sportSlug: string
  coordinatorId?: string
}

const EditTeamTable: React.FC<ITableProps> = ({
  starters,
  rosterEntries,
  updateRosterEntry,
  sportSlug,
  coordinatorId,
}) => {
  const { colors } = useTheme()
  const constants = useConstants()
  const teamStaffRoles = oc(constants).teamStaffRoles([])
  const playerRoles = roles(sportSlug)
  const showPlayerRoles = playerRoles && !!playerRoles.length

  rosterEntries.some(
    (rosterEntry, index) =>
      rosterEntry.player &&
      rosterEntry.player.id === coordinatorId &&
      rosterEntries.unshift(rosterEntries.splice(index, 1)[0]),
  )

  return (
    <Table headerBackgroundColor="darkgray">
      <thead>
        <tr>
          <th>
            <Box pl={4}>
              <Text color={colors.white}>
                <h6>Player</h6>
              </Text>
            </Box>
          </th>
          <th>
            <Text color={colors.white}>
              <h6>Lineup Role</h6>
            </Text>
          </th>
          {showPlayerRoles && (
            <th>
              <Text color={colors.white}>
                <h6>Player Role</h6>
              </Text>
            </th>
          )}
          <th>
            <Text color={colors.white}>
              <h6>Staff Role</h6>
            </Text>
          </th>
          <th style={{ width: '10%' }}>
            <Box pr={4}>
              <Text color={colors.white}>
                <h6>Remove</h6>
              </Text>
            </Box>
          </th>
        </tr>
      </thead>

      <tbody>
        {rosterEntries.map(member => (
          <tr key={member.id}>
            <td>
              <Flex alignItems="center" pl={4}>
                {member.player && member.player.id === coordinatorId ? (
                  <Box mr={3} pb={'0.1rem'}>
                    <AiFillCrown size={24} color={colors.sand} />
                  </Box>
                ) : null}
                <Text color={colors.primarynavy}>
                  {member.player ? (
                    <Link to={paths.player(member.player.id)}>
                      <h6>{member.player.username}</h6>
                    </Link>
                  ) : (
                    <h6>Name Unavailable</h6>
                  )}
                </Text>
              </Flex>
            </td>

            <td>
              <BaseButton
                variant="secondary"
                onClick={() =>
                  updateRosterEntry({
                    variables: {
                      id: member.id,
                      role: starters ? 'none' : 'main_player',
                    },
                  })
                }
              >
                {starters ? 'move to subs' : 'add to lineup'}
              </BaseButton>
            </td>
            {showPlayerRoles && (
              <td>
                <Text color={colors.primarynavy}>
                  <CustomSelect
                    onChange={e => {
                      updateRosterEntry({
                        variables: {
                          id: member.id,
                          gameRole: e.target.value,
                        },
                      })
                    }}
                    value={oc(member).gameRole('')}
                  >
                    <option value="" disabled>
                      Flex
                    </option>

                    {playerRoles.map((role: string) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </CustomSelect>
                </Text>
              </td>
            )}
            <td>
              <Text color={colors.primarynavy}>
                <CustomSelect
                  onChange={e => {
                    updateRosterEntry({
                      variables: {
                        id: member.id,
                        staffRole: e.target.value,
                      },
                    })
                  }}
                  value={oc(member).staffRoleCd('none')}
                >
                  {teamStaffRoles.map((role: string) => (
                    <option key={role} value={role}>
                      {spaceify(role)}
                    </option>
                  ))}
                </CustomSelect>
              </Text>
            </td>
            <td style={{ width: '10%' }}>
              {member.player && member.player.id !== coordinatorId && (
                <ButtonWrapper
                  onClick={() =>
                    updateRosterEntry({
                      variables: {
                        id: member.id,
                        kicked: true,
                      },
                    })
                  }
                >
                  <RemoveIcon />
                </ButtonWrapper>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default EditTeamTable
