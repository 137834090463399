import { Flex } from 'rebass'
import { styled } from '../../../styles/settings'

const Container = styled(Flex)`
  background: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.sand};
  min-width: 19rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
`

export default Container
