import React, { ReactNode } from 'react'
import { FaSteam, FaDiscord } from 'react-icons/fa'
import { Box, Link } from 'rebass'
import { steamIdToSteam64 } from '../../utils/statUtils'
import { DotaBuffIcon, CapcomIcon, EseaIcon } from '../../assets/icons/stat-icons'
import { generateStatLink } from '../../utils/statUtils'
import SportLogo from '../atoms/SportLogo'

interface SteamProps {
  steamId: string
  children?: ReactNode
}
interface DotaBuffProps {
  dotaBuff: string
  children?: ReactNode
}

interface CapcomProps {
  capcom: string
  children?: ReactNode
}

interface EseaProps {
  esea: string
  children?: ReactNode
}
interface DiscordProps {
  discordId: string
}

interface FortniteProps {
  inGameName: string
}

export const SteamLink: React.FunctionComponent<SteamProps> = ({ steamId }) => {
  return steamId !== '' ? (
    <Link href={'steam://url/SteamIDPage/' + steamIdToSteam64(steamId)}>
      <FaSteam size={20} color="discord" />
    </Link>
  ) : (
    <FaSteam size={20} color="lightgray" />
  )
}

//alternate version for player stats box on player profile
export const SteamLinkAlt = ({ children, steamId }: SteamProps) => {
  return steamId !== '' ? (
    <Link href={'steam://url/SteamIDPage/' + steamIdToSteam64(steamId)}>
      <Box mt={[5, 4, 4, 5]} mb={[3, '6px', 4, 22]}>
        <FaSteam size={48} color="black" />
      </Box>
      {children}
    </Link>
  ) : (
    <>
      <Box mt={[5, 4, 5, 5]} mb={[3, '6px', 20, 22]}>
        <FaSteam size={30} color="lightgray" />
      </Box>
      {children}
    </>
  )
}

export const DotaBuffLink = ({ children, dotaBuff }: DotaBuffProps) => {
  return dotaBuff !== '' ? (
    <Link href={generateStatLink('dota2', dotaBuff)}>
      <Box mt={[36, 5, 6, 36]} mb={[5, 23, 5, 6]}>
        <DotaBuffIcon />
      </Box>
      {children}
    </Link>
  ) : (
    <>
      <Box mt={[36, 5, 6, 36]} mb={[5, 23, 5, 6]}>
        <DotaBuffIcon />
      </Box>
      {children}
    </>
  )
}

export const CapcomLink = ({ children, capcom }: CapcomProps) => {
  return capcom !== '' ? (
    <Link href={generateStatLink('sfv', capcom)}>
      <Box mt={[6, 5, 6, 40]} mb={[5, 20, 5, 30]}>
        <CapcomIcon />
      </Box>
      {children}
    </Link>
  ) : (
    <>
      <Box mt={[6, 5, 6, 40]} mb={[5, 20, 5, 30]}>
        <CapcomIcon />
      </Box>
      {children}
    </>
  )
}

export const EseaLink = ({ children, esea }: EseaProps) => {
  return esea !== '' ? (
    <Link href={esea}>
      <Box mt={[5, 20, 5, 28]} mb={[3, 14, 20, 30]}>
        <EseaIcon />
      </Box>
      {children}
    </Link>
  ) : (
    <>
      <Box mt={[5, 20, 5, 28]} mb={[3, 14, 20, 30]}>
        <EseaIcon />
      </Box>
      {children}
    </>
  )
}

export const DiscordLink: React.FunctionComponent<DiscordProps> = ({ discordId }) => {
  return discordId !== '' ? (
    <Link href={'https://discordapp.com/users/' + discordId}>
      <FaDiscord size={20} color="steam" />
    </Link>
  ) : (
    <FaDiscord size={20} color="lightgray" />
  )
}

export const FortniteLink: React.FunctionComponent<FortniteProps> = ({ inGameName }) => {
  return (
    <Link href={generateStatLink('fortnite', inGameName)}>
      <Box mb={1}>
        <SportLogo width={'1rem'} height={'1rem'} sport="fortnite" />
      </Box>
    </Link>
  )
}
