import { styled } from '../../styles/settings/theme'

interface IStyledLinkProps {
  color?: string
}

const StyledLink = styled.a<IStyledLinkProps>`
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.color || 'inherit'};
  margin-left: auto;
  transition: ${props => props.theme.transitions.fast};
  &:hover {
    color: ${props => props.theme.colors.primaryblue};
  }
`
export default StyledLink
