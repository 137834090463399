import React, { ReactNode } from 'react'
import { Flex, Box, Text } from 'rebass'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import Animation from '../atoms/Animation'
import HeroContainer from '../atoms/HeroContainer'

const HeroContent = styled(Text)`
  color: ${props => props.theme.colors.white};
  z-index: 1;
  width: 100%;
  bottom: 3rem;
  position: absolute;

  h5 {
    max-width: 90%;
  }
  ${Mq.md} {
    h5 {
      max-width: auto;
    }
  }
`

const HeroTitle = styled(Text)`
  display: block;
  align-items: center;
  padding-bottom: 0.5rem;
  width: 100%;

  img {
    position: absolute;
    top: 0;
  }

  h1 {
    padding-right: 1rem;
  }

  ${Mq.md} {
    display: flex;

    img {
      position: relative;
    }

    h1 {
      min-width: max-content;
    }
  }
`

const SponsorBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  h5 {
    min-width: max-content;
  }
  img {
    max-height: 4rem;
    width: auto;
  }
`

const HeroActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`

interface IHeroInfoProps {
  height?: string
  title?: string
  subTitle?: string
  image?: string
  tint?: string
  position?: string
  titleComponent?: ReactNode
  actionsComponent?: ReactNode
  topLeftComponent?: ReactNode
  sponsorComponent?: ReactNode
  seasonToggle?: ReactNode
}

const Hero: React.FC<IHeroInfoProps> = ({
  title,
  subTitle,
  image,
  height,
  tint,
  position,
  titleComponent,
  actionsComponent,
  topLeftComponent,
  sponsorComponent,
  seasonToggle,
}) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    hiddenRight: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <HeroContainer height={height || '250px'} position={position} heroImage={image} tint={tint}>
      <HeroContent>
        {topLeftComponent && (
          <Box>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              {topLeftComponent}
            </Animation>
          </Box>
        )}
        <Flex
          width="auto"
          alignItems="flex-end"
          justifyContent={['flex-end', 'flex-end', 'flex-end', 'inherit']}
          minHeight={'10rem'}
          flexDirection={['column', 'column', 'row']}
        >
          {sponsorComponent && (
            <SponsorBox>
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
              >
                {sponsorComponent}
              </Animation>
            </SponsorBox>
          )}
          <Box width={1}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              <HeroTitle>{titleComponent || <h1>{title}</h1>}</HeroTitle>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              {subTitle && <h5>{subTitle}</h5>}
            </Animation>
          </Box>
          {actionsComponent && (
            <Box width={1}>
              <Animation
                initial="hiddenRight"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
              >
                <HeroActions>{actionsComponent}</HeroActions>
              </Animation>
            </Box>
          )}
        </Flex>
      </HeroContent>
      {seasonToggle || null}
    </HeroContainer>
  )
}

export default Hero
