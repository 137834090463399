import { useTheme } from 'emotion-theming'
import React, { createRef } from 'react'
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone'
import { CheckCircle } from 'react-feather'
import { GiCrossedSwords } from 'react-icons/gi'
import { Box, BoxProps, Flex, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { ClickEvent } from '../../types/aliases'
import { BaseButton } from '../atoms/Buttons'
import { FileField, IFileFieldProps } from '../atoms/FormPieces'

export interface IImageFields {
  name?: string
  dataUrl?: string
}

interface IImageUploaderProps extends BoxProps {
  imageUrl?: string
  imageName?: string
  title?: string
  guidance?: string
  onImageChanged: (fields: IImageFields) => void
  imageFieldTitle: string
  backgroundSize?: string
  pending?: boolean
  approvedImageData?: {
    url?: string
    name?: string
    onChanged: (fields: IImageFields) => void
  }
}

interface IFileDropFieldProps extends IFileFieldProps {
  bgImage?: string
  backgroundSize?: string
}

const FieldWrapper = styled(Box)<BoxProps>`
  max-width: 420px;

  p.small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p.guidance {
    max-width: 200px;
  }
`

const FileDropField = styled(FileField)<IFileDropFieldProps>`
  height: 8rem;
  cursor: pointer;
  background-image: ${props => (props.bgImage ? `url(${props.bgImage})` : 'none')};
  background-position: center;
  background-size: ${props => (props.backgroundSize ? props.backgroundSize : 'contain')};
  background-repeat: no-repeat;
`

const ImageUploader: React.FC<IImageUploaderProps> = ({
  onImageChanged,
  imageUrl,
  imageName,
  title,
  guidance,
  imageFieldTitle,
  backgroundSize,
  pending,
  approvedImageData,
  ...boxProps
}) => {
  const { colors } = useTheme()

  const dropzoneRef = createRef<DropzoneRef>()

  const handleDropRejected = (files: FileRejection[]) => {
    const error = files[0].errors[0]
    console.error(error)
  }

  const handleDropAccepted = (files: File[]) => {
    const file = files[0]
    const reader = new FileReader()

    reader.onabort = () => console.error('file reading was aborted')
    reader.onerror = () => console.error('file reading has failed')
    reader.onload = () => {
      const dataUrl = reader.result as string
      onImageChanged({ dataUrl, name: file.name })
    }
    reader.readAsDataURL(file)
  }

  const handleNewFileUpload = (e: ClickEvent) => {
    e.preventDefault()

    const dz = dropzoneRef.current
    dz && dz.open()
  }

  const handleDeleteFileUpload = (e: ClickEvent) => {
    e.preventDefault()

    onImageChanged({
      name: undefined,
      dataUrl: undefined,
    })
  }

  const handleDeleteApprovedImage = (e: ClickEvent) => {
    e.preventDefault()

    if (!approvedImageData) {
      return
    }

    approvedImageData.onChanged({
      name: undefined,
      dataUrl: undefined,
    })
  }

  return (
    <Box mb={boxProps.mb} mt={boxProps.mt}>
      {title && (
        <Flex alignItems="center" mb={4}>
          <Text color={colors.darkgray}>
            <h5>{title}</h5>
          </Text>

          {pending ? (
            <>
              <Box ml={3} mr={1}>
                <GiCrossedSwords size={20} color={colors.sand} />
              </Box>

              <Text color={colors.sand}>
                <h6>Pending Approval</h6>
              </Text>
            </>
          ) : null}
        </Flex>
      )}

      <Text mb={4} maxWidth={640}>
        {guidance ? (
          <p>{guidance}</p>
        ) : (
          <p>
            Refrain from using images with text, logos, or inappropriate material.
            <br />
            Use a JPG, or PNG with a minimum width of 1280px and minimum height of 475px.
          </p>
        )}
      </Text>

      <Flex flexDirection={['column', 'column', 'column', 'row']}>
        <FieldWrapper width="100%">
          <Dropzone
            accept={['.png', '.jpg']}
            multiple={false}
            onDropRejected={handleDropRejected}
            onDropAccepted={handleDropAccepted}
            ref={dropzoneRef}
          >
            {({ getRootProps, getInputProps }) => (
              <FileDropField {...getRootProps()} bgImage={imageUrl} backgroundSize={backgroundSize}>
                <input {...getInputProps()} />
                {imageUrl ? null : (
                  <p>{`Drag & drop or click to ${
                    approvedImageData && approvedImageData.url ? 'replace' : 'upload'
                  } image`}</p>
                )}
              </FileDropField>
            )}
          </Dropzone>
        </FieldWrapper>
        {imageUrl && (
          <FieldWrapper ml={[0, 0, 0, 5]}>
            <Flex height="100%" justifyContent="space-between" flexDirection="column" py={1}>
              <Text mt={[4, 4, 4, 0]}>
                <p className="small">{imageName}</p>
              </Text>
              <BaseButton my={[4, 4, 4, 0]} variant="secondary" onClick={handleNewFileUpload}>
                Replace {imageFieldTitle}
              </BaseButton>
              <BaseButton variant="decline" onClick={handleDeleteFileUpload}>
                Delete
              </BaseButton>
            </Flex>
          </FieldWrapper>
        )}
      </Flex>

      {approvedImageData && approvedImageData.url && (
        <Flex flexDirection={['column', 'column', 'column', 'row']} mt={5}>
          <FieldWrapper width="100%">
            <FileDropField
              bgImage={approvedImageData.url}
              backgroundSize={backgroundSize}
            ></FileDropField>
          </FieldWrapper>

          <FieldWrapper ml={[0, 0, 0, 5]} mt={[2, 2, 2, 0]}>
            <Flex height="100%" justifyContent="space-between" flexDirection="column" py={1}>
              <Flex alignItems="center">
                <CheckCircle size={20} color={colors.green} />

                <Text color={colors.green} ml={1}>
                  <h6>Approved</h6>
                </Text>
              </Flex>
              <Text mt={[4, 4, 4, 0]}>
                <p>{approvedImageData.name}</p>
              </Text>
              <BaseButton variant="decline" onClick={handleDeleteApprovedImage}>
                Delete
              </BaseButton>
            </Flex>
          </FieldWrapper>
        </Flex>
      )}
    </Box>
  )
}

export default ImageUploader
