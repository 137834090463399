import gql from 'graphql-tag'
import React, { useEffect, useContext } from 'react'
import { FaFacebook, FaTwitch, FaTwitter } from 'react-icons/fa'
import { Box, Flex, Text, TextProps } from 'rebass'
import { useTheme } from 'emotion-theming'
import { styled } from '../../styles/settings/theme'

import { CurrentUserContext } from '../../contexts/CurrentUserContext'
import { handleResult } from '../../utils/results'
import { startCase } from '../../utils/strings'
import { useUpdateSocialProfilesMutation as useUpdateSocialProfiles } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import { InputFieldWithErrors } from './InputFieldWithErrors'
import { Separator } from '../atoms/SettingsPieces'
import SectionTitle from '../atoms/SectionTitle'

gql`
  mutation updateSocialProfiles(
    $twitchUsername: String
    $twitterUsername: String
    $facebookUsername: String
    $id: ID
  ) {
    updateUser(
      twitchUsername: $twitchUsername
      twitterUsername: $twitterUsername
      facebookUsername: $facebookUsername
      id: $id
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`

const SkipText = styled(Text)<TextProps>`
  cursor: pointer;
`

interface SocialProfilesProps {
  submitButtonText?: string
  onSkip?: () => void
  onFormSubmitted: () => void
  playerId?: string
  showUneditableFields?: boolean
}

const SocialProfiles: React.FunctionComponent<SocialProfilesProps> = ({
  submitButtonText,
  onSkip,
  onFormSubmitted,
  playerId,
  showUneditableFields,
}) => {
  const {
    twitchUsername,
    facebookUsername,
    twitterUsername,
    errorsExist,
    currentUserFormErrors,
    setTwitchUsername,
    setFacebookUsername,
    setTwitterUsername,
    setCurrentUserFormErrors,
    setCurrentUserContextLoading,
    updateErrors,
  } = useContext(CurrentUserContext)
  const { colors } = useTheme()

  const fields = [
    {
      name: 'twitchUsername',
      value: twitchUsername,
      udpateField: setTwitchUsername,
      icon: <FaTwitch size={24} color={colors.primarynavy} />,
    },
    {
      name: 'facebookUsername',
      value: facebookUsername,
      udpateField: setFacebookUsername,
      icon: <FaFacebook size={24} color={colors.primarynavy} />,
    },
    {
      name: 'twitterUsername',
      value: twitterUsername,
      udpateField: setTwitterUsername,
      icon: <FaTwitter size={24} color={colors.primarynavy} />,
    },
  ]

  const [submitForm, { loading }] = useUpdateSocialProfiles({
    refetchQueries: ['userProfileSettings'],
    onError: error => {
      console.log(error)
    },
    onCompleted: data => {
      handleResult({
        result: data.updateUser,
        onSuccess: () => onFormSubmitted(),
        onFailure: errors => {
          setCurrentUserFormErrors(errors)
        },
      })
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Box>
      {!showUneditableFields && (
        <>
          <SectionTitle text="your social profiles" align="center" mb="5" />
          <Text textAlign="center" mx={7}>
            <p>
              Add in your social media handles so your teammates can follow or communicate with you
              on other platforms.
            </p>
          </Text>
        </>
      )}
      <Flex
        flexDirection="column"
        mx="auto"
        width={['100%', '100%', showUneditableFields ? '100%' : '60%']}
        my={showUneditableFields ? 0 : 6}
      >
        {fields.map((field, index) =>
          showUneditableFields ? (
            <Box key={field.name}>
              <Flex my={4} alignItems="center">
                {field.icon}
                <Text ml={4} color={colors.darkgray}>
                  <p>{field.value || '-'}</p>
                </Text>
              </Flex>
              {index !== fields.length - 1 && <Separator />}
            </Box>
          ) : (
            <Flex alignItems="center" mb={5} key={field.name}>
              {field.icon}
              <InputFieldWithErrors
                ml={3}
                width="100%"
                type="text"
                name={field.name}
                label={startCase(field.name)}
                placeholder={startCase(field.name)}
                value={field.value}
                updateSingleField={field.udpateField}
                updateErrors={updateErrors}
                maxLength={30}
                errorMessage={currentUserFormErrors[field.name]}
              />
            </Flex>
          ),
        )}
      </Flex>
      {submitButtonText && (
        <Flex flexDirection="column" alignItems="center">
          <BaseButton
            variant={errorsExist ? 'secondaryDisabled' : 'secondary'}
            disabled={errorsExist}
            onClick={() =>
              submitForm({
                variables: {
                  twitchUsername,
                  facebookUsername,
                  twitterUsername,
                  id: playerId,
                },
              })
            }
            mx="auto"
          >
            {submitButtonText}
          </BaseButton>
          {onSkip && (
            <SkipText my={3} color={colors.primarynavy} onClick={onSkip}>
              <h6>Skip</h6>
            </SkipText>
          )}
        </Flex>
      )}
    </Box>
  )
}

export default SocialProfiles
