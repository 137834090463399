import React from 'react'
import { Flex } from 'rebass'

import { Round, Line } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getQuadrant } from '../../utils/bracketDataUtils'

interface RoundProps {
  flip?: boolean
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const RoundOf128: React.FunctionComponent<RoundProps> = ({
  flip,
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const topLeft = getQuadrant(seatList, 1)
  const topRight = getQuadrant(seatList, 2)
  const bottomLeft = getQuadrant(seatList, 3)
  const bottomRight = getQuadrant(seatList, 4)

  const logicalSeats =
    flip && bottomHalf ? bottomRight : bottomHalf ? bottomLeft : flip ? topRight : topLeft

  return (
    <Round round="128" top={bottomHalf ? '31px' : '-4px'} shift={shift}>
      {logicalSeats.map((tuple, i) => (
        <Flex key={i} className={'quadruple'}>
          {flip && <Line numTeams={numTeams} round="128" flip={flip} />}
          <BracketCouplet seats={tuple} round="128" bottom="10px" flip={flip} numTeams={numTeams} />
          {!flip && <Line numTeams={numTeams} round="128" />}
        </Flex>
      ))}
    </Round>
  )
}

export default RoundOf128
