import { Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import nbaSprites from '../../assets/sprites/nba-sprites.png'

interface SpriteProps {
  zoom?: string
}

export const NbaSprite = styled(Box)<SpriteProps & BoxProps>`
  background-image: url(${nbaSprites});
  display: inline-block;
  margin-right: 7px;
  height: 32px;
  width: 32px;
  vertical-align: middle;
  zoom: ${props => props.zoom};
  -moz-transform: scale(${props => props.zoom});
  &.atlanta-hawks {
    background-position: 248px -75px;
  }
  &.boston-celtics {
    background-position: 0px -225px;
  }
  &.brooklyn-nets {
    background-position: -35px -150px;
  }
  &.charlotte-hornets {
    background-position: -72px -262px;
  }
  &.chicago-bulls {
    background-position: -72px -151px;
  }
  &.cleveland-cavaliers {
    background-position: -0px 32px;
  }
  &.dallas-mavericks {
    background-position: -72px -187px;
  }
  &.denver-nuggets {
    background-position: 105px -187px;
  }
  &.detroit-pistons {
    background-position: 0 -113px;
  }
  &.golden-state-warriors {
    background-position: 70px -113px;
  }
  &.houston-rockets {
    background-position: 0 -150px;
  }
  &.indiana-pacers {
    background-position: 32px -225px;
  }
  &.los-Angeles-clippers {
    background-position: 32px -188px;
  }
  &.los-angeles-lakers {
    background-position: 0 -185px;
  }
  &.memphis-grizzlies {
    background-position: 32px -364px;
  }
  &.miami-heat {
    background-position: 70px -76px;
  }
  &.milwaukee-bucks {
    background-position: 105px -262px;
  }
  &.minnesota-timberwolves {
    background-position: 0 -76px;
  }
  &.new-orleans-pelicans {
    background-position: 32px -148px;
  }
  &.new-york-knicks {
    background-position: 105px -224px;
  }
  &.oklahoma-city-thunder {
    background-position: 32px -110px;
  }
  &.orlando-magic {
    background-position: 106px -331px;
  }
  &.philadelphia-76ers {
    background-position: -74px 0;
  }
  &.phoenix-suns {
    background-position: 32px -332px;
  }
  &.portland-trail-blazers {
    background-position: 70px -38px;
  }
  &.sacramento-kings {
    background-position: 70px -228px;
  }
  &.san-antonio-spurs {
    background-position: 0 -36px;
  }
  &.toronto-raptors {
    background-position: -110px 0;
  }
  &.utah-jazz {
    background-position: 105px -108px;
  }
  &.washington-wizards {
    background-position: 0px 0px;
  }
  &.seattle-sonics {
    background-position: -37px 0;
  }
`
