import React from 'react'
import { toast } from 'react-toastify'
import gql from 'graphql-tag'

import TeamRegistryModal from './TeamRegistryModal'
import { handleResult, errorOn } from '../../../utils/results'
import {
  Team,
  useCheckinTeamForTournamentMutation as useCheckinTeamForTournament,
  useUncheckinTeamFromTournamentMutation as useUncheckinTeamFromTournament,
} from '../../../types/graphql'

gql`
  mutation CheckinTeamForTournament($teamId: ID!, $tournamentId: ID!) {
    createNewCheckin(teamId: $teamId, tournamentId: $tournamentId) {
      success
      value
      errors {
        field
        message
      }
    }
  }

  mutation UncheckinTeamFromTournament($teamId: ID!, $tournamentId: ID!) {
    deleteTournamentCheckin(teamId: $teamId, tournamentId: $tournamentId) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface ITournamentCheckinModalProps {
  tournamentId: string
  name: string
  teams: Team[]
  hideModal: () => void
}

const TournamentCheckinModal: React.FC<ITournamentCheckinModalProps> = ({
  tournamentId,
  name,
  teams,
  hideModal,
}) => {
  const [checkinTeamForTournament] = useCheckinTeamForTournament()
  const [uncheckinTeamFromTournament] = useUncheckinTeamFromTournament()

  const mutationOptions = (team: Team) => ({
    variables: {
      teamId: team.id,
      tournamentId,
    },
    refetchQueries: ['TournamentDetail'],
  })

  const checkinTeam = async (team: Team) => {
    try {
      const { data } = await checkinTeamForTournament(mutationOptions(team))
      handleResult({
        result: data!.createNewCheckin,
        onSuccess: _value => {
          return toast.success(`${team.name} has been checked-in for ${name}!`, {
            containerId: 'temporary',
          })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const uncheckinTeam = async (team: Team) => {
    try {
      const { data } = await uncheckinTeamFromTournament(mutationOptions(team))
      handleResult({
        result: data!.deleteTournamentCheckin,
        onSuccess: _value => {
          return toast.success(`${team.name} has been unchecked-in for ${name}!`, {
            containerId: 'temporary',
          })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <TeamRegistryModal
      isCheckin
      tournamentId={tournamentId}
      teams={teams}
      name={name}
      hideModal={hideModal}
      onToggleOn={checkinTeam}
      onToggleOff={uncheckinTeam}
    />
  )
}

export default TournamentCheckinModal
