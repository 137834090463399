import gql from 'graphql-tag'
import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'

import { AdminSeasonInfoQuery } from '../../types/fragments'
import { allSportSlugs } from '../../utils/sportUtils'
import { enumFormat } from '../../utils/enumUtils'
import { getSeasonYears } from './AdminSeasonInfo/utils'
import { parseAsNumber, parseAsEnum } from '../../utils/formUtils'
import { paths } from '../../utils/Routes'
import { relevantByPosition } from '../../utils/divisionUtils'
import { SeasonAdminContext } from '../../contexts/SeasonAdminContext'
import { SeasonRegions, SeasonSchedules } from '../../types/graphql'
import { useDivisionsAttrs } from '../../hooks/divisionsAttrs'
import { validSeriesLenghts } from '../../utils/matchUtils'

import DataFetcher from './DataFetcher'
import GamesFilter from './GamesFilter'
import { TabInput, TabSelect } from '../atoms/FormPieces'
import TabbedDatePicker from '../atoms/TabbedDatePicker'
import ToggleSwitch from '../atoms/ToggleSwitch'
import Card from '../atoms/Card'
import { BaseButton } from '../atoms/Buttons'
import ImageUploader, { IImageFields } from '../molecules/ImageUploader'
import { PopulateSeasonMutation, usePopulateSeasonMutation } from '../../types/graphql'
import AdminSafetySwitch from '../atoms/AdminSafetySwitch'

export const UPSERT_SEASON_INFO = gql`
  mutation UPSERT_SEASON_INFO(
    $banner: String
    $defaultSeriesLength: Int!
    $divisionName: String!
    $firstMatchDate: String!
    $id: ID
    $isPremiere: Boolean!
    $isPremium: Boolean!
    $maximumTeamSize: Int
    $minimumTeamSize: Int
    $name: String!
    $price: Int!
    $regionChoice: SeasonRegions!
    $registrationCloseDate: String!
    $registrationOpenDate: String!
    $rosterLockDate: String
    $scheduleTypeChoice: SeasonSchedules!
    $seasonEndDate: String!
    $seasonYear: String!
    $sponsorImage: String
    $sport: String!
    $published: Boolean!
  ) {
    editSeasonBasicInfo(
      banner: $banner
      defaultSeriesLength: $defaultSeriesLength
      divisionName: $divisionName
      firstMatchDate: $firstMatchDate
      id: $id
      isPremiere: $isPremiere
      isPremium: $isPremium
      maximumTeamSize: $maximumTeamSize
      minimumTeamSize: $minimumTeamSize
      name: $name
      price: $price
      regionChoice: $regionChoice
      registrationCloseDate: $registrationCloseDate
      registrationOpenDate: $registrationOpenDate
      rosterLockDate: $rosterLockDate
      scheduleTypeChoice: $scheduleTypeChoice
      seasonEndDate: $seasonEndDate
      seasonYear: $seasonYear
      sponsorImage: $sponsorImage
      sport: $sport
      published: $published
    ) {
      season {
        id
        ...AdminSeasonInfoQuery
      }
      errors {
        field
        message
      }
    }
  }
  ${AdminSeasonInfoQuery}
`

gql`
  mutation populateSeason($seasonId: ID!) {
    populateSeason(seasonId: $seasonId) {
      success
      errors {
        field
        message
      }
    }
  }
  ${AdminSeasonInfoQuery}
`

const AdminSeasonInfo: React.FC = () => {
  const allSports = allSportSlugs
  const regions = Object.values(SeasonRegions)
  const scheduleTypes = Object.values(SeasonSchedules)

  const seasonYears = getSeasonYears()
  // TODO: dynamically get the current year + next year's season years
  const querySeasonYears = ['2019-2020'].concat(seasonYears[0])
  const { divisionsAttrs } = useDivisionsAttrs({ seasonYears: querySeasonYears })
  const divisions = relevantByPosition(divisionsAttrs)
  const history = useHistory()

  const {
    bannerName,
    bannerUrl,
    defaultSeriesLength,
    divisionName,
    firstMatchDate,
    isEditable,
    isPremiere,
    isPremium,
    isPublished,
    maxTeamSize,
    minTeamSize,
    name,
    price,
    regCloseDate,
    regionChoice,
    regOpenDate,
    rosterLockDate,
    scheduleTypeChoice,
    seasonEndDate,
    seasonId,
    seasonYear,
    selectedSport,
    setBannerName,
    setBannerUrl,
    setDefaultSeriesLength,
    setDivisionName,
    setFirstMatchDate,
    setIsPremiere,
    setIsPremium,
    setIsPublished,
    setMaxTeamSize,
    setMinTeamSize,
    setName,
    setPrice,
    setRegCloseDate,
    setRegionChoice,
    setRegOpenDate,
    setRosterLockDate,
    setScheduleTypeChoice,
    setSeasonEndDate,
    setSeasonYear,
    setSelectedSport,
    setSponsoredImageName,
    setSponsoredImageUrl,
    sponsoredImageName,
    sponsoredImageUrl,
    updateFromQuery,
  } = useContext(SeasonAdminContext)
  // If our Context contains a SeasonId then we are not creating a season anymore.
  const isCreate = seasonId ? false : true
  useEffect(() => {
    if (!divisions.length || divisionName) {
      return
    }

    const defaultDivision = divisions[0]
    setDivisionName(defaultDivision.name)
  }, [divisions, setDivisionName, divisionName])
  useEffect(() => {
    isCreate && seasonId && history.push(paths.manageSeason(seasonId))
  }, [seasonId, history, isCreate])

  //the seasonYears array contains the 4 valid years for new seasons,
  // however in order to support old seasons we need to add the season's
  // seasonYear if its not in the list
  if (seasonYear && seasonYears.indexOf(seasonYear) < 0) {
    seasonYears.unshift(seasonYear)
  }

  const [updateBasicInfo, { loading: basicInfoLoading, error }] = useMutation(UPSERT_SEASON_INFO, {
    onCompleted(data) {
      if (data.editSeasonBasicInfo) {
        updateFromQuery(data.editSeasonBasicInfo)
        toast.success('Your Season has been saved!', { containerId: 'temporary' })
      } else {
        toast.error('Error updating season.', { containerId: 'temporary' })
      }
    },
  })

  const sportClickHandler = (clickedSport: string) => {
    if (selectedSport.includes(clickedSport)) {
      setSelectedSport('')
    } else setSelectedSport(clickedSport)
  }

  const checkData = () => {
    if (selectedSport === '') {
      toast.error('Please select a sport for this season.', { containerId: 'temporary' })
      return false
    }
    if (!name) {
      toast.error('Please enter a name for this season.', { containerId: 'temporary' })
      return false
    }
    if (regCloseDate <= regOpenDate) {
      toast.error('Please choose a registration closing date that is after registration opens.', {
        containerId: 'temporary',
      })
      return false
    }
    if (rosterLockDate && rosterLockDate <= regCloseDate) {
      toast.error('Please choose a roster lock date after registration closes.', {
        containerId: 'temporary',
      })
      return false
    }
    if (firstMatchDate <= regCloseDate) {
      toast.error('Please choose a first match date that is after registration closes.', {
        containerId: 'temporary',
      })
      return false
    }
    if (firstMatchDate <= regCloseDate) {
      toast.error('Please choose a first match date that is after registration closes.', {
        containerId: 'temporary',
      })
      return false
    }
    if (seasonEndDate <= firstMatchDate) {
      toast.error('Please choose a season end date that is after the first matches.', {
        containerId: 'temporary',
      })
      return false
    }
    if (maxTeamSize < minTeamSize) {
      toast.error('Max team size must be at least as big as the Min team size.', {
        containerId: 'temporary',
      })
      return false
    }
    return true
  }

  const handleErrors = (errors: _.Dictionary<string>) => {
    toast.error('An unforseen error has occured. Please contact Paul.')
  }

  const handlePopulateComplete = (data?: PopulateSeasonMutation) => {
    if (data && data.populateSeason) {
      toast.success('Your Season will be populated shortly.')
    }
  }

  const [populateSeason, { loading: populateLoading }] = usePopulateSeasonMutation()

  const handlePopulateSeason = async () => {
    if (!loading) {
      try {
        const variables = {
          seasonId: seasonId,
        }

        const { data } = await populateSeason({ variables })
        handlePopulateComplete(data)
      } catch (err) {
        handleErrors(err.errors)
      }
    }
  }

  const handleSubmit = () => {
    const validData = checkData()
    if (validData && !loading) {
      updateBasicInfo({
        variables: {
          banner: bannerUrl,
          defaultSeriesLength: Number(defaultSeriesLength),
          divisionName,
          firstMatchDate: firstMatchDate,
          id: seasonId,
          isPremiere: isPremiere,
          isPremium: isPremium,
          maximumTeamSize: Number(maxTeamSize),
          minimumTeamSize: Number(minTeamSize),
          name: name,
          price: Number(price) * 100,
          regionChoice: regionChoice,
          registrationCloseDate: regCloseDate,
          registrationOpenDate: regOpenDate,
          rosterLockDate: rosterLockDate,
          scheduleTypeChoice: scheduleTypeChoice,
          seasonEndDate: seasonEndDate,
          seasonYear: seasonYear,
          sponsorImage: sponsoredImageUrl,
          sport: selectedSport,
          published: isPublished,
        },
      })
    }
  }

  const setSponsoredImage = ({ name, dataUrl }: IImageFields) => {
    setSponsoredImageUrl(dataUrl)
    setSponsoredImageName(name)
  }

  const setBannerImage = ({ name, dataUrl }: IImageFields) => {
    setBannerUrl(dataUrl)
    setBannerName(name)
  }

  const loading = populateLoading || basicInfoLoading

  return (
    <DataFetcher error={error} loading={loading}>
      <Box>
        <Text mb={4}>
          <h3>Edit Basic Season Info</h3>
        </Text>
        <Text mb={7}>
          <p>Select sport and edit user facing season details.</p>
        </Text>
        <Text mb={3}>
          <h3>Sport</h3>
        </Text>
        <GamesFilter
          sportSlugs={seasonId ? [selectedSport] : allSports}
          selectedSports={[selectedSport]}
          sportClickHandler={sportClickHandler}
        />
        <Text mt={7} mb={3}>
          <h3>Season Details</h3>
        </Text>
        <Card notch={true} notchSize={20} p="4rem 2rem">
          <TabInput
            type="text"
            label={'Season name'}
            placeholder={'enter season name'}
            value={name}
            onChange={setName}
          />
          {/* row 1 */}
          <Flex mt={4} alignItems="center" width="auto" justifyContent="space-between" mx={-2}>
            <Box width={1} px={2}>
              <TabSelect
                label={'season'}
                value={seasonYear}
                onChange={setSeasonYear}
                disabled={!isEditable}
              >
                {seasonYears.map((year: string) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </TabSelect>
            </Box>
            <Box width={1} px={2}>
              <TabbedDatePicker
                disabled={!isEditable}
                selectedDate={regOpenDate}
                setSelectedDate={setRegOpenDate}
                label={'registration opens'}
              />
            </Box>
            <Box width={1} px={2}>
              <TabbedDatePicker
                disabled={!isEditable}
                selectedDate={regCloseDate}
                setSelectedDate={setRegCloseDate}
                label={'registration closes'}
              />
            </Box>
          </Flex>
          {/* row 2 */}
          <Flex mt={8} flexWrap="wrap" justifyContent="space-between" mx={-2}>
            <Box mx={2} width={'30%'}>
              <TabbedDatePicker
                disabled={!isEditable}
                selectedDate={rosterLockDate}
                setSelectedDate={setRosterLockDate}
                label={'rosters lock'}
              />
            </Box>
            <Box mx={2}>
              <TabbedDatePicker
                disabled={!isEditable}
                selectedDate={firstMatchDate}
                setSelectedDate={setFirstMatchDate}
                label={'first matches'}
              />
            </Box>
            <Box mx={2} mt={[5, 5, 0, 0]}>
              <TabbedDatePicker
                disabled={!isEditable}
                selectedDate={seasonEndDate}
                setSelectedDate={setSeasonEndDate}
                label={'season ends'}
              />
            </Box>
          </Flex>
          {/* row 3 */}
          <Flex mt={8} flexWrap="wrap" justifyContent="space-between" mx={-2}>
            <Box width={'30%'} mx={2}>
              <TabSelect label={'League Tier'} value={divisionName} onChange={setDivisionName}>
                {divisions.map(({ name }: { name: string }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </TabSelect>
            </Box>
            <Box width={'30%'} mx={2} mt={-6}>
              <TabSelect
                label={'Schedule Type'}
                value={scheduleTypeChoice}
                disabled={!isEditable}
                onChange={parseAsEnum(SeasonSchedules)(setScheduleTypeChoice)}
              >
                {scheduleTypes.map(schedule => (
                  <option key={schedule} value={schedule}>
                    {enumFormat(schedule)}
                  </option>
                ))}
              </TabSelect>
            </Box>
            <Box width={'30%'} mx={2} mt={-6}>
              <TabSelect
                label={'Region'}
                value={regionChoice}
                onChange={parseAsEnum(SeasonRegions)(setRegionChoice)}
              >
                {regions.map(region => (
                  <option key={region} value={region}>
                    {enumFormat(region)}
                  </option>
                ))}
              </TabSelect>
            </Box>
          </Flex>
          <Flex mt={8} flexWrap="wrap" justifyContent="space-between" mx={-2}>
            <Box width={'30%'} mx={2}>
              <TabInput
                type="number"
                label={'Minimum Team Size'}
                placeholder={'enter a value'}
                min={'1'}
                value={minTeamSize}
                disabled={!isEditable}
                onChange={parseAsNumber(setMinTeamSize)}
              />
            </Box>
            <Box width={'30%'} mx={2}>
              <TabInput
                type="number"
                label={'Max Team Size'}
                min={'1'}
                placeholder={'enter a value'}
                value={maxTeamSize}
                disabled={!isEditable}
                onChange={parseAsNumber(setMaxTeamSize)}
              />
            </Box>
            <Box width={'30%'} mx={2} mt={-6}>
              <TabSelect
                label={'Default series length'}
                value={defaultSeriesLength}
                disabled={!isEditable}
                onChange={parseAsNumber(setDefaultSeriesLength)}
              >
                {validSeriesLenghts.map(length => (
                  <option key={'Bo' + length} value={length}>
                    {'Best of ' + length}
                  </option>
                ))}
              </TabSelect>
            </Box>
          </Flex>
          <Flex mt={8} flexWrap="wrap" justifyContent="space-between" mx={-2}>
            <Box width={'30%'} mx={1}>
              <TabInput
                type="number"
                label={'Ala carte price (USD)'}
                placeholder={'enter a value'}
                value={price}
                step={'0.01'}
                disabled={!isEditable}
                onChange={parseAsNumber(setPrice)}
              />
            </Box>
            <Box width={'33%'} mx={1}>
              <ToggleSwitch
                disabled={!isEditable}
                handleChange={() => setIsPremiere(!isPremiere)}
                checked={isPremiere}
              />
              <h5>Premiere Season</h5>
              <p>Premiere seasons only allow 1 team per university</p>
            </Box>
            <Box width={'33%'} mx={1}>
              <ToggleSwitch
                disabled={!isEditable}
                handleChange={() => setIsPremium(!isPremium)}
                checked={isPremium}
              />
              <h5>Premium Season</h5>
            </Box>
            <ImageUploader
              mt={6}
              mb={6}
              title="Sponsor Info"
              imageUrl={sponsoredImageUrl}
              imageName={sponsoredImageName}
              onImageChanged={setSponsoredImage}
              imageFieldTitle="banner"
              guidance={
                'For best results use a square JPG, GIF, or PNG that is at least 100px by 100px.'
              }
            />
            <ImageUploader
              title="Season Hero Image"
              imageUrl={bannerUrl}
              imageName={bannerName}
              imageFieldTitle="banner"
              onImageChanged={setBannerImage}
            />
          </Flex>
        </Card>
        <Flex flexDirection="row" alignItems="center" justifyContent="space-between" width="auto">
          <Box>
            <BaseButton variant="primary" mt={4} mr={4} onClick={handleSubmit}>
              save season
            </BaseButton>
            <AdminSafetySwitch
              onClick={handlePopulateSeason}
              thisPropTriggersAReRender={Math.random()}
            >
              populate season
            </AdminSafetySwitch>
          </Box>
          <Box>
            <Flex alignItems="center">
              <h5>Published</h5>
              <ToggleSwitch
                handleChange={() => setIsPublished(!isPublished)}
                checked={isPublished}
              />
            </Flex>
          </Box>
        </Flex>
      </Box>
    </DataFetcher>
  )
}
export default AdminSeasonInfo
