import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { styled, Theme, ThemeColors } from '../../../styles/settings/theme'

import { paths } from '../../../utils/Routes'
import { adjustedFullWithShortMonth, adjustedTimeOnly } from '../../../utils/dateTimeFormats'
import SportLogo from '../../atoms/SportLogo'
import { handleResult, errorOn } from '../../../utils/results'
import {
  MatchCarouselMatchInfoFragment as Match,
  MatchCarouselTeamInfoFragment as Team,
  useCheckinTeamForMatchMutation as useCheckinTeamForMatch,
} from '../../../types/graphql'
import { BaseButton } from '../../atoms/Buttons'
import LoadingSpinner from '../../atoms/LoadingSpinner'

gql`
  mutation CheckinTeamForMatch($teamId: ID!, $matchId: ID!) {
    createNewMatchCheckin(teamId: $teamId, matchId: $matchId) {
      success
      value
      errors {
        field
        message
      }
    }
  }

  mutation UncheckinTeamFromMatch($teamId: ID!, $matchId: ID!) {
    deleteMatchCheckin(teamId: $teamId, matchId: $matchId) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface IMatchCardProps {
  match: Match
}

interface IMatchCardBorderProps {
  borderColor: ThemeColors
}

interface IMatchCardBodyProps {
  homeTeam: Team | null | undefined
  awayTeam: Team | null | undefined
  match: Match
}
interface IMatchCardHeaderProps {
  match: Match
}

const MatchCardHeaderWrapper = styled(Box)`
  padding-bottom: 0.75rem;
  border-bottom: 2px solid ${props => props.theme.colors.darkmiddlegray};
`

const MatchCardWrapper = styled(Box)<IMatchCardBorderProps>`
  padding: 1rem;
  background-color: ${props => props.theme.colors.white};
  border: ${props => `2px solid ${props.theme.colors[props.borderColor]}`};
  & a {
    color: ${props => props.theme.colors.primarynavy};
    :hover {
      color: ${props => props.theme.colors.primaryblue};
    }
`

const MatchCardHeader: React.FC<IMatchCardHeaderProps> = ({ match }) => {
  const sport = match.sport // TODO
  // const divisionLogo = 'Logo' // TODO

  return (
    <MatchCardHeaderWrapper>
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box width={1}>
          <Flex alignItems="center">
            <SportLogo width="2rem" height="2rem" sport={sport} />
            <Text textAlign="left" ml={3} mb={-2}>
              <h6>{adjustedFullWithShortMonth(match.scheduledDate)}</h6>
            </Text>
          </Flex>
        </Box>
        <Box width={1}>
          <Text textAlign="right" mb={-2}>
            <h6>{adjustedTimeOnly(match.scheduledDate)}</h6>
          </Text>
        </Box>
      </Flex>

      <Box width={1 / 2}>
        <Flex flexDirection="row" alignItems="center" justifyContent="flex-end">
          &nbsp;
          {/* TODO: <Text textAlign={'right'}>{divisionLogo}</Text> */}
        </Flex>
      </Box>
    </MatchCardHeaderWrapper>
  )
}

const Vs: React.FC = () => {
  return (
    <Text pr={2} color={Theme.colors.orange}>
      <h6>VS</h6>
    </Text>
  )
}

const MatchCardBody: React.FC<IMatchCardBodyProps> = ({ homeTeam, awayTeam, match }) => {
  const [checkinTeamForMatch, { loading }] = useCheckinTeamForMatch()

  const myTeamId =
    homeTeam && homeTeam.currentUserOnTeam
      ? homeTeam.id
      : awayTeam && awayTeam.currentUserOnTeam
      ? awayTeam.id
      : ''

  const mutationOptions = (teamId: string) => ({
    variables: {
      teamId: teamId,
      matchId: match.id,
    },
    refetchQueries: ['Dashboard'],
  })

  const checkinTeam = async () => {
    try {
      const { data } = await checkinTeamForMatch(mutationOptions(myTeamId))
      handleResult({
        result: data!.createNewMatchCheckin,
        onSuccess: _value => {
          return toast.success(`Your team has been checked-in for your match!`, {
            containerId: 'temporary',
          })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Flex flexDirection="column" mt={4}>
      <Box width={1}>
        <Flex flexDirection="row" justifyContent="space-between">
          {homeTeam ? (
            <Link to={paths.teamDetail(homeTeam.id)}>
              <Text mt={2} mb={1}>
                <h6>{homeTeam.name}</h6>
              </Text>
            </Link>
          ) : (
            'Bye'
          )}
          <Text textAlign="right">
            {/* Stats are out for now, but leaving this in for the future */}
            {/* {homeTeam.selectedSeasonStats.wins}-{homeTeam.selectedSeasonStats.losses} */}
          </Text>
        </Flex>
      </Box>

      <Box width={1}>
        <Flex flexDirection="row" justifyContent="space-between">
          <Text textAlign="left">
            <Flex mt={2}>
              <Vs />
              <h6>
                {awayTeam ? <Link to={paths.teamDetail(awayTeam.id)}>{awayTeam.name}</Link> : 'Bye'}
              </h6>
            </Flex>
          </Text>
          <Text textAlign="right">
            {/* Stats are out for now, but leaving this in for the future */}
            {/* {awayTeam.selectedSeasonStats.wins}-{awayTeam.selectedSeasonStats.losses} */}
          </Text>
        </Flex>
        {dayjs()
          .subtract(15, 'm')
          .isBefore(match.scheduledDate) &&
        match.checkinAvailable &&
        !match.currentUserCheckedIn &&
        match.currentUserIsTeamMember ? (
          <BaseButton
            mt={4}
            width={[1, 1]}
            variant="wideSand"
            onClick={() => checkinTeam()}
            fullWidth
          >
            Checkin for match
          </BaseButton>
        ) : (
          <Link to={paths.matchDetail(match.id)}>
            {/* TODO: Confirm that match rescheduling variant is working properly */}
            <BaseButton
              mt={4}
              variant={
                dayjs().isAfter(match.scheduledDate) && match.currentUserCanReport
                  ? 'wideBlue'
                  : match.currentUserCheckedIn && match.currentUserIsTeamMember
                  ? 'wideSand'
                  : match.rescheduleDate
                  ? 'reportMatch'
                  : 'wideNavy'
              }
              fullWidth
            >
              {dayjs().isAfter(match.scheduledDate) && match.currentUserCanReport
                ? 'report match'
                : match.currentUserCheckedIn && match.currentUserIsTeamMember
                ? 'checked in'
                : match.rescheduleDate
                ? 'reschedule match'
                : 'preview match'}
            </BaseButton>
          </Link>
        )}
      </Box>
    </Flex>
  )
}

export const MatchCard: React.FC<IMatchCardProps> = ({ match }) => {
  const { homeTeam, awayTeam } = match

  return (
    <MatchCardWrapper
      borderColor={
        dayjs().isBefore(match.scheduledDate) &&
        match.checkinAvailable &&
        match.currentUserIsTeamMember
          ? 'sand'
          : 'white'
      }
      px={2}
      width={1}
    >
      <MatchCardHeader match={match} />
      <MatchCardBody homeTeam={homeTeam} awayTeam={awayTeam} match={match} />
    </MatchCardWrapper>
  )
}
