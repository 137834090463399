import React, { useState } from 'react'
import { oc } from 'ts-optchain'
import gql from 'graphql-tag'
import dayjs from 'dayjs'
import { Text, Flex } from 'rebass'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useTheme } from 'emotion-theming'

import {
  Container,
  Separator,
  SettingsBlock,
  SettingsContent,
  SettingsHeader,
  SettingsModal,
  StyledSettings,
} from '../atoms/SettingsPieces'
import { siteSwitcher } from '../../utils/sites'
import { useUserAccountSettingsQuery, User } from '../../types/graphql'
import BackButton from '../atoms/BackButton'
import ChangeAccountSettingsField from '../molecules/ChangeAccountSettingsField'
import ChangePassword from '../molecules/ChangePassword'
import DataFetcher from '../organisms/DataFetcher'
import SectionTitle from '../atoms/SectionTitle'

gql`
  query userAccountSettings($id: ID) {
    userSettings(id: $id) {
      username
      subscriptionEndDate
      personalEmail
      isPremiumUser
    }
  }
`

// TODO: uncomment once user subscription reimplemented

// const UPDATE_USER_SUBSCRIPTION = gql`
//   mutation updateUserSubscription($paidForPremium: Boolean!, $id: id) {
//     updateUser(paidForPremium: $paidForPremium, id: $id) {
//       success
//       errors {
//         field
//         message
//       }
//     }
//   }
// `

interface IURLParams {
  playerId?: string
}

const AccountSettings: React.FC<RouteComponentProps<IURLParams>> = ({ match: { params } }) => {
  const playerId = oc(params).playerId('')
  const { colors } = useTheme()
  const [user, setUser] = useState({} as User)

  const { data, error, loading: userAccountSettingsLoading } = useUserAccountSettingsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: playerId },
    onCompleted: data => {
      if (data.userSettings) {
        setUser(data.userSettings as User)
      }
    },
  })

  const loading = userAccountSettingsLoading

  // TODO: uncomment once user subscription reimplemented. May need to be refactored.

  // const [updateUserSubscription, { loading: updateUserSubscriptionLoading }] = useMutation(
  //   UPDATE_USER_SUBSCRIPTION,
  //   {
  //     refetchQueries: ['userAccountSettings'],
  //     onError(error) {
  //       toast.error(handleErrors(error), { containerId: 'temporary' })
  //     },
  //     onCompleted({ updateUser }) {
  //       if (updateUser.errors) {
  //         toast.error(updateUser.errors[0].message, { containerId: 'temporary' })
  //       }
  //     },
  //   },
  // )

  // const loading = userAccountSettingsLoading || updateUserSubscriptionLoading

  // const [showUserSubscripionModal, hideUserSubscripionModal] = useModal(() => (
  //   <SettingsModal hideModal={hideUserSubscripionModal}>
  //     <UserSubscripion
  //       isUpdate
  //       onSelectAccountType={(accountType: number) => {
  //         hideUserSubscripionModal()
  //         updateUserSubscription({
  //           variables: {
  //             paidForPremium: !!accountType,
  //             id: playerId
  //           },
  //         })
  //       }}
  //     />
  //   </SettingsModal>
  // ))

  const [showChangePasswordModal, hideChangePasswordModal] = useModal(() => (
    <SettingsModal hideModal={hideChangePasswordModal}>
      <ChangePassword onFormSubmitted={hideChangePasswordModal} />
    </SettingsModal>
  ))

  const [showChangeUsernameModal, hideChangeUsernameModal] = useModal(
    () => (
      <SettingsModal hideModal={hideChangeUsernameModal}>
        <ChangeAccountSettingsField
          fieldName="username"
          currentField={oc(user).username('')}
          onFormSubmitted={(username: string) => {
            setUser({ ...user, username })
            hideChangeUsernameModal()
          }}
          userId={playerId}
        />
      </SettingsModal>
    ),
    [user],
  )

  const [showChangePersonalEmailModal, hideChangePersonalEmailModal] = useModal(
    () => (
      <SettingsModal hideModal={hideChangePersonalEmailModal}>
        <ChangeAccountSettingsField
          fieldName="personalEmail"
          fieldType="email"
          currentField={oc(user).personalEmail('')}
          onFormSubmitted={(personalEmail: string) => {
            setUser({ ...user, personalEmail })
            hideChangePersonalEmailModal()
          }}
          userId={playerId}
          fieldLabel="email"
        />
      </SettingsModal>
    ),
    [user],
  )

  if (data && (!data.userSettings || !data.userSettings.username)) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  const parsedSubscriptionEndDate =
    user.subscriptionEndDate && dayjs(user.subscriptionEndDate).format('MMM D, YYYY')

  return (
    <DataFetcher error={error} loading={loading}>
      <Container>
        {playerId && <BackButton />}
        <SectionTitle text="account settings" align="left" />
        <SettingsBlock>
          <SettingsHeader text="basic information" />
          <SettingsContent>
            <Flex my={4} justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Text color={colors.black} mr={2}>
                  <h5>username: </h5>
                </Text>
                <Text color={colors.darkgray}>
                  <p>{user.username}</p>
                </Text>
              </Flex>
              <StyledSettings onClick={showChangeUsernameModal} />
            </Flex>
            <Separator />
            <Flex my={4} justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Text color={colors.black} mr={2}>
                  <h5>
                    {siteSwitcher({
                      college: 'contact email',
                      esports: 'email',
                    })}
                    :{' '}
                  </h5>
                </Text>
                <Text color={colors.darkgray}>
                  <p>{user.personalEmail}</p>
                </Text>
              </Flex>
              <StyledSettings onClick={showChangePersonalEmailModal} />
            </Flex>
            <Separator />
            <Flex mt={4} mb={2} justifyContent="space-between" alignItems="center">
              <Flex>
                <Text color={colors.black} mr={2}>
                  <h5>password: </h5>
                </Text>
                <Text color={colors.darkgray}>
                  <p>********</p>
                </Text>
              </Flex>
              <StyledSettings onClick={showChangePasswordModal} />
            </Flex>
          </SettingsContent>
        </SettingsBlock>
        <SettingsBlock>
          {/* <SettingsHeader
            text="account type"
            onSettingsClick={!user.isPremiumUser ? showUserSubscripionModal : undefined}
          /> */}

          {/* TODO: uncomment the above and delete the below once premium subscriptions reimplemented */}
          <SettingsHeader text="account type" />
          <SettingsContent>
            <Flex width={1} my={4} alignItems="center">
              <Text color={colors.black} mr={2}>
                <h5>account type: </h5>
              </Text>

              <Text color={colors.darkgray}>
                <p>{user.isPremiumUser ? '  Premium Account' : '  Basic Account'}</p>
              </Text>
            </Flex>

            {/* TODO: unncomment the below and add back paymentIntent to userAccountSettings query once premium subsriptions reimplemented. */}

            {/* {user.isPremiumUser ? null : (
              <Flex width={1} mt={1} mb={4}>
                <Text color={colors.primarynavy}>
                  <p>
                    Upgrade to Premium for only{' '}
                    {data && data.paymentIntent && (
                      <SubscriptionPrice price={data.paymentIntent.amount} />
                    )}{' '}
                    per season.
                  </p>
                </Text>
              </Flex>
            )} */}

            <Separator />
            <Flex mt={4} mb={2} alignItems="center">
              <Text color={colors.black} mr={2}>
                <h5>expiration: </h5>
              </Text>
              <Text color={colors.darkgray}>
                <p>{parsedSubscriptionEndDate || '--'}</p>
              </Text>
            </Flex>
          </SettingsContent>
        </SettingsBlock>
      </Container>
    </DataFetcher>
  )
}
export default AccountSettings
