import { styled } from '../../styles/settings/theme'
import { Box } from 'rebass'

import Background from '../../assets/images/background__main.jpg'

interface IProps {
  hideBg?: boolean
  Background?: string
}

const AppWrapper = styled(Box)<IProps>`
  background-image: url('${props => (props.hideBg ? null : Background)}');
  background-size: 100vw;
  background-position: bottom 200px center;
  background-repeat: no-repeat;
  padding-top: 4rem;
`

export default AppWrapper
