import React from 'react'
import { useTheme } from 'emotion-theming'
import { FaCircle } from 'react-icons/fa'
import { Box } from 'rebass'

interface IStatusIconProps {
  color: string
}

const StatusIcon: React.FC<IStatusIconProps> = ({ color }) => {
  const { colors } = useTheme()
  return (
    <Box mr={3}>
      <FaCircle size={15} color={colors[color]} />
    </Box>
  )
}

export default StatusIcon
