import { ApolloError } from 'apollo-client'
import { useMutation } from '@apollo/react-hooks'
import React, { useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import gql from 'graphql-tag'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import {
  PendingInvitation,
  useCreateRosterEntryMutation as createRosterEntryMutation,
  useRescindPendingInviteMutation as useRescindPendingInvite,
} from '../../../types/graphql'
import { handleResult } from '../../../utils/results'
import { SettingsModal } from '../../atoms/SettingsPieces'
import TeamInvites from '../../molecules/TeamInvites'
import GamingProfiles from '../../molecules/GamingProfiles/GamingProfiles'
import {
  allFieldsMutationArguments,
  allFieldsMutationVariables,
} from '../../../utils/gamingProfilesUtils'

const ADD_GAME_FOR_INVITE = gql(String.raw`
  mutation updateUserGamingProfiles(
    $id: ID
    ${allFieldsMutationArguments}
  ) {
    updateUser(
      id: $id
      ${allFieldsMutationVariables}
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`)

interface InvitesProps {
  playerId?: string
}

const Invites: React.FC<InvitesProps> = ({ playerId }) => {
  const {
    pendingInvitations,
    sportFields,
    currentInvitation,
    errorsExist,
    setSportFields,
    setCurrentInvitation,
    setCurrentUserFormErrors,
    setCurrentUserContextLoading,
  } = useContext(CurrentUserContext)
  const [showGamingProfilesModal, setShowGamingProfilesModal] = useState(false)

  const [addGameForInvite, { loading: addGamesLoading }] = useMutation(ADD_GAME_FOR_INVITE, {
    refetchQueries: ['getCurrentUserTeamInformation'],
    variables: {
      id: playerId,
      ...sportFields,
    },
    onError: error => {
      console.log(error)
    },
    onCompleted: data => {
      handleResult({
        result: data.updateUser,
        onSuccess: () => {
          setShowGamingProfilesModal(false)
          createRosterEntry({
            variables: {
              teamPlayerInviteId: currentInvitation!.id,
            },
          })
        },
        onFailure: errors => {
          setCurrentUserFormErrors(errors)
          setCurrentUserContextLoading(false)
        },
      })
    },
  })

  const handleErrors = (errors: ApolloError) => {
    toast.error(errors.graphQLErrors[0].message, { containerId: 'temporary' })
    setCurrentUserContextLoading(false)
  }

  const [createRosterEntry, { loading: createRosterEntryLoading }] = createRosterEntryMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['getCurrentUserTeamInformation'],
    onError(errors) {
      handleErrors(errors)
    },
    onCompleted({ createRosterEntry }) {
      if (createRosterEntry.errors) {
        toast.error(createRosterEntry.errors[0].message, { containerId: 'temporary' })
      } else {
        toast.success(
          `${playerId ? 'Player has' : 'You have'} been added to ${currentInvitation!.team.name}`,
          { containerId: 'temporary' },
        )
      }
    },
  })

  const [rescindPendingInvite, { loading: rescindPendingInviteLoading }] = useRescindPendingInvite({
    awaitRefetchQueries: true,
    refetchQueries: ['getCurrentUserTeamInformation'],
    onError(errors) {
      handleErrors(errors)
    },
    onCompleted: () => {
      toast.success('Invite removed.', { containerId: 'temporary' })
    },
  })

  const handleTeamInviteResponse = (accepted: boolean, invitation: PendingInvitation) => {
    setCurrentInvitation(invitation)

    if (accepted && !invitation.playerPlaysSport) {
      setShowGamingProfilesModal(true)
    } else if (accepted) {
      createRosterEntry({
        variables: {
          teamPlayerInviteId: invitation.id,
        },
      })
    } else {
      rescindPendingInvite({
        variables: {
          id: invitation.id,
        },
      })
    }
  }

  const handleHideGamingProfilesModal = () => {
    setShowGamingProfilesModal(false)
    setCurrentUserFormErrors({})
    setSportFields({})
  }

  const loading = createRosterEntryLoading || rescindPendingInviteLoading || addGamesLoading

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (errorsExist) setShowGamingProfilesModal(true)
  }, [errorsExist])

  return pendingInvitations.length ? (
    <>
      <TeamInvites
        pendingInvitations={pendingInvitations as PendingInvitation[]}
        handleRepsonse={handleTeamInviteResponse}
      />
      {showGamingProfilesModal && (
        <SettingsModal hideModal={handleHideGamingProfilesModal}>
          <GamingProfiles
            headerText={`you need to add ${currentInvitation!.team.sportSlug}`}
            subheaderText={`In order to join this team you need to add ${
              currentInvitation!.team.sportSlug
            } to your profile. Please fill in the below needed information.`}
            submitButtonText="add game and accept"
            onFormSubmitted={() => {
              addGameForInvite()
            }}
            sports={[currentInvitation!.team.sportSlug!]}
          />
        </SettingsModal>
      )}
    </>
  ) : null
}
export default Invites
