import React, { useContext, useEffect } from 'react'
import { Text, Flex, Box } from 'rebass'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { handleResult } from '../../../utils/results'
import { SiteSwitch, collegeSite } from '../../../utils/sites'
import { startCase } from '../../../utils/strings'
import { useUpdateUserAboutFieldsMutation as useUpdateUserAbout } from '../../../types/graphql'

import {
  SettingsBlock,
  Separator,
  SettingsModal,
  SettingsHeader,
  SettingsContent,
} from '../../atoms/SettingsPieces'
import UserAboutSection from '../../molecules/UserAboutSection/UserAboutSection'
import UniversityFields from '../../molecules/UserAboutSection/UniversityFields'

gql`
  mutation UpdateUserAboutFields(
    $id: ID
    $name: String
    $email: String
    $universityId: ID
    $universityMajorId: ID
    $graduationYear: Int
    $discordUsername: String
    $isConfirmed: Boolean
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      universityId: $universityId
      universityMajorId: $universityMajorId
      graduationYear: $graduationYear
      discordUsername: $discordUsername
      isConfirmed: $isConfirmed
    ) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface UserAboutFieldsProps {
  playerId?: string
}

const UserAboutFields: React.FC<UserAboutFieldsProps> = ({ playerId }) => {
  const { colors } = useTheme()
  const {
    fullName,
    discordUsername,
    university,
    universityId,
    email,
    universityMajor,
    universityMajorId,
    graduationYear,
    showUserAboutModal,
    isConfirmed,
    setCurrentUserContextLoading,
    setCurrentUserFormErrors,
    resetUserToStateFromQuery,
    setShowUserAboutModal,
  } = useContext(CurrentUserContext)

  const baseUserAboutFields = {
    name: fullName,
    discordUsername,
  }

  const userAboutFields = collegeSite
    ? {
        ...baseUserAboutFields,
        school: university.name,
        schoolEmail: email,
        major: universityMajor && universityMajor.name,
        graduationYear,
      }
    : baseUserAboutFields

  const onCancel = () => {
    setShowUserAboutModal(false)
    resetUserToStateFromQuery()
  }

  const [updateUser, { loading }] = useUpdateUserAbout({
    refetchQueries: ['userProfileSettings'],
    variables: {
      id: playerId,
      name: fullName,
      email,
      universityId,
      graduationYear: Number(graduationYear),
      discordUsername,
      universityMajorId,
      isConfirmed,
    },
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      handleResult({
        result: data.updateUser,
        onSuccess: () => {
          setCurrentUserContextLoading(false)
          setShowUserAboutModal(false)
        },
        onFailure: (errors) => {
          setCurrentUserContextLoading(false)
          setCurrentUserFormErrors(errors)
        },
      })
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <SettingsBlock>
      <SettingsHeader text="about" onSettingsClick={() => setShowUserAboutModal(true)} />
      <SettingsContent>
        {Object.keys(userAboutFields).map((field: string, index: number) => (
          <Box key={field}>
            <Flex my={4} alignItems="center">
              <Text color={colors.balck} mr={2}>
                <h5>{startCase(field)}:</h5>
              </Text>
              <Text color={colors.darkgray}>
                <p>{userAboutFields[field]}</p>
              </Text>
            </Flex>
            {index !== Object.keys(userAboutFields).length - 1 && <Separator />}
          </Box>
        ))}
      </SettingsContent>
      {showUserAboutModal && (
        <SettingsModal hideModal={onCancel}>
          <UserAboutSection
            headerText="update about section"
            submitButtonText="save"
            cancelButtonText="cancel"
            onCancel={onCancel}
            onSubmit={updateUser}
          >
            <SiteSwitch esports={null} college={<UniversityFields />} />
          </UserAboutSection>
        </SettingsModal>
      )}
    </SettingsBlock>
  )
}
export default UserAboutFields
