import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Box, Flex } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { BaseButton } from '../atoms/Buttons'
import AsciiEmoji from '../atoms/AsciiEmoji'
import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'

const StyledWideContainer = styled(WideContainer)`
  text-align: center;
  flex-direction: column;
  justify-content: center;
`

const StyledFlex = styled(Flex)`
  border: 2px solid ${props => props.theme.colors.red};
  background: white;
`

interface IContainerProps {
  background?: boolean
}

const StyledWideContent = styled(WideContent)<IContainerProps>`
  margin: '0 auto';
  padding: ${props => (props.background ? '0' : '5rem 0 4rem')};

  p,
  h2 {
    color: ${props => (props.background ? 'white' : 'black')};
  }

  ${Mq.md} {
    max-width: 100%;
  }
`

const TableFlipEmoji = styled(AsciiEmoji)`
  font-size: 1.5rem;

  ${Mq.sm} {
    font-size: ${props => props.theme.fontSizes[2]};
  }
`

interface IErrorBoxProps {
  background?: boolean
  title: ReactNode
  linkTo: string
  linkText: ReactNode
}

const ErrorBox: React.FC<IErrorBoxProps> = ({ title, linkTo, linkText, background, ...props }) => {
  return (
    <StyledWideContent background={background}>
      <StyledWideContainer>
        <StyledFlex flexDirection="column" py={8}>
          <TableFlipEmoji mb={7}>¯\_(ツ)_/¯</TableFlipEmoji>

          <Box mb={5}>
            <h2>{title}</h2>
          </Box>

          <Box mb={6} width={[1, 4 / 5, 3 / 4, 2 / 3, 1 / 2]} mx={'auto'}>
            {props.children}
          </Box>

          <Link to={linkTo}>
            <BaseButton>{linkText}</BaseButton>
          </Link>
        </StyledFlex>
      </StyledWideContainer>
    </StyledWideContent>
  )
}
export default ErrorBox
