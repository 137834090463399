import { useTheme } from 'emotion-theming'
import { upperCase } from 'lodash'
import React from 'react'
import { FiCrosshair } from 'react-icons/fi'
import { GiCrossedSwords } from 'react-icons/gi'
import { Box, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { rawNumPlayers } from '../../utils/sportUtils'
import { RosterEntry } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import Alert from '../atoms/Alert'

interface IRosterBoxProps {
  starterLineup: RosterEntry[]
  substituteLineup: RosterEntry[]
  sportSlug: string
  showSubstitutes: boolean
  teamId: string
  currentUserIsEligible: boolean
  applyToTeam: () => void
  currentUserApplicationPending: boolean
  teamName: string
  isRecruiting: boolean
}

const StyledHr = styled.hr`
  background-color: ${props => props.theme.colors.middlegray};
  border: none;
  height: 2px;
`

const RosterBox: React.FunctionComponent<IRosterBoxProps> = ({
  starterLineup,
  substituteLineup,
  sportSlug,
  showSubstitutes,
  teamId,
  currentUserIsEligible,
  applyToTeam,
  currentUserApplicationPending,
  teamName,
  isRecruiting,
}) => {
  const { colors } = useTheme()
  const showApplicationInformation =
    (isRecruiting && currentUserIsEligible) || currentUserApplicationPending

  let number =
    +rawNumPlayers(sportSlug) - (showSubstitutes ? substituteLineup.length : starterLineup.length)
  const isStartersFull = number > 0

  return !showSubstitutes && showApplicationInformation ? (
    <Box bg={colors.white} pb={3}>
      <StyledHr />
      <Alert borderColor={colors.middlegray} mx={['auto']} my={['3rem']}>
        <FiCrosshair color={colors.secondaryblue} size={24} />
        <Text color={colors.darkgray} my={3}>
          {currentUserApplicationPending ? (
            <h6>your application is pending</h6>
          ) : isStartersFull ? (
            <h6>This team is recruiting teammates!</h6>
          ) : (
            <h6>This team's starting lineup is full!</h6>
          )}
        </Text>
        <Text mb={4}>
          {currentUserApplicationPending ? (
            <p>
              Your CSL profile has been shared with the coordinator for{' '}
              <strong>{upperCase(teamName)}</strong>. You will be notified if and when you are
              accepted.
            </p>
          ) : isStartersFull ? (
            <p>
              This team needs <strong>{number} more players.</strong> Apply today so you can join
              their starting lineup in time for the Tournament.
            </p>
          ) : (
            <p>
              This team already has a full starting lineup. If you would like to still join the
              team, feel free to apply as a substitute.
            </p>
          )}
        </Text>
        {!currentUserApplicationPending && (
          <BaseButton variant="secondary" mt={2} ml={2} onClick={applyToTeam}>
            Apply for Team
          </BaseButton>
        )}
      </Alert>
    </Box>
  ) : showApplicationInformation && showSubstitutes && !substituteLineup.length ? (
    <Box bg={colors.white} pb={3}>
      <StyledHr />
      <Alert borderColor={colors.middlegray} mx={['auto']} my={['3rem']}>
        <GiCrossedSwords color={colors.sand} size={24} />
        <Text color={colors.darkgray} my={3}>
          <h6>no substitutes present</h6>
        </Text>

        <p>
          This team does not currently have any substitutes. If the starting lineup is full, you can
          still apply for any team as a substitute.
        </p>
      </Alert>
    </Box>
  ) : null
}

export default RosterBox
