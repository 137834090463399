import React from 'react'
import { Box, BoxProps, Flex } from 'rebass'
import { styled } from '../../../styles/settings/theme'
import TabLabel from '../../atoms/TabLabel'
import { CustomTabSelect } from '../../atoms/FormPieces'
import { RosterEntry, Team } from '../../../types/graphql'
import { BaseButton } from '../../atoms/Buttons'
import { rawNumPlayers } from '../../../utils/sportUtils'

const RoundedBox = styled(Box)<BoxProps>``
const InputBox = styled(Box)<BoxProps>`
  position: relative;
`

interface ReportingProps {
  team: Team
  isHomeTeam: boolean
  isWinningTeam: boolean
  setWinner: (value: any) => void
  teamResults: { [key: number]: { [key: string]: string } }
  changePlayer: (e: React.ChangeEvent, isHomeTeam: boolean, i: number) => void
}

const RlReportingTable = ({
  team,
  isHomeTeam,
  isWinningTeam,
  setWinner,
  teamResults,
  changePlayer,
}: ReportingProps) => {
  const num = Number(rawNumPlayers(team.sportSlug))
  const handleScore = () => {
    setWinner(team.id)
  }
  return (
    <>
      <Box width={[1, 1, 1, 1 / 2]} mt={5} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
        <Flex alignItems="center" justifyContent="space-between">
          <h3>{team.truncatedName}</h3>
          <BaseButton variant={isWinningTeam ? 'selected' : 'select'} onClick={handleScore}>
            select as winner
          </BaseButton>
        </Flex>
        <RoundedBox mt={4} p={3} bg="backgroundgray">
          {[...Array(num)].map((_el, i: number) => (
            <InputBox mt={4} pb={i === 2 ? 0 : 2}>
              <TabLabel text="player" />
              <CustomTabSelect
                onChange={e => {
                  changePlayer(e, isHomeTeam, i)
                }}
                value={teamResults[i].name}
                name={'name'}
              >
                {team.activeRosterEntries &&
                  team.activeRosterEntries.map((entry: RosterEntry) => (
                    <option key={entry.id}>{entry.player && entry.player.username}</option>
                  ))}
              </CustomTabSelect>
            </InputBox>
          ))}
        </RoundedBox>
      </Box>
    </>
  )
}
export default RlReportingTable
