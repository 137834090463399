import React from 'react'
import { Box, Flex, Link } from 'rebass'

import { currentUserIsAdmin } from '../../utils/admins'
import { paths } from '../../utils/Routes'
import { Team } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import TournamentAdminMessage from '../molecules/TournamentAdminMessage'
import TournamentStatusBox from './TournamentStatusBox/TournamentStatusBox'

interface ITournamentHeroCTAsProps {
  name: string
  sport: string
  tournamentId: string
  currentUserRegisteredTeams: Team[]
  firstMatchDate: string
  registrationOpenDate: string
  registrationCloseDate: string
  currentUserNumberOfEligibleTeams: number
  checkinLength?: number
}

const TournamentHeroCTAs: React.FC<ITournamentHeroCTAsProps> = ({
  name,
  sport,
  tournamentId,
  currentUserRegisteredTeams,
  firstMatchDate,
  registrationOpenDate,
  registrationCloseDate,
  currentUserNumberOfEligibleTeams,
  checkinLength,
}) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Flex
      flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column']}
      ml="auto"
      mr={['auto', 'auto', 'auto', 0]}
      flex={1}
      justifyContent={['space-between', 'space-between', 'space-between', 'flex-end']}
      alignItems={['center', 'center', 'center', 'flex-end']}
      mt={[6, 6, 6, 0]}
    >
      {currentUserIsAdmin() && (
        <>
          <Link href={paths.manageTournament(tournamentId)}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <BaseButton width="100%" mt={[4, 4, 4, 4]} variant="primary">
                Edit tournament settings
              </BaseButton>
            </Animation>
          </Link>

          <Box mt={[6]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
            >
              <TournamentAdminMessage tournamentId={tournamentId} />
            </Animation>
          </Box>
        </>
      )}
      <TournamentStatusBox
        name={name}
        sport={sport}
        tournamentId={tournamentId}
        currentUserRegisteredTeams={currentUserRegisteredTeams}
        checkinLength={checkinLength}
        firstMatchDate={firstMatchDate}
        registrationOpenDate={registrationOpenDate}
        registrationCloseDate={registrationCloseDate}
        currentUserNumberOfEligibleTeams={currentUserNumberOfEligibleTeams}
      />
    </Flex>
  )
}

export default TournamentHeroCTAs
