import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { oc } from 'ts-optchain'

import { TeamProvider } from '../../contexts/TeamContext'
import TeamDetailContent from '../organisms/TeamDetailContent'
import TeamDetailQueryProvider from '../organisms/TeamDetailQueryProvider'

export interface IURLParams {
  teamId?: string
  page?: string
  pageSize?: string
}

interface IProps extends RouteComponentProps<IURLParams> {
  customStyles: any
}

const Team: React.FC<IProps> = ({ match: { params } }) => {
  const teamId = oc(params).teamId('')

  return (
    <TeamProvider>
      <TeamDetailQueryProvider teamId={teamId}>
        <TeamDetailContent />
      </TeamDetailQueryProvider>
    </TeamProvider>
  )
}

export default Team
