import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import _, { without } from 'lodash'
import React, { useState } from 'react'
import { GiCrossedSwords } from 'react-icons/gi'
import { Box, Flex, Text } from 'rebass'

import { useLazyQuery, useQuery } from '@apollo/react-hooks'

import { useSportSelector } from '../../hooks/sportSelector'
import { ClickEvent } from '../../types/aliases'
import { SportSlugs, Tournament } from '../../types/graphql'
import { SiteSwitch, siteSwitcher } from '../../utils/sites'
import { allSportSlugs } from '../../utils/sportUtils'
import { stringToEnum } from '../../utils/typeCoercions'
import Alert from '../atoms/Alert'
import { BaseButton } from '../atoms/Buttons'
import Card from '../atoms/Card'
import Content from '../atoms/Content'
import LoadingSpinner from '../atoms/LoadingSpinner'
import CreateTournamentBox from '../molecules/CreateTournamentBox'
import Filters from '../molecules/Filters'
import DataFetcher from '../organisms/DataFetcher'
import EsportsTournaments from '../organisms/EsportsTournaments'
import GamesFilter from '../organisms/GamesFilter'
import TournamentDetailHero from '../organisms/TournamentDetailHero'
import TournamentIndexHero from '../organisms/TournamentIndexHero'

const GET_TOURNAMENTS_INFO = gql`
  query PlayoffsList($featuredTournamentId: ID!) {
    playedSports {
      slug
    }
    tournament(id: $featuredTournamentId) {
      id
      name
      sport
      firstMatchDate
      numberOfTeams
      style
      registrationCloseDate
      registrationOpenDate
    }
  }
`

const GET_TOURNAMENTS = gql`
  query Tournaments($sports: [String!], $state: String!, $first: Int, $after: String) {
    tournamentsConnection(sports: $sports, state: $state, first: $first, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          sport
          banner
          firstMatchDate
          numberOfTeams
          style
          registrationCloseDate
          registrationOpenDate
          decided
          platform {
            id
            title
          }
        }
      }
    }
  }
`

const sports = allSportSlugs
const states = ['all', 'active', 'registering', 'upcoming', 'past']

const TournamentDetail: React.FC = () => {
  const { colors } = useTheme()
  const sportNamesToSportSlugs = (sportNames: string[]) => {
    const maybeSportSlugs = sportNames.map(sportSlug =>
      stringToEnum<SportSlugs>(SportSlugs, sportSlug),
    )
    return _.compact(maybeSportSlugs)
  }

  const [selectedSports, handleClickedSport, setSelectedSports] = useSportSelector(
    sportNamesToSportSlugs(sports),
  )
  const [selectedState, setSelectedState] = useState(
    siteSwitcher({
      college: 'registering',
      esports: 'all',
    }),
  )
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)
  const [loadingPagination, setLoadingPagination] = useState(false)
  const [
    getTournaments,
    {
      data: tournamentsData,
      error: tournamentsDataError,
      loading: tournamentsDataLoading,
      fetchMore,
    },
  ] = useLazyQuery(GET_TOURNAMENTS, {
    variables: {
      sports: selectedSports,
      state: selectedState,
      first: 5,
    },
  })

  const {
    data: tournamentsInfoData,
    error: tournamentsInfoError,
    loading: tournamentsInfoDataLoading,
  } = useQuery(GET_TOURNAMENTS_INFO, {
    variables: {
      featuredTournamentId: parseInt(process.env.REACT_APP_FEATURED_TOURNAMENT_ID!),
    },
    onCompleted: data => {
      if (data && data.playedSports && data.playedSports.length) {
        const playedSports = sports.filter(sport =>
          data.playedSports.map((s: any) => s.slug).includes(sport),
        )
        if (playedSports && playedSports.length) {
          setSelectedSports(sportNamesToSportSlugs(playedSports))
        }
      }
      getTournaments()
    },
  })

  const handlePagination = () => {
    setLoadingPagination(true)
    fetchMore({
      variables: {
        after: tournamentsData.tournamentsConnection.pageInfo.endCursor,
        first: 10,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.tournamentsConnection.edges
        const pageInfo = fetchMoreResult.tournamentsConnection.pageInfo

        setLoadingPagination(false)
        return newEdges.length
          ? {
              tournamentsConnection: {
                __typename: previousResult.tournamentsConnection.__typename,
                edges: [...previousResult.tournamentsConnection.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult
      },
    })
  }

  const tournaments =
    tournamentsData && tournamentsData.tournamentsConnection.edges.map((e: any) => e.node)
  const loading = tournamentsInfoDataLoading || tournamentsDataLoading
  const error = tournamentsInfoError || tournamentsDataError

  if (tournaments && !firstLoadComplete) {
    // Lets us know if there is already cached tournamentData from a previous render, in which case
    // GET_TOURNAMENTS will not be called and we only need to show the page-level loader while
    // GET_TOURNAMENTS_INFO is loading. Otherwise, the page-level loader will show until GET_TOURNAMENTS_INFO
    // has completed and a first call of GET_TOURNAMENTS has completed so we have tournaments data on the initial page load
    // as opposed to showing the loader twice.
    setFirstLoadComplete(true)
  }

  return (
    <DataFetcher error={error} loading={!firstLoadComplete}>
      <TournamentIndexHero
        title="Tournaments"
        subTitle={siteSwitcher({
          college: 'ENROLLMENT BEGINS AUGUST 26   |   Play for fun or for scholarship prizes',
          esports: 'Browse and enroll in upcoming tournaments below',
        })}
        height="18rem"
      />
      {/* {tournamentsInfoData && tournamentsInfoData.tournament && (
        <TournamentIndexHero tournament={tournamentsInfoData.tournament} featured={true} />
      )} */}
      <Content>
        {tournamentsInfoData && tournamentsInfoData.tournament && (
          <>
            <Box mb={6}>
              <GamesFilter
                sportSlugs={sports}
                unplayedSports={[]}
                selectedSports={selectedSports}
                sportClickHandler={handleClickedSport}
              />
            </Box>
            {sessionStorage.getItem('csl_user_is_admin') === 'true' && (
              <CreateTournamentBox
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
            )}
            <Flex mb={5} justifyContent="space-between" alignItems="center" flexWrap="wrap">
              <Text>
                <h2>
                  <SiteSwitch college={`${selectedState} tournaments`} esports="Open Tournaments" />
                </h2>
              </Text>
              <Filters
                selectedFilter={selectedState}
                filters={siteSwitcher({ college: states, esports: without(states, 'past') })}
                setSelectedFilter={(_e: ClickEvent, value: string) => setSelectedState(value)}
              />
            </Flex>
          </>
        )}
        {loading ? (
          <Box mt={7}>
            <LoadingSpinner />
          </Box>
        ) : tournaments && tournaments.length ? (
          <>
            <SiteSwitch
              college={
                <Flex flexDirection="column" alignItems="center">
                  {tournaments.map((tournament: Tournament) => (
                    <TournamentDetailHero
                      key={tournament.id!}
                      tournament={tournament}
                      featured={false}
                    />
                  ))}
                </Flex>
              }
              esports={<EsportsTournaments tournaments={tournaments} />}
            />
            {tournamentsData && tournamentsData.tournamentsConnection.pageInfo.hasNextPage ? (
              !loadingPagination ? (
                <Flex mt={4}>
                  <BaseButton onClick={handlePagination}>See More</BaseButton>
                </Flex>
              ) : (
                <LoadingSpinner />
              )
            ) : null}
          </>
        ) : (
          <Card notch={true} py={6} px={8}>
            <Alert borderColor={colors.sand} mx="auto" minWidth="100%" maxWidth="100%">
              <Box py={4}>
                <GiCrossedSwords color={colors.sand} size={40} />
                <h2>No {selectedState} tournaments</h2>
                <Text maxWidth={600} margin="auto">
                  <p>
                    We don’t have any {selectedState} tournaments at this moment. Please check back
                    soon to browse {selectedState} tournaments.
                  </p>
                </Text>
              </Box>
            </Alert>
          </Card>
        )}
      </Content>
    </DataFetcher>
  )
}

export default TournamentDetail
