import React, { useState } from 'react'

import { SetState } from '../types/aliases'
import {
  AdminTournamentRegistryInfoFragment as TournamentRegistry,
  AdminTournamentRoundInfoFragment as Round,
  AdminTournamentTeamInfoFragment as Team,
  BracketTypes,
  TournamentAdminQuery,
  TournamentTypes,
} from '../types/graphql'

interface TournamentState {
  name: string
  setName: SetState<string>
  tournamentId: string
  setTournamentId: SetState<string>
  selectedSport: string[]
  setSelectedSport: SetState<string[]>
  regOpenDate: Date
  setRegOpenDate: SetState<Date>
  regCloseDate: Date
  setRegCloseDate: SetState<Date>
  firstMatchDate: Date
  setFirstMatchDate: SetState<Date>
  checkinLength: number
  setCheckinLength: SetState<number>
  isPremium: boolean
  setIsPremium: SetState<boolean>
  multibracketCapable: boolean
  setMultibracketCapable: SetState<boolean>
  description: string
  setDescription: SetState<string>
  bracketType: BracketTypes
  setBracketType: SetState<BracketTypes>
  maxTeams: number
  setMaxTeams: SetState<number>
  tournamentType: TournamentTypes
  setTournamentType: SetState<TournamentTypes>
  seasonId: string
  setSeasonId: SetState<string>
  seasonName: string
  setSeasonName: SetState<string>
  seasonYear: string
  setSeasonYear: SetState<string>
  feederOptions: string[]
  setFeederOptions: SetState<string[]>
  feederName: string
  setFeederName: SetState<string>
  feederId: string
  setFeederId: SetState<string>
  price: number
  setPrice: SetState<number>
  address: string
  setAddress: SetState<string>
  geoState: string
  setGeoState: SetState<string>
  code: string
  setCode: SetState<string>
  existingTeamsWithSeeds: Team[] | undefined
  setTeamsWithSeeds: SetState<Team[] | undefined>
  teams: Team[] | undefined
  setTeams: SetState<Team[] | undefined>
  updateFromQuery: (tournament: TournamentAdminQuery['tournament']) => void
  teamSize: number
  setTeamSize: SetState<number>
  lineupsDue: number
  setLineupsDue: SetState<number>
  seriesLength: number
  setSeriesLength: SetState<number>
  bannerUrl: string | undefined
  setBannerUrl: SetState<string | undefined>
  bannerName: string | undefined
  setBannerName: SetState<string | undefined>
  sponsoredImageUrl: string | undefined
  sponsoredImageName: string | undefined
  setSponsoredImageUrl: SetState<string | undefined>
  setSponsoredImageName: SetState<string | undefined>
  isPublished: boolean
  setIsPublished: SetState<boolean>
  rounds: Round[] | undefined
  setRounds: SetState<Round[] | undefined>
  registries: TournamentRegistry[] | undefined
  setRegistries: SetState<TournamentRegistry[] | undefined>
  platformId: string | undefined
  setPlatformId: SetState<string | undefined>
  checkedInTeamIds: String[] | undefined
  setCheckedInTeamIds: SetState<String[] | undefined>
  desiredBrackets: number
  setDesiredBrackets: SetState<number>
}

interface IProps {
  children?: any
}

const defaultTournamentState: TournamentState = {
  name: '',
  setName: (): void => {},
  tournamentId: '',
  setTournamentId: (): void => {},
  selectedSport: [],
  setSelectedSport: (): void => {},
  regOpenDate: new Date(),
  setRegOpenDate: (): void => {},
  regCloseDate: new Date(),
  setRegCloseDate: (): void => {},
  firstMatchDate: new Date(),
  setFirstMatchDate: (): void => {},
  checkinLength: 0,
  setCheckinLength: (): void => {},
  isPremium: false,
  setIsPremium: (): void => {},
  multibracketCapable: false,
  setMultibracketCapable: (): void => {},
  description: '',
  setDescription: (): void => {},
  bracketType: BracketTypes.SingleElim,
  setBracketType: (): void => {},
  maxTeams: 0,
  setMaxTeams: (): void => {},
  tournamentType: TournamentTypes.Normal,
  setTournamentType: (): void => {},
  seasonId: '',
  setSeasonId: (): void => {},
  seasonName: '',
  setSeasonName: (): void => {},
  seasonYear: '',
  setSeasonYear: (): void => {},
  feederName: '',
  setFeederName: (): void => {},
  feederOptions: [],
  setFeederOptions: (): void => {},
  feederId: '',
  setFeederId: (): void => {},
  price: 0,
  setPrice: (): void => {},
  address: '',
  setAddress: (): void => {},
  geoState: '',
  setGeoState: (): void => {},
  code: '',
  setCode: (): void => {},
  existingTeamsWithSeeds: [],
  setTeamsWithSeeds: (): void => {},
  updateFromQuery: (): void => {},
  teams: [],
  setTeams: (): void => {},
  teamSize: 0,
  setTeamSize: (): void => {},
  seriesLength: 0,
  setSeriesLength: (): void => {},
  lineupsDue: 0,
  setLineupsDue: (): void => {},
  bannerUrl: '',
  bannerName: '',
  setBannerUrl: (): void => {},
  setBannerName: (): void => {},
  sponsoredImageUrl: '',
  sponsoredImageName: '',
  setSponsoredImageUrl: (): void => {},
  setSponsoredImageName: (): void => {},
  isPublished: false,
  setIsPublished: (): void => {},
  rounds: [],
  setRounds: (): void => {},
  registries: [],
  setRegistries: (): void => {},
  platformId: '',
  setPlatformId: (): void => {},
  checkedInTeamIds: [],
  setCheckedInTeamIds: (): void => {},
  desiredBrackets: 0,
  setDesiredBrackets: (): void => {},
}
export const TournamentProvider = ({ children }: IProps) => {
  const [name, setName] = useState('')
  const [tournamentId, setTournamentId] = useState('')
  const [selectedSport, setSelectedSport] = useState([''])
  const [regOpenDate, setRegOpenDate] = useState(new Date())
  const [regCloseDate, setRegCloseDate] = useState(new Date())
  const [firstMatchDate, setFirstMatchDate] = useState(new Date())
  const [description, setDescription] = useState('')
  const [checkinLength, setCheckinLength] = useState(0)
  const [isPremium, setIsPremium] = useState(false)
  const [multibracketCapable, setMultibracketCapable] = useState(false)
  const [bannerUrl, setBannerUrl] = useState<string>()
  const [bannerName, setBannerName] = useState<string>()
  const [sponsoredImageUrl, setSponsoredImageUrl] = useState<string>()
  const [sponsoredImageName, setSponsoredImageName] = useState<string>()
  const [isPublished, setIsPublished] = useState(false)
  const [platformId, setPlatformId] = useState<string>()

  //logistics
  const [bracketType, setBracketType] = useState(BracketTypes.SingleElim)
  const [maxTeams, setMaxTeams] = useState(0)
  const [tournamentType, setTournamentType] = useState(TournamentTypes.Normal)
  const [seasonId, setSeasonId] = useState('')
  const [seasonName, setSeasonName] = useState('')
  const [seasonYear, setSeasonYear] = useState('')
  const [feederOptions, setFeederOptions] = useState(['none'])
  const [feederName, setFeederName] = useState('')
  const [feederId, setFeederId] = useState('')
  const [price, setPrice] = useState(0)
  const [teamSize, setTeamSize] = useState(0)
  const [seriesLength, setSeriesLength] = useState(0)
  const [lineupsDue, setLineupsDue] = useState(0)
  const [rounds, setRounds] = useState<Round[]>()
  const [registries, setRegistries] = useState<TournamentRegistry[]>()
  const [desiredBrackets, setDesiredBrackets] = useState(0)
  //irl
  const [address, setAddress] = useState('')
  const [geoState, setGeoState] = useState('')
  const [code, setCode] = useState('')
  //teams
  const [teams, setTeams] = useState<Team[]>()
  const [existingTeamsWithSeeds, setTeamsWithSeeds] = useState<Team[]>()
  const [checkedInTeamIds, setCheckedInTeamIds] = useState<String[]>()

  const updateFromQuery = (data: TournamentAdminQuery['tournament']) => {
    const tournament = data

    if (tournament) {
      if (tournament.id) setTournamentId(tournament.id)
      if (tournament.name) setName(tournament.name)
      if (tournament.description) setDescription(tournament.description)
      if (tournament.registrationOpenDate) setRegOpenDate(new Date(tournament.registrationOpenDate))
      if (tournament.registrationCloseDate)
        setRegCloseDate(new Date(tournament.registrationCloseDate))
      if (tournament.firstMatchDate) setFirstMatchDate(new Date(tournament.firstMatchDate))
      if (tournament.sport) setSelectedSport([tournament.sport])
      if (tournament.checkinLength) setCheckinLength(tournament.checkinLength)
      if (tournament.isPremium) setIsPremium(tournament.isPremium)
      if (tournament.multibracketCapable) setMultibracketCapable(tournament.multibracketCapable)
      if (tournament.adminTournamentTeams) setTeams(tournament.adminTournamentTeams)
      if (tournament.adminTournamentTeams) setTeamsWithSeeds(tournament.adminTournamentTeams)
      if (tournament.styleChoice) setBracketType(tournament.styleChoice)
      if (tournament.typeChoice) setTournamentType(tournament.typeChoice)
      if (tournament.numberOfTeams) setMaxTeams(tournament.numberOfTeams)
      if (tournament.season) setSeasonId(tournament.season.id)
      if (tournament.minTeamSize) setTeamSize(tournament.minTeamSize)
      if (tournament.defaultSeriesLength) setSeriesLength(tournament.defaultSeriesLength)
      if (tournament.banner) {
        setBannerUrl(tournament.banner)
        setBannerName(tournament.banner.split('/').pop())
      }
      if (tournament.sponsorImage) {
        setSponsoredImageUrl(tournament.sponsorImage)
        setSponsoredImageName(tournament.sponsorImage.split('/').pop())
      }
      if (tournament.desiredBrackets) setDesiredBrackets(tournament.desiredBrackets)
      if (tournament.rounds) setRounds(tournament.rounds)
      if (tournament.published) setIsPublished(tournament.published)
      if (tournament.tournamentRegistries) setRegistries(tournament.tournamentRegistries)
      if (tournament.platform && tournament.platform.id) setPlatformId(tournament.platform.id)
      if (tournament.checkedInTeams) setCheckedInTeamIds(tournament.checkedInTeams.map(t => t.id))
    }
  }

  return (
    <TournamentContext.Provider
      value={{
        updateFromQuery,
        name,
        setName,
        tournamentId,
        setTournamentId,
        selectedSport,
        setSelectedSport,
        regOpenDate,
        setRegOpenDate,
        regCloseDate,
        setRegCloseDate,
        firstMatchDate,
        setFirstMatchDate,
        checkinLength,
        setCheckinLength,
        isPremium,
        setIsPremium,
        description,
        setDescription,
        bracketType,
        setBracketType,
        maxTeams,
        setMaxTeams,
        tournamentType,
        setTournamentType,
        seasonId,
        setSeasonId,
        seasonName,
        setSeasonName,
        seasonYear,
        setSeasonYear,
        feederOptions,
        setFeederOptions,
        feederName,
        setFeederName,
        feederId,
        setFeederId,
        price,
        setPrice,
        address,
        setAddress,
        geoState,
        setGeoState,
        code,
        setCode,
        existingTeamsWithSeeds,
        setTeamsWithSeeds,
        teams,
        setTeams,
        seriesLength,
        setSeriesLength,
        teamSize,
        setTeamSize,
        lineupsDue,
        setLineupsDue,
        sponsoredImageUrl,
        sponsoredImageName,
        setSponsoredImageUrl,
        setSponsoredImageName,
        bannerUrl,
        bannerName,
        setBannerUrl,
        setBannerName,
        isPublished,
        setIsPublished,
        rounds,
        setRounds,
        registries,
        setRegistries,
        platformId,
        setPlatformId,
        checkedInTeamIds,
        setCheckedInTeamIds,
        multibracketCapable,
        setMultibracketCapable,
        desiredBrackets,
        setDesiredBrackets,
      }}
    >
      {children}
    </TournamentContext.Provider>
  )
}
export const TournamentContext = React.createContext<TournamentState>(defaultTournamentState)
//export const TournamentConsumer = TournamentContext.Consumer
