import React from 'react'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import Hero from '../molecules/Hero'
import HeroImage from '../../assets/images/hero__tournaments.jpg'

const SportIcon = styled.img`
  position: absolute;
  top: 0;
  right: 1rem;
  max-width: 40px;
  ${Mq.md} {
    right: 0;
  }
`

interface IHeroInfoProps {
  title: string
  subTitle?: string
  sportIcon?: string
  height?: string
  divisionLogo?: React.ReactNode
  register?: React.ReactNode
}

const TournamentIndexHero: React.FC<IHeroInfoProps> = ({
  title,
  subTitle,
  height,
  divisionLogo,
  sportIcon,
  register,
}) => {
  return (
    <Hero
      height={height}
      titleComponent={
        <>
          <h1>{title}</h1>
          {divisionLogo}
        </>
      }
      subTitle={subTitle}
      actionsComponent={
        sportIcon && (
          <>
            <SportIcon src={sportIcon} />
            {register}
          </>
        )
      }
      image={HeroImage}
    />
  )
}

export default TournamentIndexHero
