import { AnimatePresence } from 'framer-motion'
import { props } from 'ramda'
import React, { useState } from 'react'
import { GiBrutalHelm } from 'react-icons/gi'
import { Box, Flex, FlexProps, Text } from 'rebass'

import AppBar from '@material-ui/core/AppBar'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

import { MediaQueries as Mq, styled } from '../../../styles/settings/theme'
import { currentUserIsLoggedIn } from '../../../utils/accountUtils'
import { paths } from '../../../utils/Routes'
import { currentlyInRegistration } from '../../../utils/seasonUtils'
import Logo from '../../atoms/Logo'
import StyledLink from '../../atoms/StyledLink'
import WideContainer from '../../atoms/WideContainer'
import NotificationBar from '../../molecules/NotificationBar'
import Toasts from '../../molecules/Toasts'
import Notifications from '../../organisms/Notifications'
import AdminMenu from './AdminMenu'
import MainMenu from './MainMenu'
import UserMenu from './UserMenu'

const StyledUsername = styled(Flex)`
  align-items: center;
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  color: inherit;
  margin-left: auto;
  cursor: pointer;
  transition: ${props => props.theme.transitions.fast};
  color: ${props => props.theme.colors.primarynavy};

  svg {
    margin-left: 0.5rem;
    margin-top: -0.25rem;
  }

  &:hover {
    color: ${props => props.theme.colors.primaryblue};
    cursor: pointer;
  }
`

const NavContent = styled(Flex)<FlexProps>`
  display: none;
  z-index: 2;
  border-bottom: 1px solid ${props => props.theme.colors.lightgray};
  background-color: ${props => props.theme.colors.white};
  margin-top: 0px;
  padding: 0 1rem;
  ${Mq.md} {
    display: inherit;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    height: 4rem;
    z-index: 999;
    position: relative;
    color: ${props => props.theme.colors.primarynavy};

    h5:hover {
      color: ${props => props.theme.colors.primaryblue};
    }
    .active {
      color: ${props => props.theme.colors.primaryblue};
    }
  }
  ${Mq.lg} {
    padding: 0 4rem;
  }
`

const StyledBox = styled(Box)`
  display: none;
  ${Mq.md} {
    display: inherit;
    width: 7rem;
  }
`

const StyledAppBar = styled(AppBar)`
  z-index: 999;
  width: 100vw;
  left: 0;
`

const HorizontalNavWrapper = styled(WideContainer)`
  z-index: 999;
`

interface IHorizontalNavProps {
  username: string
  userIsAdmin: boolean
  isIncompleteAccount: boolean
  userId: string
  loading: boolean
  showInviteModal: () => void
}

interface IProps {
  window?: () => Window
  children: React.ReactElement
}

const HideOnScroll: React.FC<IProps> = props => {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const HorizontalNav: React.FC<IHorizontalNavProps> = ({
  username,
  userIsAdmin,
  isIncompleteAccount,
  userId,
  loading,
  showInviteModal,
}) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleOnMouseLeave = (event: any) => {
    if (event.target.id !== 'userMenu') {
      setShowMenu(false)
    }
  }

  const CtaBanner = () => {
    const isHome = window.location.href.split('/')[3] === '' ? true : false
    return currentlyInRegistration() && isHome && !currentUserIsLoggedIn()
  }

  return (
    <HorizontalNavWrapper>
      <HideOnScroll {...props}>
        <StyledAppBar
          color="inherit"
          elevation={0}
          style={{
            top: 'auto',
            visibility: 'visible' /* hacky fix to prevent <Slide> from hiding temporary toasts */,
          }}
        >
          <NotificationBar />
          <Toolbar disableGutters>
            <NavContent onMouseLeave={handleOnMouseLeave}>
              <Flex alignItems="center">
                <StyledBox>
                  <Logo variant="dark" />
                </StyledBox>
                <MainMenu />
              </Flex>

              <Flex justifyContent="flex-end" alignItems="center">
                {username && userIsAdmin && (
                  <>
                    <Box onMouseEnter={() => setShowMenu(true)}>
                      <StyledUsername>{username}</StyledUsername>
                    </Box>
                    {showMenu && !loading && <AdminMenu closeMenu={() => setShowMenu(false)} />}
                  </>
                )}
                {username && !userIsAdmin && (
                  <>
                    <Box onMouseEnter={() => setShowMenu(true)}>
                      <StyledUsername>
                        {username}
                        <GiBrutalHelm size={24} />
                      </StyledUsername>
                      <AnimatePresence>
                        {showMenu && !loading && userId && (
                          <UserMenu
                            isIncompleteAccount={isIncompleteAccount}
                            userId={userId}
                            textAlign="right"
                            closeMenu={() => setShowMenu(false)}
                            showInviteModal={showInviteModal}
                          />
                        )}
                      </AnimatePresence>
                    </Box>
                    <Flex ml={5} alignItems="center">
                      <Notifications />
                    </Flex>
                  </>
                )}
                {!username && (
                  <Flex>
                    <StyledLink href={paths.signup()}>
                      <Text mt={1} mr={5}>
                        Sign Up
                      </Text>
                    </StyledLink>
                    <StyledLink href={paths.logIn()}>
                      <Flex alignItems="center">
                        <Text mt={1} mr={3}>
                          login
                        </Text>
                        {/* TODO: Add a rotating random icon */}
                        <GiBrutalHelm size={24} />
                      </Flex>
                    </StyledLink>
                  </Flex>
                )}
              </Flex>
            </NavContent>
          </Toolbar>
          <Toasts className={CtaBanner() ? 'with-banner' : 'standard'} />
        </StyledAppBar>
      </HideOnScroll>
    </HorizontalNavWrapper>
  )
}

export default HorizontalNav
