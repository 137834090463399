import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Text } from 'rebass'

import { css } from '@emotion/core'

import HeroImage from '../../assets/images/hero__tournament-default.jpg'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { Tournament } from '../../types/graphql'
import { paths } from '../../utils/Routes'
import { inRegistration, isUpcoming } from '../../utils/tournament'
import Card from '../atoms/Card'
import HeroContainer from '../atoms/HeroContainer'
import SportLogo from '../atoms/SportLogo'
import TournamentFacts from '../molecules/TournamentFacts'

interface TournamentDetailHeroProps {
  tournament: Tournament
  featured: Boolean
}

interface ItemContainerProps {
  outline: Boolean
}

interface FlexProps {
  height?: string
  width?: string
  alignItems?: string
}

const LabelContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.25rem;
`

const StyledFlex = styled(Flex)<FlexProps>`
  position: relative;
  z-index: 1;
  width: 100%;
`

const TournamentStatus = styled(Flex)<FlexProps>`
  h5 {
    position: absolute;
    right: 0;
    top: 1rem;
  }

  img {
    max-width: 50px;
  }

  ${Mq.md} {
    h5 {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
`

const ItemContainer = styled(Card)<ItemContainerProps>`
  width: 100%;
  cursor: pointer;
  min-width: 100%:

  > div {
    ${props =>
      props.outline &&
      css`
        border: 2px solid ${props.theme.colors.sand};
      `}
  }
`

/* TODO: uncomment when implementation ready
const WatchNowContainer = styled(Flex)`
  background: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.sand};
  height: 6.5rem;
  min-width: 16rem;
  width: 16rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.625rem;
`
*/

const TournamentDetailHero: React.FunctionComponent<TournamentDetailHeroProps> = ({
  tournament,
  featured,
}) => {
  const parseDate = (date: string) => dayjs(date).format('MMM D, YYYY')

  let history = useHistory()

  return (
    <ItemContainer
      notch={true}
      notchSize={16}
      mb={featured ? 0 : 5}
      onClick={() => history.push(paths.tournament(tournament.id))}
      outline={inRegistration(tournament)}
    >
      <HeroContainer
        height={featured ? '22.75rem' : '100%'}
        heroImage={tournament.banner || HeroImage}
        tint={'0, 0, 0 ,0.6'}
      >
        {featured && (
          <LabelContainer bg="primarynavy" justifyContent="center" alignItems="center">
            <Text color="white">
              <h5>Featured Tournament</h5>
            </Text>
          </LabelContainer>
        )}
        <StyledFlex height="100%" alignItems="flex-end">
          <Flex
            flexDirection="column"
            justifyContent={featured ? 'flex-end' : 'space-between'}
            height="100%"
            width="100%"
          >
            <TournamentStatus mb={8} justifyContent="space-between">
              <SportLogo sport={tournament.sport} />
              {!featured && (
                <Text color={inRegistration(tournament) || tournament.decided ? 'sand' : 'teal'}>
                  <h5>
                    {isUpcoming(tournament)
                      ? 'upcoming'
                      : inRegistration(tournament)
                      ? 'register now'
                      : tournament.decided
                      ? 'past'
                      : 'active'}
                  </h5>
                </Text>
              )}
            </TournamentStatus>
            <Text color="white">
              <h2>{tournament.name}</h2>
            </Text>

            <Flex mt={[0, 0, 4, 4]}>
              <TournamentFacts
                dates={parseDate(tournament.firstMatchDate)}
                teams={tournament.numberOfTeams ? tournament.numberOfTeams.toString() : undefined}
                style={tournament.style ? tournament.style : undefined}
              />
            </Flex>
          </Flex>

          {/* TODO: uncomment when implementation ready  */}
          {/* {featured && (
            <Flex flexDirection="column" minWidth="16rem">
              <Text color="white" mb={2}>
                <h3>happening today</h3>
              </Text>
              <WatchNowContainer>
                <Text textAlign="center" color="darkgray">
                  <h5>Tournament Finals!</h5>
                </Text>
                <Flex justifyContent="center">
                  <Text color="darkgray">
                    <h5>Match Begins In</h5>
                  </Text>
                  <Text ml={2} color="red">
                    <h5>23:01</h5>
                  </Text>
                </Flex>
                <BaseButton width="100%" variant="tertiary">
                  view stream
                </BaseButton>
              </WatchNowContainer>
            </Flex>
          )} */}
        </StyledFlex>
      </HeroContainer>
    </ItemContainer>
  )
}

export default TournamentDetailHero
