import React from 'react'
import { useTheme } from 'emotion-theming'
import { Flex, Text } from 'rebass'
import { CheckCircle } from 'react-feather'

import { Team } from '../../../types/graphql'

interface ITeamsListProps {
  teams: Team[]
}

const TeamsList: React.FC<ITeamsListProps> = ({ teams }) => {
  const { colors } = useTheme()
  return (
    <Flex flexDirection="column">
      {teams.map((team, index) => (
        <Flex mb={1} key={index}>
          <CheckCircle size={15} color={colors.secondarygreen} />
          <Text ml={3} color={colors.darkgray}>
            <h5>{team.name}</h5>
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}

export default TeamsList
