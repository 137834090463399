import React from 'react'
import { Box } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

interface IProps {
  children: any
  className?: string
  customWidth?: string
}

const Container = styled(Box)<IProps>`
  margin: auto;
  max-width: 1600px;
  padding: 0 1rem;
  min-width: ${props => props.customWidth};

  ${Mq.lg} {
    padding: 0 4rem;
  }
`

const WideContainer: React.FC<IProps> = ({ children, ...rest }) => (
  <Container py={[6]} {...rest}>
    {children}
  </Container>
)

export default WideContainer
