import React from 'react'
import { Link } from 'rebass'
import _ from 'lodash'
import { Settings } from 'react-feather'
import { styled } from '../../../styles/settings'

import {
  Leagues_DivisionFragment as Division,
  Leagues_SeasonFragment as Season,
  LeagueRegistrationStatuses as Status,
} from '../../../types/graphql'
import { currentUserIsAdmin } from '../../../utils/admins'
import { paths } from '../../../utils/Routes'
import { sportLogo } from '../../../utils/logoUtils'
import { withRegistrationStatus, registrationStatusCount } from '../../../utils/seasonUtils'
import DefaultBannerImage from '../../../assets/images/hero__league-index.jpg'
import DefaultLogoImage from '../../../assets/images/logos/CSL__LoaderIcon.png'
import { BaseButton } from '../../atoms/Buttons'
import ChunkyAccordionItem from '../../molecules/ChunkyAccordionItem'
import LeagueEnrollingLabel from '../../atoms/LeagueEnrollingLabel'
import {
  ChunkyAccordionListActions,
  ChunkyAccordionListItem,
  ChunkyAccordionListTitle,
} from '../../atoms/ChunkyAccordion'

const LeagueSportLogo = styled.img`
  height: 2rem;
  width: 2rem;
`

const withRelevantStatuses = (seasons: Season[]) => {
  const enrolling = withRegistrationStatus(seasons, Status.Enrolling)
  const active = withRegistrationStatus(seasons, Status.Active)
  const drafts = currentUserIsAdmin() ? withRegistrationStatus(seasons, Status.Draft) : []
  const upcoming = withRegistrationStatus(seasons, Status.Unstarted)
  const ended = withRegistrationStatus(seasons, Status.Ended)
  return enrolling.concat(active, drafts, upcoming, ended)
}

const formattedLeagueCount = (seasons: Season[]) => {
  return `${seasons.length} Leagues`
}

const formattedRegistrationStats = (seasons: Season[]) => {
  const enrollingCount = registrationStatusCount(seasons, Status.Enrolling)
  const activeCount = registrationStatusCount(seasons, Status.Active)

  const enrollingText = enrollingCount > 0 ? `${enrollingCount} Enrolling` : undefined
  const activeText = activeCount > 0 ? `${activeCount} Active` : undefined

  const havingText = [enrollingText, activeText].filter(t => t)
  return havingText.join(' - ')
}

const relevantSeasons = (seasons: Season[]) => {
  // TODO: check if there are seasons with other statuses that we should show
  const showableStatuses = currentUserIsAdmin()
    ? [Status.Active, Status.Enrolling, Status.Draft, Status.Unstarted, Status.Ended]
    : [Status.Active, Status.Enrolling, Status.Unstarted, Status.Ended]
  return seasons.filter(season => {
    return _.includes(showableStatuses, season.registrationStatus)
  })
}

interface IDivisionAccordionItemProps {
  division: Division
  isPreviousYear: boolean
}

const DivisionAccordionItem: React.FC<IDivisionAccordionItemProps> = ({
  division,
  isPreviousYear,
}) => {
  const filteredSeasons = isPreviousYear ? division.seasons : relevantSeasons(division.seasons)
  return (
    <ChunkyAccordionItem
      buttonText={'View Leagues'}
      heroImage={division.bannerUrl || DefaultBannerImage}
      heroTitle={division.name}
      logoImage={division.logoUrl || DefaultLogoImage}
      heroSubtitleLeft={
        isPreviousYear
          ? formattedLeagueCount(division.seasons)
          : formattedLeagueCount(withRelevantStatuses(division.seasons))
      }
      heroSubtitleRight={formattedRegistrationStats(division.seasons)}
      tint={'0, 0, 0 ,0.6'}
    >
      {filteredSeasons.map(
        (season, i) =>
          season.sport && (
            <ChunkyAccordionListItem key={season.id || i}>
              <LeagueSportLogo src={sportLogo(season.sport.slug)} />
              <ChunkyAccordionListActions>
                <ChunkyAccordionListTitle>
                  {currentUserIsAdmin() && (
                    <Link href={paths.manageSeason(season.id!)}>
                      <Settings />
                    </Link>
                  )}
                  <Link href={paths.league({ id: season.id! })}>
                    <h5>{season.name}</h5>
                  </Link>
                  {!isPreviousYear && (
                    <h6>
                      <LeagueEnrollingLabel status={season.registrationStatus} />
                    </h6>
                  )}
                </ChunkyAccordionListTitle>

                <Link href={paths.league({ id: season.id! })}>
                  <BaseButton variant="secondary">View League</BaseButton>
                </Link>
              </ChunkyAccordionListActions>
            </ChunkyAccordionListItem>
          ),
      )}
    </ChunkyAccordionItem>
  )
}

export default DivisionAccordionItem
