import React from 'react'
import { Flex } from 'rebass'

import { LowerLine, LowerDash, Round } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getTuples } from '../../utils/bracketDataUtils'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  part?: string
  numTeams: number
  seatList: Array<number>
}

const Round3BLosers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  // const seats = !bottomHalf ? ['148', '178'] : ['210', '240']
  // //dummy seats
  // const seat = !bottomHalf ? '148' : '178'
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)

  const seats = getTuples(halfSeats)

  return (
    <Round round="3B" shift={shift} top={bottomHalf ? '156px' : '78px'} numTeams={numTeams}>
      {seats.map(tuple => (
        <Flex>
          <BracketCouplet seats={tuple} round="3B" bottom={'280px'} numTeams={numTeams} />
          {numTeams === 32 ? (
            <LowerDash round="3B" />
          ) : (
            <LowerLine round="3B" numTeams={numTeams} />
          )}
        </Flex>
      ))}
    </Round>
  )
}

export default Round3BLosers
