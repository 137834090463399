import React from 'react'
import { BracketContainer } from '../atoms/BracketPieces'
import Round1Losers from './Round1Losers'
import Round2ALosers from './Round2ALosers'
import Round2BLosers from './Round2BLosers'
import Round3ALosers from './Round3ALosers'
import Round3BLosers from './Round3BLosers'
import Round4ALosers from './Round4ALosers'
import Round4BLosers from './Round4BLosers'
import Round5ALosers from './Round5ALosers'
import Round5BLosers from './Round5BLosers'
import Round6Losers from './Round6Losers'

interface BracketProps {
  top?: string
  seats: Array<number>
  bottomHalf?: boolean
  cacheUpdated: boolean
  numTeams: number
}

const LosersBracket: React.FunctionComponent<BracketProps> = ({
  top,
  bottomHalf,
  cacheUpdated,
  numTeams,
  seats,
}) => {
  const getRoundSize = (round: number) => {
    return numTeams / 2 ** (Math.floor(round / 2) + 2)
  }
  const getPreviousRoundSizes = (round: number): number => {
    return round === 0 ? 0 : getRoundSize(round - 1) + getPreviousRoundSizes(round - 1)
  }
  const getRoundStart = (round: number) => {
    return round === 0 ? 0 : getRoundSize(round - 1) + getPreviousRoundSizes(round - 1)
  }

  const getRoundOfSeats = (round: number) => {
    return seats.slice(getRoundStart(round), getRoundStart(round) + getRoundSize(round))
  }

  const getShift = (size: number) => {
    const getPxAmount: { [key: number]: string[] } = {
      128: [
        '30px',
        '230px',
        '430px',
        '630px',
        '860px',
        '1090px',
        '965px',
        '1200px',
        '1240px',
        '1240px',
      ],
      64: ['30px', '230px', '430px', '630px', '860px', '1090px', '1200px', '1200px'],
      32: ['100px', '300px', '500px', '700px', '930px', '1160px'],
      16: ['85px', '285px', '485px', '685px'],
      8: ['325px', '525px'],
      4: ['85px', '285px'],
    }
    return getPxAmount[size]
  }

  const getHeight = (size: number) => {
    const getPxAmount: { [key: number]: string } = {
      128: '1405px',
      64: '700px',
      32: '360px',
      16: '208px',
      8: '132px',
      4: '0px',
    }
    return getPxAmount[size]
  }

  const getWidth = (size: number) => {
    const getPxAmount: { [key: number]: string } = {
      128: '1440px',
      64: '1440px',
      32: '1440px',
      16: '1040px',
      8: '1040px',
      4: '1040px',
    }
    return getPxAmount[size]
  }

  return (
    <>
      {cacheUpdated && (
        <BracketContainer width={getWidth(numTeams)} height={getHeight(numTeams)} mt={top}>
          {numTeams >= 8 && (
            <>
              <Round1Losers
                seatList={getRoundOfSeats(0)}
                shift={getShift(numTeams)[0]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
              <Round2ALosers
                seatList={getRoundOfSeats(1)}
                shift={getShift(numTeams)[1]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
            </>
          )}
          {numTeams >= 16 && (
            <>
              <Round2BLosers
                seatList={getRoundOfSeats(2)}
                shift={getShift(numTeams)[2]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
              <Round3ALosers
                seatList={getRoundOfSeats(3)}
                shift={getShift(numTeams)[3]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
            </>
          )}
          {numTeams >= 32 && (
            <>
              <Round3BLosers
                seatList={getRoundOfSeats(4)}
                shift={getShift(numTeams)[4]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
              <Round4ALosers
                seatList={getRoundOfSeats(5)}
                shift={getShift(numTeams)[5]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
            </>
          )}
          {numTeams >= 64 && (
            <>
              <Round4BLosers
                seatList={getRoundOfSeats(6)}
                shift={getShift(numTeams)[6]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
              <Round5ALosers
                seatList={getRoundOfSeats(7)}
                shift={getShift(numTeams)[7]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
            </>
          )}
          {numTeams === 128 && (
            <>
              <Round5BLosers
                seatList={getRoundOfSeats(8)}
                shift={getShift(numTeams)[8]}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
              <Round6Losers
                shift={getShift(numTeams)[9]}
                seatList={getRoundOfSeats(9)}
                bottomHalf={bottomHalf}
                numTeams={numTeams}
              />
            </>
          )}
        </BracketContainer>
      )}
    </>
  )
}

export default LosersBracket
