import gql from 'graphql-tag'
import React, { useContext, useEffect, ReactNode } from 'react'
import { Redirect } from 'react-router-dom'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import {
  PendingInvitation,
  RosterEntry,
  useGetCurrentUserTeamInformationQuery as useGetTeamInfoQuery,
} from '../../../types/graphql'

import DataFetcher from '../../organisms/DataFetcher'

gql`
  query getCurrentUserTeamInformation($id: ID) {
    userSettings(id: $id) {
      university {
        id
      }
      pendingTeamInvitations {
        id
        gameRole
        playerPlaysSport
        team {
          id
          name
          sportSlug
          overallWins
          overallLosses
        }
      }
      pendingApplications {
        id
        gameRole
        team {
          id
          name
          sportSlug
          overallWins
          overallLosses
        }
      }
      activeRosterEntries {
        id
        gameRole
        team {
          id
          name
          sportSlug
          overallWins
          overallLosses
          currentUserIsCoordinator
        }
      }
    }
  }
  mutation createRosterEntry($teamPlayerInviteId: ID!) {
    createRosterEntry(teamPlayerInviteId: $teamPlayerInviteId) {
      errors {
        field
        message
      }
    }
  }
  mutation deleteTeamApplication($id: ID!) {
    deleteTeamApplication(id: $id) {
      errors {
        field
        message
      }
    }
  }
`

interface IProps {
  children?: ReactNode
  playerId?: string
}

const TeamSettingsQueryProvider: React.FC<IProps> = ({ children, playerId }) => {
  const {
    currentUserContextLoading,
    setUniversityId,
    setPendingApplications,
    setPendingInvitations,
    setActiveRosterEntries,
    setCurrentUserContextLoading,
  } = useContext(CurrentUserContext)

  const { data, error, loading } = useGetTeamInfoQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: playerId },
    onCompleted: data => {
      if (data.userSettings) {
        setUniversityId(data.userSettings.university.id)
        setPendingApplications(data.userSettings.pendingApplications as RosterEntry[])
        setPendingInvitations(data.userSettings.pendingTeamInvitations as PendingInvitation[])
        setActiveRosterEntries(data.userSettings.activeRosterEntries as RosterEntry[])
      }
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (data && !data.userSettings) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <DataFetcher loading={currentUserContextLoading} error={error}>
      {children}
    </DataFetcher>
  )
}

export default TeamSettingsQueryProvider
