import { Box, Flex, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React, { useState, useEffect } from 'react'

import {
  User_SearchFragment as User,
  useUserSearchLazyQuery as useUserSearch,
} from '../../types/graphql'

import { CustomInput } from '../atoms/FormPieces'
import Alert from '../atoms/Alert'
import Animation from '../atoms/Animation'
import Content from '../atoms/Content'
import HeroContainer from '../atoms/HeroContainer'
import HeroImage from '../../assets/images/hero__universities.jpg'
import UsersTable from '../molecules/UsersTable'

const USER_SEARCH = gql`
  fragment User_Search on User {
    id
    name
    email
    personalEmail
    username
    discordUsername
  }
`

gql`
  query userSearch($email: String!) {
    userSearch(email: $email) {
      ...User_Search
    }
  }
  ${USER_SEARCH}
`

const StyledInput = styled(CustomInput)`
  background-color: ${props => props.theme.colors.white};
`

const animations = {
  hidden: {
    opacity: 0,
    y: '24px',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const UserSearch: React.FC = () => {
  const { colors } = useTheme()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<User[]>([])
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)

  const [usersQuery] = useUserSearch({
    fetchPolicy: 'cache-and-network',
    variables: {
      email: searchQuery,
    },
    onCompleted: data => {
      if (!firstLoadComplete) setFirstLoadComplete(true)
      setSearchResults(data.userSearch)
    },
    onError: error => {
      console.log(error)
    },
  })

  useEffect(() => {
    if (!searchQuery) {
      return
    }
    usersQuery()
  }, [searchQuery, usersQuery])

  return (
    <>
      <HeroContainer height="18rem" heroImage={HeroImage} tint={'3,51,89,0.6'}>
        <Flex alignItems="flex-end" height="100%" pt={5}>
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4 }}
          >
            <Text color="white">
              <h1>User Search</h1>
            </Text>
          </Animation>
        </Flex>
      </HeroContainer>
      <Content>
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
        >
          <StyledInput
            mb={7}
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
            placeholder="Search user by email"
          />
        </Animation>

        {searchResults.length ? (
          <UsersTable users={searchResults} />
        ) : (
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: firstLoadComplete ? 0 : 0.6 }}
          >
            <Box p={5} backgroundColor={colors.white} mx="auto">
              <Alert borderColor={colors.middlegray} mx="auto">
                <Text>
                  <p>
                    {firstLoadComplete
                      ? 'There are no users with that email.'
                      : 'Search for a user by entering an email in the above search field.'}
                  </p>
                </Text>
              </Alert>
            </Box>
          </Animation>
        )}
      </Content>
    </>
  )
}

export default UserSearch
