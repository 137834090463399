import gql from 'graphql-tag'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'
import { TournamentContext } from '../../contexts/TournamentContext'
import { AdminTournamentInfo, AdminTournamentTeamInfo } from '../../types/fragments'
import {
  AdminTournamentRoundInfoFragment as Round,
  BracketTypes,
  EditTournamentLogisticsMutation,
  EditTournamentRoundPrototypesMutation,
  RoundMatchPrototypeInput,
  SportSeasonsInfoFragmentFragment as Season,
  TournamentTypes,
  useEditTournamentLogisticsMutation as useLogisticsMutation,
  useEditTournamentRoundPrototypesMutation as useRoundsMutation,
  useGetSeasonsQuery,
} from '../../types/graphql'
import { parseAsEnum } from '../../utils/formUtils'
import { AdminSafetySwitch } from '../atoms/AdminSafetySwitch'
import { TabInput, TabSelect } from '../atoms/FormPieces'
import ToggleSwitch from '../atoms/ToggleSwitch'
import AdminMatchSettingsTable from '../molecules/AdminMatchSettingsTable'
import DataFetcher from '../organisms/DataFetcher'

gql`
  mutation EditTournamentLogistics(
    $defaultSeriesLength: Int!
    $feedsIntoId: ID!
    $id: ID!
    $minTeamSize: Int!
    $numberOfTeams: Int!
    $priceCents: Int!
    $seasonId: ID!
    $styleChoice: BracketTypes!
    $typeChoice: TournamentTypes!
    $multibracketCapable: Boolean!
    $desiredBrackets: Int!
  ) {
    editTournamentLogistics(
      defaultSeriesLength: $defaultSeriesLength
      feedsIntoId: $feedsIntoId
      id: $id
      minTeamSize: $minTeamSize
      numberOfTeams: $numberOfTeams
      priceCents: $priceCents
      seasonId: $seasonId
      styleChoice: $styleChoice
      typeChoice: $typeChoice
      desiredBrackets: $desiredBrackets
      multibracketCapable: $multibracketCapable
    ) {
      value {
        ...AdminTournamentInfo
        adminTournamentTeams {
          ...AdminTournamentTeamInfo
        }
      }
    }
  }
  ${AdminTournamentInfo}
  ${AdminTournamentTeamInfo}
`
gql`
  mutation EditTournamentRoundPrototypes(
    $tournamentId: ID!
    $roundPrototypes: [RoundMatchPrototypeInput!]!
  ) {
    editTournamentRoundPrototypes(roundPrototypes: $roundPrototypes, tournamentId: $tournamentId) {
      value {
        ...AdminTournamentInfo
        adminTournamentTeams {
          ...AdminTournamentTeamInfo
        }
      }
    }
  }
  ${AdminTournamentInfo}
  ${AdminTournamentTeamInfo}
`

const sportSeasonsInfoFragment = gql`
  fragment sportSeasonsInfoFragment on Season {
    id
    divisionName
    seasonYear
    tournaments {
      id
      name
    }
  }
`

gql`
  query getSeasons($sport: String!) {
    sportSeasons(sport: $sport) {
      ...sportSeasonsInfoFragment
    }
  }
  ${sportSeasonsInfoFragment}
`

const AdminLogistics = () => {
  //only needed in this section (TODO: move these into context if cache query)
  const [seasonOptions, setSeasonOptions] = useState<string[]>()
  const [allSeasons, setAllSeasons] = useState<Season[]>()
  const [selectedSeason, setSelectedSeason] = useState<Season>()

  const {
    tournamentId,
    selectedSport,
    bracketType,
    setBracketType,
    maxTeams,
    setMaxTeams,
    tournamentType,
    setTournamentType,
    seasonId,
    setSeasonId,
    seasonName,
    setSeasonName,
    seasonYear,
    setSeasonYear,
    feederOptions,
    setFeederOptions,
    feederName,
    setFeederName,
    feederId,
    setFeederId,
    price,
    setPrice,
    teamSize,
    rounds,
    setRounds,
    setTeamSize,
    seriesLength,
    setSeriesLength,
    updateFromQuery,
    setMultibracketCapable,
    multibracketCapable,
    desiredBrackets,
    setDesiredBrackets,
  } = useContext(TournamentContext)

  const { error, loading: seasonsLoading } = useGetSeasonsQuery({
    variables: { sport: selectedSport[0] },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setAllSeasons(data.sportSeasons)
      const options = data.sportSeasons
        .map((season: Season) =>
          season.divisionName ? season.divisionName + ': ' + season.seasonYear : '',
        )
        .reverse()
      setSeasonOptions(options)
      //TODO: cache query data so doesn't rerun every time?
    },
  })

  const [editTournamentLogistics, { loading: logisticsLoading }] = useLogisticsMutation()
  const [editTournamentRounds, { loading: roundsLoading }] = useRoundsMutation()

  const loading = seasonsLoading || logisticsLoading || roundsLoading
  // todo: This should be BE authorative, but this list is fine for the foreseeable future.
  const seriesOptions = ['9', '7', '5', '3', '1']
  const numTeams = [128, 64, 32, 16, 8, 4]
  const types = Object.values(TournamentTypes)
  const brackets = Object.values(BracketTypes)

  const handleRoundPrototypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    round: Round,
    isWinners: boolean,
  ) => {
    const _rounds = [...rounds]
    const newGameCount = +e.target.value

    if (_rounds) {
      _rounds.map(r => (r.id === round.id ? (r.matchPrototype!.gameCount = newGameCount) : null))
      setRounds(_rounds)
    }
  }

  const handleSeason = (season: string) => {
    if (season !== 'select season') {
      const [division, year] = season.split(': ')
      //for use in select season field value
      setSeasonName(division)
      setSeasonYear(year)
      if (allSeasons) {
        //get seasonid
        const selectedSeason: Season = allSeasons.filter(
          (season: Season) => season.divisionName === division && season.seasonYear === year,
        )[0]
        //for use in useEffect:
        setSelectedSeason(selectedSeason)
        //for use in mutation:
        setSeasonId(selectedSeason.id)
      }
    }
  }

  const handleFeeder = (name: string) => {
    setFeederName(name)
    //find feeder id
    const id: any =
      selectedSeason &&
      selectedSeason.tournaments &&
      selectedSeason.tournaments
        .filter(tournament => tournament.name === name)
        .map(item => item.id)
        .join()

    setFeederId(id)
  }

  useEffect(() => {
    const feedsIntoOptions =
      selectedSeason && selectedSeason.tournaments
        ? selectedSeason.tournaments.map((tournament: any) => tournament.name)
        : feederOptions
    setFeederOptions(feedsIntoOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeason])

  useEffect(() => {
    if (seasonName === '' && seasonYear === '' && allSeasons) {
      const selectedSeason: Season = allSeasons.filter(
        (season: Season) => season.id === seasonId,
      )[0]

      if (selectedSeason && selectedSeason.divisionName && selectedSeason.seasonYear) {
        //for use in select season field value
        setSeasonName(selectedSeason.divisionName)
        setSeasonYear(selectedSeason.seasonYear)
        setSelectedSeason(selectedSeason)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonId, allSeasons, seasonName, seasonYear])

  const checkData = () => {
    if (!bracketType) {
      toast.error('Please select the bracket type for this tournament.', {
        containerId: 'temporary',
      })
      return false
    }
    if (!maxTeams) {
      toast.error('Please select the number of teams for this tournament.', {
        containerId: 'temporary',
      })
      return false
    }
    if (!tournamentType) {
      toast.error('Please select the tournament type for this tournament.', {
        containerId: 'temporary',
      })
      return false
    }
    return true
  }

  const extractRoundPrototypes = () => {
    return rounds
      ? rounds.reduce<RoundMatchPrototypeInput[]>((acc, r) => {
          acc.push({
            roundId: r.id,
            numberOfGames:
              r.matchPrototype && r.matchPrototype.gameCount
                ? r.matchPrototype.gameCount
                : seriesLength,
          })
          return acc
        }, [])
      : []
  }

  const handleErrors = (errors: _.Dictionary<string>) => {
    toast.error('An unforseen error has occured. Please contact Paul.')
  }

  const handleSubmitLogistics = async () => {
    const validData = checkData()
    if (validData && !loading) {
      try {
        let variables = {
          id: tournamentId,
          styleChoice: bracketType,
          typeChoice: tournamentType,
          minTeamSize: +teamSize,
          defaultSeriesLength: +seriesLength,
          seasonId: seasonId,
          numberOfTeams: +maxTeams,
          feedsIntoId: feederId,
          priceCents: +price,
          multibracketCapable: multibracketCapable,
          desiredBrackets: +desiredBrackets,
        }

        const { data } = await editTournamentLogistics({ variables })
        handleLogisticsComplete(data)
      } catch (err) {
        handleErrors(err.errors)
      }
    }
  }

  const handleLogisticsComplete = (data?: EditTournamentLogisticsMutation) => {
    if (data && data.editTournamentLogistics && data.editTournamentLogistics.value) {
      updateFromQuery(data.editTournamentLogistics.value)
      toast.success('Your tournament was updated.', { containerId: 'temporary' })
    }
  }

  const handleRoundsComplete = (data?: EditTournamentRoundPrototypesMutation) => {
    if (data && data.editTournamentRoundPrototypes && data.editTournamentRoundPrototypes.value) {
      updateFromQuery(data.editTournamentRoundPrototypes.value)
      toast.success('Your tournament was updated.', { containerId: 'temporary' })
    }
  }

  const handleSubmitRoundPrototypes = async () => {
    const validData = checkData()
    if (validData && !loading) {
      try {
        let variables = {
          tournamentId: tournamentId,
          roundPrototypes: extractRoundPrototypes(),
        }

        const { data } = await editTournamentRounds({ variables })
        handleRoundsComplete(data)
      } catch (err) {
        handleErrors(err.errors)
      }
    }
  }

  const handleBracketNumber = (e: any) => {
    setDesiredBrackets(e)
    setMaxTeams(128)
  }

  return (
    <DataFetcher error={error} loading={loading}>
      <Box>
        <Text mb={4}>
          <h3>Edit Tournament Logistics</h3>
        </Text>
        <Text mb={7}>
          <p>
            Manage series options, bracket style, tournament associations, size and registration
            options.
          </p>
        </Text>

        <Text mb={3}>
          <h3>Tournament Settings</h3>
        </Text>

        <Box bg="white" px={7} pt={4} pb={7}>
          <Flex flexWrap="wrap" mx={-2}>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              <TabSelect
                label={'bracket type'}
                value={bracketType}
                onChange={parseAsEnum(BracketTypes)(setBracketType)}
              >
                <option key={'A'}>select bracket type</option>
                {brackets.map(bracket => (
                  <option key={bracket}>{bracket}</option>
                ))}
              </TabSelect>
            </Box>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              <TabSelect
                disabled={desiredBrackets > 0}
                label={'max number of teams'}
                value={maxTeams}
                onChange={setMaxTeams}
              >
                <option key={'B'}>select number of teams</option>
                {numTeams.map(num => (
                  <option key={num}>{num}</option>
                ))}
              </TabSelect>
              {desiredBrackets > 0 && 'locked at 128 because you have set a number of brackets'}
            </Box>
          </Flex>
          <Flex flexWrap="wrap" mx={-2}>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              <TabSelect
                label={'tournament type'}
                value={tournamentType}
                onChange={parseAsEnum(TournamentTypes)(setTournamentType)}
              >
                <option key={'C'}>select type</option>
                {types.map(type => (
                  <option key={type}>{type}</option>
                ))}
              </TabSelect>
            </Box>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              <TabSelect
                label={'season'}
                value={seasonName + ': ' + seasonYear}
                onChange={handleSeason}
              >
                <option key={'D'}>select season</option>
                {seasonOptions &&
                  seasonOptions.map(season => <option key={season}>{season}</option>)}
              </TabSelect>
            </Box>
          </Flex>
          <Box>
            <TabSelect label={'feeds into'} value={feederName} onChange={handleFeeder}>
              <option key={'E'}>
                {seasonName ? 'select tournament to feed into' : 'select season first'}
              </option>
              {seasonName && feederOptions.map(feed => <option key={feed}>{feed}</option>)}
            </TabSelect>
          </Box>
          <Flex flexWrap="wrap" mx={-2}>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              <TabInput
                type="number"
                label={'Minimum Team Size'}
                placeholder={'Enter a value'}
                min={'1'}
                value={teamSize}
                onChange={setTeamSize}
              />
            </Box>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              <TabSelect
                label={'default series length'}
                value={seriesLength}
                onChange={setSeriesLength}
              >
                <option key={'G'}>select series length</option>
                {seriesOptions.map(series => (
                  <option key={series}>{series}</option>
                ))}
              </TabSelect>
            </Box>
          </Flex>
          <Flex flexWrap="wrap" mx={-2} mt={6}>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              <TabInput
                type="text"
                label={'price'}
                placeholder={'$0.00'}
                value={price}
                onChange={setPrice}
              />
            </Box>
            <Box mx={2} mt={[6, 6, 6, 6, 0]} width={'47.75%'} minWidth={'15rem'}>
              <TabInput
                type="number"
                label={'lineup due'}
                placeholder={'enter a number of days'}
                value={teamSize}
                onChange={setTeamSize}
              />
            </Box>
          </Flex>
          <Flex flexWrap="wrap" mx={-2} mt={6}>
            <Box mx={2} width={'47.75%'} minWidth={'15rem'}>
              Multi-bracket capable{' '}
              <ToggleSwitch
                handleChange={() => setMultibracketCapable(!multibracketCapable)}
                checked={multibracketCapable}
              />
            </Box>
            {multibracketCapable && (
              <Box mx={2} mt={[6, 6, 6, 6, 0]} width={'47.75%'} minWidth={'15rem'}>
                <TabInput
                  type="number"
                  label={'Number of brackets'}
                  placeholder={'enter a number of brackets'}
                  value={desiredBrackets}
                  onChange={e => handleBracketNumber(e)}
                />
                enter 0 to let the system decide the best number
              </Box>
            )}
          </Flex>
        </Box>
        <AdminSafetySwitch
          onClick={handleSubmitLogistics}
          thisPropTriggersAReRender={Math.random()}
        >
          save tournament settings
        </AdminSafetySwitch>

        <Text mt={7}>
          <h3>Match Settings</h3>
        </Text>

        <Box>
          <Flex flexWrap="wrap" mx={-2} mt={-1}>
            <AdminMatchSettingsTable
              wide={bracketType !== BracketTypes.DoubleElim}
              isWinners={true}
              changeValue={handleRoundPrototypeChange}
            />
            {bracketType === BracketTypes.DoubleElim && (
              <AdminMatchSettingsTable isWinners={false} changeValue={handleRoundPrototypeChange} />
            )}
          </Flex>
        </Box>
        <AdminSafetySwitch
          onClick={handleSubmitRoundPrototypes}
          thisPropTriggersAReRender={Math.random()}
        >
          save match settings
        </AdminSafetySwitch>
      </Box>
    </DataFetcher>
  )
}

export default AdminLogistics
