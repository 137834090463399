import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { styled } from '../../../styles/settings/theme'

import { numToString } from '../../../utils/typeCoercions'
import { Game, Team } from '../../../types/graphql'
import { BaseButton } from '../../atoms/Buttons'
import LoadingSpinner from '../../atoms/LoadingSpinner'
import SportLogo from '../../atoms/SportLogo'
import NhlReportingTable from './NhlReportingTable'
import { GameBoxReportEvidence, useEvidence } from './evidence'
import { currentUserIsAdmin } from '../../../utils/admins'

export const GET_NHL_TEAMS = gql`
  query NhlTeams {
    sportPlayerCharacteristics(nhlTeams: true) {
      nhlTeams {
        options
      }
    }
  }
`

export const REPORT_NHL_GAME_MUTATION = gql`
  mutation report_nhl_game(
    $gameId: ID!
    $winningTeamId: ID!
    $homeTeam: UserReportedGame!
    $awayTeam: UserReportedGame!
    $evidenceScreenshot: String!
  ) {
    reportNhlGame(
      gameId: $gameId
      winningTeamId: $winningTeamId
      homeTeam: $homeTeam
      awayTeam: $awayTeam
      evidenceScreenshot: $evidenceScreenshot
    ) {
      match {
        id
        settled
      }
    }
  }
`

interface GameProps {
  game: Game
  homeTeam: Team
  awayTeam: Team
  settled: boolean
  toOpen: any
  updateLoading: (loading: boolean) => void
  report: number
  index: number
  reportable: boolean
}
const Circle = styled(Box)<BoxProps>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`
const RowBox = styled(Flex)<FlexProps>`
  flex-direction: column;
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  padding: 2rem 1.5rem;
  margin-top: 1.25rem;
`
const Reporting = styled(Flex)<FlexProps>`
  border-top: 2px solid ${props => props.theme.colors.lightgray};
`

const NhlGameBox = ({
  game,
  homeTeam,
  awayTeam,
  settled,
  toOpen,
  report,
  index,
  reportable,
  updateLoading,
}: GameProps) => {
  const [ready, setReady] = useState(false)
  const [playerHome, setPlayerHome] = useState(
    homeTeam.activeRosterEntries && homeTeam.activeRosterEntries[0].player.username,
  )
  const [playerAway, setPlayerAway] = useState(
    awayTeam.activeRosterEntries && awayTeam.activeRosterEntries[0].player.username,
  )
  const [winningTeamId, setWinner] = useState()
  const [selectedTeamHome, setSelectedTeamHome] = useState(
    homeTeam.activeRosterEntries && homeTeam.activeRosterEntries[0].player.characterName,
  )
  const [selectedTeamAway, setSelectedTeamAway] = useState(
    awayTeam.activeRosterEntries && awayTeam.activeRosterEntries[0].player.characterName,
  )
  const [nhlTeams, setNhlTeams] = useState([])
  const { evidenceScreenshotUrl, evidenceScreenshotName, handleEvidenceChanged } = useEvidence()

  const activeBox = report === index

  useQuery(GET_NHL_TEAMS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setNhlTeams(data.sportPlayerCharacteristics.nhlTeams.options)
    },
  })

  const [reportNhlGame, { loading }] = useMutation(REPORT_NHL_GAME_MUTATION, {
    refetchQueries: ['matchDetailPage'],
    onError(_error) {},
    onCompleted(data) {
      if (data.reportNhlGame.match.settled) {
        toast.success(
          "Thank you for your submission. We're processing it now and brackets will be updated within 5 minutes",
          { containerId: 'temporary' },
        )
      } else {
        toast.success('Thank you for your submission!', { containerId: 'temporary' })
      }
      changeOpenIndex(-1)
      updateLoading(false)
    },
  })

  useEffect(() => {
    const isReadyForSubmit = () => {
      return winningTeamId && evidenceScreenshotUrl
    }
    setReady(isReadyForSubmit())

    if (activeBox) {
      loading ? updateLoading(true) : updateLoading(false)
    }
  }, [winningTeamId, activeBox, loading, updateLoading, evidenceScreenshotUrl])

  const handleNameChange = (e: any, isHomeTeam: any) => {
    isHomeTeam ? setPlayerHome(e.target.value) : setPlayerAway(e.target.value)
  }

  const handleTeamChange = (e: any, isHomeTeam: any) => {
    isHomeTeam ? setSelectedTeamHome(e.target.value) : setSelectedTeamAway(e.target.value)
  }

  const playerLookupId =
    homeTeam && homeTeam.activeRosterEntries && awayTeam && awayTeam.activeRosterEntries
      ? homeTeam.activeRosterEntries
          .concat(awayTeam.activeRosterEntries)
          .map((entry: any) => {
            return { name: entry.player.username, id: entry.player.id }
          })
          .reduce((obj: any, item: any) => {
            obj[item.name] = item.id
            return obj
          }, {})
      : null

  const changeOpenIndex = (index: number) => {
    toOpen(index)
  }

  const checkData = () => {
    if (!winningTeamId) {
      toast.error('Please select a winner.', { containerId: 'temporary' })
      return false
    }
    return true
  }

  const handleSubmit = () => {
    const validData = checkData()
    if (validData) {
      const gamePayload = {
        id: game.id,
        winningTeamId: winningTeamId,
        homeTeam: {
          score: winningTeamId === homeTeam.id ? 1 : 0,
          userSubmittedLineup: {
            playerId: playerLookupId[playerHome || ''],
            teamCd: selectedTeamHome,
          },
        },
        awayTeam: {
          score: winningTeamId === awayTeam.id ? 1 : 0,
          userSubmittedLineup: {
            playerId: playerLookupId[playerAway || ''],
            teamCd: selectedTeamAway,
          },
        },
      }
      if (!loading) {
        reportNhlGame({
          variables: {
            gameId: gamePayload.id,
            winningTeamId: gamePayload.winningTeamId,
            homeTeam: gamePayload.homeTeam,
            awayTeam: gamePayload.awayTeam,
            evidenceScreenshot: evidenceScreenshotUrl, // is a  Base64 encoded data URL
          },
        })
      }
    }
  }

  return (
    <RowBox>
      {/* top row */}
      <Flex justifyContent="space-between" flexDirection={['column', 'column', 'row']}>
        <Flex alignItems="center" mb={[2, 2, 0]}>
          <Box mr={4}>
            <SportLogo sport={homeTeam.sportSlug} width="2rem" height="2rem" />
          </Box>
          <Text color="darkgray">
            <h3>Game {numToString(index + 1)}</h3>
          </Text>
          {game.winner && game.winner.team && (
            <Text color="green" ml={5} mt={'2px'}>
              <h5>{game.winner.team.truncatedName}</h5>
            </Text>
          )}
        </Flex>

        <Flex
          alignItems={['flex-start', 'flex-start', 'center']}
          width={[1, 1, 1 / 2]}
          justifyContent="flex-end"
          flexDirection={['column', 'column', 'row']}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            report !== index && (
              <Flex mb={[2, 2, 0]} alignItems="center">
                <Circle bg={game.winner ? 'green' : settled ? 'middlegray' : 'red'} mr={2} />
                <Text color="darkgray" mt={'2px'}>
                  <h6>
                    {game.winner
                      ? 'game reported'
                      : reportable
                      ? 'game unreported'
                      : settled
                      ? 'game not needed'
                      : 'report previous game'}
                  </h6>
                </Text>
              </Flex>
            )
          )}
          {(!game.winner || currentUserIsAdmin()) && !loading && (
            <BaseButton
              variant={activeBox && !ready ? 'primaryDisabled' : 'primary'}
              disabled={(activeBox && !ready) || loading}
              onClick={activeBox ? handleSubmit : () => changeOpenIndex(index)}
              ml={5}
            >
              {activeBox ? 'submit game report' : 'report game'}
            </BaseButton>
          )}
        </Flex>
      </Flex>
      {/* reporting content */}
      {activeBox && (
        <Reporting mt={5} flexWrap="wrap" mx={[0, 0, 0, -2]}>
          <NhlReportingTable
            isHomeTeam={true}
            team={homeTeam}
            isWinningTeam={winningTeamId === homeTeam.id}
            setWinner={setWinner}
            changeName={handleNameChange}
            changeTeam={handleTeamChange}
            player={playerHome || ''}
            selectedTeam={selectedTeamHome || ''}
            nhlTeams={nhlTeams}
          />
          <NhlReportingTable
            isHomeTeam={false}
            team={awayTeam}
            isWinningTeam={winningTeamId === awayTeam.id}
            setWinner={setWinner}
            changeName={handleNameChange}
            changeTeam={handleTeamChange}
            player={playerAway || ''}
            selectedTeam={selectedTeamAway || ''}
            nhlTeams={nhlTeams}
          />

          <GameBoxReportEvidence
            imageName={evidenceScreenshotName}
            imageUrl={evidenceScreenshotUrl}
            onImageChanged={handleEvidenceChanged}
          />
        </Reporting>
      )}
    </RowBox>
  )
}

export default NhlGameBox
