import React from 'react'
import { Flex, Box } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import Animation from '../atoms/Animation'

interface ISeasonsWrapperProps {
  backgroundDark?: boolean
}

interface ISeasonsMenuProps extends ISeasonsWrapperProps {
  onSelectSeason: (season: string) => void
  closeMenu?: () => void
  seasons: string[]
}

const SeasonsMenuWrapper = styled(Flex)<ISeasonsWrapperProps>`
  background: ${props => props.theme.colors.sand};
  flex-direction: column;
  left: 0;
  top: 3.5rem;
  right: auto;
  overflow: hidden;
  padding: 1rem 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;

  --notchSize: 20px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 0px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );

  h5 {
    padding: 0 0 1rem 0;
    transition: ${props => props.theme.transitions.fast};
    cursor: pointer;
    color: ${props =>
      props.backgroundDark ? props.theme.colors.white : props.theme.colors.primarynavy};
    :hover {
      color: ${props => props.theme.colors.primarylightblue};
    }
  }

  ${Mq.md} {
    padding: 2rem 1rem 1rem;
    top: 3.5rem;
    right: 0;
    left: auto;
  }
`

const MenuBorder = styled(Flex)<ISeasonsWrapperProps>`
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  --notchSize: 20px;
  background: ${props =>
    props.backgroundDark ? props.theme.colors.primarynavy : props.theme.colors.white};

  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 0px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
`

const SeasonsLinks = styled(Box)`
  z-index: 9;
`

const animations = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

const SeasonsMenu: React.FC<ISeasonsMenuProps> = ({ backgroundDark, seasons, onSelectSeason }) => {
  return (
    <Animation
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.2 }}
    >
      <SeasonsMenuWrapper id="seasonsmenu" backgroundDark={backgroundDark}>
        <SeasonsLinks>
          {seasons.map(season => (
            <h5 key={season} onClick={() => onSelectSeason(season)}>
              {season.replace('-', ' - ')} Season
            </h5>
          ))}
        </SeasonsLinks>
        <MenuBorder backgroundDark={backgroundDark} />
      </SeasonsMenuWrapper>
    </Animation>
  )
}

export default SeasonsMenu
