import ApolloClient from 'apollo-client'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import React, { useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Flex, FlexProps } from 'rebass'
import { oc } from 'ts-optchain'

import { useApolloClient, useQuery } from '@apollo/react-hooks'

import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { RosterEntry, SeatTree, Team, TeamSeed } from '../../types/graphql'
import { blankBracketData } from '../../utils/bracketDataUtils'
import { playoffs } from '../../utils/playoffs'
import { addStatsToRosterEntries, addStatsToTeams } from '../../utils/statUtils'
import BracketColorKey from '../atoms/BracketColorKey'
import Hidden from '../atoms/Hidden'
import CenterBracket from '../organisms/CenterBracket'
import DataFetcher from '../organisms/DataFetcher'
import LosersBracket from '../organisms/LosersBracket'
import TournamentHero from '../organisms/TournamentHero'
import TournamentHeroCTAs from '../organisms/TournamentHeroCTAs'
import TournamentInformation from '../organisms/TournamentInformation'
import WinnersBracket from '../organisms/WinnersBracket'

// Consider moving teamStats and playerStats to their modals if page load is impacted dr  astically
const GET_TOURNAMENT_INFO = gql`
  query TournamentDetail($id: ID!) {
    tournament(id: $id) {
      id
      name
      style
      sport
      numberOfTeams
      firstMatchDate
      description
      address
      banner
      sponsorImage
      registrationOpenDate
      registrationCloseDate
      checkinLength
      currentUserNumberOfEligibleTeams
      brackets {
        id
      }
      containerId
      checkins {
        teamId
      }
      activeRosterEntries {
        id
        gameRole
        inGameName
        player {
          id
          username
          discordId
        }
      }
      playerStats {
        id
        wins
        losses
        player {
          id
        }
      }
      teamStats {
        id
        wins
        losses
        team {
          id
        }
      }
      teamSeeds {
        teamId
        seed
      }
      teams {
        id
        name
        currentUserOnTeam
        coordinator {
          id
          username
        }
        university {
          id
          abbreviation
        }
        truncatedName
      }
      grandFinals {
        seat
      }
      bracketData {
        team1Id
        team1Wins
        settled
        matchId
        team2Id
        team2Wins
        seat
        scheduledDate
      }
      seatTree {
        seat
        winnerTo
        loserTo
      }
    }
  }
`

const BracketSelector = styled(Flex)<FlexProps>`
  background-color: ${props => props.theme.colors.primarynavy};
  top: 0rem;
  z-index: 16;
  position: sticky;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: ${props => props.theme.fonts.condensed};
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  ${Mq.md} {
    font-family: ${props => props.theme.fonts.wide};
    font-weight: bold;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
`
const BracketSpace = styled(Box)`
  background-color: white;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`

interface HoveredTeamState {
  hoveredTeam: string
  setHoveredTeam: React.Dispatch<React.SetStateAction<string>>
}

interface TbdCheatSheetState {
  tbdCheatSheet: any
}

export interface IURLParams {
  tournamentId?: string
  page?: string
  pageSize?: string
}

interface IProps extends RouteComponentProps<IURLParams> {
  customStyles: any
}
const defaultHoveredTeamState: HoveredTeamState = {
  hoveredTeam: '',
  setHoveredTeam: (): void => {},
}

const tbdCheatSheetState: TbdCheatSheetState = {
  tbdCheatSheet: {},
}

export const HoveredTeamContext = React.createContext<HoveredTeamState>(defaultHoveredTeamState)
export const TbdCheatSheetContext = React.createContext<TbdCheatSheetState>(tbdCheatSheetState)

const TournamentDetail: React.FunctionComponent<IProps> = ({ match: { params } }) => {
  const client: ApolloClient<any> = useApolloClient()

  const tournamentId = oc(params).tournamentId('')
    ? oc(params).tournamentId('')
    : process.env.REACT_APP_STARLEAGUE_TOURNAMENT_ID
  const [bracket, setBracket] = useState('winners')
  const [cacheUpdated, setCacheUpdated] = useState(false)
  const [isDoubleElim, setIsDoubleElim] = useState(false)
  const [firstMatchDate, setFirstMatchDate] = useState('')
  const [numberOfPlayers, setnumberOfPlayers] = useState(0)
  const [numberOfSchools, setNumberOfSchools] = useState(0)
  const [numberOfTeams, setNumberOfTeams] = useState(0)
  const [tournamentSize, setTournamentSize] = useState(0)
  const [rosterEntries, setRosterEntries] = useState([{} as RosterEntry])
  const [teams, setTeams] = useState([{} as Team])
  const [currentUserRegisteredTeams, setCurrentUserRegisteredTeams] = useState([{} as Team])
  const [hoveredTeam, setHoveredTeam] = useState('')
  const [winnersSeats, setWinnersSeats] = useState([])
  const [losersSeats, setLosersSeats] = useState([])
  const [winnersFinalSeat, setWinnersFinalSeat] = useState(0)
  const [grandFinalSeat, setGrandFinalSeat] = useState(0)
  const [losersFinalSeat, setLosersFinalSeat] = useState(0)
  const [loserSemiFinalSeat, setLoserSemiFinalSeat] = useState(0)
  const [sport, setSport] = useState('')
  const [tbdCheatSheet, setTbdCheatSheet] = useState({})
  const [checkinLength, setCheckinLength] = useState(0)
  const [currentUserNumberOfEligibleTeams, setCurrentUserNumberOfEligibleTeams] = useState(0)
  const [bracketIds, setBracketIds] = useState([])
  const [containerId, setContainerId] = useState('')
  const scrollRef = useRef(null)

  const getSeedFromTeamId = (id: string, seeds: [TeamSeed]) => {
    const filteredSeeds = seeds.filter((s: TeamSeed) => s.teamId === id)
    return filteredSeeds.length > 0 ? filteredSeeds[0].seed : ''
  }
  const { data, error, loading: tournamentInfoLoading } = useQuery(GET_TOURNAMENT_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: { id: tournamentId },
    onCompleted: data => {
      data.tournament &&
        data.tournament.brackets &&
        setBracketIds(data.tournament.brackets.map((t: any) => t.id))
      data.tournament && data.tournament.containerId
        ? setContainerId(data.tournament.containerId)
        : setContainerId('')

      setFirstMatchDate(dayjs(data.tournament.firstMatchDate).format('dddd, MMM D, YYYY'))
      setCurrentUserNumberOfEligibleTeams(data.tournament.currentUserNumberOfEligibleTeams)
      setNumberOfTeams(data.tournament.teams.length)
      setnumberOfPlayers(data.tournament.activeRosterEntries.length)
      setNumberOfSchools(
        data.tournament.teams
          .map((t: Team) => (t.university ? t.university.id : null))
          .reduce((acc: any, d: any) => (acc.includes(d) ? acc : acc.concat(d)), []).length,
      )
      setRosterEntries(
        data.tournament.playerStats && data.tournament.playerStats.length > 0
          ? addStatsToRosterEntries(
              data.tournament.activeRosterEntries,
              data.tournament.playerStats,
            )
          : data.tournament.activeRosterEntries,
      )
      let teams =
        data.tournament.teamStats && data.tournament.teamStats.length > 0
          ? addStatsToTeams(data.tournament.teams, data.tournament.teamStats)
          : data.tournament.teams

      teams = teams.map((team: Team) => ({
        ...team,
        checkedIn: !!data.tournament.checkins.find((c: any) => c.teamId === team.id),
      }))

      setTeams(teams)
      setCurrentUserRegisteredTeams(teams.filter((team: Team) => team.currentUserOnTeam))
      setIsDoubleElim(data.tournament.style === 'double_elim' ? true : false)
      setSport(data.tournament.sport)
      setCheckinLength(data.tournament.checkinLength)

      if (data.tournament.bracketData) {
        const allSeats: any = [
          ...new Set(
            data.tournament.seatTree.map((seat: SeatTree) =>
              seat['winnerTo'] ? seat['winnerTo'].toString() : null,
            ),
          ),
        ]
        // This gets kinda hairy so here's some definitions
        // MatchSeat => This is the seat the winner of the match will go to. Each match box

        // MatchSeats for winner's bracket
        const winnerSeats: any = [
          ...new Set(
            data.tournament.seatTree
              .filter((seat: SeatTree) =>
                data.tournament.style === 'double_elim' ? seat['loserTo'] !== null : true,
              )
              .map((seat: SeatTree) => seat['winnerTo']),
          ),
        ]
        // MatchSeats for the loser's bracket
        const loserSeats: any = [
          ...new Set(
            data.tournament.seatTree
              .filter((seat: SeatTree) => seat.loserTo === null && seat.winnerTo !== null)
              .map((seat: SeatTree) => seat['winnerTo']),
          ),
        ]
        const grandFinalSeat =
          data.tournament.grandFinals.length > 0
            ? data.tournament.grandFinals[0].seat
              ? data.tournament.grandFinals[0].seat
              : ''
            : ''
        const winnerFinals =
          data.tournament.style === 'double_elim'
            ? winnerSeats[winnerSeats.length - 1]
            : winnerSeats[winnerSeats.length - 3]

        //Bit hacky, but if there are no loserSeats then this is single elim so we want the 2nd to last match
        const loserFinalsSeat =
          loserSeats.length > 0
            ? loserSeats[loserSeats.length - 1]
            : winnerSeats[winnerSeats.length - 2]
        const loserSemiFinal = loserSeats.length > 0 ? loserSeats[loserSeats.length - 2] : null

        const tbdCheatSheet: any = [
          ...new Set(
            data.tournament.seatTree
              .filter((seat: SeatTree) => seat.loserTo === null && seat.winnerTo !== null)
              .map((seat: SeatTree) => {
                let container: any = {}
                container = { teamSeat: seat.seat, matchSeat: seat.winnerTo }
                return container
              }),
          ),
        ].reduce((cheatSheet: any, loserMatch: any) => {
          let winnerMatch = data.tournament.seatTree.filter(
            (seat: SeatTree) => seat.loserTo === loserMatch.teamSeat,
          )[0]

          const winnerMatchNumber = winnerMatch
            ? winnerMatch.winnerTo === winnerFinals
              ? 'Winners Finals'
              : 'W' + (winnerSeats.indexOf(winnerMatch.winnerTo) + 1)
            : null
          // we have to check for winnerMatch.winnerTo because the grand finals match has no winnerTo
          cheatSheet[loserMatch.matchSeat]
            ? cheatSheet[loserMatch.matchSeat].push(winnerMatchNumber)
            : (cheatSheet[loserMatch.matchSeat] = [winnerMatchNumber])

          return cheatSheet
        }, {})

        setTbdCheatSheet(tbdCheatSheet)
        setWinnersFinalSeat(winnerFinals)
        setLoserSemiFinalSeat(loserSemiFinal)
        setLosersFinalSeat(loserFinalsSeat)
        setWinnersSeats(winnerSeats)
        setLosersSeats(loserSeats)
        setGrandFinalSeat(grandFinalSeat)

        client.writeData({
          data: { ...blankBracketData() },
        })
        setTournamentSize(data.tournament.numberOfTeams)
        const teamCheatSheet = data.tournament.teams.reduce(
          (obj: any, item: any, index: number) => {
            obj[item.id] = {
              name: item.truncatedName,
              seed: getSeedFromTeamId(item.id, data.tournament.teamSeeds),
            }
            return obj
          },
          {},
        )

        const bracketData =
          data.tournament.bracketData &&
          data.tournament.bracketData.reduce((obj: any, item: any) => {
            obj[item.seat] = {
              __typename: 'TournamentMatch',
              id: item.seat,
              matchId: item.matchId,
              matchNumber:
                winnerSeats.indexOf(parseInt(item.seat)) > -1
                  ? 'W' + (winnerSeats.indexOf(parseInt(item.seat)) + 1)
                  : data.tournament.style !== 'double_elim'
                  ? winnerSeats.indexOf(parseInt(item.seat)) + 1
                  : 'L' + (loserSeats.indexOf(parseInt(item.seat)) + 1),
              team1Id: item.team1Id ? item.team1Id : null,
              team1Name:
                item.team1Id === null
                  ? 'Bye'
                  : teamCheatSheet[item.team1Id]
                  ? teamCheatSheet[item.team1Id]['name']
                  : 'tbd',
              team1Wins: item.team1Wins,
              team2Id: item.team2Id ? item.team2Id : null,
              team2Name:
                item.team2Id === null
                  ? 'Bye'
                  : teamCheatSheet[item.team2Id]
                  ? teamCheatSheet[item.team2Id]['name']
                  : 'tbd',
              team2Seed:
                item.team2Id === null
                  ? ''
                  : teamCheatSheet[item.team2Id]
                  ? teamCheatSheet[item.team2Id]['seed']
                  : '',
              team1Seed:
                item.team1Id === null
                  ? ''
                  : teamCheatSheet[item.team1Id]
                  ? teamCheatSheet[item.team1Id]['seed']
                  : '',
              team2Wins: item.team2Wins,
              settled: item.settled,
              scheduledDate: item.scheduledDate,
            }
            return obj
          }, {})

        // add blank data for missing matches, but with matchNumbers
        allSeats
          .filter((n: any) => !Object.keys(bracketData).includes(n))
          .map(
            (seat: any) =>
              (bracketData[seat] = {
                __typename: 'TournamentMatch',
                id: seat,
                matchId: '',
                team1Id: null,
                team1Name: 'tbd',
                team1Wins: 0,
                team2Id: null,
                team2Name: 'tbd',
                team2Seed: '',
                team1Seed: '',
                team2Wins: 0,
                settled: false,
                scheduledDate: '',
                matchNumber:
                  winnerSeats.indexOf(parseInt(seat)) > -1
                    ? 'W' + (winnerSeats.indexOf(parseInt(seat)) + 1)
                    : data.tournament.style !== 'double_elim'
                    ? winnerSeats.indexOf(parseInt(seat)) + 1
                    : 'L' + (loserSeats.indexOf(parseInt(seat)) + 1),
              }),
          )
        client.writeData({
          data: bracketData,
        })
        setCacheUpdated(true)
      }
    },
  })

  const handleClick = (bracket: string) => {
    setBracket(bracket)
    if (scrollRef && scrollRef.current) {
      window.scrollTo(0, (scrollRef.current as any).offsetTop - 148)
    }
  }

  const tournamentReady = data && data.tournament.bracketData

  return (
    <DataFetcher error={error} loading={tournamentInfoLoading}>
      {data ? (
        <TbdCheatSheetContext.Provider value={{ tbdCheatSheet: tbdCheatSheet }}>
          <TournamentHero
            name={data.tournament.name}
            dates={playoffs[data.tournament.id] ? 'Feb 22 - Jun 7, 2020' : firstMatchDate}
            sport={sport}
            teams={numberOfTeams.toString()}
            players={numberOfPlayers.toString()}
            style={data.tournament.style}
            tournamentId={data.tournament.id}
            currentUserRegisteredTeams={currentUserRegisteredTeams}
            sponsorImage={data.tournament.sponsorImage}
            bannerImage={data.tournament.banner}
            checkinLength={checkinLength}
            currentUserNumberOfEligibleTeams={currentUserNumberOfEligibleTeams}
            firstMatchDate={data && data.tournament.firstMatchDate}
            registrationOpenDate={data && data.tournament.registrationOpenDate}
            registrationCloseDate={data && data.tournament.registrationCloseDate}
          />
          <Hidden mdUp>
            <TournamentHeroCTAs
              name={data.tournament.name}
              sport={sport}
              tournamentId={data.tournament.id}
              currentUserRegisteredTeams={currentUserRegisteredTeams}
              checkinLength={checkinLength}
              firstMatchDate={data && data.tournament.firstMatchDate}
              registrationOpenDate={data && data.tournament.registrationOpenDate}
              registrationCloseDate={data && data.tournament.registrationCloseDate}
              currentUserNumberOfEligibleTeams={currentUserNumberOfEligibleTeams}
            />
          </Hidden>
          <TournamentInformation
            firstMatchDate={data.tournament.firstMatchDate}
            numPlayers={numberOfPlayers.toString()}
            numSchools={numberOfSchools.toString()}
            numTeams={numberOfTeams.toString()}
            tournamentId={data.tournament.id}
            tournamentName={data.tournament.name}
            activeRosterEntries={rosterEntries}
            teams={teams}
            sport={sport}
            checkinLength={checkinLength}
            registrationOpenDate={data.tournament.registrationOpenDate}
            registrationCloseDate={data.tournament.registrationCloseDate}
            tournamentReady={tournamentReady}
            description={data.tournament.description}
            location={data.tournament.address}
            bracketIds={bracketIds}
            containerId={containerId}
          />

          {tournamentReady ? (
            <>
              {isDoubleElim && (
                <BracketSelector p={[3, 3, 4, 4]}>
                  <Box
                    onClick={() => handleClick('winners')}
                    mr={4}
                    color={bracket === 'losers' ? 'white' : 'primarylightblue'}
                    style={{ cursor: 'pointer' }}
                  >
                    winners bracket
                  </Box>
                  <Box
                    onClick={() => handleClick('losers')}
                    ml={4}
                    color={bracket === 'winners' ? 'white' : 'primarylightblue'}
                    style={{ cursor: 'pointer' }}
                  >
                    losers bracket
                  </Box>
                </BracketSelector>
              )}
              <HoveredTeamContext.Provider
                value={{ hoveredTeam: hoveredTeam, setHoveredTeam: setHoveredTeam }}
              >
                <BracketSpace ref={scrollRef}>
                  {bracket === 'winners' && (
                    <>
                      <Flex flexDirection="column" bg="white">
                        <BracketColorKey bracket="winners" numTeams={tournamentSize} />
                        <WinnersBracket
                          winnersSeats={winnersSeats}
                          cacheUpdated={cacheUpdated}
                          top="4rem"
                          sport={sport}
                          isDoubleElim={isDoubleElim}
                          bottomHalf={false}
                          date={firstMatchDate}
                          numTeams={tournamentSize}
                          numPlaying={numberOfTeams}
                        />
                      </Flex>
                      <CenterBracket
                        winnerFinals={winnersFinalSeat}
                        grandFinals={grandFinalSeat}
                        loserSemis={loserSemiFinalSeat}
                        loserFinals={losersFinalSeat}
                        isDoubleElim={isDoubleElim}
                        numTeams={tournamentSize}
                      />
                      <Flex bg="white">
                        <WinnersBracket
                          winnersSeats={winnersSeats}
                          cacheUpdated={cacheUpdated}
                          top="2.25rem"
                          isDoubleElim={isDoubleElim}
                          bottomHalf={true}
                          numTeams={tournamentSize}
                        />
                      </Flex>
                    </>
                  )}

                  {isDoubleElim && bracket === 'losers' && (
                    <>
                      <Flex flexDirection="column" alignItems="center" bg="white">
                        <BracketColorKey bracket="losers" numTeams={tournamentSize} />
                        <LosersBracket
                          cacheUpdated={cacheUpdated}
                          seats={losersSeats}
                          top="4rem"
                          numTeams={tournamentSize}
                        />
                      </Flex>
                      <CenterBracket
                        winnerFinals={winnersFinalSeat}
                        grandFinals={grandFinalSeat}
                        loserSemis={loserSemiFinalSeat}
                        loserFinals={losersFinalSeat}
                        lower={true}
                        numTeams={tournamentSize}
                        isDoubleElim={isDoubleElim}
                      />
                      <Flex bg="white">
                        <LosersBracket
                          seats={losersSeats}
                          cacheUpdated={cacheUpdated}
                          top="4rem"
                          bottomHalf={true}
                          numTeams={tournamentSize}
                        />
                      </Flex>
                    </>
                  )}
                </BracketSpace>
              </HoveredTeamContext.Provider>
            </>
          ) : null}
        </TbdCheatSheetContext.Provider>
      ) : null}
    </DataFetcher>
  )
}

export default TournamentDetail
