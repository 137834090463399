import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Text } from 'rebass'

import HeroImage from '../../assets/images/hero__league-index.jpg'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { SetState } from '../../types/aliases'
import { paths } from '../../utils/Routes'
import BackButton from '../atoms/BackButton'
import Hero from '../molecules/Hero'
import SeasonsToggle from '../molecules/SeasonsToggle'

const SportIcon = styled.img`
  position: absolute;
  top: 0;
  right: 1rem;
  max-width: 40px;
  ${Mq.md} {
    right: 0;
  }
`

const Title = styled.h1`
  ${Mq.md} {
    line-height: 2rem;
  }
`

interface IHeroInfoProps {
  title: string
  subTitle: string
  sportIcon?: string
  sponsorImage?: string
  banner?: string
  height?: string
  divisionLogo?: React.ReactNode
  register?: React.ReactNode
  setYear?: SetState<string>
  year?: string
  isCurrentSeason: boolean
}

const LeagueHero: React.FC<IHeroInfoProps> = ({
  title,
  subTitle,
  sportIcon,
  banner,
  sponsorImage,
  height,
  divisionLogo,
  register,
  setYear,
  year,
  isCurrentSeason,
}) => {
  const history = useHistory()
  return (
    <Hero
      height={height}
      titleComponent={
        <>
          <Title>{title}</Title>
          {divisionLogo}
        </>
      }
      topLeftComponent={
        !isCurrentSeason &&
        !setYear && (
          <Box ml={[8, 8, 8, 0]}>
            <BackButton
              onClick={() => history.push(paths.leagues())}
              buttonColor="white"
              textColor="teal"
              text="back to current season"
            />
          </Box>
        )
      }
      sponsorComponent={
        sponsorImage && (
          <>
            <Text mb={3} mt={[2, 2, 2, 0]} color="white">
              <h5>sponsored by</h5>
            </Text>
            <Box width={100}>
              <img src={sponsorImage} alt="Sponsor Logo" />
            </Box>
          </>
        )
      }
      subTitle={subTitle}
      actionsComponent={
        sportIcon && (
          <>
            <SportIcon src={sportIcon} />
            {register}
          </>
        )
      }
      image={banner || HeroImage}
      tint={isCurrentSeason ? undefined : '0,0,0,0.6'}
      // TODO: Enable once seasons scoping is in place
      seasonToggle={
        setYear &&
        year && (
          <SeasonsToggle
            backgroundDark
            changeYear={setYear}
            selectedYear={year}
            isCurrentSeason={isCurrentSeason}
          />
        )
      }
    />
  )
}

export default LeagueHero
