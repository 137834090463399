import React from 'react'
import { Flex, Text, TextProps } from 'rebass'
import { Crosshair } from 'react-feather'
import { useTheme } from 'emotion-theming'
import { styled } from '../../styles/settings/theme'

import { LeagueRegistrationStatuses as Status } from '../../types/graphql'

const StyledSVG = styled(Text)<TextProps>`
  margin-top: -2px;
`

interface ILeagueEnrollingLabelProps {
  status: string
}

const EnrollingLabel: React.FC<ILeagueEnrollingLabelProps> = ({ status }) => {
  const { colors } = useTheme()
  const ended = status === Status.Ended
  const label = ended ? '(completed)' : status
  return (
    <Flex pt={1}>
      {!ended && (
        <StyledSVG>
          <Crosshair color={colors.orange} size="20" />
        </StyledSVG>
      )}

      <Text color={ended ? colors.middlegray : colors.orange} ml={'.5rem'}>
        <h6>{label}</h6>
      </Text>
    </Flex>
  )
}

export default EnrollingLabel
