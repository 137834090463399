import { darken } from 'polished'
import React from 'react'
import { Box, BoxProps, Text } from 'rebass'
import { Input, InputProps, Select, Textarea } from '@rebass/forms'
import { styled } from '../../styles/settings/theme'

import TabLabel from '../atoms/TabLabel'

interface IFieldLabelProps {
  label: string
  color?: string
  disabled?: boolean
}

interface ITypeaheadWrapperProps {
  disabled?: boolean
}

interface ITabProps {
  label: string
  type?: string
  placeholder?: string
  value?: string | number
  onChange: (e: any) => void
  height?: string
  min?: string
  max?: string
  step?: string
  disabled?: boolean
  error?: string
  width?: number
}

export const CustomInput = styled(Input)<InputProps>`
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  background-color: ${props => props.theme.colors.backgroundgray};
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkgray};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  padding: 1rem 1rem 0.8rem;
  width: ${props => props.width};
  ::placeholder {
    opacity: 70%;
  }

  &:focus {
    border-color: ${props => props.theme.colors.darkgray};
    outline: none;
  }
`

export const LabelDiv = styled.div<IFieldLabelProps>`
  position: absolute;
  font-size: 10px;
  top: 0;
  height: 2px;
  background: ${props => props.theme.colors.backgroundgray};;
  left: 15px;
  font-family: ${props => props.theme.fonts.condensed};
  text-transform: uppercase;
  padding: 0 3px;
  line-height: 10px;
  letter-spacing: 0.1em;
  color: transparent;

  &::before {
    color: ${props => (props.color ? props.color : props.theme.colors.darkgray)};
    font-size: 10px;
    position: absolute;
    content: "${props => props.label}";
    white-space: nowrap;
    left: 3px;
    top: -3px;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
  }
`

export interface IFileFieldProps {
  color?: string
}

export const FileField = styled.div<IFileFieldProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0.75rem 0;
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  background-color: ${props => props.theme.colors.backgroundgray};
  transition: ${props => props.theme.transitions.fast};
  outline: none;
  margin-top: 0.5rem;
  position: relative;
  height: 2.2rem;
  justify-content: center;

  p {
    font-family: ${props => props.theme.fonts.condensed};
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${props => props.color || props.theme.colors.darkgray};
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
  }

  svg {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  :hover {
    border: 2px solid ${props => props.theme.colors.primarylightblue};
  }
`

// handles styling override for react-bootstrap-typeahead
export const TypeaheadWrapper = styled.div<ITypeaheadWrapperProps>`
  input {
    width: 100%;
    font-family: ${props => props.theme.fonts.condensed};
    font-size: 0.9rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${props => darken(0.3, props.theme.colors.darkmiddlegray)};
    border: solid 2px ${props => props.theme.colors.darkmiddlegray};
    background-color: ${props => props.theme.colors.backgroundgray};

    padding: 1rem 1rem 0.8rem;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    ::placeholder {
      opacity: 70%;
    }
  }
  div.dropdown-menu {
    background: white;
    position: absolute;
    width: 100%;
    padding: 20px;
    z-index: 1;
    border: solid 2px ${props => props.theme.colors.darkmiddlegray};
    background-color: ${props => props.theme.colors.backgroundgray};
    border-radius: 0 0 4px 4px;
    border-top: 0;

    a {
      font-family: ${props => props.theme.fonts.condensed};
      color: ${props => darken(0.3, props.theme.colors.darkmiddlegray)};
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`

//these are used in components below
export const CustomTabInput = styled(Input)<InputProps>`
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  border-radius: 0px 4px 4px 4px;
  background-color: ${props => props.theme.colors.backgroundgray};
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkgray};
  padding: 1rem 1rem 0.8rem;
  width: ${props => props.width};
  ::placeholder {
    opacity: 50%;
  }
`
export const CustomTabSelect = styled(Select)`
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  border-radius: 0px 4px 4px 4px;
  background-color: ${props => props.theme.colors.backgroundgray};
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkgray};
  padding: 1rem 1rem 0.8rem;

  &:focus {
    border-color: ${props => props.theme.colors.darkgray};
    outline: none;
  }
`

export const CustomTabTextArea = styled(Textarea)`
  width: ${props => props.width || '100%'};
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  border-radius: 0px 4px 4px 4px;
  background-color: ${props => props.theme.colors.backgroundgray};
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkgray};
  padding: 1rem 1rem 0.8rem;
  ::placeholder {
    opacity: 50%;
  }
`

export const InputBox = styled(Box)<BoxProps>`
  position: relative;
`

export const CheckBox = styled.input`
  -webkit-appearance: none;
  background-color: ${props => props.theme.colors.backgroundgray};
  border: 2px solid ${props => props.theme.colors.darkmiddlegray};
  width: 17px;
  height: 17px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;

  &:checked {
    background-color: ${props => props.theme.colors.darkgray};
    border: 2px solid ${props => props.theme.colors.darkgray};
    &:after {
      content: '\\2714';
      font-size: 12px;
      position: absolute;
      color: transparent;
      text-shadow: 0 0 0 white;
    }
  }
`

export const TabInput: React.FC<ITabProps> = ({
  label,
  disabled,
  max,
  min,
  type,
  placeholder,
  value,
  step,
  onChange,
  error,
}) => {
  return (
    <InputBox>
      <TabLabel text={label} />
      <CustomTabInput
        type={type}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
      />
      <Text textAlign="center" color="red" mt={2}>
        {error}
      </Text>
    </InputBox>
  )
}

export const TabTextArea: React.FC<ITabProps> = ({
  label,
  placeholder,
  value,
  onChange,
  height,
}) => {
  return (
    <InputBox mt={6}>
      <TabLabel text={label} />
      <CustomTabTextArea
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        height={height}
      />
    </InputBox>
  )
}

export const TabSelect: React.FC<ITabProps> = ({
  label,
  onChange,
  children,
  value,
  disabled,
  width,
}) => {
  return (
    <InputBox mt={6} width={width}>
      <TabLabel text={label} />
      <CustomTabSelect
        disabled={disabled}
        onChange={e => {
          onChange(e.target.value)
        }}
        value={value}
      >
        {children}
      </CustomTabSelect>
    </InputBox>
  )
}

export const FieldLabel: React.FC<IFieldLabelProps> = ({ label, color, disabled }) => {
  return (
    <LabelDiv label={label} color={color} disabled={disabled}>
      {label}
    </LabelDiv>
  )
}
