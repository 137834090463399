import { Team, TeamSeasonStats, RosterEntry, UserSeasonStats } from '../types/graphql'

export function steamIdToSteam64(steamId: string) {
  var SteamID = require('steamid')

  var sid = new SteamID(steamId)
  // debugger
  if (sid.isValid()) {
    return sid.getSteamID64(steamId)
  } else return ''
}

export function addStatsToRosterEntries(rosterEntries: any, stats: any) {
  if (rosterEntries && stats) {
    const mappedEntries = rosterEntries.map((re: RosterEntry) => {
      let stat = {
        ...stats.filter((stat: UserSeasonStats) => stat.player.id === re.player.id)[0],
      }
      if (stat === null) {
        return { re }
      }
      return { ...re, player: { ...re.player, selectedSeasonStats: stat } }
    })

    return mappedEntries
  } else return rosterEntries
}

export function addStatsToTeams(teams: any, stats: any) {
  if (teams && stats) {
    const mappedStats = stats.map((stat: TeamSeasonStats) => {
      let team = teams.filter((team: Team) => team.id === stat.team.id)[0]
      if (team === null) {
        return null
      }
      return { ...team, selectedSeasonStats: stat }
    })

    return mappedStats
  } else return teams
}

export function generateStatLink(selectedSport: string, inGameName: string) {
  const baseStatUrl = {
    lol: 'https://na.op.gg/summoner/userName=',
    dota2: `https://www.dotabuff.com/search?utf8=%E2%9C%93&q=`,
    sfv: `https://game.capcom.com/cfn/sfv/profile/`,
    fortnite: `https://fortnitetracker.com/profile/all/`,
  }

  function prepareStatsLink(inGameName: string) {
    switch (selectedSport) {
      case 'lol':
        return baseStatUrl[selectedSport] + inGameName.split(' ').join('+')
      case 'csgo':
        // return encodeURI(baseStatUrl[selectedSport] + inGameName + '&commit=Search')
        return steamIdToSteam64(inGameName)
      case 'dota2':
        return encodeURI(
          baseStatUrl[selectedSport] + steamIdToSteam64(inGameName) + '&commit=Search',
        )
      case 'sfv':
        return encodeURI(baseStatUrl[selectedSport] + inGameName)
      case 'fortnite':
        return encodeURI(baseStatUrl[selectedSport] + inGameName)
      default:
        return ''
    }
  }
  return prepareStatsLink(inGameName)
}

export default {
  generateStatLink,
}
