import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React, { useContext, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { CSVLink } from 'react-csv'
import CSVReader, { IFileInfo } from 'react-csv-reader'
import { FiXCircle } from 'react-icons/fi'
import { useModal } from 'react-modal-hook'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'
import { oc } from 'ts-optchain'

import { useMutation } from '@apollo/react-hooks'

import {
  SeasonAdminContext,
  SeasonAdminProvider,
} from '../../contexts/SeasonAdminContext'
import { AdminSeasonTeamsQuery } from '../../types/fragments'
import {
  AdminSeasonConferencesFragment as Conference,
  AdminSeasonTeamInfoFragment as Team,
  ConferenceAttributes,
} from '../../types/graphql'
import AdminSafetySwitch from '../atoms/AdminSafetySwitch'
import { BaseButton } from '../atoms/Buttons'
import { CustomInput, InputBox, TypeaheadWrapper } from '../atoms/FormPieces'
import ModalContainer from '../atoms/ModalContainer'
import Table from '../atoms/Table'
import AdminSeasonTeamsModal from '../organisms/AdminSeasonTeamsModal'
import DataFetcher from './DataFetcher'

export const UPSERT_CONFERENCES = gql`
  mutation upsertConferences(
    $seasonId: ID!
    $conferences: [ConferenceAttributes!]
    $registeredTeams: [ID!]!
  ) {
    upsertConferences(
      seasonId: $seasonId
      conferences: $conferences
      registeredTeams: $registeredTeams
    ) {
      season {
        id
        isEditable
        ...AdminSeasonTeamsQuery
      }
      errors {
        field
        message
      }
    }
  }
  ${AdminSeasonTeamsQuery}
`
interface Action {
  type: string
  newSeeds?: { index: number; seed: string }
  indexToRemove?: number
  newChecks?: { index: number; checked: boolean }
}

const AdminSeasonTeams: React.FC = () => {
  const {
    teams,
    seasonId,
    conferences,
    dropTeam,
    getConferenceNameFromTeam,
    updateTeamConference,
    updateFromQuery,
    isEditable,
    unassignTeam,
    name,
    createConference,
    setConferences,
  } = useContext(SeasonAdminContext)
  const [searchName, setSearchName] = useState('')
  const prepDataForMutation = (conferences: Conference[]) => {
    return conferences.map((conf: Conference) => extractAttributesFromConferences(conf))
  }

  const extractIdsFromTeams = (teams: Conference['teams']) => {
    if (teams) {
      return teams.map((t: any) => {
        if (t && t.id) {
          return t.id
        } else return null
      })
    } else return ['']
  }
  const csvHeaders = ['Team Name', 'Team Id', 'University Name', 'Locale', 'Conference Name']
  const extractDataForCSV = () => {
    const body = teams.map(team => [
      team.name,
      team.id,
      oc(team.university).name(''),
      oc(team.university).state(''),
      getConferenceNameFromTeam(team),
    ])
    return [csvHeaders, ...body]
  }
  const dataForCsv = extractDataForCSV()

  const getTeamFromId = (id: string) => {
    return teams.find(team => team.id === id)
  }

  const parseCsv = (data: any[], fileInfo: IFileInfo) => {
    const teamConferenceMap = data
      .map(row => {
        const team = getTeamFromId(row[csvHeaders[1]])
        const confName = row[csvHeaders[4]]

        return team ? [team, confName] : []
      })
      .filter(e => e[0] && e[1])
    const confNames = [...new Set(teamConferenceMap.map(e => e[1]))]
    const newConferences = confNames.map(name => createConference(name))
    // teams.forEach(team => unassignTeam(team))
    teamConferenceMap.forEach(([team, confName]) => {
      const confIdx = newConferences.findIndex(conf => conf.name === confName)
      newConferences[confIdx].teams = [...newConferences[confIdx].teams, team]
    })
    setConferences(newConferences)
  }

  const extractAttributesFromConferences = (conf: Conference) => {
    const confAttrs: ConferenceAttributes = {
      seasonId: seasonId,
      teamIds: conf.teams ? extractIdsFromTeams(conf.teams) : [],
      conferenceName: conf.name,
    }
    return confAttrs
  }
  const [showTeamModal, hideTeamModal] = useModal(
    () => (
      <SeasonAdminProvider>
        <ModalContainer hideModal={hideTeamModal}>
          <AdminSeasonTeamsModal seasonId={seasonId as string} />
        </ModalContainer>
      </SeasonAdminProvider>
    ),
    [seasonId],
  )
  const handleSubmit = () => {
    if (!loading) {
      updateConferences({
        variables: {
          seasonId: seasonId,
          conferences: prepDataForMutation(conferences),
          registeredTeams: teams.map(t => t.id),
        },
      })
    }
  }
  const [updateConferences, { loading, error }] = useMutation(UPSERT_CONFERENCES, {
    onCompleted(data) {
      if (data.upsertConferences.season) {
        updateFromQuery(data.upsertConferences.season)
        toast.success('Your conferences have been saved!', { containerId: 'temporary' })
      } else {
        toast.error('Error updating conferences.', { containerId: 'temporary' })
      }
    },
  })
  const { colors } = useTheme()

  return (
    <DataFetcher loading={loading} error={error}>
      <Box>
        <Text mb={4}>
          <h3>Edit Team Registrations</h3>
        </Text>
        <Text mb={6}>
          <p>See and manage registered teams.</p>
        </Text>
        {isEditable && (
          <BaseButton variant="primary" mb={7} onClick={() => showTeamModal()}>
            add team
          </BaseButton>
        )}
        <Text mb={3}>
          <h3>Registered teams</h3>
        </Text>
        <Box as="form" mb={4}>
          <Flex>
            <CustomInput
              width={[1, 1, 1, 1 / 3]}
              mt={4}
              onChange={e => setSearchName(e.target.value.toLowerCase())}
              placeholder="search by team or university name"
            />
          </Flex>
        </Box>
        {/* For some reason colors.middlegray and colors.white */}
        <Table
          headerBackgroundColor="middlegray"
          bodyBackgroundColor="white"
          height="75vh"
          overflow="visible"
        >
          <colgroup>
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '10%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '10%' }} />
          </colgroup>

          <thead>
            <tr>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>team</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>players</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>State</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>university</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>conference</h6>
                </Text>
              </th>

              {isEditable && (
                <th>
                  <Text color={colors.primarynavy}>
                    <h6>remove</h6>
                  </Text>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {teams &&
              teams
                .filter(
                  (t: Team) =>
                    (t.name && t.name.toLowerCase().includes(searchName)) ||
                    (t.university && t.university.name.toLowerCase().includes(searchName)),
                )
                .map(team => (
                  <tr key={team.id}>
                    <td>
                      <Text color={colors.primarynavy}>
                        <h6>{team.name}</h6>
                      </Text>
                    </td>
                    <td>
                      <Text color={colors.primarynavy}>
                        <h6>{team.activePlayersCount}</h6>
                      </Text>
                    </td>
                    <td>
                      <Text color={colors.darkgray}>
                        <h6>{team.university && team.university.abbreviation}</h6>
                      </Text>
                    </td>
                    <td>
                      <Text color={colors.darkgray}>
                        <h6>{team.university && team.university.state}</h6>
                      </Text>
                    </td>
                    <td>
                      <InputBox>
                        <TypeaheadWrapper>
                          <Typeahead
                            id={'conference' + team.id}
                            placeholder="conference"
                            inputProps={{
                              name: 'conference',
                            }}
                            defaultInputValue={getConferenceNameFromTeam(team)}
                            onChange={selection => updateTeamConference(team, selection[0])}
                            onInputChange={() => unassignTeam(team)}
                            allowNew={true}
                            disabled={!isEditable}
                            options={conferences.map((conf: Conference) => ({
                              ...conf,
                              label: conf.name,
                            }))}
                          />
                        </TypeaheadWrapper>
                      </InputBox>
                    </td>

                    {isEditable && (
                      <td>
                        <Box mr={4}>
                          <FiXCircle color={colors.red} onClick={() => dropTeam(team)} />
                        </Box>
                      </td>
                    )}
                  </tr>
                ))}
          </tbody>
        </Table>
        {isEditable ? (
          <>
            <Text mb={3} mt={4}>
              <h3>Bulk update conference assignments</h3>
            </Text>
            <Box mb={4}>
              <CSVReader
                parserOptions={{ header: true }}
                onFileLoaded={(data, fileInfo) => parseCsv(data, fileInfo)}
              />
            </Box>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="auto"
            >
              <Box>
                <AdminSafetySwitch thisPropTriggersAReRender={Math.random()} onClick={handleSubmit}>
                  save teams
                </AdminSafetySwitch>
                <BaseButton>
                  <CSVLink data={dataForCsv} filename={name + ' Conference Assignment'}>
                    Download Connference assignment CSV
                  </CSVLink>
                </BaseButton>
              </Box>
            </Flex>
          </>
        ) : (
          <h3>
            Conference assigment is not available at this time as season population is in progress
          </h3>
        )}
      </Box>
    </DataFetcher>
  )
}

export default AdminSeasonTeams
