import { css } from '@emotion/core'
import React from 'react'
import { Flex, FlexProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { useMatchNumber } from '../../utils/bracketDataUtils'

interface MatchNumberProps {
  flip?: boolean
  seat: number
}

const StyledMatchNumber = styled(Flex)<MatchNumberProps & FlexProps>`
  position: absolute;
  justify-content: center;
  align-items: center;
  ${props =>
    props.flip
      ? css`
          right: -11px;
        `
      : css`
          left: -12px;
        `}
  width: 10px;
  height: 63px;
  writing-mode: vertical-lr;
  text-orientation: upright;
  -webkit-text-orientation: upright;
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 12px;
  color: ${props => props.theme.colors.white};
`

export const MatchNumber: React.FunctionComponent<MatchNumberProps> = ({ seat, flip }) => {
  return (
    <StyledMatchNumber seat={seat} flip={flip}>
      {useMatchNumber(seat)}
    </StyledMatchNumber>
  )
}

export default MatchNumber
