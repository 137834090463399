import * as Y from 'yup'

export const constants = {
  nameMinLength: 3,
  nameMaxLength: 30,
}

export const schema = Y.object().shape({
  name: Y.string().required(),
})

export default {
  constants,
  schema,
}
