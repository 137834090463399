import React from 'react'
import { useTheme } from 'emotion-theming'
import { Flex, Text, Box } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { Team } from '../../types/graphql'
import HeroContainer from '../atoms/HeroContainer'
import DefaultHeroImage from '../../assets/images/hero__tournament-default.jpg'
import SportLogo from '../atoms/SportLogo'
import Animation from '../atoms/Animation'
import TournamentFacts from '../molecules/TournamentFacts'
import TournamentHeroCTAs from './TournamentHeroCTAs'
import Hidden from '../atoms/Hidden'

interface FactsProps {
  name: string
  dates?: string
  sport: string
  teams?: string
  players?: string
  style?: string
  tournamentId: string
  bannerImage?: string
  sponsorImage?: string
  currentUserRegisteredTeams: Team[]
  checkinLength?: number
  firstMatchDate: string
  registrationOpenDate: string
  registrationCloseDate: string
  currentUserNumberOfEligibleTeams: number
}

const StyledBox = styled.img`
  max-height: 100px;
  max-width: 150px;
`

const TournamentHero = ({
  name,
  dates,
  sport,
  teams,
  players,
  style,
  tournamentId,
  currentUserRegisteredTeams,
  checkinLength,
  firstMatchDate,
  registrationOpenDate,
  registrationCloseDate,
  bannerImage,
  sponsorImage,
  currentUserNumberOfEligibleTeams,
}: FactsProps) => {
  const { colors } = useTheme()
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
    },
  }

  return (
    <HeroContainer
      height="22.75rem"
      heroImage={bannerImage || DefaultHeroImage}
      tint={'3,51,89, 0.6'}
    >
      <Flex height={'100%'} flexWrap="wrap">
        <Box flex={[1, 1, 2]}>
          <Flex flexDirection="column" justifyContent="space-between" height={'100%'}>
            {sponsorImage && (
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4 }}
              >
                <Box>
                  <Text mt={[2, 2, 2, 0]} mb={2} color={colors.white}>
                    <h5>brought to you by</h5>
                  </Text>
                  <StyledBox src={sponsorImage} alt="Sponsor Logo" />
                </Box>
              </Animation>
            )}
            <Box mt="auto">
              <Flex
                alignItems={['flex-start', 'flex-start', 'center']}
                flexDirection={['column', 'column', 'row']}
              >
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                >
                  <Box>
                    <SportLogo sport={sport} height="40px" width="40px" />
                  </Box>
                </Animation>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                >
                  <Text color={colors.white} ml={[0, 0, 4]} mt={[4, 4, 0]}>
                    <h1>{name}</h1>
                  </Text>
                </Animation>
              </Flex>
              <Flex mt="1rem">
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                >
                  <TournamentFacts dates={dates} teams={teams} players={players} style={style} />
                </Animation>
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Hidden smDown>
          <TournamentHeroCTAs
            name={name}
            sport={sport}
            tournamentId={tournamentId}
            currentUserRegisteredTeams={currentUserRegisteredTeams}
            checkinLength={checkinLength}
            firstMatchDate={firstMatchDate}
            registrationOpenDate={registrationOpenDate}
            registrationCloseDate={registrationCloseDate}
            currentUserNumberOfEligibleTeams={currentUserNumberOfEligibleTeams}
          />
        </Hidden>
      </Flex>
    </HeroContainer>
  )
}

export default TournamentHero
