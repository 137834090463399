import React from 'react'
import { Box, Flex, Text } from 'rebass'

import { RosterEntry, Team } from '../../../types/graphql'
import { rawNumPlayers } from '../../../utils/sportUtils'
import { CustomInput } from '../../atoms/FormPieces'
import { CustomSelect } from '../../atoms/Select'
import Table from '../../atoms/Table'

interface ReportingProps {
  team: Team
  isHomeTeam: boolean
  changeName: (e: React.ChangeEvent, isHomeTeam: boolean, i: number) => void
  changeKills: (e: React.ChangeEvent, isHomeTeam: boolean, i: number) => void
  teamResults: { [key: number]: { [key: string]: string } }
}

const FortniteReportingTable = ({
  team,
  isHomeTeam,
  changeName,
  changeKills,
  teamResults,
}: ReportingProps) => {
  const num = Number(rawNumPlayers(team.sportSlug))

  return (
    <>
      <Box width={[1, 1, 1, 1 / 2]} mt={5} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
        <Flex alignItems="center" justifyContent="space-between">
          <h3>{team.truncatedName}</h3>
        </Flex>
        <Box mt={4}>
          <Table
            bodyBackgroundColor="backgroundgray"
            thpadding="0.8rem .75rem 0.7rem"
            tdpadding=".8rem .5rem"
            align="left"
          >
            <colgroup>
              <col span={1} style={{ width: '60%' }} />
              <col span={1} style={{ width: '40%' }} />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <Text color="white">
                    <h6>starting player</h6>
                  </Text>
                </th>
                <th>
                  <Text color="white">
                    <h6>kills</h6>
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(num)].map((el, i: number) => (
                <tr key={i}>
                  <td>
                    <CustomSelect
                      key={teamResults[i].name}
                      onChange={e => {
                        changeName(e, isHomeTeam, i)
                      }}
                      value={teamResults[i].name}
                    >
                      {team.activeRosterEntries &&
                        team.activeRosterEntries.map((entry: RosterEntry) => (
                          <option key={entry.id}>{entry.player && entry.player.username}</option>
                        ))}
                    </CustomSelect>
                  </td>
                  <td>
                    <CustomInput
                      key={`${teamResults[i].name}-kills`}
                      type="number"
                      value={parseInt(teamResults[i].kills)}
                      onChange={e => {
                        changeKills(e, isHomeTeam, i)
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Box>
    </>
  )
}
export default FortniteReportingTable
