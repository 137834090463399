import React from 'react'
import { Flex, Box, Text, TextProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

interface StepperProps {
  totalSteps: number
  currentStep: number
  background?: string
}

interface StepContainerProps {
  percentComplete: number
  background?: string
}

const StepLabel = styled(Text)<TextProps>`
  font-family: ${props => props.theme.fonts.condensed};
  color: ${props => props.theme.colors.primarynavy};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 auto 0.5rem;
`

const StepContainer = styled(Box)<StepContainerProps>`
  height: 12px;
  background: ${props => props.background || 'white'};
  border-radius: 50px;
  position: relative;

  &::before {
    content: '';
    width: ${props => props.percentComplete * 100}%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: ${props => props.theme.colors.primarylightblue};
    height: 100%;
    border-radius: 50px;
    transition: width 0.5s ease-in-out;
  }
`

const Stepper = ({ totalSteps, currentStep, background }: StepperProps) => {
  const percentComplete = currentStep / totalSteps
  return (
    <Flex flexDirection="column">
      <StepLabel>{`Step ${currentStep}/${totalSteps}`}</StepLabel>
      <StepContainer percentComplete={percentComplete} background={background}></StepContainer>
    </Flex>
  )
}

export default Stepper
