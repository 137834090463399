import React from 'react'
import { Text } from 'rebass'
import {
  Paragraph,
  MainHeader,
  SubHeader,
  SectionHeader,
} from '../atoms/RulesTypography'

const RlRules = () => {
  return (
    <>
      <MainHeader />
      <SubHeader text="Rocket League" />
      <SectionHeader text="rulebooks" />
      <Paragraph>
        CSL Rocket League 2020-2021 Season Rulebook: <a href="https://drive.google.com/file/d/17nWrMRKlgreMqXW1uLXRyjksxO95Xewz/view?usp=sharing" target="_blank" rel="noopener noreferrer">
          CSL-RL-2020-2021-Rules.pdf
          </a><br />
          Lone Star College Rocket League Intramurals Rulebook (Fall 2020): <a href="https://drive.google.com/file/d/1Gppxgpy54qdoQk71Xr5i2mYHIzmmvEz5/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            Lone-Star-College-Intramurals-Rocket-League-Rules.pdf
          </a>
      </Paragraph>
      <Text mb={'1rem'}>
        <h3>
          FOR ANY ROCKET LEAGUE RELATED QUESTIONS - PLEASE FEEL FREE TO EMAIL US AT{'  '}
          <a href="mailto:support@cslesports.gg" target="_top">
            SUPPORT@CSLESPORTS.GG{' '}
          </a>
          OR SPEAK TO AN ADMIN on{' '}
          <a href="https://discord.gg/cslesportsgg" target="_blank" rel="noopener noreferrer">
            DISCORD
          </a>{' '}
          in the #rl-help chat.
        </h3>
      </Text>
    </>
  )
}

export default RlRules
