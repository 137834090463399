import React, { useContext } from 'react'
import { TeamContext } from '../../contexts/TeamContext'

import DataFetcher from './DataFetcher'
import EditTeam from '../pages/ManageTeam/EditTeam'
import PlayerApplications from './PlayerApplications'

const ManageTeamLineup: React.FC = () => {
  const { teamContextLoading } = useContext(TeamContext)
  return (
    <DataFetcher error={undefined} loading={teamContextLoading}>
      <EditTeam />
      <PlayerApplications />
    </DataFetcher>
  )
}
export default ManageTeamLineup
