import React from 'react'
import { Link as ExternalLink, Flex } from 'rebass'
import { FaFacebook, FaYoutube, FaDiscord, FaTwitter } from 'react-icons/fa'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import Animation from '../atoms/Animation'

interface ISocialProps {
  position: string
}

const StyledFlex = styled(Flex)<ISocialProps>`
  position: ${props => props.position};
  top: 2.5rem;
  right: 2rem;
  a svg {
    color: ${props => props.theme.colors.white};
    transition: ${props => props.theme.transitions.fast};
    &:hover {
      color: ${props => props.theme.colors.primarylightblue};
    }
  }
  ${Mq.sm} {
    top: 0;
  }
  ${Mq.md} {
    top: 4rem;
    right: 4rem;
  }
`

const FooterSocialIcons: React.FC<ISocialProps> = ({ position }) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <StyledFlex mt={[6, 6, 6, 0]} position={position}>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.8 }}
      >
        <ExternalLink
          href={`https://www.facebook.com/cslesportsgg/`}
          mr={'1rem'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook size={24} />
        </ExternalLink>

        <ExternalLink
          href={`https://www.youtube.com/cslesportsgg`}
          mr={'1rem'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube size={24} />
        </ExternalLink>

        <ExternalLink
          href={`https://twitter.com/cslesportsgg`}
          target="_blank"
          mr={'1rem'}
          rel="noopener noreferrer"
        >
          <FaTwitter size={24} />
        </ExternalLink>

        <ExternalLink href={`https://discord.gg/cslesportsgg`} target="_blank" rel="noopener noreferrer">
          <FaDiscord size={24} />
        </ExternalLink>
      </Animation>
    </StyledFlex>
  )
}

export default FooterSocialIcons
