import React from 'react'
import { Link, Box, Flex, Text } from 'rebass'
import pluralize from 'pluralize'
import { styled, Theme } from '../../styles/settings'

import { paths } from '../../utils/Routes'
import { SiteSwitch } from '../../utils/sites'
import League from '../atoms/League'

interface ITeamInfoProps {
  members?: number | null
  conference?: string
  league?: string
  fb?: string
  twitch?: string
  twitter?: string
  university: string
  universityId: string
}

const StyledText = styled(Text)`
  color: ${props => props.theme.colors.white};
  margin-right: 0.5rem;
`

const TeamInfo: React.FC<ITeamInfoProps> = ({
  members,
  university,
  universityId,
  conference,
  league,
  fb,
  twitch,
  twitter,
}) => {
  return (
    <Flex alignItems="center">
      {members && (
        <StyledText>
          <h5>
            {members} {pluralize('member', members)}
          </h5>
        </StyledText>
      )}

      <SiteSwitch
        college={
          <>
            <StyledText>
              <h5>|</h5>
            </StyledText>
            <Link href={paths.university(universityId)}>
              <StyledText>
                <h5>{university}</h5>
              </StyledText>
            </Link>
            {conference && (
              <StyledText>
                <h5>|</h5>
              </StyledText>
            )}
            <StyledText>
              <h5>{conference}</h5>
            </StyledText>
            {league && (
              <StyledText>
                <h5>|</h5>
              </StyledText>
            )}
            <Box mr={2}>{league && <League league={league} color={Theme.colors.white} />}</Box>
          </>
        }
        esports={null}
      />
    </Flex>
  )
}

export default TeamInfo
