import React, { useState, useContext, useEffect } from 'react'
import { Flex } from 'rebass'
import { isEmpty } from 'lodash'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import BackButton from '../../atoms/BackButton'
import GamingProfiles from '../../molecules/GamingProfiles/GamingProfiles'
import AddGames from '../../molecules/AddGames'

interface AddProfilesProps {
  onFormSubmitted: () => void
}

const AddGamingProfiles: React.FC<AddProfilesProps> = ({ onFormSubmitted }) => {
  const { selectedSports, existingSports, setSelectedSports, sportClickHandler } = useContext(
    CurrentUserContext,
  )
  const [step, setStep] = useState(2)

  useEffect(() => {
    if (isEmpty(selectedSports)) {
      setStep(1)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSports])

  return (
    <Flex flexDirection="column">
      <BackButton onClick={() => setStep(1)} />

      {step === 1 && (
        <AddGames
          selectedSports={selectedSports}
          existingSports={existingSports}
          sportClickHandler={sportClickHandler}
          headerText="add games"
          subheaderText="Please select any additional games you’d like to add to your profile."
          submitButtonText="continue"
          onFormSubmitted={() => {
            setSelectedSports(selectedSports)
            setStep(2)
          }}
        />
      )}

      {step === 2 && (
        <GamingProfiles
          headerText="Add Gaming Profiles"
          submitButtonText="save"
          onFormSubmitted={onFormSubmitted}
          removeSport={sportClickHandler}
          sports={selectedSports}
        />
      )}
    </Flex>
  )
}

export default AddGamingProfiles
