import { RosterEntry } from '../types/graphql'

export function getFancyRole(re: RosterEntry) {
  const gameRole = re.gameRole ? re.gameRole : ''
  const lineupRole = re.roleCd === 'main_player' ? '' : re.gameRole ? '(Sub)' : 'Sub'
  return gameRole + lineupRole
}

// For now, trying out ace score as win percentage
export const aceScore = (member: RosterEntry): number => {
  const wins =
    member.player && member.player.selectedSeasonStats && member.player.selectedSeasonStats.wins
      ? member.player.selectedSeasonStats.wins
      : 0

  const losses =
    member.player && member.player.selectedSeasonStats && member.player.selectedSeasonStats.losses
      ? member.player.selectedSeasonStats.losses
      : 0

  if (losses >= wins || wins === 0) {
    return 0
  }

  return Math.ceil(((wins - losses) / (wins + losses)) * 100)
}

//ace score for teams
export const aceScoreTeam = (entry: RosterEntry): number => {
  const wins =
    entry.team && entry.team.selectedSeasonStats && entry.team.selectedSeasonStats.wins
      ? entry.team.selectedSeasonStats.wins
      : 0

  const losses =
    entry.team && entry.team.selectedSeasonStats && entry.team.selectedSeasonStats.losses
      ? entry.team.selectedSeasonStats.losses
      : 0

  if (losses >= wins || wins === 0) {
    return 0
  }

  return Math.ceil(((wins - losses) / (wins + losses)) * 100)
}
export default {
  getFancyRole,
}
