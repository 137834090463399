import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import DataFetcher from '../organisms/DataFetcher'
import { handleErrors } from '../../utils/formUtils'
import { paths } from '../../utils/Routes'

const CONFIRM_EMAIL_MUTATION = gql`
  mutation confirmByToken($token: String!) {
    confirmByToken(token: $token) {
      universityId
    }
  }
`

interface ConfirmEmailProps {
  token: string
}

const ConfirmEmail: React.FunctionComponent<ConfirmEmailProps> = ({ token }) => {
  const [mounted, setMounted] = useState(false)
  const [sendConfirmationEmail, { data, loading }] = useMutation(CONFIRM_EMAIL_MUTATION, {
    onError(error) {
      toast.error(handleErrors(error), { containerId: 'temporary' })
    },
    onCompleted() {
      toast.success('Account confirmed!', { containerId: 'temporary' })
    },
  })

  useEffect(() => {
    if (!mounted) {
      sendConfirmationEmail({
        variables: { token },
      })
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted])

  return (
    <DataFetcher loading={loading || !mounted} error={undefined}>
      <Redirect to={data && paths.university(data.confirmByToken.universityId)} />
    </DataFetcher>
  )
}

export default ConfirmEmail
