import React, { useContext, useState, useEffect, ReactNode } from 'react'
import { InputFieldWithErrors, FieldTypes, IOptionType } from '../InputFieldWithErrors'

import { startCase } from '../../../utils/strings'
import { SetState } from '../../../types/aliases'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'

interface IUserAboutFieldProps {
  name: string
  updateField: SetState<string>
  value?: string
  label?: string
  fieldSubText?: string | ReactNode
  disabled?: boolean
  options?: IOptionType[]
  children?: ReactNode
}

const UserAboutField: React.FC<IUserAboutFieldProps> = ({
  name,
  value,
  updateField,
  label,
  fieldSubText,
  disabled,
  options,
  children,
}) => {
  const { currentUserFormErrors, updateErrors, isFieldRequired, fieldTypes } = useContext(
    CurrentUserContext,
  )
  const [selected, setSelected] = useState<IOptionType[]>([])

  const handleUpdateTypeaheadField = (selectedOptions: IOptionType[]) => {
    setSelected(selectedOptions)

    if (selectedOptions[0]) {
      updateField(selectedOptions[0].id)
    }
  }

  useEffect(() => {
    if (options) {
      const optionMatch = options.find(option => option.id === value)
      optionMatch && setSelected([optionMatch])
    }
  }, [value, options])

  const defaultLabel = startCase(name)
  const type = fieldTypes[name]

  return (
    <InputFieldWithErrors
      mb={5}
      width={['100%', '100%', '48%']}
      type={type}
      name={name}
      label={label || defaultLabel}
      placeholder={label || defaultLabel}
      value={value}
      updateSingleField={type === FieldTypes.Typeahead ? undefined : updateField}
      maxLength={name === 'email' ? 320 : 30}
      errorMessage={currentUserFormErrors[name]}
      fieldSubText={fieldSubText}
      updateErrors={updateErrors}
      isRequired={isFieldRequired(name)}
      isEmailField={name === 'email'}
      isDiscordField={name === 'discordUsername'}
      isCaPostalCode={name === 'postalCode'}
      isNameField={name === 'firstName' || name === 'lastName'}
      disabled={disabled}
      updateTypeaheadField={
        type === FieldTypes.Typeahead ? response => handleUpdateTypeaheadField(response) : undefined
      }
      typeaheadProps={{
        selected,
        options: options || [],
      }}
    >
      {children}
    </InputFieldWithErrors>
  )
}

export default UserAboutField
