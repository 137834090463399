import gql from 'graphql-tag'
import React, { useState, useEffect } from 'react'
import { ApolloError } from 'apollo-client'
import { toast } from 'react-toastify'
import { oc } from 'ts-optchain'
import _ from 'lodash'

import { useConstants } from '../../hooks/constants'
import { useSportSelector } from '../../hooks/sportSelector'

import { useCurrentQueryParams, QueryParamsUpdater } from '../../hooks/currentQueryParams'
import { BaseButton } from '../atoms/Buttons'
import Content from '../atoms/Content'
import AdminLink from '../atoms/AdminLink'
import Hero from '../molecules/Hero'
import HeroImage from '../../assets/images/hero__news-announcements.jpg'
import { handleResult } from '../../utils/results'
import {
  NewsArticleTypes,
  NewsAndAnnouncements_NewsArticleFragment as NewsArticle,
  NewsAndAnnouncements_PaginatedFragment as Paginated,
  PostCategories,
  SportSlugs,
  useNewsOrAnnouncementsLazyQuery as useNewsOrAnnouncements,
} from '../../types/graphql'
import { paths, useQuery } from '../../utils/Routes'
import { stringToEnum, stringToEnumC } from '../../utils/typeCoercions'
import NewsSection from './NewsAndAnnouncements/NewsSection'
import AnnouncementsSection from './NewsAndAnnouncements/AnnouncementsSection'

const NEWS_ARTICLE_FRAGMENT = gql`
  fragment NewsAndAnnouncements_NewsArticle on NewsArticle {
    id
    title
    sport
    summary
    body
    position
    featured
    featuredImage
    type
    createdAt
    slug
    published
    newsCategory {
      id # not using id but there are very strange caching errors when it's not in the query
      name
    }
  }
`

const NEWS_AND_ANNOUNCEMENTS_PAGINATED_FRAGMENT = gql`
  fragment NewsAndAnnouncements_Paginated on NewsOrAnnouncementsPaginatedPayload {
    items {
      ...NewsAndAnnouncements_NewsArticle
    }
    totalPages
    currentPage
    count
  }
  ${NEWS_ARTICLE_FRAGMENT}
`

gql`
  query NewsOrAnnouncements(
    $page: Int!
    $perPage: Int!
    $type: NewsArticleTypes!
    $sort: NewsArticlesSortOrders
    $sports: [SportSlugs!]
    $categories: [PostCategories!]
  ) {
    newsOrAnnouncements(
      page: $page
      perPage: $perPage
      type: $type
      sort: $sort
      sports: $sports
      categories: $categories
    ) {
      success
      value {
        ...NewsAndAnnouncements_Paginated
      }
      success
      errors {
        field
        message
      }
    }
  }
  ${NEWS_AND_ANNOUNCEMENTS_PAGINATED_FRAGMENT}
`

interface IQueryParams {
  newsPage?: string
  newsCategory?: string
  newsSports?: string
}

const NewsAndAnnouncements: React.FC = () => {
  const NUM_NEWS = 10
  const params = useQuery<IQueryParams>()
  const { newsPage, newsCategory, newsSports } = params

  const givenNewsPostCategory = newsCategory
    ? stringToEnum<PostCategories>(PostCategories, newsCategory)
    : undefined
  const givenSports = newsSports
    ? (_.map(newsSports.split(/,|%2C/), stringToEnumC(SportSlugs)) as SportSlugs[])
    : undefined
  const givenNewsPage = newsPage && Number(newsPage)

  // Should be 3 for normal users but this gives the admins some extra room to
  // look at unpublished announcements
  const NUM_ANNOUNCEMENTS = 6

  const [currentQueryParams, setCurrentQueryParams] = useCurrentQueryParams<IQueryParams>(params)
  const [selectedSports, handleSportClicked, setSelectedSports] = useSportSelector(givenSports)
  const [selectedCategory, setSelectedCategory] = useState<PostCategories | undefined>(
    givenNewsPostCategory,
  )
  const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([])
  const [newsCurrentPage, setNewsCurrentPage] = useState(givenNewsPage || 1)
  const [newsTotalPages, setNewsTotalPages] = useState(0)
  const [announcements, setAnnouncements] = useState<NewsArticle[]>([])

  const constants = useConstants()
  const allSports = oc(constants).sportSlugs([])
  const allCategories = oc(constants).postCategories([])

  const handlePageChanged = (_event: React.ChangeEvent<unknown>, page: number) => {
    const newQueryParams = {
      newsPage: `${page}`,
    }
    setNewsCurrentPage(page)
    setCurrentQueryParams(params, newQueryParams)
  }

  const handleCategoryChanged = (category?: PostCategories) => {
    setSelectedCategory(category)

    if (category) {
      const newQueryParams = {
        newsCategory: `${category}`,
        newsPage: '1', // always reset to the first page when changed
      }
      // If the category changes to anything besides "Games" we want to
      // clear the selected sports since they only apply when "Games" is selected
      if (!(category === PostCategories.Games)) {
        newQueryParams['newsSports'] = undefined
        setSelectedSports([])
      }
      setCurrentQueryParams(params, newQueryParams)
    }
  }

  const handleSportChanged = (sportSlug: string) => {
    handleSportClicked(sportSlug)

    const sportsArray = _.concat(
      _.map(selectedSports, s => `${s}`),
      `${sportSlug}`,
    )

    const newQueryParams = {
      newsCategory: `${PostCategories.Games}`,
      newsSports: sportsArray,
      newsPage: '1', // always reset to the first page when changed
    }
    // Given the sport selector only applies for the "Games" selection,
    // force the category to be "Games"
    setSelectedCategory(PostCategories.Games)
    setCurrentQueryParams(params, newQueryParams)
  }

  const handleNewsQueryCompleted = (data: Paginated) => {
    setNewsArticles(data.items)
    setNewsTotalPages(data.totalPages)
  }

  const handleAnnouncementsQueryCompleted = (data: Paginated) => {
    const sortedAnnouncements = _.sortBy(data.items, announcement => announcement.position)
    setAnnouncements(sortedAnnouncements)
  }

  const handleErrors = (errors: _.Dictionary<string> | ApolloError) => {
    console.error(errors)
    toast.error('Something went wrong. Please try again later', { containerId: 'temporary' })
  }

  const [announcementsQuery, { loading: loadingAnnouncements }] = useNewsOrAnnouncements({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleResult({
        result: data!.newsOrAnnouncements,
        onSuccess: handleAnnouncementsQueryCompleted,
        onFailure: handleErrors,
      })
    },
    onError: handleErrors,
  })

  const [newsQuery, { loading: loadingNews }] = useNewsOrAnnouncements({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleResult({
        result: data!.newsOrAnnouncements,
        onSuccess: handleNewsQueryCompleted,
        onFailure: handleErrors,
      })
    },
    onError: handleErrors,
  })

  useEffect(() => {
    newsQuery({
      variables: {
        page: newsCurrentPage,
        perPage: NUM_NEWS,
        type: NewsArticleTypes.News,
        sports: selectedSports,
        categories: selectedCategory && [selectedCategory],
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsCurrentPage, selectedSports, selectedCategory])

  useEffect(() => {
    announcementsQuery({
      variables: {
        page: 1, // We only ever want the first page
        perPage: NUM_ANNOUNCEMENTS,
        type: NewsArticleTypes.Announcement,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <QueryParamsUpdater queryParams={currentQueryParams} />
      <Hero
        title={'News & Announcements'}
        image={HeroImage}
        height="18rem"
        tint={'3,51,89, 0.6'}
        actionsComponent={
          <AdminLink href={paths.createNewsAndAnnouncements()}>
            <BaseButton>Add New Post</BaseButton>
          </AdminLink>
        }
      ></Hero>
      <Content>
        {!_.isEmpty(announcements) && (
          <AnnouncementsSection loading={loadingAnnouncements} announcements={announcements} />
        )}
        {constants && (
          <NewsSection
            loading={loadingNews}
            allSports={allSports}
            allCategories={allCategories}
            selectedCategory={selectedCategory}
            selectedSports={selectedSports}
            onSportClicked={handleSportChanged}
            onCategoryChanged={handleCategoryChanged}
            onPageChanged={handlePageChanged}
            newsArticles={newsArticles}
            newsCurrentPage={newsCurrentPage}
            newsTotalPages={newsTotalPages}
          />
        )}
      </Content>
    </>
  )
}

export default NewsAndAnnouncements
