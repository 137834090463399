import React from 'react'
import { FiX } from 'react-icons/fi'
import ReactModal from 'react-modal'
import { Box } from 'rebass'
import { styled } from '../../styles/settings/theme'

import Card from '../../components/atoms/Card'

interface IModalProps {
  style?: any
  noPadding?: boolean
  hideModal: () => void
}

const StyledBox = styled(Box)`
  height: 5vh;
  margin-bottom: 0.25rem;
`
const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  color: #000;
  margin-right: 32px;
  position: absolute;
  right: 0;
  border: none;
  border-radius: 50%;
  transition: 0.2s;
  z-index: 1;
  cursor: pointer;
  background-color: white;
  padding-left: 7px;
  padding-top: 5px;
  &:hover {
    opacity: 90%;
  }
`

const Content = styled(Card)`
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  max-height: 83vh;
  overflow: auto;
`

const ModalContainer: React.FC<IModalProps> = ({ hideModal, children, style, noPadding }) => {
  const defaultStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: 99,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0 32px',
      maxHeight: '90vh',
      overflow: 'auto',
      width: '100%',
      maxWidth: '85rem',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
    },
  }
  return (
    <ReactModal
      style={{
        content: { ...defaultStyles.content, ...(style && style.content ? style.content : {}) },
        overlay: { ...defaultStyles.overlay, ...(style && style.overlay ? style.overlay : {}) },
      }}
      isOpen
      shouldCloseOnOverlayClick={true}
      onRequestClose={hideModal}
    >
      <StyledBox bg="transparent">
        <CloseButton onClick={hideModal}>
          <FiX size="24" />
        </CloseButton>
      </StyledBox>

      <Content p={noPadding ? 0 : [4, '2.5rem']} notch={true} notchSize={20}>
        {children}
      </Content>
    </ReactModal>
  )
}

export default ModalContainer
