import React from 'react'
import { Box, Text, Flex, FlexProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { prettyName } from '../../utils/sportUtils'
import SportLogo from '../atoms/SportLogo'

interface TitleProps {
  sport?: string
  dates?: string
  isDoubleElim: boolean
  numPlaying?: number
  date?: string
}
const TitleBox = styled(Flex)<FlexProps>`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  height: 7rem;
  color: ${props => props.theme.colors.darkgray};
`
const BracketTitle = ({ sport, dates, isDoubleElim, numPlaying, date }: TitleProps) => {
  return (
    <TitleBox>
      <Box mb={4}>
        <SportLogo width={'3rem'} height={'3rem'} sport={sport} />
      </Box>
      <Text mb={2}>
        <h6>{sport && prettyName(sport)} tournament</h6>
      </Text>
      <Text mb={2}>
        <h6>{date}</h6>
      </Text>
      <Flex>
        <h6>{isDoubleElim ? 'double elim' : 'single elim'}</h6>
        <Text mx={4}>
          <h6>|</h6>
        </Text>
        <h6>{numPlaying} teams</h6>
      </Flex>
    </TitleBox>
  )
}

export default BracketTitle
