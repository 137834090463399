import React from 'react'

export const collegeSite = process.env.REACT_APP_CSL_SITE === 'college'
export const esportsSite = process.env.REACT_APP_CSL_SITE === 'esports'

export const siteSwitcher = <T,>({ college, esports }: { college: T; esports: T }) => {
  return collegeSite ? college : esports
}

// Usage example
// const title = siteSwitcher({college: 'Welcome to College', esports: 'Welcome to Esports'})

interface ISiteSwitchProps {
  esports: any
  college: any
}

export const SiteSwitch: React.FC<ISiteSwitchProps> = ({ esports, college }) => {
  if (collegeSite) {
    return college
  }

  if (esportsSite) {
    return esports
  }

  return null
}

// example

// <SiteSwitch
//   college={<CollegeOnlyComponent />}
//   esports={<EsportsOnlyComponent />} />

/* <SiteSwitch esports={<EsportsOnly>} college={null} /> */
