export const prizePool: { [key: string]: string } = {
  '376': '$50,000',
  '375': '$50,000',
  '378': '$50,000',
  '379': '$15,000',
  '377': '$50,000',
}
export const prizeAmounts: { [key: string]: { [key: string]: { [key: string]: string } } } = {
  '375': {
    topPrizes: {
      first: '20,000',
      second: '10,000',
      third: '6,000',
      fourth: '4,000',
      fifth: '2,000',
      sixth: '2,000',
    },
    bottomPrizes: {
      '7th': '1,000',
      '8th': '1,000',
      '9th': '600',
      '10th': '600',
      '11th': '600',
      '12th': '600',
      '13th': '400',
      '14th': '400',
      '15th': '400',
      '16th': '400',
    },
  },
  '376': {
    topPrizes: {
      first: '5,750',
      second: '5,750',
      third: '5,750',
      fourth: '5,750',
      fifth: '5,750',
      sixth: '5,750',
    },
    bottomPrizes: {
      '7th': '5,750',
      '8th': '5,750',
      '9th': '600',
      '10th': '600',
      '11th': '600',
      '12th': '600',
      '13th': '400',
      '14th': '400',
      '15th': '400',
      '16th': '400',
    },
  },
  '378': {
    topPrizes: {
      first: '20,000',
      second: '10,000',
      third: '6,000',
      fourth: '4,000',
      fifth: '2,000',
      sixth: '2,000',
    },
    bottomPrizes: {
      '7th': '1,000',
      '8th': '1,000',
      '9th': '600',
      '10th': '600',
      '11th': '600',
      '12th': '600',
      '13th': '400',
      '14th': '400',
      '15th': '400',
      '16th': '400',
    },
  },
  '379': {
    topPrizes: {
      first: '4,500',
      second: '3,000',
      third: '1,800',
      fourth: '1,200',
      fifth: '825',
      sixth: '825',
    },
    bottomPrizes: {
      '7th': '525',
      '8th': '525',
      '9th': '300',
      '10th': '300',
      '11th': '300',
      '12th': '300',
      '13th': '150',
      '14th': '150',
      '15th': '150',
      '16th': '150',
    },
  },
  '377': {
    topPrizes: {
      first: '15,000',
      second: '10,000',
      third: '2,500',
      fourth: '2,500',
      fifth: '1,500',
      sixth: '1,500',
    },
    bottomPrizes: {
      '7th': '1,500',
      '8th': '1,500',
      '9th': '750',
      '10th': '750',
      '11th': '750',
      '12th': '750',
      '13th': '750',
      '14th': '750',
      '15th-16th': '750',
      '17th-32nd': '500',
    },
  },
}

export const playoffs: { [key: string]: Array<{ [key: string]: string | Array<string> }> } = {
  '376': [
    { date: 'Saturday, May 2', rounds: ['Losers Round of 4A', '', ''], colors: ['purple'] },
    {
      date: 'Sunday, May 10',
      rounds: ['Winners Finals ', '/ Losers Round of 4B', ''],
      colors: ['sand', 'primarynavy'],
    },
    { date: 'Sunday, May 17', rounds: ['Losers Round of 2A', '', ''], colors: ['primarynavy'] },
    { date: 'Saturday, May 23', rounds: ['Losers Finals', '', ''], colors: ['sand'] },
    { date: 'Sunday, May 31', rounds: ['Grand Finals', '', ''], colors: ['sand'] },
  ],
  '374': [
    {
      date: 'Saturday, May 2',
      rounds: ['Winners Semifinals ', '/ Losers Round of 8A', ''],
      colors: ['primarynavy', 'orange'],
    },
    {
      date: 'Sunday, May 10',
      rounds: ['Losers Round of 8B', '', ''],
      colors: ['purple'],
    },
    {
      date: 'Sunday, May 17',
      rounds: ['Winners Finals ', '/ Losers Round of 4A', ''],
      colors: ['sand', 'purple'],
    },
    {
      date: 'Saturday, May 23',
      rounds: ['Losers Round of 4B ', '/ Losers Round of 2A', ''],
      colors: ['primarynavy'],
    },
    { date: 'Saturday, June 6', rounds: ['Losers Finals', '', ''], colors: ['sand'] },
    { date: 'Sunday, June 7', rounds: ['Grand Finals', '', ''], colors: ['sand'] },
  ],
  '373': [
    {
      date: 'Saturday, May 2',
      rounds: ['Losers Round of 4A ', '', ''],
      colors: ['purple'],
    },
    {
      date: 'Sunday, May 10',
      rounds: ['Winners Finals ', '/ Losers Round of 4B', ''],
      colors: ['sand', 'primarynavy'],
    },
    {
      date: 'Sunday, May 17',
      rounds: ['Losers Round of 2A', '', ''],
      colors: ['primarynavy'],
    },
    {
      date: 'Saturday, May 23',
      rounds: ['Losers Finals', '', ''],
      colors: ['sand'],
    },
    { date: 'Saturday, June 6', rounds: ['Grand Finals', '', ''], colors: ['sand'] },
  ],
  '375': [
    {
      date: 'Saturday, May 2',
      rounds: ['Losers Round of 4A ', '', ''],
      colors: ['purple'],
    },
    {
      date: 'Saturday, May 9',
      rounds: ['Winners Finals ', '/ Losers Round of 4B', ''],
      colors: ['sand', 'primarynavy'],
    },
    {
      date: 'Saturday, May 16',
      rounds: ['Losers Round of 2A', '', ''],
      colors: ['primarynavy'],
    },
    {
      date: 'Saturday, May 23',
      rounds: ['Losers Finals', '', ''],
      colors: ['sand'],
    },
    { date: 'Sunday, May 31', rounds: ['Grand Finals', '', ''], colors: ['sand'] },
  ],
  '371': [
    {
      date: 'Saturday, May 2',
      rounds: ['Losers Round of 4A ', '', ''],
      colors: ['purple'],
    },
    {
      date: 'Saturday, May 9',
      rounds: ['Winners Finals ', '/ Losers Round of 4B', ''],
      colors: ['sand', 'primarynavy'],
    },
    {
      date: 'Saturday, May 16',
      rounds: ['Losers Round of 2A', '', ''],
      colors: ['primarynavy'],
    },
    {
      date: 'Saturday, May 23',
      rounds: ['Losers Finals', '', ''],
      colors: ['sand'],
    },
    { date: 'Saturday, June 6', rounds: ['Grand Finals', '', ''], colors: ['sand'] },
  ],
  '378': [
    {
      date: 'Saturday, May 9',
      rounds: ['Winners Finals ', '/ Losers Round of 4B', ''],
      colors: ['sand', 'primarynavy'],
    },
    {
      date: 'Saturday, May 16',
      rounds: ['Losers Round of 2A', '', ''],
      colors: ['primarynavy'],
    },
    {
      date: 'Saturday, May 23',
      rounds: ['Losers Finals', '', ''],
      colors: ['sand'],
    },
    { date: 'Saturday, May 30', rounds: ['Grand Finals', '', ''], colors: ['sand'] },
  ],
  '372': [
    {
      date: 'Saturday, May 9',
      rounds: ['Winners Finals ', '/ Losers Round of 4B', ''],
      colors: ['sand', 'primarynavy'],
    },
    {
      date: 'Saturday, May 16',
      rounds: ['Losers Round of 2A', '', ''],
      colors: ['primarynavy'],
    },
    {
      date: 'Saturday, May 23',
      rounds: ['Losers Finals', '', ''],
      colors: ['sand'],
    },
    { date: 'Saturday, June 6', rounds: ['Grand Finals', '', ''], colors: ['sand'] },
  ],
  '379': [
    {
      date: 'Saturday, May 2',
      rounds: ['Round of 8 / Losers Round of 16B / Losers Round of 8A '],
      colors: ['purple', 'orange'],
    },
    {
      date: 'Saturday, May 9',
      rounds: ['Semifinals / Losers Round of 8B / Losers Round of 4A'],
      colors: ['primarynavy', 'purple'],
    },
    {
      date: 'Saturday, May 16',
      rounds: ['Finals / Losers Round of 4B'],
      colors: ['sand', 'primarynavy'],
    },
    {
      date: 'Saturday, May 23',
      rounds: ['Losers Round of 2A / Losers Finals'],
      colors: ['primarynavy', 'sand'],
    },
    { date: 'Saturday, May 30', rounds: ['Grand Finals'], colors: ['sand'] },
  ],
  '377': [
    {
      date: 'Saturday, May 16',
      rounds: ['Semifinals'],
      colors: ['primarynavy'],
    },
    {
      date: 'Saturday, May 30',
      rounds: ['Finals'],
      colors: ['sand'],
    },
  ],
}
