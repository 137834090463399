import React from 'react'
import { Box, Flex, Text } from 'rebass'

import { RosterEntry, Team } from '../../../types/graphql'
import { rawNumPlayers } from '../../../utils/sportUtils'
import { BaseButton } from '../../atoms/Buttons'
import { CustomSelect } from '../../atoms/Select'
import Table from '../../atoms/Table'

interface ReportingProps {
  team: Team
  isHomeTeam: boolean
  handleChange: (e: React.ChangeEvent, isHomeTeam: boolean, i: number) => void
  teamResults: { [key: number]: { [key: string]: string } }
  setWinner: (value: any) => void
  isWinningTeam: boolean
}

const MaddenReportingTable = ({
  team,
  isHomeTeam,
  handleChange,
  teamResults,
  isWinningTeam,
  setWinner,
}: ReportingProps) => {
  const num = Number(rawNumPlayers(team.sportSlug))

  const handleScore = () => {
    setWinner(team.id)
  }

  return (
    <>
      <Box width={[1, 1, 1, 1 / 2]} mt={5} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
        <Flex alignItems="center" justifyContent="space-between">
          <h3>{team.truncatedName}</h3>
          <BaseButton variant={isWinningTeam ? 'selected' : 'select'} onClick={handleScore}>
            select as winner
          </BaseButton>
        </Flex>
        <Box mt={4}>
          <Table
            bodyBackgroundColor="backgroundgray"
            thpadding="0.8rem .75rem 0.7rem"
            tdpadding=".8rem .5rem"
            align="left"
          >
            <thead>
              <tr>
                <th>
                  <Text color="white">
                    <h6>player</h6>
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(num)].map((_el, i: number) => (
                <tr key={i}>
                  <td>
                    <CustomSelect
                      key={teamResults[i].name}
                      onChange={e => {
                        handleChange(e, isHomeTeam, i)
                      }}
                      value={teamResults[i].name}
                      name={'name'}
                    >
                      {team.activeRosterEntries &&
                        team.activeRosterEntries.map((entry: RosterEntry) => (
                          <option key={entry.id}>{entry.player && entry.player.username}</option>
                        ))}
                    </CustomSelect>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Box>
    </>
  )
}
export default MaddenReportingTable
