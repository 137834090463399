import React from 'react'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'
import { Box } from 'rebass'

import { SeasonAdminProvider } from '../../contexts/SeasonAdminContext'
import { styled } from '../../styles/settings'
import AdminSeasonCreateRedirect from '../atoms/AdminSeasonCreateRedirect'
import AdminSeasonTitle from '../atoms/AdminSeasonTitle'
import Content from '../atoms/Content'
import AdminSeasonInfo from '../organisms/AdminSeasonInfo'

const EditNav = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    font-family: ${props => props.theme.fonts.condensed};
    margin-bottom: 1.5rem;
  }
`

const CustomNavLink = styled(NavLink)`
  color: ${props => props.theme.colors.darkmiddlegray};
  &.active {
    color: ${props => props.theme.colors.darkgray};
  }
`

const CreateSeason: React.FC = () => {
  //basic info
  const match = useRouteMatch()
  const sections: { [key: string]: React.ReactElement } = {
    'basic-info': <AdminSeasonInfo />,
  }
  return (
    <SeasonAdminProvider>
      <AdminSeasonCreateRedirect />
      <Content>
        <AdminSeasonTitle />
        <Box
          sx={{
            display: 'grid',
            gridGap: 1,
            gridTemplateColumns: '1fr 4fr',
          }}
        >
          <EditNav>
            <li key={'info'}>
              <CustomNavLink to={`${match.url}`} isActive={() => true}>
                <h5>basic info</h5>
              </CustomNavLink>
            </li>
          </EditNav>
          <Route exact path={`${match.path}`} render={() => sections['basic-info']} />
          <Route
            path={`${match.path}/:section`}
            render={({ match }) => sections[match.params.section]}
          />
        </Box>
      </Content>
    </SeasonAdminProvider>
  )
}

export default CreateSeason
