import gql from 'graphql-tag'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box } from 'rebass'
import { oc } from 'ts-optchain'

import { useQuery } from '@apollo/react-hooks'

import { MatchDetailAttributes } from '../../types/fragments'
import { RosterEntry } from '../../types/graphql'
import { addStatsToRosterEntries } from '../../utils/statUtils'
import Content from '../atoms/Content'
import MatchDetailForfeitedMatch from '../molecules/MatchDetailForfeitedMatch'
import DataFetcher from '../organisms/DataFetcher'
import MatchDetailGameResults from '../organisms/MatchDetailGameResults'
import MatchDetailHero from '../organisms/MatchDetailHero'
import MatchDetailLineUps from '../organisms/MatchDetailLineUps'
import MatchDetailSubHero from '../organisms/MatchDetailSubHero'
import RescheduleMatchRequest from '../organisms/RescheduleMatchRequest'

const MATCH_DETAILS_PAGE = gql`
  query matchDetailPage($id: ID!) {
    match(id: $id) {
      ...MatchDetailAttributes
    }
  }
  ${MatchDetailAttributes}
`
export interface IURLParams {
  matchId?: string
  page?: string
  pageSize?: string
}
interface IProps extends RouteComponentProps<IURLParams> {
  customStyles: any
}
const MatchDetail: React.FunctionComponent<IProps> = ({ match: { params } }) => {
  const [matchData, setMatchData] = useState(null as any)
  const [skipLoader, setSkipLoader] = useState(false)
  const matchId = oc(params).matchId('')

  const { error, loading } = useQuery(MATCH_DETAILS_PAGE, {
    variables: { id: matchId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (!data || !data.match) return
      if (!data.match.isBye) {
        data.match.awayTeam.rosterEntries = addStatsToRosterEntries(
          data.match.awayTeam.rosterEntries,
          data.match.playerStats,
        )
        data.match.awayTeam.activeRosterEntries =
          data.match.awayTeam.activeRosterEntries &&
          data.match.awayTeam.rosterEntries &&
          data.match.awayTeam.rosterEntries.filter((rosterEntry: RosterEntry) =>
            data.match.awayTeam.activeRosterEntries
              .map((activeEntry: RosterEntry) => activeEntry.id)
              .includes(rosterEntry.id),
          )
        data.match.homeTeam.rosterEntries = addStatsToRosterEntries(
          data.match.homeTeam.rosterEntries,
          data.match.playerStats,
        )
        data.match.homeTeam.activeRosterEntries =
          data.match.homeTeam.activeRosterEntries &&
          data.match.homeTeam.rosterEntries &&
          data.match.homeTeam.rosterEntries.filter((rosterEntry: RosterEntry) =>
            data.match.homeTeam.activeRosterEntries
              .map((activeEntry: RosterEntry) => activeEntry.id)
              .includes(rosterEntry.id),
          )
      }
      setMatchData(data.match)
      setSkipLoader(true)
    },
  })

  return (
    <DataFetcher error={error} loading={loading} skip={skipLoader}>
      {matchData ? (
        <>
          <MatchDetailHero
            matchId={matchId}
            tournamentId={matchData.tournament ? matchData.tournament.id : ''}
            sportId={matchData.sport ? matchData.sport : ''}
            tournamentName={matchData.tournament ? matchData.tournament.name : ''}
            homeTeamId={matchData.homeTeam && matchData.homeTeam.id}
            homeTeamName={matchData.homeTeam && matchData.homeTeam.name}
            homeTeamWins={
              matchData.homeTeam && matchData.homeTeam.selectedSeasonStats
                ? matchData.homeTeam.selectedSeasonStats.wins
                : 0
            }
            homeTeamLosses={
              matchData.homeTeam && matchData.homeTeam.selectedSeasonStats
                ? matchData.homeTeam.selectedSeasonStats.losses
                : 0
            }
            homeTeamMembersCount={
              matchData.homeTeam && matchData.homeTeam.activeRosterEntries.length
            }
            awayTeamId={matchData.awayTeam && matchData.awayTeam.id}
            awayTeamName={matchData.awayTeam && matchData.awayTeam.name}
            awayTeamWins={
              matchData.awayTeam && matchData.awayTeam.selectedSeasonStats
                ? matchData.awayTeam.selectedSeasonStats.wins
                : 0
            }
            awayTeamLosses={
              matchData.awayTeam && matchData.awayTeam.selectedSeasonStats
                ? matchData.awayTeam.selectedSeasonStats.losses
                : 0
            }
            awayTeamMembersCount={
              matchData.awayTeam && matchData.awayTeam.activeRosterEntries.length
            }
            games={matchData.games}
            settled={matchData.settled}
            isBye={matchData.isBye}
          />
          <MatchDetailSubHero
            tournament={matchData.tournament ? matchData.tournament : ''}
            sportId={matchData.sport ? matchData.sport : ''}
            scheduledDate={matchData.scheduledDate}
            settled={matchData.settled}
            currentUserCanReport={matchData.currentUserCanReport}
            currentUserIsCoordinator={matchData.currentUserIsCoordinator}
            currentUserIsTeamMember={matchData.currentUserIsTeamMember}
            forfeit={matchData.forfeit}
            matchId={matchId}
            teams={[matchData.homeTeam, matchData.awayTeam]}
            rescheduleDate={matchData.rescheduleDate}
          />

          <Content>
            {!matchData.settled &&
              matchData.currentUserCanApproveReschedule &&
              matchData.rescheduleDate && (
                <Box mb={[5, 5, 7, 8]}>
                  <RescheduleMatchRequest
                    matchId={matchId}
                    rescheduleDate={matchData.rescheduleDate}
                  />
                </Box>
              )}

            <Box mb={[5, 5, 7, 8]}>
              {matchData.forfeit && matchData.forfeit.accepted ? (
                <MatchDetailForfeitedMatch />
              ) : (
                <MatchDetailGameResults
                  sportId={matchData.sport ? matchData.sport : ''}
                  games={matchData.games}
                  currentUserCanReport={matchData.currentUserCanReport}
                  currentUserIsTeamMember={matchData.currentUserIsTeamMember}
                  scheduledDate={matchData.scheduledDate}
                  homeTeam={matchData.homeTeam}
                  awayTeam={matchData.awayTeam}
                  settled={matchData.settled}
                  maps={matchData.maps}
                  parentLoading={loading}
                />
              )}
            </Box>
            {!matchData.settled && (
              <MatchDetailLineUps
                homeTeamData={{
                  sport: matchData.sport,
                  name: matchData.homeTeam && matchData.homeTeam.name,
                  rosterEntries: matchData.homeTeam && matchData.homeTeam.activeRosterEntries,
                  coordinatorId:
                    matchData.homeTeam &&
                    matchData.homeTeam.coordinator &&
                    matchData.homeTeam.coordinator.id,
                }}
                awayTeamData={{
                  sport: matchData.sport,
                  name: matchData.awayTeam && matchData.awayTeam.name,
                  rosterEntries: matchData.awayTeam && matchData.awayTeam.activeRosterEntries,
                  coordinatorId:
                    matchData.awayTeam &&
                    matchData.awayTeam.coordinator &&
                    matchData.awayTeam.coordinator.id,
                }}
              />
            )}
          </Content>
        </>
      ) : null}
    </DataFetcher>
  )
}
export default MatchDetail
