import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { SeasonAdminContext } from '../../contexts/SeasonAdminContext'
import { paths } from '../../utils/Routes'

const AdminSeasonCreateRedirect: React.FC = () => {
  const { seasonId } = useContext(SeasonAdminContext)

  return seasonId ? <Redirect to={paths.manageSeason(seasonId)} /> : null
}

export default AdminSeasonCreateRedirect
