import React from 'react'
import { Box, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { styled, MediaQueries as Mq, Theme } from '../../../styles/settings/theme'

import { paths } from '../../../utils/Routes'
import HeroImage from '../../../assets/images/hero__home-page.jpg'

import Animation from '../../atoms/Animation'
import { BaseButton } from '../../atoms/Buttons'
import Content from '../../atoms/Content'
import Card from '../../atoms/Card'
import Hero from '../../molecules/Hero'

const StyledCard = styled(Card)`
  padding: auto 1rem;
  background: ${props => props.theme.colors.white};

  h2 {
    margin-bottom: 1rem;
  }

  ${Mq.md} {
    padding: 2rem;
  }
`

const EventPageContent = styled(Box)`
  padding: 4rem 0 0;
`

const EventPageText = styled(Box)`
  max-width: 80%;
  margin: 0 auto 6rem;
`

const Divider = styled(Box)`
  height: 2px;
  background: ${props => props.theme.colors.teal};
  width: 100%;
  margin: 4rem 0;
`

const Footer = styled(Card)`
  color: ${props => props.theme.colors.white};
  padding: 4rem;
  background: ${props => props.theme.colors.primarynavy};
  border-radius: 0 6px;
  flex-direction: column;
  align-items: center
  text-align: left;
  p {
  color: ${props => props.theme.colors.white};
  }
`

const EventPage: React.FC = () => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      {/* Untyled HTML Section –––– START */}
      {/* If enabling Unstyled HTML please comment out unused imports and styled components above */}
      {/* <div className="container" style={{ background: '#fff', padding: '4rem' }}>
        <div className="header">
          <h4 className="header" style={{ marginBottom: '1rem' }}>
            Html Section content basic structures
          </h4>
          <p className="body">
            Lorem ipsum dolor sit amet consectetur adipiscing elit, quisque vestibulum enim duis
            bibendum nascetur, mus neque nostra curabitur metus lacinia. Inceptos ac interdum
            imperdiet quam turpis convallis maecenas rutrum, montes laoreet habitasse congue platea
            risus molestie, dictum fusce tristique condimentum varius integer fermentum.
          </p>
        </div>

        <div className="button" style={{ marginTop: '2rem' }}>
          <BaseButton variant="primary">Button</BaseButton>
        </div>
      </div> */}
      {/* Untyled HTML Section –––– END */}

      {/* Styled HTML Section –––– START */}
      <Hero
        title={'Event Page Template'}
        image={HeroImage}
        position="center 30%"
        height="18rem"
        tint={'3,51,89, 0.6'}
      ></Hero>
      <Content>
        <StyledCard mb={6} notch={true} notchSize={20}>
          <EventPageContent>
            <EventPageText>
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
              >
                <h2>Add pre-styled HTML here</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit, quisque vestibulum enim
                  duis bibendum nascetur, mus neque nostra curabitur metus lacinia. Inceptos ac
                  interdum imperdiet quam turpis convallis maecenas rutrum, montes laoreet habitasse
                  congue platea risus molestie, dictum fusce tristique condimentum varius integer
                  fermentum.
                </p>
                <Divider />
              </Animation>
            </EventPageText>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Footer notch={true} notchSize={20}>
                <Text mb={4}>
                  <h2>CTA Section</h2>
                </Text>
                <Text mb={7} color={Theme.colors.white}>
                  <ul>
                    <li>
                      <p>1. Create an account here</p>
                    </li>
                    <li>
                      <p>2. Register for your game of choice, with a team, or alone</p>
                    </li>
                    <li>
                      <p>3. Discover new challengers in our tournament bracket</p>
                    </li>
                    <li>
                      <p>4. Sharpen your skills as you compete to place in the money</p>
                    </li>
                    <li>
                      <p>5. Be rewarded for your wins from our cash prize pool</p>
                    </li>
                  </ul>
                </Text>
                <Link to={paths.signup()}>
                  <BaseButton variant="secondaryLight">Get Started</BaseButton>
                </Link>
              </Footer>
            </Animation>
          </EventPageContent>
        </StyledCard>
      </Content>
      {/* Styled HTML Section –––– END */}
    </>
  )
}

export default EventPage
