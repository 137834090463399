import { Flex, FlexProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

const ContentContainer = styled(Flex)<FlexProps>`
  max-width: 68.75rem;
  width: 100%;
  margin: auto;
`

export default ContentContainer
