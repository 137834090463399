import React, { useContext } from 'react'
import { Box, Text } from 'rebass'
import { isEmpty } from 'lodash'

import { Anchors } from './enums'
import { PendingInvitation } from '../../../types/graphql'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import GameSelector from '../../molecules/AddGames'
import TeamInvites from '../../molecules/TeamInvites'
import SectionTitle from '../../atoms/SectionTitle'

interface IAddGamesProps {
  onFormSubmitted: () => void
  changeAnchor: (anchor: string) => void
}

const AddGames: React.FC<IAddGamesProps> = ({ onFormSubmitted, changeAnchor }) => {
  const {
    selectedSports,
    pendingInvitations,
    acceptedInvitations,
    declinedInvitations,
    discordUsername,
    setAcceptedInvitations,
    setDeclinedInvitations,
    setSelectedSports,
    sportClickHandler,
  } = useContext(CurrentUserContext)

  const handleInvitationResponse = (accepted: boolean, invitation: PendingInvitation) => {
    const hasBeenAccepted = acceptedInvitations.includes(invitation)
    const hasBeenDeclined = declinedInvitations.includes(invitation)
    const addToAccepted = (invitation: PendingInvitation) => {
      setAcceptedInvitations([...acceptedInvitations, invitation])
      if (!selectedSports.includes(invitation.team.sportSlug)) {
        setSelectedSports([...selectedSports, invitation.team.sportSlug])
      }
    }
    const removeFromAccepted = (invitation: PendingInvitation) => {
      setAcceptedInvitations(acceptedInvitations.filter((i) => i !== invitation))
    }
    const addToDeclined = (invitation: PendingInvitation) => {
      setDeclinedInvitations([...declinedInvitations, invitation])
    }
    const removeFromDeclined = (invitation: PendingInvitation) => {
      setDeclinedInvitations(declinedInvitations.filter((i) => i !== invitation))
    }

    if (accepted) {
      if (hasBeenAccepted) {
        removeFromAccepted(invitation)
      } else {
        addToAccepted(invitation)
        removeFromDeclined(invitation)
      }
    } else {
      if (hasBeenDeclined) {
        removeFromDeclined(invitation)
      } else {
        addToDeclined(invitation)
        removeFromAccepted(invitation)
      }
    }
  }

  if (!discordUsername) {
    changeAnchor(Anchors.AboutMe)
  }

  return (
    <Box>
      <GameSelector
        selectedSports={selectedSports}
        sportClickHandler={sportClickHandler}
        headerText="what games will you play?"
        subheaderText="Please select all the games you would like to play at CSL"
        submitButtonText="continue"
        onFormSubmitted={onFormSubmitted}
      />
      {!isEmpty(pendingInvitations) && (
        <Box px={[0, 0, 7]} mt={6}>
          <SectionTitle text="team invites" align="center" mb="5" />
          <Text textAlign="center" mb={6}>
            <p>
              Congrats! It looks like you’ve been invited to join a team. Would you like to add this
              sport and join the team?
            </p>
          </Text>

          <TeamInvites
            pendingInvitations={pendingInvitations as PendingInvitation[]}
            handleRepsonse={handleInvitationResponse}
            acceptedInvitations={acceptedInvitations as PendingInvitation[]}
            declinedInvitations={declinedInvitations as PendingInvitation[]}
          />
        </Box>
      )}
    </Box>
  )
}

export default AddGames
