import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Box, Text } from 'rebass'
import { Hidden } from '@material-ui/core'
import { styled, Theme, MediaQueries as Mq } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import Animation from '../atoms/Animation'
import WideContainer from '../atoms/WideContainer'
import WideContent from '../atoms/WideContent'

interface IHeroProps {
  borderColor?: any
}

const StyledWideContent = styled(WideContent)`
  padding: 2rem;
  background: ${props => props.theme.colors.primarynavy};

  h4 {
    padding: 1rem 0;
    transition: ${props => props.theme.transitions.fast};
    &:hover {
      color: ${props => props.theme.colors.primarylightblue};
    }
    ${Mq.md} {
      padding: 0;
    }
  }
`

const Divider = styled(Box)`
  width: 1px;
  background-color: ${props => props.theme.colors.primarylightblue};
`

const HomePageValueProps: React.FC<IHeroProps> = () => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <StyledWideContent>
      <WideContainer>
        <Flex justifyContent="space-between" flexWrap={['wrap', 'wrap', 'nowrap']}>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 'max-content']}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              <Link to={paths.signup()}>
                <Text color={Theme.colors.white} textAlign={['center', 'center', 'left']}>
                  <h4>Great prizes</h4>
                </Text>
              </Link>
            </Animation>
          </Box>
          <Hidden smDown>
            <Divider width={1} />
          </Hidden>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 'max-content']}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
            >
              <Link to={paths.signup()}>
                <Text color={Theme.colors.white} textAlign="center">
                  <h4>6 Weeks of league play</h4>
                </Text>
              </Link>
            </Animation>
          </Box>
          <Hidden smDown>
            <Divider width={1} />
          </Hidden>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 'max-content']}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Link to={paths.signup()}>
                <Text color={Theme.colors.white} textAlign="center">
                  <h4>Fun for all skill levels</h4>
                </Text>
              </Link>
            </Animation>
          </Box>
          <Hidden smDown>
            <Divider width={1} />
          </Hidden>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 'max-content']}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
            >
              <Link to={paths.signup()}>
                <Text color={Theme.colors.white} textAlign="center">
                  <h4>Register now!</h4>
                </Text>
              </Link>
            </Animation>
          </Box>
        </Flex>
      </WideContainer>
    </StyledWideContent>
  )
}

export default HomePageValueProps
