import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'emotion-theming'
import { Flex, FlexProps, Text } from 'rebass'
import { styled, ThemeColors } from '../../styles/settings/theme'

import { LeftArrowIcon } from '../../assets/icons/left-arrow-icon'

const BackButtonContainer = styled(Flex)<FlexProps>`
  cursor: pointer;
`

interface IBackButtonProps {
  onClick?: () => void
  buttonColor?: ThemeColors
  text?: string
  textColor?: ThemeColors
}

const BackButton: React.FC<IBackButtonProps> = ({ onClick, buttonColor, text, textColor }) => {
  const history = useHistory()
  const { colors } = useTheme()

  const goBack = () => {
    history.goBack()
  }

  return (
    <BackButtonContainer alignItems="center" mb={5} onClick={onClick || goBack}>
      <LeftArrowIcon color={buttonColor ? colors[buttonColor] : undefined} />
      <Text ml={3} color={textColor ? colors[textColor] : colors.primarynavy}>
        <h6>{text || 'Back'}</h6>
      </Text>
    </BackButtonContainer>
  )
}

export default BackButton
