import React, { useState } from 'react'
import { Flex, Box, Text } from 'rebass'
import { useTheme } from 'emotion-theming'
import { styled } from '../../styles/settings/theme'

import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import { GiSkullSabertooth, GiJetFighter } from 'react-icons/gi'

interface IContactFormProps {
  action?: string
  supportForm?: boolean
}

const ContactFormWrapper = styled(Box)`
  padding: 4rem 0 2rem;
  svg {
    margin-right: 1rem;
    margin-top: -4px;
  }
`

const ContactForm: React.FC<IContactFormProps> = ({ action, supportForm }) => {
  const [status, setStatus] = useState('')
  const { colors } = useTheme()
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  const handleFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus('SUCCESS')
      } else {
        setStatus('ERROR')
      }
    }
    xhr.send(data)
  }

  return (
    <ContactFormWrapper>
      <Animation
        initial="hidden"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
      >
        <form onSubmit={handleFormSubmit} action={action} method="POST">
          <Flex
            mb={4}
            width="auto"
            mx={-2}
            alignItems="center"
            justifyContent="space-between"
            flexDirection={['column', 'column', 'row']}
          >
            <Box width={1} px={2}>
              <InputFieldWithErrors
                type="text"
                name="firstName"
                label="First Name"
                placeholder=""
                isNameField
              />
            </Box>
            <Box width={1} px={2} mt={[4, 4, 0]}>
              <InputFieldWithErrors
                type="text"
                name="lastName"
                label="Last Name"
                placeholder=""
                isNameField
              />
            </Box>
          </Flex>
          <Flex
            mb={4}
            width="auto"
            mx={-2}
            alignItems="center"
            justifyContent="space-between"
            flexDirection={['column', 'column', 'row']}
          >
            <Box width={1} px={2}>
              <InputFieldWithErrors
                type="email"
                name="email"
                label="Email Address"
                placeholder="Use your account email if possible"
                isEmailField
              />
            </Box>
            <Box width={1} px={2} mt={[4, 4, 0]}>
              <InputFieldWithErrors
                type="tel"
                name="phone"
                label="Phone Number"
                placeholder="(123) 456-7890"
              />
            </Box>
          </Flex>
          <Flex mb={4} width="auto" alignItems="center" justifyContent="space-between">
            <Box width={1}>
              <InputFieldWithErrors
                type="text"
                name="message"
                label={supportForm ? 'Describe Your Issue' : 'Your Message'}
                placeholder={
                  supportForm
                    ? 'What is your issue and how can we replicate it?'
                    : 'Let us know how we can help!'
                }
              />
            </Box>
          </Flex>
          <Flex mt={4} width="auto" justifyContent="space-between" alignItems="center">
            <Box width={1}>
              {status === 'SUCCESS' && (
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                >
                  <Flex alignItems="center">
                    <GiJetFighter color={colors.green} size={30} />
                    <Text color={colors.green}>
                      <p>Your message was sent successfully</p>
                    </Text>
                  </Flex>
                </Animation>
              )}
              {status === 'ERROR' && (
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                >
                  <Flex alignItems="center">
                    <GiSkullSabertooth color={colors.red} size={30} />
                    <Text color={colors.red}>
                      <p>Ooops! There was an error. Please try again.</p>
                    </Text>
                  </Flex>
                </Animation>
              )}
            </Box>
            <Box width={1}>
              <BaseButton variant="primary">Submit</BaseButton>
            </Box>
          </Flex>
        </form>
      </Animation>
    </ContactFormWrapper>
  )
}

export default ContactForm
