import React from 'react'
import { Flex, FlexProps, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { SetState } from '../../types/aliases'
import { BaseButton } from './Buttons'

interface IProps {
  email: string
  setHideBanner: SetState<boolean>
}

const NavBanner = styled(Flex)<FlexProps>`
  width: 100%;
  flex-direction: column;
  height: auto;
  display: flex;
  padding: ${props => props.theme.space[3]}px;

  ${Mq.md} {
    height: 4rem;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0;

    a {
      color: ${props => props.theme.colors.white};
    }
  }
`

const VerifyEmailBanner: React.FC<IProps> = ({ email, setHideBanner }) => {
  return (
    <NavBanner>
      <Text mr={3}>
        <h6>Please check your email at {email} to verify this account</h6>
      </Text>

      <Link
        to={{
          pathname: paths.resendConfirmation(),
          state: {
            authorized: true,
            from: window.location.pathname,
          },
        }}
      >
        <BaseButton
          mr={3}
          mt={[3, 3, 3, 0]}
          variant="whiteSecondary"
          onClick={() => setHideBanner(true)}
        >
          resend email
        </BaseButton>
      </Link>
    </NavBanner>
  )
}

export default VerifyEmailBanner
