import React, { ReactNode } from 'react'
import { Box, BoxProps, Flex, FlexProps, Text } from 'rebass'
import { css } from '@emotion/core'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

import HorizontalTableHeader from '../atoms/HorizontalTableHeader'

interface RowProps {
  showModal?: () => void
}
const Row = styled(Flex)<RowProps & FlexProps>`
  border-bottom: 2px solid ${props => props.theme.colors.middlegray};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  :last-child {
    border-bottom: none;
  }
  ${props =>
    props.showModal &&
    css`
      cursor: pointer;
      transition: ${props.theme.transitions.slow};
      &:hover {
        color: ${props.theme.colors.primarynavy};
      }
    `}
`
const Circle = styled(Box)<BoxProps>`
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
`
const StyledText = styled(Text)`
  max-width: 200px;
  ${Mq.md} {
    max-width: 500px;
  }
`
interface InfoRowProps {
  icon?: any
  text: string | Array<string>
  data?: string | Array<string>
  color?: string
  height?: string
  showModal?: () => void
  currTournament?: boolean
}

const circleColor = (tournament: boolean | undefined) => {
  return tournament ? 'green' : 'middlegray'
}

export const InfoRow = ({
  icon,
  text,
  data,
  color,
  height,
  showModal,
  currTournament,
}: InfoRowProps) => (
  <Row
    onClick={showModal ? () => showModal() : undefined}
    height={height}
    showModal={showModal}
    color={color}
  >
    <Flex alignItems="center">
      {icon && <Box mr={4}>{icon}</Box>}
      <StyledText>
        <h6>{text}</h6>
      </StyledText>
    </Flex>
    <Flex alignItems="center">
      {text === 'status' && <Circle bg={circleColor(currTournament)} mr={2} mb={1} />}
      <Text>
        <h6>{data}</h6>
      </Text>
    </Flex>
  </Row>
)

const InfoBoxContent = styled(Box)<BoxProps>`
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  --notchSize: 20px;
  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - 0px),
    0% 100%,
    0% 100%,
    0% 100%
  );
`

interface InfoProps {
  icon?: ReactNode
  children?: ReactNode
  title?: string
}

export const InfoBoxHalf = ({ icon, title, children }: InfoProps) => {
  return (
    <InfoBoxContent>
      <HorizontalTableHeader>
        {icon && <Box mr={5}>{icon}</Box>}
        <Text color="white">
          <h4>{title}</h4>
        </Text>
      </HorizontalTableHeader>
      <Box pt={4} pl={4} pr={4}>
        {children}
      </Box>
    </InfoBoxContent>
  )
}
