import gql from 'graphql-tag'
import { useTheme } from 'emotion-theming'
import { Box, Flex, Link as ExternalLink } from 'rebass'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { RouteComponentProps, Redirect, Link } from 'react-router-dom'
import { Text } from 'rebass'
import { Dictionary } from 'lodash'
import { styled, MediaQueries as Mq } from '../../styles/settings'

import {
  useSignInMutation as useSignIn,
  useSignUpMutation as useSignUp,
  UserForAuth,
} from '../../types/graphql'
import { handleResult, errorOn } from '../../utils/results'
import { paths } from '../../utils/Routes'
import { AuthenticationAttributes } from '../../types/fragments'
import { AuthFormContent, AuthFormBox } from '../atoms/AuthFormPieces'
import { BaseButton } from '../atoms/Buttons'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import { updateUserAuth } from '../../utils/formUtils'
import Animation from '../atoms/Animation'
import LoadingSpinner from '../atoms/LoadingSpinner'
import SectionTitle from '../atoms/SectionTitle'
import MarketingEmailsOptIn from '../molecules/MarketingEmailsOptIn'

gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      success
      value {
        ...AuthenticationAttributes
      }
      errors {
        field
        message
      }
    }
  }
  ${AuthenticationAttributes}
`

gql`
  mutation signUp(
    $email: String!
    $password: String!
    $username: String!
    $invitationToken: String
    $acceptedAllThree: Boolean!
    $marketingEmailsCd: Int!
    $age: Int!
    $parentEmail: String
    $parentName: String
  ) {
    signUp(
      email: $email
      password: $password
      username: $username
      invitationToken: $invitationToken
      acceptedAllThree: $acceptedAllThree
      marketingEmailsCd: $marketingEmailsCd
      age: $age
      parentEmail: $parentEmail
      parentName: $parentName
    ) {
      success
      value {
        ...AuthenticationAttributes
      }
      errors {
        field
        message
      }
    }
  }
  ${AuthenticationAttributes}
`

const StyledFlex = styled(Flex)`
  position: relative;
`

const CustomBox = styled(Box)`
  width: 100%;
  margin: 2rem auto 0;
  border: 2px solid ${(props) => props.theme.colors.middlegray};
  padding: 2rem;

  ${Mq.md} {
    width: 80%;
  }
`

const LoginLink = styled(Link)`
  position: absolute;
  right: 30px;
  top: 35px;
`

interface IProps extends RouteComponentProps {
  isSignUp: boolean
  invitationToken?: string
}

const LogIn: React.FC<IProps> = ({ isSignUp, invitationToken, location: { state } }) => {
  const { colors } = useTheme()

  const [email, setEmail] = useState('')
  const [parentName, setParentName] = useState('')
  const [parentEmail, setParentEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [acceptedOne, setAcceptedOne] = useState(false)
  const [acceptedTwo, setAcceptedTwo] = useState(false)
  const [acceptedThree, setAcceptedThree] = useState(false)
  const [acceptedAllThree, setAcceptedAllThree] = useState(false)
  const [age, setAge] = useState<number>()
  const [marketingEmailsCd, setMarketingEmailsCd] = useState(0)
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [formErrors, setFormErrors] = useState<Dictionary<string>>({})
  const [signIn, { loading: signInLoading }] = useSignIn()
  const [signUp, { loading: signUpLoading }] = useSignUp()

  const variables = { email, password }

  const handleOnSuccess = (value: UserForAuth) => {
    updateUserAuth(value)

    window.location.href = value.isIncompleteAccount ? paths.completeProfile() : paths.homepage()
  }

  const handleSignIn = async () => {
    try {
      const { data } = await signIn({ variables })
      handleResult({
        result: data!.signIn,
        onSuccess: (value) => handleOnSuccess(value),
        onFailure: (errors) => toast.error(errorOn('base', errors), { containerId: 'temporary' }),
      })
    } catch (err) {
      console.error(err)
    }
  }
  acceptedOne && acceptedTwo && acceptedThree && !acceptedAllThree && setAcceptedAllThree(true)

  const handleSignUp = async () => {
    try {
      if (age) {
        const { data } = await signUp({
          variables: {
            ...variables,
            username,
            invitationToken,
            age,
            parentName,
            parentEmail,
            acceptedAllThree,
            marketingEmailsCd,
          },
        })
        handleResult({
          result: data!.signUp,
          onSuccess: (value) => handleOnSuccess(value as UserForAuth),
          onFailure: (errors) => {
            const personalEmailError = errorOn('personalEmail', errors)

            if (personalEmailError) {
              setFormErrors({
                ...errors,
                email: personalEmailError.replace('Personal ', ''),
                personalEmail: '',
              })
            } else {
              setFormErrors(errors)
            }
          },
        })
      }
    } catch (err) {
      toast.error(err.graphQLErrors[0].message)
      console.error(err)
    }
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    isSignUp ? handleSignUp() : handleSignIn()
  }

  const loading = signInLoading || signUpLoading
  const formErrorMessages = Object.values(formErrors).filter((error) => error)
  const formError = !!formErrorMessages.length

  const isButtonDisabled =
    loading ||
    formError ||
    email === '' ||
    password === '' ||
    (isSignUp && passwordConfirmation === '') ||
    (isSignUp && username === '') ||
    (isSignUp && !acceptedThree) ||
    (isSignUp && age && age < 15) ||
    (isSignUp && !!age && age < 19 && parentName === '' && parentEmail === '')
  const stateProp = state as any
  if (stateProp && stateProp.message) {
    toast.success(stateProp.message, { containerId: 'temporary' })

    return (
      <Redirect
        to={{
          pathname: '/logIn',
          state: null,
        }}
      />
    )
  }

  const variants = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    fade: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <AuthFormContent onSubmit={handleSubmit}>
      {isSignUp && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: `
      <!--
      Start of Floodlight Tag: Please do not remove
      Activity name of this tag: 2020 - Telus - CSL - National - Register Submit
      URL of the webpage where the tag is expected to be placed: http://telus.com
      This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
      Creation Date: 12/10/2020      
      -->`,
            }}
          />
          <script type="text/javascript">
            var axel = Math.random() + ""; var a = axel * 10000000000000; document.write('
            <iframe
              title="tracker"
              // eslint-disable-next-line
              src="https://6587013.fls.doubleclick.net/activityi;src=6587013;type=telus00;cat=2020-02r;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?"
              width="1"
              height="1"
              style={{ display: 'none' }}
            ></iframe>
            ');
          </script>
          <noscript>
            <iframe
              title="tracker"
              // eslint-disable-next-line
              src="https://6587013.fls.doubleclick.net/activityi;src=6587013;type=telus00;cat=2020-02r;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
              width="1"
              height="1"
              style={{ display: 'none' }}
            ></iframe>
          </noscript>
          <div
            dangerouslySetInnerHTML={{
              __html: `
    <!-- End of Floodlight Tag: Please do not remove -->`,
            }}
          />
          <LoginLink to={paths.logIn()}>
            <Text color={colors.primarynavy}>
              <h6>Login</h6>
            </Text>
          </LoginLink>
        </>
      )}

      <AuthFormBox>
        <Animation
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
        >
          <SectionTitle
            text={isSignUp ? 'Register for CSL' : 'Sign in to csl'}
            align="center"
            mb=".75rem"
          />
        </Animation>
        {!isSignUp && (
          <Animation
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.2 }}
          >
            <Text textAlign="center">
              <p>
                Welcome back! Please select a method
                <br />
                for signing in from the options below.
              </p>
            </Text>
          </Animation>
        )}
        <CustomBox>
          <Animation
            initial="fade"
            animate="visible"
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.2 }}
          >
            <>
              {!isSignUp && (
                <Flex>
                  <Box width={1 / 2}>
                    <h5>Sign In</h5>
                  </Box>
                  <Box width={1 / 2}>
                    <Text textAlign="right">
                      <Link to={paths.forgotPassword()}>
                        <h5>Forgot password</h5>
                      </Link>
                    </Text>
                  </Box>
                </Flex>
              )}
              <Flex width="auto" mx={-2} mt={6} flexDirection={['column', 'column', 'row', 'row']}>
                {isSignUp && (
                  <Box width={1} margin="auto" px={2}>
                    <InputFieldWithErrors
                      mt={3}
                      mb={4}
                      key="username"
                      type="text"
                      name="username"
                      label="username"
                      placeholder="username"
                      errorMessage={formErrors['username']}
                      value={username}
                      updateSingleField={(field) => {
                        setUsername(field)
                      }}
                      updateErrors={(errors) => setFormErrors({ ...formErrors, ...errors })}
                    />
                  </Box>
                )}
                <Box width={1} margin="auto" px={2}>
                  <InputFieldWithErrors
                    mt={3}
                    mb={4}
                    key="email"
                    type="text"
                    name="email"
                    label="email"
                    placeholder="email"
                    value={email}
                    isEmailField={true}
                    updateSingleField={(field) => {
                      setEmail(field)
                    }}
                    updateErrors={(errors) => setFormErrors({ ...formErrors, ...errors })}
                    errorMessage={formErrors['email']}
                  />
                </Box>
              </Flex>
              <StyledFlex
                width="auto"
                mt={2}
                mx={-2}
                flexDirection={['column', 'column', 'row', 'row']}
              >
                <Box width={1} margin="auto" px={2}>
                  <InputFieldWithErrors
                    mt={3}
                    mb={4}
                    key="password"
                    type="password"
                    name="password"
                    label="password"
                    placeholder="password"
                    value={password}
                    updateSingleField={(field) => {
                      setPassword(field)
                    }}
                    isRequired={true}
                    updateErrors={(errors) => setFormErrors({ ...formErrors, ...errors })}
                    errorMessage={formErrors['password']}
                    otherPassword={passwordConfirmation}
                  />
                </Box>
                {isSignUp && (
                  <Box width={1} margin="auto" px={2}>
                    <InputFieldWithErrors
                      mt={3}
                      mb={4}
                      key="passwordConfirmation"
                      type="password"
                      name="password"
                      label="confirm password"
                      placeholder="confirm password"
                      value={passwordConfirmation}
                      updateSingleField={(field) => {
                        setPasswordConfirmation(field)
                      }}
                      updateErrors={(errors) => setFormErrors({ ...formErrors, ...errors })}
                      errorMessage={formErrors['password']}
                      isRequired={true}
                      otherPassword={password}
                    />
                  </Box>
                )}
              </StyledFlex>
              {isSignUp && (
                <>
                  <StyledFlex
                    width="auto"
                    mt={2}
                    mx={-2}
                    flexDirection={['column', 'column', 'row', 'row']}
                  >
                    <Box width={1} margin="auto" px={2}>
                      <InputFieldWithErrors
                        mt={3}
                        mb={4}
                        key="age"
                        type="number"
                        name="age"
                        label="age"
                        placeholder=""
                        value={age}
                        isAgeField={true}
                        updateSingleField={(field) => {
                          setAge(+field)
                        }}
                        isRequired={true}
                        updateErrors={(errors) => setFormErrors({ ...formErrors, ...errors })}
                        errorMessage={formErrors['age']}
                      />
                    </Box>
                  </StyledFlex>
                  {age && age > 14 && age < 19 && (
                    <StyledFlex
                      width="auto"
                      mt={2}
                      mx={-2}
                      flexDirection={['column', 'column', 'row', 'row']}
                    >
                      <Box width={1} margin="auto" px={2}>
                        <InputFieldWithErrors
                          mt={3}
                          mb={4}
                          key="parentName"
                          type="text"
                          name="parentName"
                          label="Parent's Name"
                          placeholder="enter your parent's name"
                          value={parentName}
                          updateSingleField={(field) => {
                            setParentName(field)
                          }}
                          isRequired={true}
                          updateErrors={(errors) => setFormErrors({ ...formErrors, ...errors })}
                          errorMessage={formErrors['parentName']}
                        />
                      </Box>
                      <Box width={1} margin="auto" px={2}>
                        <InputFieldWithErrors
                          mt={3}
                          mb={4}
                          key="parentEmail"
                          type="email"
                          name="parentEmail"
                          label="Parent's Email"
                          placeholder="Enter your parent's email address"
                          value={parentEmail}
                          isEmailField={true}
                          updateSingleField={(field) => {
                            setParentEmail(field)
                          }}
                          isRequired={true}
                          updateErrors={(errors) => setFormErrors({ ...formErrors, ...errors })}
                          errorMessage={formErrors['parentEmail']}
                        />
                      </Box>
                    </StyledFlex>
                  )}
                  <StyledFlex
                    width="100%"
                    mt={2}
                    mx={-2}
                    justifyContent="spaceAround"
                    alignItems="center"
                    flexDirection={['column', 'column', 'column', 'column']}
                  >
                    <Box width={1} margin="auto" mb={'2rem'} px={2}>
                      <h2>To proceed you must click the following three links:</h2>
                    </Box>
                    <Box width={1} margin="auto" mb={'2rem'} px={2}>
                      <ExternalLink
                        href="https://assets.ctfassets.net/rz9m1rynx8pv/GPOqrAXSlolHtqDXX8oNI/5c1b3e59a1bf2653aec0e9c910dc33c7/Privacy_commitment_-_June_4_2020_-_EN.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h2 onClick={() => setAcceptedOne(true)}>Privacy Policy </h2>
                      </ExternalLink>
                    </Box>
                    <Box width={1} margin="auto" mb={'2rem'} px={2}>
                      <ExternalLink
                        href="https://www.telus.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h2 onClick={() => setAcceptedTwo(true)}>Terms and Conditions</h2>
                      </ExternalLink>
                    </Box>
                    <Box width={1} margin="auto" mb={'2rem'} px={2}>
                      <ExternalLink
                        href="https://www.telus.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h2 onClick={() => setAcceptedThree(true)}>Game Rules</h2>
                      </ExternalLink>
                    </Box>
                  </StyledFlex>
                  <StyledFlex
                    width="auto"
                    mt={2}
                    mx={-2}
                    flexDirection={['column', 'column', 'column', 'column']}
                  >
                    <Box width={1} margin="auto" px={2}>
                      <MarketingEmailsOptIn
                        marketingEmailsCd={marketingEmailsCd}
                        setMarketingEmailsCd={setMarketingEmailsCd}
                      />
                    </Box>
                  </StyledFlex>
                </>
              )}
              <br />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <StyledFlex width="auto" alignItems="flex-end" flexDirection="column">
                  <Box width="auto">
                    <BaseButton
                      variant={isButtonDisabled ? 'primaryDisabled' : 'primary'}
                      disabled={isButtonDisabled}
                      type="submit"
                    >
                      {isSignUp ? 'Sign Up' : 'Sign In'}
                    </BaseButton>
                  </Box>
                </StyledFlex>
              )}
            </>
          </Animation>
        </CustomBox>
      </AuthFormBox>
    </AuthFormContent>
  )
}

export default LogIn
