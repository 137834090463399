import React, { ReactNode } from 'react'
import { Text, TextProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

interface ITextProps extends TextProps {
  text: string | ReactNode
  ml?: string
  mr?: string
  mt?: string
  mb?: string
  color?: string
  position?: string
  align?: string
}

const TitleText = styled(Text)<ITextProps & TextProps>`
  font-family: ${props => props.theme.fonts.wide};
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${props => props.color};
  position: ${props => props.position};
  text-align: ${props => props.align};
`

const SectionTitle = ({ text, ml, mr, mt, mb, color, position, align }: ITextProps) => (
  <TitleText
    ml={ml}
    mr={mr}
    mt={mt}
    mb={mb}
    color={color}
    position={position}
    align={align}
    text={text}
  >
    {text}
  </TitleText>
)

export default SectionTitle
