import React from 'react'
import { Flex, Box, Text } from 'rebass'
import { useTheme } from 'emotion-theming'

import { BaseButton } from '../../atoms/Buttons'
import TextEditor from '../../molecules/TextEditor'
import { FormCard } from '../../molecules/FormCards'
import { Mode } from './enums'

interface IPostContentFormFieldsProps {
  currentMode: Mode
  body: string
  onBodyChanged: (body: string) => void
  posted: boolean
}

const PostContentFormFields: React.FC<IPostContentFormFieldsProps> = ({
  body,
  onBodyChanged,
  posted,
}) => {
  const { colors } = useTheme()
  return (
    <FormCard title={'Post Content'}>
      <Flex p={5} flexWrap="wrap">
        <Box width={1 / 2}>
          <Text>
            <h3>Add Your Post Content Here</h3>
          </Text>
          <Text>
            <p>
              For consistent posts, try and adhere to the <br />
              design styles listed to the right:
            </p>
          </Text>
        </Box>

        <Box width={1 / 2}>
          <Text textAlign="right">
            <h3 style={{ color: colors.orange }}>
              Section Header - Header 3 in Orange {colors.orange}
            </h3>
          </Text>
          <Text textAlign="right">
            <h5 style={{ color: colors.primarynavy }}>
              Sub Header - Header 5 in Navy {colors.primarynavy}
            </h5>
          </Text>
          <Text textAlign="right">
            <p>Body font - Paragraph in black {colors.black}</p>
          </Text>
        </Box>
      </Flex>

      <Flex p={5}>
        <TextEditor text={body} handleText={onBodyChanged} placeholder={'...'} />
      </Flex>

      <Flex p={5} flexWrap="wrap" justifyContent="flex-end">
        {/* TODO: rename button once schedule functionality exists */}
        {/* NOTE: no onClick handler since side effects are
            handled by the parent form onSubmit
          */}
        {posted ? (
          <BaseButton variant="primaryDisabled" disabled={true} mr={4}>
            Post
          </BaseButton>
        ) : (
          <BaseButton mr={4}>Post</BaseButton>
        )}
      </Flex>
    </FormCard>
  )
}

export default PostContentFormFields
