import React from 'react'
import { Flex } from 'rebass'

import { Round, Line } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getQuadrant } from '../../utils/bracketDataUtils'

interface RoundProps {
  flip?: boolean
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const RoundOf64: React.FunctionComponent<RoundProps> = ({
  seatList,
  flip,
  shift,
  bottomHalf,
  numTeams,
}) => {
  const topLeft = getQuadrant(seatList, 1)
  const topRight = getQuadrant(seatList, 2)
  const bottomLeft = getQuadrant(seatList, 3)
  const bottomRight = getQuadrant(seatList, 4)

  let logicalSeats =
    flip && bottomHalf ? bottomRight : bottomHalf ? bottomLeft : flip ? topRight : topLeft

  const top: { [key: number]: string } = {
    128: bottomHalf ? '70px' : '35px',
    64: bottomHalf ? '40px' : '0px',
  }

  return (
    <Round round="64" top={top[numTeams]} shift={shift}>
      {logicalSeats.map((tuple, i) => (
        <Flex key={i}>
          {flip && <Line numTeams={numTeams} round="64" flip={flip} />}
          <BracketCouplet seats={tuple} round="64" bottom="87px" flip={flip} numTeams={numTeams} />
          {!flip && <Line numTeams={numTeams} round="64" />}
        </Flex>
      ))}
    </Round>
  )
}

export default RoundOf64
