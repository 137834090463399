import React from 'react'
import { FaFacebook, FaTwitch, FaTwitter } from 'react-icons/fa'
import { Flex, Link, Text } from 'rebass'

interface IconProps {
  fb?: string
  twitch?: string
  twitter?: string
}

const SocialIcons: React.FunctionComponent<IconProps> = ({ fb, twitch, twitter }) => {
  return (
    <Flex>
      {(fb || twitch || twitter) && (
        <Text mr={4} color="white">
          <h5>|</h5>
        </Text>
      )}
      {fb && (
        <Link href={`https://facebook.com/${fb}`} mr={'.5rem'} target="_blank">
          <FaFacebook color="white" />
        </Link>
      )}
      {twitch && (
        <Link href={`https://twitch.tv/${twitch}`} mr={'.5rem'} target="_blank">
          <FaTwitch color="white" />
        </Link>
      )}
      {twitter && (
        <Link href={`https://twitter.com/${twitter}`} target="_blank">
          <FaTwitter color="white" />
        </Link>
      )}
    </Flex>
  )
}

export default SocialIcons
