import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import gql from 'graphql-tag'
import React, { useState } from 'react'

import TeamRegistryModal from './TeamRegistryModal'
import ContactCoordinatorModal from './ContactCoordinatorModal'
import LoadingSpinner from '../../atoms/LoadingSpinner'
import { handleResult, errorOn } from '../../../utils/results'
import {
  useTournamentDetailRegModalQuery,
  Team,
  useRegisterTeamForTournamentMutation as useRegisterTeamForTournament,
  useUnregisterTeamFromTournamentMutation as useUnregisterTeamFromTournament,
} from '../../../types/graphql'

import { TournamentDetailCurrentUser } from '../../../types/fragments'

gql`
  query TournamentDetailRegModal {
    currentUser {
      ...TournamentDetail_CurrentUser
    }
  }
  ${TournamentDetailCurrentUser}

  mutation RegisterTeamForTournament($teamId: ID!, $tournamentId: ID!) {
    registerTeamForTournament(teamId: $teamId, tournamentId: $tournamentId) {
      success
      value
      errors {
        field
        message
      }
    }
  }

  mutation UnregisterTeamFromTournament($id: ID!) {
    deleteTournamentRegistry(id: $id) {
      success
      errors {
        field
        message
      }
    }
  }
`

interface ITournamentRegistrationModalProps {
  tournamentId: string
  name: string
  sport: string
  hideModal: () => void
  teamsLeftToRegister: boolean
}

const TournamentRegistrationModal: React.FC<ITournamentRegistrationModalProps> = ({
  tournamentId,
  name,
  sport,
  hideModal,
  teamsLeftToRegister,
}) => {
  const [registerTeamForTournament] = useRegisterTeamForTournament()
  const [unregisterTeamFromTournament] = useUnregisterTeamFromTournament()
  const [teams, setTeams] = useState<Team[]>([])
  const [teamsLoaded, setTeamsLoaded] = useState(false)
  useTournamentDetailRegModalQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setTeamsLoaded(true)
      if (data && data.currentUser) {
        setTeams(data.currentUser.coordinatingTeams.filter(t => t.sportSlug === sport) as Team[])
      }
    },
  })

  const registerTeam = async (team: Team) => {
    try {
      const { data } = await registerTeamForTournament({
        variables: {
          teamId: team.id,
          tournamentId,
        },
        refetchQueries: ['TournamentDetail'],
      })
      handleResult({
        result: data!.registerTeamForTournament,
        onSuccess: _value => {
          return toast.success(`${team.name} has been registered for ${name}!`, {
            containerId: 'temporary',
          })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const unregisterTeam = async (team: Team) => {
    try {
      const { data } = await unregisterTeamFromTournament({
        variables: {
          id: team.tournamentRegistries.find(registry => registry.tournament.id === tournamentId)!
            .id,
        },
        refetchQueries: ['TournamentDetail'],
      })
      handleResult({
        result: data!.deleteTournamentRegistry,
        onSuccess: _value => {
          return toast.success(`${team.name} has been unregistered from ${name}!`, {
            containerId: 'temporary',
          })
        },
        onFailure: errors => {
          return toast.error(errorOn('base', errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  if (!teamsLoaded) {
    return <LoadingSpinner />
  }

  if (isEmpty(teams)) {
    return (
      <ContactCoordinatorModal
        tournamentId={tournamentId}
        teamsLeftToRegister={teamsLeftToRegister}
      />
    )
  }

  return (
    <TeamRegistryModal
      tournamentId={tournamentId}
      teams={teams}
      name={name}
      hideModal={hideModal}
      onToggleOn={registerTeam}
      onToggleOff={unregisterTeam}
    />
  )
}

export default TournamentRegistrationModal
