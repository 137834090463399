import { Box, Text } from 'rebass'
import { CheckCircle } from 'react-feather'
import { useTheme } from 'emotion-theming'
import React from 'react'

import Alert from '../atoms/Alert'

const ForfeitsEmptyState: React.FC = () => {
  const { colors } = useTheme()

  return (
    <Box p={[5]}>
      <Alert borderColor={colors.middlegray} mx="auto">
        <CheckCircle size={38} color={colors.green} />
        <Text my={5} color={colors.darkgray}>
          <h6>You are all up to date!</h6>
        </Text>
        <Text mb={5}>
          <p>
            There are no pending forfeits for review. Please check back soon to see any new forfeits
            to approve or deny.
          </p>
        </Text>
      </Alert>
    </Box>
  )
}

export default ForfeitsEmptyState
