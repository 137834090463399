export interface FieldType {
  name: string
  type: string
  label: string
  placeholder: string
  required: boolean
  query?: string
  exclusiveOf?: string
  maxLength?: number
}

export interface SelectObjectType {
  id: string
  name: string
}

export const allFieldsBySport: any = {
  lol: [
    {
      name: 'lolSummonerName',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Summoner Name',
      required: true,
    },
    {
      name: 'lolChampionName',
      type: 'select',
      label: 'Preferred Champion',
      required: true,
      query: 'lolChampions',
    },
  ],

  csgo: [
    {
      name: 'csgoSteamId',
      type: 'text',
      placeholder: 'STEAM_0:0:84006697',
      label: 'Steam ID',
      required: true,
    },
    {
      name: 'csgoEseaProfileUrl',
      type: 'text',
      placeholder: 'https://play.esea.net/users/105317',
      label: 'ESEA Profile URL',
      required: false,
    },
  ],
  dota2: [
    {
      name: 'dota2SteamId',
      type: 'text',
      placeholder: 'STEAM_0:0:84006697',
      label: 'Steam ID',
      required: true,
    },
    {
      name: 'dota2HeroName',
      type: 'select',
      label: 'Preferred Hero',
      required: true,
      query: 'dota2Heroes',
    },
  ],
  rl: [
    {
      name: 'rlEpicId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Epic ID',
      required: true,
    },
    {
      name: 'rlSteamId',
      type: 'text',
      placeholder: 'STEAM_0:0:84006697',
      label: 'Steam ID',
      required: false,
    },
    {
      name: 'rlXboxLiveId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Xbox Gamer Tag',
      required: false,
    },
    {
      name: 'rlPsnId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'PSN',
      required: false,
    },
  ],
  sfv: [
    {
      name: 'sfvFid',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Capcom Fighter ID',
      required: false,
    },
    {
      name: 'sfvPreferredFighterCd',
      type: 'select',
      label: 'Preferred Fighter',
      required: true,
      query: 'sfvFighters',
    },
  ],
  fortnite: [
    {
      name: 'fortniteEpicUsername',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Epic Account Username',
      required: true,
    },
  ],
  gears: [
    {
      name: 'gearsXboxLiveId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Xbox Gamer Tag',
      required: true,
    },
  ],
  ow: [
    {
      name: 'owBattleNetAccountLink',
      type: 'text',
      placeholder: 'Username#1234',
      label: 'Battle.net BattleTag',
      required: true,
    },
    {
      name: 'owPreferredHeroCd',
      type: 'select',
      label: 'Preferred Hero',
      required: true,
      query: 'owHeroes',
    },
  ],
  mtga: [
    {
      name: 'mtgaUsername',
      type: 'text',
      placeholder: 'Username#1234',
      label: 'MTGA Username',
      required: true,
    },
    {
      name: 'mtgaPreferredColorCd',
      type: 'select',
      label: 'Favorite Color',
      required: true,
      query: 'mtgaColors',
    },
  ],
  nba: [
    {
      name: 'nbaXboxLiveId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Xbox Gamer Tag',
      required: true,
      exclusiveOf: 'nbaPsnId',
    },
    {
      name: 'nbaPsnId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'PSN',
      required: true,
      exclusiveOf: 'nbaXboxLiveId',
    },
    {
      name: 'nbaPreferredTeamCd',
      type: 'select',
      label: 'Preferred Team',
      required: true,
      query: 'nbaTeams',
    },
  ],
  hs: [
    {
      name: 'hsBattletag',
      type: 'text',
      placeholder: 'Username#1234',
      label: 'Battle.net BattleTag',
      required: true,
    },
    {
      name: 'hsPreferredClassCd',
      type: 'select',
      label: 'Preferred Class',
      required: true,
      query: 'hsClasses',
    },
  ],
  sc2: [
    {
      name: 'sc2Battletag',
      type: 'text',
      placeholder: 'Username#1234',
      label: 'Battle.net BattleTag',
      required: true,
    },
    {
      name: 'sc2CharacterName',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'In - game SC2 Character Name',
      required: true,
    },
    {
      name: 'sc2PreferredRaceCd',
      type: 'select',
      label: 'Preferred Race',
      required: true,
      query: 'sc2Races',
    },
  ],
  smash: [
    {
      name: 'smashGamerTag',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Smash Id',
      required: true,
    },
  ],
  nhl: [
    {
      name: 'nhlXboxLiveId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Xbox Gamer Tag',
      required: true,
      exclusiveOf: 'nhlPsnId',
    },
    {
      name: 'nhlPsnId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'PSN',
      required: true,
      exclusiveOf: 'nhlXboxLiveId',
    },
    {
      name: 'nhlPreferredTeamCd',
      type: 'select',
      label: 'Preferred Team',
      required: true,
      query: 'nhlTeams',
    },
  ],
  madden: [
    {
      name: 'maddenXboxLiveId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Xbox Gamer Tag',
      required: true,
      exclusiveOf: 'maddenPsnId',
    },
    {
      name: 'maddenPsnId',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'PSN',
      required: true,
      exclusiveOf: 'maddenXboxLiveId',
    },
    {
      name: 'maddenPreferredTeamCd',
      type: 'select',
      label: 'Preferred Team',
      required: true,
      query: 'maddenTeams',
    },
  ],
  valorant: [
    {
      name: 'valorantRiotAccount',
      type: 'text',
      placeholder: 'Gazorpazorp',
      label: 'Valorant Riot Account',
      required: true,
    },
    {
      name: 'valorantPreferredChampionCd',
      type: 'select',
      label: 'Preferred Champion',
      required: true,
      query: 'valorantAgents',
    },
  ],
}

export const allFields: any = Object.values(allFieldsBySport).flat()

export const queryNames = allFields
  .filter((field: FieldType) => field.query)
  .map((field: FieldType) => field.query)

export const allFieldsMutationArguments = allFields
  .map((field: FieldType) => `$${field.name}: String`)
  .join('\n')

export const allFieldsMutationVariables = allFields
  .map((field: FieldType) => `${field.name}: $${field.name}`)
  .join('\n')

export const selectFieldsQueries: string = queryNames
  .map(
    (name: string) => `
  ${name} {
      options
    }
  `,
  )
  .join(' ')

export const selectFieldsQueryArguments: string = queryNames
  .map((query: string) => `$${query}: Boolean`)
  .join('\n')

export const selectFieldsQueryVariables: string = queryNames
  .map((query: string) => `${query}: $${query}`)
  .join('\n')

export const allFieldsQueryVariabls = allFields.map((field: FieldType) => field.name).join('\n')
