import gql from 'graphql-tag'
import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Dictionary, compact, isEmpty } from 'lodash'

import { Flex, Text, Box } from 'rebass'

import SectionTitle from '../../atoms/SectionTitle'
import { BaseButton } from '../../atoms/Buttons'
import {
  FieldType,
  allFieldsBySport,
  selectFieldsQueries,
  selectFieldsQueryArguments,
  selectFieldsQueryVariables,
} from '../../../utils/gamingProfilesUtils'
import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import GamingProfileField from './GamingProfileField'

const SELECT_FIELDS_QUERY = gql(String.raw`
  query selectFields(
    ${selectFieldsQueryArguments}
  ) {
    sportPlayerCharacteristics(
      ${selectFieldsQueryVariables}
    ) {
      ${selectFieldsQueries}
    }
  }
`)

interface IGamingProfilesProps {
  headerText: string
  sports: string[]
  subheaderText?: string
  submitButtonText: string
  removeSport?: (sport: string) => void
  onFormSubmitted: () => void
}

const GamingProfiles: React.FC<IGamingProfilesProps> = ({
  headerText,
  sports,
  subheaderText,
  submitButtonText,
  removeSport,
  onFormSubmitted,
}) => {
  const [dropDownFieldOptionsLoaded, setDropDownFieldOptionsLoaded] = useState(false)

  const {
    sportFields,
    errorsExist,
    setSportDropdownFieldOptions,
    updateErrors,
    sportFieldError,
  } = useContext(CurrentUserContext)

  const queryFields = sports
    .map((sport: string) => allFieldsBySport[sport])
    .flat()
    .filter((field: FieldType) => field.query)

  useQuery(SELECT_FIELDS_QUERY, {
    variables: queryFields.reduce(function(variables: Dictionary<boolean>, field: FieldType) {
      variables[field.query!] = true
      return variables
    }, {}),
    skip: dropDownFieldOptionsLoaded,
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setSportDropdownFieldOptions(
        queryFields.reduce(function(options: Dictionary<string>, field: FieldType) {
          options[field.name] = data.sportPlayerCharacteristics[field.query!]
          return options
        }, {}),
      )
      setDropDownFieldOptionsLoaded(true)
    },
  })

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const errors = sports
      .reduce((fields: Array<FieldType>, sport: string) => {
        fields.push(allFieldsBySport[sport])
        return fields
      }, [])
      .flat()
      .reduce((errors, currentField) => {
        if (currentField.required) {
          errors = { ...errors, ...sportFieldError(sportFields[currentField.name], currentField) }
        }
        return errors
      }, {})
    if (isEmpty(compact(Object.values(errors)))) {
      onFormSubmitted()
    } else {
      updateErrors(errors)
    }
  }

  return (
    <Box>
      <SectionTitle text={headerText} align="center" mb={subheaderText ? '5' : '6'} />
      {subheaderText && (
        <Text textAlign="center" mb={6}>
          <p>{subheaderText}</p>
        </Text>
      )}

      <Flex flexDirection="column" mx="auto">
        {sports.map((sport: string) => (
          <GamingProfileField sport={sport} removeSport={removeSport} key={sport} />
        ))}
      </Flex>
      <Flex mt={2}>
        <BaseButton
          variant={errorsExist ? 'secondaryDisabled' : 'secondary'}
          disabled={errorsExist}
          onClick={handleSubmit}
          mx="auto"
        >
          {submitButtonText}
        </BaseButton>
      </Flex>
    </Box>
  )
}

export default GamingProfiles
