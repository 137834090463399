import 'react-toastify/dist/ReactToastify.min.css'
import React from 'react'
import { ToastContainer, ToastContainerProps, Slide } from 'react-toastify'
import { styled, Theme, MediaQueries as Mq } from '../../styles/settings'

import { UnstyledButton } from '../atoms/Buttons'

const StyledToastContainer = styled(ToastContainer)<ToastContainerProps>`
  color: ${props => props.theme.colors.white};
  text-align: left;
  width: 100vw;
  padding: 0;
  margin-left: 0px;
  position: absolute;
  z-index: 9;
  top: 4rem;
  left: 0;

  div::first-letter {
    text-transform: uppercase;
  }

  button {
    margin-right: 1rem;
    ${Mq.md} {
      margin-right: 0;
    }
  }

  svg path {
    fill: ${props => props.theme.colors.white};
    margin-top: 2px;
  }
  .Toastify__toast {
    font-family: Campton Book, sans-serif;
    align-items: center;
    box-shadow: none;
    min-height: 3.5rem;
    width: 100vw;
    padding: 0.5rem 1rem;
    margin: auto;
    h6 {
      max-width: 80%;
      line-height: 1.25rem;
    }

    ${Mq.md} {
      padding: 0.5rem 4rem;
      h6 {
        max-width: 100%;
      }
    }
  }
  .Toastify__toast--error {
    color: ${props => props.theme.colors.white};
  }
  .Toastify__toast--warning {
    background-color: ${props => props.theme.colors.sand};
  }
  .Toastify__toast--success {
    background-color: ${props => props.theme.colors.secondaryblue};
    box-shadow: 0px;
  }
  .Toastify__toast--default {
    background-color: ${props => props.theme.colors.secondaryblue};
  }
  &.with-banner {
    display: none;
  }
`

interface ICloseButtonProps {
  closeToast?(): void
  onClose?(): void
}

// tslint:disable
const CloseButton: React.SFC<ICloseButtonProps> = ({ onClose, closeToast }) => {
  const handleClose = () => {
    const closeToastFn = closeToast || (() => {})
    const onCloseFn = onClose || (() => {})

    closeToastFn()
    onCloseFn()
  }

  return (
    <UnstyledButton onClick={handleClose} aria-label="close">
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={Theme.colors.white}
          d="M15.7 0.3C15.3 -0.1 14.7 -0.1 14.3 0.3L8 6.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L6.6 8L0.3 14.3C-0.1 14.7 -0.1 15.3 0.3 15.7C0.5 15.9 0.7 16 1 16C1.3 16 1.5 15.9 1.7 15.7L8 9.4L14.3 15.7C14.5 15.9 14.8 16 15 16C15.2 16 15.5 15.9 15.7 15.7C16.1 15.3 16.1 14.7 15.7 14.3L9.4 8L15.7 1.7C16.1 1.3 16.1 0.7 15.7 0.3Z"
        />
      </svg>
    </UnstyledButton>
  )
}
// tslint:enable

const Toasts: React.SFC<ToastContainerProps> = ({ className, ...rest }) => {
  const defaults = {
    autoClose: 5000,
  }

  const others = { ...defaults, ...rest }

  return (
    <StyledToastContainer
      closeButton={<CloseButton />}
      position="top-center"
      newestOnTop={false}
      transition={Slide}
      rtl={false}
      draggable={false}
      className={className}
      role="toast"
      pauseOnFocusLoss={false}
      enableMultiContainer
      containerId={'temporary'}
      {...others}
    />
  )
}

export default Toasts
