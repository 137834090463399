import { Flex, FlexProps } from 'rebass'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { toast } from 'react-toastify'
import { useModal } from 'react-modal-hook'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import HamburgerNav from '../molecules/NavBar/HamburgerNav'
import Hidden from '../atoms/Hidden'
import HorizontalNav from '../molecules/NavBar/HorizontalNav'
import InvitePlayer from '../organisms/InvitePlayer'
import ModalContainer from '../atoms/ModalContainer'
import VerifyEmailBanner from '../atoms/VerifyEmailBanner'

const CHECK_AUTH = gql`
  query checkAuth {
    currentUser {
      id
      username
      isAdmin
      isIncompleteAccount
      emailForVerification
    }
  }
`

const NavWrapper = styled(Flex)<FlexProps>`
  background-color: transparent;

  ${Mq.md} {
    flex-direction: column;
    display: flex;
    width: 100%;
    position: absolute;
    font-family: ${props => props.theme.fonts.wide};
    color: ${props => props.theme.colors.black};
    z-index: 999;
  }
`

// TODO: REPLACE USER-RELATED STATE DATA WITH CONTEXT
const NavBar = () => {
  const { data, loading } = useQuery(CHECK_AUTH, { fetchPolicy: 'cache-and-network' })
  const [username, setUsername] = useState(localStorage.getItem('csl_username'))
  const [userId, setUserId] = useState(localStorage.getItem('csl_user_id'))
  const [accessToken, setAccessToken] = useState(localStorage.getItem('csl_access-token'))
  const [userIsAdmin, setUserIsAdmin] = useState(sessionStorage.getItem('csl_user_is_admin'))
  const [hideBanner, setHideBanner] = useState(false)
  // const [showMenu, setShowMenu] = useState(false)
  const [universityId, setUniversityId] = useState<string>()
  const emailForVerification =
    !window.location.href.includes('confirmation_token') &&
    data &&
    data.currentUser &&
    !data.currentUser.isIncompleteAccount &&
    data.currentUser.emailForVerification

  // If we get a response
  if (data && data.currentUser) {
    if (username !== data.currentUser.username) {
      setUsername(data.currentUser.username)
      sessionStorage.setItem('csl_username', data.currentUser.username)
    }
    if (userId !== data.currentUser.id) {
      setUserId(data.currentUser.id)
      sessionStorage.setItem('csl_user_id', data.currentUser.username)
    }
    if (userIsAdmin !== data.currentUser.isAdmin) {
      setUserIsAdmin(data.currentUser.isAdmin)
      data.currentUser.isAdmin
        ? sessionStorage.setItem('csl_user_is_admin', 'true')
        : sessionStorage.removeItem('csl_user_is_admin')
    }
    if (
      data.currentUser &&
      data.currentUser.university &&
      data.currentUser.university.id !== universityId
    ) {
      setUniversityId(data.currentUser.university.id)
    }

    data.currentUser.isIncompleteAccount
      ? sessionStorage.setItem('is_incomplete_account', 'true')
      : sessionStorage.removeItem('is_incomplete_account')
  } else {
    if (accessToken && data && data.currentUser === null) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
      setUsername('')
      setUserId('')
      setAccessToken('')
      setUserIsAdmin('')
      setUniversityId('')
    }
  }

  useEffect(() => {
    if (emailForVerification && !hideBanner) {
      toast.success(
        <VerifyEmailBanner email={emailForVerification} setHideBanner={setHideBanner} />,
        {
          autoClose: false,
          hideProgressBar: true,
          className: 'CTA',
          containerId: 'permanent',
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailForVerification, hideBanner])

  const [showInviteModal, hideInviteModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideInviteModal}
        style={{
          content: { maxWidth: '50rem' },
        }}
      >
        <InvitePlayer modalClose={hideInviteModal} />
      </ModalContainer>
    ),
    [],
  )

  return (
    <>
      <NavWrapper>
        <Hidden mdUp>
          <HamburgerNav
            isIncompleteAccount={data && data.currentUser && data.currentUser.isIncompleteAccount}
            userId={userId || ''}
            username={username || ''}
            userIsAdmin={!!userIsAdmin}
            showInviteModal={showInviteModal}
          />
        </Hidden>
        <Hidden smDown>
          <HorizontalNav
            isIncompleteAccount={data && data.currentUser && data.currentUser.isIncompleteAccount}
            userId={userId || ''}
            username={username || ''}
            userIsAdmin={!!userIsAdmin}
            loading={loading}
            showInviteModal={showInviteModal}
          />
        </Hidden>
      </NavWrapper>
    </>
  )
}

export default NavBar
