import { useTheme } from 'emotion-theming'
import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Text } from 'rebass'

import { Tournament } from '../../types/graphql'
import { adjustedFullWithShortMonthAndTime } from '../../utils/dateTimeFormats'
import { paths } from '../../utils/Routes'
import { isActive, tournamentStyleFormatter } from '../../utils/tournament'
import { BaseButton } from '../atoms/Buttons'
import SportLogo from '../atoms/SportLogo'
import Table from '../atoms/Table'

interface IProps {
  tournaments: Tournament[]
}

const EsportsTournaments: React.FC<IProps> = ({ tournaments }) => {
  const { colors } = useTheme()

  return (
    <Table headerBackgroundColor="middlegray">
      <thead>
        <tr>
          <th style={{ width: '5%' }}>
            <Text color={colors.black}>
              <h6>Game</h6>
            </Text>
          </th>
          <th style={{ width: '30%' }}>
            <Text color={colors.black}>
              <h6>Tournament</h6>
            </Text>
          </th>
          <th>
            <Text color={colors.black}>
              <h6>Start Date</h6>
            </Text>
          </th>

          <th style={{ width: '15%' }}>
            <Text color={colors.black}>
              <h6>Type</h6>
            </Text>
          </th>

          <th style={{ width: '15%' }}>
            <Text color={colors.black}>
              <h6>Platform</h6>
            </Text>
          </th>
          <th style={{ width: '10%' }} />
        </tr>
      </thead>

      <tbody>
        {tournaments.map(tournament => (
          <tr key={tournament.id}>
            <td>
              <SportLogo sport={tournament.sport} height="1.75rem" width="1.75rem" />
            </td>

            <td>
              <Box pr={2}>
                <Link to={paths.tournament(tournament.id)}>
                  <Text color={colors.primarynavy}>
                    <h5 style={{ minWidth: '200px' }}>{tournament.name}</h5>
                  </Text>
                </Link>
              </Box>
            </td>

            <td>
              <Text color={isActive(tournament) ? colors.teal : colors.darkgray}>
                <h5 style={{ minWidth: '100px' }}>
                  {isActive(tournament)
                    ? 'ACTIVE'
                    : adjustedFullWithShortMonthAndTime(tournament.firstMatchDate)}
                </h5>
              </Text>
            </td>

            <td>
              <Text color={colors.darkgray}>
                <h5>{tournamentStyleFormatter(tournament.style)}</h5>
              </Text>
            </td>

            <td>
              <Text color={colors.darkgray}>
                <h5>{tournament.platform ? tournament.platform.title : '-'}</h5>
              </Text>
            </td>

            <td>
              <Link to={paths.tournament(tournament.id)}>
                <BaseButton variant="secondary">Details</BaseButton>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default EsportsTournaments
