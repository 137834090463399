import React from 'react'
import { FaDiscord } from 'react-icons/fa'
import { Box, BoxProps, Flex, Text, TextProps, Link } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import { GiBrutalHelm } from 'react-icons/gi'
import { MediaQueries as Mq, styled, Theme } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { Sport, University } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import Animation from '../atoms/Animation'
import Awards from '../atoms/Awards'
import GameSelector from '../organisms/GameSelector'
import HeroContainer from '../atoms/HeroContainer'
import HeroImage from '../../assets/images/hero__team-details.jpg'
import SocialIcons from '../molecules/SocialIcons'
import { DiscordUsername } from '../atoms/DiscordUsername'

interface PlayerHeroProps {
  username: string
  userId?: string
  university?: University
  playedSports?: Array<Sport>
  fb?: string
  color?: string
  twitch?: string
  twitter?: string
  discordId?: string
  discordUsername?: string
  selectSport?: (arg0: string) => void
  awards?: number
  currentSport?: string
}

const HideBox = styled(Box)<BoxProps>`
  ${Mq.sm} {
    visibility: hidden;
  }
  ${Mq.md} {
    visibility: visible;
  }
`
const HideBoxSm = styled(Box)<BoxProps>`
  display: none;
  ${Mq.sm} {
    display: block;
  }
`
const UserName = styled(Text)<TextProps>`
  font-family: ${props => props.theme.fonts.wide};
  font-size: ${props => props.theme.fontSizes[1]};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  ${Mq.md} {
    font-size: ${props => props.theme.fontSizes[2]};
  }
`

const UserAvatar = styled(Box)`
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderLink = styled(RouterLink)`
  color: ${props => props.theme.colors.white};
`

const PlayerProfileHero = ({
  username,
  userId,
  university,
  playedSports,
  fb,
  twitter,
  twitch,
  discordId,
  discordUsername,
  selectSport,
  awards,
  currentSport,
}: PlayerHeroProps) => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <HeroContainer height="15rem" heroImage={HeroImage} tint={'3,51,89, 0.6'}>
      <Flex height="100%" flexWrap="wrap">
        <Box width={[1, 1, 1 / 2, 1 / 2]} mb={[5, 5, 2, 0]}>
          <Flex flexDirection="column" justifyContent="space-between" height="100%">
            <HideBox>
              <Awards major={awards || 0} minor={0} />
            </HideBox>
            <Flex alignItems="center">
              <HideBoxSm mr={4}>
                <Animation
                  initial="hidden"
                  animate="visible"
                  variants={animations}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                >
                  <UserAvatar>
                    {/* TODO: Let the user pick an avatar or upload one */}
                    <GiBrutalHelm size={62} color={Theme.colors.primarynavy} />
                  </UserAvatar>
                </Animation>
              </HideBoxSm>
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
              >
                <Flex flexDirection="column" height="100%" justifyContent="center">
                  <UserName mb={[2, 2, 2, 4]} color="white">
                    {username}
                  </UserName>
                  <Flex>
                    {university && (
                      <Text mr={4} color="white">
                        <h5>
                          <HeaderLink to={paths.university(university.id)}>
                            {university.name}
                          </HeaderLink>
                        </h5>
                      </Text>
                    )}
                    {(fb || twitch || twitter) && (
                      <SocialIcons fb={fb} twitch={twitch} twitter={twitter} />
                    )}
                  </Flex>
                </Flex>
              </Animation>
            </Flex>
          </Flex>
        </Box>

        <Box width={[1, 1, 1 / 2, 1 / 2]}>
          <Flex
            flexDirection={'column'}
            justifyContent="space-between"
            height="100%"
            alignItems={['flex-start', 'flex-start', 'flex-end']}
          >
            {userId && (
              <Flex flexDirection="column" alignItems={['flex-start', 'flex-start', 'flex-end']}>
                {playedSports && playedSports.length && selectSport && (
                  <Box mb={4}>
                    <GameSelector
                      size={40}
                      sports={playedSports}
                      currentSport={currentSport}
                      changeSport={selectSport}
                      customPadding="0.5rem"
                    />
                  </Box>
                )}
              </Flex>
            )}

            {discordId && discordId !== '' && discordUsername ? (
              <Link href={'https://discordapp.com/users/' + discordId}>
                <BaseButton variant="discord">
                  <Flex alignItems="center">
                    <FaDiscord size={24} />
                    <Text ml={2}>
                      <DiscordUsername>{discordUsername}</DiscordUsername>
                    </Text>
                  </Flex>
                </BaseButton>
              </Link>
            ) : discordUsername ? (
              <BaseButton variant="discordDisabled" style={{ cursor: 'default' }}>
                <Flex alignItems="center">
                  <FaDiscord size={24} />
                  {discordUsername && (
                    <Text ml={2}>
                      <DiscordUsername>{discordUsername}</DiscordUsername>
                    </Text>
                  )}
                </Flex>
              </BaseButton>
            ) : null}
          </Flex>
        </Box>
      </Flex>
    </HeroContainer>
  )
}

export default PlayerProfileHero
