import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'
import MaterialUIHidden, { HiddenProps } from '@material-ui/core/Hidden'

import { breakpoints } from '../../styles/settings/theme'

const theme = createMuiTheme({
  breakpoints: {
    values: Object.keys(breakpoints).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: curr === 'xs' ? 0 : Number(breakpoints[curr].split('px')[0]),
      }
    }, {} as BreakpointValues),
  },
})

const Hidden: React.FC<HiddenProps> = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <MaterialUIHidden {...props} />
    </ThemeProvider>
  )
}

export default Hidden
