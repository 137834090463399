import _ from 'lodash'

export interface ISeasonLike {
  id: string
}

export interface IDivisionLike {
  name: string
  position: number
  seasons?: ISeasonLike[]
}

export function orderedByPosition(items: IDivisionLike[]) {
  return _.sortBy(items, ['position', 'name'])
}

export function relevantPositions(items: IDivisionLike[]) {
  // TODO: figure out a better way to do this?
  // "Past" Divisions all have position 42 while
  // "Current" Divisions have positions 1-5
  const arbitraryHighPositionNumberToIgnore = 42

  return _.reject(items, ({ position }) => position === arbitraryHighPositionNumberToIgnore)
}

export function withSeasons(items: IDivisionLike[]) {
  return _.filter(items, item => {
    return item.seasons && item.seasons.length > 0
  })
}

export function relevantByPosition(items: IDivisionLike[]) {
  return orderedByPosition(relevantPositions(items))
}

export default {
  orderedByPosition,
  relevantPositions,
  relevantByPosition,
  withSeasons,
}
