import { ApolloError } from 'apollo-client/errors/ApolloError'
import React from 'react'
import { Flex, FlexProps } from 'rebass'

import LoadingSpinner from '../atoms/LoadingSpinner'
import ErrorLoading from '../organisms/ErrorLoading'

interface IProps extends FlexProps {
  loading: boolean
  error?: ApolloError
  children?: any
  skip?: boolean
  height?: string
  isDark?: boolean
}

// TODO: add proper error copy + UI
const DataFetcher: React.FC<IProps> = ({ loading, error, children, skip, height, my, isDark }) => {
  if (skip) {
    return children
  }

  if (loading) {
    return (
      <Flex height={height || '90vh'} justifyContent="center" alignItems="center" my={my}>
        <LoadingSpinner />
      </Flex>
    )
  }

  if (error) {
    // debugger
    return <ErrorLoading background={isDark} />
  }
  return children
}

export default DataFetcher
