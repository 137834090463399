import { useState } from 'react'
import _ from 'lodash'
import C from 'lodash/fp'

import { SportSlugs } from '../types/graphql'
import { SetState } from '../types/aliases'
import { stringToEnum } from '../utils/typeCoercions'

// NOTE: specify the tuple type since the naturally inferred type
// is an array of _either_ SportSlugs _or_ the fn types
type UseSportSelectorReturn = [
  SportSlugs[],
  (clickedSportName: string) => void,
  SetState<SportSlugs[]>,
]

export function useSportSelector(selected?: SportSlugs[]): UseSportSelectorReturn {
  const [selectedSports, setSelectedSports] = useState<SportSlugs[]>(selected || [])
  const handleSportClicked = (clickedSportName: string) => {
    const clickedSport = stringToEnum<SportSlugs>(SportSlugs, clickedSportName)
    if (!clickedSport) {
      console.error(`Unrecognized sport: ${clickedSportName}`)
      return
    }

    const newSelectedSports = _.includes(selectedSports, clickedSport)
      ? _.reject(selectedSports, C.isEqual(clickedSport))
      : _.concat(selectedSports, [clickedSport])

    setSelectedSports(newSelectedSports)
  }

  return [selectedSports, handleSportClicked, setSelectedSports]
}
