import { Box } from 'rebass'
import { styled, ThemeColors } from '../../styles/settings/theme'

interface IProps {
  borderColor?: ThemeColors
  noPadding?: boolean
  maxWidth?: string
  minWidth?: string
}

const Alert = styled(Box)<IProps>`
  max-width: ${props => (props.maxWidth || '700px')};
  min-width: ${props => (props.minWidth || 'auto')};
  border: ${props => (props.borderColor ? '2px solid' : 'none')};
  border-color: ${props => props.borderColor || 'none'};
  text-align: center;
  padding: ${props => (props.noPadding ? '0' : '2rem')};

  h2 {
    padding: 1rem 0 2rem;
  }
`

export default Alert
