export const bigImgStyles = `
  img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`

export const paddingForFirst = `
  :first-of-type {
    padding-top: 2rem;
  }
`

export const paddingForLast = `
  :last-of-type {
    padding-bottom: 2rem;
  }
`
