import { Flex, FlexProps, Text } from 'rebass'
import { GiCrossedSwords } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import { styled, Theme } from '../../styles/settings/theme'
import React from 'react'

import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'

const Container = styled(Flex)<FlexProps>`
  margin: auto;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${props => props.theme.colors.middlegray};
  background: white;
  max-width: 785px;
`

const CompleteProfileCTA = () => (
  <Container py={6} px={[3, 3, 8, 8]}>
    <GiCrossedSwords size={38} color={Theme.colors.sand} />
    <Text my={5} color="darkgray">
      <h6>profile incomplete</h6>
    </Text>
    <Text mb={5} textAlign="center">
      <p>There’s a few more things we need to know before you can start joining teams/leagues.</p>
    </Text>
    <Flex flexDirection="column" alignItems="center">
      <Link to={paths.completeProfile()}>
        <BaseButton variant="secondary" mx="auto">
          complete profile
        </BaseButton>
      </Link>
    </Flex>
  </Container>
)

export default CompleteProfileCTA
