import { useFormik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Text } from 'rebass'
import { object, string } from 'yup'

import { useUpsertUniversityInfoMutation } from '../../types/graphql'
import { handleResult } from '../../utils/results'
import { paths } from '../../utils/Routes'
import { BaseButton } from '../atoms/Buttons'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'
import DataFetcher from '../organisms/DataFetcher'

gql`
  mutation UpsertUniversityInfo($id: ID, $name: String!, $abbreviation: String, $state: String) {
    upsertUniversity(id: $id, name: $name, abbreviation: $abbreviation, state: $state) {
      success
      value {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

interface IProps {
  name?: string
  universityId?: string
  abbreviation?: string
  state?: string
}

const AdminUniversityInfo: React.FC<IProps> = ({
  name = '',
  universityId,
  abbreviation = '',
  state = '',
}) => {
  const history = useHistory()

  const [updateBasicInfo, { loading: updateLoading }] = useUpsertUniversityInfoMutation({
    onError: error => {
      toast.error(error.message, { containerId: 'temporary' })
    },
    onCompleted: data => {
      handleResult({
        result: data.upsertUniversity,
        onSuccess: value => {
          history.push(paths.manageUniversity(value.id))
          toast.success('Your university has been saved', { containerId: 'temporary' })
        },
        onFailure: errors => {
          Object.keys(errors).forEach(errorKey => {
            toast.error(errors[errorKey], { containerId: 'temporary' })
          })
        },
      })
    },
  })

  const formik = useFormik({
    initialValues: { name, abbreviation, state },
    validationSchema: object().shape({
      name: string().required(),
      abbreviation: string(),
      state: string(),
    }),
    onSubmit: values => {
      updateBasicInfo({
        variables: {
          ...values,
          id: universityId,
        },
      })
    },
  })

  return (
    <DataFetcher loading={updateLoading}>
      <Box>
        <Text mb={3}>
          <h3>University Details</h3>
        </Text>

        <form onSubmit={formik.handleSubmit}>
          <Box bg="white" p={7}>
            <Box width={'70%'} minWidth={'10rem'}>
              <InputFieldWithErrors
                mb={5}
                type="text"
                name="name"
                label="University name"
                placeholder="University name"
                value={formik.values.name}
                customOnChange={formik.handleChange}
                errorMessage={formik.errors.name}
              />
              <InputFieldWithErrors
                mb={5}
                type="text"
                name="abbreviation"
                label="Abbreviation"
                placeholder="Abbreviation"
                value={formik.values.abbreviation}
                customOnChange={formik.handleChange}
                errorMessage={formik.errors.abbreviation}
              />
              <InputFieldWithErrors
                type="text"
                name="state"
                label="state"
                placeholder="state"
                value={formik.values.state}
                customOnChange={formik.handleChange}
                errorMessage={formik.errors.state}
              />
            </Box>
          </Box>

          <BaseButton variant="primary" mt={4} type="submit">
            Save University
          </BaseButton>
        </form>
      </Box>
    </DataFetcher>
  )
}

export default AdminUniversityInfo
