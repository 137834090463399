import { Game, Team } from '../types/graphql'
import { OptionalExceptFor } from '../types/customUtilityTypes'

export const validSeriesLenghts = [1, 3, 5, 7, 9]

export const gameStartingIn24Hours = (remainingHours: number): boolean =>
  remainingHours < 24 && remainingHours >= 0

export const gameStatus = (
  winner: boolean,
  settled: boolean,
  upcomingGame: boolean,
  sport: string,
): string =>
  winner
    ? 'Game Reported'
    : settled && sport !== 'mtga'
    ? 'Game Not Needed'
    : settled && sport === 'mtga'
    ? 'Game Reporting Optional'
    : upcomingGame
    ? 'Upcoming Game'
    : 'Game Unreported'

export const matchIsBye = (settled: boolean, games: Game[]): boolean =>
  settled && games.every(game => !game.winner)

export const isTeamWinner = (games: Game[], settled: boolean, teamId: string) => {
  const gamesTeamWon = games.filter(
    game => game.winner && game.winner.team && game.winner.team.id === teamId,
  ).length
  return settled && gamesTeamWon > games.length - gamesTeamWon
}

export const teamScore = (teamId: string, games: Partial<Game>[]): number =>
  games.filter(game => game && game.winner && game.winner.team && game.winner.team.id === teamId)
    .length

export const opponentTeam = (
  teamId: string,
  teamOne: OptionalExceptFor<Team, 'id'> | null | undefined,
  teamTwo: OptionalExceptFor<Team, 'id'> | null | undefined,
) => {
  return teamOne && teamTwo ? (teamOne.id === teamId ? teamTwo : teamOne) : null
}

export const emptyGameLineup = (team: Team, game: Game, teamIsWinner: boolean) => {
  if (!team || !team.activeRosterEntries) {
    return true
  }

  if (
    (teamIsWinner && (!game.winner || !game.winner.players || game.winner.players.length === 0)) ||
    (!teamIsWinner && (!game.loser || !game.loser.players || game.loser.players.length === 0))
  ) {
    return true
  }
}
