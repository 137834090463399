import React from 'react'
import { Box } from 'rebass'
import { styled, MediaQueries as Mq } from '../../styles/settings/theme'

import HeroImage from '../../assets/images/hero__home-page.jpg'
import Animation from '../atoms/Animation'
import Content from '../atoms/Content'
import Card from '../atoms/Card'
import Hero from '../molecules/Hero'

const StyledCard = styled(Card)`
  padding: auto 1rem;
  background: ${props => props.theme.colors.white};

  h2 {
    margin-bottom: 1rem;
  }

  ${Mq.md} {
    padding: 2rem;
  }
`

const PrivacyContent = styled(Box)`
  padding: 4rem 0 0;
`

const PrivacyText = styled(Box)`
  max-width: 80%;
  margin: 0 auto 6rem;
`

const Divider = styled(Box)`
  height: 2px;
  background: ${props => props.theme.colors.teal};
  width: 100%;
  margin: 4rem 0;
`

const PrivacyPolicy: React.FC = () => {
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <>
      <Hero
        title={'Privacy Policy'}
        image={HeroImage}
        position="center 30%"
        height="18rem"
        tint={'3,51,89, 0.6'}
      ></Hero>
      <Content>
        <StyledCard mb={6} notch={true} notchSize={20}>
          <PrivacyContent>
            <PrivacyText>
              <Animation
                initial="hidden"
                animate="visible"
                variants={animations}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
              >
                <h2>CSL Privacy Policy</h2>
                <h4>Last updated – October 17, 2020</h4>
                <Divider />
                <p>Our Privacy Policy is currently being written and will be available soon!</p>
              </Animation>
            </PrivacyText>
          </PrivacyContent>
        </StyledCard>
      </Content>
    </>
  )
}

export default PrivacyPolicy
