import gql from 'graphql-tag'
import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { Box, Text } from 'rebass'
import { oc } from 'ts-optchain'

import { styled } from '../../styles/settings'
import {
  GetUniversityAdminQuery,
  useGetUniversityAdminQuery,
} from '../../types/graphql'
import { paths } from '../../utils/Routes'
import Content from '../atoms/Content'
import AdminUniversityInfo from '../organisms/AdminUniversityInfo'
import DataFetcher from '../organisms/DataFetcher'

gql`
  query GetUniversityAdmin($id: ID!) {
    university(id: $id) {
      id
      name
      abbreviation
      state
    }
  }
`

const EditNav = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    font-family: ${props => props.theme.fonts.condensed};
    margin-bottom: 1.5rem;
  }
`

interface IURLParams {
  universityId: string
}

interface IProps extends RouteComponentProps<IURLParams> {
  customStyles: any
}

interface IUniversityContentProps {
  data: GetUniversityAdminQuery
}

const ManageUniversityContent: React.FC<IUniversityContentProps> = ({ data }) => {
  if (!data || !data.university) {
    return null
  }

  enum Anchors {
    BasicInfo = 'basic-info',
  }
  const sections: { [key: string]: React.ReactElement } = {
    [Anchors.BasicInfo]: (
      <AdminUniversityInfo
        name={data.university.name}
        abbreviation={data.university.abbreviation || ''}
        state={data.university.state || ''}
        universityId={data.university.id}
      />
    ),
  }

  return (
    <Content>
      <Text mb={8}>
        <h2>Manage University: {data.university.name}</h2>
      </Text>

      <Box
        sx={{
          display: 'grid',
          gridGap: 1,
          gridTemplateColumns: '1fr 4fr',
        }}
      >
        <EditNav>
          <li>
            <h5>basic info</h5>
          </li>
        </EditNav>

        <Route
          exact
          path={paths.manageUniversity(data.university.id)}
          render={() => sections[Anchors.BasicInfo]}
        />
      </Box>
    </Content>
  )
}

const ManageUniversity: React.FunctionComponent<IProps> = ({ match: { params } }) => {
  const universityId = oc(params).universityId('')

  const { data, loading, error } = useGetUniversityAdminQuery({
    variables: {
      id: universityId,
    },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <DataFetcher loading={loading} error={error}>
      {data && <ManageUniversityContent data={data} />}
    </DataFetcher>
  )
}

export default ManageUniversity
