import React from 'react'
import { Flex } from 'rebass'
import { LowerDash, MatchBox, Round } from '../atoms/BracketPieces'
import { MatchNumber } from '../atoms/MatchNumber'
import { BracketText } from '../atoms/BracketText'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

const Round1Losers: React.FunctionComponent<RoundProps> = ({
  shift,
  seatList,
  bottomHalf,
  numTeams,
}) => {
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)

  return (
    <Round shift={shift}>
      {halfSeats.map((seat: number, idx: number) => (
        <Flex key={seat}>
          <MatchBox round="1" mb={(idx + 1) % 4 === 0 ? '50px' : '10px'} numTeams={numTeams}>
            <MatchNumber seat={seat} />
            <BracketText seat={seat} homeTeam={true} />
            <BracketText seat={seat} />
          </MatchBox>
          <LowerDash />
        </Flex>
      ))}
    </Round>
  )
}

export default Round1Losers
