import React from 'react'
import { Text, TextProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

interface HeaderProps extends TextProps {
  text: string
}

interface MainHeaderProps extends TextProps {
  text?: string
}

export const Paragraph = styled.p`
  margin-bottom: 1.5rem;
`

export const StyledUl = styled.ul`
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.black};
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  list-style-type: disc;

  ul {
    list-style-type: circle;
  }

  ul ul {
    list-style-type: disc;
  }
`

export const StyledOl = styled.ol`
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.black};
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
`

export const StyledLi = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.4rem;
  font-family: ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.black};
`

export const Uppercase = styled(Text)<TextProps>`
  text-transform: uppercase;
`

export const SectionHeader = ({ text, mt }: HeaderProps) => (
  <Uppercase mt={mt} mb={'1rem'}>
    <h3>{text}</h3>
  </Uppercase>
)

export const MainHeader = ({ text }: MainHeaderProps) => <Uppercase mb={'2rem'}></Uppercase>

export const SubHeader = ({ text }: HeaderProps) => (
  <Uppercase mb={'2rem'}>
    <h2>{text} Rules</h2>
  </Uppercase>
)
