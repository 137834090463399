import React from 'react'
import { Flex } from 'rebass'

import { MatchBox, Round, LowerLine } from '../atoms/BracketPieces'
import { BracketText } from '../atoms/BracketText'
import { MatchNumber } from '../atoms/MatchNumber'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

//only used in bracket size 128
const Round5BLosers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const halfSeats = !bottomHalf ? seatList[0] : seatList[1]

  return (
    <Round round="5B" shift={shift} top={!bottomHalf ? '600px' : '670px'}>
      <Flex>
        <MatchBox round="5B" numTeams={numTeams}>
          <MatchNumber seat={halfSeats} />
          <BracketText seat={halfSeats} homeTeam={true} />
          <BracketText seat={halfSeats} />
        </MatchBox>
        <LowerLine round="5B" bottomHalf={bottomHalf} />
      </Flex>
    </Round>
  )
}

export default Round5BLosers
