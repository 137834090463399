import React, { useContext } from 'react'
import { Text } from 'rebass'

import { TeamContext } from '../../contexts/TeamContext'

const ManageTeamTitle: React.FC = () => {
  const { name } = useContext(TeamContext)

  return (
    <Text>
      <h2>Manage {name}</h2>
    </Text>
  )
}

export default ManageTeamTitle
