import { useTheme } from 'emotion-theming'
import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Flex, FlexProps, Text } from 'rebass'
import fortnite from '../../../assets/images/sportAvatar__fortnite.jpg'
import nhl from '../../../assets/images/sportAvatar__nba2k.jpg'
import rl from '../../../assets/images/sportAvatar__rl.jpg'
import { MediaQueries as Mq, styled } from '../../../styles/settings/theme'
import { paths } from '../../../utils/Routes'
import Animation from '../../atoms/Animation'
import { BaseButton } from '../../atoms/Buttons'
import Card from '../../atoms/Card'

interface IProps {
  image?: string
  width?: string
  height?: string
  transition?: string
}

const GamePic = styled(Card)<IProps>`
  width: 100%;
  height: 18rem;
  padding-top: 14rem;
  background-image: ${props => props.theme.gradients.primarynavyFade}, url('${props =>
  props.image}');
  opacity: 1;
  transition: ${props => props.theme.transitions.fast};
  background-size: cover;
  background-position: center;

  ${Mq.md} {
    width: auto;
    height: 19.5rem;
    padding-top: 15rem;
    cursor: pointer;

    H5 {
      transition: ${props => props.theme.transitions.fast};
        }
    &:hover H5 {
      color: ${props => props.theme.colors.primarylightblue};
    }
  }
`

const LargeFlex = styled(Flex)<FlexProps>`
  display: grid;
  grid-column-gap: 0rem;
  grid-template-columns: 1fr 1fr;

  ${Mq.md} {
    display: flex;
  }
`

export const IntersectionContext = React.createContext({ inView: true })

const fnCopy = (
  <p>
    <h2>Play format Details</h2>
    <br />
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
      laboris nisi ut aliquip ex ea commodo consequat.
    </p>
    <br />
    Kicking off 12/31/2020
  </p>
)

const HomePageGames: React.FC<IProps> = ({ transition }) => {
  const { colors } = useTheme()
  const largePics1 = [
    { name: 'Fortnite', image: fortnite, startDate: '12/31/2020', playFormatCopy: fnCopy },
    { name: 'nhl', image: nhl, startDate: '12/31/2020', playFormatCopy: fnCopy },
    { name: 'rocket league', image: rl, startDate: '12/31/2020', playFormatCopy: fnCopy },
  ]

  const animations = {
    hidden: {
      opacity: 0,
      y: '-1.5rem',
    },
    hiddenLeft: {
      opacity: 0,
      x: '-1.5rem',
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  }

  return (
    <>
      <Animation
        initial="hiddenLeft"
        animate="visible"
        variants={animations}
        transition={{ ease: 'easeOut', duration: 0.4 }}
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          paddingBottom={2}
          height="auto"
          width={[1, 1, 1, 1]}
        >
          <Box mt={4} width="50%">
            <Flex
              height="100%"
              width="100%"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Text color={colors.primarynavy}>
                <h2> Brought to you by:</h2>
                <br />
                <h1>Telus</h1>
              </Text>
            </Flex>
          </Box>
          <Box mt={4} mr={8} mb={4}>
            <iframe
              width="560"
              height="315"
              title="ad video"
              src="https://www.youtube.com/embed/wVWWpUpK4Ds"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </Box>
        </Flex>
      </Animation>
      <br />

      <Flex
        backgroundColor={colors.lightgray}
        flexDirection="column"
        alignItems="center"
        width={[1, 1, 1, 1]}
        paddingBottom={'4rem'}
      >
        <Text mt={4} mb={4} color={colors.primarynavy}>
          <h1>Key Battle League Dates</h1>
        </Text>

        <Flex flexDirection="row" justifyContent="space-around" width={[1, 1, 1, 1]}>
          <Text color={colors.primarynavy}>
            <h2>[First Date]</h2>
          </Text>
          <Text color={colors.primarynavy}>
            <h2>[Second Date]</h2>
          </Text>
          <Text color={colors.primarynavy}>
            <h2>[Third Date]</h2>
          </Text>
        </Flex>
      </Flex>

      <Flex
        backgroundColor={colors.lightgray}
        flexDirection="column"
        alignItems="center"
        width={[1, 1, 1, 1]}
        paddingBottom={'4rem'}
      >
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Flex alignItems="center" flexDirection="column" width={[1, 1, 1, 1]}>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0 }}
            >
              <Text color={colors.primarynavy}>
                <h2>Register</h2>
              </Text>
              <Flex flex={1} mx={1} mb={1}>
                <Box flex={1} className="grid-item" px={1} mb={1}>
                  <Text textAlign="justify" color={colors.primarynavy}>
                    <p>
                      <br />
                      <b>1. Create an account and complete your profile</b>
                      <br />
                      <br />
                      <b>2. Invite your friends</b>
                      <br />
                      <br />
                      <b>3. Join the corresponding league!</b>
                      <br />
                    </p>
                    <br />
                  </Text>
                </Box>
              </Flex>
            </Animation>
            <Animation
              initial="hidden"
              animate="visible"
              variants={animations}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
            >
              <Link to={paths.signup()}>
                <Flex>
                  <BaseButton variant="navy" mx="auto" my={3}>
                    sign up today
                  </BaseButton>
                </Flex>
              </Link>
            </Animation>
          </Flex>

          <LargeFlex width="auto" paddingLeft={'2rem'} paddingRight={'2rem'} mx={[-2]} mb={4}>
            {largePics1.map((game, idx) => (
              <Box key={game.name} width={1} className="grid-item" px={2} mb={4}>
                <Link to={paths.signup()}>
                  <GamePic notch={true} notchSize={16} image={game.image}>
                    <Text textAlign="center" color={colors.white}>
                      <h5>{game.name}</h5>
                    </Text>
                  </GamePic>

                  <Box
                    flex={['1 0 auto', '1 0 auto', '1 0 auto', 'auto']}
                    width={['auto', 'auto', 'auto', '100%']}
                    key={idx}
                    px={2}
                    mb={4}
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width={['100%', '100%', 'auto']}
                      height="100%"
                    >
                      <Text textAlign="center" color={colors.primarynavy}>
                        {game.playFormatCopy}
                      </Text>
                    </Flex>
                  </Box>
                </Link>
              </Box>
            ))}
          </LargeFlex>
        </Animation>
      </Flex>

      <Flex
        backgroundColor={colors.lightgray}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-around"
        width={[1, 1, 1, 1]}
        paddingBottom={'4rem'}
        paddingTop={'4rem'}
      >
        <Text mt={4} mb={4}>
          <h3>
            View the rules in depth
            <a
              href="https://drive.google.com/file/d/1tmaYKcyazgF_8uvtYk4xE8jiZ4r9iCjb/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              here
            </a>
          </h3>
        </Text>
        <Text mt={4} mb={4}>
          <h2>
            <a
              href="https://www.telus.com/en/internet/best-internet-for-gaming?INTCMP=VAN_gamers"
              target="_blank"
              rel="noopener noreferrer"
            >
              Need faster Internet?
            </a>
          </h2>
        </Text>
      </Flex>
    </>
  )
}

export default HomePageGames
