import React from 'react'
import { Box } from 'rebass'
import { styled } from '../../styles/settings/theme'

import Animation from '../atoms/Animation'
import SportLogoSelector from '../molecules/SportLogoSelector'

interface IProps {
  sportSlugs: Array<string>
  selectedSports: Array<string>
  unplayedSports?: Array<string>
  sportClickHandler: (sportSlug: string) => void
  gridTemplateColumns?: string
  customPadding?: string
}

const SquareContainer = styled(Box)`
  padding-top: 100%; // For responsive square sizing
  position: relative;
`

const StyledSportLogoSelector = styled(SportLogoSelector)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  &.disabled:hover {
      border: 0px;
    }
  }
`

const GamesFilter: React.FC<IProps> = ({
  sportSlugs,
  selectedSports,
  unplayedSports,
  sportClickHandler,
  gridTemplateColumns,
  customPadding,
}) => {
  const disabledSports = unplayedSports || ['']
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <Animation
      initial="hidden"
      animate="visible"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.4 }}
    >
      <Box
        sx={{
          display: 'grid',
          gridGap: 1,
          gridTemplateColumns: gridTemplateColumns || 'repeat(auto-fill, minmax(4rem, 1fr))',
        }}
      >
        {sportSlugs.map(sportSlug => (
          // TODO: Make games animate in one at a time
          <SquareContainer key={sportSlug}>
            <StyledSportLogoSelector
              sportSlug={sportSlug}
              isSelected={selectedSports.includes(sportSlug)}
              onClick={() => sportClickHandler(sportSlug)}
              notPlayed={disabledSports.includes(sportSlug)}
              customPadding={customPadding}
              className={disabledSports.includes(sportSlug) ? 'disabled' : ''}
            />
          </SquareContainer>
        ))}
      </Box>
    </Animation>
  )
}

export default GamesFilter
