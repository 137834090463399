import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { CheckCircle } from 'react-feather'
import { useModal } from 'react-modal-hook'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import {
  GetTeamsWithPendingImagesQuery,
  useApproveTeamImagesMutation,
  useGetTeamsWithPendingImagesQuery,
} from '../../types/graphql'
import { errorsFullMessage, handleResult } from '../../utils/results'
import HeroImage from '../../assets/images/hero__universities.jpg'
import { BaseButton, UnstyledButton } from '../atoms/Buttons'
import Alert from '../atoms/Alert'
import Card from '../atoms/Card'
import Content from '../atoms/Content'
import DataFetcher from '../organisms/DataFetcher'
import HeroContainer from '../atoms/HeroContainer'
import ModalContainer from '../atoms/ModalContainer'
import Table from '../atoms/Table'

gql`
  query getTeamsWithPendingImages {
    teams(withPendingImages: true) {
      id
      name
      coordinator {
        id
        username
      }
      pendingLogoImage {
        name
        dataUrl
      }
      pendingBannerImage {
        name
        dataUrl
      }
    }
  }
`

gql`
  mutation approveTeamImages($id: ID!, $approveBanner: Boolean, $approveLogo: Boolean) {
    approveTeamImages(id: $id, approveBanner: $approveBanner, approveLogo: $approveLogo) {
      success
      value {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

const ViewImageButton = styled(UnstyledButton)`
  color: ${props => props.theme.colors.primarynavy};

  &:hover {
    color: ${props => props.theme.colors.primaryblue};
  }
`

const MediaTools = () => {
  const { colors } = useTheme()
  const [imagesToApproveData, setImagesToApproveData] = useState(
    [] as GetTeamsWithPendingImagesQuery['teams'],
  )
  const [selectedImageUrl, setSelectedImageUrl] = useState('')

  const [approveTeamImages, { loading: approveImageLoading }] = useApproveTeamImagesMutation()

  const { error, loading } = useGetTeamsWithPendingImagesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const bannerImagesToApprove = data.teams
        .filter(team => team.pendingBannerImage && team.pendingBannerImage.dataUrl)
        .map(team => ({
          ...team,
          pendingLogoImage: undefined,
        }))

      const logoImagesToApprove = data.teams
        .filter(team => team.pendingLogoImage && team.pendingLogoImage.dataUrl)
        .map(team => ({
          ...team,
          pendingBannerImage: undefined,
        }))

      setImagesToApproveData([...bannerImagesToApprove, ...logoImagesToApprove])
    },
  })

  const [showImageModal, hideImageModal] = useModal(
    () => (
      <ModalContainer hideModal={hideImageModal}>
        <Flex justifyContent="center">
          <img src={selectedImageUrl} alt="Team logo or banner needing approval" />
        </Flex>
      </ModalContainer>
    ),
    [selectedImageUrl],
  )

  const handleImageApproval = async (
    teamId: string,
    approveBanner?: boolean,
    approveLogo?: boolean,
  ) => {
    try {
      const { data } = await approveTeamImages({
        variables: {
          id: teamId,
          approveBanner,
          approveLogo,
        },
        refetchQueries: ['getTeamsWithPendingImages'],
      })

      handleResult({
        result: data!.approveTeamImages,
        onSuccess: () =>
          toast.success('Your changes have been saved.', { containerId: 'temporary' }),
        onFailure: errors => {
          toast.error(errorsFullMessage(errors), { containerId: 'temporary' })
        },
      })
    } catch (err) {
      toast.error(errorsFullMessage(err.errors), { containerId: 'temporary' })
    }
  }

  const previewImage = (url: string) => {
    setSelectedImageUrl(url)
    showImageModal()
  }

  return (
    <DataFetcher error={error} loading={loading || approveImageLoading}>
      <>
        <HeroContainer height="15rem" heroImage={HeroImage} tint={'1,26,64, 0.6'}>
          <Flex alignItems="flex-end" height="100%" pt={5}>
            <Text color={colors.white}>
              <h1>Media Tools</h1>
            </Text>
          </Flex>
        </HeroContainer>

        <Content>
          <Box mb={3}>
            <h2>
              Media Reviews{' '}
              <Text color={colors.darkgray} as="span">
                ({imagesToApproveData.length})
              </Text>
            </h2>
          </Box>

          <Card>
            <Box p={[5]} width={[1, 1, 1, 1 / 2]}>
              <Text color={colors.darkgray} mb={3}>
                <h4>Approve or deny team imagery and icons</h4>
              </Text>

              <p>
                Review the media requests below. Check for graphic content or poor use of imagery
                with logos & text
              </p>
            </Box>

            <Table headerBackgroundColor="middlegray">
              <thead>
                <tr>
                  <th>
                    <h6>Media Type</h6>
                  </th>
                  <th>
                    <h6>Team</h6>
                  </th>
                  <th>
                    <h6>Coordinator</h6>
                  </th>
                  <th>
                    <h6>Approval Decision</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {imagesToApproveData.map((row, idx) => (
                  <tr key={idx}>
                    <td>
                      <ViewImageButton
                        onClick={() =>
                          previewImage(
                            ((row.pendingLogoImage && row.pendingLogoImage.dataUrl) ||
                              (row.pendingBannerImage && row.pendingBannerImage.dataUrl)) as string,
                          )
                        }
                      >
                        <h6>View {row.pendingLogoImage ? 'Logo' : 'Banner'}</h6>
                      </ViewImageButton>
                    </td>
                    <td>
                      <Text color={colors.darkgray}>
                        <h6>{row.name}</h6>
                      </Text>
                    </td>
                    <td>
                      <Text color={colors.darkgray}>
                        <h6>{row.coordinator ? row.coordinator.username : '-'}</h6>
                      </Text>
                    </td>
                    <td>
                      <Flex justifyContent="flex-end">
                        <Box mr={3}>
                          <BaseButton
                            variant="secondary"
                            onClick={() =>
                              handleImageApproval(
                                row.id,
                                row.pendingBannerImage ? true : undefined,
                                row.pendingLogoImage ? true : undefined,
                              )
                            }
                          >
                            Approve
                          </BaseButton>
                        </Box>

                        <BaseButton
                          onClick={() =>
                            handleImageApproval(
                              row.id,
                              row.pendingBannerImage ? false : undefined,
                              row.pendingLogoImage ? false : undefined,
                            )
                          }
                          variant="decline"
                        >
                          Decline
                        </BaseButton>
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {imagesToApproveData.length === 0 && (
              <Box p={[5]}>
                <Alert borderColor={colors.middlegray} mx={['auto']}>
                  <CheckCircle size={38} color={colors.green} />
                  <Text my={5} color={colors.darkgray}>
                    <h6>You are all up to date!</h6>
                  </Text>
                  <Text mb={5}>
                    <p>
                      You don’t have any more media to review. Please check back soon to see if you
                      have any more banners or icons to approve or deny.
                    </p>
                  </Text>
                </Alert>
              </Box>
            )}
          </Card>
        </Content>
      </>
    </DataFetcher>
  )
}

export default MediaTools
