import * as Y from 'yup'

export const constants = {
  titleMaxLength: 80,
  excerptMaxLength: 100,
}

export const schema = Y.object().shape({
  excerpt: Y.string().required(),
  title: Y.string().required(),
})

export default {
  constants,
  schema,
}
