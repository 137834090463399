import { useTheme } from 'emotion-theming'
import React from 'react'
import { GiCrossedSwords } from 'react-icons/gi'
import { Text } from 'rebass'
import { MediaQueries as Mq, styled } from '../../../styles/settings/theme'

import { fullWithLongMonth } from '../../../utils/dateTimeFormats'
import { BaseButton } from '../../atoms/Buttons'
import Alert from '../../atoms/Alert'
import Animation from '../../atoms/Animation'
import Card from '../../atoms/Card'

const RegistrationAlert = styled(Card)`
  padding: 3rem 1rem;

  ${Mq.md} {
    padding: 5rem;
  }
`

interface IUnstartedLeagueContentProps {
  startDate?: Date
  onRegisterClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  allowRegistration: boolean
  children: React.ReactNode
}

const UnstartedLeagueContent: React.FC<IUnstartedLeagueContentProps> = ({
  startDate,
  onRegisterClick,
  allowRegistration,
  children,
}) => {
  const { colors } = useTheme()

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <RegistrationAlert notch={true}>
      <Alert noPadding={true} mx="auto">
        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4 }}
        >
          <GiCrossedSwords color={colors.sand} size="38px" />
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
        >
          <Text mt={4} mb={5}>
            <h2>League Has Not Started</h2>
          </Text>
        </Animation>

        <Animation
          initial="hidden"
          animate="visible"
          variants={animations}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
        >
          <Text mb={allowRegistration ? 7 : 6}>
            <p>
              This league is still in its registration period.{' '}
              {startDate && (
                <>
                  Please check back on <b>{fullWithLongMonth(startDate)}</b> to see your league
                  schedule, standings and more!
                </>
              )}{' '}
              {allowRegistration && 'In the meantime you can register for the league below'}
            </p>
          </Text>
        </Animation>

        {allowRegistration && (
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
          >
            <BaseButton onClick={onRegisterClick}>Register for League</BaseButton>
          </Animation>
        )}
        {children}
      </Alert>
    </RegistrationAlert>
  )
}

export default UnstartedLeagueContent
