import React from 'react'
import { Text } from 'rebass'
import { useTheme } from 'emotion-theming'

import Table from '../../atoms/Table'
import ToggleSwitch from '../../atoms/ToggleSwitch'
import { Team } from '../../../types/graphql'

interface ITeamRegistryTableProps {
  teams: Team[]
  toggleOn: (team: Team) => boolean | null | undefined
  toggleOnText: string
  toggleOffText: string
  onToggleOn: (team: Team) => void
  onToggleOff: (team: Team) => void
  action: string
}

const TeamRegistryTable: React.FC<ITeamRegistryTableProps> = ({
  teams,
  toggleOn,
  toggleOnText,
  toggleOffText,
  onToggleOn,
  onToggleOff,
  action,
}) => {
  const { colors } = useTheme()

  const handleChange = (team: Team) => {
    toggleOn(team) ? onToggleOff(team) : onToggleOn(team)
  }

  return (
    <Table headerBackgroundColor="middlegray" notchedTopRight bodyBorder>
      <thead>
        <tr>
          {['team name', 'status', action].map(header => (
            <th key={header}>
              <Text color={colors.black}>
                <h6>{header}</h6>
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {teams &&
          teams.map((team, index) => (
            <tr key={index}>
              <td>
                <Text color={colors.darkgray}>
                  <h6>{team.name}</h6>
                </Text>
              </td>
              <td style={{ width: '30%' }}>
                <Text color={toggleOn(team) ? colors.secondarygreen : colors.red}>
                  <h6>{toggleOn(team) ? toggleOnText : toggleOffText}</h6>
                </Text>
              </td>
              <td style={{ width: '15%' }}>
                <ToggleSwitch
                  handleChange={() => handleChange(team)}
                  checked={!!toggleOn(team)}
                  onColor={colors.secondarygreen}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default TeamRegistryTable
