import gql from 'graphql-tag'
import React, { useContext, useEffect, useState } from 'react'
import { TournamentContext } from '../../contexts/TournamentContext'
import DataFetcher from './DataFetcher'
import { AdminTournamentInfo, AdminTournamentTeamInfo } from '../../types/fragments'
import { useTournamentAdminLazyQuery } from '../../types/graphql'

gql`
  query TournamentAdmin($id: ID!) {
    tournament(id: $id) {
      ...AdminTournamentInfo
      adminTournamentTeams {
        ...AdminTournamentTeamInfo
      }
      checkedInTeams {
        id
      }
    }
  }
  ${AdminTournamentInfo}
  ${AdminTournamentTeamInfo}
`

interface IProps {
  tournamentId: string
  children?: any
}

const TournamentAdminQueryProvider: React.FunctionComponent<IProps> = ({
  tournamentId,
  children,
  ...rest
}) => {
  const { updateFromQuery } = useContext(TournamentContext)
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)

  const [tournamentQuery, { error, loading }] = useTournamentAdminLazyQuery({
    variables: { id: tournamentId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data && data.tournament) {
        updateFromQuery(data.tournament)
        setFirstLoadComplete(true)
      }
    },
  })

  useEffect(() => {
    if (!tournamentId) {
      return
    }
    tournamentQuery()
  }, [tournamentId, tournamentQuery])

  return (
    <DataFetcher loading={loading && !firstLoadComplete} error={error}>
      {children}
    </DataFetcher>
  )
}

export default TournamentAdminQueryProvider
