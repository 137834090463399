import _ from 'lodash'
import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { SetState } from '../../../types/aliases'
import { PostCategories, SportSlugs } from '../../../types/graphql'
import { parseAsEnum, parseAsNumber, valueFrom } from '../../../utils/formUtils'
import { spaceify } from '../../../utils/strings'
import { stringToEnum } from '../../../utils/typeCoercions'
import { CheckBox, IFileFieldProps } from '../../atoms/FormPieces'
import { CustomSelect } from '../../atoms/Select'
import { FormCard, FormCardInput } from '../../molecules/FormCards'
import ImageUploader from '../../molecules/ImageUploader'
import { Mode, YesOrNo } from './enums'
import { constants } from './validations'

export interface IFeaturedImageFields {
  name?: string
  dataUrl?: string
}

interface ICreateAPostFormFieldsProps {
  actionsComponent?: React.ReactNode
  id?: string
  currentMode: Mode
  title?: string
  excerpt?: string
  category?: PostCategories
  featured?: YesOrNo
  position?: number
  featuredImageDataUrl?: string
  featuredImageName?: string
  sportSlugs: SportSlugs[]
  sport?: SportSlugs
  published?: boolean
  postCategories: PostCategories[]
  onTitleChanged: SetState<string>
  onExcerptChanged: SetState<string>
  onCategoryChanged: SetState<PostCategories | undefined>
  onFeaturedChanged: SetState<YesOrNo | undefined>
  onPositionChanged: SetState<number>
  onFeaturedImageChanged: (fields: IFeaturedImageFields) => void
  onSportChanged: SetState<SportSlugs | undefined>
  onPublishedChanged: SetState<boolean>
}

interface IFileDropFieldProps extends IFileFieldProps {
  bgImage?: string
}

const CreateAPostFormFields: React.FC<ICreateAPostFormFieldsProps> = ({
  id,
  currentMode,
  actionsComponent,
  title,
  excerpt,
  category,
  postCategories,
  featured,
  position,
  featuredImageDataUrl,
  featuredImageName,
  sport,
  sportSlugs,
  published,
  onFeaturedImageChanged,
  onTitleChanged,
  onExcerptChanged,
  onCategoryChanged,
  onFeaturedChanged,
  onPositionChanged,
  onSportChanged,
  onPublishedChanged,
}) => {
  const handleCategoryChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    return parseAsEnum(PostCategories)(onCategoryChanged)(valueFrom(e))
  }

  const handleSportChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    return parseAsEnum(SportSlugs)(onSportChanged)(valueFrom(e))
  }

  const handleFeaturedChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = stringToEnum<YesOrNo>(YesOrNo, valueFrom(e))
    onFeaturedChanged(value)
  }

  const isPersisted = !!id
  const isPublished = isPersisted && published
  const createOrEdit = isPersisted ? 'Manage' : 'Create'
  const thing = currentMode
  const thingTitle = currentMode === Mode.News ? 'A News Post' : 'An Announcement'
  const formCardTitle = `${createOrEdit} ${thingTitle}`

  return (
    <FormCard title={formCardTitle} actionsComponent={actionsComponent}>
      <Flex p={6} mb={1} justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Box width={1 / 2}>
          <Text>
            <h3>Add Your {thing} Information</h3>
          </Text>
          <p>Add a featured image, title, category, and excerpt for your post below</p>
        </Box>
        <Box width={1 / 2}>
          <Text textAlign="right">
            <h3>Post Status</h3>
          </Text>
          <Text textAlign="right">
            {/* TODO: use the actual "switch" component? */}
            <label>Published</label>
            <CheckBox
              name="published"
              type="checkbox"
              disabled={!isPersisted}
              checked={isPublished}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onPublishedChanged(e.target.checked)
              }
            />
          </Text>
        </Box>
      </Flex>

      <Flex mx={6} mb={4} flexWrap="wrap">
        <FormCardInput
          width={1 / 2}
          name="title"
          placeholder=""
          value={title}
          onChange={onTitleChanged}
          label={`${thing} Title (Max ${constants.titleMaxLength} Characters)`}
        />
        <FormCardInput
          width={1 / 2}
          name="excerpt"
          placeholder=""
          value={excerpt}
          onChange={onExcerptChanged}
          label={`${thing} Excerpt (Max ${constants.excerptMaxLength} Characters`}
        />
      </Flex>

      <Flex mx={6} mb={6}>
        {currentMode === Mode.News ? (
          <>
            <Box my={1} pr={4} width={1 / 2}>
              <CustomSelect value={category || ''} onChange={handleCategoryChanged}>
                <option value={''} disabled>
                  {thing} Category
                </option>
                {postCategories.map((category, i) => (
                  <option key={i} value={category}>
                    {spaceify(category)}
                  </option>
                ))}
              </CustomSelect>
            </Box>

            {category === PostCategories.Games && (
              <Box my={1} pr={4} width={1 / 2}>
                <CustomSelect value={sport || ''} onChange={handleSportChanged}>
                  <option value={''} disabled>
                    Select a sport
                  </option>
                  {sportSlugs.sort().map((sportSlug, i) => (
                    <option key={i} value={sportSlug}>
                      {sportSlug}
                    </option>
                  ))}
                </CustomSelect>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box my={1} pr={4} width={1 / 2}>
              <CustomSelect value={featured || ''} onChange={handleFeaturedChanged}>
                <option value={''} disabled>
                  Featured Announcement?
                </option>
                {_.values(YesOrNo).map(yesOrNo => (
                  <option key={yesOrNo} value={yesOrNo}>
                    {yesOrNo}
                  </option>
                ))}
              </CustomSelect>
            </Box>

            {featured === YesOrNo.Yes && (
              <Box my={1} pr={4} width={1 / 2}>
                <FormCardInput
                  type="number"
                  name="feature-priority"
                  min={1}
                  value={position}
                  onChange={parseAsNumber(onPositionChanged)}
                  label={'Feature Priority'}
                  placeholder=""
                />
              </Box>
            )}
          </>
        )}

        {/* TODO: implement
        <Box my={1} ml={2} width={1 / 3}>
          <FormCardInput placeholder={'Schedule Post (optional)'} />
        </Box>
        */}
      </Flex>

      <Box p={6}>
        <ImageUploader
          imageUrl={featuredImageDataUrl}
          imageName={featuredImageName}
          onImageChanged={onFeaturedImageChanged}
          title="featured image"
          imageFieldTitle="featured image"
        />
      </Box>
    </FormCard>
  )
}

export default CreateAPostFormFields
