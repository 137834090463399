import React, { useState } from 'react'
import { Box } from 'rebass'

import ImageUploader, { IImageFields as IInternalImageFields } from '../../molecules/ImageUploader'

export type IImageFields = IInternalImageFields

interface IGameBoxReportEvidenceProps {
  imageName?: string
  imageUrl?: string
  onImageChanged: (imageFields: IImageFields) => void
}

export const useEvidence = () => {
  const [evidenceScreenshotUrl, setEvidenceScreenshotUrl] = useState()
  const [evidenceScreenshotName, setEvidenceScreenshotName] = useState()

  const handleEvidenceChanged = ({ name, dataUrl }: IImageFields) => {
    setEvidenceScreenshotUrl(dataUrl)
    setEvidenceScreenshotName(name)
  }

  return {
    evidenceScreenshotUrl,
    evidenceScreenshotName,
    setEvidenceScreenshotUrl,
    setEvidenceScreenshotName,
    handleEvidenceChanged,
  }
}

export const GameBoxReportEvidence: React.FC<IGameBoxReportEvidenceProps> = ({
  imageUrl,
  imageName,
  onImageChanged,
}) => {
  return (
    <Box width={1} mt={5} px={2} mb={[4, 4, 4, 0]}>
      <ImageUploader
        imageUrl={imageUrl}
        imageName={imageName}
        onImageChanged={onImageChanged}
        title="Evidence"
        imageFieldTitle="Evidence"
        guidance={'Include a screenshot of evidence for this match report'}
      />
    </Box>
  )
}
