import React from 'react'
import { Box, Flex } from 'rebass'
import { styled } from '../../../styles/settings/theme'

import TabLabel from '../../atoms/TabLabel'
import { CustomTabSelect, CustomTabTextArea } from '../../atoms/FormPieces'
import { RosterEntry, Team } from '../../../types/graphql'
import { MtgaColors } from '../../../utils/sportUtils'
import { BaseButton } from '../../atoms/Buttons'

const InputBox = styled(Box)`
  position: relative;
`

interface ReportingProps {
  team: Team
  isHomeTeam: boolean
  isWinningTeam: boolean
  setWinner: (value: any) => void
  changeName: (e: React.ChangeEvent, isHomeTeam: boolean) => void
  changeColor: (e: React.ChangeEvent, isHomeTeam: boolean) => void
  changeDeck: (e: React.ChangeEvent, isHomeTeam: boolean) => void
  player: string
  color: string
  deck?: string
}

const MtgaReportingTable = ({
  team,
  isHomeTeam,
  isWinningTeam,
  setWinner,
  changeName,
  changeColor,
  changeDeck,
  player,
  color,
  deck,
}: ReportingProps) => {
  const handleScore = () => {
    setWinner(team.id)
  }
  return (
    <>
      <Box width={[1, 1, 1, 1 / 2]} mt={5} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
        <Flex alignItems="center" justifyContent="space-between">
          <h3>{team.truncatedName}</h3>
          <BaseButton variant={isWinningTeam ? 'selected' : 'select'} onClick={handleScore}>
            select as winner
          </BaseButton>
        </Flex>
        <Box mt={4} p={3} bg="backgroundgray">
          <InputBox mt={4}>
            <TabLabel text="player" />
            <CustomTabSelect
              onChange={e => {
                changeName(e, isHomeTeam)
              }}
              value={player}
            >
              {team.activeRosterEntries &&
                team.activeRosterEntries.map((entry: RosterEntry) => (
                  <option key={entry.id}>{entry.player && entry.player.username}</option>
                ))}
            </CustomTabSelect>
          </InputBox>
          <InputBox mt={6}>
            <TabLabel text="deck color" />
            <CustomTabSelect
              onChange={e => {
                changeColor(e, isHomeTeam)
              }}
              value={color}
            >
              {MtgaColors.map(color => (
                <option key={color}>{color}</option>
              ))}
            </CustomTabSelect>
          </InputBox>
          <InputBox mt={6}>
            <TabLabel text="deck list code" />
            <CustomTabTextArea
              placeholder="Paste or Type deck code..."
              value={deck}
              onChange={e => changeDeck(e, isHomeTeam)}
              width="100%"
              height="15rem"
            />
          </InputBox>
        </Box>
      </Box>
    </>
  )
}
export default MtgaReportingTable
