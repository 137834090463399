import React from 'react'

interface LogoProps {
  colorOne?: string
  colorTwo?: string
  width?: string
  height?: string
}

export const OpenLeagueLogo: React.FunctionComponent<LogoProps> = ({ colorOne, width, height }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      >
      <path
        d="M11.454 8.551l.184-.545.185.545.591 1.704h2.587l-.518.34-1.551 1.056.59 1.704.185.545-.517-.34-1.588-1.057-1.59 1.056-.517.34.185-.544.591-1.704-1.588-1.056-.518-.34H10.752l.702-1.704zM11.638 0l-1.477 4.19-.887 2.487-.407 1.125H0l3.843 2.589 2.29 1.533 1.035.715-.407 1.125-.85 2.486-1.477 4.19 3.842-2.588 2.291-1.533 1.034-.716 1.035.716 2.29 1.533 3.917 2.555-1.478-4.19-.886-2.487-.407-1.125 1.035-.715 2.29-1.533 3.88-2.555h-8.904l-.407-1.125-.887-2.487L11.64 0z"
        fill={colorOne}
      />
    </svg>
  )
}

//with white background
export const OpenLeagueLogoAlt: React.FunctionComponent<LogoProps> = ({
  colorOne,
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height}
      id="open-league__logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.1 21.2"
    >
      <path
        d="M11.9 8.9l.2-.6.2.6.6 1.8h2.6l-.5.3-1.6 1.1.6 1.8.2.6-.5-.5-1.6-1-1.7 1-.5.4.2-.6.6-1.8L9 11l-.5-.4h2.6l.8-1.7zm.2-8.8l-1.5 4.3-.9 2.5-.5 1.2H.1l4 2.7 2.4 1.6 1.1.7-.4 1.2-.9 2.6-1.5 4.3 4-2.7 2.4-1.6 1.1-.7 1.1.7 2.4 1.6 4 2.6-1.8-4.3-.9-2.6-.4-1.2 1.1-.7 2.4-1.6 4-2.6H15.1l-.4-1.2-.9-2.6L12.1.1z"
        fill={colorOne || '#7cd1f7'}
      />
    </svg>
  )
}

//with white background
export const StarLeagueLogoAlt: React.FunctionComponent<LogoProps> = () => {
  return (
    <svg id="star-league__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.1 22.9">
      <path d="M3 12.1l-1.1-.7-1.3 3.8L4 17.5l.4-1.2.9-2.6L3 12.1zM16.2 6.4h4.3l-1.3-3.8h-4.3l.4 1.2.9 2.6zM7.9 6.4l.9-2.5.4-1.2H4.9L3.6 6.4h4.3zM9.7 19.8l-1.1.7 3.5 2.3 3.5-2.3-1.1-.7-2.4-1.6-2.4 1.6zM21.2 12.1l-2.4 1.6.9 2.6.4 1.2 3.5-2.3-1.3-3.8-1.1.7z" />
      <path
        d="M11.9 8.9l.2-.6.2.6.6 1.8h2.6l-.5.3-1.6 1.1.6 1.8.2.6-.5-.5-1.6-1-1.7 1-.5.4.2-.6.6-1.8L9 11l-.5-.4h2.6l.8-1.7zm.2-8.8l-1.5 4.3-.9 2.5-.5 1.2H.1l4 2.7 2.4 1.6 1.1.7-.4 1.2-.9 2.6-1.5 4.3 4-2.7 2.4-1.6 1.1-.7 1.1.7 2.4 1.6 4 2.6-1.8-4.3-.9-2.6-.4-1.2 1.1-.7 2.4-1.6 4-2.6H15.1l-.4-1.2-.9-2.6L12.1.1z"
        fill="#7cd1f7"
      />
    </svg>
  )
}
export const StarLeagueLogo: React.FunctionComponent<LogoProps> = ({
  colorOne,
  colorTwo,
  width,
  height,
}) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.808 11.72l-1.071-.716-1.294 3.645 3.363 2.249.406-1.159.887-2.487-2.291-1.533zM15.666 6.132H19.84l-1.293-3.645H14.41l.406 1.192.85 2.453zM7.574 6.132l.887-2.453.406-1.192H4.692L3.436 6.132h4.138zM9.348 19.18l-1.072.716 3.363 2.248L15 19.896l-1.072-.716-2.29-1.533-2.291 1.533zM20.469 11.72l-2.29 1.532.886 2.487.406 1.159 3.363-2.249-1.293-3.645-1.072.715z"
        fill={colorTwo}
      />
      <path
        d="M11.454 8.551l.184-.545.185.545.591 1.704h2.587l-.518.34-1.551 1.056.59 1.704.185.545-.517-.34-1.588-1.057-1.59 1.056-.517.34.185-.544.591-1.704-1.588-1.056-.518-.34H10.752l.702-1.704zM11.638 0l-1.477 4.19-.887 2.487-.407 1.125H0l3.843 2.589 2.29 1.533 1.035.715-.407 1.125-.85 2.486-1.477 4.19 3.842-2.588 2.291-1.533 1.034-.716 1.035.716 2.29 1.533 3.917 2.555-1.478-4.19-.886-2.487-.407-1.125 1.035-.715 2.29-1.533 3.88-2.555h-8.904l-.407-1.125-.887-2.487L11.64 0z"
        fill={colorOne}
      />
    </svg>
  )
}

export const GoldLeagueLogo: React.FunctionComponent<LogoProps> = ({
  colorOne,
  colorTwo,
  width,
  height,
}) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.808 11.72l-1.071-.716-1.294 3.645 3.363 2.249.406-1.159.887-2.487-2.291-1.533zM15.666 6.132H19.84l-1.293-3.645H14.41l.406 1.192.85 2.453zM7.574 6.132l.887-2.453.406-1.192H4.692L3.436 6.132h4.138zM9.348 19.18l-1.072.716 3.363 2.248L15 19.896l-1.072-.716-2.29-1.533-2.291 1.533zM20.469 11.72l-2.29 1.532.886 2.487.406 1.159 3.363-2.249-1.293-3.645-1.072.715z"
        fill={colorTwo}
      />
      <path
        d="M11.454 8.551l.184-.545.185.545.591 1.704h2.587l-.518.34-1.551 1.056.59 1.704.185.545-.517-.34-1.588-1.057-1.59 1.056-.517.34.185-.544.591-1.704-1.588-1.056-.518-.34H10.752l.702-1.704zM11.638 0l-1.477 4.19-.887 2.487-.407 1.125H0l3.843 2.589 2.29 1.533 1.035.715-.407 1.125-.85 2.486-1.477 4.19 3.842-2.588 2.291-1.533 1.034-.716 1.035.716 2.29 1.533 3.917 2.555-1.478-4.19-.886-2.487-.407-1.125 1.035-.715 2.29-1.533 3.88-2.555h-8.904l-.407-1.125-.887-2.487L11.64 0z"
        fill={colorOne}
      />
    </svg>
  )
}
