import React from 'react'
import { Box, Flex, Text } from 'rebass'

import { RosterEntry, Team } from '../../../types/graphql'
import { rawNumPlayers, roles } from '../../../utils/sportUtils'
import { BaseButton } from '../../atoms/Buttons'
import { CustomSelect } from '../../atoms/Select'
import Table from '../../atoms/Table'

interface ReportingProps {
  team: Team
  isHomeTeam: boolean
  handlePlayerChange: (e: React.ChangeEvent, isHomeTeam: boolean, i: number) => void
  teamResults: { [key: number]: { [key: string]: string } }
  setWinner: (value: any) => void
  isWinningTeam: boolean
  lolChampions: string[]
}

const LolReportingTable = ({
  team,
  isHomeTeam,
  handlePlayerChange,
  teamResults,
  setWinner,
  isWinningTeam,
  lolChampions,
}: ReportingProps) => {
  const num = Number(rawNumPlayers(team.sportSlug))

  const handleScore = () => {
    setWinner(team.id)
  }

  return (
    <>
      <Box width={[1, 1, 1, 1 / 2]} mt={5} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]}>
        <Flex alignItems="center" justifyContent="space-between">
          <h3>{team.truncatedName}</h3>
          <BaseButton variant={isWinningTeam ? 'selected' : 'select'} onClick={handleScore}>
            select as winner
          </BaseButton>
        </Flex>
        <Box mt={4}>
          <Table
            bodyBackgroundColor="backgroundgray"
            thpadding="0.8rem .75rem 0.7rem"
            tdpadding=".8rem .5rem"
            align="left"
          >
            <colgroup>
              <col span={1} style={{ width: '40%' }} />
              <col span={1} style={{ width: '34%' }} />
              <col span={1} style={{ width: '26%' }} />
            </colgroup>

            <thead>
              <tr>
                <th>
                  <Text color="white">
                    <h6>starting player</h6>
                  </Text>
                </th>
                <th>
                  <Text color="white">
                    <h6>pick</h6>
                  </Text>
                </th>
                <th>
                  <Text color="white">
                    <h6>Role</h6>
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(num)].map((_el, i: number) => (
                <tr key={i}>
                  <td>
                    <CustomSelect
                      key={teamResults[i].name}
                      onChange={e => {
                        handlePlayerChange(e, isHomeTeam, i)
                      }}
                      value={teamResults[i].name}
                      name={'name'}
                    >
                      {team.activeRosterEntries &&
                        team.activeRosterEntries.map((entry: RosterEntry) => (
                          <option key={entry.id}>{entry.player && entry.player.username}</option>
                        ))}
                    </CustomSelect>
                  </td>
                  <td>
                    <CustomSelect
                      key={teamResults[i].pick}
                      onChange={e => {
                        handlePlayerChange(e, isHomeTeam, i)
                      }}
                      value={teamResults[i].pick}
                      name={'pick'}
                    >
                      {lolChampions.length ? (
                        lolChampions.map((champion: string) => (
                          <option key={champion}>{champion}</option>
                        ))
                      ) : (
                        <option>{teamResults[i].pick}</option>
                      )}
                    </CustomSelect>
                  </td>
                  <td>
                    <CustomSelect
                      key={teamResults[i].role}
                      onChange={e => {
                        handlePlayerChange(e, isHomeTeam, i)
                      }}
                      value={teamResults[i].role}
                      name={'role'}
                    >
                      {team.sportSlug &&
                        roles(team.sportSlug) &&
                        roles(team.sportSlug).map(role => <option key={role}>{role}</option>)}
                    </CustomSelect>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Box>
    </>
  )
}
export default LolReportingTable
