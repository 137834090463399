import React from 'react'

import { paths } from '../../utils/Routes'
import ErrorBox from '../organisms/ErrorBox'

const title = (
  <>
    Ooooops! <br /> This page doesn't exist
  </>
)

interface IColorProps {
  background?: boolean
}

const NoMatch: React.FC<IColorProps> = ({ background }) => {
  return (
    <ErrorBox title={title} linkTo={paths.homepage()} linkText="Return Home" background={background}>
      <p>
        Double check your URL and make sure you're in the right place. If you are lost you can
        click the button below to start your journey from the beginning.
      </p>
    </ErrorBox>
  )
}
export default NoMatch
