import React from 'react'
import Helmet from 'react-helmet'
import { SiteSwitch } from '../../utils/sites'

const SEO = () => (
  <SiteSwitch
    college={
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.png" />
        <link rel="apple-touch-icon" href="logo192.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="CSL" />
        <meta
          name="keywords"
          content="CSL, Collegiate StarLeague, Collegiate Star League, college esports, collegiate esports, esports, college, gaming, egaming, leagues, tournaments, video games, Rocket League, RL, LoL, League of Legends, Valorant, Dota, CS:GO, EA, EA Sports, Madden, NBA2K, Competitive Gaming"
        />
        <title>CSL Esports – Your Esports Experience Starts Here</title>
        <meta name="og:title" content="CSL Esports" />
        <meta name="og:image" content="/csl-primary-blue.png" />
        <meta name="og:description" content="Your collegiate esports experience starts here" />
        <script src="https://cdn.rawgit.com/progers/pathseg/master/pathseg.js"></script>
        <script type="text/javascript" dangerouslySetInnerHTML={{__html: `
          ;(window.heap = window.heap || []),
            (heap.load = function(e, t) {
              ;(window.heap.appid = e), (window.heap.config = t = t || {})
              var r = t.forceSSL || 'https:' === document.location.protocol,
                a = document.createElement('script')
              ;(a.type = 'text/javascript'),
                (a.async = !0),
                (a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js')
              var n = document.getElementsByTagName('script')[0]
              n.parentNode.insertBefore(a, n)
              for (
                var o = function(e) {
                    return function() {
                      heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                    }
                  },
                  p = [
                    'addEventProperties',
                    'addUserProperties',
                    'clearEventProperties',
                    'identify',
                    'resetIdentity',
                    'removeEventProperty',
                    'setEventProperties',
                    'track',
                    'unsetEventProperty',
                  ],
                  c = 0;
                c < p.length;
                c++
              )
                heap[p[c]] = o(p[c])
            })
          heap.load('${process.env.REACT_APP_HEAP_APP_ID}')
        `}} />
      </Helmet>
    }
    esports={
      <Helmet>
        <title>The home of esports and competitive gaming by CSL</title>
        <meta 
          name="keywords" 
          data-react-helmet="true"
          content="CSL, CSL Esports, WorldGaming, WGN, WorldGaming Network, Video Games, esports, egaming, tournaments, leagues, Gaming Network, video game tournaments, CoD, Call of Duty, Rocket League, RL, EA, EA Sports, NHL, Madden, FIFA, NBA2K, Competitive Gaming, " 
        />
      </Helmet>
    } />
)

export default SEO
