import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { useNewsArticleDetailQuery as useNewsArticleDetail } from '../../types/graphql'

import DataFetcher from '../organisms/DataFetcher'
import NewsArticleDetail from '../organisms/NewsArticleDetail'
import NewsArticleDetailHero from '../atoms/NewsArticleDetailHero'
import { paths } from '../../utils/Routes'

gql`
  query NewsArticleDetail($slug: String!) {
    newsArticle(slug: $slug) {
      id
      createdAt
      title
      summary
      body
      featuredImage
      featured
      position
      type
      slug
      newsCategory {
        id
        name
      }
    }
  }
`

interface IRouteParams {
  slug: string
}

const NewsArticle: React.FC = () => {
  const params = useParams<IRouteParams>()
  const { slug } = params
  const { error, loading, data } = useNewsArticleDetail({
    variables: { slug },
    fetchPolicy: 'cache-and-network',
  })

  if (!loading && !data) {
    return <Redirect to={paths.homepage()} />
  }

  return (
    <DataFetcher error={error} loading={loading || !data}>
      {data && data.newsArticle && (
        <>
          <NewsArticleDetailHero id={data.newsArticle.id} />
          <NewsArticleDetail newsArticle={data.newsArticle} />
        </>
      )}
    </DataFetcher>
  )
}

export default NewsArticle
