import React from 'react'
import { Box, BoxProps, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { BaseButton } from '../atoms/Buttons'
import TabLabel from '../atoms/TabLabel'

const InputBox = styled(Box)<BoxProps>`
  position: relative;
`
const DateButton = styled(BaseButton)`
  background-color: ${props => props.theme.colors.backgroundgray};
  color: ${props => props.theme.colors.darkgray};
  border-color: ${props => props.theme.colors.darkmiddlegray};
  padding: 12px 30px 10px;
  :hover {
    background-color: transparent;
    border-color: ${props => props.theme.colors.darkmiddlegray};
  }
`
interface ICustomPickerProps {
  label: string
  value?: Date | null
  error?: string
  onClick?: (value: any) => void
}
export const CustomDatePicker = ({ label, value, error, onClick }: ICustomPickerProps) => {
  return (
    <InputBox>
      <TabLabel text={label} />
      <DateButton onClick={onClick}>{value ? value : 'Select date and time'}</DateButton>
      <Text textAlign="center" color="red" mt={2}>
        {error}
      </Text>
    </InputBox>
  )
}
