import { Button, ButtonProps } from 'rebass'

import { MediaQueries as Mq, styled } from '../../styles/settings/theme'

interface IButtonProps extends ButtonProps {
  fullWidth?: boolean
}

export const BaseButton = styled(Button)<IButtonProps>`
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.1em;
  font-family: ${props => props.theme.fonts.condensed};
  font-size: 1rem;
  transition: ${props => props.theme.transitions.fast};
  line-height: 1.1rem;
  border-radius: 0px;
  outline: none;
  width: ${props => (props.fullWidth ? '100%' : 'max-content')};

  ${Mq.md} {
    width: ${props => (props.fullWidth ? '100%' : 'auto')};
  }
`
//all button styles are defined in the theme

//usage example: pass in the style in the variant prop
//<BaseButton variant='primary'>primary button</BaseButton>

// variants are:
// primary
// primaryDisabled
// secondary
// secondaryDisabled
// whiteSecondary
// decline
// tertiary
// tertiaryOutlined
// teritaryDisabled

export const UnstyledButton = styled.button`
  border: none;
  background: none;
  transition: ${props => props.theme.transitions.fast};
  cursor: pointer;
  padding: 0;
  line-height: 0;
`
