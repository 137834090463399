import React, { useContext } from 'react'
import { TeamContext } from '../../contexts/TeamContext'

import DataFetcher from './DataFetcher'
import EditTeamDetails from '../pages/ManageTeam/EditTeamDetails'

const ManageTeamDetails: React.FC = () => {
  const { teamContextLoading } = useContext(TeamContext)
  return (
    <DataFetcher error={undefined} loading={teamContextLoading}>
      <EditTeamDetails />
    </DataFetcher>
  )
}
export default ManageTeamDetails
