import React from 'react'
import { Flex, Text, TextProps } from 'rebass'
import { Crosshair } from 'react-feather'
import { styled } from '../../styles/settings/theme'

const StyledSVG = styled(Text)<TextProps>`
  margin-top: -2px;
`

const RecruitingLabel = () => {
  return (
    <Flex>
      <StyledSVG>
        <Crosshair color="white" size="20" />
      </StyledSVG>
      <Text color="white" ml={'.5rem'}>
        <h6>Recruiting</h6>
      </Text>
    </Flex>
  )
}

export default RecruitingLabel
