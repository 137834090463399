import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import { useModal } from 'react-modal-hook'
import { useTheme } from 'emotion-theming'
import React, { useState } from 'react'

import { BaseButton } from '../atoms/Buttons'
import { Forfeit } from '../../types/graphql'
import { paths } from '../../utils/Routes'
import ModalContainer from '../atoms/ModalContainer'
import SportLogo from '../atoms/SportLogo'
import Table from '../atoms/Table'
import ViewForfeit from './ViewForfeit'
import { adjustedFullWithShortMonthAndTime } from '../../utils/dateTimeFormats'

interface IForfeitsTableProps {
  forfeits: Forfeit[]
}

const ForfeitsTable: React.FC<IForfeitsTableProps> = ({ forfeits }) => {
  const { colors } = useTheme()
  const [currentForfeit, setCurrenfForfeit] = useState<Forfeit>({} as Forfeit)

  const [showViewForfeitModal, hideViewForfeitModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideViewForfeitModal}
        style={{
          content: { maxWidth: '60rem' },
        }}
      >
        <ViewForfeit forfeit={currentForfeit} closeModal={hideViewForfeitModal} />
      </ModalContainer>
    ),
    [currentForfeit],
  )

  return (
    <Table headerBackgroundColor="middlegray">
      <thead>
        <tr>
          <th>
            <h6>Sport</h6>
          </th>
          <th>
            <h6>Last Updated</h6>
          </th>
          <th>
            <h6>Requesting Team</h6>
          </th>
          <th>
            <h6>Opposing Team</h6>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {forfeits.map(forfeit => (
          <tr key={forfeit.id}>
            <td>
              <Link to={paths.matchDetail(forfeit.match.id)}>
                <SportLogo sport={forfeit.match.sport} width="2rem" height="2rem" />
              </Link>
            </td>
            <td>
              <h6>{adjustedFullWithShortMonthAndTime(forfeit.updatedAt)}</h6>
            </td>
            <td>
              <Link to={paths.matchDetail(forfeit.match.id)}>
                <Text color={colors.darkgray}>
                  <h6>{forfeit.requestingTeamName}</h6>
                </Text>
              </Link>
            </td>
            <td>
              <Link to={paths.matchDetail(forfeit.match.id)}>
                <Text color={colors.darkgray}>
                  <h6>{forfeit.opposingTeamName}</h6>
                </Text>
              </Link>
            </td>
            <td>
              <BaseButton
                variant="primary"
                onClick={() => {
                  setCurrenfForfeit(forfeit)
                  showViewForfeitModal()
                }}
              >
                <h6>Review</h6>
              </BaseButton>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ForfeitsTable
