import _, { Dictionary } from 'lodash'

import { InputError } from '../types/graphql'
import { capitalize, spaceify } from './strings'

type Errors = InputError[]

interface IResult<A> {
  success: Boolean
  value?: A
  errors?: Errors | null
}

export function errorsFrom<A>(result: IResult<A>) {
  if (result.success) {
    return {}
  }
  const fieldMessagePairs = (result.errors || []).map(({ field, message }) => {
    return [field, message && capitalize(spaceify(message))]
  })
  return _.fromPairs(fieldMessagePairs)
}

export function errorOn(fieldName: string, errors: Dictionary<string>): string {
  return errors[fieldName] || ''
}

export function handleResult<A, OnSuccessType = void, OnFailureType = void>({
  result,
  onSuccess,
  onFailure,
}: {
  result: IResult<A>
  onSuccess: (value: NonNullable<A>) => OnSuccessType
  onFailure: (errors: Dictionary<string>) => OnFailureType
}) {
  if (result.success) {
    return onSuccess(result.value!)
  }

  const errors = errorsFrom(result)
  return onFailure(errors)
}

export function errorsFullMessage(errors: _.Dictionary<string>) {
  return _.map(errors, _.upperFirst).join('. ')
}
