import React from 'react'
import { Flex, Text, FlexProps } from 'rebass'
import parse from 'html-react-parser'
import { styled } from '../../styles/settings/theme'

interface TournamentDescriptionProps {
  description: string
  location: string
}

const TournamentDescriptionWrapper = styled(Flex)<FlexProps>`
  * {
    font-family: ${props => props.theme.fonts.body};
    color: ${props => props.theme.colors.black};
    font-size: 1rem;
  }

  p {
    margin-bottom: 1.5rem;
    padding-top: 1rem;
  }

  ul {
    line-height: 1.4rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    list-style-type: disc;

    ul {
      list-style-type: circle;
    }

    ul ul {
      list-style-type: disc;
    }
  }

  ol {
    line-height: 1.4rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
  }

  li {
    margin-bottom: 1rem;
    line-height: 1.4rem;
  }

  a {
    color: ${props => props.theme.colors.primaryblue};
    transition: ${props => props.theme.transitions.fast};

    &:hover {
      color: ${props => props.theme.colors.primarynavy};
    }
  }
`

const TournamentDescription: React.FunctionComponent<TournamentDescriptionProps> = ({
  description,
  location,
}) => {
  const parseTournamentDescription = () => {
    const parser = new DOMParser()
    const body = parser.parseFromString(description, 'text/html').body
    body.querySelectorAll('*').forEach(el => {
      el.removeAttribute('style')

      if (el.nodeName === 'PRE') {
        const div = document.createElement('div')
        el.innerHTML.split('\n').forEach((string: string) => {
          let replacement
          if (string === '') {
            replacement = document.createElement('br')
          } else {
            replacement = document.createElement('div')
            replacement.innerHTML = string
          }
          div.appendChild(replacement)
        })
        el.parentNode!.replaceChild(div, el)
      }
    })
    return parse(body.innerHTML)
  }
  return (
    <TournamentDescriptionWrapper flexDirection="column" py={4} px={[4, 4, 6]}>
      {parseTournamentDescription()}
      {location && (
        <>
          <Text mb={5} mt={5}>
            <h3>location</h3>
          </Text>
          <p>{location}</p>
        </>
      )}
    </TournamentDescriptionWrapper>
  )
}

export default TournamentDescription
