import _ from 'lodash'
import dayjs from 'dayjs'

import { LeagueRegistrationStatuses as Status } from '../types/graphql'

export interface ISeasonLike {
  registrationStatus: Status
  id: string
}

export function withRegistrationStatus(seasons: ISeasonLike[], status: Status) {
  return _.filter(seasons, season => season.registrationStatus === status)
}

export function registrationStatusCount(seasons: ISeasonLike[], status: Status) {
  return withRegistrationStatus(seasons, status).length
}

export function currentlyInRegistration() {
  const now = dayjs()
  return (
    dayjs(process.env.REACT_APP_REGISTRATION_OPEN_DATE) <= now &&
    dayjs(process.env.REACT_APP_REGISTRATION_CLOSE_DATE) >= now
  )
}

export default {
  withRegistrationStatus,
  registrationStatusCount,
  currentlyInRegistration,
}
