import React from 'react'
import { Box, BoxProps } from 'rebass'
import { Link } from 'react-router-dom'
import { styled } from '../../styles/settings/theme'

import { paths } from '../../utils/Routes'
import { Image } from 'rebass'
import DefaultLogoImage from '../../assets/images/logos/CSL_Esports_Primary_Blue.png'

interface IProps {
  variant: string
}

export const LogoBox = styled(Box)<BoxProps>`
  display: block;
  width: 6rem;
  margin: -4px auto auto 0;
  position: relative;
`

export const LogoText = styled(Box)<BoxProps>`
  position: absolute;
  bottom: 0px;
  left: 14px;

  h6 {
    font-size: 11px;
    letter-spacing: 0.75px;
    font-weight: 700;
  }
`

const Logo = ({ variant }: IProps) => {
  return (
    <LogoBox>
      <Link to={paths.homepage()}>
        <Image
          alt={DefaultLogoImage}
          src={DefaultLogoImage}
        />
      </Link>
    </LogoBox>
  )
}

export default Logo
