import React from 'react'
import { Box, Flex } from 'rebass'

import { Round, LowerLine } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getTuples } from '../../utils/bracketDataUtils'

interface RoundProps {
  shift?: string
  seatList: Array<number>
  bottomHalf?: boolean
  numTeams: number
}

const Round2ALosers: React.FunctionComponent<RoundProps> = ({
  seatList,
  shift,
  bottomHalf,
  numTeams,
}) => {
  const halfSeats = !bottomHalf
    ? seatList.slice(0, seatList.length / 2)
    : seatList.slice(seatList.length / 2, seatList.length)

  const seats = getTuples(halfSeats)

  return (
    <Round round="2A" shift={shift} numTeams={numTeams}>
      <Box>
        {seats.map((tuple, i) => (
          <Flex key={tuple[0]} mb={i % 2 !== 0 ? '40px' : '0px'}>
            <BracketCouplet key={i} seats={tuple} round="2A" bottom={'10px'} numTeams={numTeams} />
            <LowerLine round="2A" numTeams={numTeams} bottomHalf={bottomHalf} />
          </Flex>
        ))}
      </Box>
    </Round>
  )
}

export default Round2ALosers
