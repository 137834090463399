import React from 'react'
import { Box } from 'rebass'
import { MediaQueries, styled } from '../../styles/settings/theme'

import AsciiEmoji from '../atoms/AsciiEmoji'
import Card from '../atoms/Card'

const StyledCard = styled(Card)`
  text-align: center;
  background: ${props => props.theme.colors.white};
  padding: 4rem;
`

const TableFlipEmoji = styled(AsciiEmoji)`
  font-size: 1.5rem;

  ${MediaQueries.sm} {
    font-size: ${props => props.theme.fontSizes[2]};
  }
`
interface ResultsErrorProps {
  keyword: string
}

const ErrorLoading: React.FunctionComponent<ResultsErrorProps> = ({ keyword }) => (
  <StyledCard notch={true} notchSize={20}>
    <TableFlipEmoji mb={7}>(ノಠ益ಠ)ノ彡┻━┻</TableFlipEmoji>

    <Box mb={5}>
      <h2>{`No ${keyword} found`}</h2>
    </Box>

    <Box mb={5} width={[1, 4 / 5, 3 / 4, 2 / 3, 1 / 2]} mx={'auto'}>
      <p>Try modifying your search filters for more results.</p>
    </Box>
  </StyledCard>
)

export default ErrorLoading
