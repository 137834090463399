import React, { ReactNode } from 'react'
import { useTheme } from 'emotion-theming'
import { Flex, FlexProps, Text, Box } from 'rebass'
import { Settings } from 'react-feather'
import { MediaQueries as Mq, styled } from '../../styles/settings'

import ModalContainer from '../atoms/ModalContainer'
import Table from '../atoms/Table'

interface IProps {
  children: ReactNode
}

interface IModalProps extends IProps {
  hideModal: () => void
}

interface IHeaderProps {
  text: string
  onSettingsClick?: () => void
}

interface ITableHeaderProps {
  text: string
}

export const SettingsTable = ({ children }: IProps) => (
  <Box mt={5}>
    <Table
      headerBackgroundColor="middlegray"
      thpadding="1rem 1.5rem"
      tdpadding="1rem 1.5rem"
      notchedTopRightBottomLeft
    >
      {children}
    </Table>
  </Box>
)

export const Container = styled(Flex)<FlexProps>`
  flex-direction: column;
  min-height: auto;
  padding: ${props => props.theme.space[8]}px ${props => props.theme.space[4]}px;
  ${Mq.md} {
    margin: 0 auto;
    max-width: 820px;
  }
`

export const SettingsBlock = styled(Flex)<FlexProps>`
  flex-direction: column;
  background: white;
  margin-top: 20px;
`

export const SettingsContent = styled(Flex)<FlexProps>`
  flex-direction: column;
  padding: 0 25px 10px 25px;
`

export const Separator = styled.div`
  background: ${props => props.theme.colors.middlegray};
  height: 2px;
  width: 100%;
`

export const StyledSettings = styled(Settings)`
  cursor: pointer;
`

export const Header = styled(Flex)<FlexProps>`
  padding: 16px 25px;
  border-radius: 4px 4px 0px 0px;
  background: ${props => props.theme.colors.middlegray};
  align-items: center;
  margin-bottom: 10px;
`

export const SettingsHeader = ({ text, onSettingsClick }: IHeaderProps) => (
  <Header justifyContent="space-between">
    <Text color="primarynavy">
      <h4>{text}</h4>
    </Text>
    {onSettingsClick && <StyledSettings onClick={onSettingsClick} />}
  </Header>
)

export const SettingsModal = (props: IModalProps) => (
  <ModalContainer {...props} style={{ content: { maxWidth: '50rem' } }} />
)

export const SettingsTableHeader: React.FC<ITableHeaderProps> = ({ text }) => {
  const { colors } = useTheme()
  return (
    <th>
      <Text color={colors.primarynavy}>
        <h4>{text}</h4>
      </Text>
    </th>
  )
}
