import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { Link } from 'react-router-dom'

import { styled, ThemeColors } from '../../../styles/settings/theme'
import { paths } from '../../../utils/Routes'
import { adjustedFullWithShortMonth, adjustedTimeOnly } from '../../../utils/dateTimeFormats'
import SportLogo from '../../atoms/SportLogo'
import { MatchCarouselTournamentCheckinInfoFragment as Tournament } from '../../../types/graphql'
import { BaseButton } from '../../atoms/Buttons'

interface ITournamentCardProps {
  tournament: Tournament
}

interface ITournamentCardBodyProps {
  tournament: Tournament
}
interface ITournamentCardHeaderProps {
  tournament: Tournament
}
interface ITournamentCardWrapperProps {
  borderColor: ThemeColors
}

const TournamentCardHeaderWrapper = styled(Flex)`
  padding-bottom: 1rem;
  border-bottom: 2px solid ${props => props.theme.colors.darkmiddlegray};
`

const TournamentCardBodyWrapper = styled(Flex)`
  margin-top: 1.5rem;
`

const TournamentCardWrapper = styled(Box)<ITournamentCardWrapperProps>`
  padding: 1rem;
  background-color: ${props => props.theme.colors.white};
  border: ${props => `2px solid ${props.theme.colors[props.borderColor]}`};
`

const TournamentCardHeader: React.FC<ITournamentCardHeaderProps> = ({ tournament }) => {
  const sport = tournament.sport // TODO
  return (
    <TournamentCardHeaderWrapper flexDirection="column">
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box width={1 / 2}>
          <Flex alignItems="center">
            <SportLogo width="2rem" height="2rem" sport={sport} />
            <Text textAlign="left" ml={3}>
              <h6>{adjustedFullWithShortMonth(tournament.firstMatchDate)}</h6>
            </Text>
          </Flex>
        </Box>
        <Box width={1 / 2}>
          <Text textAlign="right">
            <h6>{adjustedTimeOnly(tournament.firstMatchDate)}</h6>
          </Text>
        </Box>
      </Flex>

      <Box width={1 / 2}>
        <Flex flexDirection="row" alignItems="center" justifyContent="flex-end">
          &nbsp;
        </Flex>
      </Box>
    </TournamentCardHeaderWrapper>
  )
}

const TournamentCardBody: React.FC<ITournamentCardBodyProps> = ({ tournament }) => {
  return (
    <TournamentCardBodyWrapper flexDirection="column">
      <Flex flexDirection="column">
        <Box width={1}>
          <Flex flexDirection="row" justifyContent="space-between">
            <Text textAlign="left" mt={2} mb={1}>
              <h6>{tournament.name}</h6>
            </Text>
            <Text textAlign="right"></Text>
          </Flex>
        </Box>

        <Box width={1}>
          <Flex flexDirection="row" justifyContent="space-between">
            <Text textAlign="left">
              <Flex mt={2}>&nbsp;</Flex>
            </Text>
          </Flex>
          <Link to={paths.tournament(tournament.id)}>
            <BaseButton
              mt={1}
              width={[1, 1]}
              variant={
                tournament.currentUserCheckedIn || !tournament.currentUserCanCheckIn
                  ? 'wideNavy'
                  : 'wideSand'
              }
              fullWidth
            >
              {tournament.currentUserCheckedIn || !tournament.currentUserCanCheckIn
                ? 'View tournament'
                : 'Checkin to tournament'}
            </BaseButton>
          </Link>
        </Box>
      </Flex>
    </TournamentCardBodyWrapper>
  )
}

export const TournamentCard: React.FC<ITournamentCardProps> = ({ tournament }) => {
  return (
    <TournamentCardWrapper
      borderColor={
        !tournament.currentUserCheckedIn && tournament.currentUserCanCheckIn ? 'sand' : 'white'
      }
    >
      <TournamentCardHeader tournament={tournament} />
      <TournamentCardBody tournament={tournament} />
    </TournamentCardWrapper>
  )
}
