import { useEffect } from 'react'
import gql from 'graphql-tag'
import { useConstantsLazyQuery } from '../types/graphql'

gql`
  query Constants {
    constants {
      sportSlugs
      postCategories
      teamStaffRoles
      currentSeasonYear
    }
  }
`

export function useConstants() {
  // TODO: should probably cache these in like sessionstorage w/ a short-ish
  // cache period of maybe a day or something
  const [query, { data }] = useConstantsLazyQuery({
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    query()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return data && data.constants
}
