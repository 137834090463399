import gql from 'graphql-tag'
import React, { useContext, useEffect, ReactNode } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { allFieldsQueryVariabls } from '../../../utils/gamingProfilesUtils'

import DataFetcher from '../../organisms/DataFetcher'

const USER_PROFILE_SETTINGS = gql(String.raw`
  query userProfileSettings($id: ID) {
   userSettings(id: $id) {
      isConfirmed
      name
      gender
      email
      discordUsername
      graduationYear
      university {
        id
        name
      }
      universityMajor {
        id
        name
      }
      playedSports {
        slug
      }
      discordUsername
      twitchUsername
      twitterUsername
      facebookUsername
      ${allFieldsQueryVariabls}
    }
  }
`)

interface IProps {
  children?: ReactNode
  playerId?: string
}

const ProfileSettingsQueryProvider: React.FC<IProps> = ({ children, playerId }) => {
  const {
    currentUserContextLoading,
    errorsExist,
    updateFromQuery,
    setCurrentUserContextLoading,
  } = useContext(CurrentUserContext)
  const { data, error, loading } = useQuery(USER_PROFILE_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    variables: { id: playerId },
    onCompleted: data => {
      if (data.userSettings && !errorsExist) {
        updateFromQuery(data.userSettings)
      }
    },
  })
  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (data && (!data.userSettings || !data.userSettings.email)) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <DataFetcher loading={currentUserContextLoading} error={error}>
      {children}
    </DataFetcher>
  )
}

export default ProfileSettingsQueryProvider
