import gql from 'graphql-tag'
import { useDivisionsAttrsQuery } from '../types/graphql'

gql`
  query DivisionsAttrs($seasonYears: [String!]) {
    divisions(seasonYears: $seasonYears) {
      name
      position
      bannerUrl
      logoUrl
    }
  }
`
export function useDivisionsAttrs({ seasonYears }: { seasonYears?: string[] } = {}) {
  const { data } = useDivisionsAttrsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      seasonYears,
    },
  })

  const divisionsAttrs = (data && data.divisions) || []

  return { divisionsAttrs }
}
