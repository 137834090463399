import React from 'react'
import { Flex } from 'rebass'
import { Round, Line } from '../atoms/BracketPieces'
import BracketCouplet from '../molecules/BracketCouplet'
import { getQuadrant } from '../../utils/bracketDataUtils'

interface RoundProps {
  flip?: boolean
  shift?: string
  bottomHalf: boolean
  numTeams: number
  seatList: Array<number>
  isDoubleElim?: boolean
}

const RoundOf16: React.FunctionComponent<RoundProps> = ({
  flip,
  shift,
  bottomHalf,
  numTeams,
  seatList,
  isDoubleElim,
}) => {
  const topLeft = getQuadrant(seatList, 1)[0]
  const topRight = getQuadrant(seatList, 2)[0]
  const bottomLeft = getQuadrant(seatList, 3)[0]
  const bottomRight = getQuadrant(seatList, 4)[0]

  let seats = flip && bottomHalf ? bottomRight : bottomHalf ? bottomLeft : flip ? topRight : topLeft

  const top = (size: number) => {
    const topPxAmount: { [key: number]: string } = {
      128: bottomHalf ? '316px' : '281px',
      64: bottomHalf ? '261px' : '221px',
      32: bottomHalf ? '127px' : '163px',
      16: isDoubleElim && bottomHalf ? '140px' : bottomHalf ? '43px' : '163px',
    }
    return topPxAmount[size]
  }

  const bottom = (size: number) => {
    const bottomPxAmount: { [key: number]: string } = {
      128: '609px',
      64: '509px',
      32: '298px',
      16: '128px',
    }
    return bottomPxAmount[size]
  }

  return (
    <Round round="16" top={top(numTeams)} shift={shift}>
      <Flex>
        {flip && <Line numTeams={numTeams} round="16" flip={flip} />}
        <BracketCouplet seats={seats} round="16" bottom={bottom(numTeams)} flip={flip} />
        {!flip && <Line numTeams={numTeams} round="16" flip={flip} />}
      </Flex>
    </Round>
  )
}

export default RoundOf16
