import React from 'react'
import { Text, Flex, Box } from 'rebass'
import { isEmpty } from 'lodash'
import { useTheme } from 'emotion-theming'

import SportLogo from '../atoms/SportLogo'
import { BaseButton } from '../atoms/Buttons'
import { SettingsTableHeader, SettingsTable } from '../atoms/SettingsPieces'
import { PendingInvitation } from '../../types/graphql'

interface ITeamInviteProps {
  pendingInvitations: PendingInvitation[]
  handleRepsonse: (accepted: boolean, invitation: PendingInvitation) => void
  acceptedInvitations?: PendingInvitation[]
  declinedInvitations?: PendingInvitation[]
}

const TeamInvites: React.FC<ITeamInviteProps> = ({
  pendingInvitations,
  handleRepsonse,
  acceptedInvitations,
  declinedInvitations,
}) => {
  const { colors } = useTheme()
  const accepted = (invitation: PendingInvitation) => {
    return (
      acceptedInvitations &&
      !isEmpty(acceptedInvitations) &&
      acceptedInvitations.includes(invitation)
    )
  }

  const declined = (invitation: PendingInvitation) => {
    return (
      declinedInvitations &&
      !isEmpty(declinedInvitations) &&
      declinedInvitations.includes(invitation)
    )
  }

  return (
    <Box>
      <SettingsTable>
        <thead>
          <tr>
            <SettingsTableHeader text="invites" />
            <SettingsTableHeader text="record" />
            <SettingsTableHeader text="role" />
            <SettingsTableHeader text="actions" />
          </tr>
        </thead>

        <tbody>
          {pendingInvitations.map((invitation, index) => (
            <tr key={index}>
              <td style={{ width: '30%' }}>
                <Flex alignItems="center">
                  <SportLogo height="2rem" width="2rem" sport={invitation.team.sportSlug} />
                  <Text color={colors.black} mx={5}>
                    <h6>{invitation.team.name}</h6>
                  </Text>
                </Flex>
              </td>

              <td>
                <Flex alignItems="center">
                  <Text color={colors.darkgray}>
                    <h6>{invitation.team.overallWins}-</h6>
                  </Text>
                  <Text color={colors.darkgray}>
                    <h6>{invitation.team.overallLosses}</h6>
                  </Text>
                </Flex>
              </td>

              <td>
                <Text color={colors.darkgray} width="20%">
                  <h6>{invitation.gameRole}</h6>
                </Text>
              </td>

              <td>
                <Flex justifyContent="flex-end">
                  <BaseButton
                    variant={accepted(invitation) ? 'primary' : 'secondary'}
                    onClick={() => handleRepsonse(true, invitation)}
                    mr={2}
                  >
                    accept
                  </BaseButton>
                  <BaseButton
                    variant={declined(invitation) ? 'declineSelected' : 'decline'}
                    onClick={() => handleRepsonse(false, invitation)}
                  >
                    decline
                  </BaseButton>
                </Flex>
              </td>
            </tr>
          ))}
        </tbody>
      </SettingsTable>
      {acceptedInvitations && !isEmpty(acceptedInvitations) && (
        <Text mt={4} color={colors.green} textAlign="center">
          <p>Click continue to add the required game information.</p>
        </Text>
      )}
      {declinedInvitations && !isEmpty(declinedInvitations) && (
        <Text mt={4} color={colors.red} textAlign="center">
          <p>Clicking continue to will decline the team invite.</p>
        </Text>
      )}
    </Box>
  )
}
export default TeamInvites
