import React from 'react'
import { useModal } from 'react-modal-hook'
import moment from 'moment-timezone'
import { Flex } from 'rebass'
import { isEmpty } from 'lodash'

import { currentUserIsAdmin } from '../../../utils/admins'
import { Team } from '../../../types/graphql'

import { BaseButton } from '../../atoms/Buttons'
import Animation from '../../atoms/Animation'
import ModalContainer from '../../atoms/ModalContainer'
import TournamentRegistrationModal from './TournamentRegistrationModal'
import TournamentCheckinModal from './TournamentCheckinModal'
import TeamsList from './TeamsList'
import CountdownText from './CountdownText'
import StatusText from './StatusText'
import Container from './Container'
import StatusIcon from './StatusIcon'
import { TournamentStatuses } from './enums'

interface ITournamentStatusBoxProps {
  name: string
  sport: string
  tournamentId: string
  currentUserRegisteredTeams: Team[]
  checkinLength?: number
  firstMatchDate?: string
  registrationOpenDate?: string
  registrationCloseDate?: string
  currentUserNumberOfEligibleTeams: number
}

const TournamentStatusBox: React.FC<ITournamentStatusBoxProps> = ({
  name,
  sport,
  tournamentId,
  currentUserRegisteredTeams,
  checkinLength,
  firstMatchDate,
  registrationOpenDate,
  registrationCloseDate,
  currentUserNumberOfEligibleTeams,
}) => {
  const startTime = moment(firstMatchDate)
  const now = moment()
  const tournamentStartCountdown = moment.duration(startTime.diff(now))
  const checkinOpenCountdown = moment
    .duration(startTime.diff(now))
    .subtract(checkinLength, 'minutes')

  const checkinCloseCountdown = moment.duration(startTime.diff(now)).subtract(10, 'minutes')
  const registrationOpenCountdown = moment.duration(moment(registrationOpenDate).diff(now))
  const registrationCloseCountdown = moment.duration(moment(registrationCloseDate).diff(now))

  const teamsLeftToRegister = currentUserNumberOfEligibleTeams > currentUserRegisteredTeams.length
  const coordinatorHasRegisteredTeams = !isEmpty(currentUserRegisteredTeams)
  const checkedInTeams = currentUserRegisteredTeams.filter(t => t.checkedIn)
  const noTeamsCheckedIn = isEmpty(checkedInTeams)
  const teamsLeftToCheckin = !isEmpty(currentUserRegisteredTeams.filter(t => !t.checkedIn))

  const countdownActive = (countdown: moment.Duration) => {
    return countdown.as('minutes') > 0
  }

  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  const [showRegistrationModal, hideRegistrationModal] = useModal(
    () => (
      <ModalContainer hideModal={hideRegistrationModal} style={{ content: { maxWidth: '60rem' } }}>
        <TournamentRegistrationModal
          tournamentId={tournamentId}
          name={name}
          sport={sport}
          hideModal={hideRegistrationModal}
          teamsLeftToRegister={teamsLeftToRegister}
        />
      </ModalContainer>
    ),
    [tournamentId, sport],
  )

  const [showCheckinModal, hideCheckinModal] = useModal(
    () => (
      <ModalContainer hideModal={hideCheckinModal} style={{ content: { maxWidth: '60rem' } }}>
        <TournamentCheckinModal
          tournamentId={tournamentId}
          teams={currentUserRegisteredTeams}
          name={name}
          hideModal={hideCheckinModal}
        />
      </ModalContainer>
    ),
    [tournamentId, currentUserRegisteredTeams],
  )

  const tournamentStatus = () => {
    if (
      (currentUserNumberOfEligibleTeams > 0 && countdownActive(registrationCloseCountdown)) ||
      countdownActive(registrationOpenCountdown)
    ) {
      return countdownActive(registrationOpenCountdown)
        ? TournamentStatuses.RegistrationUpcoming
        : countdownActive(registrationCloseCountdown)
        ? TournamentStatuses.Registering
        : ''
    } else if (
      (coordinatorHasRegisteredTeams || currentUserIsAdmin()) &&
      (countdownActive(checkinOpenCountdown) || countdownActive(checkinCloseCountdown))
    ) {
      return countdownActive(checkinOpenCountdown)
        ? TournamentStatuses.CheckInUpcoming
        : TournamentStatuses.CheckIn
    } else return countdownActive(tournamentStartCountdown) ? TournamentStatuses.Upcoming : ''
  }

  const tournamentStatusContent = {
    [TournamentStatuses.RegistrationUpcoming]: {
      countdownText: 'Registration Begins In',
      countdownValue: registrationOpenCountdown,
    },
    [TournamentStatuses.Registering]: {
      statusText: 'Registration Open',
      teamsList: currentUserRegisteredTeams,
      countdownText: 'Registration Ends In',
      countdownValue: registrationCloseCountdown,
      onCTAClick: showRegistrationModal,
      ctaText: coordinatorHasRegisteredTeams
        ? teamsLeftToRegister
          ? 'Register More Teams'
          : 'Manage Registrations'
        : 'Register',
    },
    [TournamentStatuses.CheckInUpcoming]: {
      teamsList: currentUserRegisteredTeams,
      countdownText: 'Check-ins Begin In',
      countdownValue: checkinOpenCountdown,
      disabledButtonForCountdown: teamsLeftToCheckin,
    },
    [TournamentStatuses.CheckIn]: {
      statusIconColor: noTeamsCheckedIn && coordinatorHasRegisteredTeams ? 'red' : null,
      statusText: coordinatorHasRegisteredTeams ? 'Not Checked In' : null,
      teamsList: checkedInTeams,
      countdownText: 'Check-ins Close In',
      countdownValue: checkinCloseCountdown,
      onCTAClick: coordinatorHasRegisteredTeams ? showCheckinModal : null,
      ctaText: noTeamsCheckedIn
        ? 'Check In'
        : teamsLeftToCheckin
        ? 'Check In Another Team'
        : 'Manage Checkins',
    },
    [TournamentStatuses.Upcoming]: {
      countdownText: 'Matches Begin In',
      countdownValue: tournamentStartCountdown,
    },
  }

  return tournamentStatus() ? (
    <Animation
      initial="hidden"
      animate="visible"
      variants={animations}
      transition={{ ease: 'easeOut', duration: 0.4, delay: 0.4 }}
    >
      <Container>
        <Flex justifyContent="center" alignItems="center" mb={1}>
          {tournamentStatusContent[tournamentStatus()].statusIconColor && (
            <StatusIcon color={tournamentStatusContent[tournamentStatus()].statusIconColor} />
          )}
          {tournamentStatusContent[tournamentStatus()].teamsList &&
          !isEmpty(tournamentStatusContent[tournamentStatus()].teamsList) ? (
            <TeamsList teams={tournamentStatusContent[tournamentStatus()].teamsList} />
          ) : (
            <StatusText status={tournamentStatusContent[tournamentStatus()].statusText} />
          )}
        </Flex>
        <CountdownText
          countdownValue={tournamentStatusContent[tournamentStatus()].countdownValue}
          countdownText={tournamentStatusContent[tournamentStatus()].countdownText}
          isDisabledButton={tournamentStatusContent[tournamentStatus()].disabledButtonForCountdown}
        />
        {tournamentStatusContent[tournamentStatus()].onCTAClick && (
          <BaseButton
            fullWidth
            mt={3}
            variant="tertiary"
            onClick={tournamentStatusContent[tournamentStatus()].onCTAClick}
          >
            {tournamentStatusContent[tournamentStatus()].ctaText}
          </BaseButton>
        )}
      </Container>
    </Animation>
  ) : null
}

export default TournamentStatusBox
