import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import React, { Children } from 'react'
import Slider, { Settings as ISettings } from 'react-slick'
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'
import { styled, Theme } from '../../styles/settings/theme'

interface ICarouselProps extends ISettings {
  children?: React.ReactNode
  className?: any
  onClick?: any
}

const MatchSlider = styled(Slider)`
  & .slick-slide {
    padding: 0 8px;
  }
  & .slick-prev {
    z-index: 9;
    top: -1.8rem;
    right: 2rem;
    left: auto;
  }
  & .slick-next {
    z-index: 9;
    top: -1.8rem;
    right: 0;
  }
`

const NextArrow: React.FC<ICarouselProps> = ({ className, onClick }) => {
  return (
    <FiArrowRight
      className={className}
      onClick={onClick}
      size={40}
      color={Theme.colors.primarynavy}
    />
  )
}

const PrevArrow: React.FC<ICarouselProps> = ({ className, onClick }) => {
  return (
    <FiArrowLeft
      className={className}
      onClick={onClick}
      size={40}
      color={Theme.colors.primarynavy}
    />
  )
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  draggable: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 3,
      },
    },
  ],
}

const Carousel: React.FC<ICarouselProps> = ({ children, ...ICarouselProps }) => {
  const props = { ...settings, ...ICarouselProps }

  return children && Children.toArray(children).length > 3 ? (
    <MatchSlider {...props}>{children}</MatchSlider>
  ) : (
    <>{children}</>
  )
}

export default Carousel
