import React, { useContext, useEffect } from 'react'
import { Text, Flex } from 'rebass'
import { toast } from 'react-toastify'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import SportLogo from '../../atoms/SportLogo'
import { BaseButton } from '../../atoms/Buttons'
import { SettingsTableHeader, SettingsTable } from '../../atoms/SettingsPieces'
import { useDeleteTeamApplicationMutation as useDeleteTeamApplication } from '../../../types/graphql'

const Applications: React.FC = () => {
  const { pendingApplications, setCurrentUserContextLoading } = useContext(CurrentUserContext)

  const [rescindPendingApplication, { loading }] = useDeleteTeamApplication({
    awaitRefetchQueries: true,
    refetchQueries: ['getCurrentUserTeamInformation'],
    onCompleted: () => {
      toast.success('Application removed.', { containerId: 'temporary' })
    },
  })

  useEffect(() => {
    setCurrentUserContextLoading(loading)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return pendingApplications.length ? (
    <SettingsTable>
      <thead>
        <tr>
          <SettingsTableHeader text="applications" />
          <SettingsTableHeader text="record" />
          <SettingsTableHeader text="role" />
          <SettingsTableHeader text="actions" />
        </tr>
      </thead>
      <tbody>
        {pendingApplications.map((rosterEntry, index) => (
          <tr key={index}>
            <td style={{ width: '30%' }}>
              <Flex alignItems="center">
                <SportLogo height="2rem" width="2rem" sport={rosterEntry.team.sportSlug} />
                <Text color="black" mx={5}>
                  <h6>{rosterEntry.team.name}</h6>
                </Text>
              </Flex>
            </td>

            <td>
              <Flex alignItems="center">
                <Text color="darkgray">
                  <h6>{rosterEntry.team.overallWins}-</h6>
                </Text>
                <Text color="darkgray">
                  <h6>{rosterEntry.team.overallLosses}</h6>
                </Text>
              </Flex>
            </td>

            <td>
              <Text color="darkgray">
                <h6>{rosterEntry.gameRole}</h6>
              </Text>
            </td>

            <td>
              <BaseButton
                variant="secondary"
                onClick={() =>
                  rescindPendingApplication({
                    variables: {
                      id: rosterEntry.id,
                    },
                  })
                }
              >
                rescind
              </BaseButton>
            </td>
          </tr>
        ))}
      </tbody>
    </SettingsTable>
  ) : null
}
export default Applications
