import React from 'react'
import { Box } from 'rebass'
import { styled, ThemeColors } from '../../styles/settings/theme'

interface IProps extends ITableProps {
  children: any
  height?: string
  overflow?: string
}
interface IContainerProps extends ITableProps {
  height?: string
}

interface ITableProps {
  headerBackgroundColor?: ThemeColors
  bodyBackgroundColor?: ThemeColors
  thpadding?: string
  tdpadding?: string
  align?: string
  lastRowBorder?: boolean
  bodyBorder?: boolean
  overflow?: string
  notchedTopRightBottomLeft?: boolean
  notchedTopRight?: boolean
}

const Container = styled(Box)<IContainerProps>`
  overflow-x: auto;
  overflow-y: auto;
  height: ${props => props.height || 'auto'};
`

const StyledTable = styled.table<ITableProps>`
  width: 100%;
  overflow: ${props => (props.overflow ? props.overflow : 'hidden')};
  --notchSize: 15px;

  ${props =>
    props.notchedTopRightBottomLeft
      ? `
    clip-path: polygon(
      0% 0px,
      0px 0%,
      calc(100% - var(--notchSize)) 0%,
      100% var(--notchSize),
      100% calc(100% - 0px),
      calc(100% - 0px) 100%,
      var(--notchSize) 100%,
      0% calc(100% - var(--notchSize))
    );
  `
      : ''}

  ${props =>
    props.notchedTopRight
      ? `
  clip-path:
    polygon(
      0% 0%,
      0% 0%,
      calc(100% - var(--notchSize)) 0%,
      100% var(--notchSize),
      100% calc(100% - var(--notchSize)),
      100% 100%,
      0% 100%,
      0% 0%
    );
  `
      : ''}

  thead {
    background-color: ${props =>
      props.headerBackgroundColor
        ? props.theme.colors[props.headerBackgroundColor]
        : props.theme.colors.secondarynavy};
  }

  thead.notched {
    --notchSize: 20px;
      clip-path: polygon(
        0% 0px,
        0px 0%,
        calc(100% - var(--notchSize)) 0%,
        100% var(--notchSize),
        100% calc(100% - 0px),
        0% 100%,
        0% 100%,
        0% 100%
      );

      tr h6 {
        padding: 20px 0;
      }
  }

  th {
    padding: ${props => (props.thpadding ? props.thpadding : '0.5rem 1rem')};

    &:last-child {
      text-align: ${props => (props.align ? props.align : 'right')};
    }
  }

  tbody {
    ${props =>
      props.bodyBorder
        ? `border-bottom: 2px solid ${props.theme.colors.lightgray};
    border-left: 2px solid ${props.theme.colors.lightgray};
    border-right: 2px solid ${props.theme.colors.lightgray};`
        : ''}

    background: ${props =>
      props.bodyBackgroundColor
        ? props.theme.colors[props.bodyBackgroundColor]
        : props.theme.colors.white};

    td {
      padding: ${props => (props.tdpadding ? props.tdpadding : '0 1rem')};
      height: 4.5rem;

      &:last-child {
        text-align: ${props => (props.align ? props.align : 'right')};
      }
    }

    tr:not(:last-child) ${props => (props.lastRowBorder ? ', tr:last-child' : '')} {
      border-bottom: 2px solid ${props => props.theme.colors.lightgray};
    }

    a {
      color: ${props => props.theme.colors.primarynavy};
      :hover {
        color: ${props => props.theme.colors.primaryblue};
      }
    }
  }
`

const Table = ({ children, height, ...rest }: IProps) => (
  <Container height={height}>
    <StyledTable {...rest}>{children}</StyledTable>
  </Container>
)

export default Table
