import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

import { RosterEntry } from '../../types/graphql'
import { teamStarters, teamSubstitutes } from '../../utils/teamUtils'
import PlayersTable, { IPlayersTableProps } from '../molecules/PlayersTable'

interface IProps {
  homeTeamData: ITeamLineupProps
  awayTeamData: ITeamLineupProps
}

interface ITeamLineupProps {
  name: string | null
  rosterEntries: RosterEntry[] | null
  coordinatorId: string | null
  sport: string
}

interface IStarterLineupProps extends IPlayersTableProps {
  teamName: string
  sport: string
}

const StarterContainer = styled(Box)`
  position: relative;
`
const LabelContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
`

const Triangle = styled.div`
  width: 2rem;
  background: linear-gradient(
    to right bottom,
    transparent 50%,
    ${props => props.theme.colors.middlegray} 50%
  );
`

const TeamLabel = styled(Box)`
  background: ${props => props.theme.colors.middlegray};
  padding: 0.5rem;
`

const StarterLineup = ({ sport, teamName, ...rest }: IStarterLineupProps) => {
  return (
    <StarterContainer>
      <LabelContainer justifyContent="flex-end" alignItems="stretch">
        <Triangle />
        <TeamLabel>
          <Text color="primarynavy">
            <h6>{teamName}</h6>
          </Text>
        </TeamLabel>
      </LabelContainer>

      <PlayersTable
        {...rest}
        sport={sport}
        recordsColumn={true}
        showEmptyPositions={true}
        showDiscordUsername
      />
    </StarterContainer>
  )
}

const TeamLineup = ({ name, rosterEntries, coordinatorId, sport }: ITeamLineupProps) => {
  if (!name || !rosterEntries) {
    return <></>
  }

  return (
    <>
      <Box mb={4}>
        <StarterLineup
          teamName={name}
          sport={sport}
          rosterEntries={teamStarters(rosterEntries)}
          coordinatorId={coordinatorId}
        />
      </Box>

      <PlayersTable
        rosterEntries={teamSubstitutes(rosterEntries)}
        lightHeaderBackground={true}
        playerHeaderTitle="Substitutes"
        recordsColumn={true}
        coordinatorId={coordinatorId}
        sport={sport}
        showDiscordUsername
      />
    </>
  )
}

const MatchDetailLineUps: React.FunctionComponent<IProps> = ({
  homeTeamData,
  awayTeamData,
}: IProps) => {
  return (
    <Box>
      <Text color="black" mb={3}>
        <h2>Lineups</h2>
      </Text>

      <Flex flexWrap="wrap" mx={-2} p={1}>
        {[homeTeamData, awayTeamData].map((team, idx) => (
          <Box
            width={[1, 1, 1, 1 / 2]}
            px={[0, 0, 0, 2]}
            mb={idx === 0 ? [8, 8, 8, 0] : undefined}
            key={idx}
          >
            <TeamLineup {...team} />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default MatchDetailLineUps
