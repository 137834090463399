import dayjs from 'dayjs'
import React, { useState, useRef, useEffect } from 'react'
import { useModal } from 'react-modal-hook'
import { Box, Flex, FlexProps, Text } from 'rebass'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { toast } from 'react-toastify'
import CopyToClipboard from 'react-copy-to-clipboard'
import { styled, ThemeColors } from '../../styles/settings/theme'

import { emptyGameLineup, gameStatus } from '../../utils/matchUtils'
import { Game, Map, RosterEntry, Team } from '../../types/graphql'
import { BaseButton } from '../atoms/Buttons'
import LoadingSpinner from '../atoms/LoadingSpinner'
import MatchDetailEmptyLineup from '../molecules/MatchDetailEmptyLineup'
import ModalContainer from '../atoms/ModalContainer'
import PlayersTable from '../molecules/PlayersTable'
import ReportGameModal from '../organisms/ReportGameModal'
import SportLogo from '../atoms/SportLogo'
import { currentUserIsAdmin } from '../../utils/admins'

const AUTO_REPORT_LOL_GAME = gql`
  mutation AutoReportLolGame($id: ID!) {
    autoReportLolGame(id: $id) {
      match {
        id
        settled
      }
    }
  }
`

// import { mapImage } from '../../utils/mapUtils'
interface IProps {
  scheduledDate: string
  sportId: string
  games: Game[]
  homeTeam: Team | null
  awayTeam: Team | null
  settled: boolean
  parentLoading: boolean
  maps: [Map] | null
  currentUserCanReport: boolean
  currentUserIsTeamMember: boolean
}

interface ICircleProps {
  color: ThemeColors
}

const AllGamesContainer = styled(Box)`
  background: ${props => props.theme.colors.white};
`

const GameContainer = styled(Box)`
  border: 2px solid ${props => props.theme.colors.middlegray};
`

const Circle = styled(Box)<ICircleProps>`
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 0.875rem;
  background: ${props => props.theme.colors[props.color]};
`

const ResultsContainer = styled(Flex)<FlexProps>`
  border-top: 2px solid ${props => props.theme.colors.lightgray};
`

const teamIsWinner = (teamId: string, game: Game): boolean =>
  teamId === (game && game.winner && game.winner.team && game.winner.team.id)

const circleColor = (winner: boolean, settled: boolean, upcomingGame: boolean): ThemeColors =>
  winner ? 'green' : settled ? 'middlegray' : upcomingGame ? 'yellow' : 'red'

const MatchDetailGameResults: React.FunctionComponent<IProps> = ({
  scheduledDate,
  games,
  homeTeam,
  parentLoading,
  awayTeam,
  sportId,
  settled,
  maps,
  currentUserCanReport,
  currentUserIsTeamMember,
}: IProps) => {
  const [toReport, setToReport] = useState(0)
  const [loading, setLoading] = useState(parentLoading)
  const [autoReportingFailures, setAutoReportingFailures] = useState({} as any)

  function usePrevious(value: any) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    }, [value])
    return ref.current
  }

  const prevGames = usePrevious(games)

  const [reportLolGame, { called }] = useMutation(AUTO_REPORT_LOL_GAME, {
    refetchQueries: ['matchDetailPage'],
    onError(error) {
      const parsedError = JSON.parse(error.graphQLErrors[0].message)
      const gameId: any = Object.keys(parsedError)[0]
      setAutoReportingFailures({ ...autoReportingFailures, [gameId]: true })
      toast.error(parsedError[gameId], { containerId: 'temporary' })
      updateLoading(false)
    },
    onCompleted(data) {
      if (data.autoReportLolGame.match.settled) {
        toast.success(
          "Thank you for your submission. We're processing it now and brackets will be updated within 5 minutes",
          { containerId: 'temporary' },
        )
      } else {
        toast.success('Thank you for your submission!', { containerId: 'temporary' })
      }
    },
  })

  useEffect(() => {
    if (called && loading && prevGames !== games) {
      updateLoading(false)
    }
  })

  const updateLoading = (loading: boolean) => {
    setLoading(loading)
  }
  const [showReportModal, hideReportModal] = useModal(
    () => (
      <ModalContainer hideModal={hideReportModal}>
        {homeTeam && awayTeam && (
          <ReportGameModal
            homeTeam={homeTeam}
            parentLoading={loading || parentLoading}
            awayTeam={awayTeam}
            settled={settled}
            games={games}
            report={toReport}
            maps={maps}
            updateLoading={updateLoading}
          />
        )}
      </ModalContainer>
    ),
    [toReport, homeTeam, awayTeam, games, maps, loading, updateLoading, parentLoading],
  )

  if (dayjs(scheduledDate).diff(dayjs(), 'hour') >= 24 && !settled) {
    return null
  }

  const upcomingGame = dayjs().isBefore(dayjs(scheduledDate)) ? true : false

  const renderPlayersTable = (team: Team, game: Game) => {
    const isWinner = teamIsWinner(team.id, game)

    if (emptyGameLineup(team, game, isWinner)) {
      return <MatchDetailEmptyLineup />
    }

    const rosterEntries = (isWinner ? game.winner!.players : game.loser!.players)!
      .map(player => {
        return team.rosterEntries!.find(member => member.player && member.player.id === player.id)
      })
      .filter(player => player) as RosterEntry[]

    return (
      <PlayersTable
        bodyBackgroundColor="backgroundgray"
        rosterEntries={rosterEntries}
        coordinatorId={team.coordinator && team.coordinator.id ? team.coordinator.id : null}
        sport={sportId}
        lineupEntries={isWinner ? game.winner!.lineupEntries : game.loser!.lineupEntries!}
        recordsColumn={sportId === 'lol'}
        killsColumn={sportId === 'fortnite'}
      />
    )
  }

  const handleClick = (idx: number) => {
    setToReport(idx)
    showReportModal()
  }

  const autoReportFailed = (id: string) => {
    return autoReportingFailures[id]
  }

  const handleAutoReportClick = (id: string) => {
    updateLoading(true)
    reportLolGame({
      variables: { id: Number(id) },
    })
  }

  const homeTeamLineupExists = homeTeam && homeTeam.rosterEntries && homeTeam.rosterEntries.length

  const awayTeamLineupExists = awayTeam && awayTeam.rosterEntries && awayTeam.rosterEntries.length

  const shouldShowButton = (
    games: Game[],
    idx: number,
    settled: boolean,
    roster1: any,
    roster2: any,
    sport: string,
    needCode: boolean,
  ) => {
    const prevGameReported = idx === 0 ? true : games[idx - 1].winner ? true : false
    if (needCode) {
      if (currentUserIsTeamMember) return !games[idx].winner && roster1 && roster2 && !settled
    } else if (sport === 'mtga') {
      return !games[idx].winner && roster1 && roster2
    } else {
      return !games[idx].winner && roster1 && roster2 && prevGameReported && !settled
    }
  }

  const handleTournamentCode = () => {
    toast.success('The tournament code has been copied to your clipboard.', {
      containerId: 'temporary',
    })
  }

  const getCode = (game: Game) => {
    return (game.riotTournamentCodeData && game.riotTournamentCodeData.tournamentCode) || ''
  }

  return (
    <div data-testid="match-results">
      <Box>
        <Text color="black" mb={3}>
          <h2>Match Results ({games.length})</h2>
        </Text>
        <AllGamesContainer p={5}>
          {games.map((game, idx) => (
            <GameContainer key={game.id} mb={idx === games.length - 1 ? 0 : 4} px={4} py={6}>
              <Flex justifyContent="space-between" px={1} flexWrap="wrap">
                <Flex alignItems="center" flexWrap="wrap">
                  <Box mr={3}>
                    <SportLogo sport={sportId} width="1.875rem" height="1.875rem" />
                  </Box>
                  <Text color="darkgray">
                    <h3>Game {idx + 1}</h3>
                  </Text>
                  {/* note: need unwrapped version of button if no tournament code b/c of Safari behavior */}
                  {sportId === 'lol' &&
                  shouldShowButton(
                    games,
                    idx,
                    settled,
                    homeTeamLineupExists,
                    awayTeamLineupExists,
                    sportId,
                    true,
                  ) ? (
                    getCode(game) ? (
                      <CopyToClipboard text={getCode(game)} onCopy={handleTournamentCode}>
                        <BaseButton ml={[0, 5]} mt={[4, 0]} variant="secondary">
                          copy tournament code
                        </BaseButton>
                      </CopyToClipboard>
                    ) : (
                      <BaseButton
                        ml={[0, 5]}
                        mt={[4, 0]}
                        variant="secondaryDisabled"
                        disabled={true}
                        style={{ cursor: 'default' }}
                      >
                        copy tournament code
                      </BaseButton>
                    )
                  ) : null}
                </Flex>
                <Flex alignItems="center" flexWrap="wrap" mt={[4, 4, 1, 0]}>
                  <Circle color={circleColor(Boolean(game.winner), settled, upcomingGame)} mr={2} />
                  <Text color="darkgray" mt={'0.2rem'}>
                    <h6>{gameStatus(Boolean(game.winner), settled, upcomingGame, sportId)}</h6>
                  </Text>
                  {game.map && game.map.name ? (
                    <>
                      <Text ml={5} color="darkgray" mt={'0.2rem'}>
                        <h6>{game.map.name}</h6>
                      </Text>

                      {/* <Box ml={4}>
                    <Map src={mapImage(game.map.name)} alt={game.map.name} />
                  </Box> */}
                    </>
                  ) : null}

                  {currentUserIsAdmin()
                  || (shouldShowButton(
                    games,
                    idx,
                    settled,
                    homeTeamLineupExists,
                    awayTeamLineupExists,
                    sportId,
                    false,
                  ) && currentUserCanReport)
                  ? true &&
                  (loading ? (
                    <LoadingSpinner />
                  ) : (
                    <Flex
                      justifyContent="space-between"
                      flexDirection="column"
                      mt={[4, 1, 1, 0]}
                    >
                      {sportId === 'lol' && (
                        <BaseButton
                          ml={[0, 5]}
                          mb={1}
                          variant={'primary'}
                          onClick={() => handleAutoReportClick(game.id)}
                        >
                          {autoReportFailed(game.id) ? 'Retry Auto-Report' : 'Auto-Report Game'}
                        </BaseButton>
                      )}
                      {(sportId !== 'lol' || autoReportFailed(game.id)) && (
                        <BaseButton
                          ml={[0, 5]}
                          variant={'primary'}
                          onClick={() => handleClick(idx)}
                        >
                          {sportId === 'lol' ? 'Report Game Manually' : 'Report Game'}
                        </BaseButton>
                      )}
                    </Flex>
                  ))
                : null}
            </Flex>
          </Flex>

              {game.winner && game.winner.team ? (
                <ResultsContainer flexWrap="wrap" mx={[0, 0, 0, -2]} p={1} mt={3}>
                  {[homeTeam, awayTeam].map((team, idx) => (
                    <Box width={[1, 1, 1, 1 / 2]} px={[0, 0, 0, 2]} mb={[4, 4, 4, 0]} key={idx}>
                      {team ? (
                        <>
                          <Text color={teamIsWinner(team.id, game) ? 'green' : 'lightgray'}>
                            <Flex justifyContent="space-between" py={4}>
                              <h4>{team.name}</h4>
                              <h4>
                                {teamIsWinner(team.id, game)
                                  ? game.winner && game.winner.score
                                    ? `${game.winner && game.winner.score} - ${game.loser &&
                                        game.loser.score}`
                                    : 'WIN'
                                  : game.loser && game.loser.score
                                  ? `${game.loser && game.loser.score} - ${game.winner &&
                                      game.winner.score}`
                                  : 'LOSS'}
                              </h4>
                            </Flex>
                          </Text>
                          {renderPlayersTable(team, game)}
                        </>
                      ) : null}
                    </Box>
                  ))}
                </ResultsContainer>
              ) : null}
            </GameContainer>
          ))}
        </AllGamesContainer>
      </Box>
    </div>
  )
}

export default MatchDetailGameResults
