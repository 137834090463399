import baseStyled, { CreateStyled } from '@emotion/styled'

interface IBreakpoints {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xxl: string
  [key: string]: string
}

interface IButtons {
  className: string
}

const Xs = '500px'
const Sm = '740px'
const Md = '900px'
const Lg = '1080px'
const Xl = '1200px'
const Xxl = '1540px'

export const Theme = {
  breakpoints: [Xs, Sm, Md, Lg, Xl, Xxl],
  colors: {
    primarynavy: '#033359',
    primaryblue: '#0F67C5',
    primarylightblue: '#7CD2F8',
    primarylightblueHighlight: '#7CD2F84D',
    secondarynavy: '#011A40',
    secondaryblue: '#1FB5F7',
    secondarylightblue: '#C8EBF9',
    darkgray: '#666666',
    darkmiddlegray: '#CCCCCC',
    middlegray: '#DDDDDD',
    lightgray: '#E5E5E5',
    inputgray: '#F3F3F3',
    backgroundgray: '#F3F3F3',
    orange: '#FD8237',
    sand: '#FEC22D',
    yellow: '#FEE255',
    yellowHighlight: '#FEE2554D',
    red: '#FD4854',
    green: '#27AE60',
    secondarygreen: '#4BD0AE',
    purple: '#C681FC',
    pink: '#FED1D2',
    black: '#000000',
    white: '#ffffff',
    transparent: 'transparent',
    discord: '#7289DA',
    steam: '#1b2838',
    babyBlue: '#78D1FA',
    teal: '#4BD0AE',
    twitch: '#503782',
    bronze: '#CB9473',
  },
  gradients: {
    primarynavyFade: 'linear-gradient(360deg, #011a40 0%, rgba(1, 26, 64, 0.27) 100%)',
  },
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256],
  fonts: {
    condensed: 'Helvetica Now, sans-serif',
    wide: 'Helvetica Now, sans-serif',
    body: 'Campton Book, sans-serif',
  },
  fontSizes: ['1rem', '1.25rem', '1.875rem'],
  bodyFontSizes: ['1rem', '1.125rem', '1.25rem'],
  responsiveFontSizes: ['0.875rem', '1rem', '1.25rem'],
  transitions: {
    fast: '0.2s ease-in-out',
    slow: '0.6s ease-in-out',
  },
  buttons: {
    primary: {
      color: 'primarynavy',
      bg: 'primarylightblue',
      padding: '12px 2rem 10px',
      border: '2px solid',
      borderColor: 'primarylightblue',
      ':hover': {
        bg: 'secondaryblue',
        borderColor: 'secondaryblue',
      },
      ':focus': {
        bg: 'secondaryblue',
        borderColor: 'secondaryblue',
      },
    },
    primaryDisabled: {
      color: 'white',
      bg: 'darkmiddlegray',
      padding: '12px 2rem 10px',
      border: '2px solid',
      borderColor: 'darkmiddlegray',
    },
    // note: needs a separate property for border color:
    secondary: {
      color: 'primarynavy',
      bg: 'transparent',
      padding: '12px 2rem 10px 2rem',
      border: '2px solid',
      borderColor: 'secondaryblue',
      ':hover': {
        borderColor: 'sand',
      },
      ':active': {
        borderColor: 'sand',
      },
    },
    secondaryLight: {
      color: 'white',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'primarylightblue',
      padding: '12px 2rem 10px',
      ':hover': {
        borderColor: 'secondaryblue',
      },
      ':active': {
        borderColor: 'primaryblue',
      },
    },
    secondaryDisabled: {
      color: 'darkmiddlegray',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'darkmiddlegray',
      padding: '12px 2rem 10px',
    },
    whiteSecondary: {
      color: 'white',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'white',
      padding: '12px 2rem 10px',
      ':hover': {
        bg: 'white',
        color: 'primarylightblue',
      },
    },
    select: {
      color: 'primarynavy',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'green',
      padding: '12px 15px 10px',
    },
    selected: {
      color: 'white',
      bg: 'green',
      border: '2px solid',
      borderColor: 'green',
      padding: '12px 15px 10px',
    },
    decline: {
      color: 'primarynavy',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'red',
      padding: '12px 2rem 10px',
    },
    declineSelected: {
      color: 'white',
      bg: 'red',
      border: '2px solid',
      borderColor: 'red',
      padding: '9px 0px 7.5px',
    },
    tertiary: {
      color: 'primarynavy',
      bg: 'sand',
      padding: '9.5px 15px',
    },
    tertiaryOutlined: {
      color: 'primarynavy',
      bg: 'white',
      border: '2px solid',
      borderColor: 'sand',
      padding: '9.5px 15px',
    },
    tertiaryOutlinedDisabled: {
      color: 'primarynavy',
      bg: 'white',
      border: '2px solid',
      borderColor: 'darkmiddlegray',
      padding: '9.5px 15px',
      transition: '0.2s ease-in-out',
      '&:hover': {
        borderColor: 'sand',
      },
    },
    discord: {
      color: 'primaryblue',
      bg: 'white',
      padding: '9px 20px 7.5px',
      ':hover': {
        bg: 'primarylightblue',
      },
    },
    discordDisabled: {
      color: 'darkmiddlegray',
      bg: 'white',
      padding: '9px 10px 7.5px',
      cursor: 'none',
      pointerEvents: 'none',
    },
    reportMatch: {
      color: 'white',
      bg: 'red',
      padding: '9px 0px 7.5px',
      width: '100%',
      ':hover': {
        opacity: '0.8',
      },
      ':focus': {
        bg: 'primarylightblue',
      },
    },
    wideNavy: {
      color: 'white',
      bg: 'primarynavy',
      padding: '9px 0px 7.5px',
      width: '100%',
      ':hover': {
        opacity: '0.8',
      },
      ':focus': {
        bg: 'primarylightblue',
      },
    },
    wideBlue: {
      color: 'white',
      bg: 'primaryblue',
      padding: '9px 0px 7.5px',
      width: '100%',
      ':hover': {
        opacity: '0.8',
      },
    },
    wideSand: {
      color: 'black',
      bg: 'sand',
      padding: '9px 0px 7.5px',
      width: '100%',
      ':hover': {
        opacity: '0.8',
      },
      ':focus': {
        bg: 'primarylightblue',
      },
    },
    wideRed: {
      color: 'white',
      bg: 'red',
      padding: '9px 0px 7.5px',
      width: '100%',
      ':hover': {
        opacity: '0.8',
      },
    },
    wideSecondary: {
      color: 'white',
      bg: 'primaryblue',
      padding: '9px 0px 7.5px',
      width: '100%',
      ':hover': {
        bg: 'primarylightblue',
      },
    },
    navy: {
      color: 'white',
      bg: 'primarynavy',
      padding: '12px 2rem 10px',
      ':hover': {
        opacity: '0.8',
      },
    },
  },
}

export const breakpoints: IBreakpoints = {
  xs: Xs,
  sm: Sm,
  md: Md,
  lg: Lg,
  xl: Xl,
  xxl: Xxl,
}

export const MediaQueries = Object.keys(breakpoints).reduce(
  (acc, breakKey) => {
    return {
      ...acc,
      [breakKey]: `@media (min-width: ${breakpoints[breakKey]})`,
    }
  },

  {} as IBreakpoints,
)

export type Themetype = typeof Theme
export type ThemeColors = keyof typeof Theme.colors
export const styled = baseStyled as CreateStyled<Themetype>
