import React, { useContext } from 'react'
import { FiCrosshair } from 'react-icons/fi'
import { Text, Flex, FlexProps } from 'rebass'
import { styled, Theme } from '../../../styles/settings/theme'
import { Link } from 'react-router-dom'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext'
import { paths } from '../../../utils/Routes'
import { BaseButton } from '../../atoms/Buttons'
import { SettingsBlock, Header, SettingsContent } from '../../atoms/SettingsPieces'

const StyledFlex = styled(Flex)<FlexProps>`
  margin: 3rem;
  padding: 2rem 2rem;
  border: 2px solid ${props => props.theme.colors.middlegray};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TeamSettingsEmptyState: React.FC = () => {
  const { universityId, pendingInvitations, pendingApplications, activeRosterEntries } = useContext(
    CurrentUserContext,
  )
  return !pendingInvitations.length &&
    !pendingApplications.length &&
    !activeRosterEntries.length ? (
    <SettingsBlock>
      <Header justifyContent="flex-start">
        <Text color="primarynavy">
          <h4>invites & applications</h4>
        </Text>
      </Header>
      <SettingsContent>
        <StyledFlex>
          <FiCrosshair color={Theme.colors.secondaryblue} size={24} />
          <Text color="darkgray" my={3}>
            <h6> You do not currently have any invites or applications</h6>
          </Text>
          <Text textAlign="center">
            If you would like to join other teams, feel free to browse teams at your school that are
            actively recruiting below.
          </Text>
          <Flex mt={4}>
            <Link to={paths.university(universityId)}>
              <BaseButton variant="secondary" mt={2} ml={2}>
                Browse teams
              </BaseButton>
            </Link>
          </Flex>
        </StyledFlex>
      </SettingsContent>
    </SettingsBlock>
  ) : null
}
export default TeamSettingsEmptyState
