import React from 'react'
import { Image } from 'rebass'
import { styled } from '../../styles/settings/theme'

interface LOLSpriteProps {
  championImageName: string
}

const StyledImage = styled(Image)`
  display: inline-block;
  vertical-align: baseline;
  clip-path: circle(50% at 50% 50%);
`
const version = process.env.REACT_APP_RIOT_API_VERSION

export const LOLSprite: React.FunctionComponent<LOLSpriteProps> = ({ championImageName }) => {
  return (
    <StyledImage
      alt={championImageName}
      src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championImageName}`}
    />
  )
}

export default LOLSprite
