import React from 'react'
import { Flex, FlexProps, Text } from 'rebass'
import { CheckBox } from '../atoms/FormPieces'
import { useTheme } from 'emotion-theming'

interface IMarketingEmailsOptInProps extends FlexProps {
  marketingEmailsCd: number
  setMarketingEmailsCd: (marketingEmailsCd: number) => void
}

const MarketingEmailsOptIn: React.FC<IMarketingEmailsOptInProps> = ({
  marketingEmailsCd,
  setMarketingEmailsCd,
  ...flexProps
}) => {
  const { colors } = useTheme()
  return (
    <Flex mt={flexProps.mt} mb={flexProps.mb} justifyContent="space-between">
      <Flex flexDirection="column" width="80%">
        <Text mb={5} color={colors.primarynavy}>
          {/* TODO: replace with correct copy */}
          <h5>
            Would you like to receive email updates on CSL matches, events, and other marketing
            initiatives?
          </h5>
        </Text>
        <Flex>
          <Flex>
            <CheckBox
              name="isInVarsityProgram"
              type="checkbox"
              checked={marketingEmailsCd === 1}
              onChange={() => setMarketingEmailsCd(1)}
            />
            <Text ml={3} color={colors.primarynavy}>
              <h5>Yes</h5>
            </Text>
          </Flex>
          <Flex ml={5}>
            <CheckBox
              name="isInVarsityProgram"
              type="checkbox"
              checked={marketingEmailsCd === 0}
              onChange={() => setMarketingEmailsCd(0)}
            />
            <Text ml={3} color={colors.primarynavy}>
              <h5>No</h5>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MarketingEmailsOptIn
