import { Flex, FlexProps } from 'rebass'
import { styled, MediaQueries as Mq } from '../../../styles/settings/theme'

export const Container = styled(Flex)<FlexProps>`
  max-width: 50rem;
  flex-direction: column;
  margin: 60px auto;
  min-height: calc(100vh - 201px);
  justify-content: center;
`
export const FormWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  padding: 80px 1rem;
  background: white;

  ${Mq.md} {
    padding: 80px 40px;
  }
`
