import React, { useState, ReactNode } from 'react'
import { Dictionary } from 'lodash'
import { FieldTypes } from '../components/molecules/InputFieldWithErrors'
import { FieldType, allFields, allFieldsBySport } from '../utils/gamingProfilesUtils'
import { isEmpty, compact } from 'lodash'
import { oc } from 'ts-optchain'

import { collegeSite } from '../utils/sites'
import { SetState } from '../types/aliases'
import {
  User,
  Sport,
  RosterEntry,
  CurrentUser_UniversityFragment as University,
  UniversityMajor,
  CurrentUser_PendingInvitationsFragment as PendingInvitation,
} from '../types/graphql'

interface ICurrentUserState {
  isConfirmed: boolean
  firstName: string
  lastName: string
  email: string
  discordUsername: string
  gender: string
  university: University
  universityId: string
  universityMajor: UniversityMajor
  universityMajorId: string
  graduationYear: string
  selectedSports: string[]
  existingSports: string[]
  isInvitedUser: boolean
  pendingInvitations: PendingInvitation[]
  acceptedInvitations: PendingInvitation[]
  declinedInvitations: PendingInvitation[]
  pendingApplications: RosterEntry[]
  activeRosterEntries: RosterEntry[]
  currentInvitation: PendingInvitation
  showUserAboutModal: boolean
  showGamingProfilesModal: boolean
  showAddGamingProfilesModal: boolean
  showSocialProfilesModal: boolean
  addressUnit: string
  streetAddress: string
  postalCode: string
  city: string
  province: string

  isInVarsityProgram: boolean
  varsityProgramUrl: string
  marketingEmailsCd: number
  currentUserFormErrors: Dictionary<string>
  fullName: string
  fieldTypes: Dictionary<string>
  requiredFields: Dictionary<string>
  sportFields: Dictionary<string>
  sportDropdownFieldOptions: Dictionary<string>
  paidForPremium: boolean
  twitchUsername: string
  facebookUsername: string
  twitterUsername: string

  setIsConfirmed: SetState<boolean>
  setFirstName: SetState<string>
  setLastName: SetState<string>
  setEmail: SetState<string>
  setDiscordUsername: SetState<string>
  setGender: SetState<string>
  setUniversity: SetState<University>
  setUniversityId: SetState<string>
  setAddressUnit: SetState<string>
  setStreetAddress: SetState<string>
  setPostalCode: SetState<string>
  setCity: SetState<string>
  setProvince: SetState<string>
  setUniversityMajor: SetState<UniversityMajor>
  setUniversityMajorId: SetState<string>
  setGraduationYear: SetState<string>
  setSelectedSports: SetState<string[]>
  setExistingSports: SetState<string[]>
  setIsInvitedUser: SetState<boolean>
  setPendingInvitations: SetState<PendingInvitation[]>
  setAcceptedInvitations: SetState<PendingInvitation[]>
  setDeclinedInvitations: SetState<PendingInvitation[]>
  setPendingApplications: SetState<RosterEntry[]>
  setActiveRosterEntries: SetState<RosterEntry[]>
  setCurrentInvitation: SetState<PendingInvitation>
  setIsInVarsityProgram: SetState<boolean>
  setVarsityProgramUrl: SetState<string>
  setMarketingEmailsCd: SetState<number>
  setCurrentUserFormErrors: SetState<Dictionary<string>>
  setSportFields: SetState<Dictionary<string>>
  setSportDropdownFieldOptions: SetState<Dictionary<string>>
  setPaidForPremium: SetState<boolean>
  setTwitchUsername: SetState<string>
  setFacebookUsername: SetState<string>
  setTwitterUsername: SetState<string>
  setShowUserAboutModal: SetState<boolean>
  setShowGamingProfilesModal: SetState<boolean>
  setShowAddGamingProfilesModal: SetState<boolean>
  setShowSocialProfilesModal: SetState<boolean>

  currentUserContextLoading: boolean
  errorsExist: boolean
  requiredFieldsErrorsExist: boolean
  setCurrentUserContextLoading: SetState<boolean>
  updateFromQuery: (user: User) => void
  updateErrors: (errors: Dictionary<string>) => void
  isFieldRequired: (field: string) => boolean
  sportClickHandler: (sport: string) => void
  removeSportErrors: (sport: string) => void
  removeSportFields: (sport: string) => void
  validateSportField: (value: string, field: FieldType) => void
  sportFieldError: (value: string, field: FieldType) => Dictionary<string>
  resetUserToStateFromQuery: () => void
}

interface IProps {
  children?: ReactNode
}

const defaultCurrentUserState: ICurrentUserState = {
  isConfirmed: false,
  firstName: '',
  lastName: '',
  email: '',
  discordUsername: '',
  gender: '',
  university: {} as University,
  universityId: '',
  universityMajor: {} as UniversityMajor,
  universityMajorId: '',
  graduationYear: '',
  selectedSports: [],
  existingSports: [],
  isInvitedUser: false,
  pendingInvitations: [],
  declinedInvitations: [],
  pendingApplications: [],
  activeRosterEntries: [],
  acceptedInvitations: [],
  currentInvitation: {} as PendingInvitation,
  isInVarsityProgram: false,
  varsityProgramUrl: '',
  marketingEmailsCd: 0,
  currentUserFormErrors: {},
  fullName: '',
  fieldTypes: {},
  requiredFields: {},
  sportFields: {},
  sportDropdownFieldOptions: {},
  paidForPremium: false,
  twitchUsername: '',
  facebookUsername: '',
  twitterUsername: '',
  showUserAboutModal: false,
  showGamingProfilesModal: false,
  showAddGamingProfilesModal: false,
  showSocialProfilesModal: false,
  postalCode: '',
  streetAddress: '',
  addressUnit: '',
  province: '',
  city: '',

  setIsConfirmed: (): void => {},
  setFirstName: (): void => {},
  setLastName: (): void => {},
  setEmail: (): void => {},
  setDiscordUsername: (): void => {},
  setGender: (): void => {},
  setUniversity: (): void => {},
  setUniversityId: (): void => {},
  setUniversityMajor: (): void => {},
  setUniversityMajorId: (): void => {},
  setGraduationYear: (): void => {},
  setSelectedSports: (): void => {},
  setExistingSports: (): void => {},
  setIsInvitedUser: (): void => {},
  setPendingInvitations: (): void => {},
  setAcceptedInvitations: (): void => {},
  setDeclinedInvitations: (): void => {},
  setPendingApplications: (): void => {},
  setActiveRosterEntries: (): void => {},
  setCurrentInvitation: (): void => {},
  setIsInVarsityProgram: (): void => {},
  setVarsityProgramUrl: (): void => {},
  setMarketingEmailsCd: (): void => {},
  setCurrentUserFormErrors: (): void => {},
  setSportFields: (): void => {},
  setSportDropdownFieldOptions: (): void => {},
  sportClickHandler: (): void => {},
  removeSportErrors: (): void => {},
  removeSportFields: (): void => {},
  setPaidForPremium: (): void => {},
  setTwitchUsername: (): void => {},
  setFacebookUsername: (): void => {},
  setTwitterUsername: (): void => {},
  setShowUserAboutModal: (): void => {},
  setShowGamingProfilesModal: (): void => {},
  setShowAddGamingProfilesModal: (): void => {},
  setShowSocialProfilesModal: (): void => {},
  setAddressUnit: (): void => {},
  setStreetAddress: (): void => {},
  setPostalCode: (): void => {},
  setCity: (): void => {},
  setProvince: (): void => {},
  currentUserContextLoading: false,
  errorsExist: false,
  requiredFieldsErrorsExist: false,
  setCurrentUserContextLoading: (): void => {},
  updateFromQuery: (): void => {},
  updateErrors: (): void => {},
  isFieldRequired: (): boolean => false,
  validateSportField: (): void => {},
  sportFieldError: (): Dictionary<string> => ({}),
  resetUserToStateFromQuery: (): void => {},
}

export const CurrentUserContext = React.createContext<ICurrentUserState>(defaultCurrentUserState)

export const CurrentUserProvider = ({ children }: IProps) => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isInvitedUser, setIsInvitedUser] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [discordUsername, setDiscordUsername] = useState('')
  const [gender, setGender] = useState('')
  const [university, setUniversity] = useState({} as University)
  const [universityId, setUniversityId] = useState('')
  const [universityMajor, setUniversityMajor] = useState({} as UniversityMajor)
  const [universityMajorId, setUniversityMajorId] = useState('')
  const [graduationYear, setGraduationYear] = useState('')
  const [paidForPremium, setPaidForPremium] = useState(false)
  const [twitchUsername, setTwitchUsername] = useState('')
  const [facebookUsername, setFacebookUsername] = useState('')
  const [twitterUsername, setTwitterUsername] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [addressUnit, setAddressUnit] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')

  const [selectedSports, setSelectedSports] = useState<string[]>([])
  const [existingSports, setExistingSports] = useState<string[]>([])
  const [sportFields, setSportFields] = useState<Dictionary<string>>({})
  const [sportDropdownFieldOptions, setSportDropdownFieldOptions] = useState<Dictionary<string>>({})

  const [pendingInvitations, setPendingInvitations] = useState<PendingInvitation[]>([])
  const [acceptedInvitations, setAcceptedInvitations] = useState<PendingInvitation[]>([])
  const [declinedInvitations, setDeclinedInvitations] = useState<PendingInvitation[]>([])
  const [pendingApplications, setPendingApplications] = useState<RosterEntry[]>([])
  const [activeRosterEntries, setActiveRosterEntries] = useState<RosterEntry[]>([])
  const [currentInvitation, setCurrentInvitation] = useState<PendingInvitation>(
    {} as PendingInvitation,
  )

  const [isInVarsityProgram, setIsInVarsityProgram] = useState(false)
  const [varsityProgramUrl, setVarsityProgramUrl] = useState('')
  const [marketingEmailsCd, setMarketingEmailsCd] = useState(0)

  const [currentUserContextLoading, setCurrentUserContextLoading] = useState(false)
  const [currentUserFormErrors, setCurrentUserFormErrors] = useState<Dictionary<string>>({})

  const [userStateFromQuery, setUserStateFromQuery] = useState<User>({} as User)
  const [showUserAboutModal, setShowUserAboutModal] = useState(false)
  const [showGamingProfilesModal, setShowGamingProfilesModal] = useState(false)
  const [showAddGamingProfilesModal, setShowAddGamingProfilesModal] = useState(false)
  const [showSocialProfilesModal, setShowSocialProfilesModal] = useState(false)

  const updateFromQuery = (user: User) => {
    setUserStateFromQuery(user)
    setIsConfirmed(user.isConfirmed)
    setEmail(user.email)
    setUniversityId(user.university.id)
    setUniversity(user.university)

    if (user.universityMajor) {
      setUniversityMajorId(user.universityMajor.id)
      setUniversityMajor(user.universityMajor)
    }

    if (user.graduationYear) {
      setGraduationYear(user.graduationYear.toString())
    }

    if (user.name) {
      setFirstName(user.name.split(' ')[0])
      setLastName(user.name.split(' ')[1])
    }

    setGender(oc(user).gender(''))
    setDiscordUsername(oc(user).discordUsername(''))
    setTwitchUsername(oc(user).twitchUsername(''))
    setTwitterUsername(oc(user).twitterUsername(''))
    setFacebookUsername(oc(user).facebookUsername(''))

    if (user.playedSports) {
      const playedSports = compact(user.playedSports.map((sport: Sport) => sport.slug))

      if (!isEmpty(playedSports)) {
        setExistingSports(playedSports)
        setSportFields(
          Object.assign(
            {},
            sportFields,
            playedSports.reduce((fields: Dictionary<string>, sport: string) => {
              allFieldsBySport[sport].forEach((field: FieldType) => {
                if (user[field.name]) {
                  fields[field.name] = user[field.name]
                }
              })
              return fields
            }, {}),
          ),
        )
      }
    }
  }

  const resetUserToStateFromQuery = () => {
    setCurrentUserFormErrors({})
    updateFromQuery(userStateFromQuery)
  }

  const updateErrors = (errors: Dictionary<string>) =>
    setCurrentUserFormErrors({ ...currentUserFormErrors, ...errors })

  const removeSportErrors = (sport: string) => {
    const errors = allFieldsBySport[sport].reduce(
      (errors: Dictionary<string>, field: FieldType) => {
        errors[field.name] = ''
        return errors
      },
      {},
    )
    setCurrentUserFormErrors({ ...currentUserFormErrors, ...errors })
  }

  const removeSportFields = (sport: string) => {
    const removedSportFields = allFieldsBySport[sport].map((field: FieldType) => field.name)
    const fields = Object.keys(sportFields).reduce((fields: Dictionary<string>, field: string) => {
      if (!removedSportFields.includes(field)) {
        fields[field] = sportFields[field]
      }
      return fields
    }, {})
    setSportFields(fields)
  }

  const sportClickHandler = (clickedSport: string) => {
    let updatedSelectedSports

    if (selectedSports.includes(clickedSport)) {
      updatedSelectedSports = selectedSports.filter(currentSport => currentSport !== clickedSport)
      removeSportErrors(clickedSport)
      removeSportFields(clickedSport)

      setAcceptedInvitations(
        acceptedInvitations.filter(invitation => invitation.team.sportSlug !== clickedSport),
      )
    } else {
      updatedSelectedSports = [...selectedSports, clickedSport]
    }
    setSelectedSports(updatedSelectedSports)
  }

  const validateSportField = (value: string, field: FieldType) => {
    if (field.required) {
      const error = sportFieldError(value, field)
      updateErrors(error)
    }
  }

  const sportFieldError = (value: string, field: FieldType) => {
    const errors = { [field.name]: '' }
    if (field.exclusiveOf) {
      const exclusiveOfField: FieldType = allFields.find(
        (exField: FieldType) => exField.name === field.exclusiveOf,
      )
      const bothExistError = `You cannot have both ${field.label} and ${exclusiveOfField.label}.`
      const neitherExistError = `${field.label} or ${exclusiveOfField.label} required.`
      if (value && sportFields[field.exclusiveOf]) {
        errors[field.exclusiveOf] = bothExistError
        errors[field.name] = bothExistError
      } else if (!value && !sportFields[field.exclusiveOf]) {
        errors[field.exclusiveOf] = neitherExistError
        errors[field.name] = neitherExistError
      } else {
        errors[field.exclusiveOf] = ''
        errors[field.name] = ''
      }
    } else if (!value) {
      errors[field.name] = `${field.label} is required.`
    }
    return errors
  }

  const fullName = `${firstName} ${lastName}`

  const basedRequiredFields = {
    firstName,
    lastName,
    discordUsername,
  }

  const requiredFields = collegeSite
    ? { ...basedRequiredFields, universityId, universityMajorId, email, graduationYear }
    : basedRequiredFields

  const fieldTypes = {
    universityId: FieldTypes.Typeahead,
    universityMajorId: FieldTypes.Typeahead,
    graduationYear: FieldTypes.Select,
    gender: FieldTypes.Select,
    province: FieldTypes.Select,
  }

  const isFieldRequired = (field: string) => !!Object.keys(requiredFields).includes(field)

  const requiredFieldsErrorsExist = !isEmpty(
    Object.keys(requiredFields).filter((field: string) => !!currentUserFormErrors[field]),
  )

  const errorsExist = !isEmpty(
    Object.values(currentUserFormErrors).filter((error: string) => !!error),
  )

  return (
    <CurrentUserContext.Provider
      value={{
        isConfirmed,
        firstName,
        lastName,
        email,
        discordUsername,
        gender,
        university,
        universityMajor,
        graduationYear,
        selectedSports,
        existingSports,
        isInvitedUser,
        pendingInvitations,
        acceptedInvitations,
        declinedInvitations,
        pendingApplications,
        activeRosterEntries,
        currentInvitation,
        isInVarsityProgram,
        varsityProgramUrl,
        marketingEmailsCd,
        universityId,
        universityMajorId,
        currentUserFormErrors,
        fullName,
        fieldTypes,
        requiredFields,
        sportFields,
        sportDropdownFieldOptions,
        paidForPremium,
        twitchUsername,
        facebookUsername,
        twitterUsername,
        showUserAboutModal,
        showGamingProfilesModal,
        showAddGamingProfilesModal,
        showSocialProfilesModal,

        setIsConfirmed,
        setFirstName,
        setLastName,
        setEmail,
        setDiscordUsername,
        setGender,
        setUniversity,
        setUniversityMajor,
        setGraduationYear,
        setSelectedSports,
        setExistingSports,
        setIsInvitedUser,
        setPendingInvitations,
        setAcceptedInvitations,
        setDeclinedInvitations,
        setPendingApplications,
        setActiveRosterEntries,
        setCurrentInvitation,
        setIsInVarsityProgram,
        setVarsityProgramUrl,
        setMarketingEmailsCd,
        setUniversityId,
        setUniversityMajorId,
        setCurrentUserFormErrors,
        setSportFields,
        setSportDropdownFieldOptions,
        sportClickHandler,
        removeSportErrors,
        removeSportFields,
        setPaidForPremium,
        setTwitchUsername,
        setFacebookUsername,
        setTwitterUsername,
        setShowUserAboutModal,
        setShowGamingProfilesModal,
        setShowAddGamingProfilesModal,
        setShowSocialProfilesModal,
        setStreetAddress,
        setCity,
        setPostalCode,
        setProvince,
        setAddressUnit,
        addressUnit,
        city,
        province,
        streetAddress,
        postalCode,

        currentUserContextLoading,
        errorsExist,
        requiredFieldsErrorsExist,
        setCurrentUserContextLoading,
        updateFromQuery,
        updateErrors,
        isFieldRequired,
        validateSportField,
        sportFieldError,
        resetUserToStateFromQuery,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}
