import NewsImage from '../../../assets/images/news__news.jpg'
import GameNewsImage from '../../../assets/images/news__game-news.jpg'
import FeaturesImage from '../../../assets/images/news__features.jpg'
import TournamentsImage from '../../../assets/images/news__tournaments.jpg'
import { PostCategories } from '../../../types/graphql'

export function filterImage(newsCategory: PostCategories) {
  const mapping = {
    [PostCategories.CslNews]: NewsImage,
    [PostCategories.Games]: GameNewsImage,
    [PostCategories.Features]: FeaturesImage,
    [PostCategories.Tournaments]: TournamentsImage,
  }

  return mapping[newsCategory] || ''
}
