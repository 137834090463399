import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React, { useContext } from 'react'
import { FiXCircle } from 'react-icons/fi'
import { useModal } from 'react-modal-hook'
import { toast } from 'react-toastify'
import { Box, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { paths } from '../../utils/Routes'

import { TournamentContext } from '../../contexts/TournamentContext'
import { AdminTournamentInfo, AdminTournamentTeamInfo } from '../../types/fragments'
import {
  AdminTournamentTeamInfoFragment as Team,
  EditTournamentTeamsMutation,
  TeamTournamentRegistryMap,
  useEditTournamentTeamsMutation,
} from '../../types/graphql'
import AdminSafetySwitch from '../atoms/AdminSafetySwitch'
import { BaseButton } from '../atoms/Buttons'
import ModalContainer from '../atoms/ModalContainer'
import { CustomSelect } from '../atoms/Select'
import Table from '../atoms/Table'
import AdminTournamentTeamsModal from '../organisms/AdminTournamentTeamsModal'
import { TeamCheckinToggle } from './AdminTournamentTeams/TeamCheckinToggle'
import DataFetcher from './DataFetcher'

gql`
  mutation EditTournamentTeams(
    $tournamentId: ID!
    $teamRegistriesMap: [TeamTournamentRegistryMap!]!
  ) {
    editTournamentTeams(tournamentId: $tournamentId, teamRegistriesMap: $teamRegistriesMap) {
      value {
        ...AdminTournamentInfo
        adminTournamentTeams {
          ...AdminTournamentTeamInfo
        }
      }
    }
  }
  ${AdminTournamentInfo}
  ${AdminTournamentTeamInfo}
`

const AdminTeams: React.FC = () => {
  const { colors } = useTheme()
  const {
    checkedInTeamIds,
    updateFromQuery,
    teams,
    tournamentId,
    registries,
    setRegistries,
    setTeams,
  } = useContext(TournamentContext)

  const [editTournamentTeams, { error, loading }] = useEditTournamentTeamsMutation()
  const handleSubmit = async () => {
    try {
      let variables = {
        tournamentId: tournamentId,
        teamRegistriesMap: getMutationRegistries(),
      }

      const { data } = await editTournamentTeams({ variables })
      handleMutationComplete(data)
    } catch (err) {
      handleErrors(err.errors)
    }
  }

  const handleErrors = (errors: _.Dictionary<string>) => {
    toast.error('An unforseen error has occured. Please contact Paul.', {
      containerId: 'temporary',
    })
  }

  const handleMutationComplete = (data?: EditTournamentTeamsMutation) => {
    if (data && data.editTournamentTeams && data.editTournamentTeams.value) {
      updateFromQuery(data.editTournamentTeams.value)
      toast.success('Your tournament was updated.', { containerId: 'temporary' })
    }
  }

  const [showTeamModal, hideTeamModal] = useModal(
    () => (
      <ModalContainer hideModal={hideTeamModal}>
        <AdminTournamentTeamsModal tournamentId={tournamentId} />
      </ModalContainer>
    ),
    [tournamentId],
  )

  let registriesMap = {}

  registries &&
    registries.forEach(registry => {
      if (registry.team) {
        registriesMap[registry.team.id] = registry.seed
      }
    })

  const getMutationRegistries = () => {
    return registries
      ? registries.reduce<TeamTournamentRegistryMap[]>((acc, r) => {
          r.team && acc.push({ teamId: r.team.id, seed: r.seed })
          return acc
        }, [])
      : []
  }

  //TODO: handle editing existing tournament that may already be seeded and have checked-ins

  const handleSeed = (event: React.ChangeEvent<HTMLSelectElement>, teamId: string) => {
    const seed = +event.target.value
    const _registries = [...registries]
    if (_registries) {
      _registries.forEach(reg => {
        if (reg.team && reg.team.id === teamId) reg.seed = seed
      })
      setRegistries(_registries)
    }
  }
  //todo: make this work?
  const handleRemove = (idToRemove: string) => {
    if (teams) {
      const _teams = teams.filter(t => t.id !== idToRemove)
      setTeams(_teams)
    }
    if (registries) {
      const _registries = registries.filter(t => t.team && t.team.id !== idToRemove)
      setRegistries(_registries)
    }
  }

  const isTeamCheckedIn = (teamId: string) => {
    return checkedInTeamIds ? checkedInTeamIds.indexOf(teamId) > -1 : false
  }

  return (
    <DataFetcher error={error} loading={loading}>
      <Box>
        <Text mb={4}>
          <h3>Edit Team Registrations</h3>
        </Text>
        <Text mb={6}>
          <p>See and manage registered teams.</p>
        </Text>
        <BaseButton variant="primary" mb={7} onClick={() => showTeamModal()}>
          add team
        </BaseButton>
        <Text mb={3}>
          <h3>{teams && teams.length} Registered teams</h3>
        </Text>
        <Table headerBackgroundColor="middlegray" bodyBackgroundColor="white">
          <colgroup>
            <col span={1} style={{ width: '10%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '10%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '10%' }} />
            <col span={1} style={{ width: '10%' }} />
          </colgroup>

          <thead>
            <tr>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>seed</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>team</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>players</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>university</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>locale</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>checked in</h6>
                </Text>
              </th>
              <th>
                <Text color={colors.primarynavy}>
                  <h6>remove</h6>
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {teams &&
              teams.map((team: Team, i: number) => (
                <tr key={i}>
                  <td>
                    {/* Leaving this in as seeding will be part of 9/12 */}
                    <CustomSelect
                      key={i}
                      onChange={e => handleSeed(e, team.id)}
                      value={registriesMap[team.id]}
                      name="seed"
                    >
                      <option key={i}>-</option>
                      {[...Array(+teams.length.toString())].map((el, i: number) => (
                        <option key={i}>{i + 1}</option>
                      ))}
                    </CustomSelect>
                  </td>

                  <td>
                    <Link to={paths.teamDetail(team.id)}>
                      <Text color={colors.primarynavy}>
                        <h6>{team.name}</h6>
                      </Text>
                    </Link>
                  </td>
                  <td>
                    <Text color={colors.primarynavy}>
                      <h6>{team.activePlayersCount}</h6>
                    </Text>
                  </td>
                  <td>
                    <Text color={colors.darkgray}>
                      <h6>{team.university && team.university.abbreviation}</h6>
                    </Text>
                  </td>
                  <td>
                    <Text color={colors.darkgray}>
                      <h6>{team.university && team.university.state}</h6>
                    </Text>
                  </td>
                  <td>
                    <Box color={colors.darkgray} pl={[2, 2, 2, 4]}>
                      <TeamCheckinToggle
                        teamId={team.id}
                        tournamentId={tournamentId}
                        alreadyCheckedIn={isTeamCheckedIn(team.id)}
                        teamName={team.name}
                      />
                    </Box>
                  </td>

                  <td>
                    <Box mr={4}>
                      <FiXCircle color={'red'} onClick={() => handleRemove(team.id)} />
                    </Box>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <AdminSafetySwitch onClick={handleSubmit} thisPropTriggersAReRender={Math.random()}>
          save teams
        </AdminSafetySwitch>
      </Box>
    </DataFetcher>
  )
}

export default AdminTeams
