import React, { useState, useEffect } from 'react'
import { Flex, Box, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'

import { paths } from '../../../utils/Routes'
import { adjustedFullWithShortMonthAndTime } from '../../../utils/dateTimeFormats'
import Table from '../../atoms/Table'
import WeeklyScheduleToggle from '../../molecules/WeeklyScheduleToggle'
import { useSeasonSchedulesModalLazyQuery as useSeasonSchedule } from '../../../types/graphql'
import DataFetcher from '../../organisms/DataFetcher'
import { isTeamWinner, teamScore } from '../../../utils/matchUtils'

gql`
  fragment SeasonScheduleModalGames on Game {
    id
    winner {
      id
      team {
        id
      }
    }
  }
`
gql`
  fragment SeasonScheduleModalMatch on Match {
    id
    settledMemo
    scheduledDate
    teamEnrollmentOne {
      id
      team {
        id
        name
      }
    }
    teamEnrollmentTwo {
      id
      team {
        id
        name
      }
    }
    games {
      ...SeasonScheduleModalGames
    }
  }
`

gql`
  query SeasonSchedulesModal($id: ID!, $weekNumber: Int!) {
    season(id: $id) {
      id
      roundIntervals {
        scheduledDate
        id
      }
      scheduleForWeek(weekNumber: $weekNumber) {
        id
        matches {
          ...SeasonScheduleModalMatch
        }
      }
    }
  }
`

interface ISchedulesModalProps {
  leagueId: string
}

const LeagueScheduleModal: React.FC<ISchedulesModalProps> = ({ leagueId }) => {
  const { colors } = useTheme()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [leagueQuery, { error, loading, data }] = useSeasonSchedule({
    variables: { id: leagueId, weekNumber: currentIndex },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (!leagueId) {
      return
    }
    leagueQuery({ variables: { id: leagueId, weekNumber: currentIndex } })
  }, [leagueId, currentIndex, leagueQuery])

  const season = data ? data.season : null
  const roundIntervals =
    season && season.roundIntervals
      ? season.roundIntervals.sort(function compare(a, b) {
          const dateA = a && +new Date(a.scheduledDate)
          const dateB = +new Date(b.scheduledDate)
          return dateA - dateB
        })
      : []
  const currentRoundInterval = season ? season.scheduleForWeek : null

  const numberOfWeeks =
    data && data.season && data.season.roundIntervals ? data.season.roundIntervals.length : 0

  const currentWeekMatches = currentRoundInterval ? currentRoundInterval.matches : null

  const currentWeeksInterval = currentRoundInterval
    ? roundIntervals.map(ri => ri.id).indexOf(currentRoundInterval.id)
    : currentIndex
  // currentWeeksInterval !== currentIndex && setCurrentIndex(currentWeeksInterval)

  return (
    <DataFetcher loading={loading} error={error}>
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <Box>
          <h2>Week {currentWeeksInterval + 1} Schedule</h2>
        </Box>
        <Box>
          {data && (
            <WeeklyScheduleToggle
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              numberOfWeeks={numberOfWeeks}
            />
          )}
        </Box>
      </Flex>
      <Table headerBackgroundColor="middlegray" bodyBorder={true}>
        <thead>
          <tr>
            <th>
              <h6>Date</h6>
            </th>
            <th style={{ textAlign: 'center' }}>
              <h6>Matchup</h6>
            </th>
            <th>
              <h6>Results</h6>
            </th>
          </tr>
        </thead>

        <tbody>
          {currentWeekMatches &&
            currentWeekMatches.map(match => (
              <tr key={1}>
                <td>
                  <Text color={colors.primarynavy}>
                    <h6>{adjustedFullWithShortMonthAndTime(match.scheduledDate)}</h6>
                  </Text>
                </td>
                <td>
                  <Flex alignItems="center" justifyContent="center">
                    <Box>
                      <Text color={colors.primarynavy}>
                        <Link
                          style={
                            match.games &&
                            match.teamEnrollmentOne &&
                            match.teamEnrollmentOne.team &&
                            isTeamWinner(
                              match.games,
                              !!match.settledMemo,
                              match.teamEnrollmentOne.team.id,
                            )
                              ? { color: colors.green }
                              : { color: colors.primarynavy }
                          }
                          to={{
                            pathname: paths.matchDetail(match.id),
                            state: {
                              authorized: true,
                              from: window.location.pathname,
                            },
                          }}
                        >
                          <h6>
                            {match.teamEnrollmentOne && match.teamEnrollmentOne.team
                              ? match.teamEnrollmentOne.team.name
                              : 'Bye'}
                          </h6>
                        </Link>
                      </Text>
                    </Box>
                    <Box mx={4}>
                      <Text color={colors.darkgray}>
                        <h6>VS</h6>
                      </Text>
                    </Box>
                    <Box>
                      <Text color={colors.primarynavy}>
                        <Link
                          style={
                            match.games &&
                            match.teamEnrollmentTwo &&
                            match.teamEnrollmentTwo.team &&
                            isTeamWinner(
                              match.games,
                              !!match.settledMemo,
                              match.teamEnrollmentTwo.team.id,
                            )
                              ? { color: colors.green }
                              : { color: colors.primarynavy }
                          }
                          to={{
                            pathname: paths.matchDetail(match.id),
                            state: {
                              authorized: true,
                              from: window.location.pathname,
                            },
                          }}
                        >
                          <h6>
                            {match.teamEnrollmentTwo && match.teamEnrollmentTwo.team
                              ? match.teamEnrollmentTwo.team.name
                              : 'Bye'}
                          </h6>
                        </Link>
                      </Text>
                    </Box>
                  </Flex>
                </td>
                <td>
                  <Text color={colors.primarynavy}>
                    <h6>
                      {match.games &&
                      match.teamEnrollmentTwo &&
                      match.teamEnrollmentTwo.team &&
                      match.teamEnrollmentOne &&
                      match.teamEnrollmentOne.team &&
                      match.settledMemo
                        ? teamScore(match.teamEnrollmentOne.team.id, match.games) +
                          '-' +
                          teamScore(match.teamEnrollmentTwo.team.id, match.games)
                        : 'TBD'}
                    </h6>
                  </Text>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </DataFetcher>
  )
}

export default LeagueScheduleModal
