import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { handleErrors, updateUserAuth } from '../../utils/formUtils'
import { paths, useQuery } from '../../utils/Routes'
import { AuthenticationAttributes } from '../../types/fragments'
import ErrorBox from './ErrorBox'
import DataFetcher from './DataFetcher'
import { useOauthSignInMutation, OAuthProviderEnum } from '../../types/graphql'
import { stringToEnum } from '../../utils/typeCoercions'

gql`
  mutation oauthSignIn($code: String!, $provider: OAuthProviderEnum!, $state: String!) {
    oauthSignIn(code: $code, provider: $provider, state: $state) {
      ...AuthenticationAttributes
    }
  }
  ${AuthenticationAttributes}
`

interface IRouteParams {
  provider: string
}

interface IQueryParams {
  code: string
  state: string
}

const OAuthCallback: React.FC = () => {
  const { code, state } = useQuery<IQueryParams>()
  const { provider } = useParams<IRouteParams>()
  const providerEnum = stringToEnum<OAuthProviderEnum>(OAuthProviderEnum, provider)!

  const [oauthLogin, { loading, error }] = useOauthSignInMutation({
    onError(error) {
      toast.error(handleErrors(error), { containerId: 'temporary' })
    },
    onCompleted(response) {
      const { oauthSignIn } = response
      updateUserAuth(oauthSignIn)

      if (oauthSignIn.isIncompleteAccount) {
        window.location.href = paths.completeProfile()
      } else {
        window.location.href = paths.myUniversity()
      }
    },
  })

  useEffect(() => {
    if (providerEnum) oauthLogin({ variables: { code, state, provider: providerEnum } })
  }, [oauthLogin, code, state, providerEnum])

  return (
    <>
      {loading && <DataFetcher loading={loading} />}
      {(error || !providerEnum) && (
        <ErrorBox title="Error logging you in" linkTo={paths.logIn()} linkText="Back to sign in">
          <p>
            We encountered an issue logging you in via 3rd party authentication. Please navigate
            back to the sign-in page and try again. If you are still experiencing issues please{' '}
            <a href="mailto:support@cslesports.gg">contact us</a>
          </p>
        </ErrorBox>
      )}
    </>
  )
}

export default OAuthCallback
