import React from 'react'
import { Flex } from 'rebass'
import { MatchBox, Round, LowerLine } from '../atoms/BracketPieces'
import { BracketText } from '../atoms/BracketText'
import { MatchNumber } from '../atoms/MatchNumber'

interface RoundProps {
  shift?: string
  bottomHalf?: boolean
  numTeams: number
  seatList: Array<number>
}

//only used in bracket size 128
const Round6Losers: React.FunctionComponent<RoundProps> = ({
  shift,
  bottomHalf,
  numTeams,
  seatList,
}) => {
  const halfSeats = !bottomHalf ? seatList[0] : seatList[1]
  return (
    <Round round="6" shift={shift} top={bottomHalf ? '0px' : '1275px'}>
      <Flex>
        <MatchBox round="6" numTeams={numTeams}>
          <MatchNumber seat={halfSeats} />
          <BracketText seat={halfSeats} homeTeam={true} />
          <BracketText seat={halfSeats} />
        </MatchBox>
        <LowerLine round="6" bottomHalf={bottomHalf} />
      </Flex>
    </Round>
  )
}

export default Round6Losers
