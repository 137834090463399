import { Text } from 'rebass'
import { styled } from '../../styles/settings/theme'

const AsciiEmoji = styled(Text)`
  font-family: 'Campton Book, sans-serif';
  font-weight: bold;
  font-size: ${props => props.theme.bodyFontSizes[1]};
  letter-spacing: 0.1em;
  color: ${props => props.theme.colors.sand};
`

export default AsciiEmoji
