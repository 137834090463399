import _ from 'lodash'
import React, { useState } from 'react'
import { getSeasonYears } from '../components/organisms/AdminSeasonInfo/utils'
import {
  SeasonRegions,
  SeasonSchedules,
  SeasonAdminQuery,
  AdminSeasonTeamInfoFragment as Team,
  AdminSeasonConferencesFragment as Conference,
} from '../types/graphql'
import { SetState } from '../types/aliases'

interface ISeasonAdminState {
  bannerName: string | undefined
  bannerUrl: string | undefined
  createConference: (name: string) => Conference
  conferences: Conference[]
  defaultSeriesLength: number
  divisionName?: string
  eligibleTeams: Team[]
  firstMatchDate: Date
  isEditable: boolean
  isPremiere: boolean
  isPremium: boolean
  isPublished: boolean
  maxTeamSize: number
  minTeamSize: number
  name: string
  price: number
  regCloseDate: Date
  regionChoice: SeasonRegions
  regOpenDate: Date
  rosterLockDate?: Date | undefined
  scheduleTypeChoice: SeasonSchedules
  seasonEndDate: Date
  seasonId: string
  seasonYear: string
  selectedSport: string
  setBannerName: SetState<string | undefined>
  setBannerUrl: SetState<string | undefined>
  setConferences: SetState<Conference[]>
  setDefaultSeriesLength: SetState<number>
  setDivisionName: SetState<string>
  setEligibleTeams: SetState<Team[]>
  setFirstMatchDate: SetState<Date>
  setIsEditable: SetState<boolean>
  setIsPremiere: SetState<boolean>
  setIsPremium: SetState<boolean>
  setIsPublished: SetState<boolean>
  setMaxTeamSize: SetState<number>
  setMinTeamSize: SetState<number>
  setName: SetState<string>
  setPrice: SetState<number>
  setRegCloseDate: SetState<Date>
  setRegionChoice: SetState<SeasonRegions>
  setRegOpenDate: SetState<Date>
  setRosterLockDate: SetState<Date | undefined>
  setScheduleTypeChoice: SetState<SeasonSchedules>
  setSeasonEndDate: SetState<Date>
  setSeasonId: SetState<string>
  setSeasonYear: SetState<string>
  setSelectedSport: SetState<string>
  setSponsoredImageName: SetState<string | undefined>
  setSponsoredImageUrl: SetState<string | undefined>
  setTeams: SetState<Team[]>
  sponsoredImageName: string | undefined
  sponsoredImageUrl: string | undefined
  teams: Team[]
  //utils
  updateFromQuery: (data: SeasonAdminQuery) => void
  dropTeam: (team: Team) => void
  unassignTeam: (team: Team) => void
  updateTeamConference: (team: Team, conf: any) => void
  getConferenceNameFromTeam: (team: Team) => string
}

interface IProps {
  children?: any
}

const defaultSeasonAdminState: ISeasonAdminState = {
  bannerName: '',
  bannerUrl: '',
  conferences: [],
  createConference: (name: string) => {
    const newConf: Conference = {
      __typename: 'Conference',
      name: name,
      teams: [],
    }
    return newConf
  },
  defaultSeriesLength: 3,
  divisionName: '',
  firstMatchDate: new Date(),
  isEditable: true,
  isPremiere: false,
  isPremium: false,
  isPublished: false,
  maxTeamSize: 0,
  minTeamSize: 0,
  name: '',
  price: 0.0,
  regCloseDate: new Date(),
  regionChoice: SeasonRegions.NorthAmerica,
  regOpenDate: new Date(),
  rosterLockDate: undefined,
  scheduleTypeChoice: SeasonSchedules.RoundRobin,
  seasonEndDate: new Date(),
  seasonId: '',
  seasonYear: '',
  selectedSport: '',
  setBannerName: (): void => {},
  setBannerUrl: (): void => {},
  setConferences: (): void => {},
  setDefaultSeriesLength: (): void => {},
  setDivisionName: (): void => {},
  setFirstMatchDate: (): void => {},
  setIsEditable: (): void => {},
  setIsPremiere: (): void => {},
  setIsPremium: (): void => {},
  setIsPublished: (): void => {},
  setMaxTeamSize: (): void => {},
  setMinTeamSize: (): void => {},
  setName: (): void => {},
  setPrice: (): void => {},
  setRegCloseDate: (): void => {},
  setRegionChoice: (): void => {},
  setRegOpenDate: (): void => {},
  setRosterLockDate: (): void => {},
  setScheduleTypeChoice: (): void => {},
  setSeasonEndDate: (): void => {},
  setSeasonId: (): void => {},
  setSeasonYear: (): void => {},
  setSelectedSport: (): void => {},
  setSponsoredImageName: (): void => {},
  setSponsoredImageUrl: (): void => {},
  setTeams: (): void => {},
  sponsoredImageName: '',
  sponsoredImageUrl: '',
  teams: [],
  updateFromQuery: (): void => {},
  dropTeam: (): void => {},
  getConferenceNameFromTeam: (_team: Team) => '',
  updateTeamConference: (): void => {},
  eligibleTeams: [],
  setEligibleTeams: (): void => {},
  unassignTeam: (): void => {},
}
export const SeasonAdminProvider = ({ children }: IProps) => {
  const [bannerName, setBannerName] = useState<string>()
  const [bannerUrl, setBannerUrl] = useState<string>()
  const [conferences, setConferences] = useState<Conference[]>([])
  const [defaultSeriesLength, setDefaultSeriesLength] = useState<number>(3)
  const [divisionName, setDivisionName] = useState('')
  const [eligibleTeams, setEligibleTeams] = useState<Team[]>([])
  const [firstMatchDate, setFirstMatchDate] = useState(new Date())
  const [isEditable, setIsEditable] = useState(true)
  const [isPremiere, setIsPremiere] = useState(false)
  const [isPremium, setIsPremium] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [maxTeamSize, setMaxTeamSize] = useState<number>(5)
  const [minTeamSize, setMinTeamSize] = useState<number>(1)
  const [name, setName] = useState('')
  const [price, setPrice] = useState<number>(0)
  const [regCloseDate, setRegCloseDate] = useState(new Date())
  const [regionChoice, setRegionChoice] = useState(SeasonRegions.NorthAmerica)
  const [regOpenDate, setRegOpenDate] = useState(new Date())
  const [rosterLockDate, setRosterLockDate] = useState<Date | undefined>()
  const [scheduleTypeChoice, setScheduleTypeChoice] = useState(SeasonSchedules.RoundRobin)
  const [seasonEndDate, setSeasonEndDate] = useState(new Date())
  const [seasonId, setSeasonId] = useState('')
  const [seasonYear, setSeasonYear] = useState(getSeasonYears()[0])
  const [selectedSport, setSelectedSport] = useState('')
  const [sponsoredImageName, setSponsoredImageName] = useState<string>()
  const [sponsoredImageUrl, setSponsoredImageUrl] = useState<string>()
  const [teams, setTeams] = useState<Team[]>([])

  //UTILS
  const updateFromQuery = (data: SeasonAdminQuery) => {
    const { season } = data
    if (season) {
      if (season.id) setSeasonId(season.id)
      if (season.name) setName(season.name)
      if (season.seasonYear) setSeasonYear(season.seasonYear)
      if (season.registrationOpenDate) setRegOpenDate(new Date(season.registrationOpenDate))
      if (season.registrationCloseDate) setRegCloseDate(new Date(season.registrationCloseDate))
      if (season.firstMatchDate) setFirstMatchDate(new Date(season.firstMatchDate))
      if (season.seasonEndDate) setSeasonEndDate(new Date(season.seasonEndDate))
      if (season.rosterLockDate) setRosterLockDate(new Date(season.rosterLockDate))
      if (season.sport && season.sport.slug) setSelectedSport(season.sport.slug)
      if (season.matchPrototype && season.matchPrototype.gameCount)
        setDefaultSeriesLength(season.matchPrototype.gameCount)
      if (season.regionChoice) setRegionChoice(season.regionChoice)
      if (season.scheduleTypeChoice) setScheduleTypeChoice(season.scheduleTypeChoice)
      if (season.priceCents) setPrice(season.priceCents / 100)
      if (season.division1) setIsPremiere(season.division1)
      if (season.divisionName) setDivisionName(season.divisionName)
      if (season.isPremium) setIsPremium(season.isPremium)
      if (season.isEditable !== null) setIsEditable(season.isEditable)
      if (season.maximumTeamSize) setMaxTeamSize(season.maximumTeamSize)
      if (season.minimumTeamSize) setMinTeamSize(season.minimumTeamSize)
      if (season.adminSeasonTeams) setTeams(season.adminSeasonTeams)
      if (season.conferences) setConferences(season.conferences)
      if (season.banner) {
        setBannerUrl(season.banner)
        setBannerName(season.banner.split('/').pop())
      }
      if (season.sponsorImage) {
        setSponsoredImageUrl(season.sponsorImage)
        setSponsoredImageName(season.sponsorImage.split('/').pop())
      }
      if (season.published) setIsPublished(season.published)
    }
  }

  //TODO call mutation to destroy the registration and enrollment instead
  const dropTeam = (team: Team) => {
    unassignTeam(team)
    setTeams(teams.filter((t: Team) => t !== team))
  }
  const updateTeamConference = (team: Team, newConf: any) => {
    if (newConf) {
      unassignTeam(team)

      //This can come in the following ways, based on different call sites
      const confName = newConf.name || newConf.label || newConf
      //if confName is in the list of conferences we dont need to make it
      const conferencesWithConfName = conferences.filter(conf => conf.name === confName)

      if (conferencesWithConfName.length > 0) {
        const existingConf = conferencesWithConfName[0]
        assignTeamToConference(team, existingConf)
      } else {
        const conf = createConference(confName as string)
        assignTeamToConference(team, conf)
      }
    }
  }

  //Unassign a team from all conferences.
  const unassignTeam = (team: Team) => {
    conferences.forEach((conf: Conference) => {
      if (conf.teams) {
        conf.teams = conf.teams.filter(t => team.id !== t.id)
        updateConference(conf)
      }
    })
  }

  const getConferenceForTeam = (team: Team) => {
    const conf = conferences.find(
      (c: Conference) => c.teams && c.teams.map(t => t.id).includes(team.id),
    )
    return conf
  }

  const getConferenceNameFromTeam = (team: Team) => {
    const conf = getConferenceForTeam(team)
    return conf && conf.name ? conf.name : ''
  }

  //Assign a team to a conference (create it if it doesn't exist)
  const assignTeamToConference = (team: Team, conf: Conference) => {
    if (conf.teams) {
      conf.teams = [...conf.teams, team]
    }
    updateConference(conf)
  }

  const updateConference = (conference: Conference) => {
    const confs = [...conferences]
    const targetIndex = _.findIndex(confs, (conf: Conference) => conf.name === conference.name)
    if (targetIndex > -1) {
      confs[targetIndex] = conference
      setConferences(confs)
    } else {
      setConferences([...confs, conference])
    }
  }

  //Create a conference from it's name
  const createConference = (name: string) => {
    const newConf: Conference = {
      __typename: 'Conference',
      name: name,
      teams: [],
    }
    return newConf
  }

  return (
    <SeasonAdminContext.Provider
      value={{
        bannerName,
        bannerUrl,
        conferences,
        createConference,
        defaultSeriesLength,
        divisionName,
        dropTeam,
        eligibleTeams,
        firstMatchDate,
        getConferenceNameFromTeam,
        isEditable,
        isPremiere,
        isPremium,
        isPublished,
        maxTeamSize,
        minTeamSize,
        name,
        price,
        regCloseDate,
        regionChoice,
        regOpenDate,
        rosterLockDate,
        scheduleTypeChoice,
        seasonEndDate,
        seasonId,
        seasonYear,
        selectedSport,
        setBannerName,
        setBannerUrl,
        setConferences,
        setDefaultSeriesLength,
        setDivisionName,
        setEligibleTeams,
        setFirstMatchDate,
        setIsEditable,
        setIsPremiere,
        setIsPremium,
        setIsPublished,
        setMaxTeamSize,
        setMinTeamSize,
        setName,
        setPrice,
        setRegCloseDate,
        setRegionChoice,
        setRegOpenDate,
        setRosterLockDate,
        setScheduleTypeChoice,
        setSeasonEndDate,
        setSeasonId,
        setSeasonYear,
        setSelectedSport,
        setSponsoredImageName,
        setSponsoredImageUrl,
        setTeams,
        sponsoredImageName,
        sponsoredImageUrl,
        teams,
        unassignTeam,
        updateFromQuery,
        updateTeamConference,
      }}
    >
      {children}
    </SeasonAdminContext.Provider>
  )
}
export const SeasonAdminContext = React.createContext<ISeasonAdminState>(defaultSeasonAdminState)
