import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import { isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { GiCrossedSwords } from 'react-icons/gi'
import { useModal } from 'react-modal-hook'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, Flex, Text } from 'rebass'

import { TeamContext } from '../../contexts/TeamContext'
import { MediaQueries as Mq, styled } from '../../styles/settings/theme'
import { useApplyForTeamMutation } from '../../types/graphql'
import { handleErrors } from '../../utils/formUtils'
import { paths } from '../../utils/Routes'
import { roles } from '../../utils/sportUtils'
import Animation from '../atoms/Animation'
import { BaseButton } from '../atoms/Buttons'
import Card from '../atoms/Card'
import Content from '../atoms/Content'
import ModalContainer from '../atoms/ModalContainer'
import SelectRole from '../molecules/SelectRole'
import DataFetcher from '../organisms/DataFetcher'
import MatchCarousel from '../organisms/MatchCarousel'
import TeamDetailHero from '../organisms/TeamDetailHero'
import TeamDetailInformation from '../organisms/TeamDetailInformation'
import TeamDetailLineup from '../organisms/TeamDetailLineup'
import TeamDetailSeasonHistory from '../organisms/TeamDetailSeasonHistory'

gql`
  mutation applyForTeam($id: ID!, $gameRole: String) {
    applyForTeam(id: $id, gameRole: $gameRole) {
      success
    }
  }
`

const StyledBox = styled(Box)`
  height: auto;
`

const StyledFlex = styled(Flex)`
  padding: 1rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  ${Mq.md} {
    padding: 2rem;
  }
`

const TeamDetailContent: React.FC = () => {
  const {
    id,
    sportSlug,
    settledMatches,
    carouselMatches,
    tournamentsToCheckinTo,
    setCurrentUserApplicationPending,
    currentUserIsCoordinator,
    currentSeasonEligible,
    activeRosterEntries,
    divisionName,
  } = useContext(TeamContext)

  const { colors } = useTheme()

  const [applyToTeam, { error, loading: applyForTeamLoading }] = useApplyForTeamMutation({
    onError(error) {
      toast.error(handleErrors(error), { containerId: 'temporary' })
    },
    onCompleted: () => {
      setCurrentUserApplicationPending(true)
      toast.success('Application Submitted!', { containerId: 'temporary' })
    },
  })

  const handleApply = () => {
    roles(sportSlug) ? showApplyToTeamModal() : applyToTeam({ variables: { id } })
  }
  const animations = {
    hidden: {
      opacity: 0,
      y: '24px',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  }

  const [showApplyToTeamModal, hideApplyToTeamModal] = useModal(
    () => (
      <ModalContainer
        hideModal={hideApplyToTeamModal}
        style={{
          content: { maxWidth: '41.25rem' },
        }}
      >
        <SelectRole
          hideModal={hideApplyToTeamModal}
          applyToTeam={applyToTeam}
          roles={roles(sportSlug)}
          teamId={id}
        />
      </ModalContainer>
    ),
    [sportSlug],
  )

  const shouldShowRegisterCTAs: boolean =
    currentUserIsCoordinator &&
    !divisionName &&
    activeRosterEntries.length - currentSeasonEligible >= 0

  if (shouldShowRegisterCTAs) {
    toast.success(
      <Link to={paths.leagues()} onClick={() => toast.dismiss('team-page-notice')}>
        This team qualifies for season registration - CLICK HERE TO REGISTER
      </Link>,
      {
        autoClose: false,
        hideProgressBar: true,
        className: 'CTA',
        containerId: 'permanent',
        toastId: 'team-page-notice',
      },
    )
  }

  return (
    <DataFetcher error={error} loading={applyForTeamLoading}>
      <TeamDetailHero applyToTeam={handleApply} />
      <Content>
        <Box mb={[5, 5, 7, 8]}>
          {currentUserIsCoordinator && !divisionName && (
            <Card notch notchSize={16}>
              <StyledFlex mb="1rem">
                <Box mb="2rem" mt="1rem">
                  <GiCrossedSwords size="45" color={colors.red} />
                </Box>
                <Text mb="1rem" textAlign="center" width={[1, 2 / 3, 1 / 2]}>
                  <h2>YOUR TEAM IS NOT REGISTERED</h2>
                  <Text mt="1rem">
                    <p>
                      {activeRosterEntries.length - currentSeasonEligible >= 0
                        ? 'To participate in league play, please select a league from our leagues page and register your team for a season'
                        : `Your team needs to register for a league to compete. Prior to registering for a league, please recruit ${currentSeasonEligible -
                            activeRosterEntries.length} more teammates so you have an eligible team.`}
                    </p>
                  </Text>
                </Text>
                <Link to={paths.leagues}>
                  <BaseButton>Register for Leagues</BaseButton>
                </Link>
              </StyledFlex>
            </Card>
          )}
          <Animation
            initial="hidden"
            animate="visible"
            variants={animations}
            transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
          >
            <TeamDetailLineup applyToTeam={handleApply} />
          </Animation>
        </Box>
        <Flex flexWrap="wrap" mx={[0, 0, -3]}>
          <Box width={[1, 1, 1 / 2]} px={[0, 0, 3]} mb={[5, 5, 0]}>
            <TeamDetailInformation />
          </Box>
          <StyledBox width={[1, 1, 1 / 2]} px={[0, 0, 3]}>
            <TeamDetailSeasonHistory teamId={id} matches={settledMatches} />
          </StyledBox>
        </Flex>
        {(!isEmpty(tournamentsToCheckinTo) || !isEmpty(carouselMatches)) && (
          <Box my={8}>
            <Text mb={5}>
              <h2>League Matches & Tournaments</h2>
            </Text>
            <MatchCarousel tournaments={tournamentsToCheckinTo} matches={carouselMatches} />
          </Box>
        )}
      </Content>
    </DataFetcher>
  )
}

export default TeamDetailContent
