import _, { isEmpty } from 'lodash'
import { Box, Flex, Text } from 'rebass'
import { useTheme } from 'emotion-theming'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'

import { allSportSlugs } from '../../utils/sportUtils'
import { BaseButton } from '../atoms/Buttons'
import { stringToEnum } from '../../utils/typeCoercions'
import { usePendingForfeitsLazyQuery as useForfeits, SportSlugs } from '../../types/graphql'
import { useSportSelector } from '../../hooks/sportSelector'
import Card from '../atoms/Card'
import Content from '../atoms/Content'
import DataFetcher from '../organisms/DataFetcher'
import ForfeitsEmptyState from '../molecules/ForfeitsEmptyState'
import ForfeitsTable from '../organisms/ForfeitsTable'
import GamesFilter from '../organisms/GamesFilter'
import HeroContainer from '../atoms/HeroContainer'
import HeroImage from '../../assets/images/hero__league-index.jpg'
import LoadingSpinner from '../atoms/LoadingSpinner'

gql`
  query pendingForfeits($sports: [String!], $first: Int, $after: String) {
    pendingForfeitsConnection(sports: $sports, first: $first, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          requestingTeamName
          opposingTeamName
          explanation
          proofScreenshotUrl
          updatedAt
          match {
            id
            sport
          }
        }
      }
    }
  }
`

const Forfeits: React.FC = () => {
  const { colors } = useTheme()
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)
  const [loadingPagination, setLoadingPagination] = useState(false)
  const [selectedSports, handleClickedSport] = useSportSelector(
    _.compact(allSportSlugs.map(sportSlug => stringToEnum<SportSlugs>(SportSlugs, sportSlug))),
  )

  const [getPendingForfeits, { data, error, loading, fetchMore }] = useForfeits({
    variables: {
      sports: selectedSports,
      first: 10,
    },
  })

  const handlePagination = () => {
    setLoadingPagination(true)
    fetchMore({
      variables: {
        after: data && data.pendingForfeitsConnection.pageInfo.endCursor,
        first: 10,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult && fetchMoreResult.pendingForfeitsConnection.edges
        const pageInfo = fetchMoreResult && fetchMoreResult.pendingForfeitsConnection.pageInfo

        setLoadingPagination(false)
        return newEdges && newEdges.length
          ? {
              pendingForfeitsConnection: {
                __typename: previousResult.pendingForfeitsConnection.__typename,
                edges: [...previousResult.pendingForfeitsConnection.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult
      },
    })
  }

  useEffect(() => {
    getPendingForfeits()
  }, [selectedSports, getPendingForfeits])

  const forfeits =
    data &&
    data.pendingForfeitsConnection &&
    data.pendingForfeitsConnection.edges &&
    data.pendingForfeitsConnection.edges.map((e: any) => e.node)

  if (forfeits && !firstLoadComplete) {
    setFirstLoadComplete(true)
  }

  return (
    <DataFetcher error={error} loading={!firstLoadComplete}>
      <HeroContainer height="15rem" tint={'1,26,64, 0.6'} heroImage={HeroImage}>
        <Flex alignItems="flex-end" height="100%" pt={5}>
          <Text color={colors.white}>
            <h1>Pending Forfeits</h1>
          </Text>
        </Flex>
      </HeroContainer>

      <Content>
        <Box mb={6}>
          <GamesFilter
            sportSlugs={allSportSlugs}
            unplayedSports={[]}
            selectedSports={selectedSports}
            sportClickHandler={handleClickedSport}
          />
        </Box>

        <Card>
          {(!forfeits || isEmpty(forfeits)) && !loading ? (
            <ForfeitsEmptyState />
          ) : loading ? (
            <LoadingSpinner />
          ) : (
            forfeits && <ForfeitsTable forfeits={forfeits} />
          )}
        </Card>
        {data && data.pendingForfeitsConnection.pageInfo.hasNextPage ? (
          !loadingPagination ? (
            <Flex mt={4} justifyContent="center">
              <BaseButton onClick={handlePagination}>See More</BaseButton>
            </Flex>
          ) : (
            <LoadingSpinner />
          )
        ) : null}
      </Content>
    </DataFetcher>
  )
}

export default Forfeits
