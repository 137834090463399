import React from 'react'
import { NavLink, Redirect, Route, RouteComponentProps, useRouteMatch } from 'react-router-dom'
import { Box } from 'rebass'
import { oc } from 'ts-optchain'
import { styled } from '../../styles/settings'

import { TournamentProvider } from '../../contexts/TournamentContext'
import { paths } from '../../utils/Routes'
import AdminTournamentInfo from '../organisms/AdminTournamentInfo'
import AdminTournamentIrl from '../organisms/AdminTournamentIrl'
import AdminTournamentLogistics from '../organisms/AdminTournamentLogistics'
import AdminTournamentTeams from '../organisms/AdminTournamentTeams'
import AdminTournamentTitle from '../atoms/AdminTournamentTitle'
import Content from '../atoms/Content'
import TournamentAdminQueryProvider from '../organisms/TournamentAdminQueryProvider'

const EditNav = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    font-family: ${props => props.theme.fonts.condensed};
    margin-bottom: 1.5rem;
  }
`

const CustomNavLink = styled(NavLink)`
  color: ${props => props.theme.colors.darkmiddlegray};
  &.active {
    color: ${props => props.theme.colors.darkgray};
  }
`

interface IURLParams {
  tournamentId?: string
  anchor?: string
  page?: string
}

interface IProps extends RouteComponentProps<IURLParams> {
  customStyles: any
}

const ManageTournament: React.FunctionComponent<IProps> = ({ match: { params } }) => {
  //basic info
  const match = useRouteMatch()

  const tournamentId = oc(params).tournamentId('')
  const anchor = oc(params).anchor('')
  enum Anchors {
    BasicInfo = 'basic-info',
    Logistics = 'logistics',
    Irl = 'irl',
    Teams = 'teams',
  }
  const sections: { [key: string]: React.ReactElement } = {
    [Anchors.BasicInfo]: <AdminTournamentInfo />,
    [Anchors.Logistics]: <AdminTournamentLogistics />,
    [Anchors.Irl]: <AdminTournamentIrl />,
    [Anchors.Teams]: <AdminTournamentTeams />,
  }
  return anchor === '' ? (
    <Redirect to={paths.manageTournament(tournamentId, Anchors.BasicInfo)} />
  ) : (
    <TournamentProvider>
      <TournamentAdminQueryProvider tournamentId={tournamentId}>
        <Content>
          <AdminTournamentTitle />
          <Box
            sx={{
              display: 'grid',
              gridGap: 1,
              gridTemplateColumns: '1fr 4fr',
            }}
          >
            <EditNav>
              <li key={Anchors.BasicInfo}>
                <CustomNavLink to={Anchors.BasicInfo} isActive={() => anchor === Anchors.BasicInfo}>
                  <h5>basic info</h5>
                </CustomNavLink>
              </li>
              <>
                <li key={Anchors.Logistics}>
                  <CustomNavLink
                    to={Anchors.Logistics}
                    isActive={() => anchor === Anchors.Logistics}
                  >
                    <h5>logistics</h5>
                  </CustomNavLink>
                </li>
                <li key={Anchors.Irl}>
                  <CustomNavLink to={Anchors.Irl} isActive={() => anchor === Anchors.Irl}>
                    <h5>irl</h5>
                  </CustomNavLink>
                </li>
                <li key={Anchors.Teams}>
                  <CustomNavLink to={Anchors.Teams} isActive={() => anchor === Anchors.Teams}>
                    <h5>teams</h5>
                  </CustomNavLink>
                </li>
              </>
            </EditNav>
            <Route
              exact
              path={paths.manageTournament(tournamentId)}
              render={() => sections[Anchors.BasicInfo]}
            />
            <Route path={`${match.path}`} render={({ match }) => sections[anchor]} />
          </Box>
        </Content>
      </TournamentAdminQueryProvider>
    </TournamentProvider>
  )
}

export default ManageTournament
