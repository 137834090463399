import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { capitalize, startCase } from 'lodash'
import { Text } from 'rebass'
import DataFetcher from '../organisms/DataFetcher'
import SectionTitle from '../atoms/SectionTitle'
import { AuthFormBox } from '../atoms/AuthFormPieces'
import { BaseButton } from '../atoms/Buttons'
import { InputFieldWithErrors } from '../molecules/InputFieldWithErrors'

const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      success
      errors
    }
  }
`

interface IChangePasswordProps {
  onFormSubmitted: () => void
}

const ChangePassword: React.FC<IChangePasswordProps> = ({ onFormSubmitted }) => {
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  } as any)

  const [submitForm, { loading, error }] = useMutation(CHANGE_PASSWORD, {
    variables: {
      currentPassword: passwordFields.currentPassword,
      newPassword: passwordFields.newPassword,
    },
    onCompleted({ changePassword }) {
      if (changePassword.errors.length) {
        setFormErrors(changePassword.errors)
      } else {
        toast.success('Password updated!', { containerId: 'temporary' })
        onFormSubmitted()
      }
    },
  })

  const validatePasswords = () => {
    const errors = []

    Object.keys(passwordFields).forEach((key: string) => {
      if (!passwordFields[key]) errors.push(`${capitalize(startCase(key))} is required.`)
    })

    if (passwordFields.currentPassword === passwordFields.newPassword) {
      errors.push('New password cannot be the same as current password.')
    }

    if (passwordFields.newPassword !== passwordFields.passwordConfirmation) {
      errors.push('New password does not match password confirmation.')
    }

    setFormErrors(errors)

    if (!errors.length) {
      submitForm()
    }
  }

  const isButtonDisabled =
    !!formErrors.length ||
    !passwordFields.currentPassword ||
    !passwordFields.newPassword ||
    !passwordFields.passwordConfirmation

  return (
    <DataFetcher loading={loading} error={error}>
      <AuthFormBox>
        <SectionTitle text="Change Password" align="center" mb=".75rem" />
        <Text textAlign="center" mb={5}>
          Enter your current password and new password below. Remember passwords must be 8 or more
          characters.
        </Text>
        {Object.keys(passwordFields).map((field: string) => (
          <InputFieldWithErrors
            mb={5}
            key={field}
            type="password"
            name={field}
            label={startCase(field)}
            placeholder={startCase(field)}
            value={passwordFields[field]}
            updateField={field => {
              setPasswordFields({ ...passwordFields, ...field })
              setFormErrors([])
            }}
          />
        ))}
        {!!formErrors.length &&
          formErrors.map((error: string) => (
            <Text key={error} textAlign="center" color="red">
              <p>{error}</p>
            </Text>
          ))}

        <BaseButton
          mt={3}
          variant={isButtonDisabled ? 'primaryDisabled' : 'primary'}
          disabled={isButtonDisabled}
          onClick={validatePasswords}
        >
          Change Password
        </BaseButton>
      </AuthFormBox>
    </DataFetcher>
  )
}

export default ChangePassword
