import React from 'react'
import { Box, BoxProps } from 'rebass'
import { styled } from '../../styles/settings/theme'

interface IAccordionProps {
  height: string
  heroImage?: string
  tint?: string
}

interface IStyledBoxProps {
  image?: string
  tint?: string
}

const StyledBox = styled(Box)<IStyledBoxProps & BoxProps>`
  width: 100%;
  background-image: linear-gradient(black, black), url('${props => props.image}');
  background-blend-mode: saturation;
  background-size: cover;
  background-position: center;
  box-shadow: 0 3000px rgba(${props => props.tint}) inset;
  position: relative;
`

const AccordionContainer: React.FC<IAccordionProps> = ({ height, heroImage, tint, children }) => {
  return (
    <StyledBox
      tint={tint}
      minHeight={[height]}
      height={['auto', height]}
      image={heroImage}
      p={[3, 3, 5, 6]}
    >
      {children}
    </StyledBox>
  )
}

export default AccordionContainer
