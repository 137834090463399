import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import DataFetcher from '../organisms/DataFetcher'
import { handleErrors } from '../../utils/formUtils'

const RESEND_CONFIRMATION_MUTATION = gql`
  mutation sendEmailConfirmation {
    sendEmailConfirmation {
      success
    }
  }
`

interface State {
  from: string
}

const ResendConfirmation: React.FunctionComponent<RouteComponentProps> = ({
  location: { state },
}) => {
  const [mounted, setMounted] = useState(false)
  const [sendConfirmationEmail, { loading, error }] = useMutation(RESEND_CONFIRMATION_MUTATION, {
    onError() {
      toast.error(handleErrors(error))
    },
    onCompleted() {
      toast.success('Confirmation email sent.', { containerId: 'temporary' })
    },
  })

  useEffect(() => {
    if (!mounted) {
      sendConfirmationEmail()
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted])

  return (
    <DataFetcher loading={loading || !mounted} error={error}>
      <Redirect
        to={{
          pathname: state ? (state as State).from : '/',
          state: null,
        }}
      />
    </DataFetcher>
  )
}

export default ResendConfirmation
